import React , { useState , useEffect } from 'react'
import './checklist.css'
import cl_three from '../../../assets/img/cl_three.png'
import { Link , useNavigate } from 'react-router-dom'
import { ImageUrl } from '../../globalvariable/globe'
import { Base_Url } from '../../globalvariable/globe'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import red_minus from '../../../assets/img/red_minus.png'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    ChakraProvider,
    useDisclosure
  } from '@chakra-ui/react'

export default function Checklistcomp() {

    let navigate = useNavigate()

    const { isOpen, onOpen, onClose } = useDisclosure()

    const {id} = useParams()

    let token = localStorage.getItem('user_data')

    const [ alldata , setAlldata ] = useState()
    const [ idss , setIdss ] = useState()
    const [ idsss , setIdsss ] = useState()
    const [ idssss , setIdssss ] = useState()

    const [ otp , setOtp ] = useState()

    let start = async () => {
        if(token === null){
            navigate('/')
            return
          }
        let response = await axios ({
            method : 'post',
            url : `${Base_Url}/verifyPendingOrder`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            },
            data : {
                orderId : id
            }
        })
        .then((res)=>{
            setAlldata(res.data.Data)
            console.log(res.data.Data)
        })
        .catch((err)=>{
            if(err.response.status === 401){
                navigate('/login')
            }
        })
    }

    useEffect(()=>{
        start()
    },[])

    const imageClick = (data) => {
        setIdss(data.CartDetailId)
        let input = document.querySelector('#file');
        input.click();
    }

    const imageChange = async (data , event ) =>{
        let formData = new FormData();
        formData.append("image1", event.target.files[0]);
        console.log(idss)
        formData.append("cartDetailId",idss);

        let responce = await axios.post( `${Base_Url}/uploadPendingOrderImage`, formData, {
            headers: {
                'Authorization': `Bearer ${token}` ,
                'Content-Type': 'multipart/form-data'
            } 
        })
        .then((res)=>{
            console.log(res)
            start()
            setIdss()
        })
        .catch((err)=>{
            if(err.response.status === 401){
                navigate('/login')
            }
            console.log(err)
        })
    }

    const imageClicks = (data) => {
        setIdsss(data.CartDetailId)
        let input = document.querySelector('#files');
        input.click();
    }

    const imageChanges = async (data , event ) =>{
        let formData = new FormData();
        formData.append("image2", event.target.files[0]);
        formData.append("cartDetailId",idsss);

        let responce = await axios.post( `${Base_Url}/uploadPendingOrderImage`, formData, {
            headers: {
                'Authorization': `Bearer ${token}` ,
                'Content-Type': 'multipart/form-data'
            } 
        })
        .then((res)=>{
            console.log(res)
            start()
            setIdsss()
        })
        .catch((err)=>{
            if(err.response.status === 401){
                navigate('/login')
            }
            console.log(err)
        })
    }

    const imageClickss = (data) => {
        setIdssss(data.CartDetailId)
        let input = document.querySelector('#filess');
        input.click();
    }

    const imageChangess = async (data , event ) =>{
        let formData = new FormData();
        formData.append("image3", event.target.files[0]);
        formData.append("cartDetailId",idssss);

        let responce = await axios.post( `${Base_Url}/uploadPendingOrderImage`, formData, {
            headers: {
                'Authorization': `Bearer ${token}` ,
                'Content-Type': 'multipart/form-data'
            } 
        })
        .then((res)=>{
            console.log(res)
            start()
            setIdssss()
        })
        .catch((err)=>{
            if(err.response.status === 401){
                navigate('/login')
            }
            console.log(err)
        })
    }


    let Checkbox = async(data,e) =>{

        let responce = await axios({
            method : 'post' ,
            url : `${Base_Url}/scanProduct` ,
            headers: {
                'Authorization': `Bearer ${token}` ,
                'Content-Type': 'multipart/form-data'
            },
            data : {
                cartDetailId : data.CartDetailId,
                status : data.CartDetailCustProStatus === 0 ? 1 : 0 
            }
        })
        .then((res)=>{
            console.log(res)
            start()
            setIdssss()
        })
        .catch((err)=>{
            if(err.response.status === 401){
                navigate('/login')
            }
            console.log(err)
        })

    }

    const generateOtp = async() => {
        let responce = await axios({
            method : 'post',
            url :  `${Base_Url}/pendingOrderGenerateOtp` ,
            headers: {
                'Authorization': `Bearer ${token}` ,
                'Content-Type': 'multipart/form-data'
            },
            data : {
                orderId : alldata.orderId
            }
        })
        .then((res)=>{
            setOtp(res.data.Data)
            onOpen()
        })
        .catch((err)=>{
            if(err.response.status === 401){
                navigate('/login')
            }
        })
    }



  return (
    <>
      <div>
          <div className='d-flex justify-content-between '>
                <div>
                    <p className='ch_one'>Products ({alldata?.products.length} items)</p>
                </div>
                {/* <div>
                    <div className='d-flex'>
                        <input className='ch_three' type='checkbox' />
                        <p className='ch_two'>Select all</p>
                    </div>
                </div> */}
          </div>
          {
            alldata?.products.map((data,key)=>
            <div className='mt-3'>
                <div className='ch_threes'>
                    <div className='row'>
                        <div className='ch_five col-1'>
                            <input onChange={(e)=>Checkbox(data,e)}  checked={data.CartDetailCustProStatus === 0 ? '' : 'checked'} type='checkbox' className='ch_six' />
                        </div>
                        <div className='ch_five col-5'>
                            <p className='ch_seven'>{alldata?.orderId}</p>
                            <p className='ch_eights'>APPLE 2020 Macbook Pro M1 - (8/512 GB SSD/Mac OS Big Sur)</p>
                        </div>
                        <div style={{marginTop:'4px'}} className='col-2'>
                        {
                            data.CartDetailCustProImg1 === null ? 
                            <>
                                <img onClick={(e)=>imageClick(data)} src={cl_three} />
                                <input type='file' onChange={(event)=>imageChange(data , event)} id='file' style={{display: 'none'}}/>
                            </>
                            :
                            <>
                                <img className='web_pending_ones' src={ImageUrl+'/'+data.CartDetailCustProImg1} />
                                <div className='red_minuss'>
                                    <img style={{ width :  15 }} onClick={(e)=>imageClick(data)}  src={red_minus} />
                                    <input type='file' onChange={(event)=>imageChange(data , event)} id='file' style={{display: 'none'}}/>
                                </div>
                            </>
                        }  
                                                  
                        </div>
                        <div style={{marginTop:'4px'}} className='col-2'>
                        {
                            data.CartDetailCustProImg2 === null ? 
                            <>
                                <img onClick={(e)=>imageClicks(data)} src={cl_three} />
                                <input type='file' onChange={(event)=>imageChanges(data , event)} id='files' style={{display: 'none'}}/>
                            </>
                            :
                            <>
                                <img className='web_pending_ones' src={ImageUrl+'/'+data.CartDetailCustProImg2} />
                                <div className='red_minuss'>
                                    <img style={{ width :  15 }} onClick={(e)=>imageClicks(data)}  src={red_minus} />
                                    <input type='file' onChange={(event)=>imageChanges(data , event)} id='files' style={{display: 'none'}}/>
                                </div>
                            </>
                        }  
                        </div>
                        <div style={{marginTop:'4px'}} className='col-2'>
                        {
                            data.CartDetailCustProImg3 === null ? 
                            <>
                                <img  onClick={(e)=>imageClickss(data)} src={cl_three} />
                                <input type='file' onChange={(event)=>imageChangess(data , event)} id='filess' style={{display: 'none'}}/>
                            </>
                            :
                            <>
                                <img className='web_pending_ones' src={ImageUrl+'/'+data.CartDetailCustProImg3} />
                                <div className='red_minuss'>
                                    <img style={{ width :  15 }} onClick={(e)=>imageClickss(data)}  src={red_minus} />
                                    <input type='file' onChange={(event)=>imageChangess(data , event)} id='filess' style={{display: 'none'}}/>
                                </div>
                            </>
                        }  
                        </div>
                    </div>
                </div>
            </div>
            )
          }

      </div>
      <div className='checklist_one'>
        <div onClick={()=>generateOtp()} style={{cursor:'pointer'}} className='checklist_two'>
            <p className='checklist_three'>Generate otp</p>
        </div>
    </div>
    <ChakraProvider>
        <Modal
            isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay
                bg='blackAlpha.300'
                backdropFilter='blur(10px) hue-rotate(90deg)'
                />
                <ModalContent>
                    <ModalHeader>Verification</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <p>Please Share the 4 digit Verification code to the delivery agent</p>
                    <h4 style={{ textAlign:'center' , letterSpacing : '10px' , fontSize : '30px' }} className='mt-4'>{otp?otp : ''}</h4>
                </ModalBody>
                <ModalFooter>

                </ModalFooter>
                </ModalContent>
        </Modal>
    </ChakraProvider>
    </>
  )
}