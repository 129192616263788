import React , { useState , useEffect } from 'react'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'
import Cart_one_container from '../../components/mobile_view_comp/cart_one/cart_one_container'
import { useSelector , useDispatch } from 'react-redux'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { Link , useNavigate } from 'react-router-dom'
import Dot_arrow from '../../components/mobile_view_comp/cart_one/dot_arrow'
import { ViewCart } from '../../redux/actions/productAction'
import { Base_Url } from '../../components/globalvariable/globe'
import kyc1 from '../../assets/img/kyc1.png'
import { useParams } from 'react-router-dom'

export default function Mob_cart_one() {

  let navigate = useNavigate()

  const {id} = useParams()

  let dispatch = useDispatch()
  const [ alldata , setAlldata ] = useState()

  const errnotify = (dat) => toast.error( dat , {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const allData = async()=> {

    let user = localStorage.getItem('user_data')

    if ( user === null ){
      navigate('/')
      return
    }
    let responce = await axios({
            method : 'post',
            url : `${Base_Url}/viewOrderProductDetails` ,
            headers: { 
                'Authorization': `Bearer ${user}` 
            },
            data : {
              orderId : id
            }
        })
        .then((res)=>{
            if( res.data.paymentStatus === 1  ){
            navigate('/')
            return
            }
            if(res.data.status === true){
                dispatch(ViewCart(res.data.Data))
                // console.log(res)
            }else{

            }
        })
        .catch((err) => {
          if(err.response.status === 401){
            navigate('/login')
          }
        })

    
    }

    useEffect(()=>{ 
        allData()
    },[]) 

 

  return (
    <>
      <div style={{width:'90%' , margin:'auto' ,marginTop:'65px'}}>

        <Cart_one_head link='/cart' head='Cart' />
        <img style={{ width : '100%' }}  src={kyc1} />
        <Cart_one_container />
      </div>
      <ToastContainer />
    </>
  )
}