import React from 'react'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'
import Mob_coupon_comp from '../../components/mobile_view_comp/mob_coupon/mob_coupon_comp'
import Arrow_payment from '../../components/mobile_view_comp/payment/arrow_payment'
import { Link , useParams } from 'react-router-dom'

export default function Coupon(props) {
  const {id} = useParams()
  return (
    <>
    {id==='0'? <div style={{width:'90%' , margin:'auto' ,marginTop:'65px'}}>
            <Cart_one_head link='/payment/1' head='Payment' />
            <Arrow_payment />
            <Mob_coupon_comp />
        </div>:<div style={{width:'90%' , margin:'auto' ,marginTop:'65px'}}>
            <Cart_one_head link='/payment/0' head='Payment' />
            <Mob_coupon_comp />
        </div> 
        }
    </>
  )
}