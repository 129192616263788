import React from 'react'

export default function Mob_pro_hign(probs) {
    console.log(probs)
  return (
    <>
        <div>
            <ul>
                {probs.text.map((data,key)=>
                    <li className='list'>{data.one}</li>
                )}
            </ul> 
        </div>
    </>
  )
}