import * as React from "react";
import GalleryDetail from "../../magnify_image/GalleryDetail";
import { ImageUrl } from "../../globalvariable/globe";

const Maggni = (props) =>{

    console.log(props.img,'imggggggg')

    const datas = props.img

    const FakeDataItems = () => {
    
        const sizes = { thumbnail: 150, product: 550, zoom: 850 };
  
        return datas ? datas.map((id, i) => {
        const o= {};
        for (let size in sizes) {
            o[size] = {
            url: ImageUrl+'/'+id.ProImgName,
            alt: `foto ${i + 1} - ${sizes[size]}px`,
            format: size
            };
        }
        return o;
        }): ''
    };
  
    return(
        <>
            {
                datas ? <GalleryDetail galleries={FakeDataItems()} thumbsPerView={3} />:''
            }
        </>
    )
}

export default Maggni