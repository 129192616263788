import React , {useState , useEffect} from 'react'
import './mob_cart.css'
import cart from '../../assets/img/cart.png'
import minus from '../../assets/img/minus.png'
import pl from '../../assets/img/pl.png'
import savelist from '../../assets/img/savelist.png'
import down from '../../assets/img/down.png'
import aright from '../../assets/img/aright.png'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ImageUrl } from '../globalvariable/globe'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import { LoogIn } from '../../redux/actions/productAction'
import { useNavigate } from 'react-router-dom'
import { Base_Url } from '../globalvariable/globe'
import { Cartcount } from '../../redux/actions/productAction'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  ChakraProvider
} from '@chakra-ui/react'

export default function Mobile_cart() {

  let dispatch = useDispatch()

  let navigate = useNavigate()

  let token = localStorage.getItem('user_data')

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [ another , setAnother ] = useState()
  const [ rent , setRent ] = useState()
  const [ deposit , setDeposit ] = useState()
  const [ active , setActive ] = useState(false)
  const [ qty , setQty ] = useState()
  const [ total , setTotal ] = useState()
  const [ alltotal , setAlltotal ] = useState()
  const [ removes , setRemove ] = useState(true)

  const [ login , setlogin ] = useState(true)

  let deposittotal = 0
  let rents = 0
  const [ tto , setTto ] = useState()

  //
  const [countss  , setCountss ]= useState(1)
  let wishss = localStorage.getItem('save_list')
  const [ wish ,  setWish ] = useState()
  const [ shoee , setShoee ] = useState(false)

  const [ message , setMessage ] = useState('')
  //

  const ones = () =>{
      deposittotal = 0
      rents = 0
      let data = localStorage.getItem('data')
      if ( data === null ){ 
        setRemove(false)
        return
      }else if(data === '[]'){
        setRemove(false)
      }
      setAnother(JSON.parse(data))
      JSON.parse(data).forEach((element, index) => {
          deposittotal  =  element.totalDeposit + deposittotal 
          rents = element.proTotalAmnt + rents
      })
      setTto(deposittotal)
      setTotal(rents)
      setAlltotal( deposittotal + rents )
       
    }

    useEffect(()=>{
      //
      wishss = localStorage.getItem('save_list')
      setWish(JSON.parse(wishss))
      //

        if ( token === null ) {
            setlogin(false)
        }else{
            setlogin(true)
        }
        ones()
    },[]) 

    //
    useEffect(()=>{
      wishss = localStorage.getItem('save_list')
      setWish(JSON.parse(wishss))
    },[countss])

    const removess = (dat , key) => {
      wish.forEach((element, index) => {
          if(element.proId === dat.proId) {
              wish.splice(index, 1); 
          }
      })

      localStorage.setItem('save_list' , JSON.stringify(wish))
      setCountss(countss +1)
  }


    const savellist =( data , key ) => {
      setCountss(countss +1)
      let sav_list = localStorage.getItem('save_list')
      let anotherss = JSON.parse(sav_list)
      let final = 0
      let save_data = [
          {
              datas : data
          }
      ]

      console.log(data)

      if(localStorage.getItem('save_list')){
          anotherss.forEach((element, index) => {
              if( element.datas.proId === save_data[0].datas.proId){
                  final = 1
              }
          })
          console.log(final,'final')

          if( final === 0 ){
              let allanother =  anotherss.concat(save_data)
              localStorage.setItem('save_list' , JSON.stringify(allanother))


              another.forEach((element, index) => {
                  if(element.proId === data.proId) {
                      another.splice(index, 1); 
                  }
              })
      
              localStorage.setItem('data' , JSON.stringify(another))
              ones()
              if(another.length === 0){
                  setRemove(false)
                  return
              }
              
          }

      }else{
          localStorage.setItem('save_list' , JSON.stringify(save_data))
      }
  }

  const showsave = () =>{
      setShoee(!shoee)
  }

  const movetocarts = (data,key) =>{

      // let save_data = [
      //         data
      // ]

      let localdata =   [
          {
              proVarId : data.datas.proVarId ,
              proId : data.datas.proId ,
              proQty : data.datas.proQty ,
              proDepAmnt : data.datas.proDepAmnt ,
              proRentAmnt : data.datas.proRentAmnt ,
              proVarAmnt : data.datas.proVarAmnt ,
              proTotalAmnt : data.datas.proTotalAmnt ,
              proRentType : data.datas.proRentType ,
              proBookFrom : data.datas.proBookFrom ,
              proBookTo : data.datas.proBookTo,
              imgurl : data.datas.imgurl,
              brand  :  data.datas.brand,
              monthCount : data.datas.monthCount,
              totalDeposit : data.datas.totalDeposit,
              alldet : data.datas.alldet
          }
      ]
      console.log(data,'anotherrr')


      let sav_list = localStorage.getItem('data')
      let anotherss = JSON.parse(sav_list)

      if (sav_list=='[]'){
          localStorage.setItem('data' , JSON.stringify(localdata))
          ones()
      }else{
          let allanother =  anotherss.concat(localdata)
          localStorage.setItem('data' , JSON.stringify(allanother))
          ones()
      }

      

      
      wish.forEach((element, index) => {
          if(element.proId === data.proId) {
              wish.splice(index, 1); 
          }
      })

      localStorage.setItem('save_list' , JSON.stringify(wish))
      setCountss(countss +1)
  }

    const [ price , setPrice ] = useState(0)
    let one = 0

    let skl = [ 1 , 2 ]

    const errnotify = (dat) => toast.error( dat , {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    let datas = another
    
    const plus = ( dat , key ) =>{

        another.forEach((element, index) => {
          if ( element.proQty > 12 ){
            return
          }
            if(element.proId === dat.proId) {
                dat.totalDeposit =  dat.totalDeposit + dat.proDepAmnt;
                dat.proTotalAmnt = dat.proTotalAmnt + ( dat.proVarAmnt * dat.monthCount );
                dat.proQty = dat.proQty + 1
                console.log('one')
            }
        });

        localStorage.setItem('data' , JSON.stringify(another))

        ones()

    }

    const minuss = ( dat , key ) =>{
      if(dat.proQty === 1){
        return
    }

        another.forEach((element, index) => {
            if(element.proId === dat.proId) {
                dat.totalDeposit =  dat.totalDeposit - dat.proDepAmnt;
                dat.proTotalAmnt = dat.proTotalAmnt - ( dat.proVarAmnt * dat.monthCount );
                dat.proQty = dat.proQty - 1
                console.log('one')
            }
        });

        localStorage.setItem('data' , JSON.stringify(another))

        ones()

    }

    const remove = (dat , key) => {
      
        another.forEach((element, index) => {
            if(element.proId === dat.proId) {
                another.splice(index, 1); 
            }
        })

        localStorage.setItem('data' , JSON.stringify(another))
        dispatch(Cartcount(another))
        ones()
        if(another.length === 0){
          setRemove(false)
          return
        }
    }


    const Checkout = async () => {
        console.log(another)
        if(login === false){
            navigate('/login_two')
            return
        }
        let responce = await axios ({
            method : 'post',
            url : `${Base_Url}/proceedToCheckOut`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            },
            data : {
                products : another
            }
        })
        .then((res)=>{
              if(res.data.status === false){
                setMessage(res.data.message)
                onOpen()
            }else{
                window.localStorage.removeItem('data');
                navigate('/cart_one/'+ res.data.Data.orderId)
            }
            console.log(res)
        })
        .catch((err)=>{
          errnotify('Something Went Wrong')
          if(err.response.status === 401){
            navigate('/login')
          }
            console.log(err)
        })
    }

  return (
    <>

      {
      datas && [...datas]?.reverse()?.map((dta,key)=>{
          return(
                  <div className='mob_cart_cont coool8 mt-3'>
                    <div className='row'>
                      <div className='col-7'>
                          {/* <p className='cartpara14'>Apple</p> */}
                          <p className='cartpara1 mt-3'>{dta.brand}</p>
                          {/* <p className='cartpara2'>256/8 gb , 13.7” Retina Display</p>
                          <p className='cartpara5'>Delivery Expected by April 05</p> */}
                      </div>
                      <div className='col-5'>
                        <img className='cart_img' style={{width:'100%'}} src={dta.imgurl} />
                      </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                      <div className=''>
                        <p style={{ fontSize :  12 , marginBottom : 5 }} className='cartoone'>Refundable Deposit</p>
                        <p style={{ fontSize :  12 }} className='cartotwo'>₹ { active === true ? deposit : dta.totalDeposit }{dta.proRentType === 'Monthly' ? '/M' : dta.proRentType === 'Weekly' ? '/W' : '/D' }</p>
                      </div>
                      <div className=''>
                        <p style={{ fontSize :  12 , marginBottom : 5 }} className='cartoone'>Rental Price</p>
                        <p style={{ fontSize :  12 }} className='cartotwo'>₹  {active ===true ?rent : dta.proTotalAmnt}{dta.proRentType === 'Monthly' ? '/M' : dta.proRentType === 'Weekly' ? '/W' : '/D' }</p>
                      </div>
                     
                    </div>
                    <div className='d-flex justify-content-between' >
                      <div className='' >
                      <p style={{ fontSize :  12 , marginBottom : 5 }} className='cartoone' >{dta.proRentType === 'Monthly' ? 'Month ' : dta.proRentType === 'Weekly' ? 'Week ' : 'Day '}Count</p>
                      <p className='cartotwo' >{dta.monthCount}</p>
                      </div>
                      <div className=''>
                        <div style={{ width : 130 }} className='cart_cont'>
                          <div className='row' style={{paddingTop:'3px'}}>
                            <div className='col-4'>
                              {
                                qty === 1 || dta.proQty === 1 ? '':
                                <div onClick={()=>minuss(dta,key)}  style={{marginLeft:'5px'}}>
                                  <img style={{margin:'auto' , paddingTop:'14px'}} src={minus} />
                                </div>
                              }
                              
                            </div>
                            <div className='col-4'>
                              <p style={{marginTop:'8px'}} className='cartotwo'>{active === true ? qty : dta.proQty}</p>
                            </div>
                            <div className='col-4'>
                              <div onClick={()=>plus(dta,key)} >
                                <img  style={{margin:'auto' , paddingTop:'5px' , display:'block'}} src={pl} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      {/* <div className='col-6'>
                        <div className='boot_conone'>
                          <div onClick={()=>savellist( dta , key )}  style={{marginTop:'8px'}} className='d-flex'>
                            <img style={{marginLeft:'auto'}} src={savelist} />
                            <p className='boot_conthree'>Save for later</p>
                          </div>
                        </div>
                      </div> */}
                      <div className='col-12'>
                        <div  onClick={()=>remove(dta , key)} className='boot_contwo'>
                            <p className='boot_confour'>Remove from cart</p>
                        </div>
                      </div>
                    </div> 
                  </div>
              )
        })
      } 

      
      { datas && (
      <>
        {
          removes === true ?<div className='cart_bynn'>
          <div onClick={()=>Checkout()} className='d-flex justify-content-center'>
            <p className='boot_seven '>Proceed to Checkout</p>
            <img className='crtbtimg' src={aright} />
          </div>
        </div>
        :<p style={{textAlign : 'center' , fontSize : '15px' , fontWeight : '600'}} >Cart Is Empty</p>
        }
          
      </>
      )
      }

      {!datas && (
          <>
            <p  style={{textAlign : 'center' , fontSize : '15px' , fontWeight : '600'}}>Cart Is Empty</p>
          </>
        )
      }

      {/* <div className='d-flex justify-content-between'>
          <p style={{marginLeft:'16px'}} className=''>Saved for later (1 item)</p>
          <img onClick={()=>showsave()} className = { shoee === true ? 'showsave' : ''} style={{width:'14px',height:'11px', marginRight:'26px' , cursor : 'pointer'}} src={down} />
      </div> */}

      {(() => {
          if(shoee === true){

          if(wish != null){
          
          if(wish.length > 0){
              return ( 
                  wish.map((dta,key)=>{
                      console.log(dta,'ehwwjkfejh4iu3jj3ciu44bntfg43')
                  return(
                    <div className='mob_cart_cont coool8 mt-3'>
                    <div className='row'>
                      <div className='col-7'>
                          <p className='cartpara1'>{dta.datas.brand}</p>
                      </div>
                      <div className='col-5'>
                        <img className='cart_img' style={{width:'100%'}} src={dta.datas.imgurl} />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-3'>
                        <p className='cartoone'>Deposit</p>
                        <p className='cartotwo'>₹ { active === true ? deposit : dta.datas.totalDeposit }{dta.datas.proRentType === 'Monthly' ? '/M' : dta.datas.proRentType === 'Weekly' ? '/W' : '/D' }</p>
                      </div>
                      <div className='col-4'>
                        <p className='cartoone'>Rent</p>
                        <p className='cartotwo'>₹  {active ===true ?rent : dta.datas.proTotalAmnt}{dta.datas.proRentType === 'Monthly' ? '/M' : dta.datas.proRentType === 'Weekly' ? '/W' : '/D' }</p>
                      </div>

                    </div>
                    <div className='row'>
                      <div className='col-6'>
                        <div className='boot_conone'>
                          <div onClick={()=>movetocarts( dta , key )}  style={{marginTop:'8px'}} className='d-flex'>
                            <img style={{marginLeft:'auto'}} src={savelist} />
                            <p className='boot_conthree'>Move To Cart</p>
                          </div>
                        </div>
                      </div>
                      <div className='col-6'>
                        <div  onClick={()=>removess(dta , key)} className='boot_contwo'>
                            <p className='boot_confour'>Remove from cart</p>
                        </div>
                      </div>
                    </div> 
                  </div>
                  )
                      
              })
              )
              }else{
                  return (<></>)
              }
          }
          else{
              return ( <></>)
          }
      }
      })()}

          <ChakraProvider >
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Out Of Stock</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <p>{message?message : ''}</p>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
          </ChakraProvider>
        <ToastContainer />
    </>
  )
}