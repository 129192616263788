import React from 'react'
import Running_order_comp from '../../components/mobile_view_comp/running_order/running_order_comp'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'
import Running_foot from '../../components/mobile_view_comp/running_order/running_foot'

export default function Running_order() {
  return ( 
    <>
        <div style={{width:'90%' , margin:'auto' ,marginTop:'65px' , marginBottom:'60px'}}>
            <Cart_one_head link='/order' head='Running Orders' />
            <Running_order_comp />
            {/* <Running_foot /> */}
        </div>
    </>
  )
}