import React from 'react'
import Home_mobile from '../../components/mobile_view_comp/home_mobile'
import Mobile_head from './common/mobile_head'
import Mobile_foot from './common/mobile_foot'
import Slider_mob from '../../slider_mo/slider_mob'
import Mbl_product_groub from '../../components/mobile_view_comp/mbl_product_groub'
import Popular_product from '../../components/mobile_view_comp/popular_product'
import Offer from '../../components/mobile_view_comp/offer'
import Home_container from '../../components/mobile_view_comp/home_container'
import Heading_comp from '../../components/mobile_view_comp/heading_comp'
import Three_row from '../../components/mobile_view_comp/three_row'
import ggoo from '../../assets/img/get_playstore.png'
import Switchss from '../../components/fullwidth/switch'
import Windows_height_width from '../../components/windows_height_width'

import { BsChatDotsFill } from 'react-icons/bs';
import { Link } from 'react-router-dom'

export default function Mobile_home() {

  const { height, width } = Windows_height_width();
  let token = localStorage.getItem('user_data')


  return (
    <>
      <div className='container' style={{ margin: 'auto', paddingBottom: '100px', overflowX: 'hidden', position: 'relative' }}>
        <Mobile_head />
        <Home_mobile />
        <Mobile_foot />
        <Slider_mob />
        <a href='https://play.google.com/store/apps/details?id=com.rentla.rentla'>
          <img style={{ margin: 'auto', width: '150px', paddingTop: '10px' }} src={ggoo} />
        </a>
        <Heading_comp text='Explore Products' />
        <Mbl_product_groub />
        <Popular_product />
        <Offer />
        <Three_row />
        {/* <div style={{ position : 'fixed' , zIndex : 1000 , top : 600 , right : 12 }} >
          <Switchss />
        </div> */}
        <Home_container />

        <div style={{ position: 'fixed', bottom: '100px', right: '25px', zIndex: '1' }}>
          <Link to={token ? '/support':'/login'}>
            <BsChatDotsFill size={32} color="orange" />
          </Link>
        </div> 

      </div>
    </>
  )
}