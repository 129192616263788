import React , { useEffect , useState } from 'react'
import { Nav, NavItem} from 'reactstrap';
import { useNavigate , useLocation } from 'react-router-dom';

//icons
import { ReactComponent as UserIcon } from '../../../assets/icon/c.svg'
import { ReactComponent as CartIcon } from '../../../assets/icon/v.svg'
import { ReactComponent as HomeIcon } from '../../../assets/icon/m.svg'
import { ReactComponent as PrductsIcon } from '../../../assets/icon/b.svg'
import { ReactComponent as SearchIcon } from '../../../assets/icon/n.svg'

import { ReactComponent as home1svg } from '../../../assets/icon/home1svg.svg'
import { ReactComponent as home2svg } from '../../../assets/icon/home2svg.svg'
import { ReactComponent as home3svg } from '../../../assets/icon/home3svg.svg'
import { ReactComponent as home4svg } from '../../../assets/icon/home4svg.svg'
import { ReactComponent as home5svg } from '../../../assets/icon/home5svg.svg'

//dimension
import Windows_height_width from '../../../components/windows_height_width'
import { useSelector } from 'react-redux'

export default function Mobile_foot() {

  let navigate = useNavigate()
  const location = useLocation();


  const [ noo , setNo ] = useState(0)

  const [ navs , setnavvv ] =  useState()

  const product = useSelector((state)=>state.cartcount)
  

  useEffect(()=>{
    
    //
    let wishss = localStorage.getItem('data')


    if ( wishss === null ){ 
        setNo(0) 
        return
    }else if(wishss === '[]'){
        setNo(0)
    }
    let hii = JSON.parse(wishss)

    setNo(hii.length)
},[])

useEffect(()=>{
  //

  console.log(location.pathname , 'location.pathname')

  if( location.pathname === '/' ){
    localStorage.setItem('nav' , 0)
    setnavvv(0)
    return
  }else if( location.pathname === '/search' ){
    localStorage.setItem('nav' , 1)
    setnavvv(1)
    return
  }else if( location.pathname === '/cart' ){
    localStorage.setItem('nav' , 3)
    setnavvv(3)
    return
  }else if( location.pathname === '/profile' ){
    localStorage.setItem('nav' , 4)
    setnavvv(4)
    return
  }else {
    localStorage.setItem('nav' , 2)
    setnavvv(2)
    return
  }


  let navv = localStorage.getItem('nav')


  if ( navv === null || navv === '' || navv === undefined ){ 
    setnavvv(0) 
      return
  }else {
    setnavvv(parseInt(navv))
  }
},[])

useEffect(()=>{
  console.log(product , 'product')
  if(product.products.length !=0){
      setNo(product.products.length)
  }else{
    let wishss = localStorage.getItem('data')


    if ( wishss === null ){ 
        setNo(0)
        return
    }else if(wishss === '[]'){
        setNo(0)
    }
    let hii = JSON.parse(wishss)

    setNo(hii.length)
  }
},[product])


  let user = localStorage.getItem('user_data') 

    const tabs = [{
        route: "/",
        icon: navs === 0 ? HomeIcon : home1svg ,
        label: "Home",
        in :0
      },{
        route: "/search",
        icon: navs === 1 ? home2svg : SearchIcon,
        label: "Search",
        in : 1
      },{
        route: "/products/Laptop/3/All/0",
        icon: navs === 2 ? PrductsIcon : home3svg,
        label: "Products",
        in : 2
      },{
        route: "/cart",
        icon: navs === 3 ? CartIcon :  home4svg,
        label: "Cart",
        in : 3
      },{
        route: user === null ?  "/login" : '/profile',
        icon: navs === 4 ? UserIcon : home5svg ,
        label: user === null ? "Login" : 'Profile',
        in : 4
      }]

  const { height, width } = Windows_height_width();
  
  return (
    <>
      <nav className="navbar fixed-bottom navbar-light booot bottom-tab-nav" role="navigation">
        <Nav className="w-100">
          <div className=" d-flex flex-row justify-content-around w-100">
            {
              tabs.map((tab, index) =>{ 
                  return(
                      <>
                          {/* <div className='bo'></div> */}
                          <NavItem key={`tab-${index}`}>
                            <div onClick={()=>{ 
                              localStorage.setItem('nav', tab.in)
                              navigate(tab.route)
                             }} to={tab.route} className="nav-link bottom-nav-link" >
                              <div className="row d-flex flex-column justify-content-center align-items-center">
                                {
                                  navs === tab.in  ? 
                                    <div style={{ width : '100%' , height : 5 , backgroundColor : '#F4B644' , borderBottomLeftRadius : 6 , borderBottomRightRadius : 6 , marginTop : -17 , marginBottom : 18 }} ></div>
                                  : 
                                    <div style={{ width : '100%' , height : 5 , borderBottomLeftRadius : 6 , borderBottomRightRadius : 6 , marginTop : -17 , marginBottom : 18 }} ></div>
                                }
                                <tab.icon />
                                {
                                  tab.label === 'Cart' ?   
                                    <span style={{ marginLeft : 26 , width : 22 , top : 10 }} class='badge badge-warning' id='lblCartCount'>{noo}</span> : ''
                                }
                                {
                                  tab.in === navs ? <div className='loabb bottom-tab-label'>{tab.label}</div> : <div style={{ color : '#fff' }} className='loabb bottom-tab-label'>.</div>
                                }
                                
                              </div>
                            </div>
                          </NavItem>
                      </>
                )  })
            }
          </div>
        </Nav>
      </nav>
    </>
  )
}