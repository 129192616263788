import React from 'react'
import Checklistcomp from '../../components/mobile_view_comp/checklist/checklistcomp'
import Checklist_head from '../../components/mobile_view_comp/checklist/checklist_head'

export default function Check_list() {
  return (
    <>
      <div style={{width:'90%' , margin:'auto' ,marginTop:'65px' , marginBottom:'30px'}}>
          <Checklist_head link='/order' head='Checklist' />
          <Checklistcomp />
      </div>
    </>
  )
}