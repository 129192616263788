import React, { useState, useEffect, useRef } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Link } from 'react-router-dom';
import leftar from '../assets/img/leftar.png';
import rightar from '../assets/img/rightar.png';
import '../assets/css/product.css';
import {Row} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { allProduct } from '../redux/actions/productAction';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Base_Url } from './globalvariable/globe';
import Skeleton from 'react-loading-skeleton' 

export default function Threeimage(props) {
  const [cat, setCat] = useState();
  const carousel = useRef(null);
  const responsive = {
    0: { items: 2 },
    868: { items: 3 },
    968: { items: 3 },
    1100: { items: 4 },
    1300: { items: 5 },
    1321: { items: 5 },
    1440: { items: 6 },
    1650: { items: 7 },
    1850: { items: 8 },
  };
  const handlePrevClick = () => {
    if (carousel.current) {
      carousel.current.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (carousel.current) {
      carousel.current.slideNext();
    }
  };

  let dispatch = useDispatch()
  let navigate = useNavigate()


  const errnotify = (dat) => toast.error(dat, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
  });


  
  const clicked = async (data,key) =>{
    console.log(data)
    setCat(data.CatId)
    let responce = await axios({
        method : 'post',
        url : `${Base_Url}/products`,
        data :{
            catId : cat,
            brandId : 0
        }
    })
    .then((res)=>{
        let one = async () =>{
            dispatch(allProduct(responce.data.Data))
        } 
        one()
        navigate('/products/' + data.catName + '/' + data.catId + '/' + 'All' + '/'+0)
    })
    .catch((err)=>{
        let er = 'something Went Wrong'
        errnotify(er)
        console.log(err)
    }) 
}

const clickedlist = async (data,key,dat) =>{
    console.log(data,key,dat)
    // return
    setCat(data.CatId)
    let responce = await axios({
        method : 'post',
        url : `${Base_Url}/products`,
        data :{
            catId : cat,
            brandId : dat.InventoryCatId
        }
    })
    .then((res)=>{
        let one = async () =>{
            dispatch(allProduct(responce.data.Data))
        } 
        one()
        navigate('/products/' + data.catName + '/' + data.catId+'/'+dat.InventoryCatName + '/' + dat.InventoryCatId)
    })
    .catch((err)=>{
        let er = 'something Went Wrong'
        errnotify(er)
        console.log(err)
    })
}
  return (
    <>
   <Row>
  <div className="collections-area mt-20">
    <div className="container web_cat_five">
      <div className="">
        <div className="d-flex justify-content-between">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="26" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
          </svg>
          <img src={leftar} alt="Previous" width="20px" height="20px" onClick={handlePrevClick} className="arrow " style={{ marginTop: '85px' }} />
          <AliceCarousel
            ref={carousel}
            disableButtonsControls
            infinite
            mouseTracking
            disableDotsControls
            responsive={responsive}
          >
            {props.data?.map((datas, keys) =>
              keys > 24 ? '' : (
                <div key={keys} style={{ zIndex: '4' }}>
                  <div className="">
                    <img onClick={() => clicked(datas, keys)} className="thr_two" src={`${props.urls}/${datas.catImage}`} alt={datas.catName} />
                    <div className="main-menu">
                      <nav>
                        <ul>
                          <li>
                            <Link to="">{datas.catName}</Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              )
            )}
          </AliceCarousel>
          <img src={rightar} alt="Next" width="20px" height="20px" onClick={handleNextClick} className="arrow " style={{ marginTop: '85px' }} />
        </div>
      </div>
    </div>
  </div>
</Row>

      
    </>
  );
}
