import React , { useState , useEffect } from 'react'
import { Link ,useNavigate , useParams } from 'react-router-dom'
import './handover_pro_detail_web.css'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    ChakraProvider,
    useDisclosure
} from '@chakra-ui/react' 
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Base_Url , ImageUrl } from '../../globalvariable/globe';
import Header from '../../../common/head_foot/header_two';
import Footer from '../../../common/footer';
import Profile_home_nav from '../../../page/profile_home/profile_home_nav';
import ManageAddress from '../../address/address';

export default function Handover_pro_detail_web() {

    
    const errnotify = (err) => toast.error( err , {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    let navigate  = useNavigate()

    let { id  } = useParams()
    const [ otp , setOtp ] = useState()

    const { isOpen, onOpen, onClose } = useDisclosure()

    const [ all , setAll ] = useState()

    const [ addressid, setAddressid ] = useState()

    const [ sw , setSw ] = useState(0)

    let user = localStorage.getItem('user_data')

    const allData = async() =>{
        if(user === null){
            navigate('/')
            return
          }
        let res = await axios({
            method : 'post',
            url :  `${Base_Url}/listProductHandover`,
            headers: { 
                'Authorization': `Bearer ${user}` 
            },
            data : {
                orderId : id
            }
        })
        .then((res)=>{
            setAll(res.data.Data)
            setAddressid(res.data.Data.addresss[0].UserDelivAddrId)
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    useEffect(()=>{
        allData()
    },[])

    const back = ()=>{
        navigate(-1)
    }

    const switch_data = (data,key) =>{
        setAddressid(data.UserDelivAddrId)
        setSw(key)
    }

    const clickked = async () =>{
        let res = await axios({
            method : 'post' ,
            url : `${Base_Url}/handoverGenerateOtp`,
            headers: { 
                'Authorization': `Bearer ${user}` 
            },
            data : {
                orderId : id,
                addressId : addressid
            }
        })
        .then((res)=>{
            if(res.data.status === true){
                onOpen()
                setOtp(res.data.Data)
            }else{
                errnotify('Something Went Wrong')
            }
        })
        .catch((err)=>{
            errnotify('Something Went Wrong')
            console.log(err)
        })
    }

  return (
      <>
        <Header />
        <div className='pro_home_one  mt-150 mb-150'>
            <div className='row' >
                
                <Profile_home_nav />

                <div className='col-8'>
                    <div className='pro_home_fifteen'>
                        <div className='d-flex'>
                        <p className='pro_home_eight'>handover Request</p>
                        </div>
                    </div>
                    <div className='pro_home_seven mt-3'>
                    <p className='four_veri_six'>Items ({all?.items})</p>

                        {all?.productDetails.map((datas,key)=>
                        <div className='four_veri_five mt-3' style={{ height : 'auto' }} >
                            <div className='row'>
                                <div className='col-5'>
                                    <p className='qr_sighteenen'>Serial No</p>
                                </div>
                                <div className='col-7'>
                                    <p className='qr_sighteenen'>: {datas.CartDetailInventoryProSerialNo}</p>
                                </div>
                                <div className='col-5'>
                                    <p style={{marginTop:'-5px'}} className='qr_sighteenen'>Product ID</p>
                                </div>
                                <div className='col-7'>
                                    <p style={{marginTop:'-5px'}} className='qr_sighteenen'>: {datas.CartDetailId}</p>
                                </div>
                                <div className='col-5'>
                                    <p style={{marginTop:'-5px'}} className='qr_sighteenen'>Subscribtion Period</p>
                                </div>
                                <div className='col-7'>
                                    <p style={{marginTop:'-5px'}} className='qr_sighteenen'>: {datas.CartProBookFrom} - {datas.CartProBookTo}</p>
                                </div>
                                <div className='col-5'>
                                    <p style={{marginTop:'-5px'}} className='qr_sighteenen'>Days Left</p>
                                </div>
                                <div className='col-7'>
                                    <p style={{marginTop:'-5px'}} className='qr_sighteenen'>: {datas.remainingDays}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-7'>
                                    <p className='hand_one mt-1'>{datas.BrandName}</p>
                                    <p style={{marginTop:'0px'}} className='hand_two'>{datas.ProName}</p>
                                </div>
                                <div className='col-5'>
                                    <img className='mt-3' src={ImageUrl+'/'+datas.ProImgPrimary} />
                                </div>
                            </div>
                        </div>
                        )}

                <div className='d-flex justify-content-between'>
                    <p className='hand_four mt-4'>Pick up</p>
                    <div>
                        <ManageAddress />
                    </div>
                </div>

                { all?.addresss.map((data,key)=>{
                            return(
                            <div onClick={()=>switch_data(data,key)}  >
                                <div className={(sw===key? 'web_cart_col_mone mt-3' : 'web_cart_col_mfive mt-3')}>
                                    <div className='row'>
                                        <div className='col-10'>
                                            <p className='web_cart_col_mtwo'>{data.UserDelivAddrFName}</p>
                                            <p className='web_cart_col_mthree'>{data.UserDelivAddrAddress}</p>
                                        </div>
                                        <div className='col-2'>
                                            <input style={{marginTop : '29px'}} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={(sw===key ? 'checked' : '')} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                        })
                        }


                <div className=''>
                    <div onClick={()=>clickked()} className='qr_sixteen_hands'>
                        <p className='qr_sixteens'>Handover</p>
                    </div>
                </div>
                <ChakraProvider>
                <Modal
                    isCentered isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay
                        bg='blackAlpha.300'
                        backdropFilter='blur(10px) hue-rotate(90deg)'
                        />
                        <ModalContent>
                            <ModalHeader>Verification</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <p>Please Share the 4 digit Verification code to the delivery agent</p>
                            <h4 style={{ textAlign:'center' , letterSpacing : '10px' , fontSize : '30px' }} className='mt-4'>{otp?otp : ''}</h4>
                        </ModalBody>
                        <ModalFooter>

                        </ModalFooter>
                        </ModalContent>
                </Modal>
                </ChakraProvider>
                <ToastContainer />
                    </div>
                </div>
            </div>
        </div>
        <Footer />
      </>
  )
}
