import React from 'react'
import Cart_one_head from '../components/mobile_view_comp/cart_one/cart_one_head' 
import Circle_Four from '../components/mobile_view_comp/kyc_four.js/circle_four'
import Mob_kyc_four from '../components/mobile_view_comp/kyc_four.js/mob_kyc_four' 

export default function Kyc_four() {
  return (
    <> 
      <div style={{width:'90%' , margin:'auto' ,marginTop:'65px'}}>
        <Cart_one_head link='/kyc_three'  head='Kyc' />
        {/* <Circle_Four /> */}
        <Mob_kyc_four />
      </div>
    </>
  )
}