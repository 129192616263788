import React , { useState , useEffect } from 'react'
import Header from '../../../common/head_foot/header_two'
import Footer from '../../../common/footer'
import Profile_home_nav from '../../../page/profile_home/profile_home_nav'
import Timer from '../../mobile_view_comp/otpp/timer'
import { Link , useParams , useNavigate } from 'react-router-dom'
import axios from 'axios'
import './service_request_web.css'
import { Base_Url, ImageUrl } from '../../globalvariable/globe'
import { useDispatch } from 'react-redux'
import { LoogIn } from '../../../redux/actions/productAction'

export default function Service_request_web() {

    let navigate = useNavigate()

    let { id , type } = useParams()

    let dispatch = useDispatch()

    let token = localStorage.getItem('user_data')

    const [ alldata , setAlldata ] = useState()

    const start = async()=>{
        if(token === null){
            navigate('/')
            return
          }
        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/viewService`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            },
            data : {
                orderId : id ,
                type : type
            }
        })
        .then((res)=>{
            setAlldata(res.data.Data)
            console.log(res)
        })
        .then((err)=>{
            
            console.log(err)
        })
    }

    useEffect(()=>{
        start()
    },[])

  return (
        <>
            <Header />
                <div className='pro_home_one  mt-150 mb-150'>
                    <div className='row' >
                    <Profile_home_nav />
                        <div className='col-8'>
                            <div className='pro_home_fifteen'>
                                <div className='d-flex'>
                                <p className='pro_home_eight'>Service Request</p>
                                </div>
                            </div>
                            <div className='pro_home_seven mt-3'>
                                <div className='run_ord_one'> 
                                    <div className='row'>
                                        <div className='col-5'>
                                            <Timer time={172} />
                                        </div>
                                        <div className='col-7'>
                                        <p className='run_ord_two'>ID : {alldata?.orderDetails.orderId}</p>
                                        <p className='run_ord_threeb'>{alldata?.orderDetails.fromDate} - {alldata?.orderDetails.toDate}</p>
                                        </div>
                                    </div>
                                </div>
                                <p className='service_one my-3'>Order Contains</p>
                                {
                                alldata?.productDetails.map((datas,key)=>
                                    <div className='service_twob mt-3'>
                                        <div className='row' >
                                            <div className='col-6'>
                                                {
                                                    datas?.CartDetailInventoryProSerialNo === null ? '' : <p className='service_threeb'>No : {datas.CartDetailInventoryProSerialNo === null ? '' :datas.CartDetailInventoryProSerialNo }</p>
                                                }
                                            
                                            <p className='service_fourb'>{datas.ProName === null ? '' : datas.ProName }</p>
                                            </div>
                                            <div className='col-2'>
                                            <img className='service_five__teen' src={ ImageUrl +'/'+ datas.ProImgPrimary } />
                                            </div>
                                            <div className='col-4'>
                                            <Link to={(datas.RequestedForService=== 0 ? '/service_status/'+datas.CartDetailId+'/'+id+ '/' + 1 : datas.RequestedForService=== 2 ? '/serviced/'+datas.CartDetailId+'/'+id+'/'+ datas.CartDetailInventoryProSerialNo 
                                            : datas.RequestedForService=== 1 ? '/service_status/'+datas.CartDetailId+'/'+id + '/' + 0  :  '')} >
                                                <div className={(datas.RequestedForService=== 0 ? 'service_sixb' : datas.RequestedForService=== 1 ? 'service_sixsb' : 'service_sixsgreen')} > 
                                                <p className={(datas.RequestedForService===0 ? 'service_seven' : 'service_sevens')}>{(datas.RequestedForService=== 0 ? 'Service' : datas.RequestedForService=== 1 ? 'Requested' : 'Serviced')}</p>
                                                </div>
                                            </Link>
                                            </div> 
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </>
  )
}
