import React from 'react'
import rent from '../assets/img/rent.png'
import two_tag from '../assets/img/two_tag.png'
import gift_box from '../assets/img/gift_box.png'
import tw_4 from '../assets/img/24.png'
import flash from '../assets/img/flash.png'
import truck from '../assets/img/truck.png'
import box from '../assets/img/box.png'

export default function Product({ homeCard }) {

  const card = homeCard
  console.log(homeCard, "pro")


  return (
    <>
      <div className="mt-60 pb-50">
        <div className="">
          <div className="welcome-content text-center ">
            <p style={{ fontWeight: 500, fontSize: 24, color: '#666666' }}>Rentla</p>
            {homeCard !== "" ? (
              // <p className='mt-4'>{homeCard}</p>
              <p className='mt-4' style={{ paddingLeft: '216px', paddingRight: '216px' }} dangerouslySetInnerHTML={{ __html: homeCard }} />
            ) : (
              <p className='mt-4'>Rentla is an IT rental company that focuses on renting gadgets required for corporates, students, freelancers, and individuals who need IT support. We are promoting <br />renting gadgets to optimize the use of technological resources and provide a choice for the public when it comes to choosing their gadgets. We provide gadgets at <br />affordable costs along with maintenance, software upgradation, and doorstep delivery services.</p>

            )}
          </div>
        </div>
      </div>
      <div className="container  head_container mb-100">
        <div className='row '>
          <div className='col-xl-3 col-xxl-3 col-md-6 col-sm-12 col-lg-6 mt-3'>
            <div className='addclass'>
              <img style={{ margin: 'auto' }} src={box} />
              <p style={{ fontSize: 18, fontWeight: 600, color: '#000', textAlign: 'center', marginTop: 10 }} >Latest tech at lowest cost</p>
              <p style={{ fontSize: 12, fontWeight: 400, color: '#000', textAlign: 'center', marginTop: 20 }} >Be that one person in the room with all the latest gadgets. That too, without spending much.</p>
            </div>
          </div>
          <div className='col-xl-3 col-xxl-3 col-md-6 col-sm-12 col-lg-6 mt-3'>
            <div className='addclass'>
              <img style={{ margin: 'auto' }} src={flash} />
              <p style={{ fontSize: 18, fontWeight: 600, color: '#000', textAlign: 'center', marginTop: 10 }} >Instant solutions</p>
              <p style={{ fontSize: 12, fontWeight: 400, color: '#000', textAlign: 'center', marginTop: 20 }} >Get instant solutions for your urgent gadget needs by just choosing your favorite one.</p>
            </div>
          </div>
          <div className='col-xl-3 col-xxl-3 col-md-6 col-sm-12 col-lg-6 mt-3'>
            <div className='addclass'>
              <img style={{ margin: 'auto' }} src={truck} />
              <p style={{ fontSize: 18, fontWeight: 600, color: '#000', textAlign: 'center', marginTop: 10 }} >Doorstep delivery</p>
              <p style={{ fontSize: 12, fontWeight: 400, color: '#000', textAlign: 'center', marginTop: 20 }} >Just choose the product and get it delivered right to your doorstep.</p>
            </div>
          </div>
          <div className='col-xl-3 col-xxl-3 col-md-6 col-sm-12 col-lg-6 mt-3'>
            <div className='addclass'>
              <img style={{ margin: 'auto' }} src={tw_4} />
              <p style={{ fontSize: 18, fontWeight: 600, color: '#000', textAlign: 'center', marginTop: 10 }} >24x7 FREE support</p>
              <p style={{ fontSize: 12, fontWeight: 400, color: '#000', textAlign: 'center', marginTop: 20 }} >Get exclusive in-house support while renting multiple gadgets for your office.</p>
            </div>
          </div>
        </div>
      </div>
    </>
    // card.length > 0 ? <>
    //   <div className="mt-60 pb-50">
    //     <div className="">
    //       <div className="welcome-content text-center ">
    //         <p style={{ fontWeight: 500, fontSize: 24, color: '#666666' }}>Rentla</p>
    //         <p className='mt-4'>
    //           Rentla is an IT rental company that focuses on renting gadgets required for corporates, students, freelancers, and individuals who need IT support. We are promoting <br />renting gadgets to optimize the use of technological resources and provide a choice for the public when it comes to choosing their gadgets. We provide gadgets at <br />affordable costs along with maintenance, software upgradation, and doorstep delivery services.
    //           </p>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="container  head_container mb-100">
    //     <div className='row '>
    //       {card.map((val, ind) => {
    //         return (
    //           <div className='col-xl-3 col-xxl-3 col-md-6 col-sm-12 col-lg-6 mt-3'>
    //             <div className='addclass'>
    //               {/* <img style={{ margin: 'auto' }} src={box} /> */}
    //               <p dangerouslySetInnerHTML={{ __html: val.homecard }} />

    //             </div>
    //           </div>
    //         )

    //       })}

    //     </div>
    //   </div>
    // </> :
    //   <>
    //     <div className="mt-60 pb-50">
    //       <div className="">
    //         <div className="welcome-content text-center ">
    //           <p style={{ fontWeight: 500, fontSize: 24, color: '#666666' }}>Rentla</p>
    //           <p className='mt-4'>Rentla is an IT rental company that focuses on renting gadgets required for corporates, students, freelancers, and individuals who need IT support. We are promoting <br />renting gadgets to optimize the use of technological resources and provide a choice for the public when it comes to choosing their gadgets. We provide gadgets at <br />affordable costs along with maintenance, software upgradation, and doorstep delivery services.</p>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="container  head_container mb-100">
    //       <div className='row '>
    //         <div className='col-xl-3 col-xxl-3 col-md-6 col-sm-12 col-lg-6 mt-3'>
    //           <div className='addclass'>
    //             <img style={{ margin: 'auto' }} src={box} />
    //             <p style={{ fontSize: 18, fontWeight: 600, color: '#000', textAlign: 'center', marginTop: 10 }} >Latest tech at lowest cost</p>
    //             <p style={{ fontSize: 12, fontWeight: 400, color: '#000', textAlign: 'center', marginTop: 20 }} >Be that one person in the room with all the latest gadgets. That too, without spending much.</p>
    //           </div>
    //         </div>
    //         <div className='col-xl-3 col-xxl-3 col-md-6 col-sm-12 col-lg-6 mt-3'>
    //           <div className='addclass'>
    //             <img style={{ margin: 'auto' }} src={flash} />
    //             <p style={{ fontSize: 18, fontWeight: 600, color: '#000', textAlign: 'center', marginTop: 10 }} >Instant solutions</p>
    //             <p style={{ fontSize: 12, fontWeight: 400, color: '#000', textAlign: 'center', marginTop: 20 }} >Get instant solutions for your urgent gadget needs by just choosing your favorite one.</p>
    //           </div>
    //         </div>
    //         <div className='col-xl-3 col-xxl-3 col-md-6 col-sm-12 col-lg-6 mt-3'>
    //           <div className='addclass'>
    //             <img style={{ margin: 'auto' }} src={truck} />
    //             <p style={{ fontSize: 18, fontWeight: 600, color: '#000', textAlign: 'center', marginTop: 10 }} >Doorstep delivery</p>
    //             <p style={{ fontSize: 12, fontWeight: 400, color: '#000', textAlign: 'center', marginTop: 20 }} >Just choose the product and get it delivered right to your doorstep.</p>
    //           </div>
    //         </div>
    //         <div className='col-xl-3 col-xxl-3 col-md-6 col-sm-12 col-lg-6 mt-3'>
    //           <div className='addclass'>
    //             <img style={{ margin: 'auto' }} src={tw_4} />
    //             <p style={{ fontSize: 18, fontWeight: 600, color: '#000', textAlign: 'center', marginTop: 10 }} >24x7 FREE support</p>
    //             <p style={{ fontSize: 12, fontWeight: 400, color: '#000', textAlign: 'center', marginTop: 20 }} >Get exclusive in-house support while renting multiple gadgets for your office.</p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </>
  )
}