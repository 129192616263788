import React from 'react'
import './home_style.css'
//dimension
import logo from '../../../assets/logo.png' 
import { ReactComponent as GpsIcom } from '../../../assets/icon/gps.svg'
import { Link } from 'react-router-dom';

export default function Mobile_head() {

    return (
            <>
                <div className=" fixed-top navbar-light tooot top-tab-nav">
                    <div className="d-flex justify-content-between m-auto paddd" >
                        <div  className='loab'>
                            <Link to='/'>
                                <img crossorigin="anonymous" className='imglogo' src={logo} />
                            </Link>
                        </div>
                        <div style={{textAlign:'right'}} className='loab'>
                            {/* <div className='d-flex'>
                                <div className='cityname pt-1'>Chennai</div>
                                <div className='gbs'><GpsIcom /></div> 
                            </div> */}
                        </div>
                    </div>
                </div>
            </>
        )
    }