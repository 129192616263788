import React, { useState, useEffect } from 'react'
import Vector from '../../../assets/img/Vector.png'
import Mob_pro_hign from './mob_pro_hign'
import tworight from '../../../assets/img/tworight.png'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import kart from '../../../assets/img/kart.png'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import ReactLoading from 'react-loading';
import Modals from 'react-modal';
import aright from '../../../assets/img/aright.png'
import wrong from '../../../assets/img/wrong.png'
import { Base_Url } from '../../globalvariable/globe'
import { ToastContainer, toast } from 'react-toastify';
import { selectedRemoveProduct, Wishcount, Cartcount } from '../../../redux/actions/productAction'
import { ImageUrl } from '../../globalvariable/globe'
import tik from '../../../assets/img/tik.png'
import { Select, ChakraProvider, NumberInputField, NumberInput, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, ModalContent, ModalOverlay, ModalCloseButton, useDisclosure } from '@chakra-ui/react'
import DatePicker from 'react-date-picker';
import imggg from '../../../assets/img/imggg.png'
import { useParams } from 'react-router-dom'
import Html from 'html-react-parser'
import moment from 'moment/moment'
import { Button, Form, Modal } from 'react-bootstrap'
import { useCookies } from 'react-cookie'

export default function Mob_pro_data() {

    let wishss = localStorage.getItem('wish_list')
    let typw = localStorage.getItem('user_type')

    let nre = localStorage.getItem('eye')

    const { id } = useParams()
    const customStyles = {
        content: {
            top: '40%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    let subtitles;
    const [modalIsOpens, setIsOpens] = useState(false);

    function openModals() {
        setIsOpens(true);
    }

    function afterOpenModals() {
        // references are now sync'd and can be accessed.
        subtitles.style.color = '#f00';
    }

    function closeModals() {
        setIsOpens(false);
    }


    const login = () => {
        navigate('/login')
    }
    let dispatch = useDispatch()

    let navigate = useNavigate()

    const [keys, setKeys] = useState(0)

    const product = useSelector((state) => state.oneproduct)
    const imgs = product.productImage
    const datas = product.productVariants
    const datass = product.productDetails
    const veri = product.productVariants
    const rent = product.BookingType

    const didunow = product.didYouKnow

    const OverlayOne = () => (

        <ModalOverlay />
    )
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [overlay, setOverlay] = React.useState(<OverlayOne />)
    const readmorefn = () => {
        onOpen()
    }

    const awssu = product.awsbaseurl

    const [amount, setAmount] = useState()
    const [dummy, setDummy] = useState()
    const [sta, setSta] = useState(true)

    const [loading, setLoading] = useState(false)
    const [qty, setQty] = useState(1)
    const [rents, setRent] = useState('')
    const [proveri, setProveri] = useState()
    const [proveriamount, setProveriamount] = useState()
    const [btndata, setBtnData] = useState('Add To Cart')
    const [nav, setnavi] = useState(false)
    const [monthCount, setMonthCount] = useState(1)

    const [rentType, setRentTrpe] = useState('Monthly')

    const [clicolor, setClicolor] = useState(true)

    const [depamount, setDepamount] = useState()
    const [twodummy, setTwodummy] = useState(0)
    const [send_dat, setSend_dat] = useState()
    const [varid, setVarid] = useState()
    let final = 0
    const [showEnqModal, setShowEnqModal] = useState(false)
    console.log(showEnqModal)
    const succnotify = () => toast.success('Product added Successfully', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const [wish, setWish] = useState()
    const [onees, setOnees] = useState(true)

    const [countss, setCountss] = useState(1)

    useEffect(() => {
        console.log('adkldfjkbsdfbwejkbksdfjbbjkjk')
        wishss = localStorage.getItem('wish_list')
        setWish(JSON.parse(wishss))
    }, [countss])

    useEffect(() => {


        // var config = {
        //     method: 'get',
        //     url: 'https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJN1t_tDeuEmsRUsoyG83frY4&fields=name%2Crating%2Cformatted_phone_number&key=YOUR_API_KEY',
        //     headers: { }
        //   };

        //   axios(config)
        //   .then(function (response) {
        //     console.log(JSON.stringify(response.data),'gooooooogleee');
        //   })
        //   .catch(function (error) {
        //     console.log(error);
        //   });



        wishss = localStorage.getItem('wish_list')
        setWish(JSON.parse(wishss))
        dispatch(selectedRemoveProduct())
    }, [])

    const [cookies, setCookie] = useCookies(["usercat"]);

    let dateC = new Date();
    dateC.setDate(dateC.getDate() + 1)
    let expires = "; expires=" + dateC.toUTCString();

    let urlParamsObject = new URLSearchParams(window.location.search);
    let affiliateId = urlParamsObject.get('cat');

    if (affiliateId) {
        setCookie("usercat", `${affiliateId}${id}`, { expires: dateC });
        // console.log("parmss", cookies)

    }
    else {

    }
    const sendCookie = affiliateId ? cookies.usercat : null
    const requiredFields = ['name', 'phoneNumber', 'email'];
    const [enqDetails, setEnqDetails] = useState({
        name: '',
        phoneNumber: '',
        productId: id,
        email: '',
        address: '',
        detail: '',
        cookies: sendCookie
    })

    const [validationErrors, setValidationErrors] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        address: '',
        detail: ''
    });

    const handleInputChange = (e, fieldName) => {
        setEnqDetails({
            ...enqDetails,
            [fieldName]: e.target.value
        });
        setValidationErrors({
            ...validationErrors,
            [fieldName]: e.target.value === '' ? `Please enter your ${fieldName}.` : ''
        });

    };

    const handleSubmit = async () => {
        // Check if any field is empty
        const errors = {};
        requiredFields.forEach(field => {
            if (enqDetails.phoneNumber.trim().length > 0 && enqDetails.phoneNumber.trim().length !== 10) {
                errors.phoneNumber = 'Phone number should be 10 digits.';
            }
            if (enqDetails[field] === '') {
                errors[field] = `Please enter your ${field}.`;
            }

        });

        setValidationErrors(errors);

        // Proceed with form submission if there are no errors
        if (Object.keys(errors).length === 0) {

            setShowEnqModal(false); // Close modal after submission
            let res = await axios.post(`${Base_Url}/submit_enquiry`, enqDetails)
            // console.log(res, "ress")
            if (res.data.status == true) {
                toast.success('Successfully Submitted', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            else {
                toast.error(res.data.Error_Message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }


        }
    };

    const Clickheart = (data, key) => {

        setCountss(countss + 1)
        let allata = localStorage.getItem('wish_list')
        let another = JSON.parse(allata)
        let final = 0
        let ProRentAmt = { "ProRentAmt": dummy }
        let ProImgName = { "ProImgPrimary": imgs[0].ProImgName }
        data = { ...data, ...ProRentAmt }
        data = { ...data, ...ProImgName }
        let wish_data = [
            {
                datas: data
            }
        ]

        if (localStorage.getItem('wish_list')) {
            another.forEach((element, index) => {
                console.log(index, 'index')
                if (element.datas.ProId === wish_data[0].datas.ProId) {
                    let allanother = another
                    allanother.splice(index, 1)
                    localStorage.setItem('wish_list', JSON.stringify(allanother))
                    dispatch(Wishcount(allanother))
                    final = 1
                }
            })
            console.log(final)
            if (final === 0) {
                console.log('susssssss')
                let allanother = another.concat(wish_data)
                localStorage.setItem('wish_list', JSON.stringify(allanother))
                dispatch(Wishcount(allanother))
            }

        } else {
            localStorage.setItem('wish_list', JSON.stringify(wish_data))
            dispatch(Wishcount(wish_data))
        }
        // let wish_list = localStorage.getItem('wish_list')

        // const All = async () => {
        //     let responce = await axios({
        //         method : 'post',
        //         url : `${Base_Url}/wishlist`,
        //         headers: { 
        //             'Authorization': `Bearer ${user}` 
        //         },
        //         data : {
        //             proId : data.ProId
        //         }
        //     })
        //     .then ((res)=>{
        //     })
        // }

        // All()

    }

    useEffect(() => {
        if (datas) {
            setVarid(datass[0].ProSpecificId)
            setDepamount(typw === 'corporate' ? 0 : datass[0].ProDepositAmt)
            setSend_dat(datas[0].ProSpecificMonthly)
            setDummy(datas[0].ProSpecificMonthly)
        }
    }, [datas]);

    const [value, onChange] = useState(new Date());
    const date = new Date();
    date.setDate(date.getDate() + (monthCount * 30));
    const [value2, onChange2] = useState(date);


    const press = (dat, key) => {
        setVarid(dat.ProSpecificId)
        console.log(key, 'key')
        setSta(false)
        setKeys(key)
        setTwodummy(key)
        if (datas[0].ProSpecificId === dat.ProSpecificId) {
            if (rents === 'Monthly') {

                setSend_dat(datas[0].ProSpecificMonthly)
                setAmount((datas[key].ProSpecificMonthly * qty) * monthCount)
                console.log(send_dat, 'month')
            } else if (rents === 'Weekly') {
                setSend_dat(datas[0].ProSpecificWeekly)
                setAmount((datas[key].ProSpecificWeekly * qty) * monthCount)
                console.log(send_dat, 'week')
            } else if (rents === 'Daily') {
                setSend_dat(datas[0].ProSpecificDaily)
                setAmount((datas[key].ProSpecificDaily * qty) * monthCount)
                console.log(send_dat, 'day')
            }
        } else {

            if (rents === 'Monthly') {
                setVarid(datas[key].ProSpecificId)
                setSend_dat(dat.ProSpecificMonthly)
                setAmount((datas[key].ProSpecificMonthly * qty) * monthCount)
                console.log(send_dat, 'month')
            } else if (rents === 'Weekly') {
                setVarid(datas[key].ProSpecificId)
                setSend_dat(dat.ProSpecificWeekly)
                setAmount((datas[key].ProSpecificWeekly * qty) * monthCount)
                console.log(send_dat, 'week')
            } else if (rents === 'Daily') {
                setVarid(datas[key].ProSpecificId)
                setSend_dat(dat.ProSpecificDaily)
                setAmount((datas[key].ProSpecificDaily * qty) * monthCount)
                console.log(send_dat, 'day')
            }
        }
    }

    useEffect(() => {
        setRent(rent ? rent[0].RentTypeName : '')
    }, [rent])

    useEffect(() => {
        setProveri(veri ? veri[0].ProAttrOptAttrId : '')
        setProveriamount(veri ? veri[0].ProAttrPrice : '')
    }, [veri])

    useEffect(() => {
        console.log(value, 'value')
    }, [value])

    //calculation
    useEffect(() => {
        if (qty === 1 && sta === true) {
            return
        }
        // if(sta === false ){
        //     setAmount(twodummy * qty)
        //     return
        // }
        setSta(false)
        if (typw === 'corporate') {

        } else {
            setDepamount(datass[0].ProDepositAmt * qty)
        }
        if (rents === 'Monthly') {
            setAmount((datas[twodummy].ProSpecificMonthly * qty) * monthCount)
        } else if (rents === 'Weekly') {
            setAmount((datas[twodummy].ProSpecificWeekly * qty) * monthCount)
        } else if (rents === 'Daily') {
            setAmount((datas[twodummy].ProSpecificDaily * qty) * monthCount)
        }
    }, [qty])

    useEffect(() => {

        if (monthCount !== monthCount) {
            console.log('worked')
            setMonthCount(1)
            return
        }

        const dates = new Date(value);
        if (rents === 'Daily') {
            dates.setDate(value.getDate() + (monthCount * 1));
        } else if (rents === 'Weekly') {
            dates.setDate(value.getDate() + (monthCount * 7));
        } else {
            dates.setDate(value.getDate() + (monthCount * 30));
        }
        onChange2(dates)


        if (monthCount === 1 && sta === true) {

            return
        }
        setSta(false)
        if (rents === 'Monthly') {
            setVarid(datas[twodummy].ProSpecificId)
            setAmount((datas[twodummy].ProSpecificMonthly * qty) * monthCount)
            setSend_dat(datas[twodummy].ProSpecificMonthly)
            console.log(send_dat, 'mothly')
        } else if (rents === 'Weekly') {
            setVarid(datas[twodummy].ProSpecificId)
            setAmount((datas[twodummy].ProSpecificWeekly * qty) * monthCount)
            setSend_dat(datas[twodummy].ProSpecificWeekly)
            console.log(send_dat, 'weekly')
        } else if (rents === 'Daily') {
            setVarid(datas[twodummy].ProSpecificId)
            setAmount((datas[twodummy].ProSpecificDaily * qty) * monthCount)
            setSend_dat(datas[twodummy].ProSpecificDaily)
            console.log(send_dat, 'daily')
        }
    }, [monthCount])

    useEffect(() => {


        const dates = new Date(value);
        if (rents === 'Daily') {
            setRentTrpe('Daily')
            dates.setDate(value.getDate() + (monthCount * 1));
        } else if (rents === 'Weekly') {
            setRentTrpe('Weekly')
            dates.setDate(value.getDate() + (monthCount * 7));
        } else {
            setRentTrpe('Monthly')
            dates.setDate(value.getDate() + (monthCount * 30));
        }
        onChange2(dates)



        if (rents === 'Monthly' && sta === true) {
            if (rents === 'Monthly') {
                setVarid(datas[twodummy].ProSpecificId)
                setSend_dat(datas[twodummy].ProSpecificMonthly)
                console.log(send_dat, 'mothly')
            } else if (rents === 'Weekly') {
                setVarid(datas[twodummy].ProSpecificId)
                setSend_dat(datas[twodummy].ProSpecificWeekly)
                console.log(send_dat, 'weekly')
            } else if (rents === 'Daily') {
                setVarid(datas[twodummy].ProSpecificId)
                setSend_dat(datas[twodummy].ProSpecificDaily)
                console.log(send_dat, 'daily')
            }
            return
        }
        setSta(false)
        if (rents === 'Monthly') {
            setVarid(datas[twodummy].ProSpecificId)
            setSend_dat(datas[twodummy].ProSpecificMonthly)
            console.log(send_dat, 'mothly')
            setAmount((datas[twodummy].ProSpecificMonthly * qty) * monthCount)
        } else if (rents === 'Weekly') {
            setVarid(datas[twodummy].ProSpecificId)
            setSend_dat(datas[twodummy].ProSpecificWeekly)
            console.log(send_dat, 'weekly')
            setAmount((datas[twodummy].ProSpecificWeekly * qty) * monthCount)
        } else if (rents === 'Daily') {
            setVarid(datas[twodummy].ProSpecificId)
            setSend_dat(datas[twodummy].ProSpecificDaily)
            console.log(send_dat, 'daily')
            setAmount((datas[twodummy].ProSpecificDaily * qty) * monthCount)
        }
    }, [rents])

    const sendData = () => {

        console.log(varid, 'peoid')

        if (nav === true) {
            navigate('/cart')
            return
        }

        let localdata = [
            {
                proId: datass[0].ProId,
                proQty: qty,
                proDepAmnt: typw === 'corporate' ? 0 : datass[0].ProDepositAmt,
                proRentAmnt: sta === true ? dummy : send_dat,
                proVarAmnt: sta === true ? dummy : send_dat,
                proTotalAmnt: sta === true ? dummy : amount,
                proRentType: rents,
                proBookFrom: ((value.getMonth() > 8) ? (value.getMonth() + 1) : ('0' + (value.getMonth() + 1))) + '-' + ((value.getDate() > 9) ? value.getDate() : ('0' + value.getDate())) + '-' + value.getFullYear(),
                proBookTo: ((value2.getMonth() > 8) ? (value2.getMonth() + 1) : ('0' + (value2.getMonth() + 1))) + '-' + ((value2.getDate() > 9) ? value2.getDate() : ('0' + value2.getDate())) + '-' + value2.getFullYear(),
                imgurl: ImageUrl + '/' + imgs[0].ProImgName,
                brand: datass[0].ProName,
                monthCount: monthCount,
                totalDeposit: typw === 'corporate' ? 0 : depamount,
                proVarId: varid,
                alldet: {
                    datas
                }
            }
        ]

        if (localStorage.getItem('data')) {
            let allata = localStorage.getItem('data')
            let another = JSON.parse(allata)
            another.forEach((element, index) => {
                if (element.proId === localdata[0].proId && element.proRentType === localdata[0].proRentType && element.proBookFrom === localdata[0].proBookFrom && element.monthCount === localdata[0].monthCount && element.proVarId === localdata[0].proVarId) {
                    final = 1
                    another[index].proQty = another[index].proQty + qty
                    another[index].totalDeposit = another[index].proDepAmnt * another[index].proQty
                    another[index].proTotalAmnt = another[index].proVarAmnt * another[index].proQty
                    localStorage.setItem('data', JSON.stringify(another))
                    setnavi(true)
                    dispatch(Cartcount(another))
                    setLoading(false)
                    setBtnData('Go to Cart')
                    succnotify()
                    console.log(final, 'infinal')
                }
            })

            // let obj = another.find((element, index) => {
            //     if (element.proId === localdata[0].proId) {
            //        console.log('true') // stop searching
            //     }else{
            //         console.log('false')
            //     }
            // });

            // console.log

            if (final === 0) {
                let allanother = another.concat(localdata)
                localStorage.setItem('data', JSON.stringify(allanother))
                setnavi(true)
                setLoading(false)
                dispatch(Cartcount(allanother))
                setBtnData('Go to Cart')
                succnotify()
            }
        } else {
            localStorage.setItem('data', JSON.stringify(localdata))
            dispatch(Cartcount(localdata))
            setnavi(true)
            setLoading(false)
            setBtnData('Go to Cart')
            succnotify()
        }
        console.log(final, 'final')
    }


    const calfun = (e) => {
        // const datess = new Date(value); 

        // const diffTime = Math.abs(e - datess);

        // console.log(rents,'eee1')

        // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        if (rents === 'Weekly') {
            let one = moment(e).add(7 * monthCount, 'd');
            onChange(e)
            onChange2(one)
            // datess.setDate(value.getDate() + (diffDays + ( monthCount * 7 )))
        } else if (rents === 'Daily') {
            let one = moment(e).add(1 * monthCount, 'd');
            onChange(e)
            onChange2(one)
            // datess.setDate(value.getDate() + (diffDays + ( monthCount * 1 )))
        } else {
            let one = moment(e).add(30 * monthCount, 'd');
            onChange(e)
            onChange2(one)
            // datess.setDate(value.getDate() + (diffDays + ( monthCount * 30 )));
        }
    }

    let MoooCount = (e) => {

        console.log(parseInt(e), 'fail')
        if (parseInt(e) !== parseInt(e)) {
            console.log('workedddddd')
            // setQty(1)
            return
        }
        setMonthCount(parseInt(e))

        if (parseInt(e) > 100) {
            setMonthCount(100)
            return
        }
    }

    let Qttttychange = (e) => {

        if (parseInt(e) !== parseInt(e)) {
            console.log('workedddddd')
            // setQty(1)
            return
        }
        setQty(parseInt(e))
        if (qty > 100) {
            setQty(100)
            return
        }
    }

    return (
        <>
            {
                datas ?
                    <>
                        <div className='row'>
                            <div className='col-10'>
                                {/* <p className='propara'>Apple</p>  */}
                                <p className='propara2 mt-2'>{datass ? datass[0].ProName : ''}</p>
                            </div>
                            <div className='col-2'>
                                <div className="product-like-icon">
                                    {(() => {
                                        if (wish != null) {
                                            var result = wish.filter(x => x.datas.ProId == datass[0]?.ProId);
                                            if (result.length > 0) {
                                                return (<i onClick={() => Clickheart(datass[0])} className='fa fa-heart hrt_red_clr' > </i>)
                                            } else {
                                                return (<i onClick={() => Clickheart(datass[0])} className='fa fa-heart-o' > </i>)
                                            }
                                        }
                                        else {
                                            return (<i onClick={() => Clickheart(datass[0])} className='fa fa-heart-o' > </i>)
                                        }
                                    })()}
                                </div>
                            </div>
                        </div>

                        <p className='propara4 mt-2'>Variant</p>
                        <div className='row mt-2'>
                            {veri?.map((data, key) =>
                                <div onClick={() => press(data, key)} key={key} className='col-4'>
                                    <div className={(keys === key ? 'pro_cont' : 'pro_cont1')}>
                                        <p className={(keys === key ? 'propara6' : 'propara5')}>{data.ProSpecificName}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                        <p className='propara7 mt-3 mb-2'>Highlights</p>

                        <p className='prppara3'>{datass[0]?.ProDescr ? Html(datass[0].ProDescr) : ''}</p>


                        {/* {keys == ke ? subValue : ''} */}

                        <div className='d-flex mt-2'>
                            <p className='propara8'> <Link to={"/mobile_detail/" + id}>View Details</Link></p>
                            <Link to={"/mobile_detail/" + id}><img className='propara9' src={tworight} /></Link>
                        </div>

                        <div className='dassh mt-3'>
                        </div>

                        <>
                            <div className='mob_foot' style={{ backgroundColor: '#fff' }}>
                                <div className='row'>
                                    <div className='col-3'>
                                        <p className='compara1' style={{ color: '#000' }}>{rentType} rent</p>
                                        <p className='compara2' style={{ color: '#000' }}>₹ {sta ? dummy : amount}</p>
                                    </div>
                                    <div className='col-3'>
                                        <div onClick={() => setShowEnqModal(true)} style={{ color: 'black', textAlign: 'center' }} className='bbtn'>
                                            {
                                                loading ? <ReactLoading type='balls' color='#ffffff' />
                                                    :
                                                    'Enquiry'
                                            }
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        {

                                            nre === 'lufy' ?
                                                ''

                                                :

                                                <div onClick={() => sendData()} style={{ color: 'black', textAlign: 'center' }} className='bbtn'>
                                                    {
                                                        loading ? <ReactLoading type='balls' color='#ffffff' />
                                                            :
                                                            btndata
                                                    }
                                                </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </>
                        <div className='row mt-3'>
                            <div className='col-8'>
                                <p className='propara10 mb-3'>Rent Type</p>
                                <ChakraProvider>
                                    <Select onChange={(e) => setRent(e.target.value)}>
                                        {
                                            rent?.map((data, key) =>
                                                <option key={key} selected={key === 0 ? 'selected' : ''} value={data.RentTypeName}>{data.RentTypeName}</option>
                                            )
                                        }
                                    </Select>
                                </ChakraProvider>
                            </div>
                            <div className='col-4'>
                                <p className='propara10 mb-3'>Duration</p>
                                <ChakraProvider>
                                    <NumberInput value={monthCount} onChange={(e) => MoooCount(e)} size='md' maxW={24} defaultValue={1} min={1} max={12}>
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                </ChakraProvider>
                            </div>
                        </div>
                        <p className='propara10 my-3'>Booking Date</p>
                        <div className='d-flex justify-content-between mt-3'>
                            <div className=''>
                                <div>
                                    <DatePicker minDate={new Date()} format='dd-MM-y' onChange={(e) => calfun(e)} value={value} />
                                </div>
                            </div>
                            <div className=''>
                                <p className='propara12'>To</p>
                            </div>
                            <div className=''>
                                <DatePicker className='datepick ' format='dd-MM-y' disabled={true} onChange={onChange2} value={value2} />
                            </div>
                        </div>
                        <div className='mt-3'>
                            <p className='propara10  mb-3'>Qty</p>
                            <ChakraProvider>
                                <NumberInput onChange={(e) => Qttttychange(e)} value={qty} size='md' maxW={24} defaultValue={1} min={1} max={13}>
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </ChakraProvider>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-6'>
                                <div className='cool2'>
                                    <p className='propara13'>Deposit</p>
                                    <p className='propara14'>₹ {typw === 'corporate' ? 0 : datass ? depamount : ''}</p>
                                    <p className='propara15'>Included</p>
                                    <div className='d-flex propara17o7'>
                                        <img className='sfvdkgehhrfuddd' src={tik} />
                                        <p className='propara16'>Deposit will returned</p>
                                    </div>
                                    <div className='d-flex propara17o7'>
                                        <img className='sfvdkgehhrfuddd' src={tik} />
                                        <p className='propara16'>Safe & Secure</p>
                                    </div>
                                    <div className='d-flex propara17o7'>
                                        <img className='sfvdkgehhrfuddd' src={tik} />
                                        <p className='propara16'>Zero Charges</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='cool2'>
                                    <p className='propara13'>Rent</p>
                                    <p className='propara14'>₹ {sta ? dummy : amount}</p>
                                    <p className='propara15'>Included</p>
                                    <div className='d-flex propara17o7'>
                                        <img className='sfvdkgehhrfuddd' src={tik} />
                                        <p className='propara16'>Free Delivery</p>
                                    </div>
                                    <div className='d-flex propara17o7'>
                                        <img className='sfvdkgehhrfuddd' src={tik} />
                                        <p className='propara16'>Delivery in 2-5 Hrs</p>
                                    </div>
                                    <div className='d-flex propara17o7'>
                                        <img className='sfvdkgehhrfuddd' src={tik} />
                                        <p className='propara16'>Door Step Service</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='last_cont mt-4'>
                            <div className='row'>
                                <div className='col-6'>
                                    <img className='imggg' src={awssu + '/' + didunow?.image} />
                                </div>
                                <div className='col-6'>
                                    <p className='last_p'>Did You Know ??</p>
                                    <p className='last_p1 mt-1 mb-1'>#{didunow?.title}</p>
                                    <p className='last_p3'>{didunow?.Brand}</p>
                                    <p className='last_p4 mt-1'>{didunow?.description.slice(0, 174)}...</p>
                                    <p onClick={() => readmorefn()} className='last_p5 mt-2'>Read more</p>
                                </div>
                            </div>
                        </div>
                        <ChakraProvider>
                            <Modal isCentered isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                                {overlay}
                                <ModalContent>
                                    <ModalCloseButton style={{ color: '#fff' }} />
                                    <p style={{ backgroundColor: '#161E40', padding: 12, borderRadius: 5 }}><div style={{ width: '95%', height: 'auto', backgroundColor: '#161E40', padding: 12, borderRadius: 5 }} >
                                        <div className='row' >

                                            <div className='col-12'>
                                                <p style={{ fontSize: 12, fontWeight: 900, color: '#fff' }} >Did You Know ??</p>
                                                <p style={{ fontSize: 8, fontWeight: 600, color: '#A8A8A8' }}>#{didunow.Brand}</p>
                                                <p style={{ fontSize: 12, fontWeight: 600, color: '#fff' }} >{didunow.title}</p>
                                                <p style={{ fontSize: 10, fontWeight: 500, color: '#B3B3B3' }} >{didunow.description}</p>
                                            </div>
                                        </div>
                                    </div></p>
                                </ModalContent>
                            </Modal>
                        </ChakraProvider>



                        {/* <Modals
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <img className='wrong_cart' src={wrong} />
            <div className='mod_div'>werglm</div>

            <div className='row'>
                <div className='col-6'>
                    <div onClick={closeModal} className='cart_hell_one'>
                        <p className='cart_hell_two'>close</p>
                    </div>
                </div>
                <div className='col-6'>
                    <div onClick={()=>login()} className='cart_hell_one'>
                        <p className='cart_hell_two'>Login</p>
                    </div>
                </div>
            </div>
            
      </Modals>

      <Modals
            isOpen={modalIsOpens}
            onAfterOpen={afterOpenModals}
            onRequestClose={closeModals}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <img className='wrong_cart' src={wrong} />
            <div className='mod_div'>drkll</div>
            <div className='row'>
                <div className='col-6'>
                    <div onClick={closeModals} className='cart_hell_one'>
                        <p className='cart_hell_two'>close</p>
                    </div>
                </div>
                <div className='col-6'>
                    <div onClick={()=>navigate('/')} className='cart_hell_one'>
                        <p className='cart_hell_two'>Home</p>
                    </div>
                </div>
            </div>
                </Modals> */}


                        <ToastContainer />
                    </> : ''
            }

            <Modal style={{ height: 'auto' }} show={showEnqModal} onHide={() => setShowEnqModal(false)} centered>

                <Modal.Header closeButton>
                    <Modal.Title>Enquiry</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        <Form.Group controlId="dropdownField1" className="">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your Name"
                                onChange={(e) => handleInputChange(e, 'name')}
                                isInvalid={validationErrors.name !== ''}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                {validationErrors.name}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="dropdownField1" className="pt-2">
                            <Form.Label>Mobile</Form.Label>

                            <Form.Control type="number" required isInvalid={validationErrors.name !== ''} placeholder="Enter Number" onChange={(e) => handleInputChange(e, 'phoneNumber')} />
                            <Form.Control.Feedback type="invalid">
                                {validationErrors.phoneNumber} {/* Error message for invalid input */}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="dropdownField1" className="pt-2">
                            <Form.Label>Mail</Form.Label>
                            <Form.Control type="mail" required
                                isInvalid={validationErrors.name !== ''} placeholder="Enter Mail id" onChange={(e) => handleInputChange(e, 'email')} />
                            <Form.Control.Feedback type="invalid">
                                {validationErrors.email} {/* Error message for invalid input */}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="dropdownField1" className="pt-2">
                            <Form.Label>Address</Form.Label>
                            <Form.Control type="textarea" placeholder="Address" onChange={(e) => handleInputChange(e, 'address')} />
                            <Form.Control.Feedback type="invalid">
                                {/* Error message for invalid input */}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="dropdownField1" className="pt-2">
                            <Form.Label>Detail</Form.Label>
                            <Form.Control type="text" placeholder="Enter your queries" onChange={(e) => handleInputChange(e, 'detail')} />
                            <Form.Control.Feedback type="invalid">
                                {/* Error message for invalid input */}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* Repeat the same structure for other form fields */}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        style={{ backgroundColor: 'white', color: 'black', borderColor: '#FFD100' }}
                        onClick={() => setShowEnqModal(false)}
                    >
                        Close
                    </Button>
                    <Button
                        style={{ color: 'black', backgroundColor: '#FFD100', borderColor: '#FFD100' }}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}