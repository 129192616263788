import React from 'react'
import Home_ticket_comp from '../components/fullwidth/home_ticket/home_ticket_comp'
import Header_two from '../common/head_foot/header_two'
import Footer from '../common/footer'

export default function Home_ticket() {
  return (
    <>
        <Header_two />
        <Home_ticket_comp />
        <Footer />
    </>
  )
}
