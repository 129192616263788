import React , { useEffect , useState } from 'react'
import Profile_home_nav from '../../../page/profile_home/profile_home_nav'
import { Tabs, TabList, TabPanels, Tab, TabPanel , ChakraProvider } from '@chakra-ui/react'
import { Link  , useNavigate } from 'react-router-dom'
import one123 from '../../../assets/img/123.png'
import axios from 'axios'
import { Base_Url , ImageUrl } from '../../globalvariable/globe'

export default function Payment_report_comp() {

    let navigate = useNavigate()

    const [ all , setAll ] = useState()
    const [ alls , setAlls ] = useState()

    let token = localStorage.getItem('user_data')

    let allData = async () =>{
        if(token === null){
            navigate('/')
            return
        }
        let res = await axios({
            method : 'post' ,
            url : `${Base_Url}/runningPaymentHistory`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            }
        })
        .then((res)=>{
            setAll(res.data.Data.orders)
            console.log(res)
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    let allDatas = async () =>{
        let res = await axios({
            method : 'post' ,
            url : `${Base_Url}/remainingPaymentHistory`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            }
        })
        .then((res)=>{
            setAlls(res.data.Data.orders)
            console.log(res,'twoo')
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    useEffect(()=>{
        allData()
        allDatas()
    },[])

    

    return (
    <>
        <div className='pro_home_one mt-150'>
            <div className='row' >
            
            <Profile_home_nav />

            <div className='col-8'>
            <div className='pro_home_fifteen'>
                <div className='d-flex'>
                    <p className='pro_home_eight'>Payment Report</p>
                </div>
            </div>
            <div className='pro_home_seven mt-3'>
                <ChakraProvider>
                    <Tabs isFitted >
                        {
                            all ?  <TabList>
                            <Tab _selected={{ color: 'white', bg: '#F4B644' }}>Pending</Tab>
                            <Tab _selected={{ color: 'white', bg: '#F4B644' }}>Paid</Tab>
                    </TabList> :''
                       
                        }
                        
                        <TabPanels>
                            <TabPanel>
                                {
                                    all?.length ===0 || all === undefined ? <p style={{textAlign:'center' , marginTop : '20px' , fontWeight:'600'}} >No Data Found</p>  :
                                    all?.map((data,key)=>
                                    <div onClick={async()=>{
                                        navigate(`/history_detail/${data.orderId}/${1}`)
                                    }} className='ord_det_one mt-4'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <p className='ord_det_two'>{data.OrderAutoGenId}</p>
                                            <p className='ord_det_threes'>{data.remainingDays}</p>
                                        </div>
                                        <div className='col-6'>
                                            <p className='his_onesss'>{data.expireKey}</p>
                                            <p className='his_twos'>{data.todate}</p>
                                        </div>
                                    </div>
                                    <div className='row mt-1'>
                                        {data?.carts.map((dat,key)=>
                                        
                                        {
                                            return(
                                                
                                            key >=4 ?
                                            <></>
                                            : <div className='col-3' >
                                                <img className='ord_det_seven' src={ImageUrl+'/'+dat.ProImgPrimary} />
                                                <p className='ord_det_eight'>{dat.ProName.substring(0, 20)}...</p>
                                            </div>
                                        )
                                        }
                                            
                                        )}
                                        {
                                            data .remainingCount === 0 ? '' :
                                                <div className='col-3' >
                                                    <img className='ord_det_sevens' src={one123} />
                                                
                                                    <p className='more_items'>{data.remainingCount}<br/>Items</p>
                                                    <p className='ord_det_eights'>View more</p>
                                                </div>   
                                        }
                                    
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                        <div className='mt-2'>
                                            <p className='his_three mt-2'>₹ {data.rentAmount}/{data.Type === "Monthly" ? 'M' : data.Type === "Weekly" ? 'W' : 'D'}</p>
                                        </div>
                                        </div>
                                        <div className='col-6'>
                                            {/* <Link to='/service_request'> */}
                                                <div style={{cursor : 'pointer' }} className=' mt-2'>
                                                    <p style={{color : '#F4B644'}} className='ord_det_twelve'>Running</p>
                                                </div>
                                            {/* </Link> */}
                                        </div>
                                    </div>
                                </div>
                                    
                                    )
                                }
                                
                            </TabPanel>
                            <TabPanel>
                                { 
                                    alls?.length ===0 || alls === undefined ? <p style={{textAlign:'center' , marginTop : '20px' , fontWeight:'600'}} >No Data Found</p>  :
                                    alls?.map((data,key)=>
                                    <div onClick={async()=>{
                                        navigate(`/history_detail/${data.orderId}/${0}`)
                                    }} className='ord_det_one mt-4'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <p className='ord_det_threes'>Txn No : {data.TxnNo}</p>
                                            <p className='ord_det_two'>{data.OrderAutoGenId}</p>
                                        </div>
                                        <div className='col-6'>
                                            <p className='his_twosss'>{data.fromdate}
                                             {/* - {data.todate} */}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='row mt-1'>
                                        {data?.carts.map((dat,key)=>
                                        
                                        {
                                            return(
                                                
                                            key >=4 ?
                                            <></>
                                            : <div className='col-3' >
                                                <img className='ord_det_seven' src={ImageUrl+'/'+dat.ProImgPrimary} />
                                                <p className='ord_det_eight'>{dat.ProName.substring(0, 20)}...</p>
                                            </div>
                                        )
                                        }
                                            
                                        )}
                                        {
                                            data .remainingCount === 0 ? '' :
                                                <div className='col-3' >
                                                    <img className='ord_det_sevens' src={one123} />
                                                
                                                    <p className='more_items'>{data.remainingCount}<br/>Items</p>
                                                    <p className='ord_det_eights'>View more</p>
                                                </div>   
                                        }
                                    
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                        <div className='mt-2'>
                                            <p className='his_three mt-2'>₹ {data.rentAmount}/{data.Type === "Monthly" ? 'M' : data.Type === "Weekly" ? 'W' : 'D'}</p>
                                        </div>
                                        </div>
                                        <div className='col-6'>
                                            {/* <Link to='/service_request'> */}
                                                <div style={{cursor : 'pointer'}} className='ord_det_twelvesss mt-2'>
                                                    <p className='ord_det_twelve'>{data.status}</p>
                                                </div>
                                            {/* </Link> */}
                                        </div>
                                    </div>
                                </div>
                                    )
                                }
                            
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </ChakraProvider>
                </div>
            </div>
            </div>
        </div>
    </>
    )
}
