import React , { useState , useEffect } from 'react'
import Arrow_five from '../../mobile_view_comp/kyc_five.js/arrow_five'
import approve from '../../../assets/img/approve.png'
import { Tick } from 'react-crude-animated-tick';
import headset from '../../../assets/img/headset.png'
import Profile_home_nav from '../../../page/profile_home/profile_home_nav';
import axios from 'axios';
import { Base_Url } from '../../globalvariable/globe';
import Payment from '../../../page/mobile/payment';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LoogIn } from '../../../redux/actions/productAction';

export default function Home_kyc_five_comp() {

    let dispatch = useDispatch()

    let user = localStorage.getItem('user_data')
    const [ approves , setApprove ] = useState()
    let navigate = useNavigate()

    useEffect(()=>{
        Alldata()
    },[])

    const Alldata = async () => {
        if(user === null){
            navigate('/')
            return
          }
        let respoce = await axios ({
            method : 'post' , 
            url : `${Base_Url}/kycApproval`,
            headers: { 
                'Authorization': `Bearer ${user}` 
            }
        })
        .then((res)=>{
            if(res.data.kycApproval === 1 ){
                setApprove(1)
            }else{
                setApprove(0)
            }
        })
        .catch((err)=>{
            if(err.response.status === 401){
                dispatch(LoogIn({show : true}))
                // navigate('/')
              }
            console.log(err)
        })
    }

    const backHome = () =>{
        navigate('/')
    }

    const payment = () =>{
        navigate('/')
    }
 
  return (
    <div className='pro_home_one mt-150'>
        <div className='row' >
            <Profile_home_nav />
            <div className='col-8'>
                <div className='pro_home_seven'>
                    <div className='mb-4'>
                        {
                            approves === 1 ? '' : <Arrow_five />
                        }
                            
                            <div className='row mt-4'>
                                <div className='col-12'>
                                    <div className='pro_home_fifteen'>
                                        <div className='d-flex'>
                                            <p className='pro_home_eight'>Approval</p>
                                        </div>
                                    </div>
                                    <div className='pro_home_seven mt-3'> 

                                    { approves===0 ? 
                                        <>
                                            <div className='kyc_five'>
                                                <img className='kyc_five_img' src={approve} />
                                                <p className='kyc_five_p_one'>Your Kyc waiting for approval</p>
                                                <p className='kyc_five_p_twos'>It will take 3 - 4hrs , Please be Patient </p>
                                            </div>
                                            <div onClick={()=>backHome()} className='kyc_five_box mt-4'>
                                                <p style={{cursor:'pointer'}} className='kyc_five_p_three'>Back to home</p> 
                                            </div> 
                                        </>
                                            : approves=== 1 ? 
                                        <>
                                            <div>         
                                                <Tick size={200} />
                                                <p style={{fontSize:'20px' , fontWeight : '600' , textAlign:'center'}} className='my-4'>Kyc Approval Complete</p>
                                            </div>
                                            <div className=''>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <div style={{ cursor : 'pointer' }} onClick={()=>{ navigate('/support')}} className='d-flex'>
                                                            <div className='headset'>
                                                                <img className='kaart' src={headset} />
                                                            </div>
                                                            <div>
                                                                <p className='compara222'>Support</p>
                                                                <p className='compara3333'>9am - 5Pm</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div onClick={()=>payment()} className='bbtn'>
                                                            <div className='d-flex'>
                                                                <p style={{cursor:'pointer'}} className='compara nextonee mt-1'>Back to home</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </> : ''
                                            }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
