import React from 'react'
import New_address_comp from '../../components/mobile_view_comp/new_address_comp'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'

export default function New_address() {
  return (
    <>
       <div style={{width:'90%' , margin:'auto' ,marginTop:'65px' , marginBottom:'30px'}}>
        <Cart_one_head link='/edit_profile' head='New Address' />
        <New_address_comp />
      </div>
    </>
  )
}