import React , { useState , useEffect} from 'react'
import imggg from '../../assets/img/imggg.png'
import { useSelector } from 'react-redux'
import { ImageUrl } from '../globalvariable/globe'
import {
  VStack,
  Text,
  Flex,
  Image,
  ChakraProvider,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  useDisclosure,
  Container
} from "@chakra-ui/react";
import Carousel from "react-multi-carousel";

export default function Home_container() {

  const product =  useSelector((state)=>state)

  const datas = product.allProducts.products.didYouKnow
  

  const [ modalvalueneww , setModalvalneww ] = useState('')
  const OverlayOneneww = () => (

      <ModalOverlay />
      )
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [overlayneww, setOverlayneww] = React.useState(<OverlayOneneww />)
  const readmorefn = (key)=>{
      setModalvalneww(key)
      onOpen()
  }
  const modalitemsneww =  datas && datas?.map((data,key)=>
  <div style={{width: '95%' , height : 'auto' , backgroundColor : '#161E40' , padding : 12 , borderRadius : 5}}  key={key} >
     <div className='row' >
          <div className='col-12'>
              <p style={{fontSize : 12 ,  fontWeight : 900 , color : '#fff'}} >Did You Know ??</p>
              <p style={{fontSize : 8 ,  fontWeight : 600 , color : '#A8A8A8'}}>#{data.Brand}</p>
              <p style={{fontSize : 12 ,  fontWeight : 600 , color : '#fff'}} >{data.title}</p>
              <p style={{fontSize : 10 ,  fontWeight : 500 , color : '#B3B3B3'}} >{data.description}</p>
          </div>
     </div>
  </div>
) 
  // let datas = product.allProducts.products.didYouKnow

  // let data = datas[0]


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 500 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  
  return (
    <>
 
 <ChakraProvider>
          <Container
            py={4}
            px={0}
          >
            <Carousel
                swipeable={true}
                draggable={true}
                responsive={responsive}
                infinite={true}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}                
                ssr={true} // means to render carousel on server-side.
                containerClass="carousel-container" 
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
              >
              {
              datas?
              datas?.map((data, index) => (
                <>
                <div style={{ margin : 7 }} >  

                  <div className='last_cont'>
                    <div className='row'>
                        <div className='col-6'>
                            <img className='imggg' src={ImageUrl+'/'+data?.image} />
                        </div>
                        <div className='col-6'>
                            <p className='last_p'>Did You Know ??</p>
                            <p className='last_p1 mt-1 mb-1'>#{data?.Brand}</p>
                            <p className='last_p3'>{data?.title}</p>
                            <p className='last_p4 mt-1'>{data?.description?.slice(0,231)}...</p>
                            <p onClick={() => readmorefn(index) } className='last_p5 mt-1'>Read more</p>
                        </div>
                    </div>
                  </div>

                </div>
                
            </>
              )) : <div></div>}
          </Carousel>
          {
            datas?.length === 0 || datas === undefined ? '' :
            <Modal isCentered isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                {overlayneww}
                <ModalContent>
                <ModalCloseButton style={{color:'#fff'}} />
                    <p style={{ backgroundColor : '#161E40' , padding : 12 , borderRadius : 5}}>{modalitemsneww[modalvalueneww]}</p>
                </ModalContent>
            </Modal>
          }
          
        </Container>
        
        </ChakraProvider>

 
    </>
  )
}