import React , { useState , useEffect } from 'react'
import det from '../../../assets/img/det.png'
import hrrt from '../../../assets/img/hrrt.png'
import car from '../../../assets/img/car.png'
import Skeleton from 'react-loading-skeleton'
import { Base_Url } from '../../globalvariable/globe'
import { useNavigate } from 'react-router-dom'
import { useDispatch , useSelector } from 'react-redux'
import axios from 'axios'
import { WishList } from '../../../redux/actions/productAction'
import { selectedRemoveProduct } from '../../../redux/actions/productAction'
import { ToastContainer, toast } from 'react-toastify';
import { ImageUrl } from '../../globalvariable/globe'
import { Link } from 'react-router-dom'
import Left from '../../../assets/img/left.png'
import { Wishcount } from '../../../redux/actions/productAction'

export default function Mob_wish_comp() {
  
  let navigate = useNavigate()
  let skl = [1,2,3]
  const [countss  , setCountss ]= useState(1)
  const [ keys , setKeys ] = useState()
  const [ allWish , setAllWish ] = useState()
  let dispatch = useDispatch()


  useEffect(()=>{
    Alldata()
  },[])

  useEffect(()=>{
    let wishss = localStorage.getItem('wish_list')
    setAllWish(JSON.parse(wishss))
  },[countss])



  const Alldata = async () =>{
    let wish =  localStorage.getItem('wish_list')
    let another = JSON.parse(wish)
    console.log(another,'another')
    setAllWish(another) 
    
  }

  const errnotify = (dat) => toast.error( dat , {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const susnotify = (dat) => toast.success( dat , {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
    
  const [touch , setTouch ]= useState(true);

  const clicked = async ( data , key ) =>{ 
    
    setKeys(key)
    let allata = localStorage.getItem('wish_list')
    let another = JSON.parse(allata)

    let wish_data = [
       data
      
    ]

    if(localStorage.getItem('wish_list')){
        another.forEach((element, index) => {
          console.log(wish_data,'sussss')
            if( element.datas.ProId === wish_data[0].datas.ProId){
                console.log('sussss')
                let allanother = another
                allanother.splice(index , 1)
                localStorage.setItem('wish_list' , JSON.stringify(allanother))
                dispatch(Wishcount(allanother))
                Alldata()
            }
        })
    }
    setCountss(countss +1)
    
  }

  const clicked_pro = ( data ) =>{
    navigate('/detail/'+data.datas.ProName
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '')
      +'/'+data.datas.ProId)
  }

  return (
    <>
    <div className="second-tops fixed-top navbar-light top-tab-nav">
            <div className="d-flex  m-auto paddd" >
               <div onClick={()=>{navigate(-1)}} className='loab'>
                  {/* <Link to={props.link}> */}
                    <img src={Left} />
                  {/* </Link> */}
                </div>
               <div style={{margin: 'auto'}} className='loab'>
                   <p className='ccaaat'>Wish List {  allWish === null || allWish?.length === 0 ? '' : `( ${allWish?.length} )` }</p>
               </div>
            </div>
        </div>
        {
            allWish && [...allWish]?.reverse()?.map(( data , key ) =>{
                return(
                    <div className='mob_wi_one mt-4'>
                        <div className='row'>
                            <div onClick={()=>clicked_pro(data)} className='col-5'>
                                <img style={{ width:'100%' , height:'auto' , marginTop:'20px' }} src={ImageUrl+'/'+data.datas.ProImgPrimary} />
                            </div>
                            <div className='col-7'>
                                <div className='row'>
                                    <div onClick={()=>clicked_pro(data)} className='col-10'>
                                        <p className='mob_wi_two'>{data.datas.BrandName}</p>
                                        <p className='mob_wi_three'>{data.datas.ProName}</p>
                                        {/* <p className='mob_wi_four'>256/8 gb , 13.7” Retina Display</p> */}
                                    </div>
                                    <div className='col-2'>
                                        <img style={{marginLeft:'-13px'}} onClick={()=>clicked(data , key)} className={
                                          (()=>{
                                            if(keys === key){
                                              return ' mr-1'
                                            }else if(touch===true){
                                              return 'mr-1'
                                            }else{
                                              return ' mr-1'
                                            }
                                          })()}   src={hrrt} />
                                    </div>
                                </div>
                                <div className='row mt-5' >
                                    <div className='col-5'>
                                        <p className='mob_wi_five'>₹{data.datas.ProRentAmt}/M</p>
                                    </div>
                                    <div className='col-7'>
                                        <div className='mob_wi_six'>
                                            <div className='d-flex justify-content-center'>
                                            <Link to={'/detail/'+ data.datas.ProName
                                            .toLowerCase()
                                            .trim()
                                            .replace(/[^\w\s-]/g, '')
                                            .replace(/[\s_-]+/g, '-')
                                            .replace(/^-+|-+$/g, '') +'/'+data.datas.ProId}>
                                                    <p className='mob_wi_seven'>View Detail</p>
                                        </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
         {
                  allWish === null || allWish?.length === 0 ?
                      <p style={{textAlign:'center' , marginTop:'20px',fontWeight:'600'}}>Wishlist is empty</p> :''
                    
                }
        <ToastContainer />
    </>
  )
}
