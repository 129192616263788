import React from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'


export default function Timer(props) {
  let day = props.time * 86400

  const children = ({ remainingTime }) => (
    <div role="timer" aria-live="assertive">
      {props.time} Days
    </div>
  )

  return (
    <>
        <div style={{padding: '10px'}} className="timmmerrrrr_prr">
            <CountdownCircleTimer
                
                size={110}
                strokeWidth={7}
                // duration={props.time}
                colors={['#F4B644', '#F4B644', '#F4B644', '#F4B644']}
                colorsTime={[60, 45, 30, 15]}
                onComplete={()=>console.log('worked')}
            >
                {({ remainingTime }) => (
                  <>
                    <p className=''>
                    {props.time}
                    </p>
                    <p>
                    {(" Days")}
                      
                    </p>
                  </>
                  
                )}
            </CountdownCircleTimer>
        </div>
    </>
  )
}