import React , { useState ,useRef , useMemo , useCallback, useEffect } from 'react'
import mark from '../../../assets/img/mark.png'
import tikkk from '../../../assets/img/tikkk.png'
import L from 'leaflet';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter, 
    ModalBody,
    useDisclosure,
    Button,
    ChakraProvider
  } from '@chakra-ui/react'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Base_Url } from '../../globalvariable/globe';
import Cart_one_head from '../cart_one/cart_one_head'
import { useNavigate } from 'react-router-dom'
import Header from '../../../common/head_foot/header_two';
import Footer from '../../../common/footer';
import Profile_home_nav from '../../../page/profile_home/profile_home_nav';


export default function Company_password_change_web() {
    

    const { isOpen, onOpen, onClose } = useDisclosure()
    let navigate = useNavigate()

    const [ currentpass , setCurrentpass ] =  useState('')
    const [ newpass , setNewpass ] = useState('')
    const [ confirmnewpass , setConfirmnewpass ] = useState('')
    const [ loading , setLoading ] = useState(false)
  
    let user = localStorage.getItem('user_data')

  const errnotify = (dat) => toast.error( dat , {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });

      let token = localStorage.getItem('user_data')

      let start = () =>{
        if(token === null){
          navigate('/')
          return
        }
      }
  
      useEffect(()=>{
        start()
      },[])

      let clicked = () =>{

        if( currentpass === '' ){
            errnotify('Enter Current Password')
            return
        }
        if( newpass === '' ){
            errnotify('Enter New Password')
            return
        }
        if( newpass.length < 8 ){
            errnotify('Enter Valid New Password')
            return
        }
        if( confirmnewpass === '' ){
            errnotify('Confirm New Password')
            return
        }
        if( confirmnewpass === newpass ){
            setLoading(true)
            var formData = new FormData();
            formData.append("currentpassword", currentpass );
            formData.append("newpassword", newpass );
            formData.append("confirmnewpassword", confirmnewpass );
      
          const sendData = async() =>{
            let responce = await axios.post(`${Base_Url}/changeCorporatePassword`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('user_data')}` 
                } 
            })
            .then((res)=>{
              if(res.data.status === true ){
                onOpen()
              }else{
                setLoading(false)
                errnotify(res.data.message)
                return
              }
            })
            .catch((err)=>{
              setLoading(false)
              console.log(err)
              return
              let er = 'Something Went Wrong'
              errnotify(er)
              console.log(err)
            })
          }
      
          sendData()
        }else{
            errnotify('New password Mismatch')
            return
        }

      }


  return (
    <>
    <Header />
     <div className='pro_home_one  mt-150'>
          <div className='row' >
            
            <Profile_home_nav />

            <div className='col-8'>
            <div className='pro_home_fifteen'>
                <div className='d-flex'>
                  <p className='pro_home_eight'>Change Password</p>
                </div>
              </div>
              <div className='pro_home_seven mt-3'>
                    
              <div style={{width:'90%' , margin:'auto' ,marginTop:'65px'}}>
                <div className='mt-4'>
                  
                  
                    </div>
                      <>
                          <div className='mt-3'>
                              <p className='kyc_two_para'>Current Password</p>
                              <input type="text" onChange={(e)=> setCurrentpass(e.target.value) } value={currentpass} className='form-control kyc_in_one' id="exampleInputEmail1" aria-describedby="emailHelp"  />
                          </div>
                    
                          <div className='mt-3'>
                              <p className='kyc_two_para'>Enter New Password</p>
                              <input type="email" onChange={(e)=>setNewpass(e.target.value)} value={newpass} className='form-control kyc_in_one' id="exampleInputEmail1" aria-describedby="emailHelp" />
                          </div>
                          <div className='mt-3'>
                              <p className='kyc_two_para'>Confirm New Password</p>
                              <input type="email" onChange={(e)=>setConfirmnewpass(e.target.value)} value={confirmnewpass} className='form-control kyc_in_one' id="exampleInputEmail1" aria-describedby="emailHelp" />
                          </div>
                      <div  onClick={()=>clicked()} className='lo_tw_five5'>
                          <div className='d-flex justify-content-center'>
                              <p className='mob_log_p_four'>Save Changes</p>
                          </div>
                      </div>

                </>

                <ChakraProvider>

                <Modal isCentered={true} isOpen={isOpen} size={'xs'} onClose={onClose}>

                  <ModalOverlay />
                    <ModalContent>
                        <ModalBody>
                          {/* <img onClick={()=>{  }} style={{ width : 20 , height : 20 }}  src={tikkk} /> */}
                          <p style={{ fontSize : 15 , color : '#000' , textAlign : 'center' , marginTop : 20 }} >Password Changed Successfully</p>
                        </ModalBody>
                        <ModalFooter >
                          <Button onClick={()=>{ navigate('/corporate_profile') }} bg={'#F4B644'} color={'white'}  >
                              Back To Profile
                          </Button>
                        </ModalFooter>
                    </ModalContent>

                </Modal>

                </ChakraProvider>


                <ToastContainer
                      position="top-right"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      />
                          {/* Same as */}
                  <ToastContainer />
                  </div>
              </div>
            </div>
          </div>
        </div>

      <Footer />
    
    </>
  )
}