import React , { useEffect, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ChakraProvider,
  useDisclosure,
  Button,
  Select
} from '@chakra-ui/react'

export default function Four_veri_cmp() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [ drop , setDrop]= useState('')
  const [ value , setValue ] = useState(true)

  const [ title , setTitle ] = useState('')
  const [ parameter ,  setParameter ] = useState('')

  const [ text ,  setText ] = useState(false)

  let tes = []

  useEffect(()=>{
    onOpen()
  },[])

  useEffect(()=>{
    if(drop === 'drop'){
      setValue(false)
    }else{
      setValue(true)
    }

    
  },[drop])

let submit = () =>{
  if(drop === 'text'){
    tes.push(title)
    console.log(tes,'adfklhsfhsh')
  }
}

  let Textt = (title, parameter) =>{
    setText(true)
    return(<>
    <p>{title}</p>
    <input id={parameter} type='text' />
    </>)
  }

  return (
    <>
      <div className='d-flex' >
        <p>Dynamic Field</p>
        <p onClick={onOpen}>+</p>
      </div>
      <div>
        <p>Check box Title</p>
          <input type='checkbox' />
      </div>
      <div>
        <p>Drop Down Title</p>
        <ChakraProvider>
          <Select onChange={(e)=>setDrop(e.target.value)}>
            <option value='check'>Check box</option>
            <option value='drop'>Drop Down</option>
            <option value='text'>Text box</option>
            <option value='radio'>Radio</option>
          </Select>
        </ChakraProvider>
       
      </div>
      <div>
        <p>Textbox Title</p>
          <input type='text' />
        {
          tes.map((data)=>{
            return(
              <p>ldkhcsjkdksdfs</p>
            )
          })
        }
      </div>
      <div>
        <p>Radio button title</p>
        <input type='radio' />
      </div>
      <ChakraProvider>
      <Button >Submit</Button>
      </ChakraProvider>
      

      <ChakraProvider>
                <Modal
                    isCentered isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay
                        bg='blackAlpha.300'
                        backdropFilter='blur(10px) hue-rotate(90deg)'
                        />
                          <ModalContent>
                            <ModalHeader>Add new Field </ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <p>Title</p>
                                <input onChange={(e)=>setTitle(e.target.value)} type='text' />
                                <p>Parameter Name</p>
                                <input onChange={(e)=>setParameter(e.target.value)} type='text' />
                                <p>DropDown Tile</p>
                                <Select onChange={(e)=>setDrop(e.target.value)}>
                                  <option value='check'>Check box</option>
                                  <option value='drop'>Drop Down</option>
                                  <option value='text'>Text box</option>
                                  <option value='radio'>Radio</option>
                                </Select>
                                {
                                  value === false ?  <>
                                  <p>Option </p>
                                <div>
                                  <div className='d-flex'>
                                    <input type='text' />
                                    <p>+</p>
                                  </div>
                                </div>
                                </>:""
                                }
                                
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={()=>submit()} colorScheme='blue'>
                                  Add Field
                                </Button>
                            </ModalFooter>
                            </ModalContent> 
                </Modal>
            </ChakraProvider>
    </>
  )
}
