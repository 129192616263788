import React , { useState , useEffect } from 'react'
import './history.css'
import { Link , useNavigate } from 'react-router-dom'
import one123 from '../../../assets/img/123.png'
import axios from 'axios'
import { Base_Url , ImageUrl } from '../../globalvariable/globe'

export default function History_comp() {

  let navigate = useNavigate()

  let token = localStorage.getItem('user_data')

    const [ all ,  setAll ] = useState()
    const [ alls ,  setAlls ] = useState()

    
    let Alldata = async() =>{
        if(token === null){
          navigate('/')
          return
        }
        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/history`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            }
        })
        .then((res)=>{
            setAll(res.data.Data.orders)
            console.log(res)
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    let Alldatas = async() =>{
        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/history`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            },
            data : {
                products : 1
            }
        })
        .then((res)=>{
            setAlls(res.data.Data.products)
            console.log(res,'products')
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    useEffect(()=>{
        Alldata()
        Alldatas()
    },[])

  const [ val , setVal ] = useState(0)
  const [ checked , setChecked ] = useState('checked')

  const handleChange = (e) => {
    if(val === 1){
        setChecked('checked')
        setVal(0)
    }else{
        setChecked('')
        setVal(1)
    }
  }

  const Hist = () =>{
    return(
      <>
      <div className='d-flex justify-content-between mt-4'>
        <div>
            <p className='switch_order_one'>List by orders</p>
        </div>
        <div>
            <label className="switch">
                <input type="checkbox" checked={(checked)} onChange={e => handleChange(e)} />
                <span className="slider round"></span>
            </label>
        </div>
      </div>
      {
        all ? '' : <p style={{textAlign:'center' , marginTop : '20px' , fontWeight:'600'}} >No Data Found</p> 
      }
      {all?.map((data,key)=>
      <div className='ord_det_one mt-4'>
        <div className='row'>
            <div className='col-6'>
                <p className='ord_det_two'>{data.OrderAutoGenId}</p>
                <p className='ord_det_three'>{data.remainingDays}</p>
            </div>
            <div className='col-6'>
            {/* {(()=>{
              if(data.status === "Pending Order"){
                  return(
                  <p className='his_one'>{data.status}</p>
                  )
              }
              else if(data.status === "Running Order"){
                  return(
                  <p className='his_one'>{data.status}</p>
                  )
              }
              else if(data.status === "Handovered"){
                  return(
                  <p className='his_one his_seven'>{data.status}</p>
                  )
              }
              
              else if(data.status === "Order Cancelled"){
                  return(
                  <p className='his_one his_eight'>{data.status}</p>
                  )
              }
              else if(data.status === "Handover Request"){
                  return(
                  <p className='his_one '>{data.status}</p>
                  )
              }
              else if(data.status === "Renewed Order"){
                  return(
                  <p className='his_one '>{data.status}</p>
                  )
              }
              else {
                  return(
                  <p className='his_one '>{data.status}</p>
                  )
              }
            })()} */}
            <p style={{marginTop:'-17px'}} className='his_twos'>{data.fromdate} - {data.todate}</p>
            </div>
        </div>
        <div className='row mt-1'>
            {data?.carts.map((dat,key)=>
            
            {
                return(
                    
                key >=5 ?
                <></>
                : <div className='col-3' >
                    <img className='ord_det_sevenmobbb' src={ImageUrl+'/'+dat.ProImgPrimary} />
                    <p className='ord_det_eight'>{dat.ProName.substring(0, 20)}...</p>
                </div>
            )
            }
                
            )}
            {
                data .remainingCount === 0 ? '' :
                    <div className='col-3' >
                        <img className='ord_det_sevens' src={one123} />
                    
                        <p className='more_items'>{data.remainingCount}<br/>Items</p>
                        <p className='ord_det_eights'>View more</p>
                    </div>   
            }
        
        </div>
        <div className='row'>
            <div className='col-6'>
              <div className=''>
                <p className='his_three mt-2'>₹ {data.orderamt}/{data.Type === "Monthly" ? 'M' : data.Type === "Weekly" ? 'W' : 'D'}</p>
              </div>
            </div>
            <div className='col-6'>
            {(()=>{
                                            if(data.status === "Pending Order"){
                                                return(
                                                <p className='his_one'>{data.status}</p>
                                                )
                                            }
                                            else if(data.status === "Running Order"){
                                                return(
                                                <p className='his_one'>{data.status}</p>
                                                )
                                            }
                                            else if(data.status === "Handovered"){
                                                return(
                                                <p className='his_one his_seven'>{data.status}</p>
                                                )
                                            }
                                            
                                            else if(data.status === "Order Cancelled"){
                                                return(
                                                <p className='his_one his_eight'>{data.status}</p>
                                                )
                                            }
                                            else if(data.status === "Handover Request"){
                                                return(
                                                <p className='his_one '>{data.status}</p>
                                                )
                                            }
                                            else if(data.status === "Renewed Order"){
                                                return(
                                                <p className='his_one '>{data.status}</p>
                                                )
                                            }
                                            else {
                                                return(
                                                <p className='his_one '>{data.status}</p>
                                                )
                                            }
                                            })()}
            </div>
        </div>
      </div>
      
      )}
    </>
    )
  }

  const Historyss = () => {
    return(
      <>
        <div className='d-flex justify-content-between mt-4'>
          <div>
              <p className='switch_order_one'>List by orders</p>
          </div>
          <div>
              <label className="switch">
                  <input type="checkbox" checked={(checked)} onChange={e => handleChange(e)} />
                  <span className="slider round"></span>
              </label>
          </div>
        </div>
        {alls?.map((data,key)=>
        <Link to={'/detail/'+ data.ProName
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '') +'/'+data.CartDetailProId}>
        <div className='his_ten mt-3'>
          <div className='row'>
            <div className='col-3'>
              <img style={{width:'85%'}} className='pt-1' src={ImageUrl +'/'+ data.ProImgPrimary} />
            </div>
            <div className='col-4'>
              <p className='his_eleven pt-2'>{data.ProName.substring(0, 40)}...</p>
              <p className='his_twelve'>{data.CartDetailInventoryProSerialNo}</p>
            </div>
            <div className='col-5'>
              <p className={(data.status === 'Renewed' || data.status === 'Handovered' ? 'his_fourteen mt-2' : 'his_thirteen mt-2')}>{data.status}</p>
              <p className='his_sixteen'>on {data.Date}</p>
            </div>
          </div>
        </div>
        </Link>
        )}
      </>
    )
  }

  return (
    <>
    {val === 0 ? <Hist /> : <Historyss />}
    </>
  )
}
