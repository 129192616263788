import React from 'react'
import Profile_home_comp from './profile_home/profile_home_comp'
import Header_two from '../common/head_foot/header_two'
import Footer from '../common/footer'

export default function Profile_home() {
  return (
      <>
        <Header_two />
        <Profile_home_comp /> 
        <Footer />
      </>
  )
}
