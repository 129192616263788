import React ,  { useState , useEffect } from 'react'
import left from '../../../assets/img/left.png'
import shop from '../../../assets/img/shop.png'
import addcarrt from '../../../assets/img/addcarrt.png'
import { Link  , useNavigate } from 'react-router-dom'

import { useSelector } from 'react-redux'

export default function Mob_nor_head(props) {

  let navigate = useNavigate()

  const [ noo , setNo ] = useState(0)

  const [ st , setSt ] = useState(false)

  const product = useSelector((state)=>state.cartcount)

  useEffect(()=>{
    
    //
    let wishss = localStorage.getItem('data')


    if ( wishss === null ){ 
        setNo(0) 
        return
    }else if(wishss === '[]'){
        setNo(0)
    }
    let hii = JSON.parse(wishss)

    setNo(hii.length) 
  },[])

  useEffect(()=>{
    console.log(product , 'product')
    if(product.products.length !=0){
        setNo(product.products.length)
    }else{
      let wishss = localStorage.getItem('data')
  
  
      if ( wishss === null ){ 
          setNo(0)
          return
      }else if(wishss === '[]'){
          setNo(0)
      }
      let hii = JSON.parse(wishss) 
  
      setNo(hii.length)
    }
  },[product])

  setTimeout(() => {
    setSt(true)
  }, 2000);

  const navi = () =>{
    navigate(-1)
  }

  return ( 
    <>
      <div className="second-top fixed-top navbar-light top-tab-nav">
            <div className="d-flex justify-content-between m-auto paddd" >
               <div  className='loab'>
                    <img onClick={()=>navi()} src={left} />
                </div>
               <div style={{textAlign:'right'}} className='loab'>
                   <div className='d-flex'>
                        <div onClick={()=>{
                            localStorage.setItem('nav',3)
                            navigate('/cart')
                          }} className='cityname pt-1'>
                          {
                            st === true ? 
                              <span class='badge badge-warning' style={{ borderRadius : '50%' , padding : '3px 5px' , position : 'absolute' ,
                              marginLeft : -12 }} id='lblCartCountsssvvvv'>{noo}</span>
                            : ''
                          }
                         
                          <img style={{ marginLeft : 'auto' , width :  21 }} src={addcarrt} />
                          
                        </div>
                   
                   </div>
               </div>
            </div>
        </div>
    </>
  )
}