import React from 'react'
import Four_veri_cmp from '../../components/mobile_view_comp/four_veri_veri/four_veri_cmp'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'

export default function Four_veri() {
  return (
    <div style={{width:'90%' , margin:'auto' , paddingBottom:'100px',marginTop:'65px'}}>
        {/* <Cart_one_head link='/service_pick' head='Handover Request' /> */}
        <Four_veri_cmp />
    </div>
  )
}
