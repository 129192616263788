import {
    Box,
    Button,
    Divider,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Stack,
    Text,
    useBreakpointValue,
    Image,
    useDisclosure,
    Modal,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalOverlay,
    ChakraProvider
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { OAuthButtonGroup } from './OAuthButtonGroup'
import logo from '../../../assets/logo.png'
import { CgProfile } from "react-icons/cg";
import { useSelector, useDispatch } from 'react-redux'
import { LoogIn } from '../../../redux/actions/productAction';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OTPInput from "otp-input-react";
import comp_ty from '../../../assets/img/comp_ty.png'
import four_mem from '../../../assets/img/four_mem.png'
import eye_one from '../../../assets/img/eye_one.png'
import eye_close from '../../../assets/img/eye_close.png'
import file from '../../../assets/img/file.png'
import delect from '../../../assets/img/delect.png'
import doc from '../../../assets/img/doc.png'
import { useNavigate, Link } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons'
import { Base_Url } from '../../globalvariable/globe';

import userr from '../../../assets/ioon/user.png'

import pro from '../../../assets/img/pro.png'
import ReactLoading from 'react-loading';

export const Login = () => {

    let navigate = useNavigate()

    const [phoneno, setPhoneno] = useState('') //1345412111   1234567891

    const [phonenodata, setPhonenodata] = useState(true)
    // const [ phmessage , setPhonemsg ] = useState('Enter Valid Phone No')

    let change = /^\d{10}$/;
    let emailveri = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let dispatch = useDispatch()

    const product = useSelector((state) => state.login.show)

    const [dat, setdata] = useState(false)

    const [OTP, setOTP] = useState('')

    const [name, setName] = useState('')
    const [check, setCheck] = useState(false)
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setphone] = useState('')

    const [veriname, setVeriName] = useState(false)
    const [vericheck, setveriCheck] = useState(false)
    const [veriusername, setveriUsername] = useState(false)
    const [veriemail, setveriEmail] = useState(false)
    const [veriphone, setveriphone] = useState(false)

    const [compname, setCompname] = useState('')
    const [compphone, setCompphone] = useState('')
    const [comppername, setComppername] = useState('')
    const [compemail, setCompmail] = useState('')
    const [compgts, setCompgts] = useState('')

    const [vericompname, setveriCompname] = useState(false)
    const [vericompphone, setveriCompphone] = useState(false)
    const [vericomppername, setveriComppername] = useState(false)
    const [vericompemail, setveriCompmail] = useState(false)
    const [vericompgts, setveriCompgts] = useState(false)
    const [load, setLoad] = useState(false)

    const [OTPreg, setOTPreg] = useState()


    const [usererr, setUsererr] = useState(true)
    const [reg, setReg] = useState('')

    const [Confirmpass, setConfirmpass] = useState('')
    const [Confirmpassveri, setConfirmpassveri] = useState(false)


    const [selreg, setSelreg] = useState(0)

    const [tms, setTms] = useState(true)

    const [showimg, setShowimg] = useState()


    const [eye, setEye] = useState(false)
    const [eye1, setEye1] = useState(false)
    const [pass, setPass] = useState('')
    const [companyphone, setCompanyphone] = useState('')
    const [cologinerr, setCologinerr] = useState(false)
    const [coerrmsg, setCoerrmsg] = useState('')

    const [companyname, setCompanyName] = useState('')
    const [gstno, setGstno] = useState('')
    const [comphone, setComphone] = useState('')
    const [companyemail, setCompemail] = useState('')
    const [compPassword, setComppassword] = useState('')
    const [data, setData] = useState('')
    const [showimgs, setShowimgs] = useState(false)
    const [img, setImg] = useState()

    const [comploginerr, setComploginerr] = useState(false)
    const [comperrmsg, setComperrmsg] = useState('')
    const [wish, setWish] = useState()

    const [timerss, setTimer] = useState(true)
    const [timerCont, setTimerCount] = useState(60)

    const errnotify = (dat) => toast.error(dat, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const successnotify = (sus) => toast.success(sus, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    const userClicked = () => {
        setLog(1)

        setCompanyName('')
        setGstno('')
        setComphone('')
        setCompemail('')
        setComppassword('')

        setCheck(false)
        setVeriName(false)
        setveriCheck(false)
        setveriUsername(false)
        setveriEmail(false)
        setveriphone(false)
        setveriCompname(false)
        setveriCompphone(false)
        setveriComppername(false)
        setveriCompmail(false)
        setveriCompgts(false)
        setUsererr(true)
        setCologinerr(false)
        setShowimgs(false)
        setComploginerr(false)
        setPhonenodata(true)
        setConfirmpassveri(false)
        setReg('')
        setConfirmpass('')



        setCologinerr(false)
        let ch = localStorage.getItem('user_data')
        let userData = localStorage.getItem('user_type')
        if (ch === null) {
            setOverlay(<OverlayOne />)
            onOpen()
        } else {
            if (userData === 'corporate') {
                navigate('/corporate_profile')
            } else {
                navigate('/profile')
            }

        }

    }

    useEffect(() => {
        if (product) {
            setOverlay(<OverlayOne />)
            onOpen()
            setLog(0)
            setdata(false)
            setOTP('')
            setPhoneno('')
            setSelreg(0)
            setCompname('')
            setCompphone('')
            setComppername('')
            setCompmail('')
            setCompgts('')
            setShowimg()
            setOTPreg()
            setPhonenodata(true)
        }
        dispatch(LoogIn({ show: false }))
    }, [product])

    const OverlayOne = () => (

        <ModalOverlay />
    )

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [overlay, setOverlay] = React.useState(<OverlayOne />)

    const [log, setLog] = useState(0)

    useEffect(() => {
        setLog(0)
    }, [])

    function timer(remaining) {
        remaining -= 1;

        if (remaining >= 0) {
            setTimeout(function () {
                timer(remaining);
                setTimerCount(remaining)
            }, 1000);

        }
        if (remaining === 0) {
            setTimer(false)
        }
    }

    const clicked = () => {

        if (companyname === '') {
            setComperrmsg('Enter Company Name')
            setComploginerr(true)
            return
        }

        if (gstno === '') {
            setComperrmsg('Enter gstno')
            setComploginerr(true)
            return
        }

        if (comphone === '') {
            setComperrmsg('Enter Phone Number')
            setComploginerr(true)
            return
        }

        if (comphone.length < 10) {
            setComperrmsg('Enter Valid Phone Number')
            setComploginerr(true)
            return
        }

        if (companyemail === '') {
            setComperrmsg('Enter Company Email')
            setComploginerr(true)
            return
        }

        let phonevali = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (phonevali.test(companyemail)) {

        } else {
            setComperrmsg('Enter Valid Email')
            setComploginerr(true)
            return
        }

        if (compPassword === '') {
            setComperrmsg('Enter Password')
            setComploginerr(true)
            return
        }
        if (compPassword.length < 8) {
            setComperrmsg('Password must be 8 letters')
            setComploginerr(true)
            return
        }

        if (compPassword != Confirmpass) {
            setComperrmsg('Password Mismatch')
            setComploginerr(true)
            return
        }

        setLoad(true)
        var formData = new FormData();
        var imagefile = document.querySelector('#filees');
        formData.append("document", showimgs === true ? imagefile.files[0] : '');
        formData.append("companyName", companyname);
        formData.append("gstNo", gstno);
        formData.append("phone", comphone);
        formData.append("email", companyemail);
        formData.append("password", compPassword);

        const sendData = async () => {
            let responce = await axios.post(`${Base_Url}/createCorporateAccount`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((res) => {
                    console.log(res.data)
                    if (res.data.status === true) {
                        successnotify('Company Account Created Success')
                        setComploginerr(false)
                        setLog(10)
                        setLoad(false)
                    } else {
                        setComperrmsg(res.data.message)
                        setComploginerr(true)
                        setLoad(false)
                        return
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setComperrmsg('Register Failed')
                    setComploginerr(true)
                    setLoad(false)
                })
        }
        sendData()
    }

    const remove = () => {
        document.querySelector('#file').value = "";
        // setData(backimage)
        setData('')
        setImg()
        setShowimgs(false)
    }

    const handleCapture = (target) => {
        if (target.files) {
            if (target.files.length !== 0) {
                const file = target.files[0];
                const newUrl = URL.createObjectURL(file);
                setData('')
                setShowimgs(true)
                setImg({ 'img': newUrl })
            }
        }
    }


    const signin = async () => {
        setUsererr(true)
        setdata(false)
        if (change.test(phoneno)) {
            setPhonenodata(true)
            let responce = await axios({
                method: 'post',
                url: `${Base_Url}/login`,
                data: {
                    phoneNumber: phoneno
                }
            })
                .then((res) => {
                    if (res.data.status === true) {
                        setdata(true)
                        setTimerCount(60)
                        setTimer(true)
                        timer(60)
                        setOTP('')


                        sessionStorage.setItem('phone', phoneno)
                        return
                    } else {
                        // setLog(1)
                        setdata(false)
                        // let datss = 'User Not Registered'
                        // errnotify(datss)
                        setUsererr(false)
                    }
                })
                .catch((err) => {
                    if (err) {
                        setdata(false)
                        let dats = 'Something Went Wrong'
                        errnotify(dats)
                    }
                })
        } else {
            setPhonenodata(false)
            setdata(false)
            // let dat = 'Enter Valid PhoneNo'
            // errnotify(dat)
            return
        }
    }

    const resend = async () => {
        setdata(false)
        setOTP('')
        if (change.test(phoneno)) {
            let responce = await axios({
                method: 'post',
                url: `${Base_Url}/login`,
                data: {
                    phoneNumber: phoneno
                }
            })
                .then((res) => {
                    if (res.data.status === true) {
                        setTimerCount(60)
                        setTimer(true)
                        timer(60)
                        setdata(true)
                        successnotify('Resend Otp Success')
                        // setOTP(res.data.Data.otp)
                    } else {
                        setdata(false)
                        let datss = 'User Not Registered'
                        errnotify(datss)
                    }
                })
                .catch((err) => {
                    setdata(false)
                    let dats = 'Something Went Wrong'
                    errnotify(dats)
                })
        } else {
            setdata(false)
            let dats = 'Something Went Wrong'
            errnotify(dats)
            return
        }
    }


    const resendreg = async () => {
        setdata(false)
        setOTPreg('')
        let responce = await axios({
            method: 'post',
            url: `${Base_Url}/login`,
            data: {
                phoneNumber: sessionStorage.getItem('phone')
            }
        })
            .then((res) => {
                if (res.data.status === true) {
                    setdata(true)
                    // setOTPreg(res.data.Data.otp)
                    sessionStorage.setItem('phone', sessionStorage.getItem('phone'))
                } else {
                    setdata(false)
                    let datss = 'User Not Registered'
                    errnotify(datss)
                }
            })
            .catch((err) => {
                setdata(false)
                let dats = 'Something Went Wrong'
                errnotify(dats)
            })
    }

    const sendotp = () => {

        if (OTP.length === 0) {
            errnotify('Please Enter otp')
            return
        }


        if (sessionStorage.getItem('phone')) {
            const otpss = async () => {
                let responce = await axios({
                    method: 'post',
                    url: `${Base_Url}/loginCheck`,
                    data: {
                        phoneNumber: sessionStorage.getItem('phone'),
                        otp: OTP
                    }
                })
                    .then((res) => {
                        if (res.data.status === true) {
                            let sus = 'Login Success'
                            successnotify(sus)
                            setdata(false)
                            setOTP('')
                            localStorage.setItem('user_data', res.data.Data.token)
                            localStorage.setItem('user_id', res.data.Data.userId)
                            navigate('/profile')
                            return
                        } else {
                            let dat = 'Please Enter Valid otp'
                            errnotify(dat)
                            return
                        }
                    })
                    .catch((err) => {
                        let dat = 'Something Went Wrong'
                        errnotify(dat)
                        return
                    })
            }
            otpss()
        } else {
            let dat = 'Something Went Wrong'
            errnotify(dat)
            return
        }
    }

    const sendotpreg = () => {


        if (OTPreg.length === 0) {
            errnotify('Please Enter otp')
            return
        }

        if (sessionStorage.getItem('phone')) {
            const otpss = async () => {
                let responce = await axios({
                    method: 'post',
                    url: `${Base_Url}/loginCheck`,
                    data: {
                        phoneNumber: sessionStorage.getItem('phone'),
                        otp: OTPreg
                    }
                })
                    .then((res) => {
                        if (res.data.status === true) {
                            let sus = 'Register Success'
                            successnotify(sus)
                            setdata(false)
                            setOTPreg('')
                            localStorage.setItem('user_data', res.data.Data.token)
                            localStorage.setItem('user_id', res.data.Data.userId)
                            // navigate(-1)
                            navigate('/profile')
                            return
                        } else {
                            let dat = 'Please Enter Valid otp'
                            errnotify(dat)
                            return
                        }
                    })
                    .catch((err) => {
                        let dat = 'Something Went Wrong'
                        errnotify(dat)
                        return
                    })
            }
            otpss()
        } else {
            let dat = 'Something Went Wrong'
            console.log(sessionStorage.getItem('phone'))
            errnotify(dat)
            return
        }
    }

    let resends = async () => {
        let responce = await axios({
            method: 'post',
            url: `${Base_Url}/createUserAccount`,
            data: {
                fullName: name,
                userName: username,
                email: email,
                phone: phone
            }
        })
            .then(async (res) => {
                if (res.data.status === true) {


                    setLog(4)
                    // setOTPreg(res.data.Data.otp)
                    await sessionStorage.setItem('phone', phone)
                        .then(() => {
                            successnotify('Resend Otp Success')

                        })
                    // setLog(0)

                    // let sus = "User Register Sccessfully"
                    // successnotify(sus)
                } else {
                    setReg(res.data.Error_Message)
                    // let fill = 'Something Went Wrong'
                    // errnotify(fill)
                }
            })
    }


    const clicksignin = () => {
        setLog(0)
        setdata(false)
        setOTP('')
        setPhoneno('')
        setPhonenodata(true)
    }

    const clicksignup = () => {
        setLog(2)
        setName('')
        setCheck(false)
        setUsername('')
        setEmail('')
        setphone('')
        setVeriName(false)
        setveriUsername(false)
        setveriEmail(false)
        setveriphone(false)
        setveriCheck(false)
        setPhonenodata(true)
    }

    const register = () => {

        setReg('')

        if (name === '' || username === '' || email === '' || phone === '' || check === false) {
            // let fill = 'Fill All The Field'
            // errnotify(fill)
            if (name === '') {
                setVeriName(true)
            } else {
                setVeriName(false)
            }
            if (username === '' || username.split('').length <= 5) {
                setveriUsername(true)
            } else {
                setveriUsername(false)
            }
            if (email === '') {
                setveriEmail(true)
            } else {
                setveriEmail(false)
            }
            if (email.match(emailveri)) {
                setveriEmail(false)
            } else {
                setveriEmail(true)
            }
            if (phone === '') {
                setveriphone(true)
            } else {
                setveriphone(false)
            }
            if (check === false) {
                setveriCheck(true)
            } else {
                setveriCheck(false)
            }
            return
        } else {
            setVeriName(false)
            setveriUsername(false)
            setveriEmail(false)
            setveriphone(false)
            setveriCheck(false)
        }

        if (!email.match(emailveri)) {
            setveriEmail(true)
            // let fill = 'Enter Email Correctly'
            // errnotify(fill)
            return
        } else {
            setveriEmail(false)
        }

        if (!phone.match(change)) {
            setveriphone(true)
            // let fill = 'Enter Valid PhoneNo'
            // errnotify(fill)
            return
        } else {
            setveriphone(false)
        }

        const send = async () => {
            let responce = await axios({
                method: 'post',
                url: `${Base_Url}/createUserAccount`,
                data: {
                    fullName: name,
                    userName: username,
                    email: email,
                    phone: phone
                }
            })
                .then(async (res) => {
                    if (res.data.status === true) {
                        toast.success("Registered Successfully", {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                        setTimerCount(60)
                        setTimer(true)
                        timer(60)
                        setLog(4)
                        // setOTPreg(res.data.Data.otp)
                        await sessionStorage.setItem('phone', phone)
                            .then(() => {

                                setName('')
                                setUsername('')
                                setEmail('')
                                regphone('')

                            })
                        // setLog(0)

                        // let sus = "User Register Sccessfully"
                        // successnotify(sus)
                    } else {
                        setReg(res.data.Error_Message)
                        // let fill = 'Something Went Wrong'
                        // errnotify(fill)
                    }
                })
        }
        send()
    }

    const cli = () => {
        if (selreg === 0) {
            setLog(0)
        } else {
            setLog(10)
        }
    }

    const onImage = (e) => {
        setShowimg({ [e.target.name]: URL.createObjectURL(e.target.files[0]) })
    };

    const compregister = () => {
        if (compname === '' || compphone === '' || comppername === '' || compemail === '' || compgts === '') {
            if (compname === '' || compname.split('').length <= 5) {
                setveriCompname(true)
            } else {
                setveriCompname(false)
            }
            if (compphone === '') {
                setveriCompphone(true)
            } else {
                setveriCompphone(false)
            } if (comppername === '' || comppername.split('').length <= 5) {
                setveriComppername(true)
            } else {
                setveriComppername(false)
            }
            if (compemail === '') {
                setveriCompmail(true)
            } else {
                setveriCompmail(false)
            }
            if (compemail.match(emailveri)) {
                setveriCompmail(false)
            } else {
                setveriCompmail(true)
            }
            if (compgts === '') {
                setveriCompgts(true)
            } else {
                setveriCompgts(false)
            }
            return
        } else {
            setveriCompname(false)
            setveriCompphone(false)
            setveriComppername(false)
            setveriCompmail(false)
            setveriCompgts(false)
        }

        if (!compphone.match(change)) {
            // let err = 'Enter Correct PhoneNo'
            setveriCompphone(true)
            // errnotify(err)
            return
        } else {
            setveriCompphone(false)
        }

        if (!compemail.match(emailveri)) {
            // let err = 'Enter Correct Email Id'
            setveriCompmail(true)
            // errnotify(err)
            return
        } else {
            var formData = new FormData();
            var imagefile = document.querySelector('#file');
            formData.append("document", imagefile.files[0]);
            formData.append("companyName", compname);
            formData.append("gstNo", compgts);
            formData.append("name", comppername);
            formData.append("phone", compphone);
            formData.append("email", compemail);
            setveriCompmail(false)
        }

        const sendData = async () => {
            let responce = await axios.post(`${Base_Url}/createCompanyAccount`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((res) => {
                })
                .catch((err) => {
                    console.log(err)
                })
        }

        sendData()
    }



    const changepho = (e) => {


        setPhoneno(e.target.value.replace(/[^0-9]/g, "").slice(0, 10))
        if (phoneno.length >= 10) {
            return
        }
    }

    const regphone = (e) => {
        setphone(e.target.value.replace(/[^0-9]/g, "").slice(0, 10))
    }

    const compphoneno = (e) => {
        setCompphone(e.target.value.replace(/[^0-9]/g, "").slice(0, 10))
    }

    let compsignin = async () => {
        let phonevali = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

        if (companyphone === '') {
            setCologinerr(true)
            setCoerrmsg('Enter Email')
            return
        }

        if (phonevali.test(companyphone)) {

        } else {
            setCologinerr(true)
            setCoerrmsg('Enter Valid Email')
            return
        }

        if (pass === '') {
            setCologinerr(true)
            setCoerrmsg('Enter Password')
            return
        }


        const response = await axios({
            method: 'post',
            url: `${Base_Url}/corporateLogin`,
            data: {
                email: companyphone,
                password: pass
            }
        })
            .then((res) => {
                console.log(res.data)
                if (res.data.status === true) {
                    setCologinerr(false)
                    localStorage.setItem('user_data', res.data.Data.token)
                    localStorage.setItem('user_type', 'corporate')
                    let cart = localStorage.getItem('data')
                    if (res.data.Data.userIsSubContact === 1) {
                        localStorage.setItem('eye', 'lufy')
                        localStorage.removeItem("data");
                    } else {
                        if (cart === '' || cart === undefined || cart === null) {
                            localStorage.setItem('eye', 'zoro')
                        } else {

                            let dat = JSON.parse(cart)

                            dat.forEach((element, index) => {
                                element.proDepAmnt = 0;
                                element.totalDeposit = 0
                                console.log('one')
                            });

                            let jss = JSON.stringify(dat)
                            localStorage.setItem('data', jss)
                            localStorage.setItem('eye', 'zoro')

                        }

                    }

                    navigate('/corporate_profile')
                } else {
                    setCoerrmsg(res.data.message)
                    setCologinerr(true)
                }
            })
            .catch((err) => {
                setCoerrmsg('Login Failed')
                setCologinerr(true)
                console.log(err)
            })


    }



    return (
        <>
            <div className="account-satting-active" onClick={() => userClicked()}>
                <img style={{ width: 30 }} src={userr} className='head_search_imgs' />
            </div>
            <ChakraProvider>
                <Modal isCentered isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                    {overlay}
                    <ModalContent>
                        <ModalCloseButton />
                        {(() => {
                            if (log === 0) {
                                return (
                                    <ArrowBackIcon onClick={() => setLog(1)} w={6} h={6} className='arroeeww' />
                                )
                            } else if (log === 2) {
                                return (
                                    <ArrowBackIcon onClick={() => setLog(1)} w={6} h={6} className='arroeeww' />
                                )
                            } else if (log === 3) {
                                return (
                                    <ArrowBackIcon onClick={() => setLog(1)} w={6} h={6} className='arroeeww' />
                                )
                            }
                            else if (log === 4) {
                                return (
                                    <ArrowBackIcon onClick={() => setLog(1)} w={6} h={6} className='arroeeww' />
                                )
                            } else if (log === 11) {
                                return (
                                    <ArrowBackIcon onClick={() => setLog(10)} w={6} h={6} className='arroeeww' />
                                )
                            } else if (log === 10) {
                                return (
                                    <ArrowBackIcon onClick={() => setLog(1)} w={6} h={6} className='arroeeww' />
                                )
                            }
                        })()}

                        <ModalBody >
                            <Stack spacing="8">
                                <Stack spacing="2">
                                    <Box ml='auto' mr='auto' mt='10' mb='-10' w='80px' h='60px'>

                                        <Image justify='center' src={logo} alt='logo' />
                                    </Box>
                                    <Stack spacing={{ base: '2', md: '3' }} textAlign="center">\
                                        {(() => {
                                            if (log === 2) {
                                                return (
                                                    <Heading size={{ base: 'xs', md: 'sm' }}>
                                                        Create New account
                                                    </Heading>
                                                )
                                            } else if (log === 1) {
                                                return (
                                                    <Heading size={{ base: 'xs', md: 'sm' }}>
                                                        Log in to your account
                                                    </Heading>
                                                )
                                            } else if (log === 3) {
                                                return (
                                                    <Heading size={{ base: 'xs', md: 'sm' }}>
                                                        Create New account
                                                    </Heading>
                                                )
                                            } else if (log === 4) {
                                                return (
                                                    <Heading size={{ base: 'xs', md: 'sm' }}>
                                                        Enter Otp
                                                    </Heading>
                                                )
                                            } else {
                                                return (
                                                    <Heading size={{ base: 'xs', md: 'sm' }}>
                                                        Log in to your account
                                                    </Heading>
                                                )
                                            }
                                        })()}
                                        <HStack spacing="1" justify="center">
                                            {(() => {
                                                if (log === 2) {
                                                    return (
                                                        <>
                                                            <Text color="muted">Already have an account?</Text>
                                                            <Button variant="link" onClick={() => { clicksignin() }} textColor='#F4B644'>
                                                                Sign in
                                                            </Button>

                                                        </>
                                                    )
                                                }
                                            })()}
                                        </HStack>
                                    </Stack>
                                </Stack>
                                <Box
                                    pb={{ base: '0', sm: '8' }}
                                    px={{ base: '4', sm: '10' }}
                                    bg={useBreakpointValue({ base: 'transparent', sm: 'bg-surface' })}
                                >
                                    <Stack spacing="6">
                                        <Stack spacing="5">
                                            {(() => {
                                                if (log === 2) {
                                                    return (
                                                        <FormControl>
                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <FormLabel htmlFor="name">Full Name</FormLabel>
                                                                    <Input className={(veriname ? 'inputttt_red' : '')} onChange={(e) => {
                                                                        let letters = /^[1-9][0-9]*$/;
                                                                        let last = ''
                                                                        if (e.target.value.length >= 2) {
                                                                            last = e.target.value.split('').pop()
                                                                        } else {
                                                                            last = e.target.value
                                                                        }
                                                                        if (last.match(letters)) {

                                                                        } else {
                                                                            setName(e.target.value)
                                                                        }
                                                                    }} value={name} id="name" type="text" />
                                                                    {
                                                                        veriname ? <p style={{ color: 'red', fontSize: '9px' }}>Enter Valid Full Name                                                           </p> : ''
                                                                    }
                                                                </div>
                                                                <div className='col-6'>
                                                                    <FormLabel htmlFor="name">UserName</FormLabel>
                                                                    <Input className={(veriusername ? 'inputttt_red' : '')} onChange={(e) => {
                                                                        setUsername(e.target.value)
                                                                    }} value={username} id="name" type="text" />
                                                                    {
                                                                        veriusername ? <p style={{ color: 'red', fontSize: '9px' }}>At least 6 characters</p> : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                            <FormLabel className='mt-2' htmlFor="name">E mail</FormLabel>
                                                            <Input className={(veriemail ? 'inputttt_red' : '')} onChange={(e) => setEmail(e.target.value)} value={email} id="name" type="text" />
                                                            {
                                                                veriemail ? <p style={{ color: 'red', fontSize: '9px' }}>Enter Valid Email</p> : ''
                                                            }

                                                            <FormLabel className='mt-2' htmlFor="name">Phone Number</FormLabel>
                                                            <Input className={(veriphone ? 'inputttt_red' : '')} onChange={(e) => regphone(e)} value={phone} id="name" />
                                                            {
                                                                veriphone ? <p style={{ color: 'red', fontSize: '9px' }}>Enter Valid Phone Number</p> : ''
                                                            }

                                                            <div className='row mt-2'>
                                                                <div className='col-1'>
                                                                    <input className={(vericheck ? 'inputttt_red form-check-input' : 'form-check-input')} onChange={(e) => setCheck(!check)} checked={check} type="checkbox" id="flexCheckDefault" />
                                                                </div>
                                                                <div className='col-11'>

                                                                    <p style={{ lineHeight: '1.3' }} className={(tms ? 'mob_pay_check' : 'mob_pay_checkssss')} >I have read and agree to the Rentla’s <Link to='/useragreement'>User Agreement</Link> and <Link to='/privacy_policy'>Privacy policy</Link></p>
                                                                </div>
                                                            </div>
                                                        </FormControl>
                                                    )
                                                } else if (log === 1) {
                                                    return (
                                                        <>
                                                            <div className='row'>

                                                                <div onClick={() => setSelreg(0)} className='col-6'>
                                                                    <div className={(selreg === 1 ? 'mob_login_container_threess' : 'mob_login_container_threes')}>
                                                                        <img className='mob_log_img' src={comp_ty} />
                                                                        <p className='mob_log_p_two'>Individual</p>
                                                                    </div>
                                                                </div>

                                                                <div onClick={() => setSelreg(1)} className='col-6'>
                                                                    <div className={(selreg === 0 ? 'mob_login_container_threess' : 'mob_login_container_threes')}>
                                                                        <img className='mob_log_img' src={four_mem} />
                                                                        <p className='mob_log_p_two'>Company</p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )
                                                }
                                                else if (log === 3) {
                                                    return (
                                                        <FormControl>

                                                            <div className='row'>
                                                                <div className='col-6'>
                                                                    <FormLabel htmlFor="name">Company Name</FormLabel>
                                                                    <Input className={(vericompname ? 'inputttt_red' : '')} onChange={(e) => {
                                                                        let letters = /^[1-9][0-9]*$/;
                                                                        let last = ''
                                                                        if (e.target.value.length >= 2) {
                                                                            last = e.target.value.split('').pop()
                                                                        } else {
                                                                            last = e.target.value
                                                                        }
                                                                        if (last.match(letters)) {

                                                                        } else {
                                                                            setCompname(e.target.value)
                                                                        }
                                                                    }
                                                                    } value={compname} id="name" type="text" />
                                                                    {
                                                                        vericompname ? <p style={{ color: 'red', fontSize: '9px' }}>At least 6 characters</p> : ''
                                                                    }
                                                                </div>
                                                                <div className='col-6'>
                                                                    <FormLabel htmlFor="name">Phone Number</FormLabel>
                                                                    <Input className={(vericompphone ? 'inputttt_red' : '')} onChange={(e) => compphoneno(e)} value={compphone} id="name" />
                                                                    {
                                                                        vericompphone ? <p style={{ color: 'red', fontSize: '9px' }}>Enter Valid Phone Number</p> : ''
                                                                    }
                                                                </div>
                                                            </div>

                                                            <FormLabel className='mt-2' htmlFor="name">Contact Person Name</FormLabel>
                                                            <Input className={(vericomppername ? 'inputttt_red' : '')} onChange={(e) => {
                                                                let letters = /^[1-9][0-9]*$/;
                                                                let last = ''
                                                                if (e.target.value.length >= 2) {
                                                                    last = e.target.value.split('').pop()
                                                                } else {
                                                                    last = e.target.value
                                                                }
                                                                if (last.match(letters)) {

                                                                } else {
                                                                    setComppername(e.target.value)
                                                                }
                                                            }} value={comppername} id="name" type="text" />
                                                            {
                                                                vericomppername ? <p style={{ color: 'red', fontSize: '9px' }}>At least 6 characters</p> : ''
                                                            }

                                                            <FormLabel className='mt-2' htmlFor="name">E-mail</FormLabel>
                                                            <Input className={(vericompemail ? 'inputttt_red' : '')} onChange={(e) => setCompmail(e.target.value)} value={compemail} id="name" type="text" />
                                                            {
                                                                vericompemail ? <p style={{ color: 'red', fontSize: '9px' }}>Enter Valid Email</p> : ''
                                                            }

                                                            <FormLabel className='mt-2' htmlFor="name">GTS No</FormLabel>
                                                            <Input className={(vericompgts ? 'inputttt_red' : '')} onChange={(e) => setCompgts(e.target.value)} value={compgts} id="name" type="text" />
                                                            {
                                                                vericompgts ? <p style={{ color: 'red', fontSize: '9px' }}>Enter Valid GTS No</p> : ''
                                                            }

                                                            <FormLabel className='mt-2' htmlFor="name">Upload Documents (Optional)</FormLabel>
                                                            <input onChange={(e) => onImage(e)} accept="image/*" type='file' id="file" name='file' />
                                                            {
                                                                showimg ? <img src={showimg.file} alt="img" /> : ''
                                                            }
                                                            
                                                        </FormControl>
                                                    )
                                                }
                                                else if (log === 4) {
                                                    return (
                                                        <FormControl>

                                                            <OTPInput value={OTPreg} onChange={setOTPreg} autoFocus OTPLength={5} className='hiiiiiii' otpType="String" disabled={false} />

                                                        </FormControl>
                                                    )
                                                }
                                                else if (log === 10) {
                                                    return (
                                                        <div style={{ marginTop: -20 }}>
                                                            <FormControl>
                                                                <div style={{ marginBottom: 20 }} className='d-flex justify-content-center'>
                                                                    <Text mr='2' color="muted">Don't have an account?</Text>
                                                                    <Button variant="link" onClick={() => {
                                                                        setCompanyName('')
                                                                        setGstno('')
                                                                        setComphone('')
                                                                        setCompemail('')
                                                                        setComppassword('')
                                                                        setCheck(false)
                                                                        setVeriName(false)
                                                                        setveriCheck(false)
                                                                        setveriUsername(false)
                                                                        setveriEmail(false)
                                                                        setveriphone(false)
                                                                        setveriCompname(false)
                                                                        setveriCompphone(false)
                                                                        setveriComppername(false)
                                                                        setveriCompmail(false)
                                                                        setveriCompgts(false)
                                                                        setUsererr(true)
                                                                        setCologinerr(false)
                                                                        setShowimgs(false)
                                                                        setComploginerr(false)
                                                                        setPhonenodata(true)
                                                                        setConfirmpassveri(false)
                                                                        setConfirmpass('')
                                                                        setReg('')
                                                                        setLog(11)
                                                                    }} textColor='#F4B644' >
                                                                        Sign up
                                                                    </Button>
                                                                </div>
                                                                <FormLabel htmlFor="email">Email</FormLabel>
                                                                <Input onChange={(e) => { setCompanyphone(e.target.value) }} value={companyphone} id="email" />


                                                                <p className='login_two_img400'>Password</p>
                                                                <div style={{ border: '1px solid', borderColor: 'inherit', borderRadius: 6, height: 40 }} >
                                                                    <div className='d-flex justify-content-between'>
                                                                        <div>
                                                                            <Input style={{ borderColor: '#fff', width: '120%' }} onChange={(e) => setPass(e.target.value)} type={eye === true ? 'text' : 'password'} value={pass} id="email" />
                                                                        </div>
                                                                        <div style={{ padding: 10 }} >
                                                                            {
                                                                                eye === false ? <img onClick={() => { setEye(!eye) }} style={{ width: 20, height: 20, opacity: .5 }} src={eye_one} />
                                                                                    : <img onClick={() => { setEye(!eye) }} style={{ width: 20, height: 20, opacity: .5 }} src={eye_close} />
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    cologinerr === true ?

                                                                        <p style={{ color: 'red', textAlign: 'center', marginTop: 5, marginBottom: 5 }} >{coerrmsg}</p> : ''
                                                                }

                                                            </FormControl>
                                                        </div>
                                                    )
                                                }
                                                else if (log === 11) {
                                                    return (
                                                        <div style={{ marginTop: -20 }}>
                                                            <FormControl>
                                                                <div style={{ marginBottom: 20 }} className='d-flex justify-content-center'>
                                                                    <Text mr='2' color="muted">Already have an account?</Text>
                                                                    <Button variant="link" onClick={() => { setLog(10) }} textColor='#F4B644' >
                                                                        Sign in
                                                                    </Button>
                                                                </div>
                                                                <FormLabel htmlFor="email">Company Name</FormLabel>
                                                                <Input onChange={(e) => setCompanyName(e.target.value)} value={companyname} id="email" />

                                                                <FormLabel style={{ marginTop: 10 }} htmlFor="ww">GST No</FormLabel>
                                                                <Input onChange={(e) => setGstno(e.target.value)} value={gstno} id="www" />

                                                                <FormLabel style={{ marginTop: 10 }} htmlFor="ee">Phone Number</FormLabel>
                                                                <Input onChange={(e) => setComphone(e.target.value.replace(/[^0-9]/g, "").slice(0, 10))} value={comphone} id="ee" />

                                                                <FormLabel style={{ marginTop: 10 }} htmlFor="qq">E-mail</FormLabel>
                                                                <Input onChange={(e) => setCompemail(e.target.value)} value={companyemail} id="qq" />

                                                                <FormLabel style={{ marginTop: 10 }} htmlFor="email">Password</FormLabel>

                                                                <div style={{ border: '1px solid', borderColor: 'inherit', borderRadius: 6, height: 40 }} >
                                                                    <div className='d-flex justify-content-between'>
                                                                        <div>
                                                                            <Input style={{ borderColor: '#fff', width: '120%' }} onChange={(e) => setComppassword(e.target.value)} type={eye === true ? 'text' : 'password'} value={compPassword} id="email" />
                                                                        </div>
                                                                        <div style={{ padding: 10 }}>
                                                                            {
                                                                                eye === false ? <img onClick={() => { setEye(!eye) }} style={{ width: 20, height: 20, opacity: .5 }} src={eye_one} />
                                                                                    : <img onClick={() => { setEye(!eye) }} style={{ width: 20, height: 20, opacity: .5 }} src={eye_close} />
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <FormLabel style={{ marginTop: 10 }} htmlFor="email">Confirm Password</FormLabel>

                                                                <div style={{ border: '1px solid', borderColor: 'inherit', borderRadius: 6, height: 40 }} >
                                                                    <div className='d-flex justify-content-between'>
                                                                        <div>
                                                                            <Input style={{ borderColor: '#fff', width: '120%' }} onChange={(e) => setConfirmpass(e.target.value)} type={eye1 === true ? 'text' : 'password'} value={Confirmpass} id="email" />
                                                                        </div>
                                                                        <div style={{ padding: 10 }} >
                                                                            {
                                                                                eye1 === false ? <img onClick={() => { setEye1(!eye1) }} style={{ width: 20, height: 20, opacity: .5 }} src={eye_one} />
                                                                                    : <img onClick={() => { setEye1(!eye1) }} style={{ width: 20, height: 20, opacity: .5 }} src={eye_close} />
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='lo_tw_thirteen my-4'>
                                                                </div>
                                                                <div className='mt-3'>
                                                                    <p className='kyc_p_four'>Upload Documents (Optional)</p>
                                                                    <div className='kyc_cont'>
                                                                        {
                                                                            showimgs ? <img className='kyc_img_one' src={img.img} alt="img" /> : <img style={{ opacity: .4 }} className='kyc_img_one' src={doc} alt="img" />
                                                                        }
                                                                        <div class="kyc_centered">
                                                                            <div className='kyc_cont_two'>
                                                                                <div className='d-flex justify-content-around'>
                                                                                    <div className='mt-2'>
                                                                                        <img className='kyc_img_one' src={file} />
                                                                                        <input style={{ width: 60, left: 30 }} onChange={(e) => handleCapture(e.target)} accept="image/*" name='file' className='kyc_cont_two_upload_three' id='filees' type="file" />
                                                                                    </div>
                                                                                    {/* <div className='mt-2 kyc_line'>
                                                                </div>
                                                                <div onClick={()=>remove()} className=' mt-2'>
                                                                    <img src={delect} />
                                                                </div> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    comploginerr === true ?
                                                                        <p style={{ color: 'red', textAlign: 'center', marginTop: 5, marginBottom: 5 }} >{comperrmsg}</p>
                                                                        : ''
                                                                }


                                                            </FormControl>
                                                        </div>

                                                    )
                                                } else {
                                                    return (
                                                        <div>
                                                            <FormControl>
                                                                <div style={{ marginBottom: 20 }} className='d-flex justify-content-center'>
                                                                    <Text mr='2' color="muted">Don't have an account?</Text>
                                                                    <Button variant="link" onClick={() => { clicksignup() }} textColor='#F4B644' >
                                                                        Sign up
                                                                    </Button>
                                                                </div>
                                                                <FormLabel htmlFor="email">Phone Number</FormLabel>
                                                                <Input className={(phonenodata ? '' : 'inputttt_red')} onChange={(e) => changepho(e)} value={phoneno} id="email" />
                                                                {
                                                                    phonenodata ? '' : <p style={{ color: 'red', fontSize: '9px' }}>Enter Valid Phone Number</p>
                                                                }



                                                                {
                                                                    dat ?
                                                                        <>
                                                                            <FormLabel className='mt-3' htmlFor="email">Otp</FormLabel>
                                                                            <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={5} className='hiiiiiii' otpType="String" disabled={false} />


                                                                            {
                                                                                timerss === true ?

                                                                                    <div style={{ cursor: 'pointer' }} className='d-flex justify-content-center my-3'>
                                                                                        <p>Resend Otp In : </p>
                                                                                        <p style={{ marginLeft: '10px', color: '#F4B644' }}>{timerCont} s</p>
                                                                                    </div>

                                                                                    : <p onClick={() => {
                                                                                        resend()
                                                                                    }} className='resend_otp_web'>Resend OTP</p>
                                                                            }

                                                                        </>
                                                                        : ''

                                                                }
                                                            </FormControl>
                                                        </div>
                                                    )
                                                }
                                            })()}


                                            {/* <PasswordField /> */}
                                        </Stack>
                                        {/* <HStack justify="space-between">
                            <Button variant="link" textColor='#F4B644' size="sm">
                                Register
                            </Button>
                            </HStack> */}
                                        <Stack spacing="6">
                                            {
                                                (() => {
                                                    if (log === 2) {
                                                        return (
                                                            <>
                                                                <Button onClick={() => register()} bg='#F4B644' textColor='white' _hover={{ bg: '#161E40' }} variant="solid">Register</Button>
                                                                {
                                                                    reg === '' ? '' : <p style={{ textAlign: 'center', fontWeight: '600', color: 'red' }} >{reg}</p>
                                                                }
                                                            </>
                                                        )
                                                    } else if (log === 1) {
                                                        return (
                                                            <Button onClick={() => cli()} bg='#F4B644' textColor='white' _hover={{ bg: '#161E40' }} variant="solid">Continue</Button>
                                                        )
                                                    } else if (log === 3) {
                                                        return (
                                                            <Button onClick={() => compregister()} bg='#F4B644' textColor='white' _hover={{ bg: '#161E40' }} variant="solid">Register</Button>
                                                        )
                                                    } else if (log === 4) {
                                                        return (
                                                            <>
                                                                <Button onClick={() => sendotpreg()} bg='#F4B644' textColor='white' _hover={{ bg: '#161E40' }} variant="solid">Submit</Button>
                                                                {
                                                                    timerss === true ?

                                                                        <div style={{ cursor: 'pointer' }} className='d-flex justify-content-center my-3'>
                                                                            <p>Resend Otp In : </p>
                                                                            <p style={{ marginLeft: '10px', color: '#F4B644' }}>{timerCont} s</p>
                                                                        </div>
                                                                        :
                                                                        <p onClick={() => { resend() }} className='resend_otp_web'>Resend OTP</p>
                                                                }

                                                            </>
                                                        )
                                                    }
                                                    else if (log === 10) {
                                                        return (
                                                            <Button onClick={() => compsignin()} bg='#F4B644' textColor='white' _hover={{ bg: '#161E40' }} variant="solid">Submit</Button>
                                                        )
                                                    }
                                                    else if (log === 11) {
                                                        return (
                                                            <div onClick={() => clicked()} style={{ cursor: 'pointer' }} className='profile_bbbtn' >
                                                                {
                                                                    load === false ? <p className='profile_bbbtn_text'>Submit</p>

                                                                        :

                                                                        <div className='d-flex justify-content-center' style={{ padding: 14 }} >
                                                                            <ReactLoading type='balls' color='#ffffff' />
                                                                        </div>
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <>
                                                                <Button onClick={() => dat ? sendotp() : signin()} bg='#F4B644' textColor='white' _hover={{ bg: '#161E40' }} variant="solid"> {dat ? 'Submit' : 'Sign in'}</Button>
                                                                {usererr === false ? <p style={{ textAlign: 'center', fontWeight: '600', color: 'red' }}>User Not Registered</p> : ''}
                                                            </>
                                                        )
                                                    }
                                                })()
                                            }
                                            {/* <HStack>
                                <Divider />
                                <Text fontSize="sm" whiteSpace="nowrap" color="muted">
                                    or continue with
                                </Text>
                                <Divider />
                            </HStack>
                            <OAuthButtonGroup /> */}
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Stack>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </ChakraProvider>
            <ToastContainer

            />
        </>
    )
}

