import React , { useState ,  useEffect } from 'react'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'
import axios from 'axios'
import { Base_Url } from '../../components/globalvariable/globe'
import { Link , useNavigate } from 'react-router-dom'

export default function Mob_about_us() {

  return (
    <>
        <div style={{width:'90%' , margin:'auto' , paddingBottom:'100px' , paddingTop:'65px'}}>
            <Cart_one_head link='/profile' head='About Us' />
            <p>About Us</p>

            <p>Rentla is an IT rental company that focuses on renting gadgets required for corporates, students, freelancers, and individuals who need IT support. We are promoting
renting gadgets to optimize the use of technological resources and provide a choice for the public when it comes to choosing their gadgets. We provide gadgets at
affordable costs along with maintenance, software upgradation, and doorstep delivery services.</p>
        </div>
    </>
  )
}
