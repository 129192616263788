import React , { useState , useEffect } from 'react'
import ItemsCarousel from 'react-items-carousel';
import Order_det from './order_det';
import './order.css'

export default function Mob_order_comp() {
    
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 40;
    const [ keyvalue , setKeyValue ] = useState(0)
    const [ ke , setKe ] = useState(0)
    const [subValue , setSubvalue] = useState()

    const datas = [
        {
            "cat" : "pending",
            "detail" :[
                {
                    "id" : "#597586446"
                }
            ]
        },
        {
            "cat" : "Running",
            "detail" :[
                {
                    "id" : "#222222222"
                }
            ]
        },
        {
            "cat" : "Handover",
            "detail" :[
                {
                    "id" : "#22222233sss2"
                }
            ]
        },
        {
            "cat" : "Service",
            "detail" :[
                {
                    "id" : "#22222233sss2"
                }
            ]
        },
        // {
        //     "cat" : "Replaced"
        // }
    ]
    useEffect(() => {
        toggle(0,datas[0])
      },[]);

    const toggle = (key,data) =>{
        setKeyValue(key)
        if(data.detail){
            setKe(key)
             setSubvalue(<Order_det text={data} />)
          }else{
            setSubvalue()
          }
    }

  return (
    <>
      <div>
        <ItemsCarousel
            requestToChangeActive={setActiveItemIndex}
            activeItemIndex={activeItemIndex}
            numberOfCards={4}
            gutter={20}
            outsideChevron
            chevronWidth={chevronWidth}
        >
            {datas.map((data,key)=>
            <>
            <div    className="row">
              <div className='col-3'>
              </div>
                <div className='col-9'>
                    <p onClick={()=>toggle(key,data)} className={(keyvalue === key ? 'txttss' :'txtt')}>{data.cat}</p>
                </div>
                </div>
            </>
            )}
        </ItemsCarousel>
        {ke === keyvalue ? subValue : ''}
      </div>
    </>
  )
}