import React , { useState , useEffect } from 'react'
import Mob_pro_slider from '../mobile_pro_det/mob_pro_slider'
import left from '../../../assets/img/left.png'
import Vector from '../../../assets/img/Vector.png'
import Mob_pro_hign from '../mobile_pro_det/mob_pro_hign'
import tworight from '../../../assets/img/tworight.png'
import { Link } from 'react-router-dom'
import { Select , ChakraProvider } from '@chakra-ui/react'
import DatePicker from 'react-date-picker';

export default function Running_preview_comp() {

  const [value, onChange] = useState(new Date());

  const datas =[
    {
        "detail" : "8gb/ 256gb",
        "Highlights" : [
            {
                "one" : "Stylish & Portable Thin and Light Laptop"
            },
            {
                "one" : "13.3 inch Quad LED Backlit IPS Display (227 PPI, 500 nits Brightness, Wide Colour (P3), True Tone Technology)"
            }
        ]
    },
    {
        "detail" : "16gb/ 512gb",
        "Highlights" : [
            {
                "one" : "Stylish & Portable lap Thin and Light Laptops"
            },
            {
                "one" : "13.3 inch add Quad LED Backlit IPS Display (227 PPI, 500, Wide Colour (P3), True Tone Technology)"
            }
        ]
    },
    {
        "detail" : "16gb/ 1Tb",
        "Highlights" : [
            {
                "one" : "Portable Thin and Light Laptop"
            },
            {
                "one" : "13.3 inch IPS Display (227 PPI, 500 nits Brightness, Wide Colour (P3), True Tone Technology)"
            }
        ]
    }
]

const [ keys , setKey ] = useState(0)
const [ subValue , setSubvalue ] = useState()
const [ ke , setKe ] = useState(0)

useEffect(() => {
    pass(datas[0],0)
  },[]);

const pass = (data,key) =>{
    setKey(key)
    if(data.Highlights){
        setKe(key)
        setSubvalue(<Mob_pro_hign text={data.Highlights} />)
    }else{
        setSubvalue()
    }
}

  return (
    <>
      <div style={{paddingTop:'25px'}}>

       <div className="second-top fixed-top navbar-light top-tab-nav">
            <div className="d-flex justify-content-between m-auto paddd" >
               <div  className='loab'>
                 <Link to='/order' >
                    <img src={left} />
                    </Link>
                </div>
            </div>
        </div>
      <Mob_pro_slider />
      </div>
      <div style={{width:'90%' , margin:'auto' , paddingBottom:'100px' , paddingTop:'15px'}}>
      <div className='row'>
            <div className='col-10'>
                <p className='propara'>Apple</p>
                <p className='propara2 mt-2'>Macbook 2021 M1</p>
            </div>
            <div className='col-2'>
                <img src={Vector} />
            </div>
        </div>
        <p className='prppara3'>(16 GB/1 TB SSD/Mac OS Monterey)(16.2 inch, Silver, 2.1 kg)(16.2 inch, Silver, 2.1 kg)</p>
        <p className='propara4 mt-2'>Variant</p>
        <div className='row mt-2'>
            {datas.map((data , key)=>
            <div onClick={()=>pass(data,key)} className='col-6'>
                <div className={(keys===key ? 'pro_cont' :'pro_cont1')}>
                    <p className={(keys===key ? 'propara6' :'propara5')}>{data.detail}</p>
                </div>
            </div>
            )}
        </div>
        <div className='run_prev_one mt-3'>
          <p className='run_prev_two'>Request Service</p>
        </div>
        <p className='propara7 mt-3 mb-2'>Highlights</p>
        {keys == ke ? subValue : ''}
        <Link to="/mobile_detail">
            <div className='d-flex mt-2'>
                    <p className='propara8'>View Details</p>
                    <img className='propara9' src={tworight} />
            </div>
        </Link>
        <div className='dassh mt-3'>
        </div>
        <div className='row mt-3'>
          <div className='col-8'>
            <p className='propara10 mb-3'>Rent Type</p>
            <ChakraProvider>
                <Select placeholder='Select'>
                    <option value='option1'>Day</option>
                    <option value='option2'>Week</option>
                    <option value='option3'>Monthly</option>
                </Select>
            </ChakraProvider>
          </div>
          <div className='col-4'>
            <p className='propara10  mb-3'>Qty</p>
            <ChakraProvider>
                <Select placeholder='Select'>
                    <option value='option1'>1</option>
                    <option value='option2'>2</option>
                    <option value='option3'>3</option>
                </Select>
            </ChakraProvider>
          </div>
      </div>
      <p className='propara10 my-3'>Booking Date</p>
      <div className='row mt-3'>
          <div className='col-5'>
            <DatePicker  onChange={onChange} value={value} />
          </div>
          <div className='col-1'>
            <p className='propara12'>To</p>
          </div>
          <div className='col-6'>
            <DatePicker className='datepick ' onChange={onChange} value={value} />
          </div>
      </div>
      </div>
      <div className='mob_foot'>
          <div className='row'>
              <div className='col-6'>
                <p className='compara1'>Monthly rent</p>
                <p className='compara2'>₹ 5000</p>
              </div>
              <div className='col-6'>
                  <div className='bbtn'>
                      <Link to='/renew_cart'>
                        <p className='compara'>Renew</p>
                      </Link>
                  </div>
              </div>
          </div>
      </div>
      
    </>
  )
}