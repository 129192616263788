import React, { useState , useEffect } from 'react';
import Header from '../../../common/head_foot/header_two'
import Footer from '../../../common/footer'
import Profile_home_nav from '../../../page/profile_home/profile_home_nav'
import { Link  , useParams , useNavigate } from 'react-router-dom'
import { Base_Url , ImageUrl } from '../../globalvariable/globe';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { ViewCart } from '../../../redux/actions/productAction';
import down from '../../../assets/img/down.png'
import { useDispatch , useSelector } from 'react-redux';
import './service_status_web.css'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    ChakraProvider,
    useDisclosure,
    Button,
    FormLabel,
    Input
  } from '@chakra-ui/react'
import 'react-responsive-modal/styles.css';
import {  Modal as Modalss} from 'react-responsive-modal';
import { LoogIn } from '../../../redux/actions/productAction';
import ManageAddress from '../../address/address';

export default function Service_status_web() {

  let navigate = useNavigate()

    const errnotify = (dat) => toast.error( dat , {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
  
      const susnotify = (dat) => toast.success( dat , {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    let product =  useSelector((state)=>state)
    let datas = product.viewcart.cartDetails
    let alladdress = product.viewcart.address

    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const { isOpen, onOpen, onClose } = useDisclosure()


    let user = localStorage.getItem('user_data')

    let { cartid } = useParams()
    let {  id , no } = useParams()

    const [ sw , setSw ] = useState(0)

    const [ newAddresses , setnewAddresses ] = useState(false)

    const [ editupdate , setEditupdate ] = useState(false)

    const [ alldata , setAlldata ] = useState()

    let dispatch = useDispatch()

    const [ fname , setFname ] = useState('')
    const [ lname , setLname ] = useState('')
    const [ city , setCity ] = useState('')
    const [ state , setState ] = useState('')
    const [ pincode , setPincode ] = useState('')
    const [ geocode , setGeocode ] = useState('')
    const [ newaddress , setNewaddress ] = useState('')
    const [ subaddress , setSubaddress ] = useState('')

    const [ fnames , setFnames ] = useState(false)
    const [ lnames , setLnames ] = useState(false)
    const [ citys , setCitys ] = useState(false)
    const [ states , setStates ] = useState(false)
    const [ pincodes , setPincodes ] = useState(false)
    const [ geocodes , setGeocodes ] = useState(false)
    const [ newaddresss , setNewaddresss ] = useState(false)
    const [ subaddresss , setSubaddresss ] = useState(false)
    const [ vvale , setVvale ] = useState(false)
    const [ addressid, setAddressid ] = useState()
    const [ editid , setEditId ] = useState('')
    const [ editvalue , setEditvalue ] = useState('')
    const [ errmsg , setErrmsg ] = useState(false) 

    const [ otp , setOtp ] = useState('')
    const [ ootp , setOotp ] = useState(false)


    const [ issue , setIssue ] = useState()
    const [ issueval , setIssueval ] = useState('')

    const [ historys , setHistory ] = useState(false)

    const Update = async () =>{
      if(user === null){
        navigate('/')
        return
      }
        let responce = await axios({
          method : 'post',
          url : `${Base_Url}/updateUserDeliveryAddress`,
          headers: { 
              'Authorization': `Bearer ${user}` 
          },
          data : {
            firstName : fname ,
            lastName : lname ,
            deliveryAddress : newaddress ,
            appartment : subaddresss ,
            city : city ,
            state : state ,
            post : pincode,
            addressId : editid
          }
        })
        .then((res)=>{
          if(res.data.status==false){
            let er = res.data.Data
            errnotify(er)
          }else{
            let sus = 'Address Update Successfully'
            susnotify(sus)
            onClose()
            allData()
          }
        })
        .catch((err)=>{
          if(err.response.status === 401){
            dispatch(LoogIn({show : true}))
            // navigate('/')
          }
        })
      }

      const allData = async()=> {

        let user = localStorage.getItem('user_data')
    
        if ( user === null ){
    
          return
        }
        let responce = await axios({
                method : 'post',
                url : `${Base_Url}/viewOrderProductDetails` ,
                headers: { 
                    'Authorization': `Bearer ${user}` 
                },
                data : {
                  orderId : id
                }
            })
            .then((res)=>{
                if(res.data.status === true){
                    dispatch(ViewCart(res.data.Data))
                    setAddressid(res.data.Data.address[0].UserDelivAddrId)
                }else{
    
                }
            })
            .catch((err) => {
              if(err.response.status === 401){
                dispatch(LoogIn({show : true}))
                // navigate('/')
              }
            })

            
        
        }

        const deleteAddress = async ( data , key ) =>{
            let responce = await axios({
              method : 'post' ,
              url : `${Base_Url}/deleteUserDeliveryAddress` , 
              headers: { 
                'Authorization': `Bearer ${user}` 
              },
              data :{
                addressId : data.UserDelivAddrId
              }
            })
            .then((res)=>{
              let sus = 'Address Delected Successfully'
              susnotify(sus) 
              allData() 
            })
            .catch((err)=>{
              console.log(err)
              let er = 'Something Went Wrong'
              errnotify(er)
              if(err.response.status === 401){
                dispatch(LoogIn({show : true}))
                // navigate('/')
              }
            })
        }
    
 
    const start = async()=>{
        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/selectProductToService`,
            headers: { 
                'Authorization': `Bearer ${user}` 
            },
            data : {
                orderId : id ,
                cartDetailId : cartid
            }
        })
        .then((res)=>{

          console.log(res.data,'start')


            setAlldata(res.data.Data)
            setIssue(res.data.Data.issue[0].ServiceMasterName)
           
            if( no === '0'){
              
              setOtp(res.data.Data.otp)
            }
        })
        .catch((err)=>{
            console.log(err)
        })
        let respon = await axios({
            method : 'post',
            url : `${Base_Url}/viewOrderProductDetails` ,
            headers: { 
                'Authorization': `Bearer ${user}` 
            },
            data : {
              orderId : id
            }
        })
        .then((res)=>{
            if(res.data.status === true){
                dispatch(ViewCart(res.data.Data))
                setAddressid(res.data.Data.address[0].UserDelivAddrId)
               
            }else{

            }
        })
        .catch((err) => {
          if(err.response.status === 401){
            dispatch(LoogIn({show : true}))
            // navigate('/')
          }
        })

    }


    const handleClick = () =>{
        onOpen()
        setnewAddresses(false)
        setFnames(false)
        setLnames(false)
        setCitys(false)
        setStates(false)
        setPincodes(false)
        setGeocodes(false)
        setNewaddresss(false)
        setSubaddresss(false)
        setEditupdate(false)
    }

    useEffect(()=>{
        if( no === '0'){
          setOotp(true)
          afterotp()
        }else{
          start()
        }
       
        setnewAddresses(false)
        setFnames(false)
        setLnames(false)
        setCitys(false)
        setStates(false)
        setPincodes(false)
        setGeocodes(false)
        setNewaddresss(false)
        setSubaddresss(false)
        setEditupdate(false)
    },[])

    const editAddress = ( data , key ) => {
        setEditupdate(true)
    
        setnewAddresses(!newAddresses)
        setFname(data.UserDelivAddrFName)
        setLname(data.UserDelivAddrLName)
        setCity(data.UserDelivAddrCity)
        setState(data.UserDelivAddrState)
        setPincode(data.UserDelivAddrPost)
        setNewaddress(data.UserDelivAddrAddress)
        setSubaddress(data.UserDelivAddrAppart)
        setEditId(data.UserDelivAddrId)
    }

    const switch_data = (data,key) =>{
        setAddressid(data.UserDelivAddrId)
        setSw(key)
    }
    
    const adnewwww = () =>{
        setEditupdate(false)
        setnewAddresses(!newAddresses)
        setFname('')
        setLname('')
        setCity('')
        setState('')
        setPincode('')
        setNewaddress('')
        setSubaddress('')
    }

    const Addsub = async()=>{
        if( fname === ''|| lname === ''|| city === ''|| state === ''|| pincode === ''|| geocode === ''|| newaddress === ''|| subaddress === ''){
    
            if(fname === '' ){
                setFnames(true)
            }else{
                setFnames(false)
            }
        
            if(lname === '' ){
                setLnames(true)
            }else{
                setLnames(false)
            }
        
            if(city === '' ){
                setCitys(true)
            }else{
                setCitys(false)
            }
        
            if(state === '' ){
                setStates(true)
            }else{
                setStates(false)
            }
        
            if(pincode === '' ){
                setPincodes(true)
            }else{
                setPincodes(false)
            }
        
            if(geocode === '' ){
                setGeocodes(true)
            }else{
                setGeocodes(false)
            }
        
            if(newaddress === '' ){
                setNewaddresss(true)
            }else{
                setNewaddresss(false)
            }
        
            if(subaddress === '' ){
                setSubaddresss(true)
            }else{
                setSubaddresss(false)
            }
            return
        }else{
            setFnames(false)
            setLnames(false)
            setCitys(false)
            setStates(false)
            setPincodes(false)
            setGeocodes(false)
            setNewaddresss(false)
            setSubaddresss(false)
        }
    
        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/addUserDeliveryAddress` ,
            headers: { 
                'Authorization': `Bearer ${user}` 
            },
            data : {
                firstName : fname ,
                lastName : lname ,
                deliveryAddress : newaddress ,
                appartment : subaddresss ,
                city : city ,
                state : state ,
                post : pincode
            } 
        })
        .then((res)=>{
          if(res.data.status==false){
            let er = res.data.Data
            errnotify(er)
            return
          }else{
            let sus = 'Address Added Successfully'
            susnotify(sus)
            onClose()
            allData()
          }
        })
        .catch((err)=>{
          if(res.data.status==false){
            let er = res.data.Data
            errnotify(er)
          }
          if(err.response.status === 401){
            dispatch(LoogIn({show : true}))
            // navigate('/')
          }
        })
    }

    let lastApi = async ()=>{
        setIssueval('')
        let value = new Date()
        if ( issue === '' || issue === undefined){
            setIssueval('Fill Issue Value')
            setErrmsg(true)
            return
        }else{
            setIssueval('')
            setErrmsg(false)
        }

        if( editvalue === ''){
          setIssueval('Fill Issue Value')
          setErrmsg(true)
          return
        }
        setErrmsg(false)
        let resss = await axios({
            method : 'post',
            url : `${Base_Url}/requestService`,
            headers: { 
                'Authorization': `Bearer ${user}` 
            },
            data : {
                orderId : id ,
                cartDetailId : cartid ,
                addressId : addressid ,
                issue : issue,
                manualIssue : editvalue ,
                date : value.getFullYear()+ '-' + ((value.getMonth() > 8) ? (value.getMonth() + 1) : ('0' + (value.getMonth() + 1))) + '-' + ((value.getDate() > 9) ? value.getDate() : ('0' + value.getDate()))
            }
        })
        .then((res)=>{
            if(res.data.status === true){
              console.log('sdfsdfsvsv', res.data)
                setOtp(res.data.otp)
                afterotp()
            }else {
                setIssueval('something Went Wrong')
            }
            console.log(res)
        })
        .catch((err)=>{
            setIssueval('something Went Wrong')
            console.log(err)
            if(err.response.status === 401){
              dispatch(LoogIn({show : true}))
              // navigate('/')
            }
        })
    }

    let afterotp = async() =>{
      let responce = await axios({
        method : 'post',
        url : `${Base_Url}/viewServiceRequestedProduct`,
        headers: { 
            'Authorization': `Bearer ${user}` 
        },
        data : {
            orderId : id ,
            cartDetailId : cartid
        }
    })
    .then((res)=>{
      console.log(res.data ,'dbdgbdf dgbdffgb')
        setAlldata(res.data.Data)
        setOotp(true)
        if( no === '0'){
          console.log(res.data.Data.otp,'fgdgfd')
          setOtp(res.data.Data.otp)
        }
    })
    .catch((err)=>{
        console.log(err)
    })
    }

    let optionSel = (e) =>{
        // if(e.target.value === 'None of the above'){
        //     setVvale(true)
        //     setIssue('')
        // }
        // else{
            setVvale(false)
            setIssue(e.target.value)
        // }
    }

    let history = () =>{
      setHistory(!historys)
    }

  return (
    <>
        <Header />
        <div className='pro_home_one  mt-150 mb-150'>
                    <div className='row' >
                    <Profile_home_nav />
                        <div className='col-8'>
                            <div className='pro_home_fifteen'>
                                <div className='d-flex'>
                                <p className='pro_home_eight'>Service Request</p>
                                </div>
                            </div>
                            {
                          ootp === true ? 
                      <div className='mt-5' >
                          <p style={{ color : '#000' , fontSize :  14 , fontWeight : '600'}} >Note:-</p>
                          <p style={{ fontSize : 10 , color : '#000' , fontWeight : '400' , marginTop : -10  }} >Once the issue is Solved share the OTP to our Service engineer</p>
                          <div style={{ backgroundColor : '#EAECEE' , height : 100 , width : '100%' , padding :  10 , borderRadius :  5 }}>
                              <p style={{ fontSize :  18 , color : '#000' , fontWeight : '600' , textAlign : 'center' }} >Service OTP</p>
                              <h4 style={{ textAlign:'center' , letterSpacing : '10px' , fontSize : '30px' , color : '#000' }} className='mt-4'>{otp }</h4>
                          </div>
                      </div>
                      :''

                      }
                            <div className='pro_home_seven mt-3'>
                            <div className='mt-3'>
                                        <div className='row'>
                                            <div className='col-7'>
                                                <p className='hand_twoc'>{alldata?.product.ProName}</p>
                                            </div>
                                            <div className='col-5'>
                                                <img className='mt-3' src={ImageUrl+'/'+alldata?.product.ProImgPrimary} />
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-5'>
                                                <p className='qr_sighteenen'>Serial No</p>
                                            </div>
                                            <div className='col-7'>
                                                <p className='qr_sighteenen'>: {alldata?.detail.serialNo}</p>
                                            </div>
                                            <div className='col-5'>
                                                <p style={{marginTop:'-5px'}} className='qr_sighteenen'>Order ID</p>
                                            </div>
                                            <div className='col-7'>
                                                <p style={{marginTop:'-5px'}} className='qr_sighteenen'>: {alldata?.orderId}</p>
                                            </div>
                                            <div className='col-5'>
                                                <p style={{marginTop:'-5px'}} className='qr_sighteenen'>Subscribtion Period</p>
                                            </div>
                                            <div className='col-7'>
                                                <p style={{marginTop:'-5px'}} className='qr_sighteenen'>: {alldata?.detail.fromDate} - {alldata?.detail.toDate}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p className='hand_twossss mt-2'>Select Issue</p>
                                        <select onChange={(e)=>optionSel(e)} disabled={ ootp === true ? true : false } className="form-select kyc_in_one" aria-label="Default select example">
                                          <option selected={ootp=== true ?'selected' : ''} >{ootp === true ?  alldata?.selectedIssue  :'None of the above'}</option>
                                            {
                                                alldata?.issue.map((data,key)=>
                                                    <option selected={key===0 && ootp=== false ?'selected' : ''} value={data.ServiceMasterName}>{data.ServiceMasterName}</option>
                                                )
                                            }
                                            
                                        </select>
                                    <div>
                                        <textarea disabled={ ootp === true ? true : false } onChange={(e)=>setEditvalue(e.target.value)}  value={ootp === true ?  allData?.manualissue  : editvalue} placeholder="Enter your Issue" className="form-control mt-3" id="exampleFormControlTextarea1" rows="5" ></textarea>
                                      
                                       
                                    </div>
                                    <div className='d-flex justify-content-between mt-3'>
                                        <p style={{fontSize:'15px'}} className='hand_four mt-4'>Service at</p>
                                        {/* <p style={{ cursor : 'pointer' }} onClick={()=>handleClick()} className='hand_five'>Manage Address</p> */}
                                        {/* <div>
                                          <ManageAddress />
                                        </div> */}
                                    </div>
                                   
                                    <div className='row'>
                                            { alladdress?.map((data,key)=>{
                                            return(
                                            <div onClick={()=>switch_data(data,key)}  className='col-6'>
                                            <div className={(sw===key? 'web_cart_col_monec mt-3' : 'web_cart_col_mfivec mt-3')} >
                                                <div className='row'>
                                                <div className='col-10'>
                                                    <p className='web_cart_col_mtwo'>{data.UserDelivAddrFName}</p>
                                                    <p className='web_cart_col_mthree'>{data.UserDelivAddrAddress}</p>
                                                </div>
                                                <div className='col-2'>
                                                    <input style={{marginTop : '29px'}} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={(sw===key ? 'checked' : '')} />
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                            )
                                    })
                                    } 
                                </div>
                                {/* <div className='d-flex justify-content-between mt-3'>
                                  <p className='hand_four'>Service History</p>
                                  <img onClick={()=>history()} style={{width : '10px' , height : '8px' , cursor :'pointer'}} className={historys === false ? '' : 'ser_his_p_five' } src={down} />
                                </div>
                                <div className='row'>
                                {
                                  historys === true ?<>
                                  {
                                    alldata?.serviceDetails.map(( data , key ) =>
                                    {
                                      return(
                                        <div className='col-6'>
                                          <div className='ser_his'>
                                            <div className='d-flex justify-content-between  '>
                                              <p className={data.ServiceStatus === 0 ? 'ser_his_p_four' : data.ServiceStatus === 1 ? 'ser_his_p' : 'ser_his_p' }>{data.ServiceStatus === 0 ? 'Requested' : data.ServiceStatus === 1 ? 'Serviced' : 'Replaced' }</p>
                                              <p className='ser_his_p_two'>{data.RequestedDate}</p>
                                            </div>
                                            <hr />
                                            <div className='row'>
                                              <div className='col-6'>
                                                <p className='ser_his_p_three'>Service No</p>
                                              </div>
                                              <div className='col-6'>
                                                <p className='ser_his_p_three'>: {data.ServiceId}</p>
                                              </div>
                                            </div>
                                            <div className='row'>
                                              <div className='col-6'>
                                                <p className='ser_his_p_three'>Prodect ID</p>
                                              </div>
                                              <div className='col-6'>
                                                <p className='ser_his_p_three'>: Requested</p>
                                              </div>
                                            </div>
                                            <div className='row'>
                                              <div className='col-6'>
                                                <p className='ser_his_p_three'>Issue Faced</p>
                                              </div>
                                              <div className='col-6'>
                                                <p className='ser_his_p_three'>: {data.Issue}</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    }
                                    )
                                  }
                                  </>
                                  
                                   : ''
                                }

                                </div> */}
                                {
                                  errmsg === true ?
                                  <p style={{ color : 'red' , fontSize :  14 , textAlign : 'center' }}>{issueval}</p> 
                                  : ''
                                }

                                {
                                  ootp ===  true ? "" :
                                  <div style={{cursor : 'pointer' }} className='qr_fifteenc'>
                                    <div onClick={()=>lastApi()} className='qr_sixteenc'>
                                        <p className='qr_sixteens'>Request Service</p>
                                    </div>
                                </div>
                                }
                                
                               
                            </div>
                        </div>
                    </div>
                </div>

                <ChakraProvider >
            <Modal isCentered onClose={onClose} size='lg' isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent>
              {
                newAddresses ? <ModalHeader>{editupdate ?'Edit Address' : 'Add New Address'}</ModalHeader> :  <ModalHeader>All Address</ModalHeader>
              } 
              <ModalCloseButton />
              {/* <hr /> */}
              <ModalBody>

                {
                  newAddresses ?

                  <>
                  <p onClick={()=>setnewAddresses(!newAddresses)} className='web_cart_col_four ' style={{marginRight:'24px', color:'#F4B644' , cursor:'pointer' , marginTop:'-15px' , marginBottom:'15px' }} >All Address</p>

                    <div className='row'>
                      <div className='col-6 '>
                        <FormLabel htmlFor="name">First Name</FormLabel>
                        <Input className={( fnames ? 'inputttt_red' : '')} onChange={(e)=>setFname(e.target.value)} value={fname} id="name" type="text" />
                        {
                          fnames ? <p style={{color:'red',fontSize:'9px'}}>Enter Valid Name</p> : ''
                        }
                      </div>
                      <div className='col-6'>
                        <FormLabel htmlFor="name">Last Name</FormLabel>
                        <Input className={( lnames ? 'inputttt_red' : '')} onChange={(e)=>setLname(e.target.value)} value={lname} id="name" type="text" />
                        {
                          lnames ? <p style={{color:'red',fontSize:'9px'}}>Enter Valid Name</p> : ''
                        }
                      </div>
                      <div className='col-6 mt-2'>
                        <FormLabel htmlFor="name">City</FormLabel>
                        <Input className={( citys ? 'inputttt_red' : '')} onChange={(e)=>setCity(e.target.value)} value={city} id="name" type="text" />
                        {
                          citys ? <p style={{color:'red',fontSize:'9px'}}>Enter Valid CityName</p> : ''
                        }
                      </div>
                      <div className='col-6 mt-2'>
                        <FormLabel htmlFor="name">State</FormLabel>
                        <Input className={( states ? 'inputttt_red' : '')} onChange={(e)=>setState(e.target.value)} value={state} id="name" type="text" />
                        {
                          states ? <p style={{color:'red',fontSize:'9px'}}>Enter Valid StateName</p> : ''
                        }
                      </div>
                      <div className='col-6 mt-2'>
                        <FormLabel htmlFor="name">Postal Code</FormLabel>
                        <Input className={( pincodes ? 'inputttt_red' : '')} onChange={(e)=>setPincode(e.target.value)} value={pincode} id="name" type="number" />
                        {
                          pincodes ? <p style={{color:'red',fontSize:'9px'}}>Enter Valid Pincode</p> : ''
                        }
                      </div>
                      <div className='col-6 mt-2'>
                        <FormLabel htmlFor="name">Location</FormLabel>
                        <Input className={( geocodes ? 'inputttt_red' : '')} onChange={(e)=>setGeocode(e.target.value)} value={geocode} id="name" type="text" readonly />
                        {
                          geocodes ? <p style={{color:'red',fontSize:'9px'}}>Enter Your Location</p> : ''
                        }
                      </div>
                    </div>

                     <FormLabel className='mt-2' htmlFor="name">Address</FormLabel>
                    <Input className={( newaddresss ? 'inputttt_red' : '')} onChange={(e)=>setNewaddress(e.target.value)} value={newaddress} id="name" type="text" />
                    {
                      newaddresss ? <p style={{color:'red',fontSize:'9px'}}>Enter Valid Address</p> : ''
                    }
    
                    <FormLabel className='mt-2' htmlFor="name">Appartment, Suite, Villa, Etc...</FormLabel>
                    <Input className={( subaddresss ? 'inputttt_red' : '')} onChange={(e)=>setSubaddress(e.target.value)} value={subaddress} id="name" type="text" />
                    {
                      subaddresss ? <p style={{color:'red',fontSize:'9px'}}>Enter Valid Address</p> : ''
                    }

                  </>
                  :
                  <>
                    <p onClick={()=>adnewwww()} className='web_cart_col_four ' style={{marginRight:'24px', color:'#F4B644' , cursor:'pointer' , marginTop:'-15px' , marginBottom:'15px' }} >Add New Address</p>
                    <div className='row over_model' >

                      { alladdress?.map((data,key)=>{
                        return(
                        <div onClick={()=>switch_data(data,key)}  className='col-12'>
                          <div className={(sw===key? 'web_cart_col_moness mt-3' : 'web_cart_col_mfivess mt-3')} style={{marginLeft:'14px'}}>
                            <div className='row'>
                              <div className='col-10'>
                                <p className='web_cart_col_mtwo'>{data.UserDelivAddrFName}</p>
                                <p className='web_cart_col_mthree'>{data.UserDelivAddrAddress}</p>
                              </div>
                              <div className='col-2'>
                                {/* <input style={{marginTop : '29px'}} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={(sw===key ? 'checked' : '')} /> */}
                              </div>
                            </div>
                            <div className='d-flex justify-content-end'>
                              <div onClick={()=>editAddress(data,key)} className='edit_address'>
                                <p className='edit_address_p'>Edit</p>
                              </div>
                              <div onClick={()=>deleteAddress(data,key)} className='delete_address'>
                                <p className='delete_address_p'>Delete</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        )
                      })
                      }

                    </div>
                  </>
                } 

              </ModalBody>

              <ModalFooter>
              {
                newAddresses ?
                <>
                  <Button mr={3} onClick={onClose}>Close</Button>
                  {
                    editupdate ? <Button onClick={()=>Update()} color='white' colorScheme='yellow' >Update</Button>
                    :
                    <Button onClick={()=>Addsub()} color='white' colorScheme='yellow' >Submit</Button>
                  }
                  
                </>
                :
                <Button onClick={onClose}>Close</Button>
              }
              </ModalFooter>

            </ModalContent>
          </Modal>
        </ChakraProvider>
        <Footer />
        <ToastContainer />
        <Modalss open={open} onClose={onCloseModal} center>
        <p className='hand_foot_one mt-4'>Service Requested</p>
        <p className='hand_foot_two'>Our Technician is on the way</p>
        <Link to='/' >
            <div className='hand_foot_three mb-3'>
                <p className='hand_foot_five mt-2'>Back to home</p>
            </div>
        </Link>
      </Modalss>
    </>
  )
}
