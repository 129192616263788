import React , { useState , useEffect } from 'react'
import file from '../../../assets/img/file.png'
import pans from '../../../assets/img/pan.png'
import delect from '../../../assets/img/delect.png'
import kyc3 from '../../../assets/img/kyc3.png'
import headset from '../../../assets/img/ggerhead.png'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { ImageUrl , Base_Url } from '../../globalvariable/globe'
import ReactLoading from 'react-loading';
import axios from 'axios'
import Dot_arrow from '../cart_one/dot_arrow'

export default function Mob_kyc_three() {

    let navigate = useNavigate()
    let formData = new FormData();

    const [ img , setImg ] = useState()
    const [ data , setData ] = useState('')
    const user = localStorage.getItem('user_data')
    const [ loading , setLoading ] = useState(false)
    const [ pan , setpan ] = useState('')

    const [ valiaddno , setValiaddno ] = useState(false)
    const [ suskyc , setSuskyc ] = useState(false)

    const [ value1 , setValue1 ] = useState()

    const [ pendkyc , setPendkyc ] = useState(false)
    const [ rejkyc , setRejkyc ] = useState(false)
    const [ kycstatus , setKcstatus ] = useState()
    const [ good , setGood ] = useState(false)
    const [ alldata , allsetData ] = useState('')

    const errnotify = (data) => toast.error( data , {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

    useEffect(()=>{
        setLoading(false)
        setSuskyc(false)
        Alldata()
    },[])

    const Alldata = async () => {
        if(user===null){
            navigate('/')
            return
        }

        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/kycUserPan`,
            headers: { 
                'Authorization': `Bearer ${user}` 
            }
        })
        .then((res)=>{
            allsetData(res.data)
            setKcstatus(res.data.Data.KycApproval)
            if(res.data.Data.KycApproval === 1 ){
              setSuskyc(true)
            }
            else if(res.data.Data.KycApproval === 0 ){
              setPendkyc(true)
            }else if(res.data.Data.KycApproval === 2 ){
              setRejkyc(true)
            }
            if(res.data.status === true){
              setData(res.data.Data.KycUserPanImage)
              setpan(res.data.Data.KycDetail.KycUserPanNo)
              setValue1(res.data.Data.KycDetail.KycUserPanNo)
            }else{
              setPendkyc(true)
              
            }
            console.log(res)
        })
        .catch((err)=>{
            if(err.response.status === 401){
                navigate('/login')
            }
        })
    }

    const imgFile = (e) => {
        setData('')
        setImg({'img' : URL.createObjectURL(e.target.files[0])})
        setGood(false)
    }

    const remove = () =>{
        document.querySelector('#file').value = "";
        setImg()
        setData('') 
        setGood(true)
    }

    const Clicked = async() => {
        setLoading(true)

        if(good === true){
            setLoading(false)
            errnotify('Upload Image')
            return
        }

        if( pan === '' ){
            setValiaddno(true)
            setLoading(false)
            return
        }else{
            setValiaddno(false)
        }

        let imagefile = document.querySelector('#file');

        if(imagefile == null){
            console.log('worked')
            errnotify('Upload Image')
            setLoading(false)
            return
          }

        if(imagefile.files[0] == undefined){
            console.log('worked')
            errnotify('Upload Image')
            setLoading(false)
            return
          }

        formData.append("panImg", imagefile.files[0]);
        formData.append("panNo", pan);
        console.log(pan,' ',value1)
        if( value1 === pan ){

        }else{
            let responce = await axios.post( `${Base_Url}/kycUserPan`, formData, {
                headers: {
                    'Authorization': `Bearer ${user}` ,
                    'Content-Type': 'multipart/form-data'
                } 
            })
            .then((res)=>{
                navigate('/kyc_four')
                setLoading(false)
                console.log(res)
            })
            .catch((err) =>{
                setLoading(false)
                if(err.response.status === 401){
                    navigate('/login')
                }
                console.log(err)
            })
            return
        }
        if(data != ''){
            navigate('/kyc_four')
            setLoading(false)
            return
        }
        let responce = await axios.post( `${Base_Url}/kycUserPan`, formData, {
            headers: {
                'Authorization': `Bearer ${user}` ,
                'Content-Type': 'multipart/form-data'
            } 
        })
        .then((res)=>{
            navigate('/kyc_four')
            setLoading(false)
            console.log(res)
        })
        .catch((err) =>{
            setLoading(false)
            if(err.response.status === 401){
                navigate('/login')
            }
            console.log(err)
        })
    }

    const Clickedone = () => {
        navigate('/kyc_four')
    }

    const addharChange = (e) =>{
        setpan(e.target.value.slice(0, 10))
        if(pan.length >= 12 ){
            errnotify('Only 12 Value Allowed')
            return
        }
    }

    const gotosupport =()=>{
        navigate('/support')
    }

  return (
    <>
     {
                  kycstatus === 1 ? '' : kycstatus === 0 ? <img style={{ width : '100%' }} src={kyc3} /> : alldata?.status === false ?  <img style={{ width : '100%' }} src={kyc3} />   : 
                   kycstatus === 2 ? <img style={{ width : '100%' }} src={kyc3} />   : ''
                }
    <div className='mt-4'>
          <div className='row'>
              <div className='col-2'>
              { 
                              alldata?.status === false ? 
                              <div class="donut-ring">
                                <div className={kycstatus === 1 ? "ring red" : "ring red"}></div>
                                <div className={kycstatus === 1 ? "ring blue" : "ring bluethree"}></div>
                                <div className={kycstatus === 1 ? "ring orange" : "ring orangetwo"}></div>
                                <div className={kycstatus === 1 ? "ring grrreeeennn4" : "ring green"}></div>
                                <div className="cutout">
                                  <p className='kyc_p_one'>3 Of 4</p>
                                </div>
                              </div>
                              : kycstatus === 1 ? 
                                <div class="donut-ring">
                                  <div className={kycstatus === 1 ? "ring red" : "ring red"}></div>
                                  <div className={kycstatus === 1 ? "ring bluethreeeapp" : "ring blue"}></div>
                                  <div className={kycstatus === 1 ? "ring orangegreenss" : "ring orange"}></div>
                                  <div className={kycstatus === 1 ? "ring grrreeeennn4" : "ring green"}></div>
                                  <div className="cutout">
                                    <p className='kyc_p_one'>3 Of 4</p>
                                  </div>
                                </div>
                              :
                               kycstatus === 0 ? 
                                <div class="donut-ring">
                                  <div className={kycstatus === 1 ? "ring red" : "ring red"}></div>
                                  <div className={kycstatus === 1 ? "ring blue" : "ring bluethreee"}></div>
                                  <div className={kycstatus === 1 ? "ring orange" : "ring orangetwoss"}></div>
                                  <div className={kycstatus === 1 ? "ring grrreeeennn4" : "ring yellowonw"}></div>
                                  <div className="cutout">
                                    <p className='kyc_p_one'>3 Of 4</p>
                                  </div>
                                </div>
                              :
                              kycstatus === 2 ? 
                              <div class="donut-ring">
                                <div className="ring red"></div>
                                <div className="ring bluethreeeappred"></div>
                                <div className="ring orangered444"></div>
                                <div className= "ring redones"></div>
                                <div className="cutout">
                                  <p className='kyc_p_one'>3 Of 4</p>
                                </div>
                              </div>
                            : 
                            ''

                            }
              </div>
                <div className='col-10'>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <p className='kyc_p_two'>Step 3</p>
                            <p style={{ marginLeft : 10 }} className='kyc_p_four'>Pancard</p>
                        </div>
                    
                        {
                              kycstatus === 0 ? <p style={{ color: '#cfcf00', fontSize: '12', fontWeight: '600' }} >Pending</p> : kycstatus === 1 ? <p style={{ color: 'green', fontSize: '12', fontWeight: '600' }} >Approved</p> : kycstatus === 2 ? <p style={{ color: 'red', fontSize: '12', fontWeight: '600' }} >Rejected</p> : ''
                            }
                    </div>
                </div>
          </div>
      </div>
      <div className='mt-4'> 
          <div>
            <p className='kyc_two_para'>Pancard No</p>
            <input type="text" onChange={(e)=>addharChange(e)} value={pan} className="form-control kyc_in_one" id="exampleInputEmail1" disabled={suskyc ? 'disabled' : ''} aria-describedby="emailHelp" placeholder="Pan no" />
              {
                  valiaddno ? <p style={{color:'red',fontSize:'9px'}}>Enter Valid Pan No</p> : ''
              }
          </div>
        </div>
      <div className='mt-3 pb-5'>
        <p className='kyc_p_four'>Upload Pan Card</p>
          <div style={{marginBottom:'50px'}} className='kyc_cont'>
          {
              data ? <img className='kyc_img_one' style={{ width : '100%' }} src={ ImageUrl + '/' + data } />  : img ? <img className='kyc_img_one' style={{ width : '100%' }} src={img.img} /> : <img className='kyc_img_one img_opacttty' style={{ width : '100%' }} src={pans} />
          }      
              <div class="kyc_centered">
              {(() => {
              if(suskyc == false || rejkyc == true || (pendkyc == true) &&  (data == '')){ return(
                  <div className='kyc_cont_two'>
                      <div className='d-flex justify-content-around'>
                          <div className='kyc_cont_two_upload mt-2'>
                              <img className='kyc_cont_two_upload_two kyc_img_one kyc_img_one' src={file} />
                              <input style={{ width :  50 }}  onChange={(e)=>imgFile(e)} accept="image/*" name='file' className='kyc_cont_two_upload_three' id='file' type="file" />
                          </div>
                          <div className='mt-2 kyc_line'>
                          </div>
                          <div className=' mt-2'>
                              <img onClick={()=>remove()} src={delect} />
                          </div>
                      </div>
                  </div>
                   )
                }
                  })()}
              </div>
          </div>
      </div>

      <div className='mob_footss'>
          <div className='row'>
              <div className='col-6'>
                  <div onClick={()=>gotosupport()} className='d-flex'>
                      <div className='headset'>
                        <img className='kaart' src={headset} />
                      </div>
                      <div>
                        <p className='compara222'>Support</p>
                        <p className='compara333'>9am - 5Pm</p>
                      </div>
                  </div>
              </div>
              <div className='col-6'>
              {(() => {

if((suskyc == true)){
 return(<div onClick={()=> Clickedone() } className='bbtn'>
                     <div className='d-flex'>
                         <p style={{cursor:'pointer'}} className='compara nextonee mt-1'>{loading ?
                         <div style={{marginLeft:'-47px' , marginTop : '-4px'}}>
                           <ReactLoading type='balls' color='#ffffff' />
                         </div>
                            : 'Next'}</p>
                     </div>
                 </div>)
}else if((pendkyc == true) &&  (data != '')){
 return(<div onClick={()=> Clickedone() } className='bbtn'>
 <div className='d-flex'>
     <p style={{cursor:'pointer'}} className='compara nextonee mt-1'>{loading ?
     <div style={{marginLeft:'-47px' , marginTop : '-4px'}}>
       <ReactLoading type='balls' color='#ffffff' />
     </div>
        : 'Next'}</p>
 </div>
</div>)
}else if((pendkyc == true) &&  (data == '')){
 return(<div onClick={()=> Clicked() } className='bbtn'>
                     <div className='d-flex'>
                         <p style={{cursor:'pointer'}} className='compara nextonee mt-1'>{loading ?
                         <div style={{marginLeft:'-47px' , marginTop : '-4px'}}>
                           <ReactLoading type='balls' color='#ffffff' />
                         </div>
                            : 'Next'}</p>
                     </div>
                 </div>)
}else if((rejkyc == true)){
 return(<div onClick={()=> Clicked() } className='bbtn'>
                     <div className='d-flex'>
                         <p style={{cursor:'pointer'}} className='compara nextonee mt-1'>{loading ?
                         <div style={{marginLeft:'-47px' , marginTop : '-4px'}}>
                           <ReactLoading type='balls' color='#ffffff' />
                         </div>
                            : 'Next'}</p>
                     </div>
                 </div>)
}else{
    return(
        ''
    )
}

               })()}
              </div>
          </div>
      </div>
      <ToastContainer
       
        />
        {/* Same as */}
    </>
  )
}