import React , { useState } from 'react'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'
import Arrow_payment from '../../components/mobile_view_comp/payment/arrow_payment'
import Mob_payment from '../../components/mobile_view_comp/payment/mob_payment'
import Mob_pay_foot from '../../components/mobile_view_comp/payment/mob_pay_foot'
import { Link , useParams  } from 'react-router-dom'

export default function Payment(props) {

  const {id} = useParams()

  console.log(id)

  return (
    <>
      { id === '0' ?
        <div style={{width:'90%' , margin:'auto' ,marginTop:'65px'}}>
            <Cart_one_head link='/cart' head='Payment' />
            <Mob_payment />
            {/* <Mob_pay_foot val='1' link='/payment_two' /> */}
        </div>
        :
        <div style={{width:'90%' , margin:'auto' ,marginTop:'65px'}}>
            <Cart_one_head link='/kyc_five' head='Payment' />
            <Arrow_payment />
            <Mob_payment />
            {/* <Mob_pay_foot val='0' link='/payment_two' /> */}
        </div>
      }
    </>
  )
}