import React from 'react'
import { Link } from 'react-router-dom'

export default function slider_data(props) {
    let img = props.urls+'/'+props.data.BannerAppImage
  return (
    <>
    
    {img ? 
    <div
     className={` slider-height-6  d-flex align-items-center ${
      props.sliderClass ? props.sliderClass : ""
    }`} 
  >
    
    <div className="container" >
    
        <div  className="row align-items-center slider-h9-mrg">
          {/* <div className="col-6">
            
            <div className="slider-content-7 slider-animated-1">
            
                <h1  className="animated heaone">{props.data.BannerTitle}</h1>
                <div className="slider-btn-9 btn-hover">
                <Link className="animated btton" to='/collecion'>SHOP NOW</Link>
                </div>
            </div>
            
          </div> */}
          <div className="col-12">
          <div className="">
                    <img style={{    width: '-webkit-fill-available'}}
                      className="animated bounce img-fluid"
                      src={img}
                      alt=""
                    />
                </div>
          </div>

        </div>
      </div>
</div>
:''}
</>
  )
}