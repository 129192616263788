import React from 'react'
import Pro_head from '../../components/mobile_view_comp/mob_pro_detail/pro_head'
import Mob_pro_de from '../../components/mobile_view_comp/mob_pro_detail/mob_pro_de'


export default function Mobile_pro_detail() {
  
  return (
    <>
        <div style={{width:'90%' , margin:'auto' , paddingTop:'75px' }}>
            {/* <Pro_head link='/product_detail' />  */}
            <Mob_pro_de />
        </div>
    </>
  )
}