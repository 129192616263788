import React, { useState , useEffect } from 'react'
import '../assets/css/product.css'
import { useSelector  } from 'react-redux';
import Skeleton from 'react-loading-skeleton' 
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import ph_imggg from '../assets/img/ph_imggg.png'
import { useDisclosure,Modal,ChakraProvider, ModalContent, ModalOverlay,ModalCloseButton, color } from '@chakra-ui/react';

export default function Fun_facts() { 

    const responsive = {
        0: { items: 1 }, 
        768: { items: 1},
        1056: { items: 2 },
        1400 : {items: 3},
        1850: { items: 3 },
    };


    let loadPages = [ 1 , 2 , 3 , 4 , 5 , 6 , 7 , 8 , 9 , 10 , 11 , 12 ];

    const product =  useSelector((state)=>state)
    const datas = product.allProducts.products.didYouKnow

    const [ wish ,  setWish ] = useState()

    const [ modalvalue , setModalval ] = useState('')
    const OverlayOne = () => (

        <ModalOverlay />
        )
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [overlay, setOverlay] = React.useState(<OverlayOne />)
    const readmorefn = (key)=>{
        setModalval(key)
        onOpen()
    }


    const items =  datas && datas?.map((data,key)=>
    <div style={{width: '95%' , height : 'auto' , backgroundColor : '#161E40' , padding : 12 , borderRadius : 5}}  key={key} >
       <div className='row' >
            <div className='col-4'>
                <img  style={{width : '100%' , height : '100%'}} src={product.allProducts.products.awsimageurl+'/'+data.image} />
            </div>
            <div className='col-8'>
                <p style={{fontSize : 12 ,  fontWeight : 900 , color : '#fff'}} >Did You Know ??</p>
                <p style={{fontSize : 8 ,  fontWeight : 600 , color : '#A8A8A8'}}>#{data.Brand}</p>
                <p style={{fontSize : 12 ,  fontWeight : 600 , color : '#fff'}} >{data.title}</p>
                <p style={{fontSize : 10 ,  fontWeight : 500 , color : '#B3B3B3'}} >{data.description.slice(0,400)}...</p>
                {/* 100 words 700 letters */}
                <p onClick={() => readmorefn(key) } style={{fontSize : 10 ,  fontWeight : 500 , color : '#F4B644' , textDecoration : 'underline', cursor : 'pointer'}}>Read more</p>
            </div>
       </div>
    </div>,
  ) 

  const modalitems =  datas && datas?.map((data,key)=>
    <div style={{width: '95%' , height : 'auto' , backgroundColor : '#161E40' , padding : 12 , borderRadius : 5}}  key={key} >
       <div className='row' >
           
            <div className='col-12'>
                <p style={{fontSize : 12 ,  fontWeight : 900 , color : '#fff'}} >Did You Know ??</p>
                <p style={{fontSize : 8 ,  fontWeight : 600 , color : '#A8A8A8'}}>#{data.Brand}</p>
                <p style={{fontSize : 12 ,  fontWeight : 600 , color : '#fff'}} >{data.title}</p>
                <p style={{fontSize : 10 ,  fontWeight : 500 , color : '#B3B3B3'}} >{data.description}</p>
            </div>
       </div>
    </div>
  ) 


            
  return (
    <> 
     <div >
      <div className='row mt-2'>
            <div className='container web_cat_five'>
                {
                    datas?.length === 0 || datas === undefined ? '' :  <h1 className='pop_head_tit'>Fun Facts</h1>
                }
               
            </div>
            <div className='mt-5' >
                {
                     datas?.length === 0 || datas === undefined ? '' : <AliceCarousel key="carousel" disableButtonsControls={true} mouseTracking items={items} infinite = {true}  disableDotsControls={true}  responsive={responsive} /> 
                }
                
                
            </div>
            
        </div>
        <div className='row mt-2'>
            {
                !datas && loadPages.map ((data)=>{
                    return(
                        <div  className="col-md-6 col-lg-4 col-xl-4 col-sm-6 col-xxl-3 home_pro_cont" style={{marginTop:'40px' }}>
                            <div class="product-grid" >
                                <Skeleton height={300} />
                            </div>
                        </div>
                    )
                })
                
            }
        </div>
    </div> 
    {
        datas?.length === 0 || datas === undefined ? '' :
        <ChakraProvider>
        <Modal isCentered isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
            {overlay}
            <ModalContent>
            <ModalCloseButton style={{color:'#fff'}} />
                <p style={{ backgroundColor : '#161E40' , padding : 12 , borderRadius : 5}}>{modalitems[modalvalue]}</p>
            </ModalContent>
        </Modal>
    </ChakraProvider>
    }
    
</>
  )
}

    