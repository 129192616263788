import React , { useState , useEffect } from 'react'
import Header from '../../../common/head_foot/header_two'
import Footer from '../../../common/footer'
import Profile_home_nav from '../../../page/profile_home/profile_home_nav'
import './serviced_web.css'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
import { useParams , useNavigate } from 'react-router-dom'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    ChakraProvider,
    Text,
    Button,
    useDisclosure
  } from '@chakra-ui/react'
import { Base_Url , ImageUrl } from '../../globalvariable/globe'
import { useDispatch } from 'react-redux'
import { LoogIn } from '../../../redux/actions/productAction'
import hppy from '../../../assets/img/hppy.png'
import smmill from '../../../assets/img/smmill.png'
import saad from '../../../assets/img/saad.png'

import color_sad from '../../../assets/img/color_sad.png'
import color_happy from '../../../assets/img/color_happy.png'
import color_ok from '../../../assets/img/color_ok.png'

export default function Serviced_web() {

    let navigate = useNavigate()

    let dispatch = useDispatch()

    const [ rating , setRating ] = useState()

    const [ feeddata , setFeeeddata ] = useState()
 
    const { isOpen, onOpen, onClose } = useDisclosure()

    let { id , cartid , serial } = useParams()
 
    const [ alldata , setAlldata ] = useState() 
    const [ serviceId , setServiceId ] = useState()

    let user = localStorage.getItem('user_data')

    const successnotify = (sus) => toast.success( 'Message Updated Success' , {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const start = async ()=>{
        if(user === null){
            navigate('/')
            return
          }
        let respon = await axios({
            method : 'post' ,
            url : `${Base_Url}/viewServicedProduct`,
            headers: { 
                'Authorization': `Bearer ${user}` 
            },
            data : {
                orderId : id,
                ProSerialNoId : serial,
                cartDetailId : cartid
            }
        })
        .then((res)=>{
            setServiceId(res.data.Data.serviceDetails.serviceId)
            setAlldata(res.data.Data)
            console.log(res)
        })
        .catch((err)=>{
            if(err.response.status === 401){
                dispatch(LoogIn({show : true}))
                // navigate('/')
              }
            console.log(err)
        })
    }

    const Feedback =async () =>{
        let responce = await axios({
          method : 'post',
          url : `${Base_Url}/serviceFeedback`,
          headers: { 
            'Authorization': `Bearer ${user}` 
          },
          data : {
              serviceId : serviceId ,
              rating : rating,
              description :feeddata 
          }
        })
        .then((res)=>{
          onClose()
          successnotify()
          console.log(res)
        })
        .catch((err)=>{
            if(err.response.status === 401){
                dispatch(LoogIn({show : true}))
                // navigate('/')
              }
          console.log(err)
        })
      }

    useEffect(()=>{
        // onOpen()  
        start()
    },[])

    const ratingChanged = (newRating) => {
        setRating(newRating);
    };

    const clicked = () =>{
        setFeeeddata('')
        onOpen()
    }

  return ( 
      <>
        <Header />
        <div className='pro_home_one  mt-150 mb-150'>
          <div className='row' >
            <Profile_home_nav />
                <div className='col-8'>
                    <div className='pro_home_fifteen'>
                        <div className='d-flex'>
                        <p className='pro_home_eight'>Serviced</p>
                        </div>
                    </div>
                    <div className='pro_home_seven mt-3'>
                    <div>
                        <div className='mt-3'>
                            <div className='row'>
                                <div className='col-7'>
                                    <p className='hand_one mt-1'>{alldata?.product.BrandName}</p>
                                    <p className='hand_twod'>{alldata?.product.ProName}</p>
                                    {/* <p className='hand_threed'>Apple M1 / 8‐core CPU / GPU / 256/ 8gb 13.7” Retina Display</p> */}
                                </div>
                                <div className='col-5'>
                                    <img className='mt-3' src={ImageUrl+'/'+alldata?.product.ProImgPrimary} />
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-5'>
                                    <p className='qr_sighteenen'>Serial No</p>
                                </div>
                                <div className='col-7'>
                                    <p className='qr_sighteenen'>: 
                                    {serial} 
                                    </p>
                                </div>
                                <div className='col-5'>
                                    <p style={{marginTop:'-5px'}} className='qr_sighteenen'>Order ID</p>
                                </div>
                                <div className='col-7'>
                                    <p style={{marginTop:'-5px'}} className='qr_sighteenen'>: {id}</p>
                                </div>
                                <div className='col-5'>
                                    <p style={{marginTop:'-5px'}} className='qr_sighteenen'>Subscribtion Period</p>
                                </div>
                                <div className='col-7'>
                                    <p style={{marginTop:'-5px'}} className='qr_sighteenen'>: {alldata?.product.CartProBookFrom} - {alldata?.product.CartProBookTo}</p>
                                </div>
                            </div>
                        </div>
                        <p className='mt-4 qr_sighteenen'>Issue Faced</p>
                        <p style={{ fontSize : 10  , marginTop : 10 }}>{alldata?.serviceDetails?.Issue}</p>
                        <div className='servicedd_p '>
                            <p className='servicedd_p_one'>{alldata?.serviceDetails?.manualIssue}</p>
                        </div>
                        <div onClick={()=>clicked()} className='servicedd_p_twooo mt-4'>
                            <p className='servicedd_p_fivee'>Serviced</p>
                        </div>
                    </div> 
                    <div className='popup_one'>
                        <ChakraProvider>
                            <Modal isOpen={isOpen} onClose={onClose}>
                                <ModalOverlay />
                                <ModalContent>
                                        <ModalHeader>Did You Enjoy Our Service
                                        </ModalHeader>
                                    <ModalCloseButton />
                                        <>
                                        
                                  
                                            <ModalBody>
                                            <div style={{ marginTop : -15 }} className='d-flex justify-content-start' >
                                            <div style={{ marginRight : 30 }} >
                                                {
                                                rating === 1 ? <img onClick={()=>{setRating(1)}} className='pro_home_three' src={color_sad} /> :  <img onClick={()=>{setRating(1)}} className='pro_home_three' src={saad} />
                                                }
                                            
                                                <p style={{ color : '#000' , fontSize :  14 , fontWeight :  '600' , textAlign : 'center' , marginTop : 5 , marginBottom : 10 }} >Bad</p>
                                            </div>
                                            <div style={{ marginRight : 30 }} >
                                                {
                                                rating === 2 ? <img onClick={()=>{setRating(2)}} className='pro_home_three' src={color_happy} /> : <img onClick={()=>{setRating(2)}} className='pro_home_three' src={smmill} />
                                                }
                                                <p style={{ color : '#000' , fontSize :  14 , fontWeight :  '600' , textAlign : 'center' , marginTop : 5 , marginBottom : 10 }} >Fair</p>
                                            </div>
                                            <div >
                                                {
                                                rating === 3 ? <img onClick={()=>{setRating(3)}} className='pro_home_three' src={color_ok} /> : <img onClick={()=>{setRating(3)}} className='pro_home_three' src={hppy} />
                                                }
                                                
                                                <p style={{ color : '#000' , fontSize :  14 , fontWeight :  '600' , textAlign : 'center' , marginTop : 5 , marginBottom : 10 }} >Good</p>
                                            </div>
                                        </div>
                                                <Text fontWeight='bold' mb='1rem'>
                                                Tell us what can be improved !
                                                </Text>
                                                <textarea onChange={(e)=>setFeeeddata(e.target.value)} value={feeddata} className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                            </ModalBody> 

                                            <ModalFooter>
                                                <Button onClick={()=>Feedback()} style={{backgroundColor:'#161E40' , color:'white'}}>Submit Feedback</Button>
                                            </ModalFooter>
                                        </>
                                        
                                </ModalContent>
                            </Modal>
                        </ChakraProvider>
                    </div>
                    <ToastContainer />

                    </div>
                </div>
            </div>
          </div>
        <Footer />
      </>
  )
}
