import React from 'react'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import frame from '../../../assets/img/frame.png'
import { ImageUrl } from '../../globalvariable/globe';

export default function Mob_pro_slider(props) {
  console.log(props,'propsssss')
  return (
    <>
    

      <div style={{ backgroundColor:' #F4F4F4',paddingBottom: '20px'}} className="slide-container">
        <Slide arrows={false} autoplay={true} transitionDuration={100} indicators={true}>
        {props.data.map((dat,key)=>
          <div className="each-slide" key={key}>
            <div className='slliderr_bosss' style={{backgroundImage: `url('${ImageUrl+'/'+dat.ProImgName}')`}}>
              </div>
          </div>
          )
        }
        </Slide>
      </div>
    </>
  )
}