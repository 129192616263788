import React from 'react'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'
import Mob_otp_two from './otp_two/mob_otp_two'

export default function Otp_two() {
  return (
    <>
        <div style={{width:'90%' , margin:'auto' ,marginTop:'65px'}}>
            <Cart_one_head link='/otp' head='Otp' />
            <Mob_otp_two />
        </div>
    </>
  )
}