import React from 'react'
import Bank_detail_comp from '../../components/mobile_view_comp/bank_detail_comp/bank_detail_comp'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'

export default function Bank_detail() {
  return (
    <>
        <div style={{width:'90%' , margin:'auto' , paddingBottom:'100px' , paddingTop:'65px'}}>
            <Cart_one_head link='/profile' head='Bank Details' />
            <Bank_detail_comp />
        </div>
    </>
  )
}
