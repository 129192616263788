import React from 'react'
import Service_hand_status_comp from '../../components/mobile_view_comp/service_hand_status/service_hand_status_comp'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'
import Service_hand_foot from '../../components/mobile_view_comp/service_hand_status/service_hand_foot'

export default function Service_hand_status() {
  return (
        <>
            <div style={{width:'90%' , margin:'auto' ,marginTop:'65px' , marginBottom:'60px'}}>
                <Cart_one_head link='/order' head='Running Orders' />
                <Service_hand_status_comp />
            </div> 
            {/* <Service_hand_foot /> */}
        </>
    )
}
