import React , { useEffect, useState } from 'react'
import Cart_summ from '../components/fullwidth/cart_summery/cart_summ'
import Header_two from '../common/head_foot/header_two'
import Footer from '../common/footer'
import axios from 'axios'
import { useDispatch } from 'react-redux' 
import { ViewCart , LoogIn } from '../redux/actions/productAction'
import { Base_Url } from '../components/globalvariable/globe'
import { useParams , useNavigate } from 'react-router-dom'


export default function Cart_summery() {

  const {id} = useParams()

  let dispatch = useDispatch();

  let navigate = useNavigate()


  const allData = async()=> {

    let user = localStorage.getItem('user_data')

    if ( user === null ){
      navigate('/')
      return
    }
    let responce = await axios({
            method : 'post',
            url : `${Base_Url}/viewOrderProductDetails` ,
            headers: { 
                'Authorization': `Bearer ${user}` 
            },
            data : {
              orderId : id
            }
        })
        .then((res)=>{
          console.log(res , 'resssss')
          if( res.data.paymentStatus === 1  ){
            navigate('/')
            return
            }
            if(res.data.status === true){
                dispatch(ViewCart(res.data.Data))
            }else{

            }
        })
        .catch((err) => {
          if(err.response.status === 401){
            dispatch(LoogIn({show : true}))
            // navigate('/')
          }
          console.log(err.response.status === 401)
        })

    
    }

    useEffect(()=>{ 
        allData()
    },[]) 


  return (
    <>
        <Header_two />
        <Cart_summ />
        <Footer />
    </>
  )
}