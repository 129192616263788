import React from 'react'
import Mbl_oyp from '../../components/mobile_view_comp/otpp/mbl_oyp'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'
// import Timer from '../../components/mobile_view_comp/otpp/timer'

export default function Otp() {
  return (
    <>
      <div style={{width:'90%' , margin:'auto' ,marginTop:'65px'}}>
        <Cart_one_head link='/login_two' head='OTP Verification' />
        {/* <Timer time={10} /> */}
        <Mbl_oyp />
      </div>
    </>
  )
}