import React from 'react'
import Header_two from '../common/head_foot/header_two'
import Home_kyc_one_comp from '../components/fullwidth/home_kyc/home_kyc_one_comp'
import Footer from '../common/footer'

export default function Home_kyc_one() {
  return (
    <>
        <Header_two />
        <Home_kyc_one_comp /> 
        <Footer />
    </>
  )
}
