import React , { useEffect , useState } from 'react'
import { Link } from 'react-router-dom'
import aright from '../../../assets/img/aright.png'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    useDisclosure,
    Button,
    ChakraProvider
  } from '@chakra-ui/react'
import axios from 'axios'
import Modals from 'react-modal';
import { Base_Url } from '../../globalvariable/globe'
import { useNavigate } from 'react-router-dom'

export default function Mob_reg_page() {

  let emailveri = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  let navigate = useNavigate()

  const customStyles = {
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  let emailVali = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  let phonevali = /^\d{10}$/;

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [ fullname , setFullname ] = useState('')
  const [ username , setUsername ] = useState('')
  const [ email , setEmail ] = useState('')
  const [ phone , setPhone ] = useState('')
  const [ check , setCheck ] = useState(false)

  const [open, setOpen] = useState(false);

  const [ fin , setFin ] = useState(false)

  const [ vali , setVali ] = useState(true)
  const [ valiuser , setValiuser ] = useState(true)
  const [ valiemail , setValiEmail ] = useState(true)
  const [ valiphone , setvaliphone ] = useState(true)
  const [ tms , setTms ] = useState(true)

  const [ modeldata , setModeldata ] = useState('')

  
  const bodyParameters = {
    key: "value"
  };

  // const apicall = async () =>{
    
  // }

  // useEffect(()=>{
  //   apicall()
  // },[])

  const calcul = () =>{
    if(check === false){
      setModeldata('Please check Terms and Condition')
      openModal()
      return
    }

    if(email.match(emailVali)){
      console.log('sus')
    }else{
      setModeldata('Enter Valid Email Address')
      openModal()
      return
    }
    if(phone === ''){
      setModeldata('Enter Valid Phone No')
      openModal()
      return
    }
      const funn = async () =>{
      let responce = await axios({
        method : 'post',
        url : `${Base_Url}/createUserAccount`,
        bodyParameters,
        data:{
          fullName : fullname ,
          userName : username , 
          email : email ,
          phone : phone
        }
      })
      .then((responce)=>{
        console.log(responce.data)

        if(responce.data.status === false){
          setModeldata(responce.data.Error_Message)
          openModal()
        }
        if(responce.data.status === true){
          setModeldata(responce.data.message)
          navigate('/otp/'+phone)
          // onOpen()

          // setTimeout(() => {
          //   console.log('workeddd')
          //   navigate('/login_two')
          // }, 5000)

          // localStorage.setItem('user_data' , responce.data.Data.token )
          // navigate('/login_two')
          // setFin(true)
        }
      })
      .catch((err)=>{
        console.log(err)
      })        
    }
    
    funn()

  }



  return (
    <>
      <div className='mt-4'>
          <div>
            <p className='kyc_two_para'>Full Name</p>
            <input type="text" className={(vali?'form-control kyc_in_one':'form-controlss kyc_in_one')} id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e)=>
              {
                let letters = /^[1-9][0-9]*$/; 
                let last = ''
                if(e.target.value.length >=2) {
                    last = e.target.value.split('').pop()
                }else{
                    last = e.target.value
                }
                if(last.match(letters)){

                }else{
                    console.log(e)
                    setFullname(e.target.value)
                }
        }
        } value={fullname}  />
          </div>
          <div className='mt-3'>
            <p className='kyc_two_para'>Username</p>
            <input type="text" onChange={(e)=>
              {
                let letters = /^[1-9][0-9]*$/; 
                let last = ''
                if(e.target.value.length >=2) {
                    last = e.target.value.split('').pop()
                }else{
                    last = e.target.value
                }
                if(last.match(letters)){
                  setUsername(e.target.value)
                }else{
                    console.log(e)
                    setUsername(e.target.value)
                }
        }
        } value={username} className={(valiuser?'form-control kyc_in_one':'form-controlss kyc_in_one')} id="exampleInputEmail1" aria-describedby="emailHelp" />
          </div>
          <div className='mt-3'>
            <p className='kyc_two_para'>E mail</p>
            <input type="email" className={(valiemail?'form-control kyc_in_one':'form-controlss kyc_in_one')} id="exampleInputEmail1" aria-describedby="emailHelp" value={email} onChange={(e)=>setEmail(e.target.value)} />
          </div>
          <div className='mt-3'>
            <p className='kyc_two_para'>Phone Number</p>
            <input type="text" className={(valiphone?'form-control kyc_in_one':'form-controlss kyc_in_one')}  id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e)=>setPhone(e.target.value.replace(/[^0-9]/g, "").slice(0, 10))} value={phone}  />
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-1'>
              <input className="form-check-input" onChange={(e)=>setCheck(!check)}  checked={check} type="checkbox" id="flexCheckDefault" />
          </div>
          <div className='col-11'>
            
              <p className={(tms ? 'mob_pay_check' : 'mob_pay_checkssss' )} >I have read and agree to the Rentla’s User Agreement and<a target='_blank' href='/privacy_policy'> Privacy policy </a></p>
           
          </div>
      </div>
      <div  onClick={fin ? onOpen : ()=>calcul()} className='lo_tw_five5'>
        <div className='d-flex justify-content-center'>
            <p className='mob_log_p_four'>Create Account</p>
            <img className='ti_p_five' src={aright} />
        </div>
      </div>

      <ChakraProvider>
        <Modal  isCentered={true} isOpen={isOpen}  onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <p style={{paddingTop:'10px'}} className='pay_succ'>Account Activated Successfully</p>
              <p style={{paddingTop:'10px'}} className='pay_succ'>Please Wait , We are redirecting you to login Page.</p>
            </ModalBody>

            <ModalFooter>
              <Link to='/login_two' >
                <Button mr={3} >
                  Back to Login
                </Button>
              </Link>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </ChakraProvider>

      <Modals
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className='mod_div' ref={(_subtitle) => (subtitle = _subtitle)}>{modeldata}</div>
        <ChakraProvider>
          <Button style={{marginTop:'20px',display:'block', marginLeft : 'auto' , marginRight : 'auto'}} className='mod_bt_div' onClick={closeModal}>close</Button>
        </ChakraProvider>
      </Modals>
    </>
  )
}

// if(fullname === ''){
//   setVali(false)
// }else {
//   setVali(true)
// }

// if(username === ''){
//   setValiuser(false)
// }else{
//   setValiuser(true)
// }

// if(email === ''){
//   setValiEmail(false)
// }else{
//   if(email.match(emailVali)){
//   }else{
//   }
//   setValiEmail(true)
// }

// if(phone === ''){
//   setvaliphone(false)
// }else{
//   if(phone.match(phonevali)){
//   }else{
    
//   }
//   setvaliphone(true)
// }

// if(check === false){
//   setTms(false)
// }else{
//   setTms(true)
// }