import { ActionTypes } from "../contants/action-types";

const initialState = {
    products : []
}

// const GlobePo = {
//     glo : [
//         {
//             qty : 1,
//             curdat : '',
//             enddat : '',
//             rent_type : ''
//         }
//     ]
// }


export const mapReducer = (state = initialState , {type,payload}) => {
    switch (type) {
        case  ActionTypes.MAP_PRODUCTS:
            return {...state , products:payload}; 
        case ActionTypes.MAP_PRODUCTS_UPDATE:
            return {...state,...payload} ; 
        default:
            return state;
    }
}

export const cartCountReducer = (state = initialState , {type,payload}) => {
    switch (type) {
        case  ActionTypes.CARTCOUNT:
            return {...state , products:payload}
        default:
            return state;
    }
}

export const wishCountReducer = (state = initialState , {type,payload}) => {
    switch (type) {
        case  ActionTypes.WISHCOUNT:
            return {...state , products:payload}
        default:
            return state;
    }
}


export const mapReducerzoom = (state = initialState , {type,payload}) => {
    switch (type) {
        case  ActionTypes.MAP_PRODUCTS_ZOOM:
            return {...state , products:payload};
        default:
            return state;
    }
}


export const productReducer = (state = initialState , {type,payload}) => {
    switch (type) {
        case  ActionTypes.SET_PRODUCTS:
            return {...state , products:payload}; 
        case ActionTypes.SET_PRODUCTS_UPDATE:
            return {...state,...payload} ; 
        default:
            return state;
    }
}

export const selectedProductReducer =(state={},{type,payload})=>{
    switch (type) {
        case ActionTypes.SELECTED_PRODUCT:
            return {...state,...payload} ;
        default:
            return state;
    }
}

export const allProductReducer =(state={},{type,payload})=>{
    switch (type) {
        case ActionTypes.ALL_PRODUCT:
            return {...state,...payload} ;  
        default:
            return state;
    }
}

export const oneProductReducer =(state={},{type,payload})=>{
    switch (type) {
        case ActionTypes.ONE_PRODUCT:
            return {...state,...payload} ;
        case ActionTypes.REMOVE_SELECTED_PRODUCT:
             return {} ;  
        default:
            return state;
    }
}

export const allGlobeReducer =(state = {} , {type,payload} )=>{
    switch (type) {
        case ActionTypes.GLOBE:
            return {...state,...payload} ;  
        default:
            return state;
    }
}

export const logInReducer =(state = {} , {type,payload} )=>{
    switch (type) {
        case ActionTypes.LOGIN:
            return {...state,...payload} ;  
        default:
            return state;
    }
}

export const checkOutReducer =(state = {} , {type,payload} )=>{
    switch (type) {
        case ActionTypes.CHECKOUT:
            return {...state,...payload} ;  
        default:
            return state;
    }
}

export const cartListReducer =(state = {} , {type,payload} )=>{
    switch (type) {
        case ActionTypes.CARTLIST:
            return {...state,...payload} ;  
        default:
            return state;
    }
}

export const viewCartReducer =(state = {} , {type,payload} )=>{
    
    switch (type) {
        case ActionTypes.VIEWCART:
            return {...state,...payload} ; 
        case ActionTypes.VIEWCARTUPDATE:
            return {...state,...payload} ; 
        default:
            return state;
    }
}

export const wishListReducer =(state = {} , {type,payload} )=>{
    switch (type) {
        case ActionTypes.WISHLIST:
            return {...state,...payload} ;  
        default:
            return state;
    }
}

export const Addcarts =(state = {} , {type,payload} )=>{
    switch (type) {
        case ActionTypes.CART:
            return {...state,...payload} ;  
        default:
            return state;
    }
}

export const orderCofirmReducer =(state = {} , {type,payload} )=>{
    switch (type) {
        case ActionTypes.ORDERCONFIRM:
            return {...state,...payload} ;  
        default:
            return state;
    }
}