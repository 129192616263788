import React , {useState}  from 'react'
import Left from '../../../assets/img/left.png'
import { useNavigate } from 'react-router-dom'
import Html from 'html-react-parser'
import axios from 'axios'
import { Base_Url } from '../../globalvariable/globe'
import ReactLoading from 'react-loading';

export default function Contact_Us_mobile() {

  const [ name , setName ] = useState('')
  const [ email , setEmail ] = useState('')
  const [ phone , setPhone ] = useState('')

  const [ load , setLoad ] = useState(false)

  const [ er , setEr ] = useState(false)
  const [ sus , setSus ] = useState(false)
  
  const [ erdata , setErdata ] = useState('')

  const iframe = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6537.419931849425!2d80.20840008612247!3d13.024643338778503!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52671de2a98557%3A0x723363b47b56079e!2srentla.in!5e0!3m2!1sen!2sin!4v1667453284425!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'; 
  let navigate = useNavigate()

  const clicked = ()=>{
    navigate(-1)
  }

  const submit = async () =>{
    setLoad(true)
    setSus(false)
    setEr(false)
    let responce  = await axios({
      method : 'post',
      url : `${Base_Url}/contactUs`,
      data:{
        name : name ,
        email : email ,
        phone : phone
      }
    })
    .then((res)=>{
      console.log(res.data)
      if(res.data.status === true){
        setSus(true)
        console.log(1)
        setName('')
        setEmail('')
        setPhone('')
      }else{
        console.log(2)
        setSus(false)
        setEr(true)
        setErdata(res.data.Error_Message)
      }
      setLoad(false)
    })
    .catch((err)=>{
      setSus(false)
      console.log(3)
      setEr(true)
      setErdata('Something Went Wrong')
      console.log(err)
      setLoad(false)
    })
  }

  return (
    <div className='container' style={{ margin:'auto' ,overflowX:'hidden'}}>
       <div className="second-tops fixed-top navbar-light top-tab-nav">
            <div className="d-flex  m-auto paddd" >
               <div onClick={()=>clicked()} className='loab'>
                  {/* <Link to={props.link}> */}
                    <img src={Left} />
                  {/* </Link> */}
                </div>
               <div style={{margin: 'auto'}} className='loab'>
                   <p className='ccaaat'>Contact Us</p>
               </div>
            </div>
        </div>
        <div className='pro_home_seven mt-5'>
        <p className='pro_home_eight'>Address</p>
          <div className='row mt-3'>
            <div className='col-12'>
                <p>Rentla pvt ltd,<br/>No 1/2, Janakiraman st,<br />83rd St, Muthurangam Block,<br />West Jafferkhanpet,<br />Chennai,Tamil Nadu 600083.<br />
                </p>
                <p>Phone : 9940428882<br />Email : support@rentla.in</p>
            </div>
          </div>
        <div style={{marginTop : '20px' , marginBottom:'50px' }}>{Html(iframe)}</div>
        <div className='mt-4'>
        <p className='ccaaat'>Contact Us</p>
          <div>
            <p className='kyc_two_para'>Full Name</p>
            <input onChange={(e)=>setName(e.target.value)} value={name} type="email" className="form-control kyc_in_one" id="exampleInputEmail1" aria-describedby="emailHelp" />
          </div>
          <div className='mt-3'>
            <p className='kyc_two_para'>E-mail</p>
            <input onChange={(e)=>setEmail(e.target.value)} value={email} type="email" className="form-control kyc_in_one" id="exampleInputEmail1" aria-describedby="emailHelp" />
          </div>
          <div className='mt-3'>
            <p className='kyc_two_para'>Phone No</p>
            <input  onChange={(e)=>setPhone(e.target.value.replace(/[^0-9]/g, "").slice(0, 10))} value={phone} type="number" className="form-control kyc_in_one" id="exampleInputEmail1" aria-describedby="emailHelp" />

          </div>
          <div onClick={()=>{submit()}} className='ed_pro_four' style={{width : '100%'}}>
            {
              load === true ?
              <div className='' style={{paddingTop :5 , paddingLeft : 90}}><ReactLoading type='balls' color='#ffffff' /> </div>
              : <p style={{cursor:'pointer'}} className='ed_pro_five'>Submit</p>
            }
            {
              er === true ? <p style={{fontSize : 14 ,  fontWeight : 500 , color : 'red' , textAlign : 'center' }}>{erdata}</p> : ''
            }
            {
              sus === true ? <p style={{fontSize : 14 ,  fontWeight : 500 , color : 'green' , textAlign : 'center' }}>Updated</p> : ''
            }
              
          </div>
        </div>
        </div>
    </div>
  )
}
