import React from 'react'
import head_111 from '../../../assets/img/head_111.png'
import { Link } from 'react-router-dom'

export default function Renew_footer(props) {
  return (
    <>
      <div className='mob_footssss'>
          <div className='row'>
              <div className='col-6'>
                  <div className='d-flex'>
                      <div className='headset'>
                        <img className='kaart' src={head_111} />
                      </div>
                      <div>
                        <p className='compara222 compara2223'>Support</p>
                        <p className='compara333 compara2223'>9am - 5Pm</p>
                      </div>
                  </div>
              </div>
              <div className='col-6'>
                  <div className='bbtn'>
                      <Link to={props.link}>
                        <div className='d-flex'>
                            <p className='compara nextonee mt-1'>Proceed to pay</p>
                        </div>
                      </Link>
                  </div>
              </div>
          </div>
      </div>
    </>
  )
}