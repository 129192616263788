import React , { useEffect , useState } from 'react'
import { useDispatch , useSelector } from 'react-redux';
import axios from 'axios';
import { allProduct } from '../../../redux/actions/productAction';
import Web_cat_detail from './web_cat_detail';
import { Base_Url } from '../../globalvariable/globe';
import Skeleton from 'react-loading-skeleton'
import { useParams , useNavigate } from 'react-router-dom';
import upa from '../../../assets/img/upa.png'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { color } from '@chakra-ui/react';
import { yellow } from '@material-ui/core/colors';
import { CheckBox } from '@material-ui/icons';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { async } from '@firebase/util';

export default function Web_cat(props) {

  let navigate = useNavigate()

  const [ min , setMin ] = useState(0)
  const  [ max , setMax  ] = useState(1000)

  const [ price ,  setPrice ] = useState(true)
  const [ brandt , setBrandt ] = useState(true)
  const [ type , setType ] = useState(false)

  const [ brand , setBrand ] = useState(0)
  const [ pkey , setPkey ] = useState(props.catid)
  const [ activeItemIndex , setActiveItemIndex] = useState(0);
  const chevronWidth = 40;
  const [ ke , setKe ] = useState()
  const [finalcat , setFinalcat] = useState(0)
  const [ keyone , setKeyone ] = useState(true)
  const [ all ,stAll ] = useState(true)
  const [ kid , setKid ] = useState(false)
  let brandar = []
  let skli = [ 1, 2, 3, 4, 5, 6]

  const [ty, setTy] = useState()

  let { name , names , id , bro } = useParams()

  const dispatch = useDispatch()
  const product =  useSelector((state)=>state.allpoductData)
  console.log(product,"p")
  const catttt =  product.selected.category.catId

  const products =  product.filterBrand

  const desc=product.selected.category.Description
  console.log(desc,"des")
  useEffect(()=>{
    setType(true)
    console.log(catttt , 'slkjksbcatttt')
    localStorage.setItem('brandar' , '')
    localStorage.setItem('brandars' , '')
  },[])

  const clicked = (dat,key) =>{
    if(key === 0){
      setKeyone(true)
    }else{
      setKeyone(false)
    }
    stAll(false)
    if(props.catids != 0){
      setPkey(props.catids)
      setBrand(dat.BrandId)
    }
    if(ke===key){

    }else{
      setBrand(dat.BrandId)
      setKe(key)
    }

    navigate('/products/'+name+'/'+id+ '/' + dat.BrandName + '/'+ dat.BrandId )
  }

  const bodyParameters = {
    key: "value"
  }; 

  const clikk =async () =>{
    console.log('brand',brand)
    console.log('catId',pkey)
    let responce =await axios({
      method: 'post',
      url: `${Base_Url}/products`,
      bodyParameters, 
      data :{
        catId : pkey,
        brandId : brand
      }
    })
    // setDesc(responce.data.Data.selected.category.Description)
    // console.log(responce.data.Data.selected.category.Description,"in")
    dispatch(allProduct(responce.data.Data))
  }

  useEffect(() => {
    console.log(product , 'brand')
    clikk()
  },[brand]);
  
  useEffect(() => {
    if(product?.pro?.length === 0){
      setKid(true)
    }else{
      setKid(false)
    }
  },[product]);


  useEffect(() => {
    setKeyone(true)
    stAll(true)
    setKe()
    
  },[props.catids]);

  useEffect(() => {
    
    setMin(product.filterAmount.minAmount)
    setMax(product.filterAmount.maxAmount)
  },[catttt]);

  const clears = () => {
    setPrice(false)
    setBrandt(false)
    setType(false)
  }


  const brandclick =async (data,e) =>{

    let allata = localStorage.getItem('brandar')
    
    if(allata === null ||  allata === ''){
      localStorage.setItem('brandar' , data.InventoryCatId.toString())
    }else{
      let another = allata.split(",")
      if(another.find(element => element === data.InventoryCatId.toString())){
  
  
        another.forEach((element, index) => {
          if( data.InventoryCatId.toString() === element){
              let allanother = another
              allanother.splice(index , 1)
              localStorage.setItem('brandar' , allanother.toString())
          }
      })
  
  
  
        localStorage.setItem('brandar' , another.toString())
      }else{
        another = another.concat(data.InventoryCatId)
        localStorage.setItem('brandar' , another.toString())
      }
    }
    
    let responce =await axios({
      method: 'post',
      url: `${Base_Url}/products`,
      bodyParameters,
      data :{
        catId : catttt,
        brandId : brand,
        brand : localStorage.getItem('brandar').toString(),
        type : localStorage.getItem('brandars').toString(),
        minAmount : min,
        maxAmount : max
      }
    })
    .then((res)=>{
      if(res.data.status === false){

      }else{
        dispatch(allProduct(res.data.Data))
        // setDesc(responce.data.Data.selected.category.Description)
        console.log(responce.data.Data.selected.category.Description,"inss")

        setTy(res.data.Data.filterType)
      }
    })
    .catch((err)=>{
      console.log(err,'akdhshfhsk')
    })
  }





  const typeChange =async ( data , e ) =>{ 
    let allata = localStorage.getItem('brandars')
    
    if(allata === null ||  allata === ''){
      localStorage.setItem('brandars' , data.InventoryCatId.toString())
    }else{
      let another = allata.split(",")
    if(another.find(element => element === data.InventoryCatId.toString())){


      another.forEach((element, index) => {
        if( data.InventoryCatId.toString() === element){
            let allanother = another
            allanother.splice(index , 1)
            localStorage.setItem('brandars' , allanother.toString())
        }
    })



      localStorage.setItem('brandars' , another.toString())
    }else{
      another = another.concat(data.InventoryCatId)
      localStorage.setItem('brandars' , another.toString())
    }
    }
    
    let responce =await axios({
      method: 'post',
      url: `${Base_Url}/products`,
      bodyParameters,
      data :{
        catId : catttt,
        brandId : brand,
        brand : localStorage.getItem('brandar').toString(),
        type : localStorage.getItem('brandars').toString(),
        minAmount : min,
        maxAmount : max
      }
    })
    .then((res)=>{
      if(res.data.status === false){

      }else{
        setTy(res.data.Data.filterType)
        // setDesc(responce.data.Data.selected.category.Description)
        console.log(responce.data.Data.selected.category.Description,"iddnss")

        dispatch(allProduct(res.data.Data))
      }
    })
    .catch((err)=>{
      console.log(err,'akdhshfhsk')
    })
  }


  const valueChange = async ( mins , maxs) =>{

    if( mins === maxs ) {
      setMax(max)
      setMin(min)
      return
    }

      let responce =await axios({
        method: 'post',
        url: `${Base_Url}/products`,
        bodyParameters,
        data :{
          catId : catttt,
          brandId : brand,
          brand : localStorage.getItem('brandar').toString(),
          type : localStorage.getItem('brandars').toString(),
          minAmount : mins,
          maxAmount : maxs
        }
      })
      .then((res)=>{
        if(res.data.status === false){
  
        }else{
          setTy(res.data.Data.filterType)
          console.log(responce.data.Data.selected.category.Description,"ssin")

          dispatch(allProduct(res.data.Data))
        }
      })
      .catch((err)=>{
        console.log(err,'akdhshfhsk')
      })
  }

  return (
<>
    <div className='' style={{padding : 30}}>
        <div className='row'>
          <div className='col-2'>

            
            <div className='filteeer' >
              <div style={{cursor : 'pointer'}} onClick={()=>clears()} className='d-flex justify-content-between mt-4'>
                <p style={{ fontSize : 16 , fontWeight : 600 , color : '#303651' }}>Filter</p>
                <div className='filteeeerlol' >
                <p style={{ fontSize : 10 , fontWeight : 600 , marginTop : -4 }}>Clear</p>
                </div>
              </div>
              <hr className='mt-3 mb-3' />
              

              <div className='d-flex justify-content-between mt-4'>
                <p style={{ fontSize : 16 , fontWeight : 600 , color : '#303651' }}>Price</p>
                <div  onClick={()=>{setPrice(!price)}} style={{cursor : 'pointer'}} className='filteeeerlols d-flex justify-content-start' >
                  <p style={{ fontSize : 10 , fontWeight : 600 , marginTop : -4 }}>{ price === true ? 'Hide' :  'Show'}</p>
                  <img className={price === true ? 'svndlkvndklf' : 'svndlkvndklfss'}  src={upa} />
                </div>
              </div>
              {
                price === true ? <>
                 <div className='d-flex justify-content-between mt-2'>
                    <p style={{ fontSize : 10 , fontWeight : 600 , color : '#303651' }}>₹ {min}</p>
                    <p style={{ fontSize : 10 , fontWeight : 600 , color : '#303651' }}>₹ {max}</p>
                  </div>
                  <Slider onAfterChange={(e)=>{
                      valueChange(e[0] , e[1] )
                    }} range min={60} max={4000} value={[min , max]} trackStyle={[{ backgroundColor: 'black' }]}
                    onChange={(e)=>{
                      setMin(e[0])
                      setMax(e[1])
                    }}
                  />
                </>
                :''
              }
             

              <hr className='mt-3 mb-3' />
              <div className='d-flex justify-content-between mt-4'>
                <p style={{ fontSize : 16 , fontWeight : 600 , color : '#303651' }}>Brand</p>
                <div onClick={()=>{setBrandt(!brandt)}} style={{cursor : 'pointer'}} className='filteeeerlols d-flex justify-content-start' >
                  <p style={{ fontSize : 10 , fontWeight : 600 , marginTop : -4 }}>{ brandt === true ? 'Hide' : 'Show' }</p>
                  <img className={brandt === true ? 'svndlkvndklf' : 'svndlkvndklfss'}  src={upa} />
                </div>
              </div>

             {
              brandt === true ? 
              <div className='scrooooll'>
                {
                  products?.map((data)=>{
                    return(
                    <div  className='d-flex justify-content-start'>
                        <input onChange={(e)=>brandclick(data,e)} style={{width : 20}} type='checkbox' />
                        <p style={{ fontSize : 14 , fontWeight : 500 , color : '#303651' , marginLeft : 10 , marginTop : 10 }}>{data.BrandName}</p>
                      </div>
                    )
                  })
                }
              </div>

              : ''
              
              }
              
              <hr className='mt-3 mb-3' />
              <div className='d-flex justify-content-between mt-4'>
                <p style={{ fontSize : 16 , fontWeight : 600 , color : '#303651' }}>Type</p>
                <div onClick={()=>{setType(!type)}} style={{cursor : 'pointer'}} className='filteeeerlols d-flex justify-content-start' >
                  <p style={{ fontSize : 10 , fontWeight : 600 , marginTop : -4 }}>{ type === true ? 'Hide' :  'Show'}</p>
                  <img className={type === true ? 'svndlkvndklf' : 'svndlkvndklfss'}  src={upa} />
                </div>
              </div>

              {
               type === true ? 
               <div className='scrooooll'>
                {
                  ty?.map((data)=>{

                    return(
                    <div className='d-flex justify-content-start'>
                      <input onChange={(e)=>typeChange(data,e)} style={{width : 20}} checked={ localStorage.getItem('brandars').split(",").includes(data.InventoryCatId.toString()) === true ? 'checked' : '' } type='checkbox' />
                      <p style={{ fontSize : 14 , fontWeight : 500 , color : '#303651' , marginLeft : 10 , marginTop : 10 }}>{data.typeName}</p>
                    </div>
                    )
                  })
                }
              </div> 
              : '' }

            </div>

          </div>
          <div className='col-10'>
            <Web_cat_detail />
            <div>
            <div className="mt-60 pb-50">
           <div className="">
          <div className="welcome-content text-center ">
          <p dangerouslySetInnerHTML={{ __html: desc?desc:null }} />
            <p style={{ fontWeight: 500, fontSize: 24, color: '#666666' }}></p>
            {/* <p className='mt-4'>Rentla is an IT rental company that focuses on renting gadgets required for corporates, students, freelancers, and individuals who need IT support. We are promoting <br />renting gadgets to optimize the use of technological resources and provide a choice for the public when it comes to choosing their gadgets. We provide gadgets at <br />affordable costs along with maintenance, software upgradation, and doorstep delivery services.</p> */}
          </div>
        </div>
      </div>
             
            </div>
          </div>
        </div>
    </div>
</>
  )
}


// {product.brands_of_1st_category && product.brands_of_1st_category.map((dat,key)=>
//   <div onClick={()=>clicked(dat,key)}  className={key === product.brands_of_1st_category.length-1 ? 'col-2' : 'col-2 web_cat_three'}>
//       <button style={{ margin : 'auto' }} className='web_cat_two'>{dat.BrandName}</button>
//       {
//           dat.BrandName === names ? <hr style={{ margin : 'auto' }} className='web_cat_four' />  : ke === key ? <hr  style={{ margin : 'auto' }} className='web_cat_four' /> :''
//       }
      
//   </div>
// )}
