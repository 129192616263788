import React , { useState , useEffect } from 'react'
import circle1 from '../../../assets/img/circle1.png'
import './resisrer.css'

import hppy from '../../../assets/img/hppy.png'
import smmill from '../../../assets/img/smmill.png'
import saad from '../../../assets/img/saad.png'
import color_sad from '../../../assets/img/color_sad.png'
import color_happy from '../../../assets/img/color_happy.png' 
import color_ok from '../../../assets/img/color_ok.png'

import kyctk from '../../../assets/img/kyctk.png'
import { Link , useNavigate } from 'react-router-dom'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    ChakraProvider,
    Text,
    Button,
    useDisclosure
  } from '@chakra-ui/react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Base_Url, ImageUrl } from '../../globalvariable/globe'
import axios from 'axios'

export default function Mob_view_pro() {

    const [ rating , setRating ] = useState(0)

  const [ feeddata , setFeeeddata ] = useState()

    let navigate = useNavigate()

    const [ log ,  setLog ] = useState(false) 

    const { isOpen, onOpen, onClose } = useDisclosure()

    const [ all , setAll ] = useState()
  
    const [ name , setName ] = useState('')
    const [ email , setEmail ] = useState('')
    const [ phone , setPhone ] = useState('')

    const [ type , setType ] = useState('')
    const [ kyctype , setkycType ] = useState('')

    const [ wishcountss , setWishcount ] = useState(0)

    let local = localStorage.getItem('user_type')

    const successnotify = (sus) => toast.success( 'feedback submitted' , {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }); 

    const ratings = () =>{
        setFeeeddata('')
        setLog(false)
        onOpen()
    }
 
    const logout = () =>{
        setLog(true)
        onOpen()
    }

    let token = localStorage.getItem('user_data')

    const logoutSubmit = () =>{
        localStorage.clear();
        navigate('/')
    }

    useEffect(()=>{
        //

        let widata = localStorage.getItem('wish_list')

        if ( widata === null ){ 
            setWishcount(0)
            return
        }else if(widata === '[]'){
            setWishcount(0)
        }
        let hiis = JSON.parse(widata)

        setWishcount(hiis.length)

    },[])

    const Feedback =async () =>{
        let responce = await axios({
          method : 'post',
          url : `${Base_Url}/feedback`,
          headers: { 
            'Authorization': `Bearer ${token}` 
          },
          data : {
              rating : rating, 
              description :feeddata 
          }
        })
        .then((res)=>{
          onClose()
          successnotify()
          console.log(res)
        })
        .catch((err)=>{
            if(err.response.status === 401){
                navigate('/login')
        }
          console.log(err)
        })
      }

      let alldata = async()=>{
        if (token === null){
          navigate('/') 
        }
        let responce = await axios({
          method : 'post',
          url : `${Base_Url}/viewProfile`,
          headers: { 
            'Authorization': `Bearer ${token}` 
          }
        })
        .then((res)=>{
          setAll(res.data.Data.userDetails.UserImage)
          setName(res.data.Data.userDetails.UserFullName)
          setEmail(res.data.Data.userDetails.UserEmail)
          setEmail(res.data.Data.userDetails.UserEmail)
          setPhone(res.data.Data.userDetails.UserPhone)
          setType(res.data.Data.type)
          setkycType(res.data.Data)
          localStorage.setItem('user_type', res.data.Data.type)
          localStorage.setItem('kyctype', res.data.Data.kyc)
          console.log(res)
        })
      }
    
      useEffect(()=>{ 
        alldata()
      },[])
    

  return (
    <>
        <div>
            <img  className='login_two_img10' src={circle1} />
            <div>
                <img style={{ borderRadius : '50%'  }} 
                    className={ 
                        kyctype?.kyc  === 1 ? 'profile_img_one grrrasdfsfgsgs' : 
                        kyctype?.kyc  === 3 ? 'reddddsdfsfgsgs profile_img_one' :   
                        kyctype?.kyc  === 0 ? 'zdfsdfsfdffesfgsgs profile_img_one' : 
                        kyctype?.kyc  === 4 ? 'elsesdfsfgsgs profile_img_one' : 'profile_img_one'
                    }  src={ all ? ImageUrl + '/' + all : '' } />
                <p style={{ marginTop : -73 }} className='profile_img_three'>{name?name : ''}</p>
                <p className='profile_img_four'>{email?email : ''}</p>
                <Link to={ type === 'corporate' ?   '/corporate_profile'  : type === 'user' ? '/edit_profile' : '' }>
                    <div className='profile_img_five'>
                        <p className='profile_img_six'>Edit Profile</p>
                    </div>
                </Link>
            </div>
            <div className='profile_img_seven' >
                <Link to='/order'>
                    <div className='d-flex'>
                        <img className='profile_img_ten' style={{width :21 , height :21}} src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/cart.png' />
                        <p className='profile_img_nine'>Orders</p>
                    </div>
                </Link>
                <Link to='/history'>
                    <div className='d-flex mt-4'>
                        <img className='profile_img_ten'  style={{width :21 , height :21 , marginTop :2}}  src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/history.png' />
                        <p className='profile_img_nine'>History</p>
                    </div>
                </Link>
                <Link to='/payment_report'>
                    <div className='d-flex mt-4'>
                        <img className='profile_img_ten' style={{width :21 , height :21}}  src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/paymentreport.png' />
                        <p className='profile_img_nine'>Payment Report</p>
                    </div>
                </Link>
                <Link to='/kyc_one'>
                    <div className='d-flex justify-content-between mt-4'>
                        <div className='d-flex' >
                            <img  className='profile_img_ten' style={{width :21 , height :21}}  src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/kyc.png' />
                            <p className='profile_img_nine'>KYC</p>
                        </div>
                        
                        {
                            kyctype?.kyc  === 1 ? 
                            <div className='d-flex' >
                                <img className='profile_img_ten' style={{width :21 , height :21}}  src={kyctk} />
                                <p style={{ marginLeft : 5 }} className='profile_img_nine'>Verified</p>
                            </div>

                            : '' 
                        }
                    </div>
                </Link>
                <Link to='/bank_detail'>
                    <div className='d-flex mt-4'>
                        <img className='profile_img_ten' style={{width :21 , height :21}}  src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/managebank.png' />
                        <p className='profile_img_nine'>Manage Bank</p>
                    </div>
                </Link>
                <Link to='/wish'>
                    <div className='d-flex mt-4'>
                        <img className='profile_img_ten' style={{width :21 , height :21}}  src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/wishlistone.png' />
                        <p className='profile_img_nine'>Wishlist</p>
                        <span class='badge badge-warning' id='lblCartCountsss'>{wishcountss}</span>
                    </div>
                </Link>
                <Link to='/notification'>
                    <div className='d-flex mt-4'>
                        <img className='profile_img_ten' style={{width :21 , height :21}}  src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/notification.png' />
                        <p className='profile_img_nine'>Notification</p>
                    </div>
                </Link>
                <div onClick={()=>ratings()} className='d-flex mt-4'>
                    <img className='profile_img_ten'style={{width :21 , height :21}}  src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/feedback.png' />
                    <p className='profile_img_nine'>Feedback</p>
                </div>
                <Link to='/support'>
                    <div className='d-flex mt-4'>
                        <img className='profile_img_ten' style={{width :21 , height :21}}  src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/support.png' />
                        <p className='profile_img_nine'>Support</p>
                    </div>
                </Link>
                <Link to='/terms'>
                    <div className='d-flex mt-4'>
                        <img className='profile_img_ten' style={{width :21 , height :21}}  src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/tandpolicy.png' />
                        <p className='profile_img_nine'>T&C</p>
                    </div>
                </Link>
                <Link to='/damage_policy'>
                    <div className='d-flex mt-4'>
                        <img className='profile_img_ten' style={{width :21 , height :21}}  src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/tandpolicy.png' />
                        <p className='profile_img_nine'>Damage policy</p>
                    </div>
                </Link>
                <Link to='/contact_us'>
                    <div className='d-flex mt-4'>
                        <img className='profile_img_ten' style={{width :21 , height :21}}  src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/support.png' />
                        <p className='profile_img_nine'>Contact Us</p>
                    </div>
                </Link>
                <Link to='/about_us'>
                    <div className='d-flex mt-4'>
                        <img className='profile_img_ten' style={{width :21 , height :21}}  src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/kyc.png' />
                        <p className='profile_img_nine'>About Us</p>
                    </div>
                </Link>
                <div onClick={()=>logout()} className='d-flex mt-4'>
                    <img className='profile_img_ten' style={{width :21 , height :21}}  src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/logout.png' />
                    <p className='profile_img_nine'>Logout</p>
                </div>
                <ToastContainer />
            </div>
        </div>
        <div className='popup_one'>
            <ChakraProvider>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        {
                            log === true ? <>
                            <ModalHeader>
                                Are you sure you want to logout?
                            </ModalHeader>
                            </> :
                            <ModalHeader>
                                <p style={{ fontSize : 12 , color :'#000' , fontWeight : '600'}}>Did You Enjoy Our Service</p>
                            
                            </ModalHeader>
                        }
                       
                        <ModalCloseButton />
                        {
                             log === true ? <>
                                    <ModalFooter>
                                        <Button onClick={()=>logoutSubmit()} style={{backgroundColor:'#161E40' , color:'white'}}>Logout</Button>
                                    </ModalFooter>
                                </> :
                             <>
                                <ModalBody>
                                <div style={{ marginTop : -15 }} className='d-flex justify-content-start' >
                                  <div style={{ marginRight : 30 }} >
                                    {
                                      rating === 1 ? <img onClick={()=>{setRating(1)}} className='pro_home_three' src={color_sad} /> :  <img onClick={()=>{setRating(1)}} className='pro_home_three' src={saad} />
                                    }
                                   
                                    <p style={{ color : '#000' , fontSize :  14 , fontWeight :  '600' , textAlign : 'center' , marginTop : 5 , marginBottom : 10 }} >Bad</p>
                                  </div>
                                  <div style={{ marginRight : 30 }} >
                                    {
                                      rating === 2 ? <img onClick={()=>{setRating(2)}} className='pro_home_three' src={color_happy} /> : <img onClick={()=>{setRating(2)}} className='pro_home_three' src={smmill} />
                                    }
                                    <p style={{ color : '#000' , fontSize :  14 , fontWeight :  '600' , textAlign : 'center' , marginTop : 5 , marginBottom : 10 }} >Fair</p>
                                  </div>
                                  <div >
                                    {
                                      rating === 3 ? <img onClick={()=>{setRating(3)}} className='pro_home_three' src={color_ok} /> : <img onClick={()=>{setRating(3)}} className='pro_home_three' src={hppy} />
                                    }
                                    
                                    <p style={{ color : '#000' , fontSize :  14 , fontWeight :  '600' , textAlign : 'center' , marginTop : 5 , marginBottom : 10 }} >Good</p>
                                  </div>
                                </div>

                                    <Text fontWeight='bold' mb='1rem'>
                                        Tell us what can be improved !
                                    </Text>
                                    <textarea onChange={(e)=>setFeeeddata(e.target.value)} value={feeddata} className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                </ModalBody> 

                                <ModalFooter>
                                    <Button onClick={()=>Feedback()} style={{backgroundColor:'#161E40' , color:'white'}}>Submit Feedback</Button>
                                </ModalFooter>
                            </>
                        }
                            
                    </ModalContent>
                </Modal>
            </ChakraProvider>
        </div>
    </>
  )
}