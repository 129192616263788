import React , { useState , useEffect } from 'react'
import Header_two from '../../common/head_foot/header_two'
import Footer from '../../common/footer'
import { useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import { Link , useNavigate } from 'react-router-dom';
import Profile_home_nav from '../profile_home/profile_home_nav';
import red_minus from '../../assets/img/red_minus.png'
import new_image from '../../assets/img/new_image.png'
import { useParams } from 'react-router-dom'
import axios from 'axios';
import { Base_Url } from '../../components/globalvariable/globe';
import { ImageUrl } from '../../components/globalvariable/globe';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    ChakraProvider,
    useDisclosure
  } from '@chakra-ui/react'
  import { LoogIn } from '../../redux/actions/productAction';

export default function Pending_checklist() {

    let navigate = useNavigate()
    
    let dispatch = useDispatch()

    const {id} = useParams()

    let token = localStorage.getItem('user_data')

    const [ alldata , setAlldata ] = useState()
    const [ idss , setIdss ] = useState()
    const [ idsss , setIdsss ] = useState()
    const [ idssss , setIdssss ] = useState()

    const [ otp , setOtp ] = useState()

    const { isOpen, onOpen, onClose } = useDisclosure()

    const errnotify = (dat) => toast.error( dat , {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    let start = async () => {
        if(token === null){
            navigate('/')
            return
          }
        let response = await axios ({
            method : 'post',
            url : `${Base_Url}/verifyPendingOrder`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            },
            data : {
                orderId : id
            }
        })
        .then((res)=>{
            setAlldata(res.data.Data)
            console.log(res.data.Data)
        })
        .catch((err)=>{
            if(err.response.status === 401){
                dispatch(LoogIn({show : true}))
                // navigate('/')
            }
        })
    }

    useEffect(()=>{
        start()
    },[])

    const imageClick = (data) => {
        setIdss(data.CartDetailId)
        let input = document.querySelector('#file');
        input.click();
    }

    const imageChange = async (data , event ) =>{
        let formData = new FormData();
        formData.append("image1", event.target.files[0]);
        console.log(idss)
        formData.append("cartDetailId",idss);

        let responce = await axios.post( `${Base_Url}/uploadPendingOrderImage`, formData, {
            headers: {
                'Authorization': `Bearer ${token}` ,
                'Content-Type': 'multipart/form-data'
            } 
        })
        .then((res)=>{
            console.log(res)
            start()
            setIdss()
        })
        .catch((err)=>{
            if(err.response.status === 401){
                dispatch(LoogIn({show : true}))
                // navigate('/')
            }
            console.log(err)
        })
    }

    const imageClicks = (data) => {
        setIdsss(data.CartDetailId)
        let input = document.querySelector('#files');
        input.click();
    }

    const imageChanges = async (data , event ) =>{
        let formData = new FormData();
        formData.append("image2", event.target.files[0]);
        formData.append("cartDetailId",idsss);

        let responce = await axios.post( `${Base_Url}/uploadPendingOrderImage`, formData, {
            headers: {
                'Authorization': `Bearer ${token}` ,
                'Content-Type': 'multipart/form-data'
            } 
        })
        .then((res)=>{
            console.log(res)
            start()
            setIdsss()
        })
        .catch((err)=>{
            if(err.response.status === 401){
                dispatch(LoogIn({show : true}))
                // navigate('/')
            }
            console.log(err)
        })
    }

    const imageClickss = (data) => {
        setIdssss(data.CartDetailId)
        let input = document.querySelector('#filess');
        input.click();
    }

    const imageChangess = async (data , event ) =>{
        let formData = new FormData();
        formData.append("image3", event.target.files[0]);
        formData.append("cartDetailId",idssss);

        let responce = await axios.post( `${Base_Url}/uploadPendingOrderImage`, formData, {
            headers: {
                'Authorization': `Bearer ${token}` ,
                'Content-Type': 'multipart/form-data'
            } 
        })
        .then((res)=>{
            console.log(res)
            start()
            setIdssss()
        })
        .catch((err)=>{
            if(err.response.status === 401){
                dispatch(LoogIn({show : true}))
                // navigate('/')
            }
            console.log(err)
        })
    }


    let Checkbox = async(data,e) =>{

        let responce = await axios({
            method : 'post' ,
            url : `${Base_Url}/scanProduct` ,
            headers: {
                'Authorization': `Bearer ${token}` ,
                'Content-Type': 'multipart/form-data'
            },
            data : {
                cartDetailId : data.CartDetailId,
                status : data.CartDetailCustProStatus === 0 ? 1 : 0 
            }
        })
        .then((res)=>{
            console.log(res)
            start()
            setIdssss()
        })
        .catch((err)=>{
            if(err.response.status === 401){
                dispatch(LoogIn({show : true}))
                // navigate('/')
            }
            console.log(err)
        })

    }

    const generateOtp = async() => {
        let responce = await axios({
            method : 'post',
            url :  `${Base_Url}/pendingOrderGenerateOtp` ,
            headers: {
                'Authorization': `Bearer ${token}` ,
                'Content-Type': 'multipart/form-data'
            },
            data : {
                orderId : alldata.orderId
            }
        })
        .then((res)=>{
            setOtp(res.data.Data)
            onOpen()
        })
        .catch((err)=>{
            if(err.response.status === 401){
                dispatch(LoogIn({show : true}))
                // navigate('/')
            }
        })
    }

     return (
        <> 
            <Header_two />
            <>
                <div className='pro_home_one mt-150'>
                    <div className='row' >
                        <Profile_home_nav />
                        <div className='col-8'>
                            <div className='pro_home_seven'>
                                <div  >
                                    <div >
                                        <div className='row'>

                                            <div className='col-12'>
                                                <div className=''>
                                                    <div className='my-2'>
                                                        <div className='d-flex justify-content-between'>
                                                            <p className='web_cart_col_two'>Checklist</p>
                                                            {/* <img style={{width:'27px' , height : '22px'}} src={qr} /> */}
                                                        </div>
                                                    </div>

                                                    <div className='d-flex justify-content-between my-4'>
                                                        <p className='web_cart_col_two'>Products ({alldata?.products.length} items)</p>
                                                        {/* <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label className="form-check-label" for="flexCheckDefault">
                                                                Select all
                                                            </label>
                                                        </div> */}
                                                    </div>
                                                    {
                                                        alldata?.products.map((data,key)=>
                                                        <div style={{ backgroundColor : '#f4f4f4' , borderRadius : '8px' , width : '100%' , height : 'auto' }} className='row p-4  mt-3'>
                                                        <div className='col-1 mt-1'>
                                                            <div className="form-check">
                                                                <input onChange={(e)=>Checkbox(data,e)} style={{ width:'20px' , height : '20px' , marginTop:'10px' }} className="form-check-input" type="checkbox" checked={data.CartDetailCustProStatus === 0 ? '' : 'checked'}  id="flexCheckDefault" />
                                                            </div>
                                                        </div>
                                                        <div className='col-6 '>
                                                            <p className='web_pending_one_p'>{alldata?.orderId}</p>
                                                            <p className='web_pending_one_p_one'>{data.CartDetailId}</p>
                                                        </div>
                                                        <div className='col-5 mt-2'>
                                                            <div className='row'>
                                                                <div className='col-4'>
                                                                    {
                                                                        data.CartDetailCustProImg1 === null ? 
                                                                            <>
                                                                                <img onClick={(e)=>imageClick(data)}  className='web_pending_one' src={new_image} />
                                                                                <input type='file' onChange={(event)=>imageChange(data , event)} id='file' style={{display: 'none'}}/>
                                                                            </>
                                                                        :
                                                                        <>
                                                                            <img className='web_pending_one' src={ImageUrl+'/'+data.CartDetailCustProImg1} />
                                                                            <div className=' red_minusss'>
                                                                                <img  onClick={(e)=>imageClick(data)} style={{width:'20px' , height:'20px'}} className='web_pending_one red_minus' src={red_minus} />
                                                                                <input type='file' onChange={(event)=>imageChange(data , event)} id='file' style={{display: 'none'}}/>
                                                                            </div>
                                                                        </>

                                                                    }
                                                                </div>
                                                                <div className='col-4'>
                                                                    {
                                                                        data.CartDetailCustProImg2 === null ? 
                                                                        <>
                                                                            <img onClick={(e)=>imageClicks(data)}  className='web_pending_one' src={new_image} />
                                                                            <input type='file' onChange={(event)=>imageChanges(data , event)} id='files' style={{display: 'none'}}/>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <img className='web_pending_one' src={ImageUrl+'/'+data.CartDetailCustProImg2} />
                                                                            <div  className=' red_minusss'>
                                                                                <img  onClick={(e)=>imageClicks(data)} style={{width:'20px' , height:'20px'}} className='web_pending_one red_minus' src={red_minus} />
                                                                                <input type='file' onChange={(event)=>imageChanges(data , event)} id='files' style={{display: 'none'}}/>
                                                                            </div>
                                                                        </>

                                                                    }
                                                                </div>
                                                                <div className='col-4'>
                                                                    {
                                                                        data.CartDetailCustProImg3 === null ? 
                                                                        <>
                                                                            <img onClick={(e)=>imageClickss(data)}  className='web_pending_one' src={new_image} />
                                                                            <input type='file' onChange={(event)=>imageChangess(data , event)} id='filess' style={{display: 'none'}}/>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <img className='web_pending_one' src={ImageUrl+'/'+data.CartDetailCustProImg3} />
                                                                            <div className=' red_minusss'>
                                                                                <img onClick={(e)=>imageClickss(data)} style={{width:'20px' , height:'20px'}} className='web_pending_one red_minus' src={red_minus} />
                                                                                <input type='file' onChange={(event)=>imageChangess(data , event)} id='files' style={{display: 'none'}}/>
                                                                            </div>
                                                                        </>

                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        )
                                                    }

                                                </div> 
                                            </div>

                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 mt-2'>
                                <div>
                                    <div onClick={()=>generateOtp()} style={{cursor:'pointer'}} className='web_cart_col_twenty_one'>
                                        <p className='web_cart_col_twenty_two' >Generate otp</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
        
                <div style={{marginTop:'100px'}}>
                </div>

                        {/* Same as */}
                <ToastContainer />
            </>
                <Footer />

            <ChakraProvider>
                <Modal
                    isCentered isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay
                        bg='blackAlpha.300'
                        backdropFilter='blur(10px) hue-rotate(90deg)'
                        />
                        <ModalContent>
                        <ModalHeader>Verification</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <p>Please Share the 4 digit Verification code to the delivery agent</p>
                            <h4 style={{ textAlign:'center' , letterSpacing : '10px' , fontSize : '30px' }} className='mt-4'>{otp?otp : ''}</h4>
                        </ModalBody>
                        <ModalFooter>

                        </ModalFooter>
                        </ModalContent>
                </Modal>
            </ChakraProvider>
        </>
  )
}
