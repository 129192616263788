import React from 'react'
import trac_on from '../../../assets/img/trac_on.png'

export default function Renew_cart_com() {
  return (
    <>
      <div>
          <p className='re_cart_one'>Order Id : #157845</p>
          <div className='d-flex justify-content-between'>
              <p className='re_cart_two'>Deliver to</p>
              <p className='re_cart_three'>Manage Address</p>
          </div>
          <div className='re_cart_four'>
               <p className='re_cart_five'>Ajay Bharadwaj</p>
               <p className='re_cart_six'>1/65, North Well Street, Anna Main Road,
Nagercoil, Tamil Nadu, 629709.</p>
          </div>
          <p className='re_cart_seven my-3'>Package Contains :</p>
          <div className='re_cart_eight'>
            <div className='row'>
                <div className='col-7'>
                    <p className='re_cart_nine'>Apple</p>
                    <p className='re_cart_ten'>Macbook 2021 M1</p>
                    <select className="form-select kyc_in_one" aria-label="Default select example">
                        <option selected>3</option>
                        <option value="1">4</option>
                        <option value="2">5</option>
                        <option value="3">6</option>
                    </select>
                </div>
                <div className='col-5'>
                    <img className='re_cart_eleven' src={trac_on} />
                </div>
            </div>
          </div>
      </div>
    </>
  )
}