import React from 'react'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'
import Mob_kyc_five from '../../components/mobile_view_comp/kyc_five.js/mob_kyc_five'

export default function Kyc_five() {
  return (
    <>
      <div style={{width:'90%' , margin:'auto' ,marginTop:'65px'}}>
        <Cart_one_head link='/kyc_four' head='Approval' />
       
        <Mob_kyc_five />
      </div>
    </>
  )
}