import React , { useState , useEffect } from 'react'
import Profile_home_nav from '../page/profile_home/profile_home_nav'
import det from '../assets/img/det.png'
import hrrt from '../assets/img/hrrt.png'
import car from '../assets/img/car.png'
import axios from 'axios'
import { WishList } from '../redux/actions/productAction' 
import { ToastContainer, toast } from 'react-toastify';
import { ImageUrl } from './globalvariable/globe'
import { selectedRemoveProduct , Wishcount } from '../redux/actions/productAction'
import { useNavigate } from 'react-router-dom'
import ReactLoading from 'react-loading';
import { Base_Url } from './globalvariable/globe'
import Skeleton from 'react-loading-skeleton'
import { useSelector , useDispatch } from 'react-redux';

export default function Wishlist() {

  let navigate = useNavigate()
  let skl = [1,2,3]
  const [countss  , setCountss ]= useState(1)
  const [ keys , setKeys ] = useState()
  const [ allWish , setAllWish ] = useState()


  let dispatch = useDispatch()

  useEffect(()=>{
    Alldata()
  },[])

  useEffect(()=>{
    let wishss = localStorage.getItem('wish_list')
    setAllWish(JSON.parse(wishss))
  },[countss])



  const Alldata = async () =>{
    let wish =  localStorage.getItem('wish_list')
    let another = JSON.parse(wish)
    console.log(another,'another')
    setAllWish(another) 
    
  }

  const errnotify = (dat) => toast.error( dat , {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const susnotify = (dat) => toast.success( dat , {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
    
  const [touch , setTouch ]= useState(true);

  const clicked = async ( data , key ) =>{ 
    
    setKeys(key)
    let allata = localStorage.getItem('wish_list')
    let another = JSON.parse(allata)

    let wish_data = [
       data
      
    ]

    if(localStorage.getItem('wish_list')){
        another.forEach((element, index) => {
          console.log(wish_data,'sussss')
            if( element.datas.ProId === wish_data[0].datas.ProId){
                console.log('sussss')
                let allanother = another
                allanother.splice(index , 1)
                localStorage.setItem('wish_list' , JSON.stringify(allanother))
                dispatch(Wishcount(allanother))
                Alldata()
            }
        })
    }
    setCountss(countss +1)
    
  }

  const clicked_pro = ( data ) =>{
    navigate('/detail/'+data.datas.ProName
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '')
      +'/'+data.datas.ProId)
  }

  return (
    <>
       <div className='pro_home_one  mt-150'>
          <div className='row' >
            
            <Profile_home_nav />

            <div className='col-8'>
            <div className='pro_home_fifteen'>
                <div className='d-flex'>
                  <p className='pro_home_eight'>Wish List {  allWish === null || allWish?.length === 0 ? '' : `( ${allWish?.length} )` }</p>
                </div>
              </div>
              <div className='pro_home_seven mt-3'>
              {
                
                allWish && [...allWish]?.reverse()?.map(( data , key ) =>{
                  return(
                      <div className='mob_wi_one mt-3'>
                        <div className='row'>
                            <div onClick={()=>clicked_pro(data)} className='col-4'>
                                <img style={{width:'155px'}} className='mx-auto d-block' src={data.datas.ProImgPrimary === null ? '' : ImageUrl+'/'+data.datas.ProImgPrimary } />
                            </div>
                            <div className='col-8'> 
                                  <div className='row' style={{paddingTop:'10px'}}> 
                                      <div className='col-10'>
                                          <p onClick={()=>clicked_pro(data)} className='mob_wi_two'>{data.datas.BrandName}</p>
                                          <p onClick={()=>clicked_pro(data)} className='mob_wi_threes'>{data.datas.ProName}</p>
                                          {/* <p onClick={()=>clicked_pro(data)} className='mob_wi_fours'>256/8 gb , 13.7” Retina Display</p> */}
                                      </div>
                                    <div className='col-2'>
                                        <img onClick={()=>clicked(data , key)} className={
                                          (()=>{
                                            if(keys === key){
                                              return 'mr-1'
                                            }else if(touch===true){
                                              return 'mr-1'
                                            }else{
                                              return 'mr-1'
                                            }
                                          })()}  
                                        src={hrrt} />
                                    </div>
                                </div>
                                <div className='mt-70 d-flex justify-content-between '>
                                    <div className=''>
                                        <p style={{textAlign:'left' }} className='mob_wi_five'>₹ {data.datas.ProRentAmt}/M</p>
                                    </div>
                                    <div onClick={()=>clicked_pro(data)} style={{ width : '40%' }} className='mob_wi_six'>
                                        <div className='d-flex justify-content-center'>
                                          <p style={{cursor:'pointer'}} className='mob_wi_seven'>View Detail</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                    )
                  })
                }
                {
                  allWish === null || allWish?.length === 0 ?
                      <p style={{textAlign:'center' , marginTop:'20px',fontWeight:'600'}}>Wishlist is empty</p> : ''
                    
                }
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
    </>
  )
}