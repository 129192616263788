import React , { useState , useEffect } from 'react'
import ItemsCarousels from 'react-items-carousel';
import Order_home_det from './order_home_det';
import Profile_home_nav from '../profile_home/profile_home_nav'

export default function Order_home_comp() {

  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;
  const [ keyvalue , setKeyValue ] = useState(0)
  const [ ke , setKe ] = useState(0)
  const [subValue , setSubvalue] = useState()

  const datas = [
    {
        "cat" : "pending",
        "detail" :[
            {
                "id" : "#597586446"
            }
        ]
    },
    {
        "cat" : "Running",
        "detail" :[
            {
                "id" : "#222222222"
            }
        ]
    },
    {
        "cat" : "Handover",
        "detail" :[
            {
                "id" : "#22222233sss2"
            }
        ]
    },
    {
        "cat" : "Service",
        "detail" :[
            {
                "id" : "#22222233sss2"
            }
        ]
    },
    // {
    //     "cat" : "Replaced"
    // }
]
useEffect(() => {
    toggle(0,datas[0])
  },[]);

const toggle = (key,data) =>{
    setKeyValue(key)
    if(data.detail){
        setKe(key)
         setSubvalue(<Order_home_det text={data} />)
      }else{
        setSubvalue()
      }
}
  return (
    <>
    <div className='pro_home_one  mt-150'>
          <div className='row' >
            
            <Profile_home_nav />

            <div className='col-8'>
              <div className='pro_home_fifteen'>
                <div className='d-flex'>
                  <p className='pro_home_eight'>Order</p>
                </div>
              </div>
              <div className='pro_home_seven mt-3'>

                <div>
                  <ItemsCarousels
                      requestToChangeActive={setActiveItemIndex}
                      activeItemIndex={activeItemIndex}
                      numberOfCards={5}
                      gutter={20}
                      outsideChevron
                      chevronWidth={chevronWidth}
                  >
                      {datas.map((data,key)=>
                      <>
                      <div className="row">
                        <div className='col-3'>
                        </div>
                          <div className='col-9'>
                              <p onClick={()=>toggle(key,data)} className={(keyvalue === key ? 'txttsss' :'txtt')}>{data.cat}<hr className={(keyvalue === key ? 'ord_home_one' :'ord_home_ones')}/></p>
                          </div>
                          </div>
                      </>
                      )}
                  </ItemsCarousels>
                </div>

              </div>
              <div className='pro_home_fifteen mt-3'>
                {ke === keyvalue ? subValue : ''}
              </div>
            </div>
          </div>
        </div>
    </>
  )
}