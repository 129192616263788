import React , { useState , useEffect } from 'react'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'
import Tr from '../../components/mobile_view_comp/track/tr'
import { useParams , useNavigate } from 'react-router-dom'
import axios from 'axios';
import { Base_Url } from '../../components/globalvariable/globe'
import { useDispatch } from 'react-redux'
import { ImageUrl } from '../../components/globalvariable/globe'
import { Link } from 'react-router-dom';
import kart from  '../../assets/img/kart.png'

export default function Order_track() {
  
  const {id} = useParams()

  let token = localStorage.getItem('user_data')

  const [ alldata , setAlldata ] = useState()

  let dispatch = useDispatch();

  let navigate = useNavigate()

  const All = async () =>{
    if(token === null){
      navigate('/')
      return
    }
      let responce = await axios ({
          method : 'post',
          url : `${Base_Url}/viewPendingOrders`,
          headers: { 
              'Authorization': `Bearer ${token}` 
          },
          data : {
              orderId : id
          }
      })
      .then((res)=>{
          setAlldata(res.data.Data)
          console.log(res.data.Data)
      })
      .catch((err)=>{
        if(err.response.status === 401){
          navigate('/login')
        }
          console.log(err)
      })
  }

  useEffect(()=>{
      All()
  },[])

  const next_page = async () =>{
      let response = await axios({

      })
  }


  return (
    <>
      <div style={{width:'90%' , margin:'auto' ,marginTop:'65px' , paddingBottom:'90px'}}>
          <Cart_one_head link='/order' head='Pending Orders' />
          <p className='track_one mb-4'>Details</p>
          <div className='row'>
              <div className='col-4'>
                <p className='track_two'>Order Id</p>
                <p className='track_two'>Booking Period</p>
              </div>
              <div className='col-1'>
                <p className='track_two'>:</p>
                <p className='track_two'>:</p>
              </div>
              <div className='col-7'>
                <p className='track_two'>{alldata?alldata.orderDetails.orderAutoGenId : 'Loading...'}</p>
                <p className='track_two'>{alldata?alldata.orderDetails.fromDate + ' - ' + alldata.orderDetails.toDate : 'Loading...'}</p>
              </div>
          </div>
          <p className='track_one mb-3 mt-2'>Deliver to</p>
          <div className='track_three'>
            <p className='track_four'>{alldata?.deliverTo?.UserDelivAddrFName } {alldata?.deliverTo?.UserDelivAddrLName }</p>
                                    <p className='web_cart_col_three' >{alldata?.deliverTo?.UserDelivAddrAddress+', '+ alldata?.deliverTo?.UserDelivAddrAppart +', '+alldata?.deliverTo?.UserDelivAddrCity + ', '+ alldata?.deliverTo?.UserDelivAddrState + ', ' + alldata?.deliverTo?.UserDelivAddrPost+'. '}</p>
          </div>
          <p className='track_one mb-3 mt-3'>Status</p>
          
          <Tr  data={alldata?alldata.status : 0} />

          <div>
          {
            alldata?.productDetails.map((data , key)=>{
                return(
            <div className='track_tr_one mt-3' key={key}>
              <div className='row'>
                  <div className='col-8' >
                      <p className='track_para_one'>{data.BrandName}</p>
                      <p className='track_para_two'>{data.ProName.substring(0,30)}...</p>
                      <p className='track_para_three'>Qty : {data.CartProQty}</p>
                  </div>
                  <div className='col-4'>
                      <img style={{width : '78px'}} className='track_para_four'src={ImageUrl+'/'+data.ProImgPrimary} />
                  </div>
              </div>
            </div>
            )
              })
          }
          </div>
          <div className='mob_foot'>
          <div className='row'>
              <div className='col-6'>
                <p className='compara1'>Rent</p>
                <p className='compara2'>₹ {alldata?.rentPerMonth}</p>
              </div>
              <div className='col-6'>
                  <div className='bbtn'> 
                      <Link to={'/checklist/'+alldata?.orderDetails.orderAutoGenId}>
                            <p style={{ textAlign : 'center' }} className='compara'>Verify</p>
                            {/* <img className='kaart' src={kart} /> */}
                      </Link>
                  </div>
              </div>
          </div>
      </div>
      </div>
    </>
  )
}