import React , { useState  , useEffect , useRef } from 'react'
import Left from '../../../assets/img/left.png'
import { Link } from 'react-router-dom'
import axios from 'axios';

import msg from '../../../assets/img/msg.png'
import Image_upload from '../../image_upload/image_upload'
import { db } from '../Loginchat/FirebaseConfig'
import { uid } from 'uid'
import { set ,ref, onValue, push, child } from 'firebase/database'
import Mobile_foot from '../../../page/mobile/common/mobile_foot'
import * as Scroll from 'react-scroll';
import { useNavigate } from 'react-router-dom'
import { query, orderBy, limit } from "firebase/firestore";  
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { string } from 'prop-types'
import Header from '../../../common/head_foot/header_two';
import Footer from '../../../common/footer';
import { Base_Url } from '../../globalvariable/globe';
import { Row, Col } from 'react-bootstrap';

export default function Support_comp() {
        
    let navigate = useNavigate();
    const [blogList, setBlogList] = useState([]);
    const [todo, setTodo] = useState('');
    const [login, setLogin] = useState(true);
    const [typedMessage, setTypedMessage] = useState([]);
    const [typedMessagess, setTypedMessagess] = useState([]);
    const handleUserMessage = () => {
        if (todo) {
          setTypedMessage([...typedMessage, todo]);
          writeToDataBase(todo);
        }
      };
  

      // Mobile version
const messagesRef = ref(db, 'chats/' + localStorage.getItem('user_id'));
onValue(messagesRef, (snapshot) => {
  const data = snapshot.val();
  // Handle new messages received from Firebase and update your mobile interface
});


    const handleChange = (e) => {
      setTodo(e.target.value);
    };
  
    const messagesEndRef = useRef();
  
    const scrollToBottom = () => {
      if (!messagesEndRef.current) return;
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    };
  
  
    const [chatId, setChatId] = useState({ 'chatId': 0 })
  
    const handleQueryClick = (queryMessage, id,ans) => {
      if(ans==0) setTypedMessage([...typedMessage,queryMessage])
      else if(ans==1) setTypedMessagess([...typedMessagess,queryMessage])
      setChatId({ 'chatId': id })
      setTodo(queryMessage);
      // setTypedMessage('');
      writeToDataBase(queryMessage);
    };
  
    const writeToDataBase = (message) => {
      let date = new Date().toLocaleString();
      if (message === '') {
        return;
      }
  
      const newPostKey = push(child(ref(db), 'chats')).key;
  
      set(
        ref(db, 'chats/' + localStorage.getItem('user_id') + '/' + newPostKey),
        {
          imageUrl: '',
          message: message,
          messageSentBy: 'User',
          adminname: 'name',
          uId: localStorage.getItem('user_id'),
          messageType: 'Text',
          timeStamp: date,
        }
      );
  
      setTodo('');
      // setTypedMessage([...typedMessage, message]);
    };
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.post(`${Base_Url}/chat_bot`, chatId);
          setBlogList(response.data.Data.chatMessage);
          const checkType = response.data.Data.chatMessage
          if (checkType[0].answer == 1) setTypedMessagess([...typedMessagess, checkType[0].ChatbotMessage])
        } catch (error) {
          console.error(error);
        }
      };
  
      fetchData();
  
      if (localStorage.getItem('user_data')) {
        onValue(ref(db, 'chats/' + localStorage.getItem('user_id')), (snapshot) => {
          const data = snapshot.val();
  
          if (data !== null) {
          }
          scrollToBottom();
        });
      } else {
        navigate('/');
      }
    }, [chatId]);
  


    
  const renderChatMessages = () => {
    if (!Array.isArray(blogList)) return null;

    return (
      <div>


{typedMessage && typedMessage.map((val) => {
  return (
    <div className={'d-flex justify-content-end'} style={{ marginTop: '10px', minWidth: '100px' }}>
      <div
        className='chat_scrll'
        style={{
          width: 'auto',
          height: 'auto',
          paddingTop: '5px',
          paddingBottom: '5px',
          paddingRight: '15px',
          paddingLeft: '15px',
          backgroundColor: '#f1f1f1',
          borderRadius: '6px',
          textAlign: 'center',
        }}
      >
        <p>{val}</p>
        <p style={{ marginTop: '-4px', fontSize: '10px', textAlign: 'right' }}>{new Date().toLocaleTimeString()}</p>
      </div>
    </div>
  );
})}

{typedMessagess && typedMessagess.map((val) => {
  return (
    <div className={'d-flex justify-content-start w-25'} style={{ marginTop: '10px' }}>
      <div
        className='chat_scrll'
        style={{
          width: 'auto',
          height: 'auto',
          paddingTop: '5px',
          paddingBottom: '5px',
          paddingRight: '15px',
          paddingLeft: '15px',
          backgroundColor: '#f1f1f1',
          borderRadius: '6px',
          textAlign: 'center',
        }}
      >
        <p>{val}</p>
        <p style={{ marginTop: '-4px', fontSize: '10px', textAlign: 'right' }}>{new Date().toLocaleTimeString()}</p>
      </div>
    </div>
  );
})}



      </div>
    );
  };

  return (
    <>
    <div className="second-tops fixed-top navbar-light top-tab-nav">
            <div className="d-flex  m-auto paddd" >
               <div onClick={()=>{
                navigate(-1)
               }} className='loab'>
                    <img src={Left} />
                </div>
               <div style={{margin: 'auto'}} className='loab'>
                   <p className='ccaaat'>Support</p>
               </div>
               {/* <div >
                   <p className='supp_one'>End Chat</p>
               </div> */}
            </div>
        </div>
    <div className=' '>
      <div className=''>
        <div className='row'>
          <div className='col-12'>
            <div style={{ position: 'relative', height: '485px', overflowY: 'scroll' }} className='pro_home_seven scroll_padding_bottom'>
              <div className='d-flex'>
              </div>
              {renderChatMessages()}
            </div>
            <div className='supp_two_webchattttt'>
              <div className='row'>


                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                  {blogList.map((chatOption) => (

                    <div
                      className='d-flex'
                      key={chatOption.ChatbotID}
                      onClick={() => handleQueryClick(chatOption.ChatbotMessage, chatOption.ChatbotID, chatOption.answer)}
                      style={{ marginRight: '10px', marginBottom: '10px' }}
                    >
                      {blogList.length > 1 ?
                        <div
                          style={{
                            background: '#F4B644',
                            borderRadius: '15px',
                            padding: '2px',
                            textAlign: 'start',
                          }}
                          className='border border-gray-100 px-2 '
                        >
                          <p style={{ color: '#303651', margin: '0', fontSize: '13px', fontWeight: '500' }}>{chatOption.ChatbotMessage}</p>
                        </div> : null}
                    </div>
                  ))}
                </div>



                <div className="col-10">
  <input onChange={(e) => handleChange(e)} value={todo} type="text" className="form-control" placeholder="Enter Message" />
</div>
<div onClick={() => handleUserMessage()} style={{ marginTop: '12px', cursor: 'pointer' }} className="col-2">
  <img className="mt-1" src={msg} alt="Message" />
</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);
}
