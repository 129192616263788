import React from 'react'
import Home_payment_one_comp from '../components/fullwidth/home_payment/home_payment_one_comp'
import Header_two from '../common/head_foot/header_two'
import Footer from '../common/footer'
import { Link , useParams  } from 'react-router-dom'
import Home_payment_footer from '../components/fullwidth/home_payment/home_payment_footer'
import Arrow_payment from '../components/mobile_view_comp/payment/arrow_payment'

export default function Home_payment_one() {

  const {id} = useParams()

  return (
    <>
    { id === '0' ?
        <>
            <Header_two />
            <Home_payment_one_comp />
            {/* <Home_payment_footer val='1' link='/payment_two' /> */}
            <Footer  />
        </>
        :
        <>
            <Header_two />
            <div className='pro_home_one  mt-150 mb-4'>
                <Arrow_payment />
            </div> 
            <Home_payment_one_comp />
            {/* <Home_payment_footer val='0' link='/payment_two' /> */}
            <Footer />
        </>
      }
    </>
  )
}
