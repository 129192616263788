import React , { useState , useEffect } from 'react'
import { Link } from 'react-router-dom'

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter, 
    ModalBody,
    useDisclosure,
    Button,
    ChakraProvider
  } from '@chakra-ui/react'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Base_Url } from '../../globalvariable/globe';
import Cart_one_head from '../cart_one/cart_one_head'
import wrong from '../../../assets/img/wrong.png'
import icon from '../../../assets/img/icon.png'
import tkkt from '../../../assets/img/tkkt.png'
import { useNavigate , useParams } from 'react-router-dom'



export default function Email_Veri() {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [ open , setOpen ] = useState(false)
    const [ loading , setLoading ] = useState(false)

    // const { id , details } = useParams()

  let navigate = useNavigate()

 

  useEffect(()=>{

    onOpen()
    start()

  },[])

  let start = async ()=>{
    let responce = await axios({
        method : 'post',
        url : Base_Url+'/',
        data : {

        }
    })
    .then((res)=>{
        console.log(res)
    })
    .catch((err)=>{
        console.log(err)
        setOpen(true)
    })
  }



  const errnotify = (dat) => toast.error( dat , {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
  });

  return (
    <>
    <div style={{width:'90%' , margin:'auto' ,marginTop:'65px'}}>
    <Cart_one_head link='/' head='Email Verification' />
      <div className='mt-4'>
         
        </div>


      <ChakraProvider>

      <Modal isCentered={true} isOpen={isOpen} size={'xs'} onClose={()=>{ navigate('/') }}>

        <ModalOverlay />
          <ModalContent>
              <ModalBody>
                <img  style={{ width : 'auto' , height : 'auto' , margin : 'auto' , marginBottom : 20 , marginTop : 20 }}  src={icon} />
                {
                    open === true ? 
                    <>
                        <img  style={{ width : '60%' , height : '60%' , margin : 'auto' }}  src={wrong} />
                        <p style={{ fontSize :  16 , color : 'red' , textAlign : 'center' , fontWeight : '600' , marginTop : 20 }}>Email Verification Failed</p>
                    </>
                    :
                    <>
                        <img  style={{ width : '60%' , height : '60%' , margin : 'auto' }}  src={tkkt} />
                        <p style={{ fontSize :  16 , color : 'green' , textAlign : 'center' , fontWeight : '600' , marginTop : 20 }}>Email Verification Success</p>
                    </>
                }
                
              </ModalBody>
              <ModalFooter >
                <Button bg={'#F4B644'} color={'white'} onClick={()=>{ navigate('/') }} >
                    Back To Home
                </Button>
              </ModalFooter>
          </ModalContent>

      </Modal>

      </ChakraProvider>


      <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
                {/* Same as */}
        <ToastContainer />
        </div>
    </>
  )
}