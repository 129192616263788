import React, { useState , useEffect } from 'react';
import ItemsCarousel from 'react-items-carousel';
import './collection.css'
import Data_cat from './data_cat';
import axios from 'axios';
import { useSelector , useDispatch } from 'react-redux';
import { allProduct  } from '../../../redux/actions/productAction';
import { Base_Url } from '../../globalvariable/globe';
import Skeleton from 'react-loading-skeleton' 
import { useParams , useNavigate } from 'react-router-dom';

export default function Mobile_collection() {

  let navigate = useNavigate()

  const {id , name , bro} = useParams()

  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;
  const [ cat , setCat ] = useState(0)
  const [ cats , setCats ] = useState(0)
  const [ brand , setBrand ] = useState(bro)
  const [ pkey , setPkey ] = useState(0)


  const [ usename , setUsename ] = useState()

  const dispatch = useDispatch()

  const skl = [1]
  
  const product =  useSelector((state)=>state.allpoductData)
  const imgs = product.awsbaseurl

  const clicked = async (data,key) =>{
      setBrand(0)
      setUsename(data.CatName)
      setPkey(key)
      setCat(data.CatId)
      navigate('/products/'+data.CatName+'/'+data.CatId+ '/' + 'All' + '/'+ 0 )
  }

const bodyParameters = {
   key: "value"
}; 
  
  const clikk =async () =>{
    let responce =await axios({
      method: 'post',
      url: `${Base_Url}/products`,
      bodyParameters, 
      data :{
        catId : id,
        brandId : brand
      }
    })
    setCat(id)
    dispatch(allProduct(responce.data.Data))
  }

  useEffect(() => {
    setUsename(name)
    clikk()
  },[]);

  useEffect(() => {
    clikk()
  },[cat || brand]);


  return (
      <>
        <div className='lolllllo' >
          <ItemsCarousel
            requestToChangeActive={setActiveItemIndex}
            activeItemIndex={activeItemIndex}
            numberOfCards={1}
            gutter={20}
          >
            <>
              <ul className="nav navclass nav-tabs" id="myTab" role="tablist">
              
              {product.category?.map((dat,key)=>
                <div  onClick={()=>clicked(dat,key)} className="nav-item" role="presentation">
                  <button style={ usename === dat.CatName ? { backgroundColor : '#e0e0e0' } : {}} className={(usename === dat.CatName ? "nav-link active" : "nav-link")} id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">{dat.CatName}</button>
                </div>
              )}
              </ul>
            </>
              
          </ItemsCarousel>
          {
            product.brands_of_1st_category ? <Data_cat catid={cats} catids={cat} /> : ''
          }
          
        </div>
          {
            !product.category && skl.map(()=>{
              return(
                  <>
                  <div className='row mt-2'>
                    <div className='col-3' style={{ height:'30px' , borderRadius:'10px'}} >
                      <Skeleton width='100%' height='100%' />
                    </div>
                    <div className='col-3' style={{ height:'30px' , borderRadius:'10px'}} >
                      <Skeleton width='100%' height='100%' />
                    </div>
                    <div className='col-3' style={{ height:'30px' , borderRadius:'10px'}} >
                      <Skeleton width='100%' height='100%' />
                    </div>
                    <div className='col-3' style={{ height:'30px' , borderRadius:'10px'}} >
                      <Skeleton width='100%' height='100%' />
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-6' style={{ height:'30px' , borderRadius:'10px'}} >
                      <Skeleton width='100%' height='100%' />
                    </div>
                    <div className='col-6' style={{ height:'30px' , borderRadius:'10px'}} >
                      <Skeleton width='100%' height='100%' />
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-12' style={{ height:'90px' , borderRadius:'10px'}} >
                      <Skeleton width='100%' height='100%' />
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-12' style={{ height:'90px' , borderRadius:'10px'}} >
                      <Skeleton width='100%' height='100%' />
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-12' style={{ height:'90px' , borderRadius:'10px'}} >
                      <Skeleton width='100%' height='100%' />
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-12' style={{ height:'90px' , borderRadius:'10px'}} >
                      <Skeleton width='100%' height='100%' />
                    </div>
                  </div>
                </>
              )
            })
          }
      </>
  )
}