import React , { useState , useEffect } from 'react'
import './kyc.css'
import { makeStyles } from '@material-ui/core/styles'; 
import file from '../../../assets/img/file.png'
import delect from '../../../assets/img/delect.png'
import '../../../components/fullwidth/home_kyc/home_kyc.css' 
import { useNavigate , Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Base_Url , ImageUrl } from '../../globalvariable/globe';
import Kyc from '../../../assets/img/kyc.png'
import headset from '../../../assets/img/ggerhead.png'
import ReactLoading from 'react-loading';
import Dot_arrow from '../cart_one/dot_arrow';
import Kycone from '../../../assets/img/kyc1.png'

 let buthide=0;
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    textAlign: 'center',
  },
  imgBox: {
    maxWidth: "80%",
    maxHeight: "80%",
    margin: "10px"
  },
  img: {
    height: "inherit",
    maxWidth: "inherit",
  },
  input: {
    display: "none"
  }
}));


export default function Mob_kyc_one() {

  let navigate = useNavigate()  
    let formData = new FormData();

    const [ img , setImg ] = useState()
    const [ data , setData ] = useState('')
    const [ alldata , allsetData ] = useState('')
    const user = localStorage.getItem('user_data')
    const [ loading , setLoading ] = useState(false)
    const [ suskyc , setSuskyc ] = useState(false)
    const [ pendkyc , setPendkyc ] = useState(false)
    const [ rejkyc , setRejkyc ] = useState(false)
    const [ kycstatus , setKcstatus ] = useState()
    const [ good , setGood ] = useState(false)
    let backimage = '' ;
    let buthide=0;
    const errnotify = () => toast.error('Upload Image', {
        position: "top-right", 
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true, 
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

    useEffect(()=>{
        setLoading(false)
        setSuskyc(false)
        Alldata()
    },[])

    const Alldata = async () => {
        if(user===null){
            navigate('/')
            return
        }

        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/kycUserImage`,
            headers: { 
                'Authorization': `Bearer ${user}` 
            }
        })
        .then((res)=>{
            allsetData(res.data)
            setKcstatus(res.data.Data.KycApproval)
            if(res.data.Data.KycApproval === 1 ){
              setSuskyc(true)
            }
            else if(res.data.Data.KycApproval === 0 ){
              setPendkyc(true)
            }else if(res.data.Data.KycApproval === 2 ){
              setRejkyc(true)
            }
            if(res.data.status === true){
              setData(res.data.Data.KycImage)
              backimage = res.data.Data.KycImage

            }else{
              setPendkyc(true)
              
            }
            console.log(res)
        })
        .catch((err)=>{
          if(err.response.status === 401){
            navigate('/login')
          }
        })
    }

    const remove = () =>{
      document.querySelector('#file').value = "";
        // setData(backimage)
        setData('')
        setImg()
        setGood(true)
    }

    const Clicked = async() => {
        setLoading(true)
        
        
        let imagefile = document.querySelector('#file');

        if(imagefile == null){
          console.log('worked')
          errnotify()
          setLoading(false)
          return
        }
      
        formData.append("userImg", imagefile.files[0]);
       
        if(imagefile.files[0] == undefined){
          console.log('worked')
          errnotify()
          setLoading(false)
          return
        }
        
        
      //   if(good === true){
      //     setLoading(false)
      //     errnotify()
      //     return
      // }


        // if(data != ''){
        //     navigate('/kyc_two')
        //     setLoading(false)
        //     return
        // }
        let responce = await axios.post( `${Base_Url}/kycUserImage`, formData, {
            headers: {
                'Authorization': `Bearer ${user}` ,
                'Content-Type': 'multipart/form-data'
            } 
        })
        .then((res)=>{
            navigate('/kyc_two')
            console.log(res)
            setLoading(false)
        })
        .catch((err) =>{
            setLoading(false)
            if(err.response.status === 401){
              navigate('/login')
            }
            console.log(err)
        })
    }


  const classes = useStyles();
  const [source, setSource] = useState("");
  
  const handleCapture = (target) => {
    setGood(false)
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const newUrl = URL.createObjectURL(file);
        setData('')
        setImg({'img' : newUrl})
      }
    }
  }

  const Clickedone = () => {
    navigate('/kyc_two')
  }

  const gotosupport =()=>{
    navigate('/support')
  }

  return (
    <>
    {
                  kycstatus === 1 ? '' : kycstatus === 0 ? <img style={{ width : '100%'  }} src={Kycone} />: alldata?.status === false ?  <img style={{ width : '100%'  }} src={Kycone} />  : 
                   kycstatus === 2 ? <img style={{ width : '100%'  }} src={Kycone} />  : ''
                }
   
      <div className='mt-4'>
          <div className='row'>
              <div className='col-2'>
              {
                              alldata?.status === false ? 
                              <div class="donut-ring">
                                <div className={kycstatus === 1 ? "ring red" : "ring red"}></div>
                                <div className={kycstatus === 1 ? "ring blue" : "ring blue"}></div>
                                <div className={kycstatus === 1 ? "ring orange" : "ring orange"}></div>
                                <div className={kycstatus === 1 ? "ring grrreeeennn4" : "ring green"}></div>
                                <div className="cutout">
                                  <p className='kyc_p_one'>1 Of 4</p>
                                </div>
                              </div>
                              : kycstatus === 1 ? 
                                <div class="donut-ring">
                                  <div className={kycstatus === 1 ? "ring red" : "ring red"}></div>
                                  <div className={kycstatus === 1 ? "ring blue" : "ring blue"}></div>
                                  <div className={kycstatus === 1 ? "ring orange" : "ring orange"}></div>
                                  <div className={kycstatus === 1 ? "ring grrreeeennn4" : "ring green"}></div>
                                  <div className="cutout">
                                    <p className='kyc_p_one'>1 Of 4</p>
                                  </div>
                                </div>
                              :
                               kycstatus === 0 ? 
                                <div class="donut-ring">
                                  <div className={kycstatus === 1 ? "ring red" : "ring red"}></div>
                                  <div className={kycstatus === 1 ? "ring blue" : "ring blue"}></div>
                                  <div className={kycstatus === 1 ? "ring orange" : "ring orange"}></div>
                                  <div className={kycstatus === 1 ? "ring grrreeeennn4" : "ring yellowonw"}></div>
                                  <div className="cutout">
                                    <p className='kyc_p_one'>1 Of 4</p>
                                  </div>
                                </div>
                              :
                              kycstatus === 2 ? 
                              <div class="donut-ring">
                                <div className="ring red"></div>
                                <div className="ring blue"></div>
                                <div className="ring orange"></div>
                                <div className= "ring redones"></div>
                                <div className="cutout">
                                  <p className='kyc_p_one'>1 Of 4</p>
                                </div>
                              </div>
                            :
                            ''

                            }
              </div>
              <div className='col-3 mt-2'>
                <p className='kyc_p_two_changed'>Step 1</p>
                <p className='kyc_p_three'>Selfie</p>
              </div>
          </div>
      </div>

      <div className='mt-3 pb-5'>
        <div className='d-flex justify-content-between'>
        {
                              kycstatus === 0 ? <p style={{ color: '#cfcf00', fontSize: '12', fontWeight: '600' }} >Pending</p> : kycstatus === 1 ? <p style={{ color: 'green', fontSize: '12', fontWeight: '600' }} >Approved</p> : kycstatus === 2 ? <p style={{ color: 'red', fontSize: '12', fontWeight: '600' }} >Rejected</p> : ''
                            }
        </div>
            <div className='kyc_cont'>
              {
                data ? <img style={{width:'100%'}} className='kyc_img_one' src={ ImageUrl + '/' + data } />  : img ? <img style={{width:'100%'}} className='kyc_img_one' src={img.img} /> :
                alldata?.status === false ? <img className='kyc_img_one ' src={Kyc} /> : kycstatus === 2 ? <img className='kyc_img_one ' src={Kyc} /> : ''
              }
               {(() => {

                  if((suskyc == true)){
                    let buthide=1;
                  }else if((pendkyc == true) &&  (data != '')){
                    var buthide=1;
                  }else if((pendkyc == true) &&  (data == '')){
                    var buthide=0;
                  }else if((rejkyc == true)){
                    var buthide=1;
                  }

                  })()}
                 <div class="kyc_centered">
                 {(() => {
              if(suskyc == false || rejkyc == true || (pendkyc == true) &&  (data == '')){ return(
                    <div className='kyc_cont_two'>
                        <div className='d-flex justify-content-around'>
                            <div className='kyc_cont_two_upload mt-2'>
                                <img className='kyc_cont_two_upload_two kyc_img_one' src={file} />
                                <input style={{ width :  50 }} onChange={(e)=>handleCapture(e.target)} accept="image/*" name='file' className='kyc_cont_two_upload_three' id='file' type="file" />
                            </div>
                            <div className='mt-2 kyc_line'>
                            </div>
                            <div onClick={()=>remove()} className=' mt-2'>
                                <img src={delect} />
                            </div>
                        </div>
                    </div>
                    )
                  }
                    })()}
                </div>

            </div>
        </div>
        <div className='mob_footss'>
          <div className='row'>
              <div className='col-6'>
                  <div onClick={()=>gotosupport()} className='d-flex'>
                      <div className='headset'>
                        <img className='kaart' src={headset} />
                      </div>
                      <div>
                        <p className='compara222'>Support</p>
                        <p className='compara333'>9am - 5Pm</p>
                      </div>
                  </div>
              </div>
              <div className='col-6'>

              {(() => {

 if((suskyc == true)){
  return(<div onClick={()=> Clickedone() } className='bbtn'>
                      <div className='d-flex'>
                          <p style={{cursor:'pointer'}} className='compara nextonee mt-1'>{loading ?
                          <div style={{marginLeft:'-47px' , marginTop : '-4px'}}>
                            <ReactLoading type='balls' color='#ffffff' />
                          </div>
                             : 'Next'}</p>
                      </div>
                  </div>)
 }else if((pendkyc == true) &&  (data != '')){
  return(<div onClick={()=> Clickedone() } className='bbtn'>
  <div className='d-flex'>
      <p style={{cursor:'pointer'}} className='compara nextonee mt-1'>{loading ?
      <div style={{marginLeft:'-47px' , marginTop : '-4px'}}>
        <ReactLoading type='balls' color='#ffffff' />
      </div>
         : 'Next'}</p>
  </div>
</div>)
}else if((pendkyc == true) &&  (data == '')){
  return(<div onClick={()=> Clicked() } className='bbtn'>
                      <div className='d-flex'>
                          <p style={{cursor:'pointer'}} className='compara nextonee mt-1'>{loading ?
                          <div style={{marginLeft:'-47px' , marginTop : '-4px'}}>
                            <ReactLoading type='balls' color='#ffffff' />
                          </div>
                             : 'Next'}</p>
                      </div>
                  </div>)
 }else if((rejkyc == true)){
  return(<div onClick={()=> Clicked() } className='bbtn'>
                      <div className='d-flex'>
                          <p style={{cursor:'pointer'}} className='compara nextonee mt-1'>{loading ?
                          <div style={{marginLeft:'-47px' , marginTop : '-4px'}}>
                            <ReactLoading type='balls' color='#ffffff' />
                          </div>
                             : 'Next'}</p>
                      </div>
                  </div>)
}

                })()}
                  {/* <div onClick={()=>suskyc ? Clickedone() : Clicked() } className='bbtn'>
                      <div className='d-flex'>
                          <p style={{cursor:'pointer'}} className='compara nextonee mt-1'>{loading ?
                          <div style={{marginLeft:'-47px' , marginTop : '-4px'}}>
                            <ReactLoading type='balls' color='#ffffff' />
                          </div>
                             : 'Next'}</p>
                      </div>
                  </div> */}
              </div>
          </div>
      </div>
    <ToastContainer />
    </>
  )
}