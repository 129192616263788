import React , { useState , useEffect } from 'react'
import { Link } from 'react-router-dom'
import one123 from '../../../assets/img/123.png'
import cccn from '../../../assets/img/cccn.png'
import { Base_Url } from '../../globalvariable/globe'
import { ImageUrl } from '../../globalvariable/globe'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import {
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody, 
    ModalCloseButton,
    useDisclosure,
    Button,
    ChakraProvider
  } from '@chakra-ui/react'
  import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


export default function Order_det(props) {

    const { isOpen, onOpen, onClose } = useDisclosure()

    const errnotify = (err) => toast.error(err, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    let navigate = useNavigate()

    const img =[
        {
            "img" : one123,
            "des" : "macbook M1 Chip ullw 8gb ram , 512"
        }
    ]

    let token = localStorage.getItem('user_data')
    const [ pendings , setPending ] = useState()

    const [ runi , setRuni ] = useState()
    const [ clickruni , setClickruni ] = useState()

    const [ clickhand ,  setClickedhand ] = useState()
    const [ hand , setHand ] = useState()

    const [ serve , setServe ] = useState()
    const [ clickserve , setClickerve ] = useState()

    const [ val , setVal ] = useState(0)
    const [ checked , setChecked ] = useState('checked')

    const [ vals , setVals ] = useState(0)
    const [ checkeds , setCheckeds ] = useState('checked')

    const [ valss , setValss ] = useState(0)
    const [ checkedss , setCheckedss ] = useState('checked')
    const [ canceldata , setCanceldata ] = useState(false)


    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);
  
    function openModal() {
      setIsOpen(true);
    }
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      subtitle.style.color = '#f00';
    }
  
    function closeModal() {
      setIsOpen(false);
    }


    const successnotify = (sus) => toast.success( sus , {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

    

    const handleChange =async (e) => {

        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/runningOrder`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            },
            data : {
                products : 1
            }
        })
        .then((res)=>{
            setClickruni(res.data.Data)
            console.log(res.data.Data,'click running')

        })
        .catch((err)=>{
            if(err.response.status === 401){
                navigate('/login')
  }
            console.log(err)
        })

        if(val === 1){
            setChecked('checked')
            setVal(0)
        }else{
            setChecked('')
            setVal(1)
        }
    }
    const handleChanges = async (e) => {

        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/handover`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            },
            data : {
                products : 1
            }
        })
        .then((res)=>{
            setClickedhand(res.data.Data)
            console.log(res.data.Data,'click hand')
        })
        .catch((err)=>{
            if(err.response.status === 401){
                navigate('/login')
  }
            console.log(err)
        })
        
        if(vals == 1){
            setCheckeds('checked')
            setVals(0)
        }else{
            setCheckeds('')
            setVals(1)
        }
    }

    const handleChangess = async (e) => {

        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/service`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            },
            data : {
                products : 1
            }
        })
        .then((res)=>{
            setClickerve(res.data.Data)
            console.log(res.data.Data,'click serve')
        })
        .catch((err)=>{
            if(err.response.status === 401){
                navigate('/login')
            }
            console.log(err)
        })

        if(valss == 1){
            setCheckedss('checked')
            setValss(0)
        }else{
            setCheckedss('')
            setValss(1)
        }
    }
    const first = async () => {
        if(token === null){
            navigate('/')
            return
        }
        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/pendingOrders`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            }
        })
        .then((res)=>{
            setPending(res.data.Data)
        })
        .catch((err)=>{
            if(err.response.status === 401){
                navigate('/login')
            }
            console.log(err)
        })
    }

    const run = async ()=>{
        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/runningOrder`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            }
        })
        .then((res)=>{
            setRuni(res.data.Data)
        })
        .catch((err)=>{
            if(err.response.status === 401){
                navigate('/login')
            }
            console.log(err)
        })
    }

    const handover = async ()=>{
        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/handover`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            }
        })
        .then((res)=>{
            console.log(res.data.Data,'handddddddddddddddddd')
            setHand(res.data.Data)
        })
        .catch((err)=>{
            if(err.response.status === 401){
                navigate('/login')
            }
            console.log(err)
        })
    }

    const service_data = async ()=>{
        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/service`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            }
        })
        .then((res)=>{
            setServe(res.data.Data)
        })
        .catch((err)=>{
            if(err.response.status === 401){
                navigate('/login')
            }
            console.log(err)
        })
    }

    useEffect(()=>{
        first()
        run()
        handover()
        service_data()
    },[])

    let nextpagg = ( data , key ) => {
        // console.log(data)
        navigate('/running_order/'+data.orderId)
    }

    const Service = (data) =>{
        return(
            <>
                <div className='d-flex justify-content-between mt-4'>
                    <div>
                        <p className='switch_order_one'>List by orders</p>
                    </div>
                    <div>
                        <label className="switch">
                            <input type="checkbox" checked={(checkedss)} onChange={e => handleChangess(e)} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                {
                    serve?.orders.length === 0 || serve === undefined ?  <p style={{ textAlign : 'center' , fontSize : '15px' , fontWeight : '600', marginTop:'40px'}}>No Service Orders</p>  :
                serve?.orders.map((data,key)=>{
                return(
                <>
                    <div className='ord_det_one mt-4'>
                        <div className='row'>
                            <div className='col-8'>
                                <p className='ord_det_two'>Order Id : {data.OrderAutoGenId}</p>
                                <p className='ord_det_threes_ordd'>{data.fromdate}   -   {data.todate}</p>
                            </div>
                            <div className='col-4'>
                                <p className='ord_det_four'>₹{data.orderamt}</p>
                                <p className='ord_det_fives_orddd'>{data.Type}</p>
                            </div>
                        </div>
                        <div className='row mt-1'>
                        {data.carts.map((dat,key)=>
                            {
                                return(
                                    key >=5 ?
                                    <></>
                                    :   
                                    <div className='col-3 ' >
                                        <img style={{width:'100%'}} className='ord_det_seven_orrrrdd' src={ImageUrl+'/'+dat.ProImgPrimary} />
                                        <p className='ord_det_eight'>{dat.ProName.substring(0, 8)}...</p>
                                    </div>
                                )
                            }
                                    
                                )}
                            {
                                data.remainingCount === 0 ? '' :
                                    <div className='col-3 ' >
                                        <img className='ord_det_seven_orrrrdd' src={one123} />
                                        <p className='more_items'>{data.remainingCount}<br/>Items</p>
                                        <p className='ord_det_eights'>View more</p>
                                    </div>   
                                }
                        </div>
                        <div className='row mt-4'>
                            <div className='col-6'>
                                
                            </div>
                            <div className='col-6'>
                                <Link to={'/service_request/'+data.OrderAutoGenId + '/' + 2}>
                                    <div onClick={e=>handleChangess(e)} className='ord_det_nine '>
                                        <p className='ord_det_twelve'>View Details</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </>
                )
            })
            }
            </>
        )
    }


    const Orderlist = (data) =>{
        return(
            <>
                <div className='d-flex justify-content-between mt-4'>
                    <div>
                        <p className='switch_order_one'>List by orders</p>
                    </div>
                    <div>
                        <label className="switch">
                            <input type="checkbox" checked={(checked)} onChange={e => handleChange(e)} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                {
                runi?.orders.length === 0 || runi === undefined ?  <p style={{ textAlign : 'center' , fontSize : '15px' , fontWeight : '600', marginTop:'40px'}}>No Running Orders</p>  :
                runi?.orders.map((data,key)=>
                <>
                    <div className='ord_det_one mt-4'>
                        <div className='row'>
                            <div className='col-8'>
                                <p className='ord_det_two'>Order Id : {data.OrderAutoGenId}</p>
                                <p className='ord_det_threes_ordd'>{data.fromdate}   -   {data.todate}</p>
                            </div>
                            <div className='col-4'>
                                <p className='ord_det_four'>₹{data.orderamt}</p>
                                <p className='ord_det_fives_orddd'>{data.Type}</p>
                            </div>
                        </div>
                        <div className='row mt-1'>
                        {data.carts.map((dat,key)=>
                                
                                {
                                    return(
                                        
                                    key >=5 ?
                                    <></>
                                    : <div className='col-3 ' >
                                    <img style={{width:'100%'}} className='ord_det_seven_orrrrdd' src={ImageUrl+'/'+dat.ProImgPrimary} />
                                    <p className='ord_det_eight '>{dat.ProName.substring(0, 8)}...</p>
                                </div>
                                )
                                }
                                    
                                )}
                                {
                                data.remainingCount === 0 ? '' :
                                        <div className='col-3 ' >
                                            <img className='ord_det_seven_orrrrdd' src={one123} />
                                        
                                            <p className='more_items'>{data.remainingCount}<br/>Items</p>
                                            <p className='ord_det_eights '>View more</p>
                                        </div>   
                                }
                        </div>
                        <div className='row mt-4'>
                            <div className='col-6'>
                                <Link to={'/running_order/'+data.orderId}>
                                    <div className='ord_det_ten'>
                                        <p className='ord_det_eleven'>Renew order</p>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-6'>
                                {/* <Link to='/running_order'> */}
                                    <div onClick={(e)=>nextpagg(data,key)} className='ord_det_nine'>
                                        <p className='ord_det_twelve'>View Details</p>
                                    </div>
                                {/* </Link> */}
                            </div>
                        </div>
                    </div>
                </>
                )
            }
            </>
        )
    }

    const Handover = (data) =>{
        return(
            <>
                <div className='d-flex justify-content-between mt-4'>
                    <div>
                        <p className='switch_order_one'>List by orders</p>
                    </div>
                    <div>
                        <label className="switch">
                            <input type="checkbox" checked={(checkeds)} onChange={e => handleChanges(e)} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div> 
                {
                hand?.orders.length === 0 || hand === undefined ?  <p style={{ textAlign : 'center' , fontSize : '15px' , fontWeight : '600', marginTop:'40px'}}>No Handover Orders</p>  :
                hand?.orders.map((data,key)=>
                <div className='ord_det_one mt-4'>
                    <div className='row'>
                        <div className='col-8'>
                            <p className='ord_det_two'>Order Id : {data.OrderAutoGenId}</p>
                            <p className='ord_det_threes_ordd'>{data.fromdate}   -   {data.todate}</p>
                        </div>
                        <div className='col-4'>
                            <p className='ord_det_four'>₹{data.orderamt}</p>
                            <p className='ord_det_fives_orddd'>{data.Type}</p>
                        </div>
                    </div>
                    <div className='row mt-1'>
                        {data.carts.map((dat,key)=>
                            {
                                return(
                                    key >=5 ?
                                    <></>
                                    :   
                                    <div className='col-3 ' >
                                        <img style={{width:'100%'}} className='ord_det_seven_orrrrdd' src={ImageUrl+'/'+dat.ProImgPrimary} />
                                        <p className='ord_det_eight '>{dat.ProName.substring(0, 8)}...</p>
                                    </div>
                                )
                            }
                                    
                        )}
                        {
                        data.remainingCount === 0 ? '' :
                            <div className=' col-3 ' >
                                <img className='ord_det_seven_orrrrdd' src={one123} />
                            
                                <p className='more_items'>{data.remainingCount}<br/>Items</p>
                                <p className='ord_det_eights '>View more</p>
                            </div>   
                        }
                    </div>
                    <div className='row mt-4'>
                        <div className='col-6'>
                                <div className=''>
                                    <p className='ord_det_eleven'>{data.remainingDays}</p>
                                </div>
                        </div>
                        <div className='col-6'>
                            <Link to={'/handover_req/'+data.orderId+'/'+2}>
                                <div className='ord_det_nine'>
                                    <p className='ord_det_twelve'>Handover</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                )
            }
            </>
        )
    } 

    const Service_list = () =>{
        return(
            <>
                <div className='d-flex justify-content-between mt-4'>
                    <div>
                        <p className='switch_order_one'>List by orders</p>
                    </div>
                    <div>
                        <label className="switch">
                            <input type="checkbox" checked={(checkedss)} onChange={e => handleChangess(e)}/>
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                {
                    clickserve?.products.length === 0 || clickserve === undefined ?  <p style={{ textAlign : 'center' , fontSize : '15px' , fontWeight : '600', marginTop:'40px'}}>No Service Orders</p>  :
                    clickserve?.products.map((data,key)=>
                        <div className='check_change mt-4'>
                            <Link to={data.RequestedForService === 0 ? '/service_status/'+data.CartDetailId+'/'+data.OrderAutoGenId+'/'+1  : 
                            data.RequestedForService === 1 ? '/service_status/'+data.CartDetailId+'/'+data.OrderAutoGenId+'/'+0 : '/serviced/'+data.CartDetailId+'/'+data.OrderAutoGenId+'/'+data.CartDetailInventoryProSerialNo } >
                                <div className='row'>
                                    <div className='col-8'>
                                        <p className='ord_det_two '>{data.OrderAutoGenId}</p>
                                        <p className='ord_det_threes_ordd peeerrrr'>No : {data.CartDetailInventoryProSerialNo}</p>
                                        <p className='check_change_ones peeerrrr'>{data.ProName.substring(0,20)}...</p>
                                        {
                                            data.RequestedForService === 1 ? <p style={{fontSize:'12px',fontWeight:'800',color:'orange'}}>Requested</p> : ''
                                        }
                                    </div>
                                    <div className='col-4'>
                                    <img className='check_change_twosssssss' src={ImageUrl+'/'+data.ProImgPrimary} />
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )
                }
            </>
        )
    }

    const ListOrder = () =>{
        return(
            <>
                <div className='d-flex justify-content-between mt-4'>
                    <div>
                        <p className='switch_order_one'>List by orders</p>
                    </div>
                    <div>
                        <label className="switch">
                            <input type="checkbox" checked={(checked)} onChange={e => handleChange(e)}/>
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                {
                    clickruni?.products.length === 0 || clickruni === undefined ?  <p style={{ textAlign : 'center' , fontSize : '15px' , fontWeight : '600', marginTop:'40px'}}>No Running Orders</p>  :
                    clickruni?.products.map((data,key)=>
                        <div className='check_change mt-4'>
                            <Link to={'/running_pro_detail/'+ data.OrderAutoGenId +'/'+data.CartDetailId +'/'+data.CartDetailProId} >
                                <div className='row'>
                                    <div className='col-8'>
                                        <p style={{marginBottom : '16px'}} className='ord_det_two'>{data.OrderAutoGenId}</p>
                                        <p style={{marginBottom : '4px'}} className='ord_det_threes_ordd'>No : {data.CartDetailInventoryProSerialNo}</p>
                                        <p className='check_change_ones'>{data.ProName.substring(0,20)}...</p>
                                    </div>
                                    <div className='col-4'>
                                    <img className='check_change_twosssssss' src={ImageUrl+'/'+data.ProImgPrimary} />
                                    </div>
                                </div>
                            </Link>
                        </div>
                   ) 
                }
            </>
        )
    }

    let handleary = []

    const handChange = (e,data) =>{

         handleary=handleary.concat(data.OrderId+'-'+data.CartDetailId)

        console.log(handleary,'sklwsklncklqws')
        if(e.target.checked === true){
           

            if(handleary.includes(data.OrderId+'-'+data.CartDetailId)){
                console.log('sdfkjsrjkbrjb')
            }else{
                console.log('kklsvknkln')
                handleary = handleary.concat(data.OrderId+'-'+data.CartDetailId)
            }
         }else{
             let arr = handleary.filter(function(item) {
                 return item !== data.OrderId+'-'+data.CartDetailId
             })
             handleary = arr
         }
         console.log(handleary);
    }

    const handsusaaa = async () =>{
        

        let ress = await axios({
            method  : 'post' , 
            url : `${Base_Url}/selectProductToHandover`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            },
            data : {
                orderId_cartDetailId : handleary.join()
            }
        })
        .then((res)=>{
            if(res.data.status === false ){
                errnotify('Atleast Select One Field')
            }else{
                navigate('/handover_all/'+res.data.Data.orderId)
            }
            console.log(res)
        })
       
    }

    const ListOrders = () =>{
        console.log(clickhand?.products , 'clickhand?.products')
        return(
            <>
                <div className='d-flex justify-content-between mt-4'>
                    <div>
                        <p className='switch_order_one'>List by orders</p>
                    </div>
                    <div>
                        <label className="switch">
                            <input  onChange={e => handleChanges(e)} type="checkbox" checked={(checkeds)}/>
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                {
                    clickhand?.products?.length === 0 || clickhand === undefined ?  <p style={{ textAlign : 'center' , fontSize : '15px' , fontWeight : '600', marginTop:'40px'}}>No Orders</p>  :
                    clickhand?.products.map((data,key)=>
                        <div className='check_change mt-4'>
                            <div className='row'>
                                <div className='col-1'>
                                    {/* <input onClick={(e)=>handChange(e,data)} className='ch_three mt-4' type='checkbox' /> */}
                                    {
                                        data.CartDetailProIsHandovered === 0 ? 
                                            <input type='checkbox' onClick={(e)=>handChange(e,data)} className='ch_three mt-4' />
                                        : ''
                                    }
                                   
                                </div>
                                <div className='col-7'>
                                    <Link to={'/mobile_detail/'+data.CartDetailProId} >
                                        <p className='ord_det_two '>{data.OrderAutoGenId}</p>
                                        <p className='ord_det_threes_ordd peeerrrr'>No : {data.CartDetailInventoryProSerialNo}</p>
                                        <p className='check_change_ones peeerrrr'>{data.ProName.substring(0,20)}...</p>
                                    </Link>
                                </div>
                                <div className='col-4'>
                                    <Link to={'/mobile_detail/'+data.CartDetailProId} >
                                        <img className='check_change_twosssssss' src={ImageUrl+'/'+data.ProImgPrimary} />
                                    </Link>
                                    {
                                        data.CartDetailProIsHandovered === 0 ? 
                                            <div style={{ width : 85 , height : 25 , backgroundColor  : 'rgba(244, 182, 68, 0.25)' , borderRadius : 5 , padding : '1px 5px' }} >
                                                <p style={{ fontSize : 12 , color : '#F4B644'  , textAlign : 'center' }}>Handover</p>
                                            </div>
                                        : data.CartDetailProIsHandovered === 1 ? 
                                            <div style={{ width : 85 , height : 25 , backgroundColor  : '#F4B644' , borderRadius : 5 , padding : '1px 5px' }} >
                                                <p style={{ fontSize : 12 , color : '#000' , textAlign : 'center' }}>Requested</p>
                                            </div>
                                        :
                                            <div style={{ width : 85 , height : 25 , backgroundColor  : '#008000' , borderRadius : 5 , padding : '1px 5px' }} >
                                                <p style={{ fontSize : 12 , color : '#fff' , textAlign : 'center' }}>Handovered</p>
                                            </div>
                                    }
                                    
                                    
                                </div>
                            </div>
                        </div>
                    )
                }
                
                {/* <Link to='/qr'>
                    <img className='re_cart_fifteen' src={scan} />
                </Link> */}
                <div className='mt-4'>
                    {/* <Link to='/handover_detail'> */}
                    {
                        clickhand?.products .length !== 0 ? <div onClick={()=>handsusaaa()} className='re_cart_thirteen'>
                        <p className='re_cart_fourteen'>Handover</p>
                    </div> : ''
                    }
                        
                    {/* </Link> */}
                </div>
                <ToastContainer />
            </>
        )
    }

    const pending_cancel = async(datas) =>{
        console.log(datas,'cancellllll')
        setCanceldata(true)
        let responce = await axios({
            method  : 'post' , 
            url : `${Base_Url}/cancelPendingOrder`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            },
            data : {
                orderId : datas.orderId
            }
        })
        .then((res)=>{
            if(res.data.response_code === 1){
                first()
                closeModal()
                successnotify('Order Cancel Success')
                setCanceldata(false)
            }
            console.log(res)
        })
        .catch((err)=>{
            closeModal()
            setCanceldata(false)
            errnotify('Order Cancel Failed')
        })
    }


    const paymentCheck  = (data) =>{
        if(data.status === 1){
            navigate('/kyc_one')
            return
        }
        if(data.status === 2){
            navigate('/kyc_two')
            return
        }
        if(data.status === 3){
            navigate('/kyc_three')
        }
        if(data.status === 4){
            navigate('/kyc_four')
        }
        if(data.status === 5){
            navigate('/kyc_five')
        }
        if(data.status === 6){
            navigate('/payment/0/'+data.orderIdToSend)
        }
        if(data.status === 7){
            navigate('/track/'+data.orderId)
        }
        if(data.status === 8){
            navigate('/cart_one/'+data.orderIdToSend)
        }
    }


  return (
    <>
     {(()=>{
        if(props.text.cat === 'pending'){
            console.log(pendings,'penddddd')
            return(
                pendings?.length === 0 || pendings === undefined  ?  <p style={{ textAlign : 'center' , fontSize : '15px' , fontWeight : '600', marginTop:'40px'}}>No Pending Orders</p>  : pendings?.orders.map((datas,key)=>{
                        return(
                        <div className='ord_det_one mt-4'>
                            <div className='row'>
                                <div className='col-8'>
                                    <p className='ord_det_two'>Order Id : {datas.OrderAutoGenId}</p>
                                    <p className='ord_det_threes_ordd'>{datas.fromdate}   -   {datas.todate}</p>
                                </div>
                                <div className='col-4'>
                                    <p className='ord_det_four'>₹{datas.orderamt}</p>
                                    <p className='ord_det_fives_orddd'>{datas.Type}</p>
                                </div>
                            </div>
                            <div className='row mt-1'>
                                {datas.carts.map((dat,key)=>
                                
                                {
                                    return(
                                        
                                    key >=4 ?
                                    <></>
                                    : <div className='col-3 ' >
                                    <img style={{width:'100%'}} className='ord_det_seven_orrrrdd' src={ImageUrl+'/'+dat.ProImgPrimary} />
                                    <p className='ord_det_eight'>{dat.ProName.substring(0, 8)}...</p>
                                </div>
                                )
                                }
                                    
                                )}
                               {
                                datas .remainingCount === 0 ? '' :
                                    <div className='col-3 ' >
                                        <img className='ord_det_seven_orrrrdd' src={one123} />
                                    
                                        <p className='more_items'>{datas.remainingCount}<br/>Items</p>
                                        <p className='ord_det_eights '>View more</p>
                                    </div>   
                                }
                            </div>
                            <div className='row mt-4'>
                                <div className='col-6'>
                                    <div 
                                        //  onOpen()
                                        //  setCanceldata(datas)
                                        onClick={openModal}
                                        // pending_cancel(datas)
                                     className='ord_det_ten'>
                                        <p className='ord_det_eleven'>Cancel order</p>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    {/* <Link to={'/track/'+datas.orderId}> */}
                                        <div style={{cursor:'pointer'}} onClick={()=>paymentCheck(datas)} className='ord_det_nine'>
                                            <p className='ord_det_twelve'>View order</p>
                                        </div>
                                    {/* </Link> */}
                                </div>
                            </div>
                            <ToastContainer />
 
                            <Modal
                                    isOpen={modalIsOpen}
                                    onAfterOpen={afterOpenModal}
                                    onRequestClose={closeModal}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                >
                                    <div style={{ width : '100%' }} >
                                        <img style={{ display : 'block' , width : 150 , height : 150 , marginLeft : 'auto' , marginTop : 10 , marginBottom : 10 , marginRight : 'auto' }} src={cccn} />
                                        <p style={{ fontSize :  15 , fontWeight :  '600' , textAlign : 'center' }}>Cancel Order</p>
                                        <div className='mt-4' style={{ width : '100%' }} >
                                            <div className='row' >
                                                <div className='col-6'>
                                                    <div style={{ width:'100%' , backgroundColor:'#161E40' , color : '#fff' , padding : 7 , borderRadius :  6  , textAlign : 'center' }}
                                                     onClick={()=>{closeModal()}}>
                                                        Close
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    {
                                                        canceldata === false ?
                                                        <div  style={{ width:'100%' , backgroundColor:'#E53E3E' , color : '#fff' , padding : 7 , borderRadius :  6  , textAlign : 'center' }}  onClick={()=>{  
                                                            pending_cancel(datas)
                                                        }}>
                                                            Confirm
                                                        </div>
                                                        :
                                                        <div  style={{ width:'100%' , backgroundColor:'#E53E3E' , color : '#fff' , padding : 7 , borderRadius :  6  , textAlign : 'center' }} >
                                                           <ReactLoading className='adcfsfqaw' style={{ fill : '#fff' , height :25 , width : 65}} type='balls' />
                                                        </div>
                                                        
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </Modal>
                              
                            </div>
                        )
                        
                    })
            )
            
            }else if(props.text.cat==="Handover"){
                return(
                        props.text.detail.map((data,key)=>
                        vals===0 ? <Handover data={data} /> :<ListOrders />))
            }else if(props.text.cat==="Running"){
                return(
                    props.text.detail.map((data,key)=>
                    val===0 ? <Orderlist data={data} /> : <ListOrder />))
                }
            else if(props.text.cat==="Service"){
                return(
                    props.text.detail.map((data,key)=>
                    valss===0 ? <Service data={data} /> : <Service_list />)
                    )
            }
    })()}


    
           
    </>
  )
}