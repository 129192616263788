import React , { useState , useEffect } from 'react'
import { Link , useLocation , useNavigate } from 'react-router-dom'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ChakraProvider,
  Text,
  Button,
  useDisclosure
} from '@chakra-ui/react'
import ReactStars from "react-rating-stars-component";
import '../../components/fullwidth/home_kyc/home_kyc.css'
import axios from 'axios'
import { Base_Url } from '../../components/globalvariable/globe'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch , useSelector } from 'react-redux'
import { LoogIn } from '../../redux/actions/productAction'
import hppy from '../../assets/img/hppy.png'
import smmill from '../../assets/img/smmill.png'
import saad from '../../assets/img/saad.png'

import color_sad from '../../assets/img/color_sad.png'
import color_happy from '../../assets/img/color_happy.png'
import color_ok from '../../assets/img/color_ok.png'
import kyctk from '../../assets/img/kyctk.png'
import { Wishcount } from '../../redux/actions/productAction';

export default function Profile_home_nav() {

  let locatinon = useLocation()

  let navigate = useNavigate()

  const [ log ,  setLog ] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [ rating , setRating ] = useState(0)
  const [ kyccc , setKyccc ] = useState()

  const [ feeddata , setFeeeddata ] = useState()

  const [ sts , setSta ] = useState(false)
  const product =  useSelector((state)=>state)
  const [ wishcountss , setWishcount ] = useState(0)


  let token = localStorage.getItem('user_data')

  let dispatch = useDispatch()

  const successnotify = (sus) => toast.success( 'feedback submited' , {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });


  const logout = () =>{
    setLog(true)
    onOpen()
}

const logoutSubmit = () =>{
    localStorage.clear();
    navigate('/')
}

const Feedback =async () =>{
  setSta(false)
  if(feeddata=== ''){
    return
  }
  let responce = await axios({
    method : 'post',
    url : `${Base_Url}/feedback`,
    headers: { 
      'Authorization': `Bearer ${token}` 
    },
    data : {
        rating : rating,
        description :feeddata 
    }
  })
  .then((res)=>{
    onClose()
    successnotify()
    setSta(true)
    console.log(res)
  })
  .catch((err)=>{
    setSta(false)
    if(err.response.status === 401){
      dispatch(LoogIn({show : true}))
      // navigate('/')
    }
    console.log(err)
  })
}

const feedClick = () =>{
  onOpen()
  setFeeeddata('')
}

useEffect(()=>{

  setTimeout(() => {
    let kkk = localStorage.getItem('kyctype')
    console.log( 'kyctype' , kkk )
  
    setKyccc(kkk)
  }, 1000);

},[])

useEffect(()=>{

  let widata = localStorage.getItem('wish_list')

  dispatch(Wishcount(JSON.parse(widata)))

  if ( widata === null ){ 
      setWishcount(0)
      return 
  }else if(widata === '[]'){
      setWishcount(0)
  }
  let hiis = JSON.parse(widata)

  setWishcount(hiis.length)  

},[])

useEffect(()=>{
  if( product.wishcount.products === null || product.wishcount.products === undefined ){
    setWishcount(0)
  }else{
      setWishcount(product.wishcount.products.length)
  }

},[product])

  return (
    <>
        <div className='col-4 mb-4'>
              <div className='pro_home_five mt-3'>
                <Link to='/order'>
                    <div className='d-flex justify-content-start mt-2'>
                        <img className='pro_home_sixteen' style={{marginLeft:'15px'}} src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/orders.png' />
                        <p className={(()=>{

                          if(locatinon.pathname === '/order'){
                            return 'pro_home_six header_nav_tit_color_pro'
                          }else if(locatinon.pathname === '/track'){
                            return 'pro_home_six header_nav_tit_color_pro'
                          }else if(locatinon.pathname === '/checklist'){
                            return 'pro_home_six header_nav_tit_color_pro'
                          }else{
                            return 'pro_home_six'
                          }

                        })()} >Orders</p>
                    </div>
                </Link>
                <Link to='/history'>
                  <div className='d-flex justify-content-start mt-4'>
                    <img className='pro_home_sixteen' style={{marginLeft:'15px'}} src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/history.png' />
                    <p className={(locatinon.pathname === '/history' ? 'pro_home_six header_nav_tit_color_pro' : 'pro_home_six')}>History</p>
                  </div>
                </Link>
                <Link to='/payment_report'>
                  <div className='d-flex justify-content-start mt-4'>
                    <img className='pro_home_sixteen' style={{marginLeft:'15px'}} src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/paymentreport.png' />
                    <p  className={(locatinon.pathname === '/payment_report' ? 'pro_home_six header_nav_tit_color_pro' : 'pro_home_six')}>Payment Report</p>
                  </div>
                </Link>
                <Link to='/kyc_one'>
                  <div className='d-flex justify-content-between mt-4'>

                      <div className='d-flex' >
                        <img className='pro_home_sixteen' style={{marginLeft:'15px'}} src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/kyc.png' />
                        <p className={(()=>{

                          if(locatinon.pathname === '/kyc_one'){
                            return 'pro_home_six header_nav_tit_color_pro'
                          }else if(locatinon.pathname === '/kyc_two'){
                            return 'pro_home_six header_nav_tit_color_pro'
                          }else if(locatinon.pathname === '/kyc_three'){
                            return 'pro_home_six header_nav_tit_color_pro'
                          }else if(locatinon.pathname === '/kyc_four'){
                            return 'pro_home_six header_nav_tit_color_pro'
                          }else if(locatinon.pathname === '/kyc_five'){
                            return 'pro_home_six header_nav_tit_color_pro'
                          }else{
                            return 'pro_home_six'
                          }
                          })()}

                          >KYC</p>
                      </div>
                          {
                            kyccc === '1' ?
                              <div className='d-flex' >
                                <img className='profile_img_ten' style={{width :21 , height :21}}  src={kyctk} />
                                <p style={{ marginLeft : 5 }} className='profile_img_nine'>Verified</p>
                              </div>
                            : ''
                          }
                     
                  </div>
                </Link>
                <Link to='/bank_detail'>
                  <div className='d-flex justify-content-start mt-4'>
                    <img className='pro_home_sixteen' style={{marginLeft:'15px'}} src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/managebank.png' />
                    <p  className={(locatinon.pathname === '/bank_detail' ? 'pro_home_six header_nav_tit_color_pro' : 'pro_home_six')}>Manage Bank</p>
                  </div>
                </Link>
                <Link to='/wish'>
                  <div className='d-flex justify-content-start mt-4'>
                    <img className='pro_home_sixteen' style={{marginLeft:'15px'}} src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/wishlistone.png' />
                    <p className={(locatinon.pathname === '/wish' ? 'pro_home_six header_nav_tit_color_pro' : 'pro_home_six')}>Wishlist</p>
                    <span class='badge badge-warning' id='lblCartCountsssdd'>{wishcountss}</span>
                  </div>
                </Link>
                <Link to='/notification'>
                  <div className='d-flex justify-content-start mt-4'>
                    <img className='pro_home_sixteen' style={{marginLeft:'15px'}} src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/notification.png' />
                    <p className={(()=>{
                      if(locatinon.pathname === '/notification'){
                        return 'pro_home_six header_nav_tit_color_pro'
                      }else{
                        return 'pro_home_six'
                      }
                      })()}>Notification</p>
                  </div>
                </Link>
                <div onClick={()=>feedClick()} className='d-flex justify-content-start mt-4'>
                  <img className='pro_home_sixteen' style={{marginLeft:'15px'}} src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/feedback.png' />
                  <p className='pro_home_six'>Feedback</p>
                </div>
                <Link to='/support'>
                  <div className='d-flex justify-content-start mt-4'>
                    <img className='pro_home_sixteen' style={{marginLeft:'15px'}} src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/support.png' />
                    <p className={(()=>{
                      if(locatinon.pathname === '/support'){
                        return 'pro_home_six header_nav_tit_color_pro'
                      }else{
                        return 'pro_home_six'
                      }
                      })()}>Support</p>
                  </div>
                </Link>
                <Link to='/terms'>
                  <div className='d-flex justify-content-start mt-4'>
                    <img className='pro_home_sixteen' style={{marginLeft:'15px'}} src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/tandpolicy.png' />
                    <p className={(()=>{
                        if(locatinon.pathname === '/terms'){
                          return 'pro_home_six header_nav_tit_color_pro'
                        }else{
                          return 'pro_home_six'
                        }
                        })()}
                        >T&C</p>
                  </div>
                </Link>

                <Link to='/blog'>
                  <div className='d-flex justify-content-start mt-4'>
                    <img className='pro_home_sixteen' style={{marginLeft:'15px'}} src='blogg.png' />
                    <p className={(()=>{
                      if(locatinon.pathname === '/contact_us'){
                        return 'pro_home_six header_nav_tit_color_pro'
                      }else{
                        return 'pro_home_six'
                      }
                      })()}>Blog</p>
                  </div>
                </Link>


                <Link to='/contact_us'>
                  <div className='d-flex justify-content-start mt-4'>
                    <img className='pro_home_sixteen' style={{marginLeft:'15px'}} src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/support.png' />
                    <p className={(()=>{
                      if(locatinon.pathname === '/contact_us'){
                        return 'pro_home_six header_nav_tit_color_pro'
                      }else{
                        return 'pro_home_six'
                      }
                      })()}>Contact Us</p>
                  </div>
                </Link>
                <Link to='/about_us'>
                  <div className='d-flex justify-content-start mt-4'>
                    <img className='pro_home_sixteen' style={{marginLeft:'15px'}} src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/kyc.png' />
                    <p className={(()=>{
                      if(locatinon.pathname === '/about_us'){
                        return 'pro_home_six header_nav_tit_color_pro'
                      }else{
                        return 'pro_home_six'
                      }
                      })()}>About Us</p>
                  </div>
                </Link>
                <Link to='/damage_policy'> 
                <div className='d-flex justify-content-start mt-4'>
                  <img className='pro_home_sixteen' style={{marginLeft:'15px'}} src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/tandpolicy.png' />
                  <p className={(()=>{
                      if(locatinon.pathname === '/damage_policy'){
                        return 'pro_home_six header_nav_tit_color_pro'
                      }else{
                        return 'pro_home_six'
                      }
                      })()}
                      >Damage policy</p>
                </div>
                </Link>
                {
                  token === null ? "" : 
                  <div onClick={()=>logout()} className='d-flex justify-content-start mt-4 mb-2'>
                  <img className='pro_home_sixteen' style={{marginLeft:'15px'}} src='https://rentla-image-bucket.s3.ap-south-1.amazonaws.com/iconfiles/logout.png' />
                  <p className='pro_home_six'>Logout</p>
                </div>
                }
                
              </div>
        <ToastContainer />
            </div>
            
            <ChakraProvider>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        {
                            log === true ? <>
                            <ModalHeader>
                                Are you sure you want to logout?
                            </ModalHeader>
                            </> :
                            <ModalHeader>Did You Enjoy Our Service
                            
                            </ModalHeader>
                        }
                       
                        <ModalCloseButton />
                        {
                             log === true ? <>
                                    <ModalFooter>
                                        <Button onClick={()=>logoutSubmit()} style={{backgroundColor:'#161E40' , color:'white'}}>Logout</Button>
                                    </ModalFooter>
                                </> :
                             <>
                                <ModalBody>
                                {/* <ReactStars
                                count={5}
                                isHalf={true}
                                emptyIcon={<i className="far fa-star"></i>}
                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                fullIcon={<i className="fa fa-star"></i>}
                                onChange={ratingChanged}
                                size={24}
                                activeColor="#ffd700"
                            /> */}
                                <div style={{ marginTop : -15 }} className='d-flex justify-content-start' >
                                  <div style={{ marginRight : 30 }} >
                                    {
                                      rating === 1 ? <img onClick={()=>{setRating(1)}} className='pro_home_three' src={color_sad} /> :  <img onClick={()=>{setRating(1)}} className='pro_home_three' src={saad} />
                                    }
                                   
                                    <p style={{ color : '#000' , fontSize :  14 , fontWeight :  '600' , textAlign : 'center' , marginTop : 5 , marginBottom : 10 }} >Bad</p>
                                  </div>
                                  <div style={{ marginRight : 30 }} >
                                    {
                                      rating === 2 ? <img onClick={()=>{setRating(2)}} className='pro_home_three' src={color_happy} /> : <img onClick={()=>{setRating(2)}} className='pro_home_three' src={smmill} />
                                    }
                                    <p style={{ color : '#000' , fontSize :  14 , fontWeight :  '600' , textAlign : 'center' , marginTop : 5 , marginBottom : 10 }} >Fair</p>
                                  </div>
                                  <div >
                                    {
                                      rating === 3 ? <img onClick={()=>{setRating(3)}} className='pro_home_three' src={color_ok} /> : <img onClick={()=>{setRating(3)}} className='pro_home_three' src={hppy} />
                                    }
                                    
                                    <p style={{ color : '#000' , fontSize :  14 , fontWeight :  '600' , textAlign : 'center' , marginTop : 5 , marginBottom : 10 }} >Good</p>
                                  </div>
                                </div>
                                  

                                    <Text fontWeight='bold' mb='1rem'>
                                    Tell us what can be improved !
                                    </Text>
                                    <textarea  style={{marginBottom : 10}} onChange={(e)=>setFeeeddata(e.target.value)} value={feeddata} class="form-control afserwerqwed" id="inputID"  rows="3"></textarea>
                                </ModalBody>
                                  <ModalFooter>
                                    <Button onClick={()=>Feedback()} style={{backgroundColor:'#161E40' , color:'white' , width :  '100%'}}>Submit Feedback</Button>
                                </ModalFooter>

                                
                            </>
                        }
                            
                    </ModalContent>
                </Modal>
            </ChakraProvider>
            
    </>
  )
}
