import React , { useState ,useRef , useMemo , useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import clkk from '../../../assets/img/clkk.png'
import eye_one from '../../../assets/img/eye_one.png'
import eye_close from '../../../assets/img/eye_close.png'
import file from '../../../assets/img/file.png'
import delect from '../../../assets/img/delect.png'
import doc from '../../../assets/img/doc.png'
import x from '../../../assets/img/x.png'
import mark from '../../../assets/img/mark.png'
import map from '../../../assets/img/map.png'
import L from 'leaflet';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter, 
    ModalBody,
    useDisclosure,
    Button,
    ChakraProvider
  } from '@chakra-ui/react'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Base_Url } from '../../globalvariable/globe';
import Cart_one_head from '../cart_one/cart_one_head'
import 'leaflet/dist/leaflet.css'
import { MapContainer, TileLayer, Popup ,Marker , useMapEvents } from 'react-leaflet'
import { useSelector , useDispatch } from 'react-redux'
import { mapProducts , mapProductszomm } from '../../../redux/actions/productAction'
import { useNavigate , useParams } from 'react-router-dom'



function LocationMarker() {
    const [positions, setPositions] = useState([13.0827, 80.2707])
    const [ current , setCurrentPos ] = useState('13.0827')
    const [ lan , setLan ] = useState('80.2707')
    const markerRef = useRef(null)

    let dispatch = useDispatch()

    const product = useSelector((state)=>state.mapzoom)

    useEffect(()=>{
        setPositions(product.products)
    },[product])

    const eventHandlers = useMemo(
        () => ({
          dragend() {
            const marker = markerRef.current
            if (marker != null) {
                setCurrentPos(Math.round(marker.getLatLng().lat * 100) / 100)
                setLan(Math.round(marker.getLatLng().lng * 100) / 100)
                dispatch(mapProducts(marker.getLatLng()))
                // llan(marker.getLatLng().lat)
                // llon(marker.getLatLng().lng)
                setPositions(marker.getLatLng())
            }
          },
        }),
        [],
      )

    const map = useMapEvents({
      click() {
        map.locate()
      },
      locationfound(e) {
        setPositions(e.latlng)
        map.flyTo(e.latlng, map.getZoom())
        setCurrentPos(Math.round(e.latlng.lat * 100) / 100)
        setLan(Math.round(e.latlng.lng * 100) / 100)
        dispatch(mapProducts(e.latlng))
        // llan(e.latlng.lat)
        // llon(e.latlng.lng)
      },
    })
  
    return positions === null ? null : (
      <Marker
        eventHandlers={eventHandlers}
        position={positions}
        ref={markerRef}
        draggable={true}
       >
        <Popup>You are here. {current} , {lan}</Popup>
      </Marker>
    )
  }

export default function Edit_Company_contact() {
    

  let change = /^\d{10}$/;
  let emailveri = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const { id , details } = useParams()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [ compname , setCompname ] = useState('')
  const [ gts , setGts ] = useState('')
  const [ comppername , setComppername ] = useState('')
  const [ phoneno , setPhoneno ] = useState('')
  const [ email , setEmail ] = useState('')
  const [ pass , setPass ]  = useState('')
  const [ check , setChecked ] = useState(false)

  const [ radio , setRadio ] = useState('1')

  const [ img , setImg ] = useState()
  const [ data , setData ] = useState('')

  const [ showimg , setShowimg ] = useState(false)

  const [ vericompname , setVeriCompname ] = useState(false)
  const [ verigts , setVeriGts ] = useState(false)
  const [ vericomppername , setVeriComppername ] = useState(false)
  const [ close , setClose ] = useState(false)
  const [ veriemail , setVeriEmail ] = useState(false)

  

  const product = useSelector((state)=>state.map)

  let navigate = useNavigate()

  const [ address  , setAddress ] = useState('')
  const [ ln , llan ] = useState(13.0827)
  const [ lo , llon ] = useState(80.2707)
  const [ contactperson , setContactperson ] = useState('')
  const [ address2 , setAddress2 ] = useState('')
  const [ appartment , setAppartment ] = useState('')
  const [ city , setCity ] = useState('')
  const [ state , setState ] = useState('')
  const [ post , setPost ] = useState('')
  const [ addressCheck , setAddresscheck ] = useState(false)
  const [ position ,setPosition ] = useState()

  let dispatch = useDispatch()

  let token = localStorage.getItem('user_data')

  useEffect(()=>{

    if (details === 'contact' ){
        setRadio('1')
    }else{
        setRadio('2')
    }

    start()

  },[])

    let start = async () =>{
        if(token === null){
            navigate('/')
            return
          }
        if( details === 'contact' ){
            var formData = new FormData();
            formData.append("contactId", id );
    
            let responce = await axios.post(`${Base_Url}/editCorporateContact`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('user_data')}` 
                } 
            })
            .then((res)=>{
                console.log(res.data)
                if(res.data.status === true ){
                    setCompname(res.data.Data.address.UserContactName)
                    setGts(res.data.Data.address.UserContactJobPosition)
                    setEmail(res.data.Data.address.UserContactEmail)
                    setPass(res.data.Data.address.UserContactDepartment)
                    setPhoneno(res.data.Data.address.UserContactMobile)
                    let one = res.data.Data.address.UserContactIsPrimary === 0 ?  false  : true
                    setChecked(one)
                }else{
                errnotify(res.data.message)
                return
                }
            })
            .catch((err)=>{
                console.log(err)
                errnotify('Network Error')
                return
            })
        }else{
            var formData = new FormData();
            formData.append("addressId", id );
    
            let responce = await axios.post(`${Base_Url}/editCorporateAddress`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('user_data')}` 
                } 
            })
            .then((res)=>{
                console.log(res.data)
                if(res.data.status === true ){
                    setAddress(res.data.Data.address.UserContactAddressName)
                    llan(res.data.Data.address.UserContactLatitude)
                    llon(res.data.Data.address.UserContactLongitude)
                    setContactperson(res.data.Data.address.UserContactName)
                    setAppartment(res.data.Data.address.UserContactAppartment)
                    setCity(res.data.Data.address.UserContactCity)
                    setState(res.data.Data.address.UserContactState)
                    setPost(res.data.Data.address.UserContactPincode)
                    setAddress2(res.data.Data.address.UserContactAddress)
                    setPosition([parseFloat(res.data.Data.address.UserContactLatitude),parseFloat(res.data.Data.address.UserContactLongitude)])
                    dispatch(mapProductszomm([parseFloat(res.data.Data.address.UserContactLatitude),parseFloat(res.data.Data.address.UserContactLongitude)]))
                    let one = res.data.Data.address.UserContactAddressIsPrimary === 0 ?  false  : true
                    setAddresscheck(one)
                }else{
                errnotify(res.data.message)
                return
                }
            })
            .catch((err)=>{
                console.log(err)
                errnotify('Network Error')
                return
            })
        }
       
    }



  useEffect(()=>{
    console.log(product.products.lng)
    llan(product.products.lat)
    llon(product.products.lng)
  },[product])

        let DefaultIcon = L.icon({
            iconUrl: mark,
            iconSize: [28, 46],
            iconAnchor: [17, 46]
        });
        L.Marker.prototype.options.icon = DefaultIcon;




  const errnotify = (dat) => toast.error( dat , {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });

  const onImage = (e) => {
    setShowimg({[e.target.name] : URL.createObjectURL(e.target.files[0])})
  };

  const clicked = () => {   

        if ( radio === '1' ){

            
        if( compname === ''){
            errnotify('Contact Person Name')
            return
        }

        if ( gts === ''){
            errnotify('Enter Designation')
            return
        }

        
        if( email === ''){
            errnotify('Enter Email')
                    return
        }

        let phonevali = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
                if(phonevali.test(email)){

                }else{
                errnotify('Enter Valid Email')
                return
                }

                if(pass === ''){
                errnotify('Enter Department')
                return
                }

                if( phoneno === '' )
                {
                errnotify('Enter Phone Number')
                return
                }
            
                if( phoneno.length < 10 ){
                errnotify('Enter Valid Phone Number')
                return
                }
            

            var formData = new FormData();
            formData.append("personName", compname);
            formData.append("designation", gts);
            formData.append("phone", phoneno);
            formData.append("email", email);
            formData.append("department", pass);
            formData.append("contactId", id);
            formData.append("primary", check === true ? 1 :  0 );

            const sendData = async() =>{
            let responce = await axios.post(`${Base_Url}/editCorporateContactAction`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('user_data')}` 
                } 
            })
            .then((res)=>{
                console.log(res.data)
                if(res.data.status === true ){
                navigate('/corporate_profile')
                }else{
                errnotify(res.data.message)
                return
                }
            })
            .catch((err)=>{
                console.log(err)
                return
                let er = 'Something Went Wrong'
                errnotify(er)
                console.log(err)
            })
            }

            sendData()
            }else{

            if(address === ''){
                errnotify('Enter Address')
                return
            }
            if(contactperson === ''){
                errnotify('Enter Contact Person Name')
                return
            }
            if(address2 === ''){
                errnotify('Enter Address')
                return
            }
            if(appartment === ''){
                errnotify('Enter Appartment')
                return
            }
            if(city === ''){
                errnotify('Enter City')
                return
            }
            if(state === ''){
                errnotify('Enter State')
                return
            }
            if(post === ''){
                errnotify('Enter Postal Code')
                return
            }
            if(post.toString().length === 6){
            }else{
                console.log(post)
                errnotify('Enter Valid Postal Code')
                return
            }
            var formData = new FormData();
            formData.append("appartment", appartment);
            formData.append("city", city);
            formData.append("state", state);
            formData.append("pincode", post);
            formData.append("addressId", id);
            formData.append("addressName", address);
            formData.append("personName", contactperson);
            formData.append("latitude", ln === undefined ? '' : ln);
            formData.append("longitude", lo === undefined ? '' : lo );
            formData.append("primary", addressCheck === true ? 1 : 0 );
            formData.append("doorno", address2 );

            const sendDatas = async() =>{
            let responce = await axios.post(`${Base_Url}/editCorporateAddressAction`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('user_data')}` 
                } 
            })
            .then((res)=>{
                if(res.data.status === true ){
                console.log(res.data)
                navigate('/corporate_profile')
                }else{
                errnotify(res.data.message)
                return
                }
            })
            .catch((err)=>{
                console.log(err)
                return
                let er = 'Something Went Wrong'
                errnotify(er)
                console.log(err)
            })


            }

            sendDatas()
            }

  }


  

  return (
    <>
    <div style={{width:'90%' , margin:'auto' ,marginTop:'65px'}}>
    <Cart_one_head link='/corporate_profile' head={ radio === '1' ? 'Edit Contact' : 'Edit Address'} />
      <div className='mt-4'>
         
          <p className='kyc_two_para'>Type</p>
          <div className='d-flex justify-content-start' >
            {
                radio === '1' ? 
                    <div className='d-flex justify-content-start' >
                        <input type="radio"  value="1" name='one' checked={radio === '1' ? true : false } />
                        <p style={{ color:'#000' , fontSize : 14  , padding : 10 }} >Contact</p>
                    </div>
                :
                <div style={{ marginLeft : 10}} className='d-flex justify-content-start' >
                    <input type="radio"  value="2" name='one' checked={radio === '2' ? true : false } />
                    <p style={{ color:'#000' , fontSize : 14  , padding : 10 }} >Address</p>
                </div>
            }
            
            
          </div>
          </div>
          
          {
            radio === '1' ? 
            <>
            <p className='kyc_two_para'>Contact Person Name</p>
            <input type="text" onChange={(e)=>{
                let letters = /^[1-9][0-9]*$/;
                let last = ''
                if(e.target.value.length >=2) {
                    last = e.target.value.split('').pop()
                }else{
                    last = e.target.value
                }
                if(last.match(letters)){

                }else{
                    setCompname(e.target.value)
                }
            }} value={compname} className={(vericompname ? 'form-control kyc_in_one inputttt_red' : 'form-control kyc_in_one')} id="exampleInputEmail1" aria-describedby="emailHelp"  />
          
                <div className='mt-3'>
                    <p className='kyc_two_para'>Designation</p>
                    <input type="text" onChange={(e)=> setGts(e.target.value) } value={gts} className={(verigts ? 'form-control kyc_in_one inputttt_red' : 'form-control kyc_in_one')} id="exampleInputEmail1" aria-describedby="emailHelp"  />
                </div>
                {/* <div className='mt-3'>
                    <p className='kyc_two_para'>Contact Person Name</p>
                    <input type="text" onChange={(e)=>{
                        let letters = /^[1-9][0-9]*$/;
                        let last = ''
                        if(e.target.value.length >=2) {
                            last = e.target.value.split('').pop()
                        }else{
                            last = e.target.value
                        }
                        if(last.match(letters)){

                        }else{
                            setComppername(e.target.value)
                        }
                    }} value={comppername} className={(vericomppername ? 'form-control kyc_in_one inputttt_red' : 'form-control kyc_in_one')}id="exampleInputEmail1" aria-describedby="emailHelp"  />
                </div> */}
                <div className='mt-3'>
                    <p className='kyc_two_para'>Email (Official)</p>
                    <input type="email" onChange={(e)=>setEmail(e.target.value)} value={email} className={( veriemail ? 'form-control kyc_in_one inputttt_red' : 'form-control kyc_in_one' )} id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>
                <p className='login_two_img400'>Department</p>
                            <div className='lo_tw_one'>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <input style={{width : '100%'}} onChange={(e)=>setPass(e.target.value)} value={pass}  type='text' class="form-control lo_tw_three" id="exampleInputEmail1" aria-describedby="emailHelp"
                                        // placeholder="99945 99945"
                                        />
                                    </div>
                                </div>
                            </div>
                            

                <p className='kyc_two_para mt-3'>Phone Number</p>
                            <div className='lo_tw_one'>
                                <div className='d-flex'>
                                    <div >
                                        <p style={{ fontSize : 15 }} className='lo_tw_two'>+91</p>
                                    </div>
                                    <div>
                                        <input style={{ marginLeft : -10 , paddingLeft : 0 }} onChange={(e)=>setPhoneno(e.target.value.replace(/[^0-9]/g, "").slice(0,10))}  value={phoneno}  class="form-control lo_tw_three" id="exampleInputEmail1" aria-describedby="emailHelp"
                                        // placeholder="99945 99945"
                                        />
                                    </div>
                                </div>
                            </div>
                

            
                <div style={{ marginTop : 20 }} >
                    <div className='d-flex justify-content-start' >
                        <input style={{ width : 20 , height : 20   }} onChange={(e)=>{setChecked(e.target.checked)}} checked={ check } type="checkbox" />
                        <p style={{ color : '#000' , fontSize : 14 , fontWeight : '500' , marginLeft : 10 }}>Set As a Primary Contact</p>
                    </div>
                </div>
            <div  onClick={()=>clicked()} className='lo_tw_five5'>
                <div className='d-flex justify-content-center'>
                    <p className='mob_log_p_four'>Save Changes</p>
                </div>
            </div>

      </>

      : 
      <>

      <p className='kyc_two_para'>Address Name</p>
      <input type="text" onChange={(e)=>{
          let letters = /^[1-9][0-9]*$/;
          let last = ''
          if(e.target.value.length >=2) {
              last = e.target.value.split('').pop()
          }else{
              last = e.target.value
          }
          if(last.match(letters)){

          }else{
            setAddress(e.target.value)
          }
      }} value={address} className={(vericompname ? 'form-control kyc_in_one inputttt_red' : 'form-control kyc_in_one')} id="exampleInputEmail1" aria-describedby="emailHelp"  />
    

                <p className='login_two_img400'>Map</p>
                    <div className='lo_tw_one'>
                        <div onClick={()=>{ onOpen()}} className='d-flex justify-content-between'>
                            <div>
                                <input style={{width : '100%'}} value={ ln === undefined || lo === undefined ? '' : Math.round(ln * 100) / 100 +' , '+ Math.round(lo * 100) / 100 } disabled="true"    class="form-control lo_tw_three" id="exampleInputEmail1" aria-describedby="emailHelp"
                                // placeholder="99945 99945"
                                />
                            </div>
                            <div  style={{ padding : 13 }} >
                                
                                <img onClick={()=>{  }} style={{ width : 20 , height : 20 }}  src={map} />
                                
                            </div>
                        </div>
                    </div>

          <div className='mt-3'>
              <p className='kyc_two_para'>Contact Person Name</p>
              <input type="text" onChange={(e)=> setContactperson(e.target.value) } value={contactperson} className={(verigts ? 'form-control kyc_in_one inputttt_red' : 'form-control kyc_in_one')} id="exampleInputEmail1" aria-describedby="emailHelp"  />
          </div>
          {/* <div className='mt-3'>
              <p className='kyc_two_para'>Contact Person Name</p>
              <input type="text" onChange={(e)=>{
                  let letters = /^[1-9][0-9]*$/;
                  let last = ''
                  if(e.target.value.length >=2) {
                      last = e.target.value.split('').pop()
                  }else{
                      last = e.target.value
                  }
                  if(last.match(letters)){

                  }else{
                      setComppername(e.target.value)
                  }
              }} value={comppername} className={(vericomppername ? 'form-control kyc_in_one inputttt_red' : 'form-control kyc_in_one')}id="exampleInputEmail1" aria-describedby="emailHelp"  />
          </div> */}
          <div className='mt-3'>
              <p className='kyc_two_para'>Address</p>
              <input type="email" onChange={(e)=>setAddress2(e.target.value)} value={address2} className='form-control kyc_in_one' id="exampleInputEmail1" aria-describedby="emailHelp" />
          </div>

          <div className='mt-3'>
              <p className='kyc_two_para'>Appartment, Suite, Villa, Etc...</p>
              <input type="email" onChange={(e)=>setAppartment(e.target.value)} value={appartment} className='form-control kyc_in_one' id="exampleInputEmail1" aria-describedby="emailHelp" />
          </div>


          <div className='mt-3'>
              <p className='kyc_two_para'>City</p>
              <input type="email" onChange={(e)=>setCity(e.target.value)} value={city} className='form-control kyc_in_one' id="exampleInputEmail1" aria-describedby="emailHelp" />
          </div>


          <div className='mt-3'>
              <p className='kyc_two_para'>State</p>
              <input type="email" onChange={(e)=>setState(e.target.value)} value={state} className='form-control kyc_in_one' id="exampleInputEmail1" aria-describedby="emailHelp" />
          </div>


          <div className='mt-3'>
              <p className='kyc_two_para'>Postal Code</p>
              <input type="number" onChange={(e)=>{
                setPost(e.target.value)
                }} value={post} className='form-control kyc_in_one' id="exampleInputEmail1" aria-describedby="emailHelp" />
          </div>

      
          <div style={{ marginTop : 20 }} >
              <div className='d-flex justify-content-start' >
                  <input style={{ width : 20 , height : 20   }} onChange={(e)=>{setAddresscheck(e.target.checked)}}  checked={addressCheck} type="checkbox" />
                  <p style={{ color : '#000' , fontSize : 14 , fontWeight : '500' , marginLeft : 10 }}>Set As a Primary Address</p>
              </div>
          </div>
      <div  onClick={()=>clicked()} className='lo_tw_five5 mb-4'>
          <div className='d-flex justify-content-center'>
              <p className='mob_log_p_four'>Save Changes</p>
          </div>
      </div>

</>

    }

      <ChakraProvider>

      <Modal isCentered={true} isOpen={isOpen} size={'xl'} onClose={onClose}>

        <ModalOverlay />
          <ModalContent>
              <ModalBody>
              <MapContainer center={position} zoom={13} scrollWheelZoom={false}>
                    <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                      <LocationMarker />
                </MapContainer>
              </ModalBody>
              <ModalFooter >
                <Button bg={'#F4B644'} color={'white'} onClick={()=>{ onClose()}} >
                    Submit
                </Button>
              </ModalFooter>
          </ModalContent>

      </Modal>

      </ChakraProvider>


      <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
                {/* Same as */}
        <ToastContainer />
        </div>
    </>
  )
}