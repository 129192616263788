import React , { useState , useEffect } from 'react'
import { Link ,useNavigate , useParams } from 'react-router-dom'
import Left from '../../assets/img/left.png'
import axios from 'axios'
import { Base_Url, ImageUrl } from '../globalvariable/globe'
import './hand_reqq.css'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    ChakraProvider,
    useDisclosure,
    Text,
    Button,
  } from '@chakra-ui/react'
import { ToastContainer, toast } from 'react-toastify';

import hppy from '../../assets/img/hppy.png'
import smmill from '../../assets/img/smmill.png'
import saad from '../../assets/img/saad.png'
import color_sad from '../../assets/img/color_sad.png'
import color_happy from '../../assets/img/color_happy.png'
import color_ok from '../../assets/img/color_ok.png'

export default function Hand_reqq() {

    const { isOpen, onOpen, onClose } = useDisclosure()

    let navigate  = useNavigate()

    let { id , ary , no } = useParams()

    const [ all , setAll ] = useState()

    const [ ootp , setOotp ]= useState(false)

    let user = localStorage.getItem('user_data')

    const [ addressid, setAddressid ] = useState()
    const [ otp , setOtp ] = useState()

    const [ sw , setSw ] = useState(0)

    const [ handoverId , setHandoverId ] = useState()
    const [rating, setRating] = useState()
    const [feeddata, setFeeeddata] = useState()
    const successnotify = (sus) => toast.success('Feedback Submited Success', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const allData = async() =>{
        if(user === null){
            navigate('/')
            return
          }
        let res = await axios({
            method : 'post',
            url :  `${Base_Url}/listSelectHandover`,
            headers: { 
                'Authorization': `Bearer ${user}` 
            },
            data : {
                orderId : id,
                cartDetailId :ary 
            }
        })
        .then((res)=>{
            setAll(res.data.Data)
            setAddressid(res.data.Data.addresss[0].UserDelivAddrId)
            if( no === '0'){
                setOtp(res.data.Data.otp)
            }
            console.log(res)
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    useEffect(()=>{
        if(no === '0'){
            setOotp(true)
        }
        allData()
        console.log(no,'dafvsl;fv,;l')
    },[])


    const back = ()=>{
        navigate(-1)
    }

    const switch_data = (data,key) =>{
        console.log(data, 'datatatatt')
        setAddressid(data.UserDelivAddrId)
        setSw(key)
    }

    const final = async() =>{
        let req = await axios({
            method : 'post',
            url : `${Base_Url}/handoverOrderGenerateOtp`,
            headers: { 
                'Authorization': `Bearer ${user}` 
            },
            data : {
                orderId : id,
                cartDetailId : ary ,
                addressId : addressid
            }
        })
        .then((res)=>{
            if(res.data.status === true){
                setHandoverId(res.data.handoverId)
                setOtp(res.data.Data)
                setOotp(true)
                onOpen()
            }
            console.log(res)
        })
        .catch((err)=>{
            console.log(err)
        })
    }


    const ratingChanged = (newRating) => {
        setRating(newRating);
    };
    const clicked = () => {
        setFeeeddata('')
        onOpen()
    }

    const Feedback = async () => {
        let responce = await axios({
            method: 'post',
            url: `${Base_Url}/handoverFeedback`,
            headers: {
                'Authorization': `Bearer ${user}`
            },
            data: {
                handoverId: handoverId,
                rating: rating,
                description: feeddata
            }
        })
            .then((res) => {
                onClose()
                successnotify()
                console.log(res)
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    navigate('/login')
                }
                console.log(err)
            })
    }


  return (
    <>
    <div style={{width:'90%' , margin:'auto' , paddingBottom:'100px',marginTop:'65px'}}>
    <div className="second-tops fixed-top navbar-light top-tab-nav">
            <div className="d-flex  m-auto paddd" >
               <div onClick={()=>back()} className='loab'>
                    <img src={Left} />
                </div>
               <div style={{margin: 'auto'}} className='loab'>
                   <p className='ccaaat'>Handover Request</p> 
               </div>
            </div> 
        </div>

        {
            ootp === true  ? 
            <div >
                <div style={{ backgroundColor : '#F4F4F4' , width : '100%' , height : 'auto' , padding : '14px 10px' , borderRadius : 5 , marginBottom : 10 }}>
                    <p style={{ color : 'rgba(69, 69, 69, 0.5)' , fontSize :  12 , fontWeight : '400' , textAlign : 'center' }} >Please Share the 4 digit Verification code to the agent</p>
                    <p style={{ fontSize :  14 , fontWeight : '500' , color : '#000' , marginBottom : 4 , marginTop : 2 , textAlign : 'center' }}>handover OTP</p>
                    <h4 style={{ textAlign:'center' , letterSpacing : '10px' , fontSize : '30px' }} className='mt-4'>{otp?otp : ''}</h4>
                </div>
            </div>
            : ''
        }
        
        <div>
          <p className='renew_one mb-3'>Details</p>
          <div className='row'>
              <div className='col-5'>
                <p className='renew_two'>Order Id</p>
              </div>
              <div className='col-1'>
                    <p className='renew_three'>:</p>
              </div>
              <div className='col-6'>
                    <p className='renew_four'>{all?.details.autoGenId}</p>
              </div>
          </div>
          <div className='row mt-2'>
              <div className='col-5'>
                <p className='renew_two'>Booking Date</p>
              </div>
              <div className='col-1'>
                    <p className='renew_three'>:</p>
              </div>
              <div className='col-6'>
                    <p className='renew_four'>{all?.details.fromDate} - {all?.details.toDate}</p>
              </div>
          </div>
          <div className='row mt-2'>
              <div className='col-5'>
                <p className='renew_two'>Days left</p>
              </div>
              <div className='col-1'>
                    <p className='renew_three'>:</p>
              </div>
              <div className='col-6'>
                    <p className='renew_four'>{all?.details.remainingDays}</p>
              </div>
          </div>
            <p className='renew_five my-2'>items ({all?.productDetails.length})</p>
            {all?.productDetails.map((data,key)=>
            <div className='run_ord_fives mt-3'>
                <div className='row'>
                <div className='col-8'>
                    <p className='run_ord_six_hand'>ID : {data.CartDetailAutoGenId}</p>
                    <p className='run_ord_sevens_hand'>NO : {data.CartDetailInventoryProSerialNo}</p>
                    <p style={{marginTop:'4px'}} className='run_ord_eights'>Name : {data.ProName}</p>
                </div>
                <div className='col-4'>
                    <img style={{width:'100%',marginTop:'10px'}} className='run_ord_nine' src={ImageUrl+'/'+data.ProImgPrimary} />
                </div>
                </div>
            </div>
            )}
        </div>
        <div className='d-flex justify-content-between'>
            <p className='run_ord_sevens_hands'>Pick Up</p>
            {/* <Link to='/address'>
                <p className='run_ord_sevens_handss'>Manage Address</p>
            </Link> */}
        </div>
        { all?.addresss.map((data,key)=>
        <div onClick={()=>switch_data(data,key)} className={(sw===key? 'containneerr' : 'containneerrr')}>
            <div className='row p-2'>
            <div className='col-10'>
                <p className='rob_obe mb-2'>{data.UserDelivAddrFName}</p>
                <p className='rob_two'>{data.UserDelivAddrAddress}</p>
            </div>
            <div style={{margin:'auto'}} className='col-2'>
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={(sw===key ? 'checked' : '')} />
            </div>
            </div>
        </div>
    )}
        </div> 
        
            {
                ootp === true  ? 
                    ''
                :
                <div className='checklist_one'>
                    <div onClick={()=>final()} style={{cursor:'pointer'}} className='checklist_two'>
                        <p className='checklist_three'>handover</p>
                    </div>
                </div>
            }
        


        <div className='popup_one'>
            <ChakraProvider>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>


                        <ModalHeader>
                            <p style={{ fontSize : 12 , color :'#000' , fontWeight : '600'}}>Did You Enjoy Our Service</p>
                        </ModalHeader>
                        <ModalCloseButton />
                            <ModalBody>
                            <div style={{ marginTop : -15 }} className='d-flex justify-content-start' >
                            <div style={{ marginRight : 30 }} >
                                {
                                rating === 1 ? <img onClick={()=>{setRating(1)}} className='pro_home_three' src={color_sad} /> :  <img onClick={()=>{setRating(1)}} className='pro_home_three' src={saad} />
                                }
                                
                                <p style={{ color : '#000' , fontSize :  14 , fontWeight :  '600' , textAlign : 'center' , marginTop : 5 , marginBottom : 10 }} >Bad</p>
                            </div>
                            <div style={{ marginRight : 30 }} >
                                {
                                rating === 2 ? <img onClick={()=>{setRating(2)}} className='pro_home_three' src={color_happy} /> : <img onClick={()=>{setRating(2)}} className='pro_home_three' src={smmill} />
                                }
                                <p style={{ color : '#000' , fontSize :  14 , fontWeight :  '600' , textAlign : 'center' , marginTop : 5 , marginBottom : 10 }} >Fair</p>
                            </div>
                            <div >
                                {
                                rating === 3 ? <img onClick={()=>{setRating(3)}} className='pro_home_three' src={color_ok} /> : <img onClick={()=>{setRating(3)}} className='pro_home_three' src={hppy} />
                                }
                                
                                <p style={{ color : '#000' , fontSize :  14 , fontWeight :  '600' , textAlign : 'center' , marginTop : 5 , marginBottom : 10 }} >Good</p>
                            </div>
                            </div>

                                <Text fontWeight='bold' mb='1rem'>
                                    Tell us what can be improved !
                                </Text>
                                <textarea onChange={(e)=>setFeeeddata(e.target.value)} value={feeddata} className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </ModalBody> 

                        <ModalFooter>
                            <Button onClick={()=>Feedback()} style={{backgroundColor:'#161E40' , color:'white'}}>Submit Feedback</Button>
                        </ModalFooter>


                    </ModalContent>
                </Modal>
            </ChakraProvider>
        </div>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        {/* Same as */}
        <ToastContainer />
    </>
  )
}
