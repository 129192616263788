import React from 'react'
import Payment_report_comp from '../components/fullwidth/payment_report/payment_report_comp'
import Header_two from '../common/head_foot/header_two'
import Footer from '../common/footer'

export default function Payment_report() {
  return (
    <>
        <Header_two />
        <Payment_report_comp />
        <Footer />
    </>
  )
}
