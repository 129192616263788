import React from 'react'
import Wishlist from '../components/wishlist'
import Header_two from '../common/head_foot/header_two'
import Footer from '../common/footer'

export default function Wish() {

  return (
    <>
        <Header_two />
        <Wishlist />
        <Footer />
    </>
  )
}