import React , { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Dimension_err() {

  let navigate = useNavigate()

  useEffect(()=>{
    navigate('/')
  },[])

  return (
    <>
      <div>dimension_err</div>
    </>
  )
}