import React from 'react'
import Mobile_collection from '../../components/mobile_view_comp/mobile_collection/mobile_collection'
import Mobile_foot from './common/mobile_foot'
import Mobile_head from './common/mobile_head'

export default function Mob_collection() {
  return (
    <>
      <div style={{width:'90%' , margin:'auto' , paddingBottom:'100px' , paddingTop:'65px'}}>
        <Mobile_head />
        <Mobile_collection />
        <Mobile_foot/>
      </div>
    </>
  )
}