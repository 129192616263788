import React from 'react'
import Support_comp from '../../components/mobile_view_comp/support_comp/support_comp'
import Loginchat from '../../components/mobile_view_comp/Loginchat/Loginchat'

export default function Support() {
  return (
    <>
        <div style={{width:'90%' , margin:'auto' , paddingBottom:'66px' , paddingTop:'65px'}}>
            <Support_comp />
            {/* <Loginchat /> */}
        </div>
    </>
  )
}
