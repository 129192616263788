import React from 'react'
import './mob_coupon.css'
import logo from '../../../assets/img/icon.png'

export default function Mob_coupon_comp() {
  return (
    <>
        <div className='my-4'>
            <div className='mob_couon_card'>
                <div className='row'>
                    <div className='col-8'>
                        <input type="email" className="form-control kyc_in_onessss" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Coupon Code" />
                    </div>
                    <div className='col-4'>
                        <div className='coupon_box_two'>
                            <p className='coupon_p_one'>Verify</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className='my-4'>
            <div className='coupon'>
                <div className='row'>
                    <div className='col-5 coupon_code_line'>
                        <img src={logo} />
                        <p className='coupon_code'>RENTLA30</p>
                    </div>
                    <div className='col-7'>
                        <p className='coupon_offer'>30% Off</p>
                        <p className='cooupon_detail'>Welcome offer</p>
                        <p className='coupon_detail'>Valid until 01 May 2022</p>
                    </div>
                </div>
                <div className='half_circle'>
                </div>
                <div className='half_circle2'>
                </div>
            </div>
        </div>

        <div className='my-4'>
            <div className='coupon'>
                <div className='row'>
                    <div className='col-5 coupon_code_line'>
                        <img src={logo} />
                        <p className='coupon_code'>RENTLA30</p>
                    </div>
                    <div className='col-7'>
                        <p className='coupon_offer'>30% Off</p>
                        <p className='cooupon_detail'>Welcome offer</p>
                        <p className='coupon_detail'>Valid until 01 May 2022</p>
                    </div>
                </div>
                <div className='half_circle'>
                </div>
                <div className='half_circle2'>
                </div>
            </div>
        </div>


    </>
  )
}