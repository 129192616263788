import firebase from 'firebase/app'
import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'

// const firebaseConfig = {
//   apiKey: "AIzaSyCoQ0qVoGdskfe51KIObESfw6EjbLKknzg",
//   authDomain: "test-51b3e.firebaseapp.com",
//   databaseURL: "https://test-51b3e-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "test-51b3e",
//   storageBucket: "test-51b3e.appspot.com",
//   messagingSenderId: "459776201614",
//   appId: "1:459776201614:web:20ed59336645b193730adf",
//   measurementId: "G-77FPM4EMQF"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyCMfOMZSK5UPuT2Rp26SgATrneMMGNMMO4",
//   authDomain: "rentla-eff0e.firebaseapp.com",
//   databaseURL: "https://rentla-eff0e-default-rtdb.firebaseio.com",
//   projectId: "rentla-eff0e",
//   storageBucket: "rentla-eff0e.appspot.com",
//   messagingSenderId: "102214542958",
//   appId: "1:102214542958:web:16756f103502e2d0b3287f"
// };

// https://rentla-eff0e-default-rtdb.firebaseio.com/

// const firebaseConfig = {
//   apiKey: "AIzaSyCMfOMZSK5UPuT2Rp26SgATrneMMGNMMO4",
//   authDomain: "rentla-eff0e.firebaseapp.com",
//   databaseURL: "https://rentla-eff0e-default-rtdb.firebaseio.com",
//   projectId: "rentla-eff0e",
//   storageBucket: "rentla-eff0e.appspot.com",
//   messagingSenderId: "102214542958",
//   appId: "1:102214542958:web:b57dbec242381d27b3287f"
// };

// Initialize Firebase



//live

const firebaseConfig = {
  apiKey: "AIzaSyCMfOMZSK5UPuT2Rp26SgATrneMMGNMMO4",
  authDomain: "rentla-eff0e.firebaseapp.com",
  databaseURL: "https://rentla-eff0e-default-rtdb.firebaseio.com",
  projectId: "rentla-eff0e",
  storageBucket: "rentla-eff0e.appspot.com",
  messagingSenderId: "102214542958",
  appId: "1:102214542958:web:16756f103502e2d0b3287f"
};


//ajith

// const firebaseConfig = {
//   apiKey: "AIzaSyBrhxV6SS1Hb7cweJGLw6fz35920tHEgm0",
//   authDomain: "dem0-cde37.firebaseapp.com",
//   databaseURL: "https://dem0-cde37-default-rtdb.firebaseio.com",
//   projectId: "dem0-cde37",
//   storageBucket: "dem0-cde37.appspot.com",
//   messagingSenderId: "909728699385",
//   appId: "1:909728699385:web:e58f205f29da5f3369a328",
//   measurementId: "G-91BBN15GMT"
// };


const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);