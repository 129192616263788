import React, { useState, useEffect, useRef } from 'react';
import Profile_home_nav from '../../../page/profile_home/profile_home_nav';
import axios from 'axios';
import { set, ref, onValue, push, child } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { db } from '../../mobile_view_comp/Loginchat/FirebaseConfig';
import msg from '../../../assets/img/msg.png';
import Header from '../../../common/head_foot/header_two';
import Footer from '../../../common/footer';
import { Base_Url } from '../../globalvariable/globe';
import { Row, Col } from 'react-bootstrap';

export default function Web_Support() {
  let navigate = useNavigate();
  const [blogList, setBlogList] = useState([]);
  const [todo, setTodo] = useState('');
  const [login, setLogin] = useState(true);
  const [typedMessage, setTypedMessage] = useState([]);
  const [typedMessagess, setTypedMessagess] = useState([]);


  // Web version
  const messagesRef = ref(db, 'chats/' + localStorage.getItem('user_id'));
  onValue(messagesRef, (snapshot) => {
    const data = snapshot.val();
    // Handle new messages received from Firebase and update your web interface
  });


  const handleChange = (e) => {
    setTodo(e.target.value);
  };


  const handleUserMessage = () => {
    if (todo) {
      setTypedMessage([...typedMessage, todo]);
      writeToDataBase(todo);
    }
  };

  const messagesEndRef = useRef();

  const scrollToBottom = () => {
    if (!messagesEndRef.current) return;
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };


  const [chatId, setChatId] = useState({ 'chatId': 0 })

  const handleQueryClick = (queryMessage, id, ans) => {
    if (ans == 0) setTypedMessage([...typedMessage, queryMessage])
    else if (ans == 1) setTypedMessagess([...typedMessagess, queryMessage])
    setChatId({ 'chatId': id })
    setTodo(queryMessage);
    writeToDataBase(queryMessage);
  };

  const writeToDataBase = (message) => {
    let date = new Date().toLocaleString();
    if (message === '') {
      return;
    }

    const newPostKey = push(child(ref(db), 'chats')).key;

    set(
      ref(db, 'chats/' + localStorage.getItem('user_id') + '/' + newPostKey),
      {
        imageUrl: '',
        message: message,
        messageSentBy: 'User',
        adminname: 'name',
        uId: localStorage.getItem('user_id'),
        messageType: 'Text',
        timeStamp: date,
      }
    );

    setTodo('');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${Base_Url}/chat_bot`, chatId);
        setBlogList(response.data.Data.chatMessage);
        const checkType = response.data.Data.chatMessage
        if (checkType[0].answer == 1) setTypedMessagess([...typedMessagess, checkType[0].ChatbotMessage])
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();

    if (localStorage.getItem('user_data')) {
      onValue(ref(db, 'chats/' + localStorage.getItem('user_id')), (snapshot) => {
        const data = snapshot.val();

        if (data !== null) {
        }
        scrollToBottom();
      });
    } else {
      navigate('/');
    }
  }, [chatId]);


  const renderChatMessages = () => {
    if (!Array.isArray(blogList)) return null;

    return (
      <div>

        {typedMessage && typedMessage.map((val) => {

          return (
            <div className={'d-flex justify-content-end'} style={{ marginTop: '10px', minWidth: '100px' }}>
              <div
                className='chat_scrll'
                style={{
                  width: 'auto',
                  height: 'auto',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                  paddingRight: '15px',
                  paddingLeft: '15px',
                  backgroundColor: '#f1f1f1',
                  borderRadius: '6px',
                  textAlign: 'center',
                }}
              >

                <p>{val}</p>

                <p style={{ marginTop: '-4px', fontSize: '10px', textAlign: 'right' }}>{new Date().toLocaleTimeString()}</p>

              </div>
            </div>)
        })}


        {typedMessagess && typedMessagess.map((val) => {

          return (
            <div className={'d-flex justify-content-start w-25'} style={{ marginTop: '10px', minWidth: '100px' }}>
              <div
                className='chat_scrll'
                style={{
                  width: 'auto',
                  height: 'auto',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                  paddingRight: '15px',
                  paddingLeft: '15px',
                  backgroundColor: '#f1f1f1',
                  borderRadius: '6px',
                  textAlign: 'center',
                }}
              >

                <p>{val}</p>
                <p style={{ marginTop: '-4px', fontSize: '10px', textAlign: 'right' }}>{new Date().toLocaleTimeString()}</p>

              </div>
            </div>)
        })}


      </div>
    );
  };


  return (
    <>
      <Header />
      <div className='pro_home_one mt-150'>
        <div className=''>
          <div className='row'>
            <Profile_home_nav />
            <div className='col-8'>
              <div style={{ position: 'relative', height: '485px', overflowY: 'scroll' }} className='pro_home_seven scroll_padding_bottom'>
                <div className='d-flex'>
                  <p className='pro_home_eight mb-2' style={{ textAlign: 'center', fontWeight: 'bold' }}>Support</p>
                </div>
                {renderChatMessages()}
              </div>
              <div className='supp_two_webchattttt'>
                <div className='row'>


                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                    {blogList.map((chatOption) => (

                      <div
                        className='d-flex'
                        key={chatOption.ChatbotID}
                        onClick={() => handleQueryClick(chatOption.ChatbotMessage, chatOption.ChatbotID, chatOption.answer)}
                        style={{ marginRight: '10px', marginBottom: '10px' }}
                      >
                        {blogList.length > 1 ?
                          <div
                            style={{
                              background: '#F4B644',
                              borderRadius: '15px',
                              padding: '2px',
                              textAlign: 'center',
                              minWidth: '60px',
                            }}
                            className='border border-gray-100 px-2 '
                          >
                            <p style={{ color: '#303651', margin: '0', cursor: 'pointer', fontSize: '13px', fontWeight: '500' }}>{chatOption.ChatbotMessage}</p>
                          </div> : null}
                      </div>
                    ))}
                  </div>


                  <div className="col-10">
                    <input onChange={(e) => handleChange(e)} value={todo} type="text" className="form-control" placeholder="Enter Message" />
                  </div>
                  <div onClick={() => handleUserMessage()} style={{ marginTop: '12px', cursor: 'pointer' }} className="col-2">
                    <img className="mt-1" src={msg} alt="Message" />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}