import React , { useState  , useEffect } from 'react'
import Html from 'html-react-parser'
import Mob_nor_head from '../../../page/mobile/common/mob_nor_head'
import Mob_pro_slider from '../mobile_pro_det/mob_pro_slider'
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from 'react-date-picker';
import axios from 'axios'
import { Select , ChakraProvider , NumberInputField , NumberInput , NumberInputStepper , NumberIncrementStepper , NumberDecrementStepper } from '@chakra-ui/react'
import { useNavigate , useParams , Link } from 'react-router-dom'
import { ImageUrl , Base_Url } from '../../globalvariable/globe';
import Vector from '../../../assets/img/Vector.png'
import tworight from '../../../assets/img/tworight.png'
import { useSelector , useDispatch } from 'react-redux';
import { Wishcount } from '../../../redux/actions/productAction';

export default function Running_pro_detail_mob() {

    let wishss = localStorage.getItem('wish_list')

    let dispatch = useDispatch()

    let navigate = useNavigate()

    const [ keys , setKeys ] = useState(0)
  
    let token = localStorage.getItem('user_data')
  
    let { id , catid , detid } = useParams()
    const [countss  , setCountss ]= useState(1)

    const [ rentvalue , setRentvalue ] = useState('Monthly')
  
    const [ datas , setDates ] = useState()
    const [ datass , setDatass ] = useState()
    const [ veri , setVeri ] = useState()  
    const [ rent , setRents ] = useState()
    const [ imgg , setImgg ] = useState()
    const [ all , setAll ] = useState()
  
    const [value, onChange] = useState(new Date());

    const [ wish ,  setWish ] = useState()
   
  
    const [ amount , setAmount ] = useState()
    const [ dummy , setDummy ] = useState()
    const [ sta , setSta ] = useState(true)
  
    const [ loading , setLoading ] = useState(false)
    const [ qty , setQty ] = useState(1)
    const [ rents , setRent ] = useState('')
    const [ proveri , setProveri ] = useState()
    const [ proveriamount , setProveriamount ] = useState()
    const [ btndata , setBtnData ] = useState('Add To Cart')
    const [ nav , setnavi ] = useState(false)
    const [ monthCount , setMonthCount] = useState(1)
  
    const [ clicolor , setClicolor ] = useState(true)
  
    const [ depamount , setDepamount ] = useState()
    const [ twodummy , setTwodummy ] = useState(0)
    const [ send_dat , setSend_dat ] = useState()
    const [ varid , setVarid ] = useState()
    const [ more , setMore ] = useState(false)
    let final = 0
  
    const succnotify = () => toast.success('Product added Successfully', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
  
    const errnotify = () => toast.error('Something Went Wrong', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
  
    const loginnotify = () => toast.error('Login', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true, 
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    }); 
  
    const allData = async () =>{
        if(token === null){
            navigate('/')
            return
        }
        let res = await axios({
            method : 'post',
            url : `${Base_Url}/viewSingleRunningProduct`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            },
            data : {
              orderId : id ,
              cartDetailId : catid ,
              proId : detid 
            }
        })
        .then((res)=>{
              setDatass(res.data.Data.productDetails)
              setVeri(res.data.Data.productVariants)
              setDates(res.data.Data.productVariants)
              setRents(res.data.Data.BookingType)
              setImgg(res.data.Data.productImage)
              setAll(res.data.Data)
              const dat = new Date(res.data.Data.startingDateWeb);
              onChange(new Date(dat))
              console.log(res)
        })
    }
  
    useEffect(()=>{
        wishss = localStorage.getItem('wish_list')
        setWish(JSON.parse(wishss))
        allData()
    },[])

    useEffect(()=>{
        console.log('adkldfjkbsdfbwejkbksdfjbbjkjk')
        wishss = localStorage.getItem('wish_list')
        setWish(JSON.parse(wishss))
    },[countss])
  
    useEffect(() => {
        console.log(datas,'jkdfuiwghfuiegruifgeriufgurigu')
        if(datas){
            setVarid(datass[0].ProSpecificId)
            setDepamount(datass[0].ProDepositAmt)
            setSend_dat(datas[0].ProSpecificMonthly?datas[0].ProSpecificMonthly : '')
            setDummy(datas[0].ProSpecificMonthly)
        }
    },[datas]);
  
    const date = new Date();
    date.setDate(date.getDate() + ( monthCount*30 ));
    const [value2, onChange2] = useState(date);
  
    const press  = (dat,key) => {
        setVarid(dat.ProSpecificId)
        console.log(key,'key')
        setSta(false)
        setKeys(key)
        setTwodummy(key)
        if(datas[0].ProSpecificId === dat.ProSpecificId){
            if(rents === 'Monthly'){
                
                setSend_dat(datas[0].ProSpecificMonthly)
                setAmount((datas[key].ProSpecificMonthly * qty) * monthCount)
                console.log(send_dat,'month')
            }else if(rents === 'Weekly'){
                setSend_dat(datas[0].ProSpecificWeekly)
                setAmount((datas[key].ProSpecificWeekly * qty) * monthCount)
                console.log(send_dat,'week')
            }else if(rents === 'Daily'){
                setSend_dat(datas[0].ProSpecificDaily)
                setAmount((datas[key].ProSpecificDaily * qty) * monthCount)
                console.log(send_dat,'day')
            }
        }else{
           
            if(rents === 'Monthly'){
                setVarid(datas[key].ProSpecificId)
                setSend_dat(dat.ProSpecificMonthly)
                setAmount((datas[key].ProSpecificMonthly * qty) * monthCount)
                console.log(send_dat,'month')
            }else if(rents === 'Weekly'){
                setVarid(datas[key].ProSpecificId)
                setSend_dat(dat.ProSpecificWeekly)
                setAmount((datas[key].ProSpecificWeekly * qty) * monthCount)
                console.log(send_dat,'week') 
            }else if(rents === 'Daily'){
                setVarid(datas[key].ProSpecificId)
                setSend_dat(dat.ProSpecificDaily)
                setAmount((datas[key].ProSpecificDaily * qty) * monthCount)
                console.log(send_dat,'day')
            }
        }
    }
  
    useEffect(()=>{
        setRent(rent?rent[0].RentTypeName:'')


    },[rent])
  
    useEffect(()=>{
        veri?.map((dat)=>{
            if(dat.selected===1){
                setProveri(dat.ProSpecificId)
                setProveriamount(dat.ProSpecificMonthly)
            }
        })
      //   setProveri(veri ? veri[0].ProAttrOptAttrId : '' )
      //   setProveriamount(veri ? veri[0].ProAttrPrice : '')
    },[veri])
  
    useEffect(()=>{
        console.log(value, 'value')
    },[value])
  
    //calculation
    useEffect(()=>{
        if(qty === 1 && sta===true){
            return
        }
        // if(sta === false ){
        //     setAmount(twodummy * qty)
        //     return
        // }
        setSta(false)
        setDepamount(datass[0].ProDepositAmt * qty)
        if(rents === 'Monthly'){
            setAmount((datas[twodummy].ProSpecificMonthly * qty) * monthCount)
        }else if(rents === 'Weekly'){
            setAmount((datas[twodummy].ProSpecificWeekly * qty) * monthCount)
        }else if(rents === 'Daily'){
            setAmount((datas[twodummy].ProSpecificDaily * qty) * monthCount)
        }
    },[qty])
  
    useEffect(()=>{
        if(monthCount !== monthCount){
            console.log('worked')
            setMonthCount(1)
            return
        }
  
        const dates = new Date(value);
        if(rents === 'Daily'){
            dates.setDate(value.getDate() + ( monthCount * 1 ));
        }else if(rents === 'Weekly'){
            dates.setDate(value.getDate() + ( monthCount * 7 ));
        }else {
            dates.setDate(value.getDate() + ( monthCount * 30 ));
        }
        onChange2(dates)
  
  
        if(monthCount === 1 && sta===true){
            
            return
        }
        setSta(false)
        if(rents === 'Monthly'){
            setVarid(datas[twodummy].ProSpecificId)
            setAmount((datas[twodummy].ProSpecificMonthly * qty) * monthCount)
            setSend_dat(datas[twodummy].ProSpecificMonthly)
            console.log(send_dat , 'mothly')
        }else if(rents === 'Weekly'){
            setVarid(datas[twodummy].ProSpecificId)
            setAmount((datas[twodummy].ProSpecificWeekly * qty) * monthCount)
            setSend_dat(datas[twodummy].ProSpecificWeekly)
            console.log(send_dat , 'weekly')
        }else if(rents === 'Daily'){
            setVarid(datas[twodummy].ProSpecificId)
            setAmount((datas[twodummy].ProSpecificDaily * qty) * monthCount)
            setSend_dat(datas[twodummy].ProSpecificDaily)
            console.log(send_dat , 'daily')
        }
    },[monthCount])
  
    useEffect(()=>{
  
        if(rents === 'Daily'){
            setRentvalue('Daily')
        }else if(rents === 'Weekly'){
            setRentvalue('Weekly')
        }else{
            setRentvalue('Monthly')
        }

        const dates = new Date(value);
        if(rents === 'Daily'){
            dates.setDate(value.getDate() + ( monthCount * 1 ));
        }else if(rents === 'Weekly'){
            dates.setDate(value.getDate() + ( monthCount * 7 ));
        }else {
            dates.setDate(value.getDate() + ( monthCount * 30 ));
        }
        onChange2(dates)
  
  
  
        if(rents === 'Monthly' && sta===true){
            if(rents === 'Monthly'){
                setVarid(datas[twodummy].ProSpecificId)
                setSend_dat(datas[twodummy].ProSpecificMonthly)
                console.log(send_dat , 'mothly')
            }else if(rents === 'Weekly'){
                setVarid(datas[twodummy].ProSpecificId)
                setSend_dat(datas[twodummy].ProSpecificWeekly)
                console.log(send_dat , 'weekly')
            }else if(rents === 'Daily'){
                setVarid(datas[twodummy].ProSpecificId)
                setSend_dat(datas[twodummy].ProSpecificDaily)
                console.log(send_dat , 'daily')
            }
            return
        }
        setSta(false)
        if(rents === 'Monthly'){
            setVarid(datas[twodummy].ProSpecificId)
            setSend_dat(datas[twodummy].ProSpecificMonthly)
            console.log(send_dat , 'mothly')
            setAmount((datas[twodummy].ProSpecificMonthly * qty) * monthCount)
        }else if(rents === 'Weekly'){
            setVarid(datas[twodummy].ProSpecificId)
            setSend_dat(datas[twodummy].ProSpecificWeekly)
                console.log(send_dat , 'weekly')
            setAmount((datas[twodummy].ProSpecificWeekly * qty) * monthCount)
        }else if(rents === 'Daily'){
            setVarid(datas[twodummy].ProSpecificId)
            setSend_dat(datas[twodummy].ProSpecificDaily)
            console.log(send_dat , 'daily')
            setAmount((datas[twodummy].ProSpecificDaily * qty) * monthCount)
        }
    },[rents])
  
  
  
    const calfun = (e) =>{
        const datess = new Date(value); 
        const diffTime = Math.abs(e - datess);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        datess.setDate(value.getDate() + (diffDays + ( monthCount * 30 )));
        onChange(e)
        onChange2(datess)
    }

    const Clickheart = ( data , key ) => {
        
        setCountss(countss +1)
        let allata = localStorage.getItem('wish_list')
        let another = JSON.parse(allata)
        let final = 0
        let ProRentAmt = { "ProRentAmt" : dummy }
        let ProImgName = { "ProImgPrimary" : imgg[0].ProImgName }
        data = {...data , ...ProRentAmt}
        data = {...data , ...ProImgName}
        let wish_data = [
            {
                datas : data
            }
        ]
    
        if(localStorage.getItem('wish_list')){
            another.forEach((element, index) => {
                console.log(index,'index')
                if( element.datas.ProId === wish_data[0].datas.ProId){
                    let allanother = another
                    allanother.splice(index , 1)
                    localStorage.setItem('wish_list' , JSON.stringify(allanother))
                    dispatch(Wishcount(allanother))
                    final = 1
                }
            })
            console.log(final)
            if( final === 0 ){
                console.log('susssssss')
                let allanother =  another.concat(wish_data)
                localStorage.setItem('wish_list' , JSON.stringify(allanother))
                dispatch(Wishcount(allanother))
            }
    
        }else{
            localStorage.setItem('wish_list' , JSON.stringify(wish_data))
            dispatch(Wishcount(wish_data))
        }
    }
  
    const Renew = async () =>{
      let res = await axios({
          method : 'post' ,
          url : `${Base_Url}/renewSingleRunningProduct`,
          headers: { 
              'Authorization': `Bearer ${token}` 
          },
          data : {
              orderId : id,
              rentType : rents,
              fromDate : ((value.getMonth() > 8) ? (value.getMonth() + 1) : ('0' + (value.getMonth() + 1))) + '-' + ((value.getDate() > 9) ? value.getDate() : ('0' + value.getDate())) + '-' + value.getFullYear() ,
              cartDetailId : all.cartDetailId,
              qty : monthCount
          }
      })
      .then((res)=>{
          if(res.data.status === true){
              console.log(res,'resssssss')
              navigate('/renew_order/'+ res.data.Data.orderId)
          }
          console.log(res)
      })
      .catch((err)=>{ 
          console.log(err)
      })
    }

    let handclick  = async() =>{

        let handleary = all.orderPrimaryId+'-'+all.cartDetailId

        let ress = await axios({
            method  : 'post' , 
            url : `${Base_Url}/selectOrderProductToHandover`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            },
            data : {
                cartDetailId : catid,
                orderId : id
            }
        })
        .then((res)=>{
            console.log(res.data.Data.details.autoGenId , 'res.data.Data.orderId')
            if(res.data.status === false ){
                errnotify('Atleast Select One Field')
            }else{
                if( all?.handovered === 0 ){
                    navigate('/handover_detail/'+res.data.Data.details.autoGenId  + '/' + catid + '/' + 1)
                }else{
                    navigate('/handover_detail/'+res.data.Data.details.autoGenId  + '/' + catid + '/' + 0)
                }
                
            }
            console.log(res)
        })
    }
    
  return (
    <>
    <Mob_nor_head link="/products/laptop/3" />
    {imgg ? <Mob_pro_slider data={imgg} /> :''}
    <div style={{width:'90%' , margin:'auto' , paddingBottom:'100px' , paddingTop:'15px'}}>
        <div className='row'>
            <div className='col-10'> 
                <p className='propara2 mt-2'>{datass?datass[0].ProName:''}</p>
            </div>
            <div className='col-2'>
            {/* {(() => {
                if(wish != null){
                var result = wish.filter(x => x.datas.ProId == datass[0]?.ProId);
            if(result.length > 0){
                return ( <i onClick={()=>Clickheart(datass[0])} className='fa fa-heart hrt_red_clr fa-lg' > </i>)
                }else{
                    return ( <i onClick={()=>Clickheart(datass[0])} className='fa fa-heart-o fa-lg' > </i>)
                }
            }
            else{
                return ( <i onClick={()=>Clickheart(datass[0])} className='fa fa-heart-o fa-lg' > </i>)
            }
            })()} */}
            </div>
        </div>
        {/* <p className='prppara3'>{datass?Html(datass[0].ProDescr):''}</p> */}
        <p className='propara4 mt-2'>Variant</p>
        <div className='row mt-2'>
            {veri?.map((data , key)=>
            <div  key={key} className='col-4'>
                <div className={(data.selected===1 ? 'pro_cont' :'pro_cont1')}>
                    <p className={(data.selected===1 ? 'propara6' :'propara5')}>{data.ProSpecificName}</p>
                </div>
            </div>
            )}
        </div>
        <div className='row'>
            <div className='col-6' style={{ marginTop : 20 }} >
                {
                    all?.handovered === 1 ? 
                    <div onClick={()=>{
                            handclick()
                        }} style={{ width : '100%' , height : 45 , backgroundColor : '#fff' , borderRadius :  5  , padding : 11 , border : '1px solid #161E40', cursor : 'pointer' }} >
                        <p style={{ color : '#161E40' , fontSize : 10 , fontWeight :  '500' , textAlign : 'center' }} >Handover Requested</p>
                    </div>
                    : all?.handovered === 2 ? 
                    <div onClick={()=>{
                            handclick()
                        }} style={{ width : '100%' , height : 45 , backgroundColor : '#fff' , borderRadius :  5  , padding : 11 , border : '1px solid #161E40', cursor : 'pointer' }} >
                        <p style={{ color : '#161E40' , fontSize : 14 , fontWeight :  '500' , textAlign : 'center' }} >Handovered</p>
                    </div>
                    : 
                    <div onClick={()=>{
                            handclick()
                        }} style={{ width : '100%' , height : 45 , backgroundColor : '#fff' , borderRadius :  5  , padding : 11 , border : '1px solid #161E40', cursor : 'pointer' }} >
                        <p style={{ color : '#161E40' , fontSize : 14 , fontWeight :  '500' , textAlign : 'center' }} >Handover Now</p>
                    </div>
                }
                
            </div>
            <div className='col-6' >
                {
                    all?.serviced === 1 ?
                    <Link to={'/service_status/'+catid+'/'+id+'/'+ 1}>
                        <div className='boxxes_dt'>
                            <p style={{color:'white', textAlign:'center' , fontSize : 10}}>Service Requested</p>
                        </div>
                    </Link>
                    :
                    all?.serviced === 2 ?
                    <Link to={'/service_status/'+catid+'/'+id+'/'+ 1}>
                        <div className='boxxes_dt'>
                            <p style={{color:'white', textAlign:'center' , fontSize : 13}}>Serviced</p>
                        </div>
                    </Link>
                    :
                    <Link to={'/service_status/'+catid+'/'+id+'/'+ 1}>
                        <div className='boxxes_dt'>
                            <p style={{color:'white', textAlign:'center' , fontSize : 13}}>Request Service</p>
                        </div>
                    </Link>
                }
            </div>
        </div>
       
        <p className='propara7 mt-3 mb-2'>Highlights</p>
        {/* {keys == ke ? subValue : ''} */}
        <Link to={"/mobile_detail/"+detid}>
            <div className='d-flex mt-2'>
                    <p className='propara8'>View Details</p>
                    <img className='propara9' src={tworight} />
            </div>
        </Link>
        <div className='dassh mt-3'>
        </div>

        <>
            <div className='mob_foot'>
                <div className='row'>
                    <div className='col-6'>
                        <p className='compara1'>{rentvalue} rent</p>
                        <p className='compara2'>₹ {sta ? dummy : amount}</p>
                    </div>
                    <div className='col-6'>
                        {
                            all?.status === 1 ? 
                        <div  style={{color:'white' , textAlign : 'center'}} className='bbtn'>
                            <p  style={{color:'white' , textAlign : 'center'}} >Renewd</p>
                            </div> : <div  onClick={()=>Renew()} style={{color:'white' , textAlign : 'center'}} className='bbtn'>
                            <p  style={{color:'white' , textAlign : 'center'}} > Renew</p>
                        </div>
                        }
                        
                    </div>
                </div>
            </div>
        </>
        <div className='row mt-3'>
          <div className='col-8'>
            <p className='propara10 mb-3'>Rent Type</p>
            <ChakraProvider>
                <Select onChange={(e)=>setRent(e.target.value)} placeholder='Select'>
                  {
                    rent?.map((data,key)=>
                      <option key={key} selected={ key===0 ? 'selected' : ''} value={data.RentTypeName}>{data.RentTypeName}</option>
                    )
                  }Running_pro_detail
                </Select>
            </ChakraProvider>
          </div>
          <div className='col-4'>
            <p className='propara10 mb-3'>Count</p>
            <ChakraProvider>
                <NumberInput onChange={(e) => setMonthCount(parseInt(e)) } size='md' maxW={24} defaultValue={1} min={1} max={12}>
                    <NumberInputField />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            </ChakraProvider>
          </div>
      </div>
      <p className='propara10 my-3'>Booking Date</p>
      <div className='row mt-3'>
          <div className='col-5'>
            <DatePicker format='dd-MM-y' onChange={(e)=>calfun(e)} value={value} />
          </div>
          <div className='col-1'>
            <p className='propara12'>To</p>
          </div>
          <div className='col-6'>
            <DatePicker className='datepick ' format='dd-MM-y' disabled={true}  onChange={onChange2} value={value2} />
          </div>
      </div>
            <ToastContainer />
        </div>
    </>
  )
} 
