import React from 'react';
import ReactDOM from "react-dom/client";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./scss/style.scss";
import './assets/css/style.css'
// var XMLHttpRequest = require("xmlhttprequest").XMLHttpRequest;
// const invocation = new XMLHttpRequest();
// const url = 'https://bar.other/resources/credentialed-content/';
// import './assets/css/icons.min.css'



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();