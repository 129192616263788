import React, { useEffect , useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Base_Url } from '../../globalvariable/globe'
import Profile_home_nav from '../../../page/profile_home/profile_home_nav'
import Header from '../../../common/head_foot/header_two'
import Footer from '../../../common/footer'
import Html from 'html-react-parser'

export default function PrivacyPolicy_web() {

  const [ alldata , setAlldata ] = useState()

  let navigate = useNavigate()

  let All = async ()=>{
    let res = await axios({
      method : 'post',
      url : `${Base_Url}/privacyPolicy` 
    })
    .then((res)=>{
      console.log(res)
      setAlldata(res.data.Data.PolicyValue)
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    All()
  })

  const clicked = ()=>{
    navigate(-1)
  }

  return (
    <>
    <Header />
        <div className='pro_home_one mt-150'>
          <div className='row' >
            
            <Profile_home_nav />

            <div className='col-8'>
            <div className='pro_home_fifteen'>
                <div className='d-flex'>
                  <p className='pro_home_eight'>Privacy Policy</p>
                </div>
              </div>
              <div className='pro_home_seven mt-3'>
                <p  style={{fontSize :'14px' , fontWeight: '600'}}>{alldata?Html(alldata):''}</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
    </>
  )
}
