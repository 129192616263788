import React from 'react'

export default function Arrow_payment() {
  return (
    <>
      <div className='row  '>
        <div className='col-3'>
            <div className='boc1112'>
                <p className='po'>Cart</p>
            </div>
        </div>
        <div className='col-3'>
            <div className='boc11123'>
                <p className='po'>Kyc</p>
            </div>
        </div>
        <div className='col-3'>
            <div className='boc11123'>
                <p style={{marginLeft:'20px'}} className='po'>Approval</p>
            </div>
        </div>
        <div className='col-3'>
            <div className='boc11134'>
                <p className='po2'>Payment</p>
            </div>
        </div>
      </div>
    </>
  )
}