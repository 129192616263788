import React from 'react'
import Ser_req_comp from '../../components/mobile_view_comp/service_request/ser_req_comp'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'

export default function Service_request() {
  return (
    <div style={{width:'90%' , margin:'auto' , paddingBottom:'100px',marginTop:'65px'}}>
        <Cart_one_head link='/order' head='Service Request' />
        <Ser_req_comp />
    </div>
  )
}
