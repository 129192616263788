import { combineReducers } from 'redux'
import { productReducer ,selectedProductReducer , allProductReducer , 
    oneProductReducer , allGlobeReducer , logInReducer , checkOutReducer , 
    cartListReducer , viewCartReducer, wishListReducer , orderCofirmReducer , Addcarts , mapReducer , mapReducerzoom ,
     cartCountReducer , wishCountReducer } from './productReducer'

const reducers = combineReducers({
    allProducts : productReducer,
    productss : selectedProductReducer,
    allpoductData : allProductReducer,
    oneproduct : oneProductReducer,
    global : allGlobeReducer,
    login : logInReducer,
    checkout : checkOutReducer,
    cartlist : cartListReducer,
    viewcart : viewCartReducer,
    wishlist : wishListReducer,
    orderconfirm : orderCofirmReducer,
    cart : Addcarts,
    map : mapReducer,
    mapzoom  : mapReducerzoom,
    cartcount :  cartCountReducer,
    wishcount : wishCountReducer

})

export default reducers