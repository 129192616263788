import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../../common/head_foot/header_two';

import axios from 'axios';
import { Base_Url } from '../../globalvariable/globe';
import { ImageUrl } from '../../globalvariable/globe';
import Footer from '../../../common/footer';
import { Helmet } from 'react-helmet';


const Blog_webtwo = () => {
  const { slug } = useParams();
  const [blogData, setBlogData] = useState(null);
  // const item = seodata[id];
  const [item, setItem] = useState({})
  console.log(item, "itt")

  if (item.BlogSeoTitle == null) {
    var titles = 'Best IT Product on Rent Services';

  }
  if (item.BlogSeoDescription == null) {
    var description = 'Best IT Product on Rent Services';
  }
  if (item.BlogSeoKeywords == null) {

    var keywords = 'Best IT Product on Rent Services';
  } else {
    var titles = item.BlogSeoTitle;
    var description = item.BlogSeoDescription;
    var keywords = item.BlogSeoKeywords;
  }

  const seoKey = { blogId:slug}

  const getSeoItem = async () => {

    await axios.post(`${Base_Url}/blogseo`, seoKey)
      .then((res) => {
        console.log(res.data, "ress")
        setItem(res.data.blogseo)
      }).catch((err) => {
        console.log("error", err)
      })

  }

  useEffect(() => {
    const fetchBlogById = async () => {
      try {
        const response = await axios.post(`${Base_Url}/view_blog`, {
          id:slug
        });

        if (response.data.status && response.data.response_code === 1) {
          setBlogData(response.data.Data.blog);
        } else {
          console.error('Failed to fetch blog data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching blog data by id:', error);
      }
    };

    fetchBlogById();
    getSeoItem();
  }, [slug]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{titles}</title>
        <meta name="description" content={description} data-react-helmet="true" />
        <meta name="keywords" content={keywords} data-react-helmet="true" />
      </Helmet>

      <Header />
      <div className=' mt-150'>
        <section id="posts" class="posts">
          <div class="container" data-aos="fade-up">
            <div class="row g-5">
              {blogData ? (

                <>
                  <div class="col-lg-12">
                    <div class="post-entry-1 lg">
                      <div class="post-meta"><h2 class="lapspec fw-bold py-3 " style={{ fontSize: '31px' }} >{blogData.BlogTitle}</h2> </div>
                      <a href="single-post.html"><img src={ImageUrl + '/' + blogData.image} alt="" class="img-fluid mb-2" /></a>


                      <p className=" bloghead fw-bold py-3">We provide gadgets at
                        affordable costs along with maintenance, software upgradation, and doorstep delivery services.</p>
                      <p class="mb-4 d-block" dangerouslySetInnerHTML={{ __html: blogData.BlogDescription }}></p>

                      <div class="d-flex align-items-center author">
                        <div class="photo"><img src="" alt="" class="img-fluid" /></div>
                        <div class="name">
                          <h3 class="m-0 p-0"></h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <p>No blog data found for this ID.</p>
              )}


            </div>
          </div>


        </section>




      </div>




      <Footer />




    </div>
  );
};

export default Blog_webtwo;
