import React , { useEffect , lazy , Suspense } from 'react'
import Swiper from "react-id-swiper";
import Slider_data from './slider_data';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton'

// const Loading_slider = lazy(()=>import('../components/loading/loading_slider')) 

export default function Slider(props) {
    const product =  useSelector((state)=>state)
    const datas = product.allProducts.products.banner
    let loadPages = [ 1 ];
  
       const params = {
        effect: "fade",
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        watchSlidesVisibility: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        renderPrevButton: () => (
          <button className="swiper-button-prev ht-swiper-button-nav">
            <i className="pe-7s-angle-left" /> 
          </button>
        ),
        renderNextButton: () => (
          <button className="swiper-button-next ht-swiper-button-nav">
            <i className="pe-7s-angle-right" />
          </button>
        )
      };
  return (
    <>
    {
       props.data ?  
      <div className="slider-area">
          <div className="slider-active owl-carousel nav-style-1"> 
              <Swiper {...params}>
                {
                  datas?.map((datas , key)=>{
                    return( 
                      <Slider_data data={datas} key={key} urls={props.urls}  sliderClass="swiper-slide"/>
                    )
                })}
              </Swiper> 
          </div>
      </div> 
      :
      loadPages.map(()=>{
        return(
          <div 
          // className="container head_container"
          style={{width:'100%'}}
          > 
              <div className="slider-active owl-carousel nav-style-1">
                <div className='slider-height-7 bg-glaucous d-flex align-items-center'>
                  <div className="container-fluid">
                    <div className="row align-items-center slider-h9-mrg">
                      <div className="col-12"> 
                        <Skeleton height={'500px'} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        )
      })
    }
      
    </>
  )
}
