import React from 'react'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'
import Mob_kyc_one from '../../components/mobile_view_comp/kyc_one/mob_kyc_one'

export default function Kyc_one() {
  return (
    <>
        <div style={{width:'90%' , margin:'auto' , paddingBottom:'100px',marginTop:'65px'}}>
            <Cart_one_head link='/profile' head='Kyc' />
           
            <Mob_kyc_one />
        </div>
    </>
  )
}