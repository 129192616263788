import React from 'react'
import Home_bank_detail_comp from '../components/fullwidth/home_bank_detail/home_bank_detail_comp'
import Header_two from '../common/head_foot/header_two'
import Footer from '../common/footer'

export default function Home_bank_detail() {
  return (
    <>
        <Header_two />
        <Home_bank_detail_comp />
        <Footer />
    </>
  )
}
