import React , { useState , useEffect } from 'react'
import ItemsCarousel from 'react-items-carousel';
import Detail_cat from './detail_cat';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { allProduct } from '../../../redux/actions/productAction';
import { useDispatch } from 'react-redux';
import { Base_Url } from '../../globalvariable/globe';
import { useParams , useNavigate } from 'react-router-dom';

export default function Data_cat(props) {

    let { name , names , id , bro } = useParams()

    let navigate = useNavigate()

    const [ brand , setBrand ] = useState(0)
    const [ pkey , setPkey ] = useState(props.catid)
    const [ activeItemIndex , setActiveItemIndex] = useState(0);
    const chevronWidth = 40;
    const [ ke , setKe ] = useState()
    const [finalcat , setFinalcat] = useState(0)
    const [ keyone , setKeyone ] = useState(true)

    const dispatch = useDispatch()

    const product =  useSelector((state)=>state.allpoductData)

    
    const clicked = (dat,key) =>{
      if(key === 0){
        setKeyone(true)
      }else{
        setKeyone(false)
      }
      if(props.catids != 0){
        setPkey(props.catids)
        setBrand(dat.BrandId)
      }
      if(ke===key){

      }else{
        setBrand(dat.BrandId)
        setKe(key)
      }
      navigate('/products/'+name+'/'+id+ '/' + dat.BrandName + '/'+ dat.BrandId )
    }

    const bodyParameters = {
      key: "value"
    }; 

  const clikk = async () =>{
    let responce = await axios({
      method: 'post',
      url: `${Base_Url}/products`,
      bodyParameters,
      data :{
        catId : pkey,
        brandId : brand
      }
    })
    dispatch(allProduct(responce.data.Data))
  }

  useEffect(() => {
    clikk()
  },[brand]);

  useEffect(() => {
    setKeyone(true)
    setKe()
  },[props.catids]);



  return (
    <>
    <div 
    className='yhdghkbfgh'
    // style={{marginTop:'20px'}}
    >
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={4}
        gutter={20}
        outsideChevron
        chevronWidth={chevronWidth}
      >
        {product.brands_of_1st_category?.map((dat,key)=>
        <>
          <div  onClick={()=>clicked(dat,key)}  className="row">
            {/* <div className='col-3'>
            </div> */}
            <div className='col-9'>
              <p style={{textAlign : 'center'}} className={(  dat.BrandName === names ? 'txttss' : ke === key ? 'txttss' :'txtt')}>{dat.BrandName.split(" ").join("")}</p>
            </div>
          </div>
        </>
        )}
      </ItemsCarousel>
      <Detail_cat />
    </div>
    </>
  )
}
