import React , { useState , useEffect } from 'react'
import axios from 'axios'
import { Base_Url , ImageUrl } from '../../globalvariable/globe'
import { useNavigate , useParams , Link } from 'react-router-dom'
import Header from '../../../common/head_foot/header_two'
import logo from '../../../assets/img/icon.png'
import Footer from '../../../common/footer'
import { Wishcount } from '../../../redux/actions/productAction'
import { useSelector , useDispatch } from 'react-redux';

export default function OfferWeb() {

    
    const [ all , setAll ] = useState()

    let wishss = localStorage.getItem('wish_list')
    const [ wish ,  setWish ] = useState()

    let dispatch = useDispatch()

    let navigate = useNavigate()

    let { id } = useParams()

    const allData = async () =>{

        let res = await axios ({
            method : 'post' ,
            url : `${Base_Url}/viewOfferProducts`,
            data : {
                catId : id
            }
        })
        .then((res)=>{
            setAll(res.data.Data.products)
            console.log(res)
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    useEffect(()=>{
        wishss = localStorage.getItem('wish_list')
        setWish(JSON.parse(wishss))
        allData()
    },[])

    const [countss  , setCountss ]= useState(1)

    useEffect(()=>{
        wishss = localStorage.getItem('wish_list')
        setWish(JSON.parse(wishss))
    },[countss])

    const clicked = ( dat , key ) =>{
        navigate('/detail/'+ dat.ProName
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '')+'/' +dat.ProId)
    }

     const Clickheart = ( data , key ) => {
        setCountss(countss +1)
        let allata = localStorage.getItem('wish_list')
        let another = JSON.parse(allata)
        let final = 0

        let wish_data = [
            {
                datas : data
            }
        ]

        if(localStorage.getItem('wish_list')){
            another.forEach((element, index) => {
                if( element.datas.ProId === wish_data[0].datas.ProId){
                    let allanother = another
                    allanother.splice(index , 1)
                    localStorage.setItem('wish_list' , JSON.stringify(allanother))
                    dispatch(Wishcount(allanother))
                    final = 1
                }
            })
            console.log(final)
            if( final === 0 ){
                let allanother =  another.concat(wish_data)
                localStorage.setItem('wish_list' , JSON.stringify(allanother))
                dispatch(Wishcount(allanother))
            }

        }else{
            localStorage.setItem('wish_list' , JSON.stringify(wish_data))
            dispatch(Wishcount(wish_data))
        }
     }


  return (
    <>
        <Header />
        <div className='container web_cat_five'>
            <div className='row  mt-80  mb-80'>
                {
                    all?.length === 0 ? <p style={{marginTop:'60px' , fontSize:'20px'}}>No Data Found</p> : <p style={{marginTop:'60px' , fontSize:'20px'}}>Offer</p>
                }
            
                {all?.map((data,key)=>
                <div  class="col-md-6 col-lg-4 col-xl-4 col-sm-6 col-xxl-3 home_pro_cont"  key={key}  style={{height:'500px'}}>
                <div class="product-grid" >
                    <div className='pro'>
                        <div className="product-image">
                        {/* <div onClick={()=>clicked(data,key)}>
                            <div className="image">
                                <img  className="pic-1 onepice" src={ ImageUrl +'/'+data.ProImgPrimary} />
                            </div>
                        </div> */}
                        <Link to={'/detail/'+ data.ProName
                            .toLowerCase()
                            .trim()
                            .replace(/[^\w\s-]/g, '')
                            .replace(/[\s_-]+/g, '-')
                            .replace(/^-+|-+$/g, '') +'/'+data.ProId}>
                            <div className="image">
                                <img  className="pic-1 onepice" src={ ImageUrl +'/'+data.ProImgPrimary} />
                            </div>
                        </Link>
                            <div className="product-like-icon">
                            {(() => {
                                                if(wish != null){
                                                    console.log(typeof(wish),'wishhhh')
                                                var result = wish.filter(x => x.datas.ProId == data.ProId);
 
                                            if(result.length > 0){
                                               return ( <i onClick={()=>Clickheart(data,key)} className='fa fa-heart hrt_red_clr' > </i>)
                                                }else{
                                                    return ( <i onClick={()=>Clickheart(data,key)} className='fa fa-heart-o' > </i>)
                                                }
                                            }
                                            else{
                                                return ( <i onClick={()=>Clickheart(data,key)} className='fa fa-heart-o' > </i>)
                                            }
                                            })()}

                            </div>
                                <div >

                                    <div   className="head">
                                    </div>
                        
                                    {/* <div   className="heads mb-1">
                                        <h5>Accessories Include</h5>
                                    </div>
                                    <div className='cont d-flex  bd-highlight'>
                                        <div className='bd-highlight'>

                                            <div style={{width:'60px' , height:'60px' ,marginTop:'40px',marginBottom:'20px',marginLeft:'10px',borderStyle:'solid',borderWidth:'1px',borderRadius:'4px',borderColor:'#cfcfcf'}} className=" product-linkss">
                                                <img title='Monitor' style={{marginTop:'10px',padding:'4px'}} className="pic-2" src="https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE4LEgb?ver=6c30" />
                                            </div>

                                        </div>
                                        <div className='bd-highlight'>

                                            <div style={{width:'60px' , height:'60px' ,marginTop:'40px',marginBottom:'20px',marginLeft:'10px',borderStyle:'solid',borderWidth:'1px',borderRadius:'4px',borderColor:'#cfcfcf'}} className=" product-linkss">
                                            <img title='Keyboard' style={{marginTop:'10px',padding:'4px'}} className="pic-2" src="https://m.media-amazon.com/images/I/61dQmz5+hnL._SX450_.jpg" />
                                            </div>

                                        </div>
                                        <div className='bd-highlight'>

                                            <div style={{width:'60px' , height:'60px' ,marginTop:'40px',marginBottom:'20px',marginLeft:'10px',borderStyle:'solid',borderWidth:'1px', borderRadius:'4px',borderColor:'#cfcfcf'}} className=" product-linkss">
                                                <img title='Mouse' style={{marginTop:'10px' ,padding:'4px'}} className="pic-2" src="https://media.4rgos.it/i/Argos/9213513_R_Z001A?w=750&h=440&qlt=70" />
                                            </div>
                                            
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                    <div>
                        <div class="product-content">
                        <div class="row lit">
                            <div class="col"> 
                                <h5 class="title">
                                {data.ProName.substring(0, 35)}....</h5>
                            </div>
                            {/* <div class="col">
                                <div className='d-flex justify-content-end' style={{marginRight:'18px'}}>
                                    <div className='bd-highlight'>
                                        <i class="fa fa-truck"></i>
                                    </div>
                                    <div className='  bd-highlight'>
                                        <p className='paragraph'>60 hour delivery</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                            <div>
                                <hr />
                            </div>
                            <div className='row mt-4 pb-4'>
                            <div className='col-8'>
                            <div  className="product-det">
                                {/* <div   className="btn product-detss " style={{backgroundColor: '#161E40', color: 'white',fontSize:'10px' }}>2 GB</div>
                                <div type="button" style={{ fontSize:'10px'}} className="btn btn-default product-detss bro_desss_pro">4 GB</div>
                                <div type="button" style={{fontSize:'10px'}} className="btn btn-default product-detss bro_desss_pro">6 GB</div> */}
                                {
                                    data.spec.map((datass,keyss)=>{
                                        
                                        if(keyss < 1){
                                            return(
                                                <div className='btn product-detss product_detss_box'>{datass.ProSpecificName}</div>
                                            )
                                        }else{
                                        return
                                        }
                                    })
                                }
                                <Link to={'/detail/'+ data.ProName
                                    .toLowerCase()
                                    .trim()
                                    .replace(/[^\w\s-]/g, '')
                                    .replace(/[\s_-]+/g, '-')
                                    .replace(/^-+|-+$/g, '') +'/'+data.ProId}>
                                    <div  className='btn btn-default product-detss bro_desss_pro product_detss_box_two'>More</div>
                                </Link>
                            </div>
                            </div>
                            <div className='col-4'>
                                <div className='pull-right'>
                                    <div className='d-flex'>
                                        
                                        {/* <div style={{marginRight:'10px' ,marginTop:'4px', color:'#cfcfcf',fontSize:'10px',textDecoration:' line-through'}} className="prices">Rs:178.80</div> */}
                                        <div style={{fontSize:'10px',borderRadius:'4px'}} className="prices">
                                            <div style={{padding:'4px',marginRight:'15px'}}>
                                                <div  className="price">Rs:{data.ProRentAmt} / M</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
                )}
                </div>
            </div>
                                <Footer no={all?.length <= 2 ? 1 : 0 } />
    </>
  )
}
