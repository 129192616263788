import React , { useEffect } from 'react'
import Mobile_cart from '../../components/mobile_view_comp/mobile_cart'
import Mobile_foot from './common/mobile_foot'
import Mobile_head from './common/mobile_head'

export default function Mob_cart() {


  return (
    <>
      <div style={{width:'90%' , margin:'auto' , paddingBottom:'100px' , paddingTop:'65px'}}>
        <Mobile_head />
          <Mobile_cart />
        <Mobile_foot />
      </div>
    </>
  )
}