import React, { useEffect , useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Base_Url } from '../../globalvariable/globe'
import Profile_home_nav from '../../../page/profile_home/profile_home_nav'
import Header from '../../../common/head_foot/header_two'
import Footer from '../../../common/footer'
import Html from 'html-react-parser'

export default function UserAgrement() {

  const [ alldata , setAlldata ] = useState()

  let navigate = useNavigate()

  

  return (
    <>
    <Header />
        <div className='pro_home_one mt-150'>
          <div className='row' >
            
            <Profile_home_nav />

            <div className='col-8'>
            <div className='pro_home_fifteen'>
                <div className='d-flex'>
                  <p className='pro_home_eight'>User Agreement</p>
                </div>
              </div>
              <div className='pro_home_seven mt-3'>
                <iframe style={{ width : '100%' , height : 600 }} src='https://rentla.akprojects.co/useraggrementview' ></iframe>
              </div>
            </div>
          </div>
        </div>
        <Footer />
    </>
  )
}
