import React , { useState , useEffect } from 'react'
import axios from 'axios'
import Header from '../../../common/head_foot/header_two'
import Footer from '../../../common/footer'
import Profile_home_nav from '../../../page/profile_home/profile_home_nav'
import head_111 from '../../../assets/img/head_111.png'
import { Link , useParams  , useNavigate } from 'react-router-dom'
import { Base_Url , ImageUrl } from '../../globalvariable/globe'
import './Renew_order.css'

export default function Renew_order_web() {

  let navigate = useNavigate()

  let token = localStorage.getItem('user_data')

  let { id } = useParams()

  const [ all , setAll ] = useState()

  const allData = async() =>{
    if(token === null){
      navigate('/')
      return
    }
    let res = await axios ({
      method : 'post' ,
      url : `${Base_Url}/viewRenewedOrder`,
      headers: { 
        'Authorization': `Bearer ${token}` 
      },
      data : {
          orderId : id
      }
    })
    .then((res)=>{
      console.log(res)
      setAll(res.data.Data)
    })
  }

  useEffect(()=>{
    allData()
  },[])

  const gotosupport =()=>{
    navigate('/support')
  }

  return (
    <>
      <Header />
      <div className='pro_home_one  mt-150 mb-150'>
          <div className='row' >
            
            <Profile_home_nav />

            <div className='col-8'>
            <div className='pro_home_fifteen'>
                <div className='d-flex'>
                  <p className='pro_home_eight'>Renew Request</p>
                </div>
              </div>
              <div className='pro_home_seven mt-3'>
          <p className='renew_one mb-2'>Details</p>
          <div className='row'>
              <div className='col-5'>
                <p className='renew_two'>Order Id</p>
              </div>
              <div className='col-1'>
                    <p className='renew_three'>:</p>
              </div>
              <div className='col-6'>
                    <p className='renew_four'>{all?.orderId}</p>
              </div>
          </div>
          <div className='row mt-2'>
              <div className='col-5'>
                <p className='renew_two'>Booking Date</p>
              </div>
              <div className='col-1'>
                    <p className='renew_three'>:</p>
              </div>
              <div className='col-6'>
                    <p className='renew_four'>{all?.Detail.fromDate} - {all?.Detail.toDate}</p>
              </div>
          </div>
        <p className='renew_five my-2'>Package Contains :</p>
        {all?.productDetails.map((data,key)=>
                  <div className='run_ord_fives mt-3'>
                    <div className='row'>
                      <div className='col-8'>
                        <p className='run_ord_six mt-1'>{data.BrandName}</p>
                        <p className='run_ord_sevensa'>{data.ProName}</p>
                        <p className='run_ord_eights mt-1'>Qty : {data.CartProQty}</p>
                      </div>
                      <div className='col-4'>
                        <img style={{width:'97px'}} className='run_ord_ninethousand' src={ImageUrl+'/'+data.ProImgPrimary} />
                      </div>
                    </div>
                  </div>
                )}
                <div className='mob_footssssa mt-3'>
                  <div className='row'>
                      <div className='col-6'>
                          <div onClick={()=>gotosupport()} className='d-flex'>
                              <div className='headset'>
                                <img style={{cursor:'pointer'}} className='kaart' src={head_111} />
                              </div>
                              <div>
                                <p style={{cursor:'pointer'}} className='compara222 compara2223'>Support</p>
                                <p style={{cursor:'pointer'}} className='compara333a compara2223'>9am - 5Pm</p>
                              </div>
                          </div>
                      </div>
                      <div className='col-6'>
                          <div className='bbtn'>
                              <Link  to={'/payment/0/'+all?.orderId}>
                                <div className='d-flex'>
                                    <p className='compara nextonee mt-1'>Proceed to pay</p>
                                </div>
                              </Link>
                          </div>
                      </div>
                  </div>
              </div>
              </div>
              

            </div>
          </div>
        </div>
      <Footer />
    </>
  )
}
