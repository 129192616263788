import React, { useState, useEffect } from 'react'
import Header from '../../../common/head_foot/header_two'
import Footer from '../../../common/footer'
import feed from '../../../assets/img/feed.png'
import downl from '../../../assets/img/downf.png'
import downk from '../../../assets/img/downk.png'
import Profile_home_nav from '../../../page/profile_home/profile_home_nav'
import Timer from '../../mobile_view_comp/otpp/timer'
import DatePicker from 'react-date-picker'
import axios from 'axios'
import { Base_Url, ImageUrl } from '../../globalvariable/globe'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import './running_details_erb.css'
import { useDispatch } from 'react-redux'
import { LoogIn } from '../../../redux/actions/productAction'
import { ChakraProvider, NumberInputField, NumberInput, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper } from '@chakra-ui/react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    Button,
    useDisclosure
} from '@chakra-ui/react'
import { ToastContainer, toast } from 'react-toastify';

import hppy from '../../../assets/img/hppy.png'
import smmill from '../../../assets/img/smmill.png'
import saad from '../../../assets/img/saad.png'
import color_sad from '../../../assets/img/color_sad.png'
import color_happy from '../../../assets/img/color_happy.png'
import color_ok from '../../../assets/img/color_ok.png'

export default function Running_details_erb() {

    let navigate = useNavigate()

    let dispatch = useDispatch()

    let { id } = useParams()

    let token = localStorage.getItem('user_data')

    const [value, onChange] = useState(new Date());

    const [alldata, setAlldata] = useState()

    const [monthCount, setMonthCount] = useState(1)

    const [monthtype, setMonthtype] = useState()

    const [startdate, setStartdate] = useState()

    const [enddate, setEnddate] = useState()

    const [val, setVal] = useState()

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [rating, setRating] = useState()
    const [feeddata, setFeeeddata] = useState()
    let user = localStorage.getItem('user_data')
    const successnotify = (sus) => toast.success('Feedback Submited Success', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false, 
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });


    let Data = async () => {
        if (token === null) {
            navigate('/')
            return
        }
        let responce = await axios({
            method: 'post',
            url: `${Base_Url}/viewRunningOrder`,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: {
                orderId: id
            }
        })
            .then((res) => {
                setAlldata(res.data.Data)
                const datesss = new Date(res.data.Data.startingDateWeb);
                setStartdate(datesss)
                setMonthtype(res.data.Data.BookingType[0].RentTypeName)
                const someDate = new Date(datesss);
                var numberOfDaysToAdd = 30;
                var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
                setEnddate(new Date(result))
                console.log(res)
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    dispatch(LoogIn({ show: true }))
                    // navigate('/')
                }
            })
    }

    useEffect(() => {
        Data()
    }, [])

    let incre = (e) => {
        if (parseInt(e) !== parseInt(e)) {
            console.log('workedddddd')
            // setMonthCount(1)
            return
        }
        if (parseInt(e) > 100) {
            setMonthCount(100)
        } else {
            setMonthCount(parseInt(e))
        }
        return
    }

    useEffect(() => {
        if (monthtype === "Monthly") {
            const someDate = new Date(startdate);
            var numberOfDaysToAdd = 30 * monthCount;
            var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            setEnddate(new Date(result))
        }
        if (monthtype === "Weekly") {
            const someDate = new Date(startdate);
            var numberOfDaysToAdd = 7 * monthCount;
            var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            setEnddate(new Date(result))
        }
        if (monthtype === "Daily") {
            const someDate = new Date(startdate);
            var numberOfDaysToAdd = 1 * monthCount;
            var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            setEnddate(new Date(result))
        }
    }, [monthCount])

    let incremon = (e) => {
        setMonthtype(e.target.value)

        if (e.target.value === "Monthly") {
            const someDate = new Date(startdate);
            var numberOfDaysToAdd = 30 * monthCount;
            var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            setEnddate(new Date(result))
        }
        if (e.target.value === "Weekly") {
            const someDate = new Date(startdate);
            var numberOfDaysToAdd = 7 * monthCount;
            var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            setEnddate(new Date(result))
        }
        if (e.target.value === "Daily") {
            const someDate = new Date(startdate);
            var numberOfDaysToAdd = 1 * monthCount;
            var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            setEnddate(new Date(result))
        }
    }

    const submit = async () => {
        let res = await axios({
            method: 'post',
            url: `${Base_Url}/renewRunningOrder`,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: {
                orderId: alldata?.orderId,
                rentType: monthtype,
                fromDate: alldata?.startingDateWeb,
                toDate: ((enddate.getDate() > 9) ? enddate.getDate() : ('0' + enddate.getDate())) + '-' + ((enddate.getMonth() > 8) ? (enddate.getMonth() + 1) : ('0' + (enddate.getMonth() + 1))) + '-' + enddate.getFullYear(),
                qty: monthCount
            }
        })
            .then((res) => {
                if (res.data.status === true) {
                    navigate('/renew_order/' + res.data.Data.orderId)
                }
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const ratingChanged = (newRating) => {
        setRating(newRating);
    };
    const clicked = () => {
        setFeeeddata('')
        onOpen()
    }

    const Feedback = async () => {
        let responce = await axios({
            method: 'post',
            url: `${Base_Url}/runningOrderFeedback`,
            headers: {
                'Authorization': `Bearer ${user}`
            },
            data: {
                orderId: id,
                rating: rating,
                description: feeddata
            }
        })
            .then((res) => {
                onClose()
                successnotify()
                console.log(res)
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    navigate('/login')
                }
                console.log(err)
            })
    }

    return (
        <>
            <Header />
            <div className='pro_home_one  mt-150'>
                <div className='row' >

                    <Profile_home_nav />

                    <div className='col-8'>
                        <div className='pro_home_fifteen'>
                            <div className='d-flex'>
                                <p className='pro_home_eight'>Running details</p>
                            </div>
                        </div>
                        <div className='pro_home_seven mt-3'>
                            <div className='run_ord_one'>
                                <div className='row'>
                                    <div className='col-5'>
                                        <Timer time={alldata?.orderDetails.remainingDays} />
                                    </div>
                                    <div className='col-7'>
                                        <p className='run_ord_two mb-4'>ID : {alldata?.orderDetails.orderId}</p>
                                        <p className='run_ord_three'>{alldata?.orderDetails.fromDate} - {alldata?.orderDetails.toDate}</p>
                                    </div>
                                </div>
                            </div>
                            <div >
                                <div className='row mt-2 mb-2' >

                                    <div className='col-6' >
                                        <div onClick={() => clicked()} style={{ width: '100%', height: 45, backgroundColor: '#F4F4F4', borderRadius: 5, padding: 14, cursor: 'pointer' }} >
                                            <div>
                                                <div className='d-flex justify-content-center' >
                                                    <div >
                                                        <img style={{ marginLeft: 'auto', width: 30 }} className='run_ord_nine' src={feed} />
                                                    </div>
                                                    <p style={{ fontWeight: '500', fontSize: 14, color: '#000' }} >Feedback</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-6' >
                                        <div style={{ width: '100%', height: 45, backgroundColor: '#F4F4F4', borderRadius: 5, padding: 14, cursor: 'pointer' }} >
                                            <div>
                                                <div className='d-flex justify-content-center' >
                                                    <div style={{ marginTop: 6 }} >
                                                        <img style={{ marginLeft: 'auto', width: 25 }} className='run_ord_nine' src={downl} />
                                                    </div>
                                                    <p style={{ fontWeight: '500', fontSize: 14, color: '#000' }} >Downlaod</p>
                                                    <div style={{ marginTop: 10 }} >
                                                        <img style={{ marginLeft: 'auto', width: 25 }} className='run_ord_nine' src={downk} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className='row mt-2 mb-2' >

                                    <div className='col-6' >
                                        <div onClick={() => {
                                            navigate(`/handover_req/${id}`+'/'+1)
                                        }} style={{ width: '100%', height: 45, backgroundColor: '#fff', borderRadius: 5, padding: 11, border: '1px solid #161E40', cursor: 'pointer' }} >
                                            <p style={{ color: '#161E40', fontSize: 14, fontWeight: '500', textAlign: 'center' }} >Handover Now</p>
                                        </div>
                                    </div>

                                    <div className='col-6' >
                                        <div onClick={() => {
                                            navigate('/service_request/'+alldata?.orderDetails.orderId+"/"+1 )
                                        }} style={{ width: '100%', height: 45, backgroundColor: '#161E40', borderRadius: 5, padding: 11, cursor: 'pointer' }} >
                                            <p style={{ color: '#fff', fontSize: 14, fontWeight: '500', textAlign: 'center' }} >Request Service</p>
                                        </div>
                                    </div>

                                </div>


                            </div>
                            
                            <p className='run_ord_four'>Order Contains</p>
                            <div>
                                {alldata?.productDetails.map((data, key) =>
                                    <div className='run_ord_five mt-3'>
                                        <div className='row'>
                                            <div className='col-8'>
                                                <p className='run_ord_six'>{data.BrandName}</p>
                                                <p className='run_ord_sevensss'>{data.ProName}</p>
                                                <p className='run_ord_eightsss'>Qty : {data.CartProQty}</p>
                                            </div>
                                            <div className='col-4'>
                                                <img  className='run_ord_ninea' src={ImageUrl + '/' + data.ProImgPrimary} />
                                                {/* {data.status  ? (data.status == 'Replacement' ?
                            <div className='run_ord_tenss'>
                            <p className='run_ord_eleven'>Handovered</p>
                            </div>
                            :
                            <div className='run_ord_twelvess'>
                            <p className='run_ord_eleven'>Replacement</p>
                            </div>
                            ) : ''} */}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='run_ord_twelvee my-3'></div>
                            <div className='row'>
                                <div className='col-6'>
                                    <p className='kyc_two_para'>Rent Type</p>
                                    <select onChange={(e) => incremon(e)} className="form-select kyc_in_one" aria-label="Default select example">
                                        {
                                            alldata?.BookingType.map((datas, key) =>
                                                <option selected={key === 0 ? 'selected' : ''} value={datas.RentTypeName}>{datas.RentTypeName}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div className='col-6'>
                                    <p className='propara10 mb-2'>Duration</p>
                                    <ChakraProvider>
                                        <NumberInput onChange={(e) => incre(e)} value={monthCount} size='md' maxW={24} defaultValue={1} min={1} max={12}>
                                            <NumberInputField />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper />
                                                <NumberDecrementStepper />
                                            </NumberInputStepper>
                                        </NumberInput>
                                    </ChakraProvider>
                                </div>
                            </div>

                            <p className='propara10 my-3'>Booking Date</p>
                            <div style={{ marginBottom: '30px' }}>
                                <div className='row mt-3 '>
                                    <div className='col-3'>
                                        <DatePicker disabled value={startdate} />
                                    </div>
                                    <div className='col-1'>
                                        <p className='propara12'>To</p>
                                    </div>
                                    <div className='col-3'>
                                        <DatePicker className='datepick' disabled value={enddate} />
                                    </div>
                                </div>
                            </div>

                            <div className=' hshshsw'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <p className='compara1a'>rent</p>
                                        <p className='compara2a'>₹ {monthtype === "Monthly" ? alldata?.rentPerMonth * monthCount : monthtype === "Weekly" ? alldata?.rentPerWeek * monthCount : monthtype === "Daily" ? alldata?.rentPerDay * monthCount : alldata?.rentPerMonth * monthCount}
                                            {monthtype === "Monthly" ? '/M' : monthtype === "Weekly" ? '/W' : monthtype === "Daily" ? '/D' : '/M'}</p>
                                    </div>
                                    <div className='col-6'>
                                        {
                                            alldata?.renewalStatus === 0 ?
                                                <div style={{ cursor: 'pointer' }} onClick={() => submit()} className='bbtn'>
                                                    <div className='d-flex justify-content-center'>
                                                        <p className='compara '>Renew</p>
                                                    </div>
                                                </div>
                                                :
                                                <div style={{ cursor: 'pointer' }} className=''>
                                                    <div className='d-flex justify-content-center'>
                                                        <p className='cooooompraaa '>Renewed</p>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            <Footer />
            <div className='popup_one'>
                <ChakraProvider>
                    <Modal isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay />
                        <ModalContent>
                        

                            <ModalHeader>
                            <p style={{ fontSize : 12 , color :'#000' , fontWeight : '600'}}>Did You Enjoy Our Service</p>
                            </ModalHeader>
                            <ModalCloseButton />
                                <ModalBody>
                                <div style={{ marginTop : -15 }} className='d-flex justify-content-start' >
                                <div style={{ marginRight : 30 }} >
                                    {
                                    rating === 1 ? <img onClick={()=>{setRating(1)}} className='pro_home_three' src={color_sad} /> :  <img onClick={()=>{setRating(1)}} className='pro_home_three' src={saad} />
                                    }
                                    
                                    <p style={{ color : '#000' , fontSize :  14 , fontWeight :  '600' , textAlign : 'center' , marginTop : 5 , marginBottom : 10 }} >Bad</p>
                                </div>
                                <div style={{ marginRight : 30 }} >
                                    {
                                    rating === 2 ? <img onClick={()=>{setRating(2)}} className='pro_home_three' src={color_happy} /> : <img onClick={()=>{setRating(2)}} className='pro_home_three' src={smmill} />
                                    }
                                    <p style={{ color : '#000' , fontSize :  14 , fontWeight :  '600' , textAlign : 'center' , marginTop : 5 , marginBottom : 10 }} >Fair</p>
                                </div>
                                <div >
                                    {
                                    rating === 3 ? <img onClick={()=>{setRating(3)}} className='pro_home_three' src={color_ok} /> : <img onClick={()=>{setRating(3)}} className='pro_home_three' src={hppy} />
                                    }
                                    
                                    <p style={{ color : '#000' , fontSize :  14 , fontWeight :  '600' , textAlign : 'center' , marginTop : 5 , marginBottom : 10 }} >Good</p>
                                </div>
                                </div>

                                    <Text fontWeight='bold' mb='1rem'>
                                        Tell us what can be improved !
                                    </Text>
                                    <textarea onChange={(e)=>setFeeeddata(e.target.value)} value={feeddata} className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                </ModalBody> 

                            <ModalFooter>
                                <Button onClick={()=>Feedback()} style={{backgroundColor:'#161E40' , color:'white'}}>Submit Feedback</Button>
                            </ModalFooter>


                        </ModalContent>
                    </Modal>
                </ChakraProvider>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {/* Same as */}
            <ToastContainer />
        </>
    )
}
