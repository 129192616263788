import React, { useState, useEffect, useRef } from 'react'
import Magnify from './magnify'
import { useSelector, useDispatch } from 'react-redux'
import info from '../assets/img/info.png'
import { Select, ChakraProvider, NumberInputField, NumberInput, NumberInputStepper, NumberIncrementStepper, Tabs, TabList, TabPanels, Tab, TabPanel, NumberDecrementStepper } from '@chakra-ui/react'
import DatePicker from 'react-date-picker';
import tik from '../assets/img/tik.png'
import ReactLoading from 'react-loading';
import { useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { selectedRemoveProduct, Cartcount, Wishcount } from '../redux/actions/productAction'
import Skeleton from 'react-loading-skeleton'
import { Base_Url, ImageUrl } from './globalvariable/globe'
import Html from 'html-react-parser'
import tocart from '../assets/img/tocart.png'
import ReactImageMagnify from 'react-image-magnify';    
import moment from 'moment/moment'
import { Button, Form, Modal } from 'react-bootstrap'
import axios from 'axios'
import { useCookies } from 'react-cookie'

// import { selectedRemoveProduct } from '../redux/actions/productAction'

export default function All_detail() {
    const { id } = useParams()

    const [img, setImg] = useState();

    const [date2, setDate2] = useState();

    let wishss = localStorage.getItem('wish_list')

    const [wish, setWish] = useState()

    const [cookies, setCookie] = useCookies(["usercat"]);

    let dateC = new Date();
    dateC.setDate(dateC.getDate() + 1)
    let expires = "; expires=" + dateC.toUTCString();

    let urlParamsObject = new URLSearchParams(window.location.search);
    let affiliateId = urlParamsObject.get('cat');

    console.log(affiliateId, "aff")


    if (affiliateId) {
        setCookie("usercat", `${affiliateId}`, { expires: dateC });
        // console.log("parmss", cookies)

    }
    else {

    }
    const sendCookie = affiliateId ? cookies.usercat : null

    let dispatch = useDispatch()

    let navigate = useNavigate()

    const [keys, setKeys] = useState(0)
    const [images, setImages] = useState([])

    const product = useSelector((state) => state.oneproduct)
    // console.log(product, "sdfd")
    const imgs = product.productImage
    const datas = product.productVariants
    const datass = product.productDetails
    const veri = product.productVariants
    const rent = product.BookingType
    const desc = product.Seodetails ? product.Seodetails.ProSeoDescription : null
    // console.log(desc, "sdfd")

    const imgss = product.awsbaseurl
    const dtass = product.webImage

    let nre = localStorage.getItem('eye')

    const [enqDetails, setEnqDetails] = useState({
        name: '',
        phoneNumber: '',
        productId: id,
        email: '',
        address: '',
        detail: '',
        cookies: sendCookie
    })

    const [validationErrors, setValidationErrors] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        address: '',
        detail: ''
    });
    // console.log(enqDetails,"de")
    const handleInputChange = (e, fieldName) => {
        setEnqDetails({
            ...enqDetails,
            [fieldName]: e.target.value
        });
        setValidationErrors({
            ...validationErrors,
            [fieldName]: e.target.value === '' ? `Please enter your ${fieldName}.` : ''
        });

    };

    const [showEnqModal, setShowEnqModal] = useState(false)
    const [thankModal, setThankModal] = useState(false)

    const requiredFields = ['name', 'phoneNumber', 'email'];

    const handleSubmit = async () => {
        // Check if any field is empty
      
        const errors = {};
        requiredFields.forEach(field => {
            if (enqDetails.phoneNumber.trim().length > 0 && enqDetails.phoneNumber.trim().length !== 10) {
                errors.phoneNumber = 'Phone number should be 10 digits.';
            }
            if (enqDetails[field] === '') {
                errors[field] = `Please enter your ${field}.`;
            }

        });

        setValidationErrors(errors);

        // Proceed with form submission if there are no errors
        if (Object.keys(errors).length === 0) {

            setShowEnqModal(false); // Close modal after submission
            let res = await axios.post(`${Base_Url}/submit_enquiry`, enqDetails)
            // console.log(res, "ress")
            if (res.data.status == true) {
                toast.success('Successfully Submitted,Thank you!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setThankModal(true)
            }
            else {
                toast.error(res.data.Error_Message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }


        }
    };


    const FakeDataItems = () => {

        // console.log(dtass, 'dtassdtassdtassdtassdtassdtassdtassdtass')

        // return  dtass?.map((id, i) => {
        //     let url =  imgss+'/'+id.ProImgName,
        //     images.push[url]
        // })
    };


    useEffect(() => {
        if (dtass?.length === 0 || dtass === undefined || dtass === '') {

        } else {
            setImg(imgss + '/' + dtass[0].ProImgName)
            dtass.map((id) =>
                setImages(images.concat(imgss + '/' + id.ProImgName))

            )
        }
        console.log(dtass, 'dtassdtassdtassdtassdtassdtassdtassdtass')
    }, [dtass])



    // const images = [
    //     'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png',
    //     './images/img2.jpg',
    //     './images/img3.jpg',
    //     './images/img4.jpg',
    //     './images/img5.jpg',
    // ];

    const newone = product.webAppConfignew

    const [amount, setAmount] = useState()
    const [dummy, setDummy] = useState()
    const [sta, setSta] = useState(true)

    const [loading, setLoading] = useState(false)
    const [qty, setQty] = useState(1)
    const [rents, setRent] = useState('')
    const [proveri, setProveri] = useState()
    const [proveriamount, setProveriamount] = useState()
    const [btndata, setBtnData] = useState('Add To Cart')
    const [nav, setnavi] = useState(false)
    const [monthCount, setMonthCount] = useState(1)

    const [clicolor, setClicolor] = useState(true)

    const [depamount, setDepamount] = useState()
    const [twodummy, setTwodummy] = useState(0)
    const [send_dat, setSend_dat] = useState()
    const [varid, setVarid] = useState()
    const [more, setMore] = useState(false)
    let final = 0

    let typw = localStorage.getItem('user_type')

    const [onees, setOnees] = useState(true)

    const [countss, setCountss] = useState(1)

    useEffect(() => {
        console.log('adkldfjkbsdfbwejkbksdfjbbjkjk')
        wishss = localStorage.getItem('wish_list')
        setWish(JSON.parse(wishss))
    }, [countss])



    const Clickheart = (data, key) => {

        setCountss(countss + 1)
        let allata = localStorage.getItem('wish_list')
        let another = JSON.parse(allata)
        let final = 0
        let ProRentAmt = { "ProRentAmt": dummy }
        let ProImgName = { "ProImgPrimary": imgs[0].ProImgName }
        data = { ...data, ...ProRentAmt }
        data = { ...data, ...ProImgName }
        let wish_data = [
            {
                datas: data
            }
        ]

        if (localStorage.getItem('wish_list')) {
            another.forEach((element, index) => {
                console.log(index, 'index')
                if (element.datas.ProId === wish_data[0].datas.ProId) {
                    let allanother = another
                    allanother.splice(index, 1)
                    localStorage.setItem('wish_list', JSON.stringify(allanother))
                    dispatch(Wishcount(allanother))
                    final = 1
                }
            })
            console.log(final)
            if (final === 0) {
                console.log('susssssss')
                let allanother = another.concat(wish_data)
                localStorage.setItem('wish_list', JSON.stringify(allanother))
                dispatch(Wishcount(allanother))
            }

        } else {
            localStorage.setItem('wish_list', JSON.stringify(wish_data))
            dispatch(Wishcount(wish_data))
        }
    }


    const succnotify = () => toast.success('Product added Successfully', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const errnotify = () => toast.error('Something Went Wrong', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const loginnotify = () => toast.error('Login', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    useEffect(() => {
        setImages([])
        wishss = localStorage.getItem('wish_list')
        setWish(JSON.parse(wishss))
        dispatch(selectedRemoveProduct())
        FakeDataItems()
    }, [])

    useEffect(() => {
        console.log(datas, 'jkdfuiwghfuiegruifgeriufgurigu')
        if (datas) {
            setVarid(datass[0].ProSpecificId)
            setDepamount(typw === 'corporate' ? 0 : datass[0].ProDepositAmt)
            setSend_dat(datas[0].ProSpecificMonthly ? datas[0].ProSpecificMonthly : '')
            setDummy(datas[0].ProSpecificMonthly)
        }
    }, [datas]);

    const [value, onChange] = useState(new Date());
    const date = new Date();
    date.setDate(date.getDate() + (monthCount * 30));
    const [value2, onChange2] = useState(date);

    const press = (dat, key) => {
        setVarid(dat.ProSpecificId)
        console.log(key, 'key')
        setSta(false)
        setKeys(key)
        setTwodummy(key)
        if (datas[0].ProSpecificId === dat.ProSpecificId) {
            if (rents === 'Monthly') {

                setSend_dat(datas[0].ProSpecificMonthly)
                setAmount((datas[key].ProSpecificMonthly * qty) * monthCount)
                console.log(send_dat, 'month')
            } else if (rents === 'Weekly') {
                setSend_dat(datas[0].ProSpecificWeekly)
                setAmount((datas[key].ProSpecificWeekly * qty) * monthCount)
                console.log(send_dat, 'week')
            } else if (rents === 'Daily') {
                setSend_dat(datas[0].ProSpecificDaily)
                setAmount((datas[key].ProSpecificDaily * qty) * monthCount)
                console.log(send_dat, 'day')
            }
        } else {
            if (rents === 'Monthly') {
                setVarid(datas[key].ProSpecificId)
                setSend_dat(dat.ProSpecificMonthly)
                setAmount((datas[key].ProSpecificMonthly * qty) * monthCount)
                console.log(send_dat, 'month')
            } else if (rents === 'Weekly') {
                setVarid(datas[key].ProSpecificId)
                setSend_dat(dat.ProSpecificWeekly)
                setAmount((datas[key].ProSpecificWeekly * qty) * monthCount)
                console.log(send_dat, 'week')
            } else if (rents === 'Daily') {
                setVarid(datas[key].ProSpecificId)
                setSend_dat(dat.ProSpecificDaily)
                setAmount((datas[key].ProSpecificDaily * qty) * monthCount)
                console.log(send_dat, 'day')
            }
        }
    }

    useEffect(() => {
        setRent(rent ? rent[0].RentTypeName : '')
    }, [rent])

    useEffect(() => {
        setProveri(veri ? veri[0].ProAttrOptAttrId : '')
        setProveriamount(veri ? veri[0].ProAttrPrice : '')
    }, [veri])

    useEffect(() => {
        console.log(product, 'prodeeeectttt')
        console.log(value, 'value')
    }, [value])

    //calculation
    useEffect(() => {




        if (qty === 1 && sta === true) {
            return
        }
        setSta(false)
        if (typw === 'corporate') {

        } else {
            setDepamount(datass[0].ProDepositAmt * qty)
        }

        if (rents === 'Monthly') {
            setAmount((datas[twodummy].ProSpecificMonthly * qty) * monthCount)
        } else if (rents === 'Weekly') {
            setAmount((datas[twodummy].ProSpecificWeekly * qty) * monthCount)
        } else if (rents === 'Daily') {
            setAmount((datas[twodummy].ProSpecificDaily * qty) * monthCount)
        }
    }, [qty])

    useEffect(() => {

        if (monthCount !== monthCount) {
            console.log('worked')
            setMonthCount(1)
            return
        }


        const dates = new Date(value);
        if (rents === 'Daily') {
            dates.setDate(value.getDate() + (monthCount * 1));
        } else if (rents === 'Weekly') {
            dates.setDate(value.getDate() + (monthCount * 7));
        } else {
            dates.setDate(value.getDate() + (monthCount * 30));
        }
        onChange2(dates)
        setDate2(dates)


        if (monthCount === 1 && sta === true) {

            return
        }
        setSta(false)
        if (rents === 'Monthly') {
            setVarid(datas[twodummy].ProSpecificId)
            setAmount((datas[twodummy].ProSpecificMonthly * qty) * monthCount)
            setSend_dat(datas[twodummy].ProSpecificMonthly)
            console.log(send_dat, 'mothly')
        } else if (rents === 'Weekly') {
            setVarid(datas[twodummy].ProSpecificId)
            setAmount((datas[twodummy].ProSpecificWeekly * qty) * monthCount)
            setSend_dat(datas[twodummy].ProSpecificWeekly)
            console.log(send_dat, 'weekly')
        } else if (rents === 'Daily') {
            setVarid(datas[twodummy].ProSpecificId)
            setAmount((datas[twodummy].ProSpecificDaily * qty) * monthCount)
            setSend_dat(datas[twodummy].ProSpecificDaily)
            console.log(send_dat, 'daily')
        }
    }, [monthCount])

    useEffect(() => {


        const dates = new Date(value);
        if (rents === 'Daily') {
            dates.setDate(value.getDate() + (monthCount * 1));
        } else if (rents === 'Weekly') {
            dates.setDate(value.getDate() + (monthCount * 7));
        } else {
            dates.setDate(value.getDate() + (monthCount * 30));
        }
        onChange2(dates)
        setDate2(dates)


        if (rents === 'Monthly' && sta === true) {
            if (rents === 'Monthly') {
                setVarid(datas[twodummy].ProSpecificId)
                setSend_dat(datas[twodummy].ProSpecificMonthly)
                console.log(send_dat, 'mothly')
            } else if (rents === 'Weekly') {
                setVarid(datas[twodummy].ProSpecificId)
                setSend_dat(datas[twodummy].ProSpecificWeekly)
                console.log(send_dat, 'weekly')
            } else if (rents === 'Daily') {
                setVarid(datas[twodummy].ProSpecificId)
                setSend_dat(datas[twodummy].ProSpecificDaily)
                console.log(send_dat, 'daily')
            }
            return
        }
        setSta(false)
        if (rents === 'Monthly') {
            setVarid(datas[twodummy].ProSpecificId)
            setSend_dat(datas[twodummy].ProSpecificMonthly)
            console.log(send_dat, 'mothly')
            setAmount((datas[twodummy].ProSpecificMonthly * qty) * monthCount)
        } else if (rents === 'Weekly') {
            setVarid(datas[twodummy].ProSpecificId)
            setSend_dat(datas[twodummy].ProSpecificWeekly)
            console.log(send_dat, 'weekly')
            setAmount((datas[twodummy].ProSpecificWeekly * qty) * monthCount)
        } else if (rents === 'Daily') {
            setVarid(datas[twodummy].ProSpecificId)
            setSend_dat(datas[twodummy].ProSpecificDaily)
            console.log(send_dat, 'daily')
            setAmount((datas[twodummy].ProSpecificDaily * qty) * monthCount)
        }
    }, [rents])

    const sendData = () => {

        if (nav === true) {
            navigate('/cart')
            return
        }

        console.log(value2, 'value2', value, 'value')

        let localdata = [
            {
                proVarId: varid,
                proId: datass[0].ProId,
                proQty: qty,
                proDepAmnt: typw === 'corporate' ? 0 : datass[0].ProDepositAmt,
                proRentAmnt: sta === true ? dummy : send_dat,
                proVarAmnt: sta === true ? dummy : send_dat,
                proTotalAmnt: sta === true ? dummy : amount,
                proRentType: rents,
                proBookFrom: ((value?.getMonth() > 8) ? (value?.getMonth() + 1) : ('0' + (value?.getMonth() + 1))) + '-' + ((value?.getDate() > 9) ? value?.getDate() : ('0' + value?.getDate())) + '-' + value?.getFullYear(),
                proBookTo: ((date2?.getMonth() > 8) ? (date2?.getMonth() + 1) : ('0' + (date2?.getMonth() + 1))) + '-' + ((date2?.getDate() > 9) ? date2?.getDate() : ('0' + date2?.getDate())) + '-' + date2?.getFullYear(),
                imgurl: ImageUrl + '/' + imgs[0].ProImgName,
                brand: datass[0].ProName,
                monthCount: monthCount,
                totalDeposit: typw === 'corporate' ? 0 : depamount,
                alldet: {
                    datas
                }
            }
        ]

        if (localStorage.getItem('data')) {
            let allata = localStorage.getItem('data')
            let another = JSON.parse(allata)
            another.forEach((element, index) => {
                if (element.proId === localdata[0].proId && element.proRentType === localdata[0].proRentType && element.proBookFrom === localdata[0].proBookFrom && element.monthCount === localdata[0].monthCount && element.proVarId === localdata[0].proVarId) {
                    console.log(element.proQty, 'element.proQty element.proQty')
                    final = 1
                    another[index].proQty = another[index].proQty + qty
                    another[index].totalDeposit = another[index].proDepAmnt * another[index].proQty
                    another[index].proTotalAmnt = another[index].proVarAmnt * another[index].proQty
                    localStorage.setItem('data', JSON.stringify(another))
                    dispatch(Cartcount(another))
                    setnavi(true)
                    setLoading(false)
                    setBtnData('Go to Cart')
                    succnotify()
                    console.log(final, 'infinal')
                }
            })

            // let obj = another.find((element, index) => {
            //     if (element.proId === localdata[0].proId) {
            //        console.log('true') // stop searching
            //     }else{
            //         console.log('false')
            //     }
            // });

            // console.log

            if (final === 0) {
                let allanother = another.concat(localdata)
                localStorage.setItem('data', JSON.stringify(allanother))
                dispatch(Cartcount(allanother))
                setnavi(true)
                setLoading(false)
                setBtnData('Go to Cart')
                succnotify()
            }
        } else {
            localStorage.setItem('data', JSON.stringify(localdata))
            dispatch(Cartcount(localdata))
            setnavi(true)
            setLoading(false)
            setBtnData('Go to Cart')
            succnotify()
        }

        // setTimeout(() => {
        //     navigate(0) 
        // }, 2000)
        console.log(final, 'final')
    }

    const calfun = (e) => {

        // const datess = new Date(value); 

        // const diffTime = Math.abs(e - datess);

        // console.log(rents,'eee1')

        // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        if (rents === 'Weekly') {
            let one = moment(e).add(7 * monthCount, 'd');
            onChange(e)
            onChange2(one)
            setDate2(one._i)
            console.log(one._i, 'sfvfsdfcvsvsd')
            // datess.setDate(value.getDate() + (diffDays + ( monthCount * 7 )))
        } else if (rents === 'Daily') {
            let one = moment(e).add(1 * monthCount, 'd');
            onChange(e)
            onChange2(one)
            setDate2(one._i)
            // datess.setDate(value.getDate() + (diffDays + ( monthCount * 1 )))
        } else {
            let one = moment(e).add(30 * monthCount, 'd');
            onChange(e)
            onChange2(one)
            setDate2(one._i)
            // datess.setDate(value.getDate() + (diffDays + ( monthCount * 30 )));
        }


    }

    let Qttttychange = (e) => {
        if (qty > 12 && e === 1) {
            return
        }
        if (qty >= 1) {
            console.log(parseInt(e), 'fail')
            // if (parseInt(e) !== parseInt(e)){
            //     console.log('workedddddd')
            //     // setQty(1)
            //     return
            // }
            setQty(qty + e)

            if (qty > 100) {
                setQty(100)
                return
            } if (qty + e === 0) {
                setQty(1)
            }
        } else {
            setQty(1)
        }

    }

    let montttttCount = (e) => {
        console.log(parseInt(e), 'fail')
        if (parseInt(e) !== parseInt(e)) {
            console.log('workedddddd')
            // setQty(1)
            return
        }
        setMonthCount(parseInt(e))

        if (parseInt(e) > 100) {
            setMonthCount(100)
            return
        }
    }



    const hoverHandler = (image, i) => {
        setImg(image);
        refs.current[i].classList.add('active');
        for (var j = 0; j < images.length; j++) {
            if (i !== j) {
                refs.current[j].classList.remove('active');
            }
        }
    };
    const refs = useRef([]);
    refs.current = [];
    const addRefs = (el) => {
        if (el && !refs.current.includes(el)) {
            refs.current.push(el);
        }
    };






    return (
        <>
            <div className="shop-area pb-5" style={{ paddingTop: '73px' }}>
                {
                    datas ?
                        <div className="container head_containersss" style={{ backgroundColor: 'white', paddingTop: '40px' }}>
                            <div className="row">
                                <div className="col-xl-5 col-lg-5 col-md-5">


                                    <div className="container">
                                        <div className="left">
                                            <div className="left_1">
                                                {images?.map((image, i) => {

                                                    return (
                                                        <div
                                                            className={i == 0 ? 'img_wrap active' : 'img_wrap'}
                                                            key={i}
                                                            onMouseOver={() => hoverHandler(image, i)}
                                                            ref={addRefs}
                                                        >
                                                            <img src={image} alt="" />
                                                        </div>
                                                    )
                                                }
                                                )}
                                            </div>
                                            <div className="left_2">
                                                <ReactImageMagnify
                                                    {...{
                                                        smallImage: {
                                                            alt: 'Rentla',
                                                            isFluidWidth: true,
                                                            src: img,
                                                        },
                                                        largeImage: {
                                                            src: img,
                                                            width: 1200,
                                                            height: 1800,
                                                            backgroundColor: 'white'
                                                        },
                                                        enlargedImageContainerDimensions: {
                                                            width: '170%',
                                                            height: '100%',
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="right"></div>
                                    </div>




                                    {/* <Magnify /> */}






                                    <div style={{ marginTop: 30 }} >
                                        <div className='row'>
                                            <div className='d-flex justify-content-start'>
                                                <img className='info_img' src={info} />
                                                <p style={{ fontSize: 14, fontWeight: 500, color: '#000', marginTop: 2 }}>Disclaimer</p>
                                            </div>
                                            <p style={{ fontSize: 14, fontWeight: 500, color: '#000' }} className='info_veri_two'>This is a representative image only. Actual product might differ in condition{product.productDisclaimer ? product.productDisclaimer[0].ProDisclaimer : ''}</p>
                                        </div>
                                    </div>

                                    <div className='row mt-4'>
                                        <div className='col-6'>
                                            <div className='cool2 cool2_veri'>
                                                <p className='propara13444'>Deposit</p>
                                                <p className='propara14'><span style={{ fontFamily: 'arial' }} >₹</span>
                                                    {typw === 'corporate' ? 0 : datass ? depamount : ''}
                                                </p>
                                                <p className='propara15'>Included</p>
                                                <div className='d-flex '>
                                                    <img className='verti_img skdnskf sfvdkgehhrfuddd' style={{ width: 20, height: 20 }} src={tik} />
                                                    <p className='propara16'>Deposit will returned</p>
                                                </div>
                                                <div className='d-flex '>
                                                    <img className='verti_img sfvdkgehhrfuddd' style={{ width: 20, height: 20 }} src={tik} />
                                                    <p className='propara16'>Safe & Secure</p>
                                                </div>
                                                <div className='d-flex '>
                                                    <img className='verti_img sfvdkgehhrfuddd' style={{ width: 20, height: 20 }} src={tik} />
                                                    <p className='propara16'>Zero Charges</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='cool2 cool2_veri'>
                                                <p className='propara13444'>Rent</p>
                                                <p className='propara14'><span style={{ fontFamily: 'arial' }} >₹</span>
                                                    {/* {datas?datas[0].ProSpecificMonthly:''} */}
                                                    {sta ? dummy : amount}</p>
                                                <p className='propara15'>Included</p>
                                                <div className='d-flex '>
                                                    <img className='verti_img sfvdkgehhrfuddd' style={{ width: 20, height: 20 }} src={tik} />
                                                    <p className='propara16'>Free Delivery</p>
                                                </div>
                                                <div className='d-flex '>
                                                    <img className='verti_img sfvdkgehhrfuddd' style={{ width: 20, height: 20 }} src={tik} />
                                                    <p className='propara16'>Delivery in 2-5 Hrs</p>
                                                </div>
                                                <div className='d-flex '>
                                                    <img className='verti_img sfvdkgehhrfuddd' style={{ width: 20, height: 20 }} src={tik} />
                                                    <p className='propara16'>Door Step Service</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>




                                </div>
                                <div className="col-xl-1 col-lg-1 col-md-1">
                                    <div className="pro-details-wishlist">
                                        {(() => {
                                            if (wish != null) {
                                                var result = wish.filter(x => x.datas.ProId == datass[0]?.ProId);
                                                if (result.length > 0) {
                                                    return (<i onClick={() => Clickheart(datass[0])} className='fa fa-heart hrt_red_clr fa-lg' > </i>)
                                                } else {
                                                    return (<i onClick={() => Clickheart(datass[0])} className='fa fa-heart-o fa-lg' > </i>)
                                                }
                                            }
                                            else {
                                                return (<i onClick={() => Clickheart(datass[0])} className='fa fa-heart-o fa-lg' > </i>)
                                            }
                                        })()}
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6  col-md-6">
                                    <div className="product-details-content" style={{ paddingRight: 110 }}>
                                        <p style={{ fontSize: 24, fontWeight: 600, color: '#000' }} className='my-1'>{datass ? datass[0].ProName : ''}</p>
                                        <div className="product-details-price mb-1">
                                        </div>
                                        <p className='product-details-contentrrrrp' >{datass[0].ProDescr ? Html(datass[0].ProDescr) : ''}</p>
                                        <div>
                                            <div style={{ marginTop: 15 }} className='d-flex justify-content-start'>
                                                {
                                                    veri?.map((dat, key) =>
                                                        <div style={{ cursor: 'pointer', marginRight: 20 }} onClick={() => press(dat, key)} >
                                                            <div className={(keys === key ? 'pro_conts' : 'pro_cont1s')}>
                                                                <p style={{ fontSize: '12px' }} className={(keys === key ? 'de_veri_css' : 'propara5')}>{dat.ProSpecificName}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>

                                        <div className='row' style={{ marginTop: 15 }}>

                                            <div className='col-6 col-lg-6 col-xl-6 col-xxl-6'>


                                                <p className='propara10 '>Rent Type</p>
                                                <ChakraProvider>
                                                    <Select onChange={(e) => setRent(e.target.value)} size={'md'} >
                                                        {
                                                            rent?.map((data, key) =>
                                                                <option selected={key === 0 ? 'selected' : ''} value={data.RentTypeName}>{data.RentTypeName}</option>
                                                            )
                                                        }

                                                    </Select>
                                                </ChakraProvider>
                                            </div>
                                            <div className='col-3 col-lg-3 col-xl-3 col-xxl-3'>
                                                <p className='propara10'>Duration</p>
                                                <ChakraProvider>
                                                    <NumberInput onChange={(e) => montttttCount(e)} value={monthCount} size='md' defaultValue={1} min={1} max={12}>
                                                        <NumberInputField />
                                                        <NumberInputStepper>
                                                            <NumberIncrementStepper />
                                                            <NumberDecrementStepper />
                                                        </NumberInputStepper>
                                                    </NumberInput>
                                                </ChakraProvider>
                                            </div>
                                            <div className='col-3 col-lg-3 col-xl-3 col-xxl-3'>
                                                <p className='propara10 '>Qty</p>
                                                <div className='slvmsdfm' >
                                                    <div className='d-flex justify-content-between'>
                                                        <p style={{ color: '#F4B644', cursor: 'pointer', fontSize: 20 }} onClick={() => { Qttttychange(-1) }}>-</p>
                                                        <p>{qty}</p>
                                                        <p style={{ color: '#F4B644', cursor: 'pointer', fontSize: 20 }} onClick={() => { Qttttychange(1) }}>+</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className='propara10 ' style={{ marginTop: 15 }}>Rental Period</p>
                                        <div className='row mb-1' style={{ width: '104%' }}>
                                            <div className='col-5'>
                                                <DatePicker minDate={new Date()} showLeadingZeros={true} format='dd-MM-y' onChange={(e) => { calfun(e) }} value={value} />
                                            </div>
                                            <div className='col-2'>
                                                <p className='propara12 '>To</p>
                                            </div>
                                            <div className='col-5'>
                                                <DatePicker format='dd-MM-y' disabled={true} className='datepick ' onChange={onChange2} value={value2} />
                                            </div>
                                        </div>



                                        <p className='compara23333 text-start mt-3'><span style={{ fontFamily: 'arial' }} >₹</span> {sta ? dummy : amount} /-</p>

                                        <div className="pro-details-quality d-flex justify-content-between " style={{ paddingTop: 15, cursor: 'pointer' }}>

                                            <div class="row">

                                                <div className="col-6 ">
                                                    <div className='pro_cart_det_cart me-5 w-75' onClick={() => setShowEnqModal(true)}>
                                                        <p style={{ fontSize: 15, fontWeight: 500, textAlign: 'center' }}>Enquiry</p>
                                                    </div>
                                                </div>



                                                <div className="col-6">
                                                    <div onClick={() => sendData()} style={{ cursor: 'pointer' }} className="pro-details-cart w-90 ">

                                                        {
                                                            nre === 'lufy' ?

                                                                ''

                                                                :

                                                                <div className='pro_cart_det_cart ' >
                                                                    {
                                                                        loading ? <ReactLoading type='balls' color='#ffffff' />
                                                                            :

                                                                            <>
                                                                                <div className='row'>
                                                                                    <div className='col-8'>
                                                                                        <p style={{ fontSize: 16, fontWeight: 500, textAlign: 'right' }}>{btndata}</p>
                                                                                    </div>
                                                                                    <div className='col-4'>
                                                                                        <img src={tocart} />
                                                                                    </div>
                                                                                </div>
                                                                            </>


                                                                    }
                                                                </div>
                                                        }


                                                    </div>

                                                </div>
                                            </div>
                                        </div>



                                        <div>
                                            <div>
                                                <p style={{ fontSize: 16, fontWeight: 600, color: '#000', marginTop: 30 }}>Specifications</p>
                                            </div>


                                            <div className="description-review-area">
                                                <div className="">
                                                    <div id="des-details2" className="tab-pane">
                                                        <div className="product-description-wrapper vndkhjdhgsdibgbdgb">
                                                            {
                                                                more === false ? product?.webAppConfignew?.map((data, key) =>
                                                                    <>
                                                                        <p style={{ marginTop: 5, fontWeight: '700', textAlign: 'left', marginBottom: 5, fontSize: 15 }}>{data.name}</p>

                                                                        {
                                                                            data.values.map((datasss, key) =>

                                                                                key >= 5 ? '' :
                                                                                    <>
                                                                                        <div className='row' >
                                                                                            <div className='col-4'>
                                                                                                <p style={{ fontSize: 14, fontWeight: 500, color: '#697089' }}>{datasss.name}</p>
                                                                                            </div>
                                                                                            <div className='col-6' >
                                                                                                <p style={{ fontSize: 14, fontWeight: 500, color: '#303651' }} >{datasss.value}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                            )
                                                                        }
                                                                    </>
                                                                ) : product?.webAppConfignew?.map((data, key) =>
                                                                    <>
                                                                        <p style={{ marginTop: 20, fontWeight: '700', textAlign: 'left', marginBottom: 20, fontSize: 15 }}>{data.name}</p>
                                                                        <table>
                                                                            {
                                                                                data.values.map((datasss, key) =>

                                                                                    <tr>
                                                                                        <th style={{ width: '40%' }}>{datasss.name}</th>
                                                                                        <th style={{ width: '20%' }}>:</th>
                                                                                        <th style={{ width: '40%' }}>{datasss.value}</th>
                                                                                    </tr>

                                                                                )
                                                                            }
                                                                        </table>
                                                                    </>
                                                                )

                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                            {desc != null ? (
                                <p style={{ fontWeight: 500, fontSize: 20, color: '#666666' }}>Description : </p>
                            ) : (
                                <></>

                            )}


                            <p style={{ fontWeight: 500, fontSize: 14, color: '#666666', marginTop: '10px' }}>{desc}</p>

                        </div>
                        :
                        <div className="container head_containers">
                            <div className="row" style={{ height: 800 }}>
                                <div className="col-xl-7 col-lg-7 col-md-12">
                                    <Skeleton height={'100%'} />
                                </div>
                                <div className="col-lg-5 col-lg-5 col-md-12">
                                    <div className="product-details-content">
                                        <Skeleton count={1} />
                                        <div className="product-details-price mb-1">
                                            <Skeleton count={1} />
                                        </div>
                                        <Skeleton count={4} />
                                        <div className="pro-details-list mb-2">
                                            <ul>
                                                <Skeleton count={1} />
                                                <Skeleton count={1} />
                                                <Skeleton count={1} />
                                            </ul>
                                        </div>

                                        <div>
                                            <div className='row mb-2'>
                                                <div className='col-4'>
                                                    <Skeleton width={'100%'} height={'100%'} />
                                                </div>
                                                <div className='col-4'>
                                                    <Skeleton width={'100%'} height={'100%'} />
                                                </div>
                                                <div className='col-4'>
                                                    <Skeleton width={'100%'} height={'100%'} />
                                                </div>
                                            </div>
                                        </div>


                                        <div>
                                            <div className='row'>
                                                <div className='d-flex justify-content-start'>
                                                    <Skeleton count={3} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mt-4'>
                                            <div className='col-6'>
                                                <div className='cool2 cool2_veri'>
                                                    <Skeleton width={'100%'} height={'100%'} />
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <Skeleton width={'100%'} height={'100%'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-60 pb-50">
                                <div className="">
                                    <div className="welcome-content text-center ">
                                        {/* <p dangerouslySetInnerHTML={{ __html: desc ? desc : null }} /> */}
                                        <p style={{ fontWeight: 500, fontSize: 24, color: '#666666' }}>{desc}</p>
                                        {/* <p className='mt-4'>Rentla is an IT rental company that focuses on renting gadgets required for corporates, students, freelancers, and individuals who need IT support. We are promoting <br />renting gadgets to optimize the use of technological resources and provide a choice for the public when it comes to choosing their gadgets. We provide gadgets at <br />affordable costs along with maintenance, software upgradation, and doorstep delivery services.</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                }




                <Modal  show={showEnqModal} onHide={() => setShowEnqModal(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Enquiry</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="dropdownField1" className="">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your Name"
                                    onChange={(e) => handleInputChange(e, 'name')}
                                    isInvalid={validationErrors.name !== ''}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {validationErrors.name}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="dropdownField1" className="pt-2">
                                <Form.Label>Mobile</Form.Label>

                                <Form.Control type="number" required isInvalid={validationErrors.name !== ''} placeholder="Enter Number" onChange={(e) => handleInputChange(e, 'phoneNumber')} />
                                <Form.Control.Feedback type="invalid">
                                    {validationErrors.phoneNumber} {/* Error message for invalid input */}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="dropdownField1" className="pt-2">
                                <Form.Label>Mail</Form.Label>
                                <Form.Control type="mail" required
                                    isInvalid={validationErrors.name !== ''} placeholder="Enter Mail id" onChange={(e) => handleInputChange(e, 'email')} />
                                <Form.Control.Feedback type="invalid">
                                    {validationErrors.email} {/* Error message for invalid input */}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="dropdownField1" className="pt-2">
                                <Form.Label>Address</Form.Label>
                                <Form.Control type="textarea" placeholder="Address" onChange={(e) => handleInputChange(e, 'address')} />
                                <Form.Control.Feedback type="invalid">
                                    {/* Error message for invalid input */}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="dropdownField1" className="pt-2">
                                <Form.Label>Detail</Form.Label>
                                <Form.Control type="text" placeholder="Enter your queries" onChange={(e) => handleInputChange(e, 'detail')} />
                                <Form.Control.Feedback type="invalid">
                                    {/* Error message for invalid input */}
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* Repeat the same structure for other form fields */}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            style={{ backgroundColor: 'white', color: 'black', borderColor: '#FFD100' }}
                            onClick={() => setShowEnqModal(false)}
                        >
                            Close
                        </Button>
                        <Button
                            style={{ color: 'black', backgroundColor: '#FFD100', borderColor: '#FFD100' }}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* <Modal style={{ height: 'auto' }} show={thankModal} onHide={() => setThankModal(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Submited</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Thank You!</h5>
                        <p>We will reach you soon!</p>
                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal> */}

               
            </div>
            <ToastContainer />

        </>
    )
}