import React , { useState , useEffect } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import Windows_height_width from '../components/windows_height_width'
import arww from '../assets/img/aright.png'
import banner from '../assets/img/banner.png'


export default function Slider_data(props) {

  const { height, width } = Windows_height_width();

  const [ wi , setWi ] = useState(width-400)
  console.log('props',props)
  let dyt = () =>
  
      {
          if(width < 1440){
              setWi(width)
          }
      }

  useEffect(()=>{
    
      dyt()
  },[width])

  return (
    <div className={`slider-height-7 bg-glaucous d-flex align-items-center ${ props.sliderClass ? props. sliderClass : ""  }`} >
      <div 
      // className="container head_container"
      style={{width:'100%'}}
      >
        <div className="row align-items-center slider-h9-mrg">
          
          {/* <div className="col-lg-5 col-md-5 col-12 col-sm-6  col-xxl-5">
            <div className="slider-content-17 ">
              <p className=" headerpp">{props.data.BannerTitle}</p>
              <h1
                dangerouslySetInnerHTML={{ __html: props.data.BannerDescr }}
              />
              <div  className=" slider-btn-19">
                  <div className=' row fdxx'>
                    <div className='col-8 '>
                      <div className='fdssa '>Start Renting</div>
                    </div>
                    <div className='col-4 '>
                      <div className='fdddxx '>
                        <img className='imgfdd ' src={arww} />
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>*/}
          <div className="col-12">
            <div className="slider-singleimg-hm9 ">
              <img style={{    width: '-webkit-fill-available'}}
                className=" bounce"
                src={props.urls +'/'+ props.data.BannerImage}
                alt=""
              />
            </div>
          </div>
          {/* <img src={banner} /> */}
        </div>
      </div>
    
</div>
  )
}


{/* <div className="container">
        <div className="row align-items-center slider-h9-mrg">
            <div className="col-lg-6 col-md-6 col-12 col-sm-6">
                <div className="slider-content-7 slider-animated-1">
                    <h3 className="animate__animated">{props.data.title}</h3>
                    <h1 className="animated">New Design <br />Bluetooth Speaker</h1>
                    <div className="slider-btn-9 btn-hover">
                        <a className="animate__animated" href="shop.html">SHOP NOW</a>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12 col-sm-6">
                <div className="slider-singleimg-hm9 slider-animated-1">
                    <img className="animated" src={props.data.img} alt="" />
                </div>
            </div>
        </div>
    </div> */}