import React , { useState , useEffect} from 'react'
import './profile_home.css'
import Profile_home_nav from './profile_home_nav'
import axios from 'axios'
import { Base_Url } from '../../components/globalvariable/globe'
import { ToastContainer, toast } from 'react-toastify';
import ManageAddress from '../../components/address/address'
import { ImageUrl } from '../../components/globalvariable/globe'
import pro_img from '../../assets/img/pro_img.png'
import { useNavigate } from 'react-router-dom'
import ReactLoading from 'react-loading';

export default function Profile_home_comp() {

  let navigate = useNavigate()

  const errnotify = (dat) => toast.error( dat , {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const successnotify = (sus) => toast.success( sus , {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const [ cha , setCha ] = useState(0)

  const [ all , setAll ] = useState()

  let formData = new FormData(); 
  const [ kyctype , setkycType ] = useState('')

  const [ name , setName ] = useState('')
  const [ email , setEmail ] = useState('')
  const [ phone , setPhone ] = useState('')

  const [ load , setLoad ] = useState(false)

  const [ img , setImg ] = useState()
  const [ data , setData ] = useState('')

  let token = localStorage.getItem('user_data')

  const datass = [
    {
      "name" : "Ajay Bharadwaj",
      "address" : "1/65, North Well Street, Anna Main Road, Tamil Nadu, 629709."
    },
    {
      "name" : "Sundar",
      "address" : "1/65, North Well Street, Anna Main Road, Nagercoil, Tamil Nadu, 629709."
    }
  ]

  let alldata = async()=>{
    if(token === null){
      navigate('/')
      return
    }
    let responce = await axios({
      method : 'post',
      url : `${Base_Url}/viewProfile`,
      headers: { 
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((res)=>{
      setAll(res.data.Data.userDetails.UserImage)
      setName(res.data.Data.userDetails.UserFullName)
      setEmail(res.data.Data.userDetails.UserEmail)
      setPhone(res.data.Data.userDetails.UserPhone)
      setkycType(res.data.Data)
      localStorage.setItem('user_type', res.data.Data.type)
      localStorage.setItem('kyctype', res.data.Data.kyc)
      if(res.data.Data.type === 'corporate'){
        navigate('/corporate_profile')
      }
      console.log(res)
    })
  }

  useEffect(()=>{
    alldata()
  },[])

  const clicked = (data,key) =>{
    setCha(key)
  }

  const imgFile = (e) => { 

   
    setAll('')
    setImg({'img' : URL.createObjectURL(e.target.files[0])}) 
    
}

let Update = async()=>{

  setLoad(true)
  formData.append("name", name);
  let imagefile = document.querySelector('#file');
  formData.append("image", imagefile.files[0]);

  let responce = await axios.post( `${Base_Url}/editProfile`, formData, {
    headers: {
        'Authorization': `Bearer ${token}` ,
        'Content-Type': 'multipart/form-data'
    } 
})
.then((res)=>{
  setLoad(false)
  successnotify('Update Success')
  console.log(res)
  alldata()
})
.catch((err)=>{
  setLoad(false)
  successnotify('Update Failed')
})
} 
 
  return ( 
    <>
        <div className='pro_home_one mt-150'>
          <div className='row' >
            
            <Profile_home_nav />

            <div className='col-8'>
              <div className='pro_home_seven'>

                <div className='d-flex'>
                  <p className='pro_home_eight'>Personal Information</p>
                </div>
                <div className='d-flex justify-content-center'>
                  {
                    all  ? <img className={ 
                      kyctype?.kyc  === 1 ? 'ed_pro_one_web grrrasdfsfgsgs' : 
                      kyctype?.kyc  === 3 ? 'reddddsdfsfgsgs ed_pro_one_web' :   
                      kyctype?.kyc  === 0 ? 'zdfsdfsfdffesfgsgs ed_pro_one_web' : 
                      kyctype?.kyc  === 4 ? 'elsesdfsfgsgs ed_pro_one_web' : 'ed_pro_one_web'
                  } src={ImageUrl+'/'+all} /> : img != undefined  ? <img className='ed_pro_one_web' src={img.img} /> : ''
                  }
                </div>
                <div className='ed_pro_two_web_pro'>
                  <input onChange={(e)=>imgFile(e)} accept="image/*" name='file' className='kyc_cont_two_upload_threess' id='file' type="file" />
                  <p  className='ed_pro_three'>Edit Image</p>
                </div>
                    <div className='d-flex justify-content-end' >
                    <ManageAddress/>
                    </div>

                <div className='row mt-3'>
                  <div className='col-12'>

                    <label className='pro_home_ten' for="exampleInputEmail1">Name</label>
                    <input type="text" value={name} onChange={(e)=>setName(e.target.value)} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                  </div>

                </div>

                <div className='row mt-3'>
                  <div className='col-12'>

                  <label className='pro_home_ten' for="exampleInputEmail1">Email</label>
                    <input value={email} readOnly= {true} onChange={(e)=>setEmail(e.target.value)} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                  </div>

                  <div className='col-12 mt-3'>

                  <label className='pro_home_ten' for="exampleInputEmail1">Phone No</label>
                    <input value={phone} readOnly= {true} onChange={(e)=>setPhone(e.target.value)}  class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                  </div>
                  
                </div>
                <div className='row'>
                <div style={{textAlign:'end' , marginTop:'23px'}} className='col-6'>
                    
                    </div>
                  <div onClick={()=>Update()} className='col-6'>
                    <div style={{cursor : 'pointer'}} className='profile_bbbtn' >
                      {
                        load === false ? <p className='profile_bbbtn_text'>Update</p>

                        :
                    
                      <div className='d-flex justify-content-center'  style={{padding  : 14 }} >
                        <ReactLoading type='balls' color='#ffffff' />
                      </div>
                    }
                    </div>
                  </div>
                  
                </div>
                
                  
                {/* <div className='faq mt-4'>
                  <p className='pro_home_eleven'>What happens when I update my email address (or mobile number)?</p>
                  <p className='pro_home_twelve mt-2'>Your login email id (or mobile number) changes, likewise. You'll receive all your account related communication on your updated email address (or mobile number).</p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <ToastContainer  />
    </>
  )
}
