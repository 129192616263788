import React from 'react'
import Profile_home_nav from '../../../page/profile_home/profile_home_nav'
import Header from '../../../common/head_foot/header_two'
import Footer from '../../../common/footer'

export default function Shiping () {


  return (
    <>
    <Header />
        <div className='pro_home_one mt-150'>
          <div className='row' >
            
            <Profile_home_nav />

            <div className='col-8'>
            <div className='pro_home_fifteen'>
                <div className='d-flex'>
                  <p className='pro_home_eight'>SHIPPING POLICY</p>
                </div>
              </div>
              <div className='pro_home_seven mt-3'>
                <p><strong>BILLING AND SHIPPING POLICY</strong></p>
                <p  style={{fontSize :'14px' , fontWeight: '400' , marginTop : '20px'}}>
                RENTLA, accepts online payment, UPI or cash on delivery as well once the order is been processed, it will be shipped directly to your desired location as preferred by customer/lessee.<br /><br />
                RENTLA, features customers/lessee can assign/authorise anyone to collect your order and affix signatures as required by RENTLA on all equipments.<br /><br />
                Delivery orders made on RENTLA will be shipped out or dispatched within 24 Hours before delivery date required by customer/lessee.<br /><br />
                Customers/lessee can take delivery or return the product either by RENTLA or by themselves on all business working days (i.e., Monday to Saturday between 9 AM to 7 PM).
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
    </>
  )
}
