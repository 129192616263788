import React from 'react'
import Mob_address from '../../components/mobile_view_comp/address/mob_address'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'

export default function Address() {
  return (
    <>
        <div style={{width:'90%' , margin:'auto' ,marginTop:'65px'}}>
            <Cart_one_head link='/cart_one' head='All Address' />
            <Mob_address />
        </div>
    </>
  )
}
