import React, { useEffect, useState } from 'react'
import All_detail from '../components/all_detail'
import Header_two from '../common/head_foot/header_two'
import Aditional_info from '../components/aditional_info'
import Footer from '../common/footer'
import ProductDetail from '../components/productDetail'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { oneProduct } from '../redux/actions/productAction'
import { useParams } from 'react-router-dom'
import { Base_Url } from '../components/globalvariable/globe'
import { Helmet } from "react-helmet";
import seodata from "./packsdetaildescription.json";



export default function Product_details() {


  const dispatch = useDispatch()

  const { id } = useParams()
  const [item, setItem] = useState({})
  // console.log(item, "seo-item")
  // const item = seodata[id];

  if (item.ProSeoTitle == null) {
    var titles = 'Best IT Product on Rent Services';
  }
  if (item.ProSeoKeywords == null) {

    var description = 'Best IT Product on Rent Services';

  }
  if (item.ProSeoDescription == null) {

    var keywords = 'Best IT Product on Rent Services';
  } else {
    var titles = item.ProSeoTitle;
    var description = item.ProSeoKeywords;
    var keywords = item.ProSeoDescription;
  }

  const bodyParameters = {
    key: "value"
  };

  const clikk = async () => {
    let responce = await axios({
      method: 'post',
      url: `${Base_Url}/selectProduct`,
      bodyParameters,
      data: {
        proId: id
      }
    })
    console.log(responce.data.Data, 'datattatat')
    dispatch(oneProduct(responce.data.Data))
  }
  const key = { proId: id }

  const getSeoItem = async () => {

    let res = await axios.post(`${Base_Url}/product_seo_keys`, key)
      .then((res) => {
        console.log(res.data, "ress")
        setItem(res.data.proseo)
      }).catch((err) => {
        console.log("error", err)
      })

  }
  useEffect(() => {
    clikk()
  }, [])


  useEffect(() => {
    getSeoItem()
  }, [id])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{titles}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      {titles}ss
      <Header_two />
      <All_detail />
      {/* <Aditional_info /> */}
      {/* <ProductDetail /> */}
      <Footer />
    </>
  )
}