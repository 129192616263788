import React from 'react'
import kyc1 from '../../../assets/img/kyc1.png'

export default function Arrow_cart() {
  return (
    <>
    {/* <img style={{ width:'100%', margin:'auto' , display:'block' }} src={kyc1} /> */}
      <div className='row'>
        <div className='col-3'>
            <div className='boc1112'>
                <p className='po'>Cart</p>
            </div>
        </div>
        <div className='col-3'>
            <div className='boc1114'>
                <p className='po4'>Payment</p>
            </div>
        </div>
        <div className='col-3'>
            <div className='boc111'>
                <p className='po2'>Kyc</p>
            </div>
        </div>
        <div className='col-3'>
            <div className='boc1113'>
                <p className='po3'>Approval</p>
            </div>
        </div>
        
      </div>
    </>
  )
}