import React from 'react'
// import Search_mobile from '../../components/mobile_view_comp/search_mobile'
import Mobile_head from './common/mobile_head'
import Mobile_foot from './common/mobile_foot'
import Search_comp from '../../components/mobile_view_comp/search_page/search_comp'
import Recent_and_trending from '../../components/mobile_view_comp/search_page/recent_and_trending'
import Heading_comp from '../../components/mobile_view_comp/heading_comp'
import Mbl_product_groub from '../../components/mobile_view_comp/mbl_product_groub'
import Three_row from '../../components/mobile_view_comp/three_row'

export default function Search() {
  return (
    <>
      <div style={{width:'90%' , margin:'auto' , paddingBottom:'100px' , paddingTop:'65px'}}>
        {/* <Search_mobile /> */}
        <Mobile_head />
        <Search_comp />
        {/* <Recent_and_trending /> */}
        {/* <Heading_comp text='Categories'/> */}
        {/* <Mbl_product_groub /> */}
        <Mobile_foot />
      </div>
    </>
  )
}