import React , { useState } from 'react'
import './mob_login.css'
import comp_ty from '../../../assets/img/comp_ty.png'
import four_mem from '../../../assets/img/four_mem.png'
import aright from '../../../assets/img/aright.png'
import { Link } from 'react-router-dom'

export default function Mob_login_one() {
    const [ datas , setDates ] = useState(0)
    const [page,setPage] = useState('/login_two')
    const two_data = [
        {
            "head" : "Individual",
            "img" : comp_ty
        },
        {
            "head" : "Company",
            "img" : four_mem
        }
    ]
    const cli = (data,key) =>{
        setDates(key)
        if(key === 0){
            setPage('/login_two')
            // setPage('/corporate_profile')
        }else{
            setPage('/corporate_login')
        }
    }
  return (
    <>
        <div className='mob_login_container'>
            <div className='mob_login_container_two '>
                <p className='mob_login_para_one'>You are a ?</p>
                <div className='row'>

                {two_data.map((data,key)=>
                    <div onClick={()=>cli(data,key)} className='col-6'>
                        <div className={(datas===key ? 'mob_login_container_three' : 'mob_login_container_four')}>
                            <img className='mob_log_img' src={data.img} />
                            <p className='mob_log_p_two'>{data.head}</p>
                        </div>
                    </div>
                )}
                </div>
            </div>
            <Link to={page} >
                <div className='mob_login_container_five mt-5'>
                    <div className='row'>
                        <div className='col-7 col_padd'>
                            <p className='mob_log_p_four'>Continue</p>
                        </div>
                        <div className='col-5 col_padd'>
                            <img className='mob_log_img_one' src={aright} />
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    </>
  )
}