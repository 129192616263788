import React from 'react'
import Profile_home_nav from '../../../page/profile_home/profile_home_nav'
import Header from '../../../common/head_foot/header_two'
import Footer from '../../../common/footer'

export default function Refund_return () {


  return (
    <>
    <Header />
        <div className='pro_home_one mt-150'>
          <div className='row' >
            
            <Profile_home_nav />

            <div className='col-8'>
            <div className='pro_home_fifteen'>
                <div className='d-flex'>
                  <p className='pro_home_eight'>Refund Cancellation and Return/Handover Policy</p>
                </div>
              </div>
              <div className='pro_home_seven mt-3'>
                <p><strong>REFUND POLICY</strong></p>
                <p  style={{fontSize :'14px' , fontWeight: '400' , marginTop : '20px'}}>
                All refunds will be processed through online transfer or receipt of cash only as preferred by customer/lessee. No same day cash or payment will be accommodated and refunds will be processed within 3 days of product being returned in good working condition<br/><br />
                Refunds will be only for deposit amount or any case of non-fulfilment of rental order as applicable.<br /><br />
                RENTLA, reserves right to deduct certain amount from the security deposit made, if any damage has been caused to the rented products.
                </p>
                <p className='mt-5'><strong>RETURN/HANDOVER POLICY</strong></p>
                <p  style={{fontSize :'14px' , fontWeight: '400' , marginTop : '20px'}}>
                The rental period can be extended strictly based upon availability of products and services and extension period charges may vary depend upon products rented, the payment of rent shall be made in advance or on returning the product being leased by customer/lessee.<br /><br />
                It is affirmed by the customer/lessee Rented equipments(laptop, computer or others) will be returned to lessor (RENTLA) after cessation of rental period as set forth initially by customer/lessee.<br /> <br />
                The Customer/lessee is completely responsibility for the safe custody of the computer or laptop and other accessories that being rented shall be returned in a good condition received at the time of delivery and product rented should be returned back to RENTLA in person after termination of end period or shall request RENTLA to pickup in the location preferred by customer.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
    </>
  )
}
