import React from 'react'
import Mobile_foot from './common/mobile_foot'
import Mob_view_pro from '../../components/mobile_view_comp/mobile_profile/mob_view_pro'
import { background } from '@chakra-ui/react'

export default function Mob_profile() {
  return (
    <>
    <div style={{backgroundColor:'#E5E5E5', width:'100%'}} >
      <Mob_view_pro />
      <div style={{ width:'90%' , margin:'auto' , paddingBottom:'100px'}}>
        <Mobile_foot />
       
      </div>
    </div>
    </>
  )
}