import React, { useState , useEffect , useRef } from 'react'
import '../assets/css/product.css'
import { useSelector , useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'; 
import { selectedRemoveProduct , set_Product_Update , Wishcount  } from '../redux/actions/productAction';
import axios from 'axios';
import { Base_Url , newURL } from './globalvariable/globe';
import Skeleton from 'react-loading-skeleton' 
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import leftar from '../assets/img/leftar.png' 
import rightar from '../assets/img/rightar.png'

export default function ProductDetail() { 

    let dispatch = useDispatch()
    let user = localStorage.getItem('user_data')
    let wishss = localStorage.getItem('wish_list')
    
    const carousel = useRef(null);
    const carousels = useRef(null);

    const responsive = {
        0: { items: 1 }, 
        868: { items: 2},
        968: { items: 2},
        1100: { items: 4},
        1300: { items: 5},
        1321: { items: 5 },
        1400 : {items: 5},
        1600 : {items: 5},
        1850: { items: 5 },
    };

    const [ proo , setProo ] = useState()

    let loadPages = [ 1 , 2 , 3 , 4 , 5 , 6 , 7 , 8 , 9 , 10 , 11 , 12 ];

    const product =  useSelector((state)=>state)
    const datas = product.allProducts.products.pro
    const trending = product.allProducts.products.trending
    let d = product.allProducts.products.pro

    const [ rams , setRams ] = useState(0)
    const [ heart , setHeart ] = useState(true)
    const [ wish ,  setWish ] = useState()
    const   [onees , setOnees ] = useState(true)

    useEffect(()=>{
        wishss = localStorage.getItem('wish_list')
        setWish(JSON.parse(wishss))
    },[])

    const [countss  , setCountss ]= useState(1)

    useEffect(()=>{
        console.log('adkldfjkbsdfbwejkbksdfjbbjkjk')
        wishss = localStorage.getItem('wish_list')
        setWish(JSON.parse(wishss))
    },[countss])

    useEffect(()=>{
        console.log('adkldfjkbsdfbwejkbksdfjbbjkjk',product)
    },[product])

    const clickeds = (datas,key, data) => {
        console.log(key,'keyyyy')
        if(key === 0){
            console.log(onees,'onee')
            setOnees(true)
        }else{
            setOnees(false)
        }
        
        setProo(data.ProId)
        console.log(data.ProId)
        console.log(key)
        setRams(key)
    }

    const Clickheart = ( data , key ) => {
        
        setCountss(countss +1)
        let allata = localStorage.getItem('wish_list')
        let another = JSON.parse(allata)
        let final = 0

        let wish_data = [
            {
                datas : data
            }
        ]

        if(localStorage.getItem('wish_list')){
            another.forEach((element, index) => {
                console.log(index,'index')
                if( element.datas.ProId === wish_data[0].datas.ProId){
                    let allanother = another
                    allanother.splice(index , 1)
                    localStorage.setItem('wish_list' , JSON.stringify(allanother))
                    dispatch(Wishcount(allanother))
                    final = 1
                }
            })
            console.log(final)
            if( final === 0 ){
                console.log('susssssss')
                let allanother =  another.concat(wish_data)
                localStorage.setItem('wish_list' , JSON.stringify(allanother))
                dispatch(Wishcount(allanother))
            }

        }else{
            localStorage.setItem('wish_list' , JSON.stringify(wish_data))
            dispatch(Wishcount(wish_data))
        }

        
        // let wish_list = localStorage.getItem('wish_list')

        // const All = async () => {
        //     let responce = await axios({
        //         method : 'post',
        //         url : `${Base_Url}/wishlist`,
        //         headers: { 
        //             'Authorization': `Bearer ${user}` 
        //         },
        //         data : {
        //             proId : data.ProId
        //         }
        //     })
        //     .then ((res)=>{
        //     })
        // }

        // All()

    }

    const items =  datas && datas.map((data,key)=>
    <div  onClick={() => carousel?.current?.slideTo(key)} class="col-md-6 col-lg-4 col-xl-4 col-sm-6 col-xxl-3 home_pro_cont"  key={key}  style={{height:'330px'}}>
        <div class="product-grids" >
            <div className='pro'>
                <div className="product-image">
                    <Link to={'detail/'+ data.ProName
                        .toLowerCase()
                        .trim()
                        .replace(/[^\w\s-]/g, '')
                        .replace(/[\s_-]+/g, '-')
                        .replace(/^-+|-+$/g, '') +'/'+data.ProId}>
                            <div className="image">
                                <img style={{padding:20}} className="pic-1 onepice" src={product.allProducts.products.awsimageurl+'/'+data.ProImgPrimary} />
                            </div>
                    </Link>
                        <div className="product-like-icon">
                            {(() => {
                                if(wish != null){
                                var result = wish.filter(x => x.datas.ProId == data.ProId);
                            if(result.length > 0){
                            return ( <i onClick={()=>Clickheart(data,key)} className='fa fa-heart hrt_red_clr' > </i>)
                                }else{
                                    return ( <i onClick={()=>Clickheart(data,key)} className='fa fa-heart-o' > </i>)
                                }
                            }
                            else{
                                return ( <i onClick={()=>Clickheart(data,key)} className='fa fa-heart-o' > </i>)
                            }
                            })()}
                        </div>
                        
                </div>
                <div className="product-content">
                    <div className="row lit">
                        <div  className="col">
                            <h5 class="title">{data.ProName.split(' ').slice(0,3).join(' ') }</h5>
                        </div>
                    </div>
                </div>
        
        </div>
    </div>
</div>,
  ) 


  const itemss =  trending && trending.map((data,key)=>
  <div  onClick={() => carousels?.current?.slideTo(key)} class="col-md-6 col-lg-4 col-xl-4 col-sm-6 col-xxl-3 home_pro_cont"  key={key}  style={{height:'330px'}}>
      <div class="product-grids" >
      <div className='pro'>
          <div className="product-image">
          <Link to={'/detail/'+ data.ProName
              .toLowerCase()
              .trim()
              .replace(/[^\w\s-]/g, '')
              .replace(/[\s_-]+/g, '-')
              .replace(/^-+|-+$/g, '') +'/'+data.ProId}>
              <div className="image">
                  <img style={{padding:20}} className="pic-1 onepice" src={product.allProducts.products.awsimageurl+'/'+data.ProImgPrimary} />
              </div>
          </Link>
              <div className="product-like-icon">
              {(() => {
                  if(wish != null){
                  var result = wish.filter(x => x.datas.ProId == data.ProId);
              if(result.length > 0){
                 return ( <i onClick={()=>Clickheart(data,key)} className='fa fa-heart hrt_red_clr' > </i>)
                  }else{
                      return ( <i onClick={()=>Clickheart(data,key)} className='fa fa-heart-o' > </i>)
                  }
              }
              else{
                  return ( <i onClick={()=>Clickheart(data,key)} className='fa fa-heart-o' > </i>)
              }
              })()}
              </div>
                  
              </div>

      <div >
          <div className="product-content">
          <div className="row lit">
              <div  className="col">
                  <h5 class="title">{data.ProName.split(' ').slice(0,3).join(' ') }</h5>
              </div>
          </div>
          </div>
          </div>
      </div>
  </div>
</div>,
) 

            
            
  return (
    <> 
     <div className='container web_cat_five'>
      <div className='row mt-2'>
            <div>
                {
                    datas?.length === 0 || datas === undefined  ? '' :  <h1 className='pop_head_tit'>Popular Products</h1>
                }
               
            </div>
            <div className='thumbs'>
                <AliceCarousel key="carousel" ref={carousel} disableButtonsControls={true}  mouseTracking items={items} infinite = {true}  disableDotsControls={true}  responsive={responsive} />
                <div className="btn-prev" onClick={(e) => carousel?.current?.slidePrev(e)} ><img src={leftar} /></div>
                <div className="btn-next" onClick={(e) => carousel?.current?.slideNext(e)}><img src={rightar} /></div>
            </div>
            <div>
                {
                    trending?.length === 0 || trending === undefined  ? '' :  <h1 className='pop_head_tit'>Trending Products</h1>
                }
               
            </div>
            <div className='thumbs'>
                <AliceCarousel key="carousels" ref={carousels} disableButtonsControls={true} mouseTracking items={itemss} infinite = {true}  disableDotsControls={true}  responsive={responsive} />
                <div className="btn-prev" onClick={(e) => carousels?.current?.slidePrev(e)} ><img src={leftar} /></div>
                <div className="btn-next" onClick={(e) => carousels?.current?.slideNext(e)}><img src={rightar} /></div>
            </div>
            
        </div>
        <div className='row mt-2'>
            {
                !datas && loadPages.map ((data)=>{
                    return(
                        <div  className="col-md-6 col-lg-4 col-xl-4 col-sm-6 col-xxl-3 home_pro_cont" style={{marginTop:'40px' }}>
                            <div class="product-grid" >
                                <Skeleton height={300} />
                            </div>
                        </div>
                    )
                })
                
            }
        </div>
    </div> 
</>
  )
}

    