import React , { useEffect } from 'react'
import Swiper from "react-id-swiper";
import Slider_data from './slider_data';
import { useSelector , useDispatch } from "react-redux";
import axios from "axios";
import { setProducts } from "../redux/actions/productAction";
import { Base_Url } from '../components/globalvariable/globe';
import Skeleton from 'react-loading-skeleton'

export default function Slider_mob() {

  const product =  useSelector((state)=>state)
  const datas = product.allProducts.products.banner
  console.log('dfre',datas)

  let loadPages = [ 1 ];

  const dispatch = useDispatch()

const bodyParameters = {
   key: "value"
};

    const fectch_data = async() =>{
        const response = await axios.post(`${Base_Url}/homePageWeb`, bodyParameters)
        .catch((err)=>{
          console.log(err)
        })
        dispatch(setProducts(response.data.Data))
    }


    const params = {
        effect: "fade",
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        }, 
        watchSlidesVisibility: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
        ,
        renderPrevButton: () => (
          <button style={{display:'none'}} className="swiper-button-prev ht-swiper-button-nav">
            <i className="pe-7s-angle-left" />
          </button>
        ),
        renderNextButton: () => (
          <button style={{display:'none'}} className="swiper-button-next ht-swiper-button-nav">
            <i className="pe-7s-angle-right" />
          </button>
        ),
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true
        }
      };

  useEffect(()=>{
    fectch_data()
  },[])

  return (
    <>
    {
      datas ?
        <div style={{marginTop:'30px'}} className="slider-area" >
          <div  className="slider-active owl-carousel nav-style-1">
              <Swiper {...params}>
                  {datas?.map((data , key)=>{
                    return( <Slider_data data={data} urls={product.allProducts.products.awsimageurl} key={key} sliderClass="swiper-slide"/>)
              })}
              </Swiper>
          </div>
      </div>
      :
      loadPages.map(()=>{
        return( 
          <div className="container mt-5">
            <div className="row align-items-center slider-h9-mrg">
              <div className="col-12"> 
                <Skeleton height={'200px'} />
              </div>
            </div>
          </div>
        )
      })
    }
      
    </>
  )
}
