import React from 'react'
import './tik.css'
import des3 from '../../../assets/img/des3.png'

export default function Home_ticket_comp() {
  return (
    <div className='pro_home_one mt-150 mb-4'>
        <div className='row mt-4'>
            <div className='col-12'>
                <div className='pro_home_fifteen'>
                    <div className='d-flex'>
                        <p className='pro_home_eight'>Coupons</p>
                    </div>
                </div>
                <div className='pro_home_seven mt-3'>

                    <div className='my-4'>
                        <div className='mob_couon_card'>
                            <div className='row'>
                                <div className='col-8'>
                                    <input type="email" className="form-control kyc_in_onessss" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Coupon Code" />
                                </div>
                                <div className='col-4'>
                                    <div className='coupon_box_twos'>
                                        <p className='coupon_p_one'>Verify</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className='cop_ho_one'>Available coupons</p>
                    <div className='row mt-3'>
                        <div className='col-4'>
                            <img src={des3} />
                        </div>
                        <div className='col-4'>
                            <img src={des3} />
                        </div>
                        <div className='col-4'>
                            <img src={des3} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
