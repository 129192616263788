import React from 'react'
import Handover_req_comp from '../../components/mobile_view_comp/handover_req_comp/handover_req_comp'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'
import Handover_req_footer from '../../components/mobile_view_comp/handover_req_comp/handover_req_footer'

export default function Handover_req() {
  return (
    <>
        <div style={{width:'90%' , margin:'auto' , paddingBottom:'100px',marginTop:'65px'}}>
            <Cart_one_head link='/order' head='handover Request' />
            <Handover_req_comp />
            {/* <Handover_req_footer /> */}
        </div>
    </>
  )
}