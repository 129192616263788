import React, { useEffect, useState } from "react";
import Header_two from "../common/head_foot/header_two";
import Collection_detail from "../components/collection_detail";
import { Helmet } from "react-helmet";
import seodata from "./packsdescription.json";
import { useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import { Base_Url } from "../components/globalvariable/globe";



export default function Collection() {
    const { id } = useParams()
    // const item = seodata[id];
    const [item, setItem] = useState({})
    console.log(item, "itt")

    if (item.InventoryCatSeoTitle == null) {
        var titles = 'Best IT Product on Rent Services';

    }
    if (item.InventoryCatSeoDescription == null) {

        var description = 'Best IT Product on Rent Services';

    }
    if (item.InventoryCatSeoKeyword == null) {


        var keywords = 'Best IT Product on Rent Services';
    } else {
        var titles = item.InventoryCatSeoTitle;
        var description = item.InventoryCatSeoDescription;
        var keywords = item.InventoryCatSeoKeyword;
    }
    const key = { catId: id }

    const getSeoItem = async () => {

        await axios.post(`${Base_Url}/category_seo_keys`, key)
            .then((res) => {
                console.log(res.data, "ress")
                setItem(res.data.catseo)
            }).catch((err) => {
                console.log("error", err)
            })

    }

    useEffect(() => {
        getSeoItem()
    }, [id])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{titles}</title>
                <meta name="description" content={description} data-react-helmet="true" />
                <meta name="keywords" content={keywords} data-react-helmet="true" />
            </Helmet>
            <Header_two />
            <Collection_detail />
        </>
    )
}