import React , { useState , useEffect } from 'react'
import { Link , useNavigate } from 'react-router-dom'
import clkk from '../../../assets/img/clkk.png'
import eye_one from '../../../assets/img/eye_one.png'
import eye_close from '../../../assets/img/eye_close.png'
import file from '../../../assets/img/file.png'
import delect from '../../../assets/img/delect.png'
import doc from '../../../assets/img/doc.png'
import x from '../../../assets/img/x.png'
import stamp from '../../../assets/img/stamp.png'
import Cart_one_head from '../cart_one/cart_one_head'
import plus_1 from '../../../assets/img/plus_1.png'
import newww from '../../../assets/img/newww.png'
import edit_ed from '../../../assets/img/edit_ed.png'
import ReactLoading from 'react-loading';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter, 
    ModalBody,
    useDisclosure,
    Button,
    ChakraProvider,
    ModalCloseButton
  } from '@chakra-ui/react'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Base_Url } from '../../globalvariable/globe';
import { faL } from '@fortawesome/free-solid-svg-icons'

export default function Company_profile() {

  let change = /^\d{10}$/;
  let emailveri = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [ compname , setCompname ] = useState('')
  const [ gts , setGts ] = useState('')
  const [ comppername , setComppername ] = useState('')
  const [ phoneno , setPhoneno ] = useState('')
  const [ email , setEmail ] = useState('')
  const [ pass , setPass ]  = useState('')
  const [ loading , setLoading ] = useState(false)
  const [ address , setAddress ] = useState()
  const [ contact , setContact ] = useState()

  const [ img , setImg ] = useState()
  const [ data , setData ] = useState('')

  const [ showimg , setShowimg ] = useState(false)


  const [ userimg  , setUserimg ] = useState()
  const [ userimgshow  , setUserimgshow ] = useState(false)
  const [ userimgapi  , setUserimgapi ] = useState(false)


  const [ vericompname , setVeriCompname ] = useState(false)
  const [ verigts , setVeriGts ] = useState(false)
  const [ dataimg , setdataimg ] = useState(false)
  const [ close , setClose ] = useState(false)
  const [ veriemail , setVeriEmail ] = useState(false)

  let token = localStorage.getItem('user_data')

  let navigate = useNavigate()

  const errnotify = (dat) => toast.error( dat , {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });


    let start =async () =>{
      if(token === null){
        navigate('/')
        return
      }
        let responce = await axios({ 
            method : 'post',
            url : `${Base_Url}/editCorporateProfile`,
            headers: { 
              'Authorization': `Bearer ${localStorage.getItem('user_data')}` 
            }
        })
        .then((res)=>{
          console.log(res.data)
          if(res.data.status === true ){
            setLoading(false)
            setCompname(res.data.Data.userDetails.UserCompanyName)
            setGts(res.data.Data.userDetails.UserGstNo)
            setPhoneno(res.data.Data.userDetails.UserPhone)
            setEmail(res.data.Data.userDetails.UserEmail)
            setAddress(res.data.Data.addresss)
            setContact(res.data.Data.contact)
            if (res.data.Data.userDetails.UserDocument === '' ){
                setShowimg(false)
                setdataimg(false)
            }else{
                setShowimg(true)
                setdataimg(true)
                setImg({'img' : res.data.Data.awsbaseurl+'/'+ res.data.Data.userDetails.UserDocument})
            }

            if (res.data.Data.userDetails.UserImage === '' ){
                setUserimgshow(false)
                setUserimgapi(false)
            }else{
                setUserimgshow(true)
                setUserimgapi(true)
                setUserimg({'img' : res.data.Data.awsbaseurl+'/'+ res.data.Data.userDetails.UserImage})
            }


          }else{
            setLoading(false)
            errnotify(res.data.Data)
            return
          }
        })
        .catch((err)=>{
            setLoading(false)
          console.log(err)
          return
          let er = 'Something Went Wrong'
          errnotify(er)
          console.log(err)
        })
      
    }

    useEffect(()=>{
        start()
    },[])

  const onImage = (e) => {
    setShowimg({[e.target.name] : URL.createObjectURL(e.target.files[0])})
  };

  const clicked = () => {

   if( compname === ''){
    errnotify('Enter Company Name')
    return
   }

   if ( gts === ''){
    errnotify('Enter gts')
    return
   }

   if( phoneno === '' )
   {
    errnotify('Enter Phone Number')
    return
   }

   if( phoneno.length < 10 ){
    errnotify('Enter Valid Phone Number')
    return
   }

   if( email === ''){
    errnotify('Enter Email')
            return
   }

   let phonevali = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if(phonevali.test(email)){

        }else{
          errnotify('Enter Valid Email')
          return
        }
        setLoading(true)
      var formData = new FormData();
      var imagefile = document.querySelector('#file');
      var imagefile2 = document.querySelector('#files');
      formData.append("document", (showimg === true && dataimg === false) ?  imagefile.files[0] : '' );
      formData.append("userImage", (userimgshow === true && userimgapi === false) ?  imagefile2.files[0] : '' );
      formData.append("companyName", compname);
      formData.append("gstNo", gts);
      formData.append("phone", phoneno);
      formData.append("email", email);
      formData.append("password", pass);

    const sendData = async() =>{
      let responce = await axios.post(`${Base_Url}/editCorporateProfileAction`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${localStorage.getItem('user_data')}` 
          } ,
      })
      .then((res)=>{
        if(res.data.status === true ){
          setLoading(false)
          start()
          onOpen()
        }else{
            setLoading(false)
          errnotify(res.data.message)
          return
        }
      })
      .catch((err)=>{
        setLoading(false)
        console.log(err)
        return
        let er = 'Something Went Wrong'
        errnotify(er)
        console.log(err)
      })
    }

    sendData()

  }

  const handleCapture = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const newUrl = URL.createObjectURL(file);
        setData('')
        setShowimg(true)
        setdataimg(false)
        setImg({'img' : newUrl})
      }
    }
  }


  const handleCaptures = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const newUrl = URL.createObjectURL(file);
        setUserimgshow(true)
        setUserimgapi(false)
        setUserimg({'img' : newUrl})
      }
    }
  }

  const remove = () =>{
    document.querySelector('#file').value = "";
      // setData(backimage)
      setData('')
      setImg()
      setdataimg(false)
      setShowimg(false)
  }

  return (
    <>
    <div style={{width:'90%' , margin:'auto' ,marginTop:'65px'}} >
     <Cart_one_head link='/' head='Profile' />
      <div className='mt-4'>
          <div>
            <div >
                <div className='kyc_cont'>
                    {
                        userimgshow ? <img style={{ width : 150 , height : 150 }} className='kyc_img_one' src={userimg.img} alt="img"/> : ''
                    }
                <div class="kyc_centered">
                    <div className=''>
                        <div className='d-flex justify-content-around'>
                            <div className='mt-2'>
                                <img style={{ width : 40 , height : 40 , marginTop : -45 , marginLeft : 110 , borderRadius : 10}}  className='kyc_img_one' src={edit_ed} />
                                <input onChange={(e)=>handleCaptures(e.target)} accept="image/*" name='file' className='kyc_cont_two_upload_three' id='files' type="file" />
                            </div>
                            {/* <div className='mt-2 kyc_line'>
                            </div>
                            <div onClick={()=>remove()} className=' mt-2'>
                                <img src={delect} />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <p className='kyc_two_para'>Company Name</p>
            <input type="text" onChange={(e)=>{
                let letters = /^[1-9][0-9]*$/;
                let last = ''
                if(e.target.value.length >=2) {
                    last = e.target.value.split('').pop()
                }else{
                    last = e.target.value
                }
                if(last.match(letters)){

                }else{
                    setCompname(e.target.value)
                }
            }} value={compname} className={(vericompname ? 'form-control kyc_in_one inputttt_red' : 'form-control kyc_in_one')} id="exampleInputEmail1" aria-describedby="emailHelp"  />
          </div>
          <div className='mt-3'>
            <p className='kyc_two_para'>GTS No</p>
            <input type="text" onChange={(e)=> setGts(e.target.value) } value={gts} className={(verigts ? 'form-control kyc_in_one inputttt_red' : 'form-control kyc_in_one')} id="exampleInputEmail1" aria-describedby="emailHelp"  />
          </div>
          {/* <div className='mt-3'>
            <p className='kyc_two_para'>Contact Person Name</p>
            <input type="text" onChange={(e)=>{
                let letters = /^[1-9][0-9]*$/;
                let last = ''
                if(e.target.value.length >=2) {
                    last = e.target.value.split('').pop()
                }else{
                    last = e.target.value
                }
                if(last.match(letters)){

                }else{
                    setComppername(e.target.value)
                }
            }} value={comppername} className={(vericomppername ? 'form-control kyc_in_one inputttt_red' : 'form-control kyc_in_one')}id="exampleInputEmail1" aria-describedby="emailHelp"  />
          </div> */}
          <p className='kyc_two_para mt-3'>Phone Number</p>
                    <div className='lo_tw_one'>
                        <div className='d-flex'>
                            <div >
                                <p style={{ fontSize : 15 }} className='lo_tw_two'>+91</p>
                            </div>
                            <div>
                                <input disabled onChange={(e)=>setPhoneno(e.target.value.replace(/[^0-9]/g, "").slice(0,10))}  value={phoneno}  class="form-control lo_tw_three" id="exampleInputEmail1" aria-describedby="emailHelp"
                                  // placeholder="99945 99945"
                                  style={{ marginLeft : -9 , paddingLeft : 0 }}
                                />
                            </div>
                        </div>
                    </div>
          <div className='mt-3'>
            <p className='kyc_two_para'>E-mail</p>
            <input type="email" disabled onChange={(e)=>setEmail(e.target.value)} value={email} className={( veriemail ? 'form-control kyc_in_one inputttt_red' : 'form-control kyc_in_one' )} id="exampleInputEmail1" aria-describedby="emailHelp" />
          </div>
          <ChakraProvider >
                <Button onClick={()=>{navigate('/password_change')}} bg={'#F4B644'} color={'white'} width={'100%'} mt={5} >
                    Change Password
                </Button>
                
          </ChakraProvider>
        </div>
        <div className='lo_tw_thirteen my-4'>
        </div>

        {
          contact?.length === 0 ?
            '' : <p style={{ color :'#3A3C3F' , fontSize : 13 , fontWeight : '500' }}>Contacts</p>
        }
        

        {
            contact?.map(( dat , key ) =>{
                return(
                    <div style={{ width : '100%' , height : 'auto' , backgroundColor : '#fff' , border :'1px solid #F4B644' , borderRadius : 6 , padding : '10px 8px' , marginBottom : 10 }} >
                        <div className='d-flex justify-content-between' >
                            <p style={{ color : '#F4B644' , fontSize : 14 , fontWeight : '600' }} >Contact</p>
                            <p style={{ color : '#F4B644' , fontSize : 10 , fontWeight : '500' }} >{dat.UserContactIsPrimary === 1 ? 'Primary' : ''}</p>
                        </div>
                        <p style={{ color : '#000' , fontSize : 13 , fontWeight : '600' }} >{dat.UserContactName}</p>
                        <p style={{ color : '#000' , fontSize : 11 , fontWeight : '400' }} >{dat.UserContactEmail}</p>
                        <div className='d-flex justify-content-between' >
                            <div>
                                <p style={{ color : '#000' , fontSize : 11 , fontWeight : '400' }} >{dat.UserContactMobile}</p>
                            </div>
                            <div className='d-flex align-items-end flex-column' >
                                <div onClick={()=>{ navigate(`/edit_corporate_contact/contact/${ dat.UserContactId }`)}} style={{ width : 41 , height : 24 , boxShadow : '0px 2px 5px rgba(0, 0, 0, 0.1)' , backgroundColor : '#fff' , borderRadius : 3 , padding : 2 , textAlign : 'center' }} >
                                    <p style={{ color : '#F4B644' , fontSize : 12 , fontWeight : '500' }} >Edit</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }

       {
        address?.length === 0 ? "" : <p style={{ color :'#3A3C3F' , fontSize : 13 , fontWeight : '500' , marginTop : 20 }}>Address</p>
       }

        {
          address?.map((dat, key)=>{
            return(
              <div style={{ width : '100%' , height : 'auto' , backgroundColor : '#fff' , border :'1px solid #F4B644' , borderRadius : 6 , padding : '10px 8px' , marginBottom : 10 }} >
                <div className='d-flex justify-content-between' >
                    <p style={{ color : '#F4B644' , fontSize : 14 , fontWeight : '600' }} >Address</p>
                    <p style={{ color : '#F4B644' , fontSize : 10 , fontWeight : '500' }} >{dat.UserContactAddressIsPrimary === 1 ? 'Primary' : ''}</p>
                </div>
                <p style={{ color : '#000' , fontSize : 13 , fontWeight : '600' }} >{ dat.UserContactName }</p>
                <div className='d-flex justify-content-between' >
                    <div>
                        <p style={{ color : '#000' , fontSize : 11 , fontWeight : '400' }} >{dat.UserContactAddress}</p>
                    </div>
                    <div className='d-flex align-items-end flex-column' >
                        <div onClick={()=>{ navigate(`/edit_corporate_contact/address/${ dat.UserContactId }`)}} style={{ width : 41 , height : 24 , boxShadow : '0px 2px 5px rgba(0, 0, 0, 0.1)' , backgroundColor : '#fff' , borderRadius : 3 , padding : 2 , textAlign : 'center' }} >
                            <p style={{ color : '#F4B644' , fontSize : 12 , fontWeight : '500' }} >Edit</p>
                        </div>
                    </div>
                </div>
            </div>
            )
          })
        }
       

        <div className='mt-3' onClick={()=>{ navigate('/corporate_contact/1')}} style={{ border : '0.77847px solid #161E40' , padding : 8 , width : '100%' , height : 'auto' , borderRadius : 6 , cursor : 'pointer' }} >
            <div className='d-flex justify-content-center'  >
                <img style={{ width : 20 , height : 20 }} src={plus_1} />
                <p style={{ color : '#161E40' , fontSize : 13 , fontWeight : '500' }} >Add a new address or new contact</p>
            </div>
        </div>

        <div className='lo_tw_thirteen my-4'>
        </div>

        <div className='mt-3'>
          <p className='kyc_p_four'>GST Certificate (Optional)</p>
          <div className='kyc_cont'>
          {
            showimg ? <img className='kyc_img_one' src={img.img} alt="img"/> : <img style={{ opacity : .4 }} className='kyc_img_one' src={doc} alt="img"/>
          }
            <div class="kyc_centered">
                <div className='kyc_cont_two'>
                    <div className='d-flex justify-content-around'>
                        <div className='mt-2'>
                            <img  className='kyc_img_one' src={file} />
                            <input onChange={(e)=>handleCapture(e.target)} accept="image/*" name='file' className='kyc_cont_two_upload_three' id='file' type="file" />
                        </div>
                        {/* <div className='mt-2 kyc_line'>
                        </div>
                        <div onClick={()=>remove()} className=' mt-2'>
                            <img src={delect} />
                        </div> */}
                    </div>
                </div>
            </div>
          </div>
      </div>
      <div className='mb-3' >
        <ChakraProvider >
            <div onClick={()=>clicked()}  style={{cursor : 'pointer'}} className='profile_bbbtn' >
            {
                loading === true ?  
                <div className='d-flex justify-content-center'  style={{padding  : 14 }} >
                  <ReactLoading className='hjkmhkhjk' type='balls' color='#ffffff' />
                </div>
                : <p className='profile_bbbtn_text'>Save</p>
            }
            </div>
        </ChakraProvider>
        </div>
      <ChakraProvider>

      <Modal isCentered={true} isOpen={isOpen} size={'xs'} onClose={onClose}>

                <ModalOverlay />

                  <ModalContent>
                  <ModalCloseButton />
                      <ModalBody>
                        <img style={{margin:'auto' , paddingTop:'10px' , width : 250 , height : 250 }} src={newww} />
                        <div style={{ margin : 'auto' }}  >
                        <Button  width={'100%'} onClick={()=>onClose()} >
                          Profile Updated
                        </Button>
                        </div>
                        
                      </ModalBody>
                  </ModalContent>

              </Modal>

      </ChakraProvider>


      <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
                {/* Same as */}
        <ToastContainer />
        </div>
    </>
  )
}