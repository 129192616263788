import React, { useState, useEffect } from 'react'
import './mob_payment.css'
import { Link, useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import newww from '../../../assets/img/newww.png'
import { Base_Url, ImageUrl } from '../../globalvariable/globe'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    ChakraProvider,
    useDisclosure,
    Button
} from '@chakra-ui/react'
import ReactLoading from 'react-loading';
import { Tick } from 'react-crude-animated-tick';

export default function Mob_payment() {

    const { ordid } = useParams()

    let user = localStorage.getItem('user_data')

    let navigate = useNavigate()

    const [all, setAll] = useState()
    const [coupon, setCoupon] = useState()
    const [couponid, setCouponid] = useState()
    const [coupontype, setCoupontype] = useState()
    const [couponmax, setCouponmax] = useState()
    const [fullamount, setFullamount] = useState()
    const [discou, setDiscou] = useState()

    const [check1, setCheck1] = useState(false)
    const [check2, setCheck2] = useState(false)
    const [check11, setCheck11] = useState(true)
    const [check22, setCheck22] = useState(true)

    const { isOpen, onOpen, onClose } = useDisclosure()

    const [loading, setLoading] = useState(false)

    const [modelss, setModelss] = useState(0)

    const [radio, setRadio] = useState('upi')

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
                console.log('susss')
            };
            script.onerror = () => {
                resolve(false);
                console.log('fail')
            };
            document.body.appendChild(script);
        });
    };

    const clickedsus = (res,kycKey) => {

        let test = async () => {
            const options = {
                key: "rzp_live_RCb1GAspqmMdgc",
                currency: res.Data.paymentDetails.currency,
                amount: res.Data.paymentDetails.amount * 100,
                name: res.Data.name,
                description: "Wallet Transaction",
                order_id: res.Data.paymentDetails.id,
                image: res.Data.image,
                handler: async function (response) {

                    console.log(response)

                    if (response) {
                        if (kycKey == 1) {
                            console.log(1, "111")
                            navigate('/kyc_one')
                        } else if (kycKey == 2) {
                            console.log(2, "111")
                            navigate('/kyc_two')
                        } else if (kycKey == 3) {
                            console.log(3)

                            navigate('/kyc_three')
                        } else if (kycKey == 4) {
                            navigate('/kyc_four')
                        } else if (kycKey == 5) {
                            navigate('/kyc_five')
                        }
                        else {
                            setModelss(1)
                            onOpen()
                        }

                    }
                },
                prefill: {
                    name: res.Data.paymentDetails.notes.userName,
                    email: res.Data.paymentDetails.notes.userEmail,
                    contact: res.Data.paymentDetails.notes.userPhone,
                },
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        }

        test()
        setLoading(false)
    }

    const data = async () => {
        if (user === null) {
            navigate('/')
            return
        }
        let responcs = await axios({
            method: 'post',
            url: `${Base_Url}/viewPaymentPage`,
            headers: {
                'Authorization': `Bearer ${user}`
            },
            data: {
                orderId: ordid
            }
        })
            .then((res) => {
                console.log(res.data, 'sfgvsfvsd')
                if (res.data.paymentStatus === 1) {
                    navigate('/')
                    return
                }
                setAll(res.data.Data)
                setCoupon(res.data.Data.coupon)
                setFullamount(res.data.Data.paymentDetails.PaymentOrderTotalAmount)
                setDiscou(res.data.Data.paymentDetails.PaymentOrderDiscount)
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    navigate('/login')
                }
                console.log(err)
            })
    }

    useEffect(() => {
        data()
        loadScript("https://checkout.razorpay.com/v1/checkout.js");
    }, [])

    const clicked = () => {
        onOpen()
    }

    const selected = (data, key) => {

        setCouponid(data.CouponValue)
        setCoupontype(data.CouponValueType)
        setCouponmax(data.CouponMaxAmnt)
        if (data.CouponValueType === "percentage") {
            let fll = fullamount / data.CouponValue
            console.log(fll)
            if (data.CouponMaxAmnt < fll) {
                setDiscou(data.CouponMaxAmnt)
                setFullamount(fullamount - data.CouponMaxAmnt)
            } else {
                setDiscou(fll)
                setFullamount(fullamount - fll)
            }
        } else {
            setDiscou(data.CouponValue)
            setFullamount(fullamount - data.CouponValue)
        }
        onClose()
    }

    let payment = async () => {
        setLoading(true)
        if (check1 === false) {
            setCheck11(false)
        } else {
            setCheck11(true)
        }
        if (check2 === false) {
            setCheck22(false)
        } else {
            setCheck22(true)
        }
        if (check1 === false || check2 === false) {
            setLoading(false)
            return
        } else {
            setCheck11(true)
            setCheck22(true)
        }

        let responce = await axios({
            method: 'post',
            url: `${Base_Url}/payNow`,
            headers: {
                'Authorization': `Bearer ${user}`
            },
            data: {
                orderId: ordid,
                cod: radio === 'upi' ? 0 : 1
            }
        })
            .then((res) => {
                if (radio === 'cod' && res.data.status === true) {
                    setLoading(false)
                    setModelss(1)
                    onOpen()
                    return
                }
                if (radio === 'upi' && res.data.status === true) {
                    setLoading(false)
                    clickedsus(res.data, res.data.KycType)
                } else {
                    setLoading(false)
                }
                if (res.data.KycType === 1) {
                    navigate('/kyc_one')
                } else if (res.data.KycType === 2) {
                    navigate('/kyc_two')
                } else if (res.data.KycType === 3) {
                    navigate('/kyc_three')
                } else if (res.data.KycType === 4) {
                    navigate('/kyc_four')
                } else if (res.data.KycType === 5) {
                    navigate('/kyc_five')
                }
                console.log(res)

            })
            .catch((err) => {
                setLoading(false)
                console.log(err, 'err')
                // if(err?.response.status === 401){
                //     navigate('/login')
                // }

            })
    }

    let checked = (e) => {
        setCheck1(!check1)
    }

    let checked2 = (e) => {
        setCheck2(!check2)
    }

    const Returnorder = () => {
        navigate('/order')
        onClose()
    }

    return (
        <>
            <div className='mt-4'>
                {/* <Link to='/coupon/1' > */}
                <div onClick={() => clicked()} className='mob_payment'>
                    <p className='Mob_pay_p_one'>Apply Coupon Code</p>
                </div>
                {/* </Link> */}
            </div>
            <div >
                <div className='d-flex justify-content-between' >
                    <p className='mb-2 mt-3' style={{ fontWeight: '600', fontSize: 14, color: '#000' }} >Payment Options</p>
                    <p className='mb-2 mt-3' style={{ fontWeight: '500', fontSize: 12, color: '#000' }}>Payment Id : {all?.orderId}</p>
                </div>
                <div class="form-check">
                    <input checked={radio === 'upi' ? 'checked' : ''} class="form-check-input" type="radio" value='upi' onChange={(e) => { setRadio(e.target.value) }} name="flexRadioDefault" id="flexRadioDefault1" />
                    <label style={{ fontWeight: '500', fontSize: 12, color: '#000' }} class="form-check-label" for="flexRadioDefault1">
                        Online
                    </label>
                </div>
                <div class="form-check">
                    <input checked={radio === 'cod' ? 'checked' : ''} class="form-check-input" type="radio" value='cod' onChange={(e) => { setRadio(e.target.value) }} name="flexRadioDefault" id="flexRadioDefault2" />
                    <label style={{ fontWeight: '500', fontSize: 12, color: '#000' }} class="form-check-label" for="flexRadioDefault2">
                        Cash on Delivery
                    </label>
                </div>
            </div>
            <p className='my-3 Mob_pay_p_two'>Price Details</p>
            <div className='row'>

                <div className='col-8'>
                    <p className='Mob_pay_p_three'>Refundable Deposit</p>
                </div>
                <div className='col-1'>
                    <p className='Mob_pay_p_three'>:</p>
                </div>
                <div className='col-3'>
                    <p className='Mob_pay_p_threes'>₹ {all?.paymentDetails?.depositAmount} /-</p>
                </div>

                <div className='col-8'>
                    <p className='Mob_pay_p_three'>Rental Price</p>
                </div>
                <div className='col-1'>
                    <p className='Mob_pay_p_three'>:</p>
                </div>
                <div className='col-3'>
                    <p className='Mob_pay_p_threes'>₹ {all?.paymentDetails?.PaymentOrderAmount} /-</p>
                </div>

                <div className='col-8'>
                    <p className='Mob_pay_p_three'>Tax</p>
                </div>
                <div className='col-1'>
                    <p className='Mob_pay_p_three'>:</p>
                </div>
                <div className='col-3'>
                    <p className='Mob_pay_p_threes'>₹ {all?.paymentDetails?.PaymentOrderServiceTax} /-</p>
                </div>

                <div className='col-8'>
                    <p className='Mob_pay_p_three'>Gst</p>
                </div>
                <div className='col-1'>
                    <p className='Mob_pay_p_three'>:</p>
                </div>
                <div className='col-3'>
                    <p className='Mob_pay_p_threes'>₹ {all?.paymentDetails?.PaymentOrderGst} /-</p>
                </div>

                {/* <div className='col-8'>
                <p className='Mob_pay_p_three'>Amount</p>
            </div>
            <div className='col-1'>
                <p className='Mob_pay_p_three'>:</p>
            </div>
            <div className='col-3'>
                <p className='Mob_pay_p_threes'>10000/-</p>
            </div> */}

                <div className='col-8'>
                    <p className='Mob_pay_p_three'>Discout</p>
                </div>
                <div className='col-1'>
                    <p className='Mob_pay_p_three'>:</p>
                </div>
                <div className='col-3'>
                    <p className='Mob_pay_p_threes'>₹ {discou ? discou : '0'} /-</p>
                </div>

                <div className='col-8'>
                    <p className='Mob_pay_p_three'>Delivery Charge</p>
                </div>
                <div className='col-1'>
                    <p className='Mob_pay_p_three'>:</p>
                </div>
                <div className='col-3'>
                    <p className='Mob_pay_p_threes'>₹ {all?.paymentDetails?.PaymentOrderDeliveryCharge} /-</p>
                </div>

            </div>
            <div className='mob_pay_line my-3'></div>
            <div className='row'>
                <div className='col-8'>
                    <p className='Mob_pay_p_two'>Amount Payable</p>
                </div>
                <div className='col-4'>
                    <p className='mob_pay_tot'>₹ {fullamount ? fullamount : ''} /-</p>
                </div>
            </div>
            <div className='my-5'>
                <div className='row'>
                    <div className='col-1'>
                        <input onChange={(e) => checked(e)} className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                    </div>
                    <div className='col-11'>
                        <p style={{ cursor: 'pointer' }} className='mob_pay_check'>I agree with <span style={{ fontWeight: '700' }} onClick={() => { navigate('/terms') }}>terms & condition</span></p>
                        {
                            check11 === false ? <p style={{ color: 'red', fontSize: '8px' }}>Please confirm T&C</p> : ''
                        }
                    </div>
                </div>
                <div className='row'>
                    <div className='col-1'>
                        <input onChange={(e) => checked2(e)} className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                    </div>
                    <div className='col-11'>
                        <p style={{ cursor: 'pointer' }} className='mob_pay_check'>I agree with <span style={{ fontWeight: '700' }} onClick={() => { navigate('/damage_policy') }}>Damage policy</span></p>
                        {
                            check22 === false ? <p style={{ color: 'red', fontSize: '8px' }}>Please Agree with Damage policy</p> : ''
                        }
                    </div>
                </div>
                <div className='mob_footsss'>
                    <div className='row'>
                        <div className='col-6'>
                            <div className='d-flex'>
                                <div className='headset'>
                                </div>
                                <div>
                                    <p className='pay_foot_ones'>Grand Total</p>
                                    <p className='pay_foot_twos'>₹ {fullamount ? fullamount : ''} /-</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='bbtn'>
                                <div onClick={() => payment()} style={{ cursor: 'pointer' }} className='d-flex'>
                                    <p style={{ cursor: 'pointer' }} className='compara nextonee mt-1'>{loading ?
                                        <div style={{ marginLeft: '-47px', marginTop: '-4px' }}>
                                            <ReactLoading type='balls' color='#ffffff' />
                                        </div>
                                        : 'Pay Now'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChakraProvider>
                <Modal
                    isCentered isOpen={isOpen} onClose={modelss === 1 ? '' : onClose}>
                    <ModalOverlay
                        bg='blackAlpha.300'
                        backdropFilter='blur(10px) hue-rotate(90deg)'
                    />
                    {
                        modelss === 1 ? <ModalContent>
                            <ModalBody>
                                <div className='row'>
                                    <div className=''>
                                        <img src={newww} style={{ width: 250, height: 250, margin: 'auto' }} />
                                        <p style={{ fontSize: '15px', fontWeight: '600', textAlign: 'center' }} >Your Order Confirmed</p>
                                    </div>
                                    {/* <Link to='/order'>
                                        <p style={{ textAlign: 'center', color : '#0d6efd', fontWeight : '600' }}>Return To Order Page</p>
                                    </Link> */}
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button colorScheme='blue' mr={3} m={'auto'} onClick={Returnorder}>
                                    Go to Order Page
                                </Button>
                            </ModalFooter>
                        </ModalContent> : <ModalContent>
                            <ModalHeader>All Coupon</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <p>Select Your Coupon</p>
                                <div className='row'>
                                    {
                                        coupon?.map((data, key) =>
                                            <div onClick={() => selected(data, key)} className='col-6'>
                                                <img src={ImageUrl + '/' + data.CouponImage} />
                                                {/* <p style={{textAlign:'center',marginTop:'-40px'}}>{data.CouponName}</p> */}
                                            </div>
                                        )
                                    }

                                </div>
                            </ModalBody>
                            <ModalFooter>
                                {/* <Button>Select</Button> */}
                            </ModalFooter>
                        </ModalContent>
                    }

                </Modal>
            </ChakraProvider>
        </>
    )
}