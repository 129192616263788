import React , {useState} from 'react'
import OTPInput from "otp-input-react";
import aright from '../../../assets/img/aright.png'
import { Link } from 'react-router-dom';
import { Tick } from 'react-crude-animated-tick';

export default function Mob_otp_two() {
    const [OTP, setOTP] = useState("");
  return (
    <>
      <div>
            <div>
                <Tick size={200} />
            </div> 
          <div className='timerr'>
            <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="String" disabled={false}  />
          </div>
          <Link to='/reg'>
            <div className='mob_login_container_five'>
                <div className='d-flex justify-content-center'>
                    <p className='ti_p_four'>Next</p>
                    <img className='ti_p_five' src={aright} />
                </div>
            </div>
          </Link>
      </div>
    </>
  )
}