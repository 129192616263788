import React from 'react'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head' 
import Circle_Three from '../../components/mobile_view_comp/kyc_three/circle_three'
import Mob_kyc_three from '../../components/mobile_view_comp/kyc_three/mob_kyc_three' 

export default function Kyc_three() {
  return ( 
    <>
      <div style={{width:'90%' , margin:'auto' ,marginTop:'65px'}}>
        <Cart_one_head link='/kyc_two'  head='Kyc' />
        
        {/* <Circle_Three /> */}
        <Mob_kyc_three />
      </div>
    </>
  )
}