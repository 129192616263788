import React from 'react'
import Renew_order_comp from '../../components/mobile_view_comp/renew_order_comp/renew_order_comp'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'
import Renew_footer from '../../components/mobile_view_comp/renew_order_comp/renew_footer'

export default function Renew_order() {
  return (
    <>
        <div style={{width:'90%' , margin:'auto' ,marginTop:'65px' , marginBottom:'60px'}}>
            <Cart_one_head link='/order' head='Cart' />
            <Renew_order_comp />
            {/* <Renew_footer  link='/payment/0' /> */}
        </div>
    </>
  )
}