import React, { useEffect , useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Base_Url } from '../../globalvariable/globe'
import Profile_home_nav from '../../../page/profile_home/profile_home_nav'
import Header from '../../../common/head_foot/header_two'
import Footer from '../../../common/footer'
import Html from 'html-react-parser'
import './profile_home.css'
import ReactLoading from 'react-loading';

export default function Contact_us() {

  const [ name , setName ] = useState('')
  const [ email , setEmail ] = useState('')
  const [ phone , setPhone ] = useState('')

  const [ load , setLoad ] = useState(false)

  const [ er , setEr ] = useState(false)
  const [ sus , setSus ] = useState(false)
  
  const [ erdata , setErdata ] = useState('')

  const iframe = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6537.419931849425!2d80.20840008612247!3d13.024643338778503!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52671de2a98557%3A0x723363b47b56079e!2srentla.in!5e0!3m2!1sen!2sin!4v1667453284425!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'; 
  let navigate = useNavigate()

  const clicked = ()=>{
    navigate(-1)
  }

  const submit = async () =>{
    setLoad(true)
    setSus(false)
    setEr(false)
    let responce  = await axios({
      method : 'post',
      url : `${Base_Url}/contactUs`,
      data:{
        name : name ,
        email : email ,
        phone : phone
      }
    })
    .then((res)=>{
      
      if(res.data.status === true){
        setSus(true)
        
        setName('')
        setEmail('')
        setPhone('')
      }else{
        
        setSus(false)
        setEr(true)
        setErdata(res.data.Error_Message)
      }
      setLoad(false)
    })
    .catch((err)=>{
      setSus(false)
      
      setEr(true)
      setErdata('Something Went Wrong')
      
      setLoad(false)
    })
  }


  return (
    <>
    <Header />
        <div className='pro_home_one mt-150'>
          <div className='row' >
            
            <Profile_home_nav />

            <div className='col-8'>
            <div className='pro_home_fifteen'>
                <div className='d-flex'>
                  <p className='pro_home_eight'>Contact Us</p>
                </div>
              </div>
              <div className='pro_home_seven mt-3'>
              <p className='pro_home_eight'>Address</p>
                <div className='row mt-3'>
                    
                    <div className='col-6'>
                      <p>Rentla pvt ltd,</p>
                        <p>No 1/2, Janakiraman st,</p>
                        <p>83rd St, Muthurangam Block,</p>
                        <p>West Jafferkhanpet,</p>
                        <p>Chennai,Tamil Nadu 600083.</p>
                        
                    </div>
                    <div className='col-6'>
                    <p>Phone : 9940428882</p>
                      <p>Email : support@rentla.in</p>
                    
                    </div>
                </div>

                <div style={{marginTop : '50px' , marginBottom:'50px' }}>{Html(iframe)}</div>

                <p className='pro_home_eight'>Contact Us</p>

              <div className='row mt-3'>
                  <div className='col-6'>

                    <label className='pro_home_ten' for="exampleInputEmail1">Name</label>
                    <input type="text" value={name} onChange={(e)=>setName(e.target.value)} class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                  </div>
                  
                  <div className='col-6'>

                  <label className='pro_home_ten' for="exampleInputEmail1">Phone No</label>
                    <input value={phone}  onChange={(e)=>setPhone(e.target.value.replace(/[^0-9]/g, "").slice(0,10))} type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                  </div>

                </div>

                <div className='row mt-3'>
                  <div className='col-12'>

                  <label className='pro_home_ten' for="exampleInputEmail1">Email</label>
                    <input value={email}  onChange={(e)=>setEmail(e.target.value)} type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                  </div>

                  
                </div>
                <div className='row'>
                <div style={{textAlign:'end' , marginTop:'23px'}} className='col-6'>
                    
                    </div>
                  <div onClick={()=>submit()} className='col-6'>
                    <div style={{cursor : 'pointer'}} className='profile_bbbtn' >
                    {
                        load === true ? <div className='' style={{paddingTop :17 , paddingLeft : 90}}><ReactLoading type='balls' color='#ffffff' /> </div>
                        :<p className='profile_bbbtn_text'>Submit</p>
                    }
                    </div>
                  </div>
                  
                </div>

                <div className='row'>
                  <div style={{textAlign:'end' , marginTop:'23px'}} className='col-6'>
                    
                  </div>
                  <div className='col-6'>
                    {
                      er === true ? <p style={{fontSize : 14 ,  fontWeight : 500 , color : 'red' , textAlign : 'center' }}>{erdata}</p> : ''
                    }
                    {
                      sus === true ? <p style={{fontSize : 14 ,  fontWeight : 500 , color : 'green' , textAlign : 'center' }}>Updated</p> : ''
                    }
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
    </>
  )
}
