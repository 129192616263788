import React , { useState , useEffect } from 'react'
import pro_img from '../../../assets/img/pro_img.png'
import { Link , useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Base_Url, ImageUrl } from '../../globalvariable/globe'
import { ToastContainer, toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import { faL } from '@fortawesome/free-solid-svg-icons'

export default function Edit_profile_comp() {

  let navigate = useNavigate()

  const successnotify = (sus) => toast.success( sus , {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });

  const [ cha , setCha ] = useState(0)

  const [ all , setAll ] = useState()

  let formData = new FormData(); 

  const [ name , setName ] = useState('')
  const [ email , setEmail ] = useState('')
  const [ phone , setPhone ] = useState('')

  const [ img , setImg ] = useState()
  const [ data , setData ] = useState(false)

  let token = localStorage.getItem('user_data')

  let alldata = async()=>{
    if (token === null){ 
      navigate('/')
    }
    let responce = await axios({
      method : 'post',
      url : `${Base_Url}/viewProfile`,
      headers: { 
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((res)=>{
      setAll(res.data.Data.userDetails.UserImage)
      setName(res.data.Data.userDetails.UserFullName)
      setEmail(res.data.Data.userDetails.UserEmail)
      setPhone(res.data.Data.userDetails.UserPhone)
      console.log(res)
    })
  }

  useEffect(()=>{
    alldata()
  },[])

  const clicked = (data,key) =>{
    setCha(key)
  }

  const imgFile = (e) => { 
    setAll('')
    setImg({'img' : URL.createObjectURL(e.target.files[0])}) 
    
}

let Update = async()=>{
  setData(true)
  let imagefile = document.querySelector('#file');
  formData.append("image", imagefile.files[0]);
  formData.append("name", name);
  // formData.append("email", email);
  // formData.append("phone", phone);


  let responce = await axios.post( `${Base_Url}/editProfile`, formData, {
    headers: {
        'Authorization': `Bearer ${token}` ,
        'Content-Type': 'multipart/form-data'
    } 
})
.then((res)=>{
  setData(false)
  console.log(res)
  alldata()
  successnotify('Update Success')
})
.catch((err)=>{
  setData(false)
})
}


// let addtess = async ()=>{
//   let responce = await axios({
//     method : 'post',
//     url : `${Base_Url}/viewOrderProductDetails` ,
//     headers: { 
//         'Authorization': `Bearer ${user}` 
//     },
//     data : {
//       orderId : id
//     }
// })
// .then((res)=>{
//     if(res.data.status === true){
//         dispatch(ViewCart(res.data.Data))
//         // console.log(res)
//     }else{

//     }
// })
// .catch((err) => {
//   if(err.response.status === 401){
//     navigate('/login')
//   }
// })
// }

  return (
    <>
      <div>
        <div className='d-flex justify-content-center'>
          
          {
             all  ? <img className='ed_pro_one' src={ImageUrl+'/'+all} /> : img  ? <img className='ed_pro_one' src={img.img} /> : ''
          }
          
        </div>
        <div className='ed_pro_two'>
          <input onChange={(e)=>imgFile(e)} accept="image/*" name='file' className='kyc_cont_two_upload_threess' id='file' type="file" />
          <p  className='ed_pro_three'>Edit Image</p>
        </div>
          
        
          <div className='mt-4'>
          <div>
            <p className='kyc_two_para'>Full Name</p>
            <input onChange={(e)=>setName(e.target.value)} value={name} type="email" className="form-control kyc_in_one" id="exampleInputEmail1" aria-describedby="emailHelp" />
          </div>
          <div className='mt-3'>
            <p className='kyc_two_para'>E mail</p>
            <input readOnly= {true} onChange={(e)=>e.target.value} value={email} type="email" className="form-control kyc_in_one" id="exampleInputEmail1" aria-describedby="emailHelp" />
          </div>
          <div className='mt-3'>
            <p className='kyc_two_para'>Phone Number</p>
            <input readOnly= {true} onChange={(e)=>e.target.value} value={phone} type="number" className="form-control kyc_in_one" id="exampleInputEmail1" aria-describedby="emailHelp" />
          </div>
        </div>
        <Link to='/address'>
          <p style={{textAlign : 'center' , marginTop:'30px' , color : '#F4B644'}}>Manage Address</p>
        </Link>
        <div onClick={()=>Update()} className='ed_pro_four'>
            {
              data === true ? <div className='loas_login_lll' style={{paddingTop :5}}><ReactLoading type='balls' color='#ffffff' /> </div> : <p style={{cursor:'pointer'}} className='ed_pro_five'>Update</p>
              
            }
              
          </div>
        
        {/* <div className='ed_pro_six mt-4'>
          <p className='ed_pro_sevens'>Complete your  KYC</p>
        </div> */}
        {/* <p className='ed_pro_seven mt-5'>Address</p> */}

        {/* {datass.map((data,key)=>
        <div onClick={()=>clicked(data,key)} className={(cha===key ? 'ed_pro_enght mt-4' : 'ed_pro_thirteen mt-4')}>
          <div className='row'>
            <div className='col-8'>
              <p className='ed_pro_nine'>{data.name}</p>
              <p className='ed_pro_ten'>{data.address}</p>
            </div>
            <div className='col-4'>
              <div className='ed_pro_eleven'>
                <p className='ed_pro_twelve'>Edit</p>
              </div>
            </div>
          </div>
        </div>
        )}
        <Link to='/new_address' >
        <div className='ed_pro_fourteen mt-4'>
            <div className='d-flex justify-content-center mt-2'>
              <img className='ed_pro_sixteen' src={plus_1} />
              <p className='ed_pro_fifteen'>Add a new address</p>
            </div>
        </div>
        </Link>
      <div className='ed_pro_seventeen mt-4'>
          <p className='ed_pro_eighteen'>Save Changes</p>
      </div> */}
       {/* { alladdress?.map((data,key)=>
        <div onClick={()=>switch_data(data,key)} className={(sw===key? 'containneerr' : 'containneerrr')}>
            <div className='row p-2'>
            <div className='col-10'>
                <p className='rob_obe'>{data.UserDelivAddrFName}</p>
                <p className='rob_two'>{data.UserDelivAddrAddress}</p>
            </div>
            <div style={{margin:'auto'}} className='col-2'>
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={(sw===key ? 'checked' : '')} />
            </div>
            </div>
        </div>
    )} */}

      <div className=' mt-4'>

      </div>
      </div>
      <ToastContainer  />
    </>
  )
}