import React, { useState, useEffect } from 'react'
import Arrow_cart from '../../mobile_view_comp/cart_one/arrow_cart'
import './home_kyc.css'
import Kyc from '../../../assets/img/kyc.png'
import file from '../../../assets/img/file.png'
import delect from '../../../assets/img/delect.png'
import headset from '../../../assets/img/headset.png'
import { useNavigate } from 'react-router-dom'
import Profile_home_nav from '../../../page/profile_home/profile_home_nav'
import axios from 'axios'
import { Base_Url, ImageUrl } from '../../globalvariable/globe'
import ReactLoading from 'react-loading';
import { ToastContainer, toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux'
import { LoogIn } from '../../../redux/actions/productAction'

export default function Home_kyc_one_comp() {

  let dispatch = useDispatch()

  let navigate = useNavigate()
  let formData = new FormData();

  const [img, setImg] = useState()
  const [data, setData] = useState('')
  const [alldata, allsetData] = useState('')

  const user = localStorage.getItem('user_data')
  const [loading, setLoading] = useState(false)
  const [suskyc, setSuskyc] = useState(false)
  const [pendkyc, setPendkyc] = useState(false)
  const [rejkyc, setRejkyc] = useState(false)
  const [kycstatus, setKcstatus] = useState()
  const [good, setGood] = useState(false)
  let backimage = '';
  let buthide = 0;

  const errnotify = () => toast.error('Upload Image', {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  useEffect(() => {
    setLoading(false)
    setSuskyc(false)
    Alldata()
  }, [])

  const Alldata = async () => {
    if (user === null) {
      navigate('/')
      return
    }

    let responce = await axios({
      method: 'post',
      url: `${Base_Url}/kycUserImage`,
      headers: {
        'Authorization': `Bearer ${user}`
      }
    })
      .then((res) => {
        allsetData(res.data)
        setKcstatus(res.data.Data.KycApproval)
        if (res.data.Data.KycApproval === 1) {
          setSuskyc(true)
        }
        else if (res.data.Data.KycApproval === 0) {
          setPendkyc(true)
        } else if (res.data.Data.KycApproval === 2) {
          setRejkyc(true)
        }
        if (res.data.status === true) {
          setData(res.data.Data.KycImage)
          backimage = res.data.Data.KycImage

        } else {
          setPendkyc(true)

        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate('/login')
        }
      })
  }

  const remove = () => {
    document.querySelector('#file').value = "";
    // setData(backimage)
    setData('')
    setImg()
    setGood(true)
  }

  const Clicked = async () => {
    setLoading(true)


    let imagefile = document.querySelector('#file');

    if (imagefile == null) {
      errnotify()
      setLoading(false)
      return
    }

    formData.append("userImg", imagefile.files[0]);
    if (imagefile.files[0] == undefined) {
      errnotify()
      setLoading(false)
      return
    }

    //   if(good === true){
    //     setLoading(false)
    //     errnotify()
    //     return
    // }


    // if(data != ''){
    //     navigate('/kyc_two')
    //     setLoading(false)
    //     return
    // }
    let responce = await axios.post(`${Base_Url}/kycUserImage`, formData, {
      headers: {
        'Authorization': `Bearer ${user}`,
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((res) => {
        navigate('/kyc_two')
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        if (err.response.status === 401) {
          navigate('/login')
        }
        console.log(err)
      })
  }

  const [source, setSource] = useState("");

  const imgFile = (e) => {
    setData('')
    setImg({ 'img': URL.createObjectURL(e.target.files[0]) })
  }

  const Clickedone = () => {
    navigate('/kyc_two')
  }

  const gotosupport = () => {
    navigate('/support')
  }

  return (
    <>
      <div className='pro_home_one mt-150'>
        <div className='row' >
          <Profile_home_nav /> 
          <div className='col-8'>
            <div className='pro_home_seven'>
              <div className=' mb-4'>
                {
                  kycstatus === 1 ? '' : kycstatus === 0 ? <Arrow_cart /> : alldata?.status === false ?  <Arrow_cart />  :  kycstatus === 2 ? <Arrow_cart />  : ''
                }
                <div className='row mt-2'>
                  <div className='col-12'>
                    <div className='pro_home_fifteen'>
                      <div className='d-flex'>
                        <p className='pro_home_eight'>Kyc</p>
                      </div>
                    </div>
                    <div className='pro_home_seven'>
                      <div className=''>
                        <div className='row'>
                          <div className='col-2'>
                            {
                              alldata?.status === false ? 
                              <div class="donut-ring">
                                <div className={kycstatus === 1 ? "ring red" : "ring red"}></div>
                                <div className={kycstatus === 1 ? "ring blue" : "ring blue"}></div>
                                <div className={kycstatus === 1 ? "ring orange" : "ring orange"}></div>
                                <div className={kycstatus === 1 ? "ring grrreeeennn4" : "ring green"}></div>
                                <div className="cutout">
                                  <p className='kyc_p_one'>1 Of 4</p>
                                </div>
                              </div>
                              : kycstatus === 1 ? 
                                <div class="donut-ring">
                                  <div className={kycstatus === 1 ? "ring red" : "ring red"}></div>
                                  <div className={kycstatus === 1 ? "ring blue" : "ring blue"}></div>
                                  <div className={kycstatus === 1 ? "ring orange" : "ring orange"}></div>
                                  <div className={kycstatus === 1 ? "ring grrreeeennn4" : "ring green"}></div>
                                  <div className="cutout">
                                    <p className='kyc_p_one'>1 Of 4</p>
                                  </div>
                                </div>
                              :
                               kycstatus === 0 ? 
                                <div class="donut-ring">
                                  <div className={kycstatus === 1 ? "ring red" : "ring red"}></div>
                                  <div className={kycstatus === 1 ? "ring blue" : "ring blue"}></div>
                                  <div className={kycstatus === 1 ? "ring orange" : "ring orange"}></div>
                                  <div className={kycstatus === 1 ? "ring grrreeeennn4" : "ring yellowonw"}></div>
                                  <div className="cutout">
                                    <p className='kyc_p_one'>1 Of 4</p>
                                  </div>
                                </div>
                              :
                              kycstatus === 2 ? 
                              <div class="donut-ring">
                                <div className="ring red"></div>
                                <div className="ring blue"></div>
                                <div className="ring orange"></div>
                                <div className= "ring redones"></div>
                                <div className="cutout">
                                  <p className='kyc_p_one'>1 Of 4</p>
                                </div>
                              </div>
                            :
                            ''

                            }
                            
                          </div>
                          <div className='col-3'>
                            <p style={{ marginLeft: '-17px' }} className='kyc_p_two'>Step 1</p>
                            <p className='kyc_p_threes'>Selfie</p>
                          </div>
                          <div style={{ textAlign: 'right' }} className='col-7'>
                            {
                              kycstatus === 0 ? <p style={{ color: '#cfcf00', fontSize: '12', fontWeight: '600' }} >Pending</p> : kycstatus === 1 ? <p style={{ color: 'green', fontSize: '12', fontWeight: '600' }} >Approved</p> : kycstatus === 2 ? <p style={{ color: 'red', fontSize: '12', fontWeight: '600' }} >Rejected</p> : ''
                            }
                          </div>
                        </div>
                      </div>

                      <div className='mt-3'>


                        <div className='kyc_cont'>

                          {
                            data ? <img className='kyc_img_one' src={ImageUrl + '/' + data} /> : img ? <img className='kyc_img_one' src={img.img} /> : 
                            alldata?.status === false ? <img className='kyc_img_one ' src={Kyc} /> : kycstatus === 2 ? <img className='kyc_img_one ' src={Kyc} /> : ''
                          }

                          {(() => {

                            if ((suskyc == true)) {
                              let buthide = 1;
                            } else if ((pendkyc == true) && (data != '')) {
                              let buthide = 1;
                            } else if ((pendkyc == true) && (data == '')) {
                              let buthide = 0;
                            } else if ((rejkyc == true)) {
                              let buthide = 0;
                            }

                          })()}
                          <div class="kyc_centered">
                            {(() => {
                              if (suskyc == false || rejkyc == true || (pendkyc == true) && (data == '')) {
                                return (
                                  <div className='kyc_cont_two'>
                                    <div className='d-flex justify-content-around'>
                                      <div className='kyc_cont_two_upload mt-2'>
                                        <img className='kyc_cont_two_upload_two kyc_img_one' src={file} />
                                        <input onChange={(e) => imgFile(e)} accept="image/*" name='file' className='kyc_cont_two_upload_three' id='file' type="file" />
                                      </div>
                                      <div className='mt-2 kyc_line'>
                                      </div>
                                      <div onClick={() => remove()} className=' mt-2'>
                                        <img src={delect} />
                                      </div>
                                    </div>
                                  </div>
                                )
                              }else{
                                return(
                                  ''
                                )
                              }
                            })()}
                          </div>

                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-6'>
                          <div onClick={() => gotosupport()} className='d-flex'>
                            <div className='headset'>
                              <img style={{ cursor: 'pointer' }} className='kaart' src={headset} />
                            </div>
                            <div>
                              <p style={{ cursor: 'pointer' }} className='compara222'>Support</p>
                              <p style={{ cursor: 'pointer' }} className='compara3333'>9am - 5Pm</p>
                            </div>
                          </div>
                        </div>
                        <div className='col-6'>
                          {(() => {

                            if ((suskyc == true) ) {

                              return (
                                <div onClick={() => Clickedone()} className='bbtn'>
                                  <div className='d-flex'>
                                    <p style={{ cursor: 'pointer' }} className='compara nextonee mt-1'>{loading ?
                                      <div style={{ marginTop: '-4px' }}>
                                        <ReactLoading type='balls' color='#ffffff' />
                                      </div>
                                      : 'Next'}</p>
                                  </div>
                                </div>
                              )
                            } else if ((pendkyc == true) && (data != '') ) {

                              return (
                                <div onClick={() => Clickedone()} className='bbtn'>
                                  <div className='d-flex'>
                                    <p style={{ cursor: 'pointer' }} className='compara nextonee mt-1'>{loading ?
                                      <div style={{ marginTop: '-4px' }}>
                                        <ReactLoading type='balls' color='#ffffff' />
                                      </div>
                                      : 'Next'}</p>
                                  </div>
                                </div>)
                            } else if ((pendkyc == true) && (data == '')) {

                              return (
                                <div onClick={() => Clicked()} className='bbtn'>
                                  <div className='d-flex'>
                                    <p style={{ cursor: 'pointer' }} className='compara nextonee mt-1'>{loading ?
                                      <div style={{ marginTop: '-4px' }}>
                                        <ReactLoading type='balls' color='#ffffff' />
                                      </div>
                                      : 'Next'}</p>
                                  </div>
                                </div>)
                            } else if ((rejkyc == true)) {
                              return (
                                <div onClick={() => Clicked()} className='bbtn'>
                                  <div className='d-flex'>
                                    <p style={{ cursor: 'pointer' }} className='compara nextonee mt-1'>{loading ?
                                      <div style={{ marginTop: '-4px' }}>
                                        <ReactLoading type='balls' color='#ffffff' />
                                      </div>
                                      : 'Next'}</p>
                                  </div>
                                </div>)
                            }else{
                              return(
                                ''
                              )
                            }

                          })()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  )
}
