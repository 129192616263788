import React from 'react'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'
import Edit_profile_comp from '../../components/mobile_view_comp/edit_profile/edit_profile_comp'

export default function Edit_profile() {
  return (
    <>
      <div style={{width:'90%' , margin:'auto' ,marginTop:'65px' , marginBottom:'30px'}}>
        <Cart_one_head link='/profile'  head='Profile' />
        <Edit_profile_comp />
      </div>
    </>
  )
}