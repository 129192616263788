import React , { useState , useEffect } from 'react'
import Profile_home_nav from '../profile_home/profile_home_nav'
import { Tabs, TabList, TabPanels, Tab, TabPanel , ChakraProvider } from '@chakra-ui/react'
import { Link , useNavigate } from 'react-router-dom'
import one123 from '../../assets/img/123.png'
import axios from 'axios'
import { Base_Url , ImageUrl } from '../../components/globalvariable/globe'

export default function History_home_comp() {

    let navigate = useNavigate()

    let token = localStorage.getItem('user_data')

    const [ all ,  setAll ] = useState()
    const [ alls ,  setAlls ] = useState()

    
    let Alldata = async() =>{
        if(token === null){
            navigate('/')
            return
        }
        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/history`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            }
        })
        .then((res)=>{
            setAll(res.data.Data.orders)
            console.log(res.data,'csklvkdhsjvdfvbdbvbsbvbffbd')
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    let Alldatas = async() =>{
        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/history`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            },
            data : {
                products : 1
            }
        })
        .then((res)=>{
            setAlls(res.data.Data.products)
            console.log(res,'products')
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    useEffect(()=>{
        Alldata()
        Alldatas()
    },[])

  return (
    <>
        <div className='pro_home_one  mt-150'>
          <div className='row' >
            
            <Profile_home_nav />

            <div className='col-8'>
            <div className='pro_home_fifteen'>
                <div className='d-flex'>
                  <p className='pro_home_eight'>History</p>
                </div>
              </div>
              <div className='pro_home_seven mt-3'>
                <ChakraProvider>
                    <Tabs isFitted >
                        {
                            all ? <TabList>
                            <Tab _selected={{ color: 'white', bg: '#F4B644' }}>Order</Tab>
                            <Tab _selected={{ color: 'white', bg: '#F4B644' }}>Product</Tab>
                        </TabList>   : <p style={{textAlign:'center' , marginTop : '20px' , fontWeight:'600'}} >No Data Found</p> 
                        }
                        
                        <TabPanels>
                            <TabPanel>
                            {all?.map((data,key)=>
                                <div className='ord_det_one mt-4'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <p className='ord_det_two'>{data.OrderAutoGenId}</p>
                                            <p className='ord_det_threes'>{data.remainingDays}</p>
                                        </div>
                                        <div className='col-6'>
                                        {/* {(()=>{
                                            if(data.status === "Pending Order"){
                                                return(
                                                <p className='his_one'>{data.status}</p>
                                                )
                                            }
                                            else if(data.status === "Running Order"){
                                                return(
                                                <p className='his_one'>{data.status}</p>
                                                )
                                            }
                                            else if(data.status === "Handovered"){
                                                return(
                                                <p className='his_one his_seven'>{data.status}</p>
                                                )
                                            }
                                            
                                            else if(data.status === "Order Cancelled"){
                                                return(
                                                <p className='his_one his_eight'>{data.status}</p>
                                                )
                                            }
                                            else if(data.status === "Handover Request"){
                                                return(
                                                <p className='his_one '>{data.status}</p>
                                                )
                                            }
                                            else if(data.status === "Renewed Order"){
                                                return(
                                                <p className='his_one '>{data.status}</p>
                                                )
                                            }
                                            else {
                                                return(
                                                <p className='his_one '>{data.status}</p>
                                                )
                                            }
                                            })()} */}
                                            <p className='his_twos'>{data.fromdate} - {data.todate}</p>
                                        </div>
                                    </div>
                                    
                                    <div className='row mt-1'>
                                        {data?.carts.map((dat,key)=>
                                        
                                        {
                                            return(
                                                
                                            key >=4 ?
                                            <></>
                                            : <div className='col-3' >
                                                <img className='ord_det_seven' src={ImageUrl+'/'+dat.ProImgPrimary} />
                                                <p className='ord_det_eight'>{dat.ProName.substring(0, 20)}...</p>
                                            </div>
                                        )
                                        }
                                             
                                        )}
                                        {
                                            data .remainingCount === 0 ? '' :
                                                <div className='col-3' >
                                                    <img className='ord_det_sevens' src={one123} />
                                                
                                                    <p className='more_items'>{data.remainingCount}<br/>Items</p>
                                                    <p className='ord_det_eights'>View more</p>
                                                </div>   
                                        }
                                    
                                    </div> 
                                    <div className='row'>
                                        <div className='col-6'>
                                        <div className='mt-2'>
                                            <p className='his_three mt-2'>₹ {data.orderamt}/{data.Type === "Monthly" ? 'M' : data.Type === "Weekly" ? 'W' : 'D'}</p>
                                        </div>
                                        </div> 
                                        <div className='col-6'>
                                            {/* <Link to='/service_request'> */}
                                            {/* {(()=>{
                                            if(data.status === "Pending Order"){
                                                return(
                                                <div  style={{cursor : 'pointer'}} className='his_four mt-2'>
                                                    <p className='ord_det_twelve'>{data.status}</p>
                                                </div>
                                                )
                                            }else if(data.status === "Running Order"){
                                                return(
                                                <div  style={{cursor : 'pointer'}} className='his_four mt-2'>
                                                    <p className='ord_det_twelve'>{data.status}</p>
                                                </div>
                                                )
                                            }
                                            else if(data.status === "Handovered"){
                                                return(
                                                <div  style={{cursor : 'pointer'}} className='his_five mt-2'>
                                                    <p className='ord_det_twelve'>{data.status}</p>
                                                </div>
                                                )
                                            }
                                            else if(data.status === "Order Cancelled"){
                                                return(
                                                <div style={{cursor : 'pointer'}} className='his_six mt-2'>
                                                    <p className='ord_det_twelve'>{data.status}</p>
                                                </div>
                                                )
                                            }
                                            else if(data.status === "Handover Request"){
                                                return(
                                                <div  style={{cursor : 'pointer'}} className='his_four mt-2'>
                                                    <p className='ord_det_twelve'>{data.status}</p>
                                                </div>
                                                )
                                            } else if(data.status === "Renewed Order"){
                                                return(
                                                <div  style={{cursor : 'pointer'}} className='his_five mt-2'>
                                                    <p className='ord_det_twelve'>{data.status}</p>
                                                </div>
                                                )
                                            }else{
                                                return(
                                                <div  style={{cursor : 'pointer'}} className='his_four mt-2'>
                                                    <p className='ord_det_twelve'>{data.status}</p>
                                                </div>
                                                )
                                            }
                                            })()} */}
                                            {(()=>{
                                            if(data.status === "Pending Order"){
                                                return(
                                                <p className='his_one'>{data.status}</p>
                                                )
                                            }
                                            else if(data.status === "Running Order"){
                                                return(
                                                <p className='his_one'>{data.status}</p>
                                                )
                                            }
                                            else if(data.status === "Handovered"){
                                                return(
                                                <p className='his_one his_seven'>{data.status}</p>
                                                )
                                            }
                                            
                                            else if(data.status === "Order Cancelled"){
                                                return(
                                                <p className='his_one his_eight'>{data.status}</p>
                                                )
                                            }
                                            else if(data.status === "Handover Request"){
                                                return(
                                                <p className='his_one '>{data.status}</p>
                                                )
                                            }
                                            else if(data.status === "Renewed Order"){
                                                return(
                                                <p className='his_one '>{data.status}</p>
                                                )
                                            }
                                            else {
                                                return(
                                                <p className='his_one '>{data.status}</p>
                                                )
                                            }
                                            })()}
                                            {/* </Link> */}
                                        </div>
                                    </div>
                                </div>
                                )}
                            </TabPanel>
                            <TabPanel>
                                {alls?.map((data,key)=>
                                    <Link to={'/detail/'+ data.ProName
                                    .toLowerCase()
                                    .trim()
                                    .replace(/[^\w\s-]/g, '')
                                    .replace(/[\s_-]+/g, '-')
                                    .replace(/^-+|-+$/g, '') +'/'+data.CartDetailProId}>
                                        <div className='his_ten mt-3'>
                                            <div className='row'>
                                                <div className='col-2'>
                                                    <img style={{width:'75px'}} className=' mx-auto' src={ImageUrl +'/'+ data.ProImgPrimary} />
                                                </div>
                                                <div className='col-5'>
                                                <p style={{fontSize:'10px'}} className='his_eleven mt-3'>{data.ProName}</p>
                                                <p className='his_twelves'>{data.CartDetailInventoryProSerialNo}</p>
                                                </div>
                                                <div className='col-5'>
                                                <p className={(data.status === 'Renewed' || data.status === 'Handovered' ? 'his_fourteen mt-2' : 'his_thirteen mt-2')}>{data.status}</p>
                                                <p className='his_sixteens'>on {data.Date}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                )}
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </ChakraProvider>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}