import React , { useState , useEffect } from 'react'
import circle1 from '../../../assets/img/circle1.png'
import icon from '../../../assets/img/ico1.png'
import aright from '../../../assets/img/aright.png'
import eye_one from '../../../assets/img/eye_one.png'
import eye_close from '../../../assets/img/eye_close.png'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Base_Url } from '../../globalvariable/globe'

export default function Company_login() {

    const [ phone , setPhone ] = useState('')
    const [ pass , setPass ] = useState('')
    const [ eye , setEye ] = useState(false)

    const [ loading , setLoading ] = useState(false)

    const [ errmsg , setErrMsg ] = useState('Enter Valid PhoneNo')

    const navigate = useNavigate()

    const [ otp , setOtp ] = useState()

    // const succnotify = () => toast.success('Product added Successfully', {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     });


const errnotifyone = (dat) => toast.error( dat , {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

const errnotify = () => toast.error( errmsg , {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

    const clicked = async() =>{

        let phonevali = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

        if(phone ===''){
            errnotifyone('Enter Email')
            return
        }

        if(phonevali.test(phone)){

        }else{
            errnotifyone('Enter Valid Email')
            return
        }

        if(pass === ''){
            errnotifyone('Enter Password')
            return
        }



        setLoading(true)

        const response = await axios({
            method:'post',
            url : `${Base_Url}/corporateLogin`,
            data:{
                email : phone ,
                password : pass
            }
        })
        .then((res)=>{
            console.log(res.data)
            if(res.data.status === true){
                localStorage.setItem('user_data', res.data.Data.token)
                let cart  = localStorage.getItem('data')

                if( res.data.Data.userIsSubContact === 1 ){
                    localStorage.setItem('eye' , 'lufy')
                    localStorage.removeItem("data");
                }else{
                    if( cart === '' || cart === undefined ||  cart === null ){
                        localStorage.setItem('eye' , 'zoro') 
                    }else{
                       
                        let dat = JSON.parse(cart)

                        dat.forEach((element, index) => { 
                            element.proDepAmnt = 0 ;
                            element.totalDeposit=0
                            console.log('one')
                        });
    
                        let jss = JSON.stringify(dat)
                        localStorage.setItem('data' , jss)
                        localStorage.setItem('eye' , 'zoro')  
    
                    }
                }
                navigate('/profile')
            }else{
                setErrMsg(res.data.message)
                errnotify()
            }
            setLoading(false)
        })
        .catch((err)=>{
            setLoading(false)
            setErrMsg('Login Failed')
            errnotify()
            console.log(err)
        })
        setLoading(false)
    }

    let phonevalue = (e)=>{
        setPhone(e.target.value.replace(/[^0-9]/g, "").slice(0, 10))
    }

  return (
    <>
      <div>
          <div>
                <img className='login_two_img1' src={circle1} />
                <div className='login_tow_containner_one'>
                    <img className='login_two_img2' src={icon} />
                    <p className='login_two_img3'>Welcome to <span className='log_two_span'>Rentla</span> !!</p>
                    <p className='login_two_img4'>Email</p>
                    <div className='lo_tw_one'>
                        <div className='d-flex'>
                            <div>
                                <input onChange={(e)=>{setPhone(e.target.value)}}  value={phone}  class="form-control lo_tw_three" id="exampleInputEmail1" aria-describedby="emailHelp"
                                // placeholder="99945 99945"
                                />
                            </div>
                        </div>
                    </div>

                    <p className='login_two_img400'>Password</p>
                    <div className='lo_tw_one'>
                        <div className='d-flex justify-content-between'>
                            <div>
                                <input style={{width : '100%'}} onChange={(e)=>setPass(e.target.value)} value={pass}  type={ eye === true ?  'text' : 'password' } class="form-control lo_tw_three" id="exampleInputEmail1" aria-describedby="emailHelp"
                                // placeholder="99945 99945"
                                />
                            </div>
                            <div  style={{ padding : 13 }} >
                                {
                                    eye === false ? <img onClick={()=>{ setEye(!eye) }} style={{ width : 20 , height : 20 , opacity : .5 }} src={eye_one} />
                                    : <img onClick={()=>{ setEye(!eye) }} style={{ width : 20 , height : 20 , opacity : .5 }}  src={eye_close} />
                                }
                                
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-6'>
                            <Link to='/login_three' >
                                <p className='lo_tw_foue'>Register Account</p>
                            </Link>
                        </div>
                        <div className='col-6'>
                            <div className='lo_tw_five'>
                                {
                                    loading ? 
                                    <div className='loas_login'>
                                        <ReactLoading type='balls' color='#ffffff' /> 
                                    </div>
                                :
                                    <div onClick={()=>clicked()} className='row'>
                                        <div className='col-6'>
                                            <p className='lo_tw_six'>Next</p>
                                        </div>
                                        <div className='col-6'>
                                            <img className='lo_tw_seven' src={aright} />
                                        </div> 
                                    </div>
                                }
                                
                            </div>
                        </div>
                    </div>
                    {/* <div style={{marginTop:'30px'}} className='row'>
                        <div className='col-5'>
                            <hr />
                        </div>
                        <div className='col-2'>
                            <p className='lo_tw_eight'>OR</p> 
                        </div>
                        <div className='col-5'>
                            <hr />
                        </div>
                    </div>
                    <div className='lo_tw_nine'>
                        <div className='d-flex justify-content-center '>
                            <img className='lo_tw_ten mt-3' src={google} />
                            <p className='lo_tw_elevan'>Log In with Google</p>
                        </div>
                    </div> */}
                    <p className='lo_tw_twelve mt-4'>view and agree to the rentla’s <span style={{ fontWeight : '800' }} ><Link to='/useragreement'> User Agreement </Link></span> and <span style={{ fontWeight : '800' }} ><Link to='/privacy_policy'>Privacy policy</Link></span></p>

                </div>
                <img className='login_two_img233' src={circle1} />
          </div>
      </div>
        <ToastContainer />
    </>
  )
}

{/* <ReactLoading type='balls' color='#ffffff' /> */}