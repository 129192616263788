import React from 'react'
import { Link } from 'react-router-dom'
import Left from '../../../assets/img/left.png'
import qr from '../../../assets/img/qr.png'

export default function Checklist_head(props) {
  return (
    <>
      <div className="second-tops fixed-top navbar-light top-tab-nav">
            <div className="d-flex  m-auto paddd" >
               <div  className='loab'>
                  <Link to={props.link}>
                    <img src={Left} />
                  </Link>
                </div>
               <div style={{margin: 'auto'}} className='loab'>
                   <p className='ccaaat'>{props.head}</p>
               </div>
               {/* <img className='qrs' src={qr} /> */}
            </div>
        </div>
    </>
  )
}