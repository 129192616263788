import React from 'react'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'
import Mob_reg_page from '../../components/mobile_view_comp/mob_reg/mob_reg_page'

export default function Mob_reg() {
  return (
    <>
        <div style={{width:'90%' , margin:'auto' ,marginTop:'65px'}}>
            <Cart_one_head link='/login' head='Create Account' />
            <Mob_reg_page />
        </div>
    </>
  )
}