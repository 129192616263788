import React , { useState , useEffect } from 'react'
import axios from 'axios'
import Left from '../../../assets/img/left.png'
import { useNavigate } from 'react-router-dom'
import { Base_Url } from '../../globalvariable/globe'
import Html from 'html-react-parser'

export default function MobuserAgrement() {

 
  let navigate = useNavigate()
  const clicked = ()=>{
    navigate(-1)
  }

  return (
    <div className='container' style={{ margin:'auto' ,overflowX:'hidden'}}>
       <div className="second-tops fixed-top navbar-light top-tab-nav ">
            <div className="d-flex  m-auto paddd" >
               <div onClick={()=>clicked()} className='loab'>
                  {/* <Link to={props.link}> */}
                    <img src={Left} />
                  {/* </Link> */}
                </div>
               <div style={{margin: 'auto'}} className='loab'>
                   <p className='ccaaat'>User Agrement</p>
               </div>
            </div>
        </div>
        <iframe  style={{ width : '100%' , height : 600 , marginTop : 80 }} src='https://rentla.akprojects.co/useraggrementview' ></iframe>

    </div>
  )
}
