import { ActionTypes } from  '../contants/action-types'

export const setProducts = ( products ) => {
    return {
        type : ActionTypes.SET_PRODUCTS,
        payload : products
    }
}

export const mapProducts = ( products ) => {
    return {
        type : ActionTypes.MAP_PRODUCTS,
        payload : products
    }
}

export const mapProductszomm = ( products ) => {
    return {
        type : ActionTypes.MAP_PRODUCTS_ZOOM,
        payload : products
    }
}


export const selectedProduct = ( product ) => {
    return {
        type : ActionTypes.SELECTED_PRODUCT,
        payload : product
    }
}

export const allProduct = ( product ) => {
    return {
        type : ActionTypes.ALL_PRODUCT,
        payload : product
    }
}

export const oneProduct = ( product ) => {
    return {
        type : ActionTypes.ONE_PRODUCT,
        payload : product
    }
}

export const selectedRemoveProduct = () => {
    return {
        type : ActionTypes.REMOVE_SELECTED_PRODUCT
    }
}

export const allGlobe = ( product ) => {
    return {
        type : ActionTypes.GLOBE,
        payload : product
    }
}

export const LoogIn = ( product ) => {
    return {
        type : ActionTypes.LOGIN,
        payload : product
    }
}

export const CheckOuts = ( product ) => {
    return {
        type : ActionTypes.CHECKOUT,
        payload : product
    }
}

export const CartList = ( product ) => {
    return {
        type : ActionTypes.CARTLIST,
        payload : product
    }
}

export const ViewCart = ( product ) => {
    return {
        type : ActionTypes.VIEWCART,
        payload : product
    }
}

export const UpdateViewCart = ( product ) => {
    return {
        type : ActionTypes.VIEWCARTUPDATE,
        payload : product
    }
}

export const Cartss = ( product ) => {
    return {
        type : ActionTypes.CART,
        payload : product
    }
}

export const WishList = ( product ) => {
    return {
        type : ActionTypes.WISHLIST,
        payload : product
    }
}

export const Cartcount = ( product ) => {
    return {
        type : ActionTypes.CARTCOUNT,
        payload : product
    }
}

export const Wishcount = ( product ) => {
    return {
        type : ActionTypes.WISHCOUNT,
        payload : product
    }
}

export const OrderConfirm = ( product ) => {
    return {
        type : ActionTypes.ORDERCONFIRM,
        payload : product
    }
}

export const set_Product_Update = ( product ) => {
    return {
        type : ActionTypes.SET_PRODUCTS_UPDATE,
        payload : product
    }
}