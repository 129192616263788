import React , { useState , useEffect } from 'react' 
import { Link } from 'react-router-dom'; 
import { ImageUrl } from '../../globalvariable/globe';
import { useSelector , useDispatch } from 'react-redux';
import logo from '../../../assets/img/icon.png'
import { Wishcount } from '../../../redux/actions/productAction';

export default function Web_cat_detail() { 

    const [ heart , setHeart] = useState(true) 

    const products = useSelector((state)=>state.allpoductData)
    console.log(products,'prodectsss')
    const product =  useSelector((state)=>state)
    const [countss  , setCountss ]= useState(1)
    const [ wish ,  setWish ] = useState()
    let wishss = localStorage.getItem('wish_list')

    let dispatch = useDispatch()

    const Clickheart = ( data , key ) => {
        setCountss(countss +1)
        let allata = localStorage.getItem('wish_list')
        let another = JSON.parse(allata)
        let final = 0
        // setHeart(true)

        let wish_data = [
            {
                datas : data
            }
        ]

        if(localStorage.getItem('wish_list')){
            another.forEach((element, index) => {
                console.log(index,'index')
                if( element.datas.ProId === wish_data[0].datas.ProId){
                    let allanother = another
                    allanother.splice(index , 1)
                    localStorage.setItem('wish_list' , JSON.stringify(allanother))
                    dispatch(Wishcount(allanother))
                    final = 1
                }
            })
            console.log(final)
            if( final === 0 ){
                console.log('susssssss')
                let allanother =  another.concat(wish_data)
                localStorage.setItem('wish_list' , JSON.stringify(allanother))
                dispatch(Wishcount(allanother))
            }

        }else{
            localStorage.setItem('wish_list' , JSON.stringify(wish_data))
            dispatch(Wishcount(wish_data))
        }
        // let wish_list = localStorage.getItem('wish_list')

        // const All = async () => {
        //     let responce = await axios({
        //         method : 'post',
        //         url : `${Base_Url}/wishlist`,
        //         headers: { 
        //             'Authorization': `Bearer ${user}` 
        //         },
        //         data : {
        //             proId : data.ProId
        //         }
        //     })
        //     .then ((res)=>{
        //     })
        // }

        // All()

    }
    useEffect(()=>{
        wishss = localStorage.getItem('wish_list')
        setWish(JSON.parse(wishss))
    },[])

    useEffect(()=>{
        console.log('adkldfjkbsdfbwejkbksdfjbbjkjk')
        wishss = localStorage.getItem('wish_list')
        setWish(JSON.parse(wishss))
    },[countss])

  return (
      <>
        <div className='container web_cat_five'>
            <div className='row mt-2'>
                {products.pro?.map((data,key)=>
                <div  class="col-md-6 col-lg-4 col-xl-4 col-sm-6 col-xxl-3 home_pro_cont"  key={key}  style={{height:'430px'}}>
                <div class="product-grid" style={{marginTop : 0 }} >
                    <div className='pro'>
                        <div className="product-image">
                            <Link to={'/detail/'+ data.ProName
                                .toLowerCase()
                                .trim()
                                .replace(/[^\w\s-]/g, '')
                                .replace(/[\s_-]+/g, '-')
                                .replace(/^-+|-+$/g, '') +'/'+data.ProId}>
                                <div className="image">
                                    <img  className="pic-1 onepice" src={ ImageUrl +'/'+data?.ProWebImage} />
                                </div>
                            </Link>
                            <div className="product-like-icon">
                                {(() => {
                                    if(wish != null){
                                        console.log(typeof(wish),'wishhhh')
                                    var result = wish.filter(x => x.datas.ProId == data.ProId);

                                    if(result.length > 0){
                                        return ( <i onClick={()=>Clickheart(data,key)} className='fa fa-heart hrt_red_clr' > </i>)
                                            }else{
                                                return ( <i onClick={()=>Clickheart(data,key)} className='fa fa-heart-o' > </i>)
                                            }
                                    }
                                    else{
                                        return ( <i onClick={()=>Clickheart(data,key)} className='fa fa-heart-o' > </i>)
                                    }
                                })()}

                            </div>
                                <div >

                                    <div   className="head">
                                    </div>
                        
                                    
                                </div>
                                <div>
                        <div className="product-content">
                        <div className="row lit">
                            <div className="col"> 
                                <h5 className="title" style={{fontWeight :600}}>
                                {data.ProName.split(' ').slice(0,4).join(' ')}</h5>
                            </div>
                        </div>
                            <div>
                                <hr />
                            </div>
                            <div className='row mt-4 pb-4'>
                            <div className='col-4'>
                            <div  className="product-det">
                                {
                                    data.spec.map((datass,keyss)=>{
                                        
                                        if(keyss < 1){
                                            return(
                                                <div className='btn product-detss product_detss_box'>{datass.ProSpecificName}</div>
                                            )
                                        }else{
                                        return
                                        }
                                    })
                                }
                                <Link to={'/detail/'+ data.ProName
                                    .toLowerCase()
                                    .trim()
                                    .replace(/[^\w\s-]/g, '')
                                    .replace(/[\s_-]+/g, '-')
                                    .replace(/^-+|-+$/g, '') +'/'+data.ProId}>
                                    {/* <div  className='btn btn-default product-detss bro_desss_pro product_detss_box_two'>More</div> */}
                                </Link>
                            </div>
                            </div>
                            <div className='col-8'>
                                <div className='pull-right'>
                                    <div className='d-flex'>
                                    <div  className="hrfhfgdgdggfb"><span style={{fontFamily:'arial'}} >₹</span> {data.ProRentAmt}</div>
                                        
                                        <div style={{fontSize:'10px',borderRadius:'4px'}} className="prices">
                                            <div style={{padding:'4px',marginRight:'15px'}}>
                                                
                                                <div  className="price"><span style={{fontFamily:'arial'}} >₹</span> {data.ProRentAmt}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        </div>

                    
                    </div>
                </div>
            </div>
                )}
                {
                products.pro.length === 0 ? <p style={{ textAlign:'center', marginTop:'30px' , fontWeight : '600' , fontSize : '20px' }}>No Product Found</p> : ''
                }
            </div>
        </div>
        {/* <div className={ products.pro.length === 0 ? 'foot_indexx' : '' } >
        <footer className="footer-area bg-gray pt-60 pb-10 mt-60">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-md-4 col-sm-4">
                        <div className="copyright mb-30">
                            <div className="footer-logo">
                                <Link to='/'>
                                    <img alt="" src={logo} />
                                </Link>
                            </div>
                            <p>© 2019 <a >Rentla</a>.<br /> All Rights Reserved</p>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4">
                        <div className="footer-widget mb-30 ml-30">
                            <div className="footer-title">
                                <h3>ABOUT US</h3>
                            </div>
                            <div className="footer-list">
                                <ul>
                                    <li><a >About us</a></li>
                                    <li><a >Store location</a></li>
                                    <li><a >Contact</a></li>
                                    <li><a >Orders tracking</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4">
                        <div className="footer-widget mb-30 ml-50">
                            <div className="footer-title">
                                <h3>USEFUL LINKS</h3>
                            </div>
                            <div className="footer-list">
                                <ul>
                                    <li><a ><Link to='/terms'>T & C</Link></a></li>
                                    <li><a ><Link to='/privacy_policy'>Privacy Policy</Link></a></li>
                                    <li><a ><Link to='/damage_policy'>Damage Policy</Link></a></li>
                                    <li><a ><Link to='/faq'>FAQs</Link></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-6">
                        <div className="footer-widget mb-30 ml-75">
                            <div className="footer-title">
                                <h3>FOLLOW US</h3>
                            </div>
                            <div className="footer-list">
                                <ul>
                                    <li><a href='https://www.facebook.com/rentla.ind' target="_blank" >Facebook</a></li>
                                    <li><a href='https://www.linkedin.com/company/rentla-in/' target="_blank">Linkedin</a></li>
                                    <li><a href='https://www.instagram.com/rentla.in/' target="_blank" >Instagram</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div> */}
    </>
  )
}
