import React , {useState , useEffect} from 'react'
import approve from '../../../assets/img/approve.png'
import kyc3 from '../../../assets/img/kyc3.png'
import { Tick } from 'react-crude-animated-tick';
import Mobcart_one_foot from '../cart_one/mobcart_one_foot';
import axios from 'axios';
import { Base_Url } from '../../globalvariable/globe';
import { useNavigate } from 'react-router-dom';
import Dot_arrow from '../cart_one/dot_arrow';

export default function Mob_kyc_five() {

    let user = localStorage.getItem('user_data')
    const [ approves , setApprove ] = useState()
    let navigate = useNavigate()

    useEffect(()=>{
        Alldata()
    },[])

    const Alldata = async () => {
        if(user === null){
            navigate('/')
            return
          }
        let respoce = await axios ({
            method : 'post' , 
            url : `${Base_Url}/kycApproval`,
            headers: { 
                'Authorization': `Bearer ${user}` 
            }
        })
        .then((res)=>{
            if(res.data.kycApproval === 1 ){
                setApprove(1)
            }else{
                setApprove(0)
            }
        })
        .catch((err)=>{
            if(err.response.status === 401){
                navigate('/login')
            }
            console.log(err)
        })
    }

    const backHome = () =>{
        navigate('/')
    }

  return (
    <>
    { approves===0 ? 
    <>
    {
        approves === 1 ? '' : <img style={{ width : '100%' }} src={kyc3} />
    }
     
        <div className='kyc_five'>
            <img className='kyc_five_img' src={approve} />
            <p className='kyc_five_p_one'>Your Kyc waiting for approval</p>
            <p className='kyc_five_p_two'>It will take 3 - 4hrs , Please be Patient </p>
        </div>
        <div onClick={()=>backHome()} className='kyc_five_box mt-4'> 
            <p className='kyc_five_p_three'>Back to Home</p> 
        </div>
    </>
        : approves===1 ?
    <>
        <div>            
            <img className='kyc_fives' src='https://tenor.com/view/fireworks-color-sparkle-gif-15241523.gif' />
            <div className='tikkk'>
                <Tick size={200} />
                <p style={{fontSize:'15px' , fontWeight : '600' , textAlign:'center'}} className='my-4'>Kyc Approval Completed</p>
            </div> 
        </div>
        <Mobcart_one_foot link='/' />
    </> : ''
        }
    </>
  )
}