import React , { useState  , useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import Html from 'html-react-parser'
import { useSelector , useDispatch } from 'react-redux';
import { ImageUrl , Base_Url } from '../../globalvariable/globe'
import axios from 'axios'
import { useNavigate , useParams , Link } from 'react-router-dom'
import { Select , ChakraProvider , NumberInputField , NumberInput , NumberInputStepper , NumberIncrementStepper , NumberDecrementStepper } from '@chakra-ui/react'
import DatePicker from 'react-date-picker';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../../common/head_foot/header_two'
import Footer from '../../../common/footer'
import Maggni from './maggni'
import { Wishcount } from '../../../redux/actions/productAction';
 
export default function Running_pro_detail() {

    let wishss = localStorage.getItem('wish_list')
  
    let navigate = useNavigate()
    let dispatch = useDispatch()

  const [ wish ,  setWish ] = useState()

  const [ keys , setKeys ] = useState(0)

  let token = localStorage.getItem('user_data')

  let { id , catid , detid } = useParams()

  const [ datas , setDates ] = useState()
  const [ datass , setDatass ] = useState()
  const [ veri , setVeri ] = useState() 
  const [ rent , setRents ] = useState()
  const [ imgg , setImgg ] = useState()
  const [ all , setAll ] = useState()

  const [value, onChange] = useState(new Date());
 

  const [ amount , setAmount ] = useState()
  const [ dummy , setDummy ] = useState()
  const [ sta , setSta ] = useState(true)

  const [ loading , setLoading ] = useState(false)
  const [ qty , setQty ] = useState(1)
  const [ rents , setRent ] = useState('')
  const [ proveri , setProveri ] = useState()
  const [ proveriamount , setProveriamount ] = useState()
  const [ btndata , setBtnData ] = useState('Add To Cart')
  const [ nav , setnavi ] = useState(false)
  const [ monthCount , setMonthCount] = useState(1)

  const [ clicolor , setClicolor ] = useState(true)

  const [ depamount , setDepamount ] = useState()
  const [ twodummy , setTwodummy ] = useState(0)
  const [ send_dat , setSend_dat ] = useState()
  const [ varid , setVarid ] = useState()
  const [ more , setMore ] = useState(false)

  const [countss  , setCountss ]= useState(1)

  let final = 0
 

  useEffect(()=>{
    console.log('adkldfjkbsdfbwejkbksdfjbbjkjk')
    wishss = localStorage.getItem('wish_list')
    setWish(JSON.parse(wishss))
},[countss])

  const allData = async () =>{
    if(token === null){
        navigate('/')
        return
      }
      let res = await axios({
          method : 'post',
          url : `${Base_Url}/viewSingleRunningProduct`,
          headers: { 
              'Authorization': `Bearer ${token}` 
          },
          data : {
            orderId : id ,
            cartDetailId : catid ,
            proId : detid 
          }
      })
      .then((res)=>{
            setDatass(res.data.Data.productDetails)
            setVeri(res.data.Data.productVariants)
            setDates(res.data.Data.productVariants)
            setRents(res.data.Data.BookingType)
            setImgg(res.data.Data.productImage)
            setAll(res.data.Data)
            const dat = new Date(res.data.Data.startingDateWeb);
            onChange(new Date(dat))
            console.log(res,'dhishduighugdugsguy')
      })
  }

  useEffect(()=>{
    wishss = localStorage.getItem('wish_list')
    setWish(JSON.parse(wishss))
    allData()
  },[])

  useEffect(() => {
      console.log(datas,'jkdfuiwghfuiegruifgeriufgurigu')
      if(datas){
          setVarid(datass[0].ProSpecificId)
          setDepamount(datass[0].ProDepositAmt)
          setSend_dat(datas[0].ProSpecificMonthly?datas[0].ProSpecificMonthly : '')
          setDummy(datas[0].ProSpecificMonthly)
      }
  },[datas]);

  const date = new Date();
  date.setDate(date.getDate() + ( monthCount*30 ));
  const [value2, onChange2] = useState(date);

  const press  = (dat,key) => {
      setVarid(dat.ProSpecificId)
      console.log(key,'key')
      setSta(false)
      setKeys(key)
      setTwodummy(key)
      if(datas[0].ProSpecificId === dat.ProSpecificId){
          if(rents === 'Monthly'){
              
              setSend_dat(datas[0].ProSpecificMonthly)
              setAmount((datas[key].ProSpecificMonthly * qty) * monthCount)
              console.log(send_dat,'month')
          }else if(rents === 'Weekly'){
              setSend_dat(datas[0].ProSpecificWeekly)
              setAmount((datas[key].ProSpecificWeekly * qty) * monthCount)
              console.log(send_dat,'week')
          }else if(rents === 'Daily'){
              setSend_dat(datas[0].ProSpecificDaily)
              setAmount((datas[key].ProSpecificDaily * qty) * monthCount)
              console.log(send_dat,'day')
          }
      }else{
         
          if(rents === 'Monthly'){
              setVarid(datas[key].ProSpecificId)
              setSend_dat(dat.ProSpecificMonthly)
              setAmount((datas[key].ProSpecificMonthly * qty) * monthCount)
              console.log(send_dat,'month')
          }else if(rents === 'Weekly'){
              setVarid(datas[key].ProSpecificId)
              setSend_dat(dat.ProSpecificWeekly)
              setAmount((datas[key].ProSpecificWeekly * qty) * monthCount)
              console.log(send_dat,'week') 
          }else if(rents === 'Daily'){
              setVarid(datas[key].ProSpecificId)
              setSend_dat(dat.ProSpecificDaily)
              setAmount((datas[key].ProSpecificDaily * qty) * monthCount)
              console.log(send_dat,'day')
          }
      }
  }

  const Clickheart = ( data , key ) => {
        
    setCountss(countss +1)
    let allata = localStorage.getItem('wish_list')
    let another = JSON.parse(allata)
    let final = 0
    let ProRentAmt = { "ProRentAmt" : dummy }
    let ProImgName = { "ProImgPrimary" : imgg[0].ProImgName }
    data = {...data , ...ProRentAmt}
    data = {...data , ...ProImgName}
    let wish_data = [
        {
            datas : data
        }
    ]

    if(localStorage.getItem('wish_list')){
        another.forEach((element, index) => {
            console.log(index,'index')
            if( element.datas.ProId === wish_data[0].datas.ProId){
                let allanother = another
                allanother.splice(index , 1)
                localStorage.setItem('wish_list' , JSON.stringify(allanother))
                dispatch(Wishcount(allanother))
                final = 1
            }
        })
        console.log(final)
        if( final === 0 ){
            console.log('susssssss')
            let allanother =  another.concat(wish_data)
            localStorage.setItem('wish_list' , JSON.stringify(allanother))
            dispatch(Wishcount(allanother))
        }

    }else{
        localStorage.setItem('wish_list' , JSON.stringify(wish_data))
        dispatch(Wishcount(wish_data))
    }
}

  useEffect(()=>{
      setRent(rent?rent[0].RentTypeName:'')
  },[rent])

  useEffect(()=>{
      veri?.map((dat)=>{
          if(dat.selected===1){
            setProveri(dat.ProSpecificId)
            setProveriamount(dat.ProSpecificMonthly)
          }
      })
    //   setProveri(veri ? veri[0].ProAttrOptAttrId : '' )
    //   setProveriamount(veri ? veri[0].ProAttrPrice : '')
  },[veri])

  useEffect(()=>{
      console.log(value, 'value')
  },[value])

  //calculation
  useEffect(()=>{
      if(qty === 1 && sta===true){
          return
      }
      // if(sta === false ){
      //     setAmount(twodummy * qty)
      //     return
      // }
      setSta(false)
      setDepamount(datass[0].ProDepositAmt * qty)
      if(rents === 'Monthly'){
          setAmount((datas[twodummy].ProSpecificMonthly * qty) * monthCount)
      }else if(rents === 'Weekly'){
          setAmount((datas[twodummy].ProSpecificWeekly * qty) * monthCount)
      }else if(rents === 'Daily'){
          setAmount((datas[twodummy].ProSpecificDaily * qty) * monthCount)
      }
  },[qty])

  useEffect(()=>{
    if(monthCount !== monthCount){
        console.log('worked')
        setMonthCount(1)
        return
    }

      const dates = new Date(value);
      if(rents === 'Daily'){
          dates.setDate(value.getDate() + ( monthCount * 1 ));
      }else if(rents === 'Weekly'){
          dates.setDate(value.getDate() + ( monthCount * 7 ));
      }else {
          dates.setDate(value.getDate() + ( monthCount * 30 ));
      }
      onChange2(dates)


      if(monthCount === 1 && sta===true){
          
          return
      }
      setSta(false)
      if(rents === 'Monthly'){
          setVarid(datas[twodummy].ProSpecificId)
          setAmount((datas[twodummy].ProSpecificMonthly * qty) * monthCount)
          setSend_dat(datas[twodummy].ProSpecificMonthly)
          console.log(send_dat , 'mothly')
      }else if(rents === 'Weekly'){
          setVarid(datas[twodummy].ProSpecificId)
          setAmount((datas[twodummy].ProSpecificWeekly * qty) * monthCount)
          setSend_dat(datas[twodummy].ProSpecificWeekly)
          console.log(send_dat , 'weekly')
      }else if(rents === 'Daily'){
          setVarid(datas[twodummy].ProSpecificId)
          setAmount((datas[twodummy].ProSpecificDaily * qty) * monthCount)
          setSend_dat(datas[twodummy].ProSpecificDaily)
          console.log(send_dat , 'daily')
      }
  },[monthCount])

  useEffect(()=>{

      
      const dates = new Date(value);
      if(rents === 'Daily'){
          dates.setDate(value.getDate() + ( monthCount * 1 ));
      }else if(rents === 'Weekly'){
          dates.setDate(value.getDate() + ( monthCount * 7 ));
      }else {
          dates.setDate(value.getDate() + ( monthCount * 30 ));
      }
      onChange2(dates)



      if(rents === 'Monthly' && sta===true){
          if(rents === 'Monthly'){
              setVarid(datas[twodummy].ProSpecificId)
              setSend_dat(datas[twodummy].ProSpecificMonthly)
              console.log(send_dat , 'mothly')
          }else if(rents === 'Weekly'){
              setVarid(datas[twodummy].ProSpecificId)
              setSend_dat(datas[twodummy].ProSpecificWeekly)
              console.log(send_dat , 'weekly')
          }else if(rents === 'Daily'){
              setVarid(datas[twodummy].ProSpecificId)
              setSend_dat(datas[twodummy].ProSpecificDaily)
              console.log(send_dat , 'daily')
          }
          return
      }
      setSta(false)
      if(rents === 'Monthly'){
          setVarid(datas[twodummy].ProSpecificId)
          setSend_dat(datas[twodummy].ProSpecificMonthly)
          console.log(send_dat , 'mothly')
          setAmount((datas[twodummy].ProSpecificMonthly * qty) * monthCount)
      }else if(rents === 'Weekly'){
          setVarid(datas[twodummy].ProSpecificId)
          setSend_dat(datas[twodummy].ProSpecificWeekly)
              console.log(send_dat , 'weekly')
          setAmount((datas[twodummy].ProSpecificWeekly * qty) * monthCount)
      }else if(rents === 'Daily'){
          setVarid(datas[twodummy].ProSpecificId)
          setSend_dat(datas[twodummy].ProSpecificDaily)
          console.log(send_dat , 'daily')
          setAmount((datas[twodummy].ProSpecificDaily * qty) * monthCount)
      }
  },[rents])



  const calfun = (e) =>{
      const datess = new Date(value); 
      const diffTime = Math.abs(e - datess);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
      datess.setDate(value.getDate() + (diffDays + ( monthCount * 30 )));
      onChange(e)
      onChange2(datess)
  }

  const Renew = async () =>{
    let res = await axios({
        method : 'post' ,
        url : `${Base_Url}/renewSingleRunningProduct`,
        headers: { 
            'Authorization': `Bearer ${token}` 
        },
        data : {
            orderId : id,
            rentType : rents,
            fromDate : ((value.getMonth() > 8) ? (value.getMonth() + 1) : ('0' + (value.getMonth() + 1))) + '-' + ((value.getDate() > 9) ? value.getDate() : ('0' + value.getDate())) + '-' + value.getFullYear() ,
            cartDetailId : all.cartDetailId,
            qty : monthCount
        }
    })
    .then((res)=>{
        if(res.data.status === true){
            console.log(res,'resssssss')
            navigate('/renew_order/'+ res.data.Data.orderId)
        }
        console.log(res)
    })
    .catch((err)=>{
        console.log(err)
    })
  }


  let handclick  = async() =>{

    let handleary = all.orderPrimaryId+'-'+all.cartDetailId

    let ress = await axios({
        method  : 'post' , 
        url : `${Base_Url}/selectOrderProductToHandover`,
        headers: { 
            'Authorization': `Bearer ${token}` 
        },
        data : {
            cartDetailId : catid,
            orderId : id
        }
    })
    .then((res)=>{
        if(res.data.status === false ){
            
        }else{
            if( all?.handovered === 0 ){
                navigate('/handover_detail/'+res.data.Data.details.autoGenId  + '/' + catid + '/' + 1)
            }else{
                navigate('/handover_detail/'+res.data.Data.details.autoGenId  + '/' + catid + '/' + 0)
            }
        }
        console.log(res)
    })
}

//   const Renew = async () =>{
//     let res = await axios({
//         method : 'post' ,
//         url : `${Base_Url}/renewSingleRunningProduct`,
//         headers: { 
//             'Authorization': `Bearer ${token}` 
//         },
//         data : {
//             orderId : id,
//             rentType : rents,
//             fromDate : ((value.getMonth() > 8) ? (value.getMonth() + 1) : ('0' + (value.getMonth() + 1))) + '-' + ((value.getDate() > 9) ? value.getDate() : ('0' + value.getDate())) + '-' + value.getFullYear() ,
//             toDate : ((value2.getMonth() > 8) ? (value2.getMonth() + 1) : ('0' + (value2.getMonth() + 1))) + '-' + ((value2.getDate() > 9) ? value2.getDate() : ('0' + value2.getDate())) + '-' + value2.getFullYear(),
//             qty : monthCount
//         }
//     })
//     .then((res)=>{
//         if(res.data.status === true){
//             console.log(res,'resssssss')
//             navigate('/renew_order/'+ res.data.Data.orderId)
//         }
//         console.log(res)
//     })
//     .catch((err)=>{
//         console.log(err)
//     })
//   }

  return (
      <>
      <Header />
          <div className="shop-area pb-5" style={{backgroundColor: '#f1f1f1',paddingTop:'115px'}}>
              {
                  datas ? 
                  <div className="container whyycontainerrr" style={{backgroundColor: 'white',paddingTop:'40px' , height: '610px' }}>
                      <div className="row">
                          <div className="col-xl-7 col-lg-7 col-md-12">
                              <Maggni img={imgg?imgg:''} />
                          </div>
                          <div className="col-lg-5 col-lg-5 col-md-12">
                              <div className="product-details-content">
                                  <h2 className='my-1'>{datass?datass[0].ProName:''}</h2>
                                  <div className="product-details-price mb-1">
                                      {/* <span>Rs : {sta ? dummy : amount} /-</span> */}
                                      {/* <span className="old">Rs:20.00 </span> */}
                                  </div>
                                  <p className='product-details-contentrrrrp' >{datass[0].ProDescr?Html(datass[0].ProDescr):''}</p> 
                                  {/* <div className="pro-details-list mb-2">
                                      <ul>
                                          <li>- 0.5 mm Dail</li>
                                          <li>- Inspired vector icons</li>
                                          <li>- Very modern style  </li>
                                      </ul>
                                  </div> */}

                                  <div>
                                      <div className='row mb-2'>
                                          {   
                                          veri?.map(( dat , key )=>
                                              <div style={{cursor:'pointer'}}
                                            //    onClick={()=>press(dat,key)}
                                                className='col-4'>
                                                  <div className={(dat.selected===1 ? 'pro_conts' :'pro_cont1s')}>
                                                      <p style={{fontSize:'12px'}} className={(dat.selected===1 ? 'de_veri_css' :'propara5')}>{dat.ProSpecificName}</p>
                                                  </div>
                                              </div>
                                          )
                                          }
                                      </div>
                                  </div>
                                  <div className='row'>
                                    <div className='col-6' style={{ marginTop : 20 }} >
                                        {
                                            all?.handovered === 1 ? 
                                            <div onClick={()=>{
                                                    handclick()
                                                }} style={{ width : '100%' , height : 45 , backgroundColor : '#fff' , borderRadius :  5  , padding : 11 , border : '1px solid #161E40', cursor : 'pointer' }} >
                                                <p style={{ color : '#161E40' , fontSize : 10 , fontWeight :  '500' , textAlign : 'center' }} >Handover Requested</p>
                                            </div>
                                            : all?.handovered === 2 ? 
                                            <div onClick={()=>{
                                                    handclick()
                                                }} style={{ width : '100%' , height : 45 , backgroundColor : '#fff' , borderRadius :  5  , padding : 11 , border : '1px solid #161E40', cursor : 'pointer' }} >
                                                <p style={{ color : '#161E40' , fontSize : 14 , fontWeight :  '500' , textAlign : 'center' }} >Handovered</p>
                                            </div>
                                            : 
                                            <div onClick={()=>{
                                                    handclick()
                                                }} style={{ width : '100%' , height : 45 , backgroundColor : '#fff' , borderRadius :  5  , padding : 11 , border : '1px solid #161E40', cursor : 'pointer' }} >
                                                <p style={{ color : '#161E40' , fontSize : 14 , fontWeight :  '500' , textAlign : 'center' }} >Handover Now</p>
                                            </div>
                                        }
                                    </div>
                                    <div className='col-6'>
                                        {
                                            all?.serviced === 1 ?
                                            <Link to={'/service_status/'+catid+'/'+id+'/'+ 1}>
                                                <div className='boxxes_dt'>
                                                    <p style={{color:'white', textAlign:'center' , fontSize : 10}}>Service Requested</p>
                                                </div>
                                            </Link>
                                            :
                                            all?.serviced === 2 ?
                                            <Link to={'/service_status/'+catid+'/'+id+'/'+ 1}>
                                                <div className='boxxes_dt'>
                                                    <p style={{color:'white', textAlign:'center' , fontSize : 13}}>Serviced</p>
                                                </div>
                                            </Link>
                                            :
                                            <Link to={'/service_status/'+catid+'/'+id+'/'+ 1}>
                                                <div className='boxxes_dt'>
                                                    <p style={{color:'white', textAlign:'center' , fontSize : 13}}>Request Service</p>
                                                </div>
                                            </Link>
                                        }
                                    </div>
                                  </div>
                                 

                                  <div className='row mt-1'>
                                      <div className='col-6'>
                                          <p className='propara10 '>Rent Type</p>
                                          <ChakraProvider>
                                              <Select onChange={(e)=>setRent(e.target.value)} size={'sm'} placeholder='Select'>
                                                  {
                                                      rent?.map((data,key)=>
                                                          <option selected={key===0 ? 'selected' : ''} value={data.RentTypeName}>{data.RentTypeName}</option>
                                                      )
                                                  }

                                              </Select>
                                          </ChakraProvider>
                                      </div>
                                      <div className='col-6'>
                                          <p className='propara10'>Count</p>
                                          <ChakraProvider>
                                              <NumberInput onChange={(e) => setMonthCount(parseInt(e)) } size='sm' maxW={24} defaultValue={1} min={1} max={12}>
                                                  <NumberInputField />
                                                  <NumberInputStepper>
                                                      <NumberIncrementStepper />
                                                      <NumberDecrementStepper />
                                                  </NumberInputStepper>
                                              </NumberInput>
                                          </ChakraProvider>
                                      </div>
                                  </div>
                                  <p className='propara10 mt-2'>Booking Date</p>
                                  <div className='row mb-1'>
                                      <div className='col-4'>
                                          <DatePicker format='dd-MM-y' onChange={(e)=>calfun(e)} value={value} />
                                      </div>
                                      <div className='col-1'>
                                          <p  className='propara12 mt-1' style={{marginLeft:'6px'}}>To</p>
                                      </div>
                                      <div className='col-4'>
                                          <DatePicker format='dd-MM-y' disabled={true} className='datepick ' onChange={onChange2} value={value2} />
                                      </div>
                                  </div>
 
                                  {/* <p className='propara10 '>Qty</p>
                                  <ChakraProvider>
                                      <NumberInput onChange={(e) => setQty(parseInt(e)) } size='sm' maxW={24} defaultValue={1} min={1}>
                                          <NumberInputField />
                                          <NumberInputStepper>
                                              <NumberIncrementStepper />
                                              <NumberDecrementStepper />
                                          </NumberInputStepper>
                                      </NumberInput>
                                  </ChakraProvider> */}

                                  <div className="pro-details-quality pt-2 pb-4">
                                          <p style={{width:'200px'}} className='compara23333'>₹ {sta ? dummy : amount} /-</p>
                                          {
                                            all?.status === 1 ? <div  style={{cursor:'pointer'}} className="pro-details-cart ">
                                      
                                            <div   className='pro_cart_det_cart' >
                                            <p  style={{color:'white' , textAlign : 'center'}} >Renewd</p>
                                            </div>
                                        </div> : <div onClick={()=>Renew()} style={{cursor:'pointer'}} className="pro-details-cart ">
                                      
                                      <div   className='pro_cart_det_cart' >
                                      <p  style={{color:'white' , textAlign : 'center'}} > Renew</p>
                                      </div>
                                  </div>
                                          }
                                      
                                      <div className="pro-details-wishlist">
                                      {/* {(() => {
                                                if(wish != null){
                                                var result = wish.filter(x => x.datas.ProId == datass[0]?.ProId);
                                            if(result.length > 0){
                                                return ( <i onClick={()=>Clickheart(datass[0])} className='fa fa-heart hrt_red_clr fa-lg' > </i>)
                                                }else{
                                                    return ( <i onClick={()=>Clickheart(datass[0])} className='fa fa-heart-o fa-lg' > </i>)
                                                }
                                            }
                                            else{
                                                return ( <i onClick={()=>Clickheart(datass[0])} className='fa fa-heart-o fa-lg' > </i>)
                                            }
                                            })()} */}
                                      </div>
                                      {/* <div className="pro-details-compare">
                                          <a href="#"><i title='share' className="pe-7s-shuffle"></i></a>
                                      </div> */}
                                  </div>
                              </div>
                          </div>
                      </div>

                  </div>
              : 
              <div className="container">
                  <div className="row">
                      <div className="col-xl-7 col-lg-7 col-md-12">
                          <Skeleton height={'100%'} />
                      </div>
                      <div className="col-lg-5 col-lg-5 col-md-12">
                          <div className="product-details-content">
                          <Skeleton count={1} />
                              <div className="product-details-price mb-1">
                                  <Skeleton count={1} />
                              </div>
                              <Skeleton count={4} />
                              <div className="pro-details-list mb-2">
                                  <ul>
                                      <Skeleton count={1} />
                                      <Skeleton count={1} />
                                      <Skeleton count={1} />
                                  </ul>
                              </div>

                              <div>
                                  <div className='row mb-2'>
                                      <div className='col-4'>
                                         <Skeleton width={'100%'} height={'100%'} />
                                      </div>
                                      <div className='col-4'>
                                          <Skeleton width={'100%'} height={'100%'} />
                                      </div>
                                      <div className='col-4'>
                                          <Skeleton width={'100%'} height={'100%'} />
                                      </div>
                                  </div>
                              </div>


                              <div>
                                  <div className='row'>
                                      <div className='d-flex justify-content-start'>
                                          <Skeleton count={3} />
                                      </div>
                                  </div>
                              </div>

                              <div className='row mt-4'>
                                  <div className='col-6'>
                                      <div className='cool2 cool2_veri'>
                                          <Skeleton width={'100%'} height={'100%'} />
                                      </div>
                                  </div>
                                  <div className='col-6'>
                                      <Skeleton width={'100%'} height={'100%'} />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  </div>
              }
              


          </div>

          <ToastContainer />
          <Footer />
      </>
  )
} 
