import React , { useState , useEffect } from 'react'
import det from '../../../assets/img/det.png'
import './mob_pro_det.css'
import { useSelector , useDispatch } from 'react-redux'
import { Base_Url , ImageUrl } from '../../globalvariable/globe'
import axios from 'axios'
import { oneProduct } from '../../../redux/actions/productAction'
import { useParams } from 'react-router-dom'
import Html from 'html-react-parser'
import left from '../../../assets/img/left.png'
import { useNavigate } from "react-router-dom";

export default function Mob_pro_de() {

    let navigate = useNavigate();    

    const product = useSelector((state)=>state.oneproduct)
    const imgs = product.productImage
    const datas = product.productVariants
    const datass = product.productDetails
    const veri = product.productVariants
    const rent = product.BookingType

    const dispatch = useDispatch()

  const {id} = useParams()
  
  const bodyParameters = {
    key: "value"
  }; 

  const clikk = async () =>{
    let responce = await axios({
      method: 'post',
      url: `${Base_Url}/selectProduct`,
      bodyParameters, 
      data :{
        proId : id
      }
    })
    dispatch(oneProduct(responce.data.Data))
    
  }

  useEffect(()=>{
    clikk()
  },[])

  useEffect(()=>{
    console.log(imgs,'jkdfgjkdwsgfg')
  },[imgs])

  const goback = () => {
    navigate(-1)
  }

  return (
    <>
     <div className="tooot fixed-top navbar-light top-tab-nav">
            <div className="d-flex justify-content-between m-auto paddd" >
                <div onClick={()=>goback()} className='d-flex loab'>
                    <img src={left} />
                    <p className='propara13'>Product Details</p>
                  </div>
            </div>
        </div>
      <div className='row'>
            <div className='col-3'>
                {
                    imgs ? <img style={{width:'100%'}} src={ImageUrl+'/'+imgs[0].ProImgName} /> : ''
                }
            </div>
            <div className='col-9'>
                <p className='propara2'>{datass?datass[0].ProName:''}</p>
                <p className='prodett1'>{datass?Html( datass[0].ProDescr):''}</p>
                <p className='prodett2'>₹ {datas?datas[0].ProSpecificMonthly:''}/M</p>
            </div>
      </div>
      <div className='det_div'>
      <p className='prodett3'>All Details</p>
        <table >
        {
                                                     product?.webAppConfignew?.map((data,key)=>
                                                    <>
                                                        <p style={{marginTop : 10 , fontWeight : '700' , marginBottom :10}}>{data.name}</p>
                                                        <table>
                                                            {
                                                                data.values.map((datasss,key)=>
                                                                    
                                                                        key >= 5 ? '' : <tr>
                                                                            <th style={{ width : '50%' }} >{datasss.name}</th>
                                                                            <th style={{ width : '50%' }} >{datasss.value}</th>
                                                                        </tr>
                                                                    
                                                                )
                                                            }
                                                        </table>
                                                    </>
                                                    ) 
                                                
                                                }
        </table>
      </div>
    </>
  )
}