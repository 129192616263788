import React from 'react'

export default function Heading_comp(props) {
    console.log(props)
  return (
    <>
       <div style={{paddingTop:'25px'}}>
            <div style={{ position : 'relative' , zIndex : 7 }} className="pro_groub_head">
                {props.text}
            </div>
        </div>
    </>
  )
}