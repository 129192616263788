import React from 'react'
import blabla from '../../../assets/img/blabla.png'

export default function Service_pick_comp() {

    const data = [
        {
            "id" : "#51h8515",
            "no" : "56884365453",
            "det" : "APPLE 2020 Macbook Pro M1 - (8/512 GB SSD/Mac OS Big Sur)",
            "img" : blabla
        },
        {
            "id" : "#51tj8515",
            "no" : "56884365453",
            "det" : "APPLE 2020 Macbook Pro M1 - (8/512 GB SSD/Mac OS Big Sur)",
            "img" : blabla
        },
        {
            "id" : "#51ty8515",
            "no" : "56884365453",
            "det" : "APPLE 2020 Macbook Pro M1 - (8/512 GB SSD/Mac OS Big Sur)",
            "img" : blabla
        },
        {
            "id" : "#577u18515",
            "no" : "56884365453",
            "det" : "APPLE 2020 Macbook Pro M1 - (8/512 GB SSD/Mac OS Big Sur)",
            "img" : blabla
        },
        {
            "id" : "#5178515",
            "no" : "56884365453",
            "det" : "APPLE 2020 Macbook Pro M1 - (8/512 GB SSD/Mac OS Big Sur)",
            "img" : blabla
        }
    ]

  return (
    <>
        <div>
            <p className='qr_seventeen'>Details</p>
            <div className='row'>
                <div className='col-6'>
                    <p className='qr_sighteen'>Order ID</p>
                    <p style={{marginTop:'-15px'}} className='qr_sighteen'>Subscribtion Period</p>
                    <p style={{marginTop:'-15px'}} className='qr_sighteen'>Days Left</p>
                </div>
                <div className='col-1'>
                    <p className='qr_sighteen'>:</p>
                    <p style={{marginTop:'-15px'}} className='qr_sighteen'>:</p>
                    <p style={{marginTop:'-15px'}} className='qr_sighteen'>:</p>
                </div>
                <div className='col-5'>
                    <p className='qr_sighteen'>#518515</p>
                    <p style={{marginTop:'-15px'}} className='qr_sighteen'>3rd Mar - 2nd May 22</p>
                    <p style={{marginTop:'-15px'}} className='qr_sighteen'>20 Days</p>
                </div>
            </div>
        </div>
        <p className='qr_twenty mt-2'>Items ({data.length})</p>
        {data.map((det,key)=>
            <div className='qr_twentyone mt-3'>
                <div className='row'>
                    <div className='col-8'>
                        <p className='qr_eight '>ID : {det.id}</p>
                        <p className='qr_eight' style={{marginTop:'-18px'}}>No : {det.no}</p>
                        <p className='qr_twentytwo' style={{marginTop:'-18px'}}>{det.det}</p>
                    </div>
                    <div className='col-4'>
                        <img className='mt-3' src={det.img} />
                    </div>
                </div>
            </div>
        )}
    </>
  )
}
