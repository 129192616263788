import React from 'react'
import Service_pick_comp from '../../components/mobile_view_comp/service_pick_comp/service_pick_comp'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'
import Ser_foot from '../../components/mobile_view_comp/service_pick_comp/ser_foot'

export default function Service_pick() {
  return (
    <>
        <div style={{width:'90%' , margin:'auto' , paddingBottom:'100px',marginTop:'65px'}}>
            <Cart_one_head link='/handover_req' head='Service Request' />
            <Service_pick_comp />
        </div>
        <Ser_foot />
    </>
  )
}