import React from 'react'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'
import Mob_kyc_two from '../../components/mobile_view_comp/kyc_two/mob_kyc_two' 
import Circle_two from '../../components/mobile_view_comp/kyc_two/circle_two'

export default function Kyc_two() {
  return (
    <>
      <div style={{width:'90%' , margin:'auto' ,marginTop:'65px'}}>
        <Cart_one_head link='/kyc_one'  head='Kyc' />
       
        {/* <Circle_two /> */}
        <Mob_kyc_two />
      </div>
    </>
  )
}