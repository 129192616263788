import React from 'react'
import map from '../../assets/img/map.png'

export default function New_address_comp() {
  return (
    <>
      <div className='new_add_one'>
        <div className='row'>
            <div className='col-8'>
                <p className='new_add_two'>Choose On Map</p>
            </div>
            <div className='col-4'>
                <img className='new_add_three' src={map} />
            </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-6'>
          <div className='mt-3'>
            <p className='kyc_two_para'>First Name</p>
            <input type="email" className="form-control kyc_in_one" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="User 12" />
          </div>
        </div>
        <div className='col-6'>
          <div className='mt-3'>
            <p className='kyc_two_para'>last Name</p>
            <input type="email" className="form-control kyc_in_one" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="21user" />
          </div>
        </div>
      </div>
      <div className='mt-3'>
        <p className='kyc_two_para'>Address</p>
        <input type="email" className="form-control kyc_in_one" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="16/5, Vedhandham Street." />
      </div>
      <div className='mt-3'>
        <p className='kyc_two_para'>Appartment, Suite, Villa, Etc...</p>
        <input type="email" className="form-control kyc_in_one" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="43c, Vijay Appartments" />
      </div>
      <div className='mt-3'>
        <p className='kyc_two_para'>City</p>
        <input type="email" className="form-control kyc_in_one" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Chennai" />
      </div>
      <div className='mt-3'>
        <p className='kyc_two_para'>State</p>
        <input type="email" className="form-control kyc_in_one" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Tamil Nadu" />
      </div>
      <div className='mt-3'>
        <p className='kyc_two_para'>Postal Code</p>
        <input type="email" className="form-control kyc_in_one" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="600001" />
      </div>
      <div className='ed_pro_seventeen mt-5'>
          <p className='ed_pro_eighteen'>Save Changes</p>
      </div>
    </>
  )
}