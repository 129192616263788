import React , { useState , useEffect } from 'react'
import cart_lap from '../../../assets/img/cart_lap.png' 
import headset from '../../../assets/img/ggerhead.png'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch ,  useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
import { UpdateViewCart } from '../../../redux/actions/productAction'
import { ImageUrl } from '../../globalvariable/globe'
import {
    useNumberInput,
    Button,
    HStack,
    Input
  } from '@chakra-ui/react'
import pl from '../../../assets/img/pl.png'
import minus from '../../../assets/img/minus.png'
import { OrderConfirm } from '../../../redux/actions/productAction';
import { Base_Url } from '../../globalvariable/globe';
import { useParams } from 'react-router-dom'

export default function Cart_one_container() {

    const {id} = useParams()

    let dispatch = useDispatch()
    let navigate = useNavigate()

    let user = localStorage.getItem('user_data')

    let product =  useSelector((state)=>state)
    let datas = product.viewcart.cartDetails
    let alladdress = product.viewcart.address
    let d = product.viewcart.cartDetails
    let ids = product.viewcart.orderId

    let one= 0 ;
    let two = 0 ;

    const [ price , setPrice ] = useState(0)
    const [ twoprice , settwoPrice ] = useState(0) 

    const [ addressid, setAddressid ] = useState()

    const [ sw , setSw ] = useState(0)

    let uusid = localStorage.getItem('user_type')
    
    const errnotify = (dat) => toast.error( dat , {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
  
      const susnotify = (dat) => toast.success( dat , {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      useEffect(()=>{
        setAddressid(alladdress? alladdress[0]?.UserDelivAddrId : '')
        let one = 0
        d?.map((data,key)=>{
            two = two + data.CartProChangeDepAmount
            one = one + data.CartProChangeRentAmount   
        })
        setPrice(one)
        settwoPrice(two)
    },[d]) 

    const plus = ( dat , key ) =>{

        setPrice(0)
  
        let dataa = parseInt(dat.CartProQty) + 1 
  
        d.map((data , key)=>{
            if(data.CartId===dat.CartId){
                if(dat.CartProQty >= 10 ){
                    let daa = 'Max Value is 10'
                    errnotify(daa)
                    return
                }
                data.CartProChangeDepAmount = dat.CartProDepAmnt*dataa
                data.CartProQty = dataa
                data.CartProChangeRentAmount = dat.CartProRentAmnt * dataa 
            }
            
        })
        
        dispatch(UpdateViewCart(d))
  
        d?.map((data,key)=>{
            two = two + data.CartProChangeDepAmount
            one = one + data.CartProChangeRentAmount
  
        })
        setPrice(one)
        settwoPrice(two)
  
        const Finish = async()=>{
            let responce = await axios({
                method : 'post',
                url : `${Base_Url}/viewOrderProductDetails`,
                headers: {  
                    'Authorization': `Bearer ${user}` 
                },
                data : {
                  orderId : id
                }
            })
            .then((res)=>{
                
                // console.log(res) 
            })
            .catch((err)=>{
                if(err.response.status === 401){
                    navigate('/login')
                }
                console.log(err)
            })
        }
  
        Finish()
    }
  
    const minuss = ( dat , key ) =>{
  
        setPrice(0)
  
        let dataa = parseInt(dat.CartProQty)-1 
  
        d.map((data , key)=>{
            
            if(data.CartId===dat.CartId){
                if(dat.CartProQty <= 1 ){
                    let daa = 'Min Value required'
                    errnotify(daa)
                    return
                }
                data.CartProChangeDepAmount = dat.CartProDepAmnt * dataa
                data.CartProQty = dataa
                data.CartProChangeRentAmount = dat.CartProRentAmnt * dataa
            }
            
        })
        dispatch(UpdateViewCart(d))
  
        d?.map((data,key)=>{
          two = two + data.CartProChangeDepAmount
          one = one + data.CartProChangeRentAmount 
        })
        setPrice(one)
        settwoPrice(two)
  
        const Finish = async()=>{
            let responce = await axios({
                method : 'post',
                url : `${Base_Url}/viewOrderProductDetails`,
                headers: { 
                    'Authorization': `Bearer ${user}` 
                },
                data : {
                  orderId : id
                }
            })
            .then((res)=>{
                // console.log(res)
            })
            .catch((err)=>{
                if(err.response.status === 401){
                    navigate('/login')
                }
                console.log(err)
            })
        }
  
        Finish()
  
    }

    const Confirm = async()=>{ 
        let store = localStorage.getItem('user_data')
        if( store === null ){
          let er = 'You Must Login First'
          errnotify(er)
          return
        }else{
          let responce = await axios({
            method : 'post' ,
            url : `${Base_Url}/updateAddress` , 
            headers: { 
              'Authorization': `Bearer ${user}` 
            },
            data : {
                orderId : id ,
                addressId : addressid
            }
          })
          .then((res)=>{
            dispatch(OrderConfirm(res.data))
            console.log(res,'hihu8')
            if(res.data.status === false){
                errnotify('Add Address')
            }
            if( res.data.kycType === 6 ){
            console.log(id,'iddddddddddd')
              navigate('/payment/0/'+id)
              return
            }
            
            if( res.data.kycType === 1 ){
              navigate('/kyc_one')
            }else if( res.data.kycType === 2 ){
              navigate('/kyc_two')
            }else if( res.data.kycType === 3 ){
              navigate('/kyc_three')
            }else if( res.data.kycType === 4 ){
              navigate('/kyc_four')
            }else if( res.data.kycType === 5 ){
              navigate('/kyc_five')
            }
    
          })
          .catch((err)=>{
            console.log(err)
            let er = 'Something Went Wrong'
            if(err.response.status === 401){
                navigate('/login')
            }
            errnotify(er)
          })
        }
      }
const switch_data = (data,key) =>{
    console.log(data, 'datatatatt')
    setAddressid(data.UserDelivAddrId)
    setSw(key)
}



  return (
    <>
    <div>
        <p style={{fontWeight:'600'}} className='mt-3'>Payment Id : {ids?ids:''}</p>
    </div>
    <div className='row mt-3'>
        <div className='col-6'>
            <p className='cart_ppp2'>Deliver to</p>
        </div>
        <div className='col-6'>

            {
                uusid === 'corporate' ?

                <Link to='/corporate_contact/2'>
                    <p className='cart_ppp3'>Manage Address</p>
                </Link>
            :
            <Link to='/address'>
                <p className='cart_ppp3'>Manage Address</p>
            </Link>
            }
        </div>
    </div>
      
    { alladdress?.map((data,key)=>
        <div onClick={()=>switch_data(data,key)} className={(sw===key? 'containneerr' : 'containneerrr')}>
            <div className='row p-2'>
            <div className='col-10'>
                <p className='rob_obe'>{data.UserDelivAddrFName}</p>
                <p className='rob_two'>{data.UserDelivAddrAddress}</p>
            </div>
            <div style={{margin:'auto'}} className='col-2'>
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={(sw===key ? 'checked' : '')} />
            </div>
            </div>
        </div>
    )}
    <p className='card_final_p my-4'>Package Contains :</p>
    <div style={{paddingBottom:'70px'}}>
    {
        datas?.map((dta,key)=>{
        return(
            <div className='final_cooon  mb-3'>
                <div className='row p-3'>
                    <div className='col-8'>
                        <p className='paraonesss mt-1'>{dta.ProName}</p>
                        <p className='paraonessso'>Qty : {dta.CartProQty}</p>


                            {/* <div className='row' style={{marginTop:'10px' , paddingBottom:'6px' , backgroundColor:'white' , borderRadius:'5px' , width:'60%' , marginLeft:'0px'}}>

                                <div className='col-4'>
                                    <div onClick={()=>minuss(dta,key)} style={{marginLeft:'5px'}}>
                                        <img style={{margin:'auto' , paddingTop:'15px'}} src={minus} />
                                    </div>
                                </div>

                                <div className='col-4'>
                                    <p style={{marginTop:'8px'}} className='cartotwo'>{dta.CartProQty}</p>
                                </div>

                                <div className='col-4'>
                                    <div onClick={()=>plus(dta,key)} >
                                        <img  style={{margin:'auto' , paddingTop:'5px' , display:'block'}} src={pl} />
                                    </div>
                                </div>

                            </div> */}


                    </div>
                    <div  className='col-4'>
                        <img style={{ width:'100%'}} src={ImageUrl+'/'+dta.ProImgPrimary} />
                    </div>
                </div>
            </div>
        )
        })
    }
    
    </div>
    <div className='mob_footss'>
        <div className='row'>
            <div className='col-6'>
                <div className='d-flex'>
                    <div className='headset'>
                    <img className='kaart' src={headset} />
                    </div>
                    <Link to='/support'>
                        <div >
                            <p className='compara222'>Support</p>
                            <p className='compara333'>9am - 5Pm</p>
                        </div>
                    </Link>
                </div>
            </div>
            <div className='col-6'>
                <div onClick={()=>Confirm()} className='bbtn'>
                    <div className='d-flex'>
                        <p className='compara nextonee mt-1'>Confirm</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ToastContainer />

    </>
  )
}