import React from 'react'
import { useSelector } from 'react-redux'
import { ImageUrl } from '../globalvariable/globe'
import { Link } from 'react-router-dom'

export default function Web_offers() {

    const product =  useSelector((state)=>state)
    const datas = product?.allProducts?.products?.offer
    console.log(datas,'offfferrrrr')

    return ( 
        <div className='container web_cat_five mb-5'>
            <div className='row mt-2'>
                <div>
                    {
                      datas?.length === 0 ? ''    : <h1 className='pop_head_tit mb-5'>Shop By Discounts</h1>
                    }
                </div>
                <div className='row'>
                    {
                        datas?.map((data,key)=>{
                            return(
                                <div className='col-3'>
                                    <Link to={'/offer/'+data.OfferCategory}>
                                        <img style={{margin:'auto', width:'100%'}} src={ImageUrl+'/'+data.OfferImage} />
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
