import React from 'react'
import logo from '../../../assets/img/icon.png'

export default function Mob_login_head() {
  return (
    <>
      <div className=" fixed-top navbar-light tooot top-tab-nav">
            <div className="d-flex justify-content-between m-auto paddd" >
               <div  className='loab'>
                   <img className='imglogo' src={logo} />
                </div>
               <div style={{textAlign:'right'}} className='loab'>
                   
               </div>
            </div>
        </div>
    </>
  )
}