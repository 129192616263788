import React , { useEffect , useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ChakraProvider,
  useDisclosure,
  Button,
  FormLabel,
  Input
} from '@chakra-ui/react'
import axios from 'axios';
import { useDispatch ,  useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { UpdateViewCart } from '../../../redux/actions/productAction'; 
import { ImageUrl } from '../../globalvariable/globe';
import { ViewCart , OrderConfirm , LoogIn} from '../../../redux/actions/productAction';
import { useNavigate , Link } from 'react-router-dom';
import { Base_Url } from '../../globalvariable/globe';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom'
import ManageAddress from '../../address/address';
import ReactLoading from 'react-loading';
import { faL } from '@fortawesome/free-solid-svg-icons';


export default function Cart_detail() {

    const {id} = useParams()

    let user = localStorage.getItem('user_data')
    let navigate = useNavigate()

    let product =  useSelector((state)=>state)
    let datas = product.viewcart.cartDetails
    let alladdress = product.viewcart.address
    let ids = product.viewcart.orderId
    let d = product.viewcart.cartDetails

    let skl = [ 1 , 2 ]    

    let one= 0 ;
    let two = 0 ;

    const [ price , setPrice ] = useState(0)
    const [ twoprice , settwoPrice ] = useState(0)

    const { isOpen, onOpen, onClose } = useDisclosure()

    
    const [ loading , setloading ] = useState(false)

    const [ sw , setSw ] = useState(0)

    const [ num, setNum ] = useState(1)

    let dispatch = useDispatch()

    const [ newAddresses , setnewAddresses ] = useState(false)

    const [ editupdate , setEditupdate ] = useState(false)

    const [ fname , setFname ] = useState('')
    const [ lname , setLname ] = useState('')
    const [ city , setCity ] = useState('')
    const [ state , setState ] = useState('')
    const [ pincode , setPincode ] = useState('')
    const [ geocode , setGeocode ] = useState('')
    const [ newaddress , setNewaddress ] = useState('')
    const [ subaddress , setSubaddress ] = useState('')

    const [ fnames , setFnames ] = useState(false)
    const [ lnames , setLnames ] = useState(false)
    const [ citys , setCitys ] = useState(false)
    const [ states , setStates ] = useState(false)
    const [ pincodes , setPincodes ] = useState(false)
    const [ geocodes , setGeocodes ] = useState(false)
    const [ newaddresss , setNewaddresss ] = useState(false)
    const [ subaddresss , setSubaddresss ] = useState(false)
    const [ addressid, setAddressid ] = useState()
    const [ editid , setEditId ] = useState('')


    let uusid = localStorage.getItem('user_type')

    const errnotify = (dat) => toast.error( dat , {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    const susnotify = (dat) => toast.success( dat , {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    useEffect(()=>{
      if(num<1){
        setNum(1)
      }
    },[num])

    useEffect(()=>{
      setAddressid(alladdress? alladdress[0]?.UserDelivAddrId : '')
      let one = 0
      d?.map((data,key)=>{
          two = two + data.CartProChangeDepAmount
          one = one + data.CartProChangeRentAmount 

      })
      setPrice(one)
      settwoPrice(two)
  },[d]) 

  useEffect(()=>{
    setnewAddresses(false)
    setFnames(false)
    setLnames(false)
    setCitys(false)
    setStates(false)
    setPincodes(false)
    setGeocodes(false)
    setNewaddresss(false)
    setSubaddresss(false)
    setEditupdate(false)

    navigator.geolocation.getCurrentPosition(function(position) {
    });

  },[])

  const Addsub = async()=>{
    if( fname === ''|| lname === ''|| city === ''|| state === ''|| pincode === ''|| geocode === ''|| newaddress === ''|| subaddress === ''){

      if(fname === '' ){
        setFnames(true)
      }else{
        setFnames(false)
      }

      if(lname === '' ){
        setLnames(true)
      }else{
        setLnames(false)
      }

      if(city === '' ){
        setCitys(true)
      }else{
        setCitys(false)
      }

      if(state === '' ){
        setStates(true)
      }else{
        setStates(false)
      }

      if(pincode === '' ){
        setPincodes(true)
      }else{
        setPincodes(false)
      }

      if(geocode === '' ){
        setGeocodes(true)
      }else{
        setGeocodes(false)
      }

      if(newaddress === '' ){
        setNewaddresss(true)
      }else{
        setNewaddresss(false)
      }

      if(subaddress === '' ){
        setSubaddresss(true)
      }else{
        setSubaddresss(false)
      }
      return
    }else{
      setFnames(false)
      setLnames(false)
      setCitys(false)
      setStates(false)
      setPincodes(false)
      setGeocodes(false)
      setNewaddresss(false)
      setSubaddresss(false)
    }

    let responce = await axios({
      method : 'post',
      url : `${Base_Url}/addUserDeliveryAddress` ,
      headers: { 
          'Authorization': `Bearer ${user}` 
      },
      data : {
        firstName : fname ,
        lastName : lname ,
        deliveryAddress : newaddress ,
        appartment : subaddresss ,
        city : city ,
        state : state ,
        post : pincode
      }
    })
    .then((res)=>{
     
      if(res.data.status==false){
        let er = res.data.Data
        errnotify(er)
      }else{
      let sus = 'Address Added Successfully'
      susnotify(sus)
      onClose()
      allData()
      }
    })
    .catch((err)=>{
     
      if(err.response.status === 401){
        dispatch(LoogIn({show : true}))
        // navigate('/')
      }
      if(res.data.status==false){
        let er = res.data.message
        errnotify(er)
      }
    })

  }

  const allData = async()=> {

    let user = localStorage.getItem('user_data')

    if ( user === null ){

      return
    }
    let responce = await axios({
            method : 'post',
            url : `${Base_Url}/viewOrderProductDetails` ,
            headers: { 
                'Authorization': `Bearer ${user}` 
            },
            data : {
              orderId : id
            }
        })
        .then((res)=>{
            if(res.data.status === true){
                dispatch(ViewCart(res.data.Data))
            }else{

            }
        })
        .catch((err) => {
          if(err.response.status === 401){
            dispatch(LoogIn({show : true}))
            // navigate('/')
          }
        })
    }


    const switch_data = (data,key) =>{
      setAddressid(data.UserDelivAddrId)
      setSw(key)
    }

    const handleClick = () =>{
      onOpen()
      setnewAddresses(false)
      setFnames(false)
      setLnames(false)
      setCitys(false)
      setStates(false)
      setPincodes(false)
      setGeocodes(false)
      setNewaddresss(false)
      setSubaddresss(false)
      setEditupdate(false)
    }

    const plus = ( dat , key ) =>{

      setPrice(0)

      let dataa = parseInt(dat.CartProQty) + 1 

      d.map((data , key)=>{
          if(data.CartId===dat.CartId){
              if(dat.CartProQty >= 10 ){
                  let daa = 'Max Value is 10'
                  errnotify(daa)
                  return
              }
              data.CartProChangeDepAmount = dat.CartProDepAmnt*dataa
              data.CartProQty = dataa
              data.CartProChangeRentAmount = dat.CartProRentAmnt * dataa 
          }
          
      })
      
      dispatch(UpdateViewCart(d))

      d?.map((data,key)=>{
          two = two + data.CartProChangeDepAmount
          one = one + data.CartProChangeRentAmount 

      })
      setPrice(one)
      settwoPrice(two)

      const Finish = async()=>{
          let responce = await axios({
              method : 'post',
              url : `${Base_Url}/viewOrderProductDetails`,
              headers: { 
                  'Authorization': `Bearer ${user}` 
              },
              data : {
                orderId : id
              }
          })
          .then((res)=>{
              
              // console.log(res) 
          })
          .catch((err)=>{
            if(err.response.status === 401){
              dispatch(LoogIn({show : true}))
              // navigate('/')
            }
              console.log(err)
          })
      }

      Finish()
  }

  const minuss = ( dat , key ) =>{

      setPrice(0)

      let dataa = parseInt(dat.CartProQty)-1 

      d.map((data , key)=>{
          
          if(data.CartId===dat.CartId){
              if(dat.CartProQty <= 1 ){
                  let daa = 'Min Value required'
                  errnotify(daa)
                  return
              }
              data.CartProChangeDepAmount = dat.CartProDepAmnt * dataa
              data.CartProQty = dataa
              data.CartProChangeRentAmount = dat.CartProRentAmnt * dataa
          }
          
      })
      dispatch(UpdateViewCart(d))

      d?.map((data,key)=>{
        two = two + data.CartProChangeDepAmount
        one = one + data.CartProChangeRentAmount 
      })
      setPrice(one)
      settwoPrice(two)

      const Finish = async()=>{
          let responce = await axios({
              method : 'post',
              url : `${Base_Url}/viewOrderProductDetails`,
              headers: { 
                  'Authorization': `Bearer ${user}` 
              },
              data : {
                orderId : id
              }
          })
          .then((res)=>{
          })
          .catch((err)=>{
            if(err.response.status === 401){
              dispatch(LoogIn({show : true}))
              // navigate('/')
            }
              console.log(err)
          })
      }

      Finish()

  }

  const editAddress = ( data , key ) => {
    setEditupdate(true)

    setnewAddresses(!newAddresses)
    setFname(data.UserDelivAddrFName)
    setLname(data.UserDelivAddrLName)
    setCity(data.UserDelivAddrCity)
    setState(data.UserDelivAddrState)
    setPincode(data.UserDelivAddrPost)
    setNewaddress(data.UserDelivAddrAddress)
    setSubaddress(data.UserDelivAddrAppart)
    setEditId(data.UserDelivAddrId)
  }

  const adnewwww = () =>{
    setEditupdate(false)
    setnewAddresses(!newAddresses)
    setFname('')
    setLname('')
    setCity('')
    setState('')
    setPincode('')
    setNewaddress('')
    setSubaddress('')
  }
 
  const Update = async () =>{
    let responce = await axios({
      method : 'post',
      url : `${Base_Url}/updateUserDeliveryAddress`,
      headers: { 
          'Authorization': `Bearer ${user}` 
      },
      data : {
        firstName : fname ,
        lastName : lname ,
        deliveryAddress : newaddress ,
        appartment : subaddresss ,
        city : city ,
        state : state ,
        post : pincode,
        addressId : editid
      }
    })
    .then((res)=>{
      if(res.data.status==false){
        let er = res.data.Data
        errnotify(er)
      }else{
        let sus = 'Address Update Successfully'
        susnotify(sus)
        onClose()
        allData()
      }
    })
    .catch((err)=>{
      if(err.response.status === 401){
        dispatch(LoogIn({show : true}))
        // navigate('/')
      }
    })
  }

  const Confirm = async()=>{

    let store = localStorage.getItem('user_data')
    if( store === null ){
      let er = 'You Must Login First'
      errnotify(er)
      return
    }else{
      setloading(true)
      let responce = await axios({
        method : 'post' ,
        url : `${Base_Url}/updateAddress` , 
        headers: { 
          'Authorization': `Bearer ${user}` 
        },
        data : {
          orderId : id ,
          addressId : addressid
        }
      })
      .then((res)=>{
        setloading(false)
        if(res.data.status === false){
          errnotify(res.data.message)
        }
        dispatch(OrderConfirm(res.data))
        
        if( res.data.kycType === 6 ){
         
          
          navigate('/payment/0/'+id)
          return
        }
        
        if( res.data.kycType === 1 ){
          navigate('/kyc_one')
        }else if( res.data.kycType === 2 ){
          navigate('/kyc_two')
        }else if( res.data.kycType === 3 ){
          navigate('/kyc_three')
        }else if( res.data.kycType === 4 ){
          navigate('/kyc_four')
        }else if( res.data.kycType === 5 ){
          navigate('/kyc_five')
        }

      })
      .catch((err)=>{
        setloading(false)
        console.log(err)
        let er = 'Something Went Wrong'
        errnotify(er)
        if(err.response.status === 401){
          dispatch(LoogIn({show : true}))
          // navigate('/')
        }
      })
    }
  }

  const deleteAddress = async ( data , key ) =>{
      let responce = await axios({
        method : 'post' ,
        url : `${Base_Url}/deleteUserDeliveryAddress` , 
        headers: { 
          'Authorization': `Bearer ${user}` 
        },
        data :{
          addressId : data.UserDelivAddrId
        }
      })
      .then((res)=>{
        let sus = 'Address Delected Successfully'
        susnotify(sus) 
        allData() 
      })
      .catch((err)=>{
        console.log(err)
        let er = 'Something Went Wrong'
        errnotify(er)
        if(err.response.status === 401){
          dispatch(LoogIn({show : true}))
          // navigate('/')
        }
      })
  }

  return (
    <>
        <div style={{marginTop:'150px'}} >
            <div className='container'>
                <div className='row'>

                    <div  className='col-12'>
                        <div className='web_cart_col_8'>
                            <div className='web_cart_col_one'>
                                <div className='row'>
                                    <p className='web_cart_col_two'>Payment Id : {ids?ids:''}</p>
                                </div>
                            </div>
                            <hr />

                            <div className='row'>
                              <div className='d-flex justify-content-between'>
                                <p className='web_cart_col_four mt-3' style={{marginLeft:'14px'}} >Deliver to</p>
                                {
                                  uusid === 'corporate' ?
                                  <div  >
                                    <Link to='/corporate_contact/2'>
                                      <p style={{ marginTop : 20 , fontSize : 15 }} className='cart_ppp3'>Manage Address</p>
                                    </Link>
                                  </div>
                                  :
                                <div>
                                  <ManageAddress />
                                </div>
                                }
                                
                              </div>
                            </div>

                            <div className='row px-3'>

                              { alladdress?.map((data,key)=>{
                                return(
                                <div onClick={()=>switch_data(data,key)}  className='col-6'>
                                  <div className={(sw===key? 'web_cart_col_mone mt-3' : 'web_cart_col_mfive mt-3')} >
                                    <div className='row'>
                                      <div className='col-10'>
                                        <p className='web_cart_col_mtwo'>{data.UserDelivAddrFName}</p>
                                        <p className='web_cart_col_mthree'>{data.UserDelivAddrAddress}</p>
                                      </div>
                                      <div className='col-2'>
                                        <input style={{marginTop : '29px'}} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={(sw===key ? 'checked' : '')} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                )
                              })
                              }

                            </div>
                            <div className='row'>

                              { !alladdress && skl.map((data,key)=>{
                                return(
                                <div className='col-6'>
                                    <Skeleton width='90%' height='88px' /> 
                                </div>
                                )
                              })
                              }

                            </div>

                            <p className='web_cart_col_msix'>Package Contains :</p>
                            {
                              datas?.map((dta,key)=>{
                                return(
                                  <>
                                    <div className='row p-4'>

                                      <div className='col-2'>
                                          <img style={{ width : '72%' , margin : 'auto' }} src={ImageUrl+'/'+dta.ProImgPrimary} /> 
                                      </div>

                                      <div className='col-6 '>
                                          <p className='web_cart_col_three' style={{marginTop:'20px'}} >{dta.BrandName}</p>
                                          <p className='web_cart_col_four' style={{marginBottom:'-5px'}} >{dta.ProName}</p>
                                          {/* <p className='web_cart_col_five mt-2' style={{marginBottom:'-10px'}} >256/8 gb , 13.7” Retina Display</p> */}
                                          <p className='web_cart_col_four mt-2'>Qty : {dta.CartProQty}</p>
                                      </div>

                                      <div className='col-2 mt-2'>
                                        {/* <div className='row' style={{marginTop:'10px' , paddingBottom:'6px' , backgroundColor:'beige' , borderRadius:'5px'}}>

                                            <div className='col-4'>
                                                <div onClick={()=>minuss(dta,key)} style={{marginLeft:'5px'}}>
                                                    <img style={{margin:'auto' , paddingTop:'15px'}} src={minus} />
                                                </div>
                                            </div>

                                            <div className='col-4'>
                                              <p style={{marginTop:'8px'}} className='cartotwo'>{dta.CartProQty}</p>
                                            </div>

                                            <div className='col-4'>
                                                <div onClick={()=>plus(dta,key)} >
                                                    <img  style={{margin:'auto' , paddingTop:'5px' , display:'block'}} src={pl} />
                                                </div>
                                            </div>

                                        </div> */}
                                      </div>
                                    </div>
                                    {datas?.length === key+1 ? '' : <hr /> }

                                  </>
                                )
                              })
                            }
                            {
                              !datas && skl.map((dta,key)=>{
                                return(
                                <>
                                  <div className='row p-4'>
                                      <div className='col-2'> 
                                        <Skeleton width={'100%'} height={'100%'} />  
                                      </div>
                                      <div className='col-6 mt-1'>
                                          <Skeleton count={1} />
                                          <Skeleton count={1} />
                                          <Skeleton count={1} />
                                          <Skeleton count={1} />
                                      </div>
                                      <div className='col-4 mt-2'>
                                          <div className='row'>
                                              <div className='col-6'>
                                                  <Skeleton count={1} />
                                                  <Skeleton count={1} />
                                                  <div className='web_cart_col_eight mt-1'>
                                                      <div className='d-flex justify-content-center'>
                                                          <Skeleton width={'100%'} height={'100%'} />
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className='col-6'>
                                              <Skeleton count={1} />
                                                  <Skeleton count={1} />
                                                  <div className='web_cart_col_eight mt-1'>
                                                      <div className='d-flex justify-content-center'>
                                                          <Skeleton width={'100%'} height={'100%'} />
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                </>
                                )         
                              })
                            }
                            {
                              loading === true ? 
                              <div style={{ marginLeft : 'auto' }} className='web_cart_col_twenty_one'>
                                <ReactLoading style={{ fill : '#fff' , margin :  'auto' , width : 64 , height : 64 , paddingTop : 13}} type='balls' color='#ffffff' />
                              </div>

                              : 

                              <div onClick={()=> Confirm()} style={{ marginLeft : 'auto' }} className='web_cart_col_twenty_one'>
                                <p style={{cursor:'pointer'}} className='web_cart_col_twenty_two'>Confirm</p>
                              </div>
                            }
                            
                        </div>
                    </div>
                    {/* {
                      alladdress && (
                      <div className='col-4'>
                        <div className='web_cart_col_4'  >
                            <p className='web_cart_col_two' style={{padding:'10px'}}>PRICE DETAILS</p>
                            <hr />
                            <div className='row'>
                                <div className='col-8'>
                                    <p className='web_cart_col_eleven' >Price ({ datas ? datas.length :''} items)</p>
                                    <p className='web_cart_col_eleven' >Deposit Amount</p>
                                    <p className='web_cart_col_eleven' >Discount</p>
                                    <p className='web_cart_col_eleven' >Delivery Charges</p>
                                    <hr/>
                                    <p className='web_cart_col_four' style={{marginTop:'20px',marginBottom:'20px'}} >Total Amount</p>
                                </div>
                                <div className='col-4'>
                                    <p className='web_cart_col_eleven' style={{textAlign:'right'}} >₹{price}</p>
                                    <p className='web_cart_col_twelve' >₹ {twoprice}</p>
                                    <p className='web_cart_col_twelve' >− ₹3,842</p>
                                    <p className='web_cart_col_twelve' >FREE</p>
                                    <hr/>
                                    <p className='web_cart_col_four' style={{marginTop:'20px',marginBottom:'20px' , textAlign:'right'}} >₹2,656</p>
                                </div>
                                  <div onClick={()=> Confirm()}  className='web_cart_col_twenty_one'>
                                      <p style={{cursor:'pointer'}} className='web_cart_col_twenty_two'>Confirm</p>
                                  </div>
                            </div>
                        </div>
                    </div> 
                  )
                } */}

                    {
                      !alladdress && (
                        <div className='col-4'>
                          <div className='web_cart_col_4'  >
                            <Skeleton count={1} />
                              <div className='row'>
                                  <div className='col-8'>
                                    <Skeleton count={1} />
                                    <Skeleton count={1} />
                                    <Skeleton count={1} />
                                    <Skeleton count={1} />
                                      <hr/>
                                    <Skeleton count={1} />
                                  </div>
                                  <div className='col-4'>
                                    <Skeleton count={1} />
                                    <Skeleton count={1} />
                                    <Skeleton count={1} />
                                    <Skeleton count={1} />
                                      <hr/>
                                    <Skeleton count={1} />
                                  </div>
                                  <div>
                                    <Skeleton width={'100%'} height={'20px'} />  
                                  </div>
                              </div>
                          </div>
                        </div>
                      )
                    }
                </div>
            </div>
        </div>
        
        <div style={{marginTop:'100px'}}>
        </div>
        
        <ChakraProvider >
            <Modal isCentered onClose={onClose} size='lg' isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent>
              {
                newAddresses ? <ModalHeader>{editupdate ?'Edit Address' : 'Add New Address'}</ModalHeader> :  <ModalHeader>All Address</ModalHeader>
              } 
              <ModalCloseButton />
              {/* <hr /> */}
              <ModalBody>

                {
                  newAddresses ?

                  <>
                  <p onClick={()=>setnewAddresses(!newAddresses)} className='web_cart_col_four ' style={{marginRight:'24px', color:'#F4B644' , cursor:'pointer' , marginTop:'-15px' , marginBottom:'15px' }} >All Address</p>

                    <div className='row'>
                      <div className='col-6 '>
                        <FormLabel htmlFor="name">First Name</FormLabel>
                        <Input className={( fnames ? 'inputttt_red' : '')} onChange={(e)=>setFname(e.target.value)} value={fname} id="name" type="text" />
                        {
                          fnames ? <p style={{color:'red',fontSize:'9px'}}>Enter Valid Name</p> : ''
                        }
                      </div>
                      <div className='col-6'>
                        <FormLabel htmlFor="name">Last Name</FormLabel>
                        <Input className={( lnames ? 'inputttt_red' : '')} onChange={(e)=>setLname(e.target.value)} value={lname} id="name" type="text" />
                        {
                          lnames ? <p style={{color:'red',fontSize:'9px'}}>Enter Valid Name</p> : ''
                        }
                      </div>
                      <div className='col-6 mt-2'>
                        <FormLabel htmlFor="name">City</FormLabel>
                        <Input className={( citys ? 'inputttt_red' : '')} onChange={(e)=>setCity(e.target.value)} value={city} id="name" type="text" />
                        {
                          citys ? <p style={{color:'red',fontSize:'9px'}}>Enter Valid CityName</p> : ''
                        }
                      </div>
                      <div className='col-6 mt-2'>
                        <FormLabel htmlFor="name">State</FormLabel>
                        <Input className={( states ? 'inputttt_red' : '')} onChange={(e)=>setState(e.target.value)} value={state} id="name" type="text" />
                        {
                          states ? <p style={{color:'red',fontSize:'9px'}}>Enter Valid StateName</p> : ''
                        }
                      </div>
                      <div className='col-6 mt-2'>
                        <FormLabel htmlFor="name">Postal Code</FormLabel>
                        <Input className={( pincodes ? 'inputttt_red' : '')} onChange={(e)=>setPincode(e.target.value)} value={pincode} id="name" type="number" />
                        {
                          pincodes ? <p style={{color:'red',fontSize:'9px'}}>Enter Valid Pincode</p> : ''
                        }
                      </div>
                      <div className='col-6 mt-2'>
                        <FormLabel htmlFor="name">Location</FormLabel>
                        <Input className={( geocodes ? 'inputttt_red' : '')} onChange={(e)=>setGeocode(e.target.value)} value={geocode} id="name" type="text" readonly />
                        {
                          geocodes ? <p style={{color:'red',fontSize:'9px'}}>Enter Your Location</p> : ''
                        }
                      </div>
                    </div>

                     <FormLabel className='mt-2' htmlFor="name">Address</FormLabel>
                    <Input className={( newaddresss ? 'inputttt_red' : '')} onChange={(e)=>setNewaddress(e.target.value)} value={newaddress} id="name" type="text" />
                    {
                      newaddresss ? <p style={{color:'red',fontSize:'9px'}}>Enter Valid Address</p> : ''
                    }
    
                    <FormLabel className='mt-2' htmlFor="name">Appartment, Suite, Villa, Etc...</FormLabel>
                    <Input className={( subaddresss ? 'inputttt_red' : '')} onChange={(e)=>setSubaddress(e.target.value)} value={subaddress} id="name" type="text" />
                    {
                      subaddresss ? <p style={{color:'red',fontSize:'9px'}}>Enter Valid Address</p> : ''
                    }

                  </>
                  :
                  <>
                    <p onClick={()=>adnewwww()} className='web_cart_col_four ' style={{marginRight:'24px', color:'#F4B644' , cursor:'pointer' , marginTop:'-15px' , marginBottom:'15px' }} >Add New Address</p>
                    <div className='row over_model' >

                      { alladdress?.map((data,key)=>{
                        return(
                        <div onClick={()=>switch_data(data,key)}  className='col-12'>
                          <div className={(sw===key? 'web_cart_col_moness mt-3' : 'web_cart_col_mfivess mt-3')} style={{marginLeft:'14px'}}>
                            <div className='row'>
                              <div className='col-10'>
                                <p className='web_cart_col_mtwo'>{data.UserDelivAddrFName}</p>
                                <p className='web_cart_col_mthree'>{data.UserDelivAddrAddress}</p>
                              </div>
                              <div className='col-2'>
                                {/* <input style={{marginTop : '29px'}} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={(sw===key ? 'checked' : '')} /> */}
                              </div>
                            </div>
                            <div className='d-flex justify-content-end'>
                              <div onClick={()=>editAddress(data,key)} className='edit_address'>
                                <p className='edit_address_p'>Edit</p>
                              </div>
                              <div onClick={()=>deleteAddress(data,key)} className='delete_address'>
                                <p className='delete_address_p'>Delete</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        )
                      })
                      }

                    </div>
                  </>
                } 

              </ModalBody>

              <ModalFooter>
              {
                newAddresses ?
                <>
                  <Button mr={3} onClick={onClose}>Close</Button>
                  {
                    editupdate ? <Button onClick={()=>Update()} color='white' colorScheme='yellow' >Update</Button>
                    :
                    <Button onClick={()=>Addsub()} color='white' colorScheme='yellow' >Submit</Button>
                  }
                  
                </>
                :
                <Button onClick={onClose}>Close</Button>
              }
              </ModalFooter>

            </ModalContent>
          </Modal>
        </ChakraProvider>

        <ToastContainer />

    </>
  )
}

