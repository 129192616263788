import React from 'react'
import { useSelector } from 'react-redux'
import { ImageUrl } from '../globalvariable/globe'
import { useNavigate } from 'react-router-dom'

export default function Offer() {

    const product =  useSelector((state)=>state)
    const datas = product.allProducts.products.offer
    console.log(datas , 'l;jkfjwjfiwohfoihwiofhoiwhfoihwo')

    let navigate = useNavigate()

    const clicked = (data) =>{
        navigate('/offer/'+data.OfferCategory)
    }

  return (
    <>
    {
        datas?.length != 0 ?
        <div className="pro_groub_head">
            Offers
        </div>
        :'' 
    }
        
            <div className="d-flex justify-content-start">
                {
                    datas?.map((data)=>
                        <div onClick={()=>clicked(data)} style={{marginRight:'10px' , marginLeft:'10px'}} >
                            <img src={ImageUrl+'/'+data.OfferImage} />
                        </div>
                    )
                }
                {/* <div className='backgro'  style={{marginRight:'10px' , marginLeft:'10px'}}>
                    <div>
                        <img className='img1' src={img1} />
                        <div className='d-flex'>
                            <p className='offer_para1'>30%</p>
                            <p className='offer_para2'>OFF</p>
                        </div>
                    </div>
                    <p className='offer_para3'>On Accessories</p>
                </div>
                <div className='backgro1' style={{marginRight:'10px' , marginLeft:'10px'}}>
                    <div>
                        <img className='img1' src={img3} />
                            <div className='d-flex'>
                                <p className='offer_para1'>50%</p>
                                <p className='offer_para2'>OFF</p>
                            </div>
                    </div>
                    <p className='offer_para3'>On Laptops</p>
                </div>
                <div className='backgro2' style={{marginRight:'10px' , marginLeft:'10px'}}>
                    <div>
                        <img className='img1' src={img2} />
                        <div className='d-flex'>
                            <p className='offer_para1'>60%</p>
                            <p className='offer_para2'>OFF</p>
                        </div>
                    </div>
                    <p className='offer_para3'>On Projectors</p>
                </div> */}
            </div>

    </>
  )
}