import React, { useEffect , useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Base_Url } from '../../globalvariable/globe'
import Profile_home_nav from '../../../page/profile_home/profile_home_nav'
import Header from '../../../common/head_foot/header_two'
import Footer from '../../../common/footer'
import box from '../../../assets/img/boox.png'

export default function Home_Notification() {

    let token = localStorage.getItem('user_data')

    const [ data , setData ] = useState()
  
    let navigate = useNavigate()
  
    const Noti =async () => {
  
      if (token === null){
        navigate('/')
        return
      }
  
      let responce = await axios({
        method : 'post',
        url : `${Base_Url}/notification`,
        headers: { 
          'Authorization': `Bearer ${token}` 
        }
      })
      .then((res)=>{
        setData(res.data.Data)
        console.log(res.data)
      })
      .catch((err)=>{
        console.log(err)
      })
    }
  
  
    useEffect(()=>{
      Noti()
    },[])

  return (
    <>
    <Header />
        <div className='pro_home_one mt-150'>
          <div className='row' >
            
            <Profile_home_nav />

            <div className='col-8'>
            <div className='pro_home_fifteen'>
                <div className='d-flex'>
                  <p className='pro_home_eight'>Notification</p>
                </div>
              </div>
              <div className='pro_home_seven mt-3'>
              {
                
                data?.map(( data , key ) =>{
                  return(
                      <div className='mob_wi_one mt-3'>
                        <div className='row'>
                            <div style={{padding : 20}} className='col-8'>
                                <p style={{fontSize :16 , fontWeight : 600}} >{data.tbnotifytitle}</p>
                                <p style={{fontSize :14 , fontWeight : 500}} >{data.tbnotifymessage}</p>
                            </div>
                            <div className='col-4'> 
                                <img style={{width:'70px'}} className='mx-auto d-block mt-4' src={box} />
                            </div>
                        </div>
                      </div>
                    )
                  })
                }
                {
                  data?.length === 0 || data=== '' || data === undefined  ?
                      <p style={{textAlign:'center' , marginTop:'20px',fontWeight:'600'}}>Notification is empty</p> :''
                    
                }
              </div>
            </div>
          </div>
        </div>
        <Footer />
    </>
  )
}
