import React, { useEffect , useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Base_Url } from '../../globalvariable/globe'
import Profile_home_nav from '../../../page/profile_home/profile_home_nav'
import Header from '../../../common/head_foot/header_two'
import Footer from '../../../common/footer'
import Html from 'html-react-parser'
import ReactLoading from 'react-loading';

export default function About_US() {

  return (
    <>
    <Header />
        <div className='pro_home_one mt-150'>
          <div className='row' >
            
            <Profile_home_nav />

            <div className='col-8'>
            <div className='pro_home_fifteen'>
                <div className='d-flex'>
                  <p className='pro_home_eight'>About Us</p>
                </div>
              </div>
              <div className='pro_home_seven mt-3'>
          <p>Rentla is an IT rental company that focuses on renting gadgets required for corporates, students, freelancers, and individuals who need IT support. We are promoting
renting gadgets to optimize the use of technological resources and provide a choice for the public when it comes to choosing their gadgets. We provide gadgets at
affordable costs along with maintenance, software upgradation, and doorstep delivery services.</p>

              </div>
            </div>
          </div>
        </div>
        <Footer />
    </>
  )
}
