import React from 'react'
import Handover_detail_comp from '../../components/mobile_view_comp/handover_detail/handover_detail_comp'

export default function Handover_detail() {
  return (
    <>
      <div style={{width:'90%' , margin:'auto' , paddingBottom:'100px',marginTop:'65px'}}>
        <Handover_detail_comp />
      </div>
    </>
  )
}
