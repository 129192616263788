import React , { useState , useEffect } from 'react'
import Header from '../../../common/head_foot/header_two'
import Footer from '../../../common/footer'
import { Link } from 'react-router-dom'
import axios  from 'axios'
import { Base_Url, ImageUrl } from '../../globalvariable/globe'
import Skeleton from 'react-loading-skeleton' 
import { useSelector , useDispatch } from 'react-redux';
import { Wishcount } from '../../../redux/actions/productAction'

export default function Web_search() {

    let wishss = localStorage.getItem('wish_list')
    let dispatch = useDispatch()

    let data = ''
    const [countss  , setCountss ]= useState(1)

    const [ alldata , setAlldata ] = useState()
    const [ wish ,  setWish ] = useState()

    let loadPages = [ 1 , 2 , 3 , 4 , 5 , 6 , 7 , 8 , 9 , 10 , 11 , 12 ];

    let All = async() =>{
    // localStorage.setItem('search', data)
        let value = localStorage.getItem('search')
        let res = await axios({
            method : 'post',
            url : `${Base_Url}/search`,
            data : {
                search : value
            }
        })
        .then((res)=>{
            setAlldata(res.data.Data.searchDetails)
            console.log(res)
        //   localStorage.removeItem("search");
        })
        .catch((err)=>{
    //   localStorage.removeItem("search");
            console.log(err)
        })
    }

    useEffect(()=>{
        wishss = localStorage.getItem('wish_list')
        setWish(JSON.parse(wishss))
        All()
    },[])

    useEffect(()=>{
        wishss = localStorage.getItem('wish_list')
        setWish(JSON.parse(wishss))
    },[countss])


    const Clickheart = ( data , key ) => {
        
        setCountss(countss +1)
        let allata = localStorage.getItem('wish_list')
        let another = JSON.parse(allata)
        let final = 0

        let wish_data = [
            {
                datas : data
            }
        ]

        if(localStorage.getItem('wish_list')){
            another.forEach((element, index) => {
                console.log(index,'index')
                if( element.datas.ProId === wish_data[0].datas.ProId){
                    let allanother = another
                    allanother.splice(index , 1)
                    localStorage.setItem('wish_list' , JSON.stringify(allanother))
                    dispatch(Wishcount(allanother))
                    final = 1
                }
            })
            console.log(final)
            if( final === 0 ){
                console.log('susssssss')
                let allanother =  another.concat(wish_data)
                localStorage.setItem('wish_list' , JSON.stringify(allanother))
                dispatch(Wishcount(allanother))
            }

        }else{
            localStorage.setItem('wish_list' , JSON.stringify(wish_data))
            dispatch(Wishcount(wish_data))
        }
    }

    return (
    <> 
        <Header />
        <div className='container web_cat_five'>
        <div className='row mt-100 '>
        {/* {
            alldata ? 
            <div>
            <h1 style={{textAlign:'left',fontSize:'20px',fontWeight:'600'}} className='mt-100'>{localStorage.getItem('search')}</h1>
            </div> :''
        } */}
            
            {
                alldata?.map((data,key)=>{
                        return(
                            
                            <div  class="col-md-6 col-lg-4 col-xl-4 col-sm-6 col-xxl-3 home_pro_cont"  key={key}  style={{height:'500px'}}>
                                <div class="product-grid" >
                                    <div className='pro'>
                                        <div className="product-image">
                                        <Link to={'/detail/'+ data.ProName
                                            .toLowerCase()
                                            .trim()
                                            .replace(/[^\w\s-]/g, '')
                                            .replace(/[\s_-]+/g, '-')
                                            .replace(/^-+|-+$/g, '') +'/'+data.ProId}>
                                            <div className="image">
                                                <img  className="pic-1 onepice" src={ImageUrl+'/'+data.ProWebImg} />
                                            </div>
                                        </Link>
                                        

                                            <div className="product-like-icon">
                                            {(() => {
                                                if(wish != null){
                                                var result = wish.filter(x => x.datas.ProId == data.ProId);
                                            if(result.length > 0){
                                                return ( <i onClick={()=>Clickheart(data,key)} className='fa fa-heart hrt_red_clr' > </i>)
                                                }else{
                                                    return ( <i onClick={()=>Clickheart(data,key)} className='fa fa-heart-o' > </i>)
                                                }
                                            }
                                            else{
                                                return ( <i onClick={()=>Clickheart(data,key)} className='fa fa-heart-o' > </i>)
                                            }
                                            })()}
                                            </div>
                                                <div 
                                                // onClick={()=>clicked(data,key)}
                                                >
        
                                                    <div   className="head">
                                                    </div>
                                        
                                                    {/* <div   className="heads mb-1">
                                                        <h5>Accessories Include</h5>
                                                    </div>
                                                    <div className='cont d-flex  bd-highlight'>
                                                        <div className='bd-highlight'>
        
                                                            <div style={{width:'60px' , height:'60px' ,marginTop:'40px',marginBottom:'20px',marginLeft:'10px',borderStyle:'solid',borderWidth:'1px',borderRadius:'4px',borderColor:'#cfcfcf'}} className=" product-linkss">
                                                                <img title='Monitor' style={{marginTop:'10px',padding:'4px'}} className="pic-2" src="https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE4LEgb?ver=6c30" />
                                                            </div>
        
                                                        </div>
                                                        <div className='bd-highlight'>
        
                                                            <div style={{width:'60px' , height:'60px' ,marginTop:'40px',marginBottom:'20px',marginLeft:'10px',borderStyle:'solid',borderWidth:'1px',borderRadius:'4px',borderColor:'#cfcfcf'}} className=" product-linkss">
                                                            <img title='Keyboard' style={{marginTop:'10px',padding:'4px'}} className="pic-2" src="https://m.media-amazon.com/images/I/61dQmz5+hnL._SX450_.jpg" />
                                                            </div>
        
                                                        </div>
                                                        <div className='bd-highlight'>
        
                                                            <div style={{width:'60px' , height:'60px' ,marginTop:'40px',marginBottom:'20px',marginLeft:'10px',borderStyle:'solid',borderWidth:'1px', borderRadius:'4px',borderColor:'#cfcfcf'}} className=" product-linkss">
                                                                <img title='mouse' style={{marginTop:'10px' ,padding:'4px'}} className="pic-2" src="https://media.4rgos.it/i/Argos/9213513_R_Z001A?w=750&h=440&qlt=70" />
                                                            </div>
                                                            
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
        
                                    <div >
                                        <div className="product-content">
                                        <div className="row lit">
                                            <div  className="col">
                                                <h5 className="title" style={{ fontWeight : '600' }} >{data.ProName.substring(0, 40) }</h5>
                                            </div>
                                            {/* <div class="col">
                                                <div className='d-flex justify-content-end' style={{marginRight:'18px'}}>
                                                    <div className='bd-highlight'>
                                                        <i class="fa fa-truck"></i>
                                                    </div>
                                                    <div className='  bd-highlight'>
                                                        <p className='paragraph'>60 hour delivery</p>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                            <div>
                                                <hr />
                                            </div>
                                            <div className='row mt-4 pb-4'>
                            <div className='col-4'>
                            <div  className="product-det">
                                
                                {
                                    data.spec.map((datass,keyss)=>{
                                        
                                        if(keyss < 1){
                                            return(
                                                <div className='btn product-detss product_detss_box'>{datass.ProSpecificName}</div>
                                            )
                                        }else{
                                        return
                                        }
                                    })
                                }
                                <Link to={'/detail/'+ data.ProName
                                    .toLowerCase()
                                    .trim()
                                    .replace(/[^\w\s-]/g, '')
                                    .replace(/[\s_-]+/g, '-')
                                    .replace(/^-+|-+$/g, '') +'/'+data.ProId}>
                                    {/* <div  className='btn btn-default product-detss bro_desss_pro product_detss_box_two'>More</div> */}
                                </Link>
                            </div>
                            </div>
                            <div className='col-8'>
                                <div className='pull-right'>
                                    <div className='d-flex'>
                                    <div  className="hrfhfgdgdggfb"><span style={{fontFamily:'arial'}} >₹</span> {data.ProRentAmt}</div>
                                        
                                        <div style={{fontSize:'10px',borderRadius:'4px'}} className="prices">
                                            <div style={{padding:'4px',marginRight:'15px'}}>
                                                
                                                <div  className="price"><span style={{fontFamily:'arial'}} >₹</span> {data.ProRentAmt}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        )
                    }) 
            }

            
            
        </div>
        <div className='row mt-100'>
            {/* {
                !alldata && loadPages.map ((data)=>{
                    return(
                        <div  className="col-md-6 col-lg-4 col-xl-4 col-sm-6 col-xxl-3 home_pro_cont" style={{marginTop:'40px' }}>
                            <div class="product-grid" >
                                <Skeleton height={300} />
                            </div>
                        </div>
                    )
                })
                
            } */}
            {
                alldata ? '' :  <p style={{textAlign:'center',fontSize:'20px',fontWeight:'700'}} className='my-5'>No Data Found</p>
            }
            </div>
    </div> 
    <div className={alldata? '':'foot_indexx'}>
    <Footer />
    </div>
        
    </>
    )
}
