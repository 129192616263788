import React , { useState ,  useEffect } from 'react'
import Mob_notification_comp from '../../components/mobile_view_comp/mob_notification/mob_notification_comp'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'
import axios from 'axios'
import { Base_Url } from '../../components/globalvariable/globe'
import { Link , useNavigate } from 'react-router-dom'

export default function Notification() {

  let token = localStorage.getItem('user_data')

  const [ data , setData ] = useState()

  let navigate = useNavigate()

  const Noti =async () => {

    if (token === null){
      navigate('/')
      return
    }

    let responce = await axios({
      method : 'post',
      url : `${Base_Url}/notification`,
      headers: { 
        'Authorization': `Bearer ${token}` 
      }
    })
    .then((res)=>{
      setData(res.data.Data)
      console.log(res.data)
    })
    .catch((err)=>{
      console.log(err)
    })
  }


  useEffect(()=>{
    Noti()
  },[])

  return (
    <>
        <div style={{width:'90%' , margin:'auto' , paddingBottom:'100px' , paddingTop:'65px'}}>
            <Cart_one_head link='/profile' head='Notification' />
            <Mob_notification_comp datas={data} />
        </div>
    </>
  )
}
