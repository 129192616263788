import React , { useState , useEffect } from 'react'
import Run_one from '../../../assets/img/run_one.png'
import Run_two from '../../../assets/img/run_two.png'
import head_111 from '../../../assets/img/head_111.png'
import { Link , useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { Base_Url, ImageUrl } from '../../globalvariable/globe'

export default function Renew_order_comp() {

  let navigate =  useNavigate()

  let token = localStorage.getItem('user_data')

  let { id } = useParams()

  const [ all , setAll ] = useState()

  const allData = async() =>{
    if(token === null){
      navigate('/')
      return
    }
    let res = await axios ({
      method : 'post' ,
      url : `${Base_Url}/viewRenewedOrder`,
      headers: { 
        'Authorization': `Bearer ${token}` 
      },
      data : {
          orderId : id
      }
    })
    .then((res)=>{
      console.log(res)
      setAll(res.data.Data)
    })
  }

  useEffect(()=>{
    allData()
  },[])

  const gotosupport =()=>{
    navigate('/support')
  }

  return (
    <>
      <div>
          <p className='renew_one'>Details</p>
          <div className='row'>
              <div className='col-5'>
                <p className='renew_two'>Order Id</p>
              </div>
              <div className='col-1'>
                    <p className='renew_three'>:</p>
              </div>
              <div className='col-6'>
                    <p className='renew_four'>{all?.orderId}</p>
              </div>
          </div>
          <div className='row mt-2'>
              <div className='col-5'>
                <p className='renew_two'>Booking Date</p>
              </div>
              <div className='col-1'>
                    <p className='renew_three'>:</p>
              </div>
              <div className='col-6'>
                    <p className='renew_four'>{all?.Detail.fromDate} - {all?.Detail.toDate}</p>
              </div>
          </div>
        <p className='renew_five my-1 pt-3'>Package Contains</p>
        {all?.productDetails.map((data,key)=>
          <div className='run_ord_fives mt-3'>
            <div className='row'>
              <div className='col-8'>
                <p className='run_ord_six'>{data.BrandName}</p>
                <p className='run_ord_sevens'>{data.ProName}</p>
                <p className='run_ord_eights'>Qty : {data.CartProQty}</p>
              </div>
              <div className='col-4'>
                <img style={{width:'90px' , marginTop:'1px'}} className='run_ord_nine' src={ImageUrl+'/'+data.ProImgPrimary} />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='mob_footssss'>
          <div className='row'>
              <div className='col-6'>
                  <div onClick={()=>gotosupport()} className='d-flex'>
                      <div className='headset'>
                        <img className='kaart' src={head_111} />
                      </div>
                      <div>
                        <p className='compara222 compara2223'>Support</p>
                        <p className='compara333 compara2223'>9am - 5Pm</p>
                      </div>
                  </div>
              </div>
              <div className='col-6'>
                  <div className='bbtn'>
                      <Link to={'/payment/0/'+all?.orderId}>
                        <div className='d-flex'>
                            <p className='compara nextonee mt-1'>Proceed to pay</p>
                        </div>
                      </Link>
                  </div>
              </div>
          </div>
      </div>
    </>
  )
}