export const ActionTypes ={
    SET_PRODUCTS : "SET_PRODUCTS",
    SELECTED_PRODUCT : "SELECTED_PRODUCT",
    ALL_PRODUCT : "ALL_PRODUCT",
    REMOVE_SELECTED_PRODUCT : "REMOVE_SELECTED_PRODUCT",
    ONE_PRODUCT : "ONE_PRODUCT",
    GLOBE : 'GLOBE',
    LOGIN : 'LOGIN',
    CHECKOUT : 'CHECKOUT',
    CARTLIST : 'CARTLIST',
    VIEWCART : 'VIEWCART',
    VIEWCARTUPDATE : 'VIEWCARTUPDATE',
    WISHLIST : 'WISHLIST',
    ORDERCONFIRM : 'ORDERCONFIRM',
    SET_PRODUCTS_UPDATE : 'SET_PRODUCTS_UPDATE',
    CART : 'CART',
    MAP_PRODUCTS : 'MAP_PRODUCTS',
    MAP_PRODUCTS_UPDATE : 'MAP_PRODUCTS_UPDATE',
    MAP_PRODUCTS_ZOOM : 'MAP_PRODUCTS_ZOOM',
    CARTCOUNT : 'CARTCOUNT',
    WISHCOUNT : 'WISHCOUNT'
}