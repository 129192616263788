import React from 'react'
import headset from '../../../assets/img/ggerhead.png'
import { Link , useNavigate } from 'react-router-dom'

export default function Mobcart_one_foot(props) {

  let navigate = useNavigate()


  return (
    <>
      <div className='mob_footss'>
          <div className='row'>
              <div className='col-6'>
                  <div style={{ cursor : 'pointer' }} onClick={()=>{navigate('/support')}} className='d-flex'>
                      <div className='headset'>
                        <img className='kaart' src={headset} />
                      </div>
                      <div>
                        <p className='compara222'>Support</p>
                        <p className='compara333'>9am - 5Pm</p>
                      </div>
                  </div>
              </div>
              <div className='col-6'>
                  <div className='bbtn'>
                      <Link to={`${props.link}`} >
                        <div className='d-flex'>
                            <p className='compara nextonee mt-1'>Back to Home</p>
                        </div>
                      </Link>
                  </div>
              </div>
          </div>
      </div>
    </>
  )
}