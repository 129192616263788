import React , { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import clkk from '../../../assets/img/clkk.png'
import eye_one from '../../../assets/img/eye_one.png'
import eye_close from '../../../assets/img/eye_close.png'
import file from '../../../assets/img/file.png'
import delect from '../../../assets/img/delect.png'
import doc from '../../../assets/img/doc.png'
import x from '../../../assets/img/x.png'
import stamp from '../../../assets/img/stamp.png'
import ReactLoading from 'react-loading';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter, 
    ModalBody,
    useDisclosure,
    Button,
    ChakraProvider
  } from '@chakra-ui/react'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Base_Url } from '../../globalvariable/globe';

export default function Mob_login_three() {

  let change = /^\d{10}$/;
  let emailveri = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [ compname , setCompname ] = useState('')
  const [ gts , setGts ] = useState('')
  const [ comppername , setComppername ] = useState('')
  const [ phoneno , setPhoneno ] = useState('')
  const [ email , setEmail ] = useState('')
  const [ pass , setPass ]  = useState('')
  const [ eye , setEye ] = useState(false)
  const [ eye1 , setEye1 ] = useState(false)
  const [ confirmpass , setConfirmpass ] = useState('')

  const [ img , setImg ] = useState()
  const [ data , setData ] = useState('')

  const [ showimg , setShowimg ] = useState(false)

  const [ vericompname , setVeriCompname ] = useState(false)
  const [ verigts , setVeriGts ] = useState(false)
  const [ vericomppername , setVeriComppername ] = useState(false)
  const [ close , setClose ] = useState(false)
  const [ veriemail , setVeriEmail ] = useState(false)

  const [ load , setLoad ] = useState(false)

  let navigate = useNavigate()

  const errnotify = (dat) => toast.error( dat , {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });

      const successnotify = (sus) => toast.success( sus , {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
  const onImage = (e) => {
    setShowimg({[e.target.name] : URL.createObjectURL(e.target.files[0])})
  };

  const clicked = () => {


   if( compname === ''){
    errnotify('Enter Company Name')
    return
   }

   if ( gts === ''){
    errnotify('Enter gst')
    return
   }

   if( phoneno === '' )
   {
    errnotify('Enter Phone Number')
    return
   }

   if( phoneno.length < 10 ){
    errnotify('Enter Valid Phone Number')
    return
   }

   if( email === ''){
    errnotify('Enter Email')
            return
   }

   let phonevali = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if(phonevali.test(email)){

        }else{
          errnotify('Enter Valid Email')
          return
        }

        if(pass === ''){
          errnotify('Enter Password')
          return
        }
        if (pass.length < 8 ){
          errnotify('Password must be 8 letters')
          return
        }
        if( pass != confirmpass ){
          errnotify('Password Mismatch')
          return
        }
        setLoad(true)
      var formData = new FormData();
      var imagefile = document.querySelector('#file');
      formData.append("document", showimg === true ?  imagefile.files[0] : '' );
      formData.append("companyName", compname);
      formData.append("gstNo", gts);
      formData.append("phone", phoneno);
      formData.append("email", email);
      formData.append("password", pass);

    const sendData = async() =>{
      let responce = await axios.post(`${Base_Url}/createCorporateAccount`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          } 
      })
      .then((res)=>{
        console.log(res.data)
        if(res.data.status === true ){
          onOpen()
          setClose(true)
          setLoad(false)
          successnotify('Company Account Created Success')
          // navigate('/corporate_login')
          // onOpen()
        }else{
          setLoad(false)
          errnotify(res.data.message)
          return
        }
      })
      .catch((err)=>{
        setLoad(false)
        console.log(err)
        let er = 'Company Account Created Failed'
        errnotify(er)
        console.log(err)
      })
    }

    sendData()

  }

  const handleCapture = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const newUrl = URL.createObjectURL(file);
        setData('')
        setShowimg(true)
        setImg({'img' : newUrl})
      }
    }
  }

  const remove = () =>{
    document.querySelector('#file').value = "";
      // setData(backimage)
      setData('')
      setImg()
      setShowimg(false)
  }

  return (
    <>
      <div className='mt-4'>
          <div>
            <p className='kyc_two_para'>Company Name</p>
            <input type="text" onChange={(e)=>{
                let letters = /^[1-9][0-9]*$/;
                let last = ''
                if(e.target.value.length >=2) {
                    last = e.target.value.split('').pop()
                }else{
                    last = e.target.value
                }
                if(last.match(letters)){

                }else{
                    setCompname(e.target.value)
                }
            }} value={compname} className={(vericompname ? 'form-control kyc_in_one inputttt_red' : 'form-control kyc_in_one')} id="exampleInputEmail1" aria-describedby="emailHelp"  />
          </div>
          <div className='mt-3'>
            <p className='kyc_two_para'>GST No</p>
            <input type="text" onChange={(e)=> setGts(e.target.value) } value={gts} className={(verigts ? 'form-control kyc_in_one inputttt_red' : 'form-control kyc_in_one')} id="exampleInputEmail1" aria-describedby="emailHelp"  />
          </div>
          {/* <div className='mt-3'>
            <p className='kyc_two_para'>Contact Person Name</p>
            <input type="text" onChange={(e)=>{
                let letters = /^[1-9][0-9]*$/;
                let last = ''
                if(e.target.value.length >=2) {
                    last = e.target.value.split('').pop()
                }else{
                    last = e.target.value
                }
                if(last.match(letters)){

                }else{
                    setComppername(e.target.value)
                }
            }} value={comppername} className={(vericomppername ? 'form-control kyc_in_one inputttt_red' : 'form-control kyc_in_one')}id="exampleInputEmail1" aria-describedby="emailHelp"  />
          </div> */}
          <p className='kyc_two_para mt-3'>Phone Number</p>
                    <div className='lo_tw_one'>
                        <div className='d-flex'>
                            <div >
                                <p style={{ marginRight : -10 , fontSize :  15 }} className='lo_tw_two'>+91</p>
                            </div>
                            <div>
                                <input style={{ paddingLeft : 0 }} onChange={(e)=>setPhoneno(e.target.value.replace(/[^0-9]/g, "").slice(0,10))}  value={phoneno}  class="form-control lo_tw_three" id="exampleInputEmail1" aria-describedby="emailHelp"
                                // placeholder="99945 99945"
                                />
                            </div>
                        </div>
                    </div>
          <div className='mt-3'>
            <p className='kyc_two_para'>E-mail</p>
            <input type="email" onChange={(e)=>setEmail(e.target.value)} value={email} className={( veriemail ? 'form-control kyc_in_one inputttt_red' : 'form-control kyc_in_one' )} id="exampleInputEmail1" aria-describedby="emailHelp" />
          </div>

          <p className='login_two_img400'>Password</p>
                    <div className='lo_tw_one'>
                        <div className='d-flex justify-content-between'>
                            <div>
                                <input style={{width : '100%'}} onChange={(e)=>setPass(e.target.value)} value={pass}  type={ eye === true ?  'text' : 'password' } class="form-control lo_tw_three" id="exampleInputEmail1" aria-describedby="emailHelp"
                                // placeholder="99945 99945"
                                />
                            </div>
                            <div  style={{ padding : 13 }} >
                                {
                                    eye === false ? <img onClick={()=>{ setEye(!eye) }} style={{ width : 20 , height : 20 , opacity : .5 }} src={eye_one} />
                                    : <img onClick={()=>{ setEye(!eye) }} style={{ width : 20 , height : 20 , opacity : .5 }}  src={eye_close} />
                                }
                                
                            </div>
                        </div>
                    </div>

                    <p className='login_two_img400'>Confirm Password</p>
                    <div className='lo_tw_one'>
                        <div className='d-flex justify-content-between'>
                            <div>
                                <input style={{width : '100%'}} onChange={(e)=>setConfirmpass(e.target.value)} value={confirmpass}  type={ eye1 === true ?  'text' : 'password' } class="form-control lo_tw_three" id="exampleInputEmail1" aria-describedby="emailHelp"
                                // placeholder="99945 99945"
                                />
                            </div>
                            <div  style={{ padding : 13 }} >
                                {
                                    eye1 === false ? <img onClick={()=>{ setEye1(!eye1) }} style={{ width : 20 , height : 20 , opacity : .5 }} src={eye_one} />
                                    : <img onClick={()=>{ setEye1(!eye1) }} style={{ width : 20 , height : 20 , opacity : .5 }}  src={eye_close} />
                                }
                                
                            </div>
                        </div>
                    </div>


        </div>
        <div className='lo_tw_thirteen my-4'>
        </div>
        <div className='mt-3'>
          <p className='kyc_p_four'>Upload Documents (Optional)</p>
          <div className='kyc_cont'>
          {
            showimg ? <img className='kyc_img_one' src={img.img} alt="img"/> : <img style={{ opacity : .4 }} className='kyc_img_one' src={doc} alt="img"/>
          }
            <div class="kyc_centered">
                <div className='kyc_cont_two'>
                    <div className='d-flex justify-content-around'>
                        <div className='mt-2'>
                            <img  className='kyc_img_one' src={file} />
                            <input style={{ width : 60 }} onChange={(e)=>handleCapture(e.target)} accept="image/*" name='file' className='kyc_cont_two_upload_three' id='file' type="file" />
                        </div>
                        {/* <div className='mt-2 kyc_line'>
                        </div> */}
                        {/* <div onClick={()=>remove()} className=' mt-2'>
                            <img src={delect} />
                        </div> */}
                    </div>
                </div>
            </div>
          </div>
      </div>
      <div onClick={()=>clicked()} style={{cursor : 'pointer'}} className='profile_bbbtn mb-4' >
          {
                  load === false ? <p className='profile_bbbtn_text'>Submit</p>

                  :
              
              <div className='d-flex justify-content-center'  style={{padding  : 14 , marginRight : 30 }} >
                  <ReactLoading type='balls' color='#ffffff' />
              </div>
          }
          </div>
     

      <ChakraProvider>

        <Modal isCentered={true} isOpen={isOpen} size={'xs'} onClose={onClose}>

          <ModalOverlay />
            <ModalContent>
              {
                close === false ? 
                <>
                <ModalBody>
                  <div className='d-flex justify-content-between' >
                    <p ></p>
                    <p  className='pay_succ'>Verify Your Account</p>
                    <img onClick={()=>{setClose(true)}} style={{ width: 20 , height : 20 }} src={x} />
                  </div>
                  <p style={{ color : '#454545' , fontSize :  12 , fontWeight : '400' , textAlign : 'center' , marginTop : 10 , lineHeight : 'normal' }}>Account Activation link has been sent to the email address you provided</p>
                  <img style={{margin:'auto' , paddingTop:'20px' , width : '50%' , height : '50%'}} src={stamp} />
                  <p style={{ color : '#454545' , fontSize :  14 , fontWeight : '400' , textAlign : 'center' , marginTop : 10 , lineHeight : 'normal' , textDecoration : 'underline' , cursor : 'pointer' }}>Didn't Get the mail? Send it again</p>
                </ModalBody>
                </>
                :
                <>
                <ModalBody>
                  <div className='d-flex justify-content-end' >
                    <Link to='/' >
                      <img  style={{ width: 20 , height : 20 }} src={x} />
                    </Link>
                  </div>
                  {/* <p style={{paddingTop:'10px'}} className='pay_succ'>We will get back to you soon !!</p> */}
                  <p style={{paddingTop:'10px'}} className='pay_succ'>Company Created Successfully</p>
                  <img style={{margin:'auto' , paddingTop:'10px'}} src={clkk} />
                </ModalBody>
    
                <ModalFooter>
                  <Link to='/corporate_login' >
                    <Button bg={'#161E40'} color={'white'} mr={3} >
                      Back to Company Login
                    </Button>
                  </Link>
                </ModalFooter>
                </>
              }
            

            </ModalContent>

        </Modal>

      </ChakraProvider>


      <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
                {/* Same as */}
        <ToastContainer />
    </>
  )
}