import React , { useState , useEffect } from 'react'
import lo from '../../assets/img/lo.png'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    ChakraProvider,
    Button,
    ModalOverlay
  } from '@chakra-ui/react'
  import { CloseIcon } from '@chakra-ui/icons'
  import { Login } from '../../components/other/login/login'
  import { Link } from 'react-router-dom'
  import Windows_height_width from '../../components/windows_height_width'
  import { AiOutlineHeart } from "react-icons/ai";
  import { AiOutlineShoppingCart } from "react-icons/ai";
  import { useSelector , useDispatch } from 'react-redux';

  import { FiSearch } from "react-icons/fi";
  import { useLocation , useNavigate } from "react-router-dom";
  import search_one from '../../assets/img/search_one.png'


  import car from '../../assets/img/ca.png'
  import htt from '../../assets/img/htt.png'

  import CartIcon from '../../assets/ioon/cart.png'
  import CartIcons from '../../assets/ioon/carthov.png'
  import wish from '../../assets/ioon/wish.png'
  import { Wishcount } from '../../redux/actions/productAction'
 

export default function Header() {

    const [ search , setSearch ] = useState('')


    const [ noo , setNo ] = useState(0)

    const [ wishcountss , setWishcount ] = useState(0)

    let navigate = useNavigate()
    const product =  useSelector((state)=>state)
    let dispatch = useDispatch()

    let locatinon = useLocation()

    function openSearch() {
        document.getElementById("myOverlay").style.display = "block";
    }
      
    function closeSearch() {
    document.getElementById("myOverlay").style.display = "none";
    }

    const { height, width } = Windows_height_width();

    const [ wi , setWi ] = useState()

    const [ login , setlogin ] = useState(false)

    let dyt = () =>
        {
            if(width > 1850){
                setWi(width-400)
            }else if(width > 1630){
                setWi(width-200)
            }else if(width > 1440){
                setWi(width)
            }
        }

    useEffect(()=>{
        dyt()
    },[width])

    useEffect(()=>{
        let stor = localStorage.getItem('user_data')
        if ( stor === null ) {
            setlogin(false)
        }else{
            setlogin(true)
        }
    },[])

    const searchClicked = () =>{
        
        if(search === ''){ 

            return
        }
        else if (search === ' '){
            console.log('sdf cs')
            setSearch('')
            return
        }else{
            localStorage.setItem('search' , search)
            navigate('/search')
        }
    }

    useEffect(()=>{
        //
        let wishss = localStorage.getItem('data')

        let widata = localStorage.getItem('wish_list')

        dispatch(Wishcount(JSON.parse(widata)))

        console.log(product, 'productproduct')

        if ( widata === null ){ 
            setWishcount(0)
            return
        }else if(widata === '[]'){
            setWishcount(0)
        }
        let hiis = JSON.parse(widata)

        setWishcount(hiis.length)


        if ( wishss === null ){  
            setNo(0)
            return
        }else if(wishss === '[]'){
            setNo(0)
        }
        let hii = JSON.parse(wishss)

        setNo(hii.length)


    },[])


    useEffect(()=>{
        console.log(product.wishcount.products,'sdfsfdsiudf d s s df s f     productproductproductproductproductproduct')
        if( product.wishcount.products === null || product.wishcount.products === undefined ){
            setWishcount(0)
        }else{
            setWishcount(product.wishcount.products.length)
        }
        
    },[product])

    const OverlayOne = () => (
        <ModalOverlay
          bg='blackAlpha'
          backdropFilter='blur(5px) hue-rotate(90deg)'
        />
      )
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
    const [overlay, setOverlay] = React.useState(<OverlayOne />)

    const enterKey =(e) =>{
        if (e.key === 'Enter') {
            searchClicked()
          }
    }

  return (
<>
    <header className="header-area clearfix header-hm9 transparent-bar">
        <div className='container head_containers'>
            <div className="header-top-area">
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-2 col-12 col-xxl-2">
                        <div style={{width:'115px'}} className="logo-hm9 text-center">
                            <Link to='/'>
                                <img className='hdddimggg' alt="logo"  src={lo} />
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-8 col-sm-5 col-xxl-8">
                        {/* <div className="main-menu">
                            <nav>
                                <ul>
                                    <li>
                                        <Link to='/'><p style={{marginTop:'20px'}} className={(locatinon.pathname === '/' ? 'header_nav_tit_color' : 'header_nav_tit_color_two')}>Home</p></Link>
                                    </li>
                                    
                                    <li>
                                        <Link to='/products/test/test'><p className={(locatinon.pathname === 'products' ? 'header_nav_tit_color' : 'header_nav_tit_color_two')}>Products </p></Link>
                                    </li>
                                                                
                                </ul>
                            </nav>
                        </div> */}
                    </div>
                    {/* <li>
                        <Link to=''>Shop 
                            <i className="fa fa-angle-down"></i>
                        </Link>
                        <ul className="submenu">
                            <li><a>wishlist </a></li>
                            <li><a>my account</a></li> 
                        </ul>
                    </li>  */}
                    
                    <div className="col-lg-2 col-md-2 col-sm-2 col-12 col-xxl-2">
                        <div className="header-right-wrap">
                            <div className="same-style header-search">
                            <div class="search-container">
                                <div className='d-flex justify-content-start'>
                                    <input onKeyDown={(e)=>enterKey(e)} onChange={(e)=>{
                                        if(e.target.value === ' ')
                                        {

                                        }else{
                                            setSearch(e.target.value)
                                        }
                                    }} value={search} style={{background:`url${search_one}`}} className='head_search'  type="text" placeholder="Laptops, Workstation, Pc, Etc..." name="search" />
                                    <img onClick={()=>searchClicked()} className='head_search_img' src={search_one} />
                                </div>
                            </div>
                                {/* <Link to='' ><FiSearch onClick={()=>openSearch()} /></Link> */}
                            </div>
                            <div className="same-style account-satting">
                                <Login />
                            </div>
                                
                            <div className="same-style cart-wrap">
                                <div 
                                // ref={btnRef} onClick={onOpen}
                                >
                                        <Link to='/cart'>
                                            <img style={{width : 30 }} src={CartIcon}   className='head_search_imgs ' />
                                            <span class='badge badge-warning' id='lblCartCount'>{noo}</span>
                                        </Link>
                                </div>
                                <div className="shopping-cart-content">
                                    <ul>
                                        <li className="single-shopping-cart">
                                            <div className="shopping-cart-img">
                                                <a href="#"><img alt="" src="assets/img/cart/cart-1.png" /></a>
                                            </div>
                                            <div className="shopping-cart-title">
                                                <h4><a href="#">T- Shart & Jeans </a></h4>
                                                <h6>Qty: 02</h6>
                                                <span>$260.00</span>
                                            </div>
                                            <div className="shopping-cart-delete">
                                                <a href="#"><i className="fa fa-times-circle"></i></a>
                                            </div>
                                        </li>
                                        <li className="single-shopping-cart">
                                            <div className="shopping-cart-img">
                                                <a href="#"><img alt="" src="assets/img/cart/cart-2.png" /></a>
                                            </div>
                                            <div className="shopping-cart-title">
                                                <h4><a href="#">T- Shart & Jeans </a></h4>
                                                <h6>Qty: 02</h6>
                                                <span>$260.00</span>
                                            </div>
                                            <div className="shopping-cart-delete">
                                                <a href="#"><i className="fa fa-times-circle"></i></a>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="shopping-cart-total">
                                        <h4>Shipping : <span>$20.00</span></h4>
                                        <h4>Total : <span className="shop-total">$260.00</span></h4>
                                    </div>
                                    <div className="shopping-cart-btn btn-hover text-center">
                                        <a className="default-btn" href="cart-page.html">view cart</a>
                                        <a className="default-btn" href="checkout.html">checkout</a>
                                    </div>
                                </div>
                            </div>
                            <div className="same-style header-wishlist">
                                    <Link to="/wish">
                                        <img style={{width : 30}} src={wish}  className='head_search_imgs' />
                                        <span class='badge badge-warning' id='lblCartCount'>{wishcountss}</span>
                                    </Link>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <div id="myOverlay" className="overlay">
        <span className="closebtn" onClick={()=>closeSearch()} title="Close Overlay">×</span>
        <div className="overlay-content">
            <input type="text" placeholder="Search.." name="search" />
            <button ><i className="fa fa-search"></i></button>
        </div>
    </div>

    <ChakraProvider>
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            finalFocusRef={btnRef}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Shopping Cart</DrawerHeader>

                <DrawerBody>
                    <div className='row'>
                        <div className='col-3' style={{marginTop:'20px' ,borderRadius:'6px' , padding:'6px' , borderColor:'#ebebeb' , borderWidth:'1px' , borderStyle:'solid'}}>
                            <img width='100px'   src='https://media.istockphoto.com/photos/laptop-with-blank-screen-isolated-on-white-background-with-clipping-picture-id1157599345?k=20&m=1157599345&s=612x612&w=0&h=n6ARK2hCnQCd7JXPUvPgeFUPkuSSz26UpAQ8XTe1kPo=' />
                        </div>
                        <div style={{marginTop:'20px'}} className='col-7'>
                            <p style={{fontSize:'12px', lineHeight:'1.2'}}>Lenova i5 7th gen , 8gb ram.</p>
                            <p className='mt-1' style={{fontSize:'12px'}}>1 X 30000/-</p>
                        </div>
                        <div style={{marginTop:'20px'}} className='col-2'>
                            <CloseIcon w={3} h={3} color="#b5b5b5" />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-3' style={{marginTop:'20px' ,borderRadius:'6px' , padding:'6px' , borderColor:'#ebebeb' , borderWidth:'1px' , borderStyle:'solid'}}>
                            <img width='100px'   src='https://media.istockphoto.com/photos/black-modern-laptop-with-open-display-picture-id1034249358?k=20&m=1034249358&s=612x612&w=0&h=BcZWqv7p9sbFjpeGU3QtIClpNOgyffmbkEMfmZ_PDXU=' />
                        </div>
                        <div style={{marginTop:'20px'}} className='col-7'>
                            <p style={{fontSize:'12px', lineHeight:'1.2'}}>Acer i5 7th gen , 8gb ram.</p>
                            <p className='mt-1' style={{fontSize:'12px'}}>1 X 30000/-</p>
                        </div>
                        <div style={{marginTop:'20px'}} className='col-2'>
                            <CloseIcon w={3} h={3} color="#b5b5b5" />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-3' style={{marginTop:'20px' ,borderRadius:'6px' , padding:'6px' , borderColor:'#ebebeb' , borderWidth:'1px' , borderStyle:'solid'}}>
                            <img width='100px'   src='https://i.dell.com/is/image/DellContent/content/dam/ss2/product-images/dell-client-products/notebooks/inspiron-notebooks/15-3521/pdp/laptop-inspiron-15-3521-pdp-gallery-504x350-bk.psd?fmt=jpg&wid=570&hei=400' />
                        </div>
                        <div style={{marginTop:'20px'}} className='col-7'>
                            <p style={{fontSize:'12px', lineHeight:'1.2'}}>Hp i5 7th gen , 8gb ram.</p>
                            <p className='mt-1' style={{fontSize:'12px'}}>1 X 30000/-</p>
                        </div>
                        <div style={{marginTop:'20px'}} className='col-2'>
                            <CloseIcon w={3} h={3} color="#b5b5b5" />
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div  className='col-6 font-weight-bold'  >
                        <p style={{fontWeight:'500'}}>SubTotal : </p>
                        </div>
                        <div className='col-6 font-weight-bold' >
                            <p style={{float:'right' , fontWeight:'500'}}>12000/-</p>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <Button style={{backgroundColor:'#F4B644' , color:'white' , float:'right' }} >Check Out</Button>
                    </div>
                </DrawerBody>

                <DrawerFooter>
                    <Button variant='outline' mr={3} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button  style={{backgroundColor:'#F4B644' , color:'white' }}>View Cart</Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    </ChakraProvider>

</>
  )
}




{/* <li><a href="#">Pages</a>
    <ul>
        <li><a href="about.html">about us</a></li>
        <li><a href="cart-page.html">cart page</a></li>
        <li><a href="checkout.html">checkout </a></li>
        <li><a href="wishlist.html">wishlist </a></li>
        <li><a href="my-account.html">my account</a></li>
        <li><a href="login-register.html">login / register </a></li>
        <li><a href="contact.html">contact us </a></li>
        <li><a href="404.html">404 page </a></li>
    </ul>
</li> */}


{/* <Link to='/cart'>
    <img style={{width : 30 }} src={CartIcon}   className='head_search_imgs ' />
    <span class='badge badge-warning' id='lblCartCount'>5</span>
</Link> */}