import React from 'react'
import Mob_wish_comp from '../../components/mobile_view_comp/mob_wish/mob_wish_comp'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'

export default function Mob_wish() {
  return (
    <>
        <div style={{width:'90%' , margin:'auto' ,marginTop:'65px' , marginBottom:'60px'}}>
            {/* <Cart_one_head link='/profile' head='Wishlist' /> */}
            <Mob_wish_comp />
        </div>
    </>
  )
}
