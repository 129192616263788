import React , { useState , useEffect } from "react";
import Vector from '../../assets/img/Vector.png'
import hrrt from '../../assets/img/hrrt.png'
import hrttt from '../../assets/img/hrttt.png'
import {
  VStack,
  Text,
  Flex,
  Image,
  ChakraProvider,
  Container
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Html from 'html-react-parser'
import { useSelector , useDispatch } from 'react-redux';
import { Wishcount } from "../../redux/actions/productAction";


export default function Popular_product() {

  const product =  useSelector((state)=>state)
  const datas = product.allProducts.products.popular
  const img = product.allProducts.products.awsimageurl
  let wishss = localStorage.getItem('wish_list')
  const [ wish ,  setWish ] = useState()
  let dispatch = useDispatch()

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 500 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  useEffect(()=>{
    wishss = localStorage.getItem('wish_list')
    setWish(JSON.parse(wishss))
  },[])

  const [countss  , setCountss ]= useState(1)

  useEffect(()=>{
      wishss = localStorage.getItem('wish_list')
      setWish(JSON.parse(wishss))
  },[countss])

  useEffect(()=>{
    console.log(datas,'efklrgurgfieu')
  },[datas])

  const Clickheart = ( data , key ) => {
    
    setCountss(countss +1)
    let allata = localStorage.getItem('wish_list')
    let another = JSON.parse(allata)
    let final = 0 
    // setHeart(true)

    let wish_data = [
        {
            datas : data
        }
    ]

    if(localStorage.getItem('wish_list')){
        another.forEach((element, index) => {
            if( element.datas.ProId === wish_data[0].datas.ProId){
                let allanother = another
                allanother.splice(index , 1)
                localStorage.setItem('wish_list' , JSON.stringify(allanother))
                dispatch(Wishcount(allanother))
                final = 1
            }
        })
        console.log(final)
        if( final === 0 ){
            let allanother =  another.concat(wish_data)
            localStorage.setItem('wish_list' , JSON.stringify(allanother))
            dispatch(Wishcount(allanother))
        }

    }else{
        localStorage.setItem('wish_list' , JSON.stringify(wish_data))
        dispatch(Wishcount(wish_data))
    }
    // let wish_list = localStorage.getItem('wish_list')

    // const All = async () => {
    //     let responce = await axios({
    //         method : 'post',
    //         url : `${Base_Url}/wishlist`,
    //         headers: { 
    //             'Authorization': `Bearer ${user}` 
    //         },
    //         data : {
    //             proId : data.ProId
    //         }
    //     })
    //     .then ((res)=>{
    //     })
    // }
    // All()
  }

  return (
    <>
    
      <div style={{paddingTop:'15px'}}>
        <div className="pro_groub_head">
            Popular Products
        </div>
        <ChakraProvider>
          <Container
            py={4}
            px={0}
          >
            <Carousel
                swipeable={true}
                draggable={true}
                responsive={responsive}
                infinite={true}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}                
                ssr={true} // means to render carousel on server-side.
                containerClass="carousel-container" 
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
              >
              {
              datas?
              datas?.map((post, index) => (
                <div >                   
                    <Flex
                      key={index}
                      bg="#EDEFF1"
                      justifyContent="space-between" 
                      flexDirection="column"
                      overflow="hidden"
                      margin='auto'
                      width='136px'
                      color="gray.300"
                      rounded={5}
                      flex={1}
                      p={2}
                    >
                      <VStack mb={2}>
                        <Link to={'/detail/'+ post.ProName
                            .toLowerCase()
                            .trim()
                            .replace(/[^\w\s-]/g, '')
                            .replace(/[\s_-]+/g, '-')
                            .replace(/^-+|-+$/g, '') 
                            +'/'+post.ProId}
                        >
                          <Image className="pop_image" src={img+'/'+post.ProImgPrimary} />
                            <p style={{ marginBottom : 0 , paddingBottom : 0 , marginTop : 0}} className='paraone'>{ post.BrandName }</p>
                            <p style={{ fontWeight : 'bold' }} className='paratwodfgdg'>{ post.ProName.substring(0, post.ProName.indexOf('-')) }</p>
                            <p style={{ fontWeight : '500' }} className='paratwogbdgbd'>{ post.ProName.substring(post.ProName.indexOf('-') + 1 , 65) }</p>
                        </Link>              
                      </VStack>

                      <Flex justifyContent="space-between">
                      <Link to={'/detail/'+ post.ProName
                            .toLowerCase()
                            .trim()
                            .replace(/[^\w\s-]/g, '')
                            .replace(/[\s_-]+/g, '-')
                            .replace(/^-+|-+$/g, '') 
                            +'/'+post.ProId}
                          >
                        <button
                          className="pop_button" style={{ padding : 0}}
                        >
                          <p style={{ fontSize : 11 , fontFamily : 'Roboto' , color : '#fff' , fontWeight : '600' }} >₹ {post.ProRentAmt}/M</p>
                          
                        </button>
                      </Link>
                      {(() => {
                          if(wish != null){
                              console.log(typeof(wish),'wishhhh')
                          var result = wish.filter(x => x.datas.ProId == post.ProId);

                      if(result.length > 0){
                          return ( <Image onClick={()=>Clickheart(post,index)} className="pop_icon" src={hrrt} />)
                          }else{
                              return ( <Image onClick={()=>Clickheart(post,index)} className="pop_icon" src={hrttt} />)
                          }
                      }
                      else{
                          return ( <Image onClick={()=>Clickheart(post,index)} className="pop_icon" src={hrttt} />)
                      }
                      })()}
                        
                      </Flex>
                    </Flex>
               
                </div>
                
              )) : <div></div>}
          </Carousel>
        </Container>
        </ChakraProvider>
    </div>
  </>
  )
}


