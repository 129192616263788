import React, { useState , useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Link , useParams } from 'react-router-dom';
import axios from 'axios';
import { Base_Url , ImageUrl } from '../../globalvariable/globe';
import { useDispatch , useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { ViewCart } from '../../../redux/actions/productAction';
import down from '../../../assets/img/down.png'
import { useNavigate } from 'react-router-dom';
import { faL } from '@fortawesome/free-solid-svg-icons';

export default function Service_hand_status_comp() {

    let navigate = useNavigate()

    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    const [ vvale , setVvale ] = useState(false)
    const [ historys , setHistory ] = useState(false)

    const [ otp , setOtp ] = useState('')
    const [ ootp , setOotp ] = useState(false)


    let user = localStorage.getItem('user_data')

    const [ alldata , setAlldata ] = useState()
    const [ issue , setIssue ] = useState()
    const [ issueval , setIssueval ] = useState('')
    const [ errmsg , setErrmsg ] = useState(false) 
    const [ addressid, setAddressid ] = useState()
    const [ editvalue , setEditvalue ] = useState('')

    const [ sw , setSw ] = useState(0)

    let product =  useSelector((state)=>state)
    let alladdress = product.viewcart.address

    let dispatch = useDispatch()

    let { cartid } = useParams()
    let { id , no } = useParams()

    const errnotify = (dat) => toast.error( dat , {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
  
      const susnotify = (dat) => toast.success( dat , {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    const switch_data = (data,key) =>{
        setAddressid(data.UserDelivAddrId)
        setSw(key)
    }

    const start = async()=>{
        if(user === null){
            navigate('/')
            return
          }
        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/selectProductToService`,
            headers: { 
                'Authorization': `Bearer ${user}` 
            },
            data : {
                orderId : id ,
                cartDetailId : cartid
            }
        })
        .then((res)=>{
            setAlldata(res.data.Data)
            setIssue(res.data.Data.issue[0].ServiceMasterName)
            setOtp(res.data.Data.otp)
            console.log(res)
        })
        .catch((err)=>{
            if(err.response.status === 401){
                navigate('/login')
            }
            console.log(err)
        })


        let respon = await axios({
            method : 'post',
            url : `${Base_Url}/viewOrderProductDetails` ,
            headers: { 
                'Authorization': `Bearer ${user}` 
            },
            data : {
              orderId : id
            }
        })
        .then((res)=>{
          console.log(res , 'resssss')
            if(res.data.status === true){
                dispatch(ViewCart(res.data.Data))
                setAddressid(res.data.Data.address[0].UserDelivAddrId)
            }else{
    
            }
        })
        .catch((err) => {
            if(err.response.status === 401){
                navigate('/login')
            }
        })

    }

    useEffect(()=>{
        if( no === '0'){
            setOotp(true)
            afterotp()
          }else{
            start()
          }
    },[])

    let lastApi = async ()=>{
        let value = new Date()
        console.log(issue , 'issue')
        setIssueval('')
        if ( issue === '' || issue === undefined){
            setIssueval('Fill Issue Value')
            setErrmsg(true)
            return
        }else{
            setIssueval('')
            setErrmsg(false)
        }

        if( editvalue === ''){
          setIssueval('Fill Issue Value')
          setErrmsg(true)
          return
        }
        setErrmsg(false)
        let resss = await axios({
            method : 'post',
            url : `${Base_Url}/requestService`,
            headers: { 
                'Authorization': `Bearer ${user}` 
            },
            data : {
                orderId : id ,
                cartDetailId : cartid ,
                addressId : addressid ,
                issue : issue,
                manualIssue : editvalue ,
                date : value.getFullYear()+ '-' + ((value.getMonth() > 8) ? (value.getMonth() + 1) : ('0' + (value.getMonth() + 1))) + '-' + ((value.getDate() > 9) ? value.getDate() : ('0' + value.getDate()))
            }
        })
        .then((res)=>{
            if(res.data.status === true){
                setOtp(res.data.otp)
                afterotp()
            }else {
                setIssueval('something Went Wrong')
            }
            console.log(res)
        })
        .catch((err)=>{
            setIssueval('something Went Wrong')
            if(err.response.status === 401){
                navigate('/login')
            }
            console.log(err)
        })
    }

    let optionSel = (e) =>{
        if(e.target.value === 'None of the above'){
            setVvale(true)
            setIssue('')
        }
        else{
            setVvale(false)
            setIssue(e.target.value)
        }
    }

    let afterotp = async() =>{
        let responce = await axios({
          method : 'post',
          url : `${Base_Url}/viewServiceRequestedProduct`,
          headers: { 
              'Authorization': `Bearer ${user}` 
          },
          data : {
              orderId : id ,
              cartDetailId : cartid
          }
      })
      .then((res)=>{
        console.log(res.data ,'dbdgbdf dgbdffgb')
          setAlldata(res.data.Data)
          setOotp(true)
          if( no === '0'){
            console.log(res.data.Data.otp,'fgdgfd')
            setOtp(res.data.Data.otp)
          }
      })
      .catch((err)=>{
          console.log(err)
      })
      }
  

    let history = () =>{
        setHistory(!historys)
    }

  return ( 
    <>

    <div >
        {
            ootp === true ? 
            <>
        <div className='mt-5'  >
            <p style={{ color : '#000' , fontSize :  14 , fontWeight : '600'}} >Note:-</p>
            <p style={{ fontSize : 10 , color : '#000' , fontWeight : '400' , marginTop : -20  }} >Once the issue is Solved share the OTP to our Service engineer</p>
            <div style={{ backgroundColor : '#EAECEE' , height : 100 , width : '100%' , padding :  10 , borderRadius :  5 }}>
                <p style={{ fontSize :  18 , color : '#000' , fontWeight : '600' , textAlign : 'center' }} >Service OTP</p>
                <h4 style={{ textAlign:'center' , letterSpacing : '10px' , fontSize : '30px' }} className='mt-4'>{otp?otp : ''}</h4>
            </div>
        </div>
         <div className='lo_tw_thirteen my-4'>
         </div>
         </>
        :''

        }
        
       
    </div>
        <div className='mt-5'>
            <div className='row'>
                <div className='col-7'>
                    <p className='hand_two'>{alldata?.product.ProName}</p>
                </div>
                <div className='col-5'>
                    <img style={{width:'100%'}} className='mt-3' src={ImageUrl+'/'+alldata?.product.ProImgPrimary} />
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-5'>
                    <p className='qr_sighteenen'>Serial No</p>
                </div>
                <div className='col-7'>
                    <p className='qr_sighteenen'>: {alldata?.detail.serialNo}</p>
                </div>
                <div className='col-5'>
                    <p style={{marginTop:'-5px'}} className='qr_sighteenen'>Order ID</p>
                </div>
                <div className='col-7'>
                    <p style={{marginTop:'-5px'}} className='qr_sighteenen'>: {alldata?.orderId}</p>
                </div>
                <div className='col-5'>
                    <p style={{marginTop:'-5px'}} className='qr_sighteenen'>Subscribtion Period</p>
                </div>
                <div className='col-7'>
                    <p style={{marginTop:'-5px'}} className='qr_sighteenen'>: {alldata?.detail.fromDate} - {alldata?.detail.toDate}</p>
                </div>
            </div>
        </div>
        <p className='hand_twossss mt-2'>Select Issue</p>
            <select disabled={ ootp === true ? true : false } onChange={(e)=>optionSel(e)} style={{marginTop:'-10px'}} className="form-select kyc_in_one" aria-label="Default select example">
                <option selected={ootp=== true ?'selected' : ''} >{ootp === true ?  alldata?.selectedIssue  :'None of the above'}</option>
                {
                    alldata?.issue.map((data,key)=>
                        <option selected={key===0 && ootp=== false ?'selected' : ''} value={data.ServiceMasterName}>{data.ServiceMasterName}</option>
                    )
                }
            </select>
        <div>
           <textarea disabled={ ootp === true ? true : false } onChange={(e)=>setEditvalue(e.target.value)} value={ootp === true ?  alldata?.manualissue  : editvalue} placeholder="Enter your Issue" className="form-control mt-3" id="exampleFormControlTextarea1" rows="5" ></textarea>
        </div>
        <div className='d-flex justify-content-between mt-3'>
            <p className='hand_four'>Service at</p>
            {/* <Link to='/address'>
                <p className='hand_five'>Manage Address</p>
            </Link> */}
        </div>
                { alladdress?.map((data,key)=>{
                    return(
                    <div onClick={()=>switch_data(data,key)}  >
                        <div className={(sw===key? 'web_cart_col_mone mt-3' : 'web_cart_col_mfive mt-3')}>
                            <div className='row'>
                                <div className='col-10'>
                                    <p className='web_cart_col_mtwo'>{data.UserDelivAddrFName}</p>
                                    <p className='web_cart_col_mthree'>{data.UserDelivAddrAddress}</p>
                                </div>
                                <div className='col-2'>
                                    <input style={{marginTop : '29px'}} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={(sw===key ? 'checked' : '')} />
                                </div>
                            </div>
                        </div>
                    </div>
                    )
                })
                }
{/* 
        <div className='d-flex justify-content-between mt-3'>
            <p className='hand_four'>Service History</p>
            <img onClick={()=>history()} style={{width : '10px' , height : '8px' , cursor :'pointer'}} className={historys === false ? '' : 'ser_his_p_five' } src={down} />
        </div>

        <div className='row'>
                                {
                                  historys === true ?<>
                                  {
                                    alldata?.serviceDetails.map(( data , key ) =>
                                    {
                                      return(
                                        <div className='col-12'>
                                          <div className='ser_his'>
                                            <div className='d-flex justify-content-between  '>
                                              <p  onClick={()=>{
                                                if(data.ServiceStatus === 0){

                                                }
                                              }} className={data.ServiceStatus === 0 ? 'ser_his_p_four' : data.ServiceStatus === 1 ? 'ser_his_p' : 'ser_his_p' }>{ data.ServiceStatus === 0 ? 'Requested' : data.ServiceStatus === 1 ? 'Serviced' : 'Replaced' }</p>
                                              <p className='ser_his_p_two'>{data.RequestedDate}</p>
                                            </div>
                                            <hr />
                                            <div className='row'>
                                              <div className='col-6'>
                                                <p className='ser_his_p_three'>Service No</p>
                                              </div>
                                              <div className='col-6'>
                                                <p className='ser_his_p_three'>: {data.ServiceId}</p>
                                              </div>
                                            </div>
                                            <div className='row'>
                                              <div className='col-6'>
                                                <p className='ser_his_p_three'>Prodect ID</p>
                                              </div>
                                              <div className='col-6'>
                                                <p className='ser_his_p_three'>: Requested</p>
                                              </div>
                                            </div>
                                            <div className='row'>
                                              <div className='col-6'>
                                                <p className='ser_his_p_three'>Issue Faced</p>
                                              </div>
                                              <div className='col-6'>
                                                <p className='ser_his_p_three'>: {data.Issue}</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    }
                                    )
                                  }
                                  </>
                                  
                                   : ''
                                }

        </div> */}

                                {
                                  errmsg === true ?
                                  <p style={{ color : 'red' , fontSize :  14 , textAlign : 'center' }}>{issueval}</p> 
                                  : ''
                                }


                                {
                                    ootp === true ? "" : 
                                    <div className='qr_fifteenc' style={{ width : '100%' }}>
                                    <div onClick={()=>lastApi()} className='qr_sixteen' style={{ width : '100%' }}>
                                        <p className='qr_sixteens'>Request Service</p>
                                    </div>
                                </div>

                                }

      <Modal open={open} onClose={onCloseModal} center>
        <p className='hand_foot_one mt-4'>Service Requested</p>
        <p className='hand_foot_two'>Our Technician is on the way</p>
        <Link to='/' >
            <div className='hand_foot_three mb-3'>
                <p className='hand_foot_five mt-2'>Back to home</p>
            </div>
        </Link>
      </Modal>
        <ToastContainer />
    </>
  )
}
