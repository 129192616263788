import React from 'react'
import { Link } from 'react-router-dom'

export default function Three_row() {
  return (
    <>
      <div className="row mt-4 mb-4">
        <div className='col-4'>
            
              <p className='middd'><Link to='/terms'>T & C</Link></p>
        </div>
        <div className='col-4'>
              <p className='middd'> <Link to='/privacy_policy'>Privacy Policy</Link></p>
        </div>
        <div className='col-4'>
          
            <p className='middd'><Link to='/faq'>F.A.Q</Link></p>
          
        </div>
        {/* <div className='col-4 mt-4'>
         
            <p className='middd'> <Link to='/shipping_policy'>Shipping Policy</Link></p>
          
        </div>
        <div className='col-5 mt-4'>
          
            <p className='middd' style={{textAlign:'left' ,  marginLeft:'5px'}}><Link to='/refund_return_policy'>Return/Handover</Link></p>
          
        </div> */}
    </div>
    </>
  )
}