import React from 'react'
import History_home_comp from './history_home/history_home_comp'
import Header_two from '../common/head_foot/header_two'
import Footer from '../common/footer'

export default function History_home() {
  return (
    <>
        <Header_two />
        <History_home_comp />
        <Footer />
    </>
  )
}
