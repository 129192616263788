import React , { useState , useEffect  , useRef } from 'react'
import axios from 'axios';
import { useSelector , useDispatch } from 'react-redux';
import { allProduct } from '../redux/actions/productAction';
import Web_cat from './fullwidth/web_cat.js/web_cat';
import { Base_Url } from './globalvariable/globe';
import Skeleton from 'react-loading-skeleton'
import { useNavigate , useParams } from 'react-router-dom';
import Footer from '../common/footer';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import leftar from '../assets/img/leftar.png' 
import rightar from '../assets/img/rightar.png'

export default function Collection_detail() {
  const responsive = {
    0: { items: 2 }, 
    868: { items: 3},
    968: { items: 3},
    1100: { items: 4},
    1300: { items: 5},
    1321: { items: 5 },
    1440 : {items: 6},
    1650 : {items: 7},
    1850: { items: 8 },
};
const carousel = useRef(null);


  let {name , bro} = useParams()

    const[ active , setActive ] = useState('none');

    let navigate = useNavigate()

    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 40;
    const [ cat , setCat ] = useState(0)
    const [ brand , setBrand ] = useState(bro)
    const [ pkey , setPkey ] = useState(0)

    let loadPages = [ 1 , 2 , 3 , 4 ];

    const dispatch = useDispatch()
    
    const product =  useSelector((state)=>state.allpoductData)
    const imgs = product.awsbaseurl

    let skli = [ 1, 2, 3, 4, 5, 6]

    const {id} = useParams()
    
    

    const [ usename , setUsename ] = useState(name)

    const clicked = (data,key) => {
      setBrand(0)
      setUsename(data.CatName)
      setPkey(key) 
      setCat(data.CatId)
      console.log('this is ')
      navigate('/products/'+data.CatName+'/'+data.CatId+ '/' + 'All' + '/'+ 0 )
      return
      if(active == 'none'){
          setActive('block')
      }else{
          setActive('none')
      }
    }

  
  const bodyParameters = {
     key: "value"
  }; 

  const clikks =async () =>{
    let responce =await axios({
      method: 'post',
      url: `${Base_Url}/products`,
      bodyParameters,
      data :{
        catId : id,
        brandId : brand
      }
    })
    setCat(id)
    dispatch(allProduct(responce.data.Data))
  }

    const clikk =async () =>{
      
        let responce =await axios({
          method: 'post',
          url: `${Base_Url}/products`,
          bodyParameters,
          data :{
            catId : cat,
            brandId : brand
          }
        })
        dispatch(allProduct(responce.data.Data))
      }
 
  

    useEffect(()=>{
        clikks()
    },[])

    useEffect(() => {
        clikk()
    },[cat]);


      const items =   product.category && product.category.map((dat,key)=>
      <div  onClick={()=>clicked(dat,key)} className="col" style={{zIndex:'4' , marginTop : 10 , }}>
        <div className={( usename === dat.CatName ? 'web_collection' : 'thr_one' )} >
            <p style={{cursor : 'pointer'}} className={( usename === dat.CatName ? 'web_text_col' : 'thr_three')} >{dat.CatName}</p>
        </div>
      </div>,
      
    ) 


  return (
    <>

        <div  className={product?.pro?.length <= 4 || product?.pro === undefined || product?.pro === null || product?.pro=== ''  ? "collections-area adfsuifwewc" : 'collections-area' } style={{paddingTop:'90px',paddingBottom:'50px'}}>
            <div  className="container  web_cat_five">
                <div className="">

                <div className="row justify-content-md-center" style={{paddingRight : 10 , paddingLeft : 10}} >
                <div className='thumf'>

                <AliceCarousel key="carousel" ref={carousel} disableButtonsControls={true}  mouseTracking items={items} infinite = {true}  disableDotsControls={true}  responsive={responsive} />
                <div className="btn-prev " onClick={(e) => carousel?.current?.slidePrev(e)} ><img src={leftar} /></div>
                <div className="btn-next " onClick={(e) => carousel?.current?.slideNext(e)}><img src={rightar} /></div>
            </div>
            <div>
                {
                }
            </div>
                     
                    {/* <AliceCarousel key="carousels" ref={carousel} disableButtonsControls={true} mouseTracking items={items}   disableDotsControls={true}  responsive={responsive} /> */}
                 
                        
                </div>
                <div className='row'>
                  {
                    !product.category && skli.map(()=>{
                      return(
                        <div  className="col-md-6 col-lg-4 col-xl-4 col-sm-6  col-xxl-2 mt-lg-3 mt-xl-3 mt-md-3 mt-sm-3" style={{zIndex:'4',paddingRight : 10 , paddingLeft : 10}}>
                          <div className=""></div>
                            <div className= 'thr_one_skl' >
                          
                            </div>
                        </div> 
                      )
                    })
                  }
                </div> 

              </div>
               
        </div>

        <div className='container web_cat_five '>
          <div className='row mt-2'>
            {
                !product.category && loadPages.map ((data)=>{
                    return(
                      <div  className="col-md-6 col-lg-4 col-xl-4 col-sm-6 col-xxl-3 home_pro_cont" style={{marginTop:'40px' }}> 
                        <Skeleton height={300} />
                      </div>
                    )
                })
                
            }
        </div>
      </div>
        {
            product.brands_of_1st_category ? <Web_cat catids={cat} /> : ''
        }
    </div>
    <Footer no={product?.pro?.length <= 4 || product?.pro === undefined || product?.pro === null || product?.pro=== ''  ? 1 : 0 } />
    </>
  )
}