import React , { useState , useEffect } from 'react'
import { useNavigate  , Link } from 'react-router-dom'
import axios from 'axios'
import file from '../../../assets/img/file.png'
import bill from '../../../assets/img/bill.png'
import delect from '../../../assets/img/delect.png'
import kyc4 from '../../../assets/img/kyc4.png'
import headset from '../../../assets/img/ggerhead.png'
import { ToastContainer, toast } from 'react-toastify';
import { ImageUrl , Base_Url } from '../../globalvariable/globe'
import ReactLoading from 'react-loading';
import ci_plus from '../../../assets/img/ci_plus.png'
import Dot_arrow from '../cart_one/dot_arrow'

export default function Mob_kyc_four() {

   
    const [ kycstatus , setKcstatus ] = useState()

    let navigate = useNavigate()

    const [ showimg , setShowimg ] = useState(0)

    let user = localStorage.getItem('user_data')

    const [ datatrue , setDatatrue ] = useState(false)

    const [ loading , setLoading ] = useState(false)

    const [ aadharname , setAadharname ] = useState('')
    const [ aadharnamevali , setAadharnamevali ] = useState(false)

    const [ valiaddname , setValiaddname ] = useState(false)
    const [ suskyc , setSuskyc ] = useState(false)

    const [ electrictype  , setElectrictype ] = useState()
    const [ electricimage , setElectricimage ] = useState()

    const [ banktype , setBanktype ] = useState()
    const [ bankimage , setBankimage ] = useState()

    const [ electiontype , setElectiontype ] = useState()
    const [ electionimage , setElectionimage ] = useState()

    const [ drivingtype , setDrivingtype ] = useState()
    const [ drivingimage , setDrivingimage ] = useState()

    const [ imageA , setImageA ] = useState()
    const [ imageB , setImageB ] = useState()
    const [ imageC , setImageC ] = useState()
    const [ imageD , setImageD ] = useState()

    const [ one , setOne ] = useState(false)
    const [ two , setTwo ] = useState(false)
    const [ three , setThree ] = useState(false)
    const [ four , setFour ] = useState(false)

    const [ alldatas , allsetData ] = useState('')

    const [ typeA , setTypeA ] = useState()
    const [ typeB , setTypeB ] = useState()
    const [ typeC , setTypeC ] = useState()
    const [ typeD , setTypeD ] = useState()

    useEffect(()=>{
        alldata()
    },[])

    const errnotify = (data) => toast.error(data, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    
    const Clickedone = ()=>{
        navigate('/kyc_five')
    }

    const alldata = async () =>{
        if(user === null){
            navigate('/')
            return
        }
        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/kycUserAddressProof`,
            headers: { 
                'Authorization': `Bearer ${user}` 
            }
        })
        .then((res)=>{
            allsetData(res.data)
            setKcstatus(res.data.Data.KycApproval)
            console.log(res,'first data')
            if(res.data.status === false){ 
                setShowimg(0)
            }
            // if(res.data.Data.address.KycUserAddressProofType != '' || res.data.Data.address.KycUserAddressProofType != null){
            //     setShowimg(0)
            // }
            if(res.data.Data.address.typeB == '' || res.data.Data.address.typeB === null ){
                
            }else{
                setShowimg(1)
            }
            if(res.data.Data.address.typeC === '' || res.data.Data.address.typeC === null ){
                
            }else{
                setShowimg(2)
            }
            if(res.data.Data.address.typeD === '' || res.data.Data.address.typeD === null ){
                
            }
            else{
                setShowimg(3) 
            }
            res.data.Data.address.KycUserAddress === '' ? setAadharname() : setAadharname(res.data.Data.address.KycUserAddress)
            if(res.data.Data.KycApproval === 1 ){
                setSuskyc(true)
            }


            if(res.data.Data.address.KycUserAddressProofImg === '' || res.data.Data.address.KycUserAddressProofImg === null ){
                setImageA()
                setOne(false)
            }else{
                setImageA(res.data.Data.address.KycUserAddressProofImg)
                setOne(true)
            }

            if(res.data.Data.address.imgB === '' || res.data.Data.address.imgB === null ){
                setImageB()
                setTwo(false)
            }else{
                setImageB(res.data.Data.address.imgB)
                setTwo(true)
            }

            if( res.data.Data.address.imgC === '' || res.data.Data.address.imgC === null ){
                setImageC()
                setThree(false)
            }else{
                setImageC(res.data.Data.address.imgC)
                setThree(true)
            }

            if(res.data.Data.address.imgD === '' || res.data.Data.address.imgD === null ){
                setImageD()
                setFour(false)
            }else{
                setImageD(res.data.Data.address.imgD)
                setFour(true)
            }

            
            
            res.data.Data.address.KycUserAddressProofType === '' ? setTypeA() : setTypeA(res.data.Data.address.KycUserAddressProofType)
            // res.data.Data.address.KycUserAddressProofImg === '' ? setImageA() : setImageA(res.data.Data.address.KycUserAddressProofImg)
            // res.data.Data.address.KycUserAddressProofType === '' ? setElectrictype() : setElectrictype(res.data.Data.address.KycUserAddressProofType)

            res.data.Data.address.typeB === '' ? setTypeB() : setTypeB(res.data.Data.address.typeB)
            // res.data.Data.address.imgB === '' ? setImageB() : setImageB(res.data.Data.address.imgB)
            // res.data.Data.address.typeB === '' ? setBanktype() : setBanktype(res.data.Data.address.typeB)

            res.data.Data.address.typeC === '' ? setTypeC() : setTypeC(res.data.Data.address.typeC)
            // res.data.Data.address.imgC === '' ? setImageC() : setImageC(res.data.Data.address.imgC)
            // res.data.Data.address.typeC === '' ? setElectiontype() : setElectiontype(res.data.Data.address.typeC)

            res.data.Data.address.typeD === '' ? setTypeD() : setTypeD(res.data.Data.address.typeD)            
            // res.data.Data.address.imgD === '' ? setImageD() : setImageD(res.data.Data.address.imgD)
            // res.data.Data.address.typeD === '' ? setDrivingtype() : setDrivingtype(res.data.Data.address.typeD)
            if(res.data.Data.KycApproval === 2 ){
                // setElectrictype('')
                // setElectricimage('')
                // setBanktype('')
                // setBankimage('')
                // setElectiontype()
                // setElectionimage()
                // setDrivingtype()
                // setDrivingimage()
                setShowimg(0)
                setAadharname('')
                setImageA('')
                let electricimageid = document.querySelector('#electricimage');
                console.log(electricimageid.files[0],'adjswofiwsfiyhwhu')
            }
            
        })
        .catch((err)=>{
            console.log(err)
        })
    }
    

   
    const Clicked = async () => {
        
        if( one === false && two === false && three === false && four === false){
            errnotify('Add Image')
            return
        }else{

        }
        setLoading(true)
        setAadharnamevali(false)


        if(aadharname === ''){
            setLoading(false)
            setAadharnamevali(true)
            errnotify('Address Field is required')
            return
        }
        let electricimageid = document.querySelector('#electricimage');
        let bankimageid = document.querySelector('#bankimage');
        let electionimageid = document.querySelector('#electionimage');
        let drivingimageid = document.querySelector('#drivingimage');

        // if(imageA != '' || imageB != '' || imageC != '' || imageD != ''){
            
        // }
        // if(electricimageid.files[0] !== undefined && electrictype === undefined){
        //     console.log('sussss')
        //     setElectrictype('electri')
        // }

        // if(imageA === '' && electricimageid === null || electricimageid.files[0] === undefined ){
        //     setLoading(false)
        //     errnotify('Select Image Field')
        //     return
        // }

        let formData = new FormData();

        

        formData.append("address", aadharname);
        
        formData.append("addressProofA", electricimageid === null ? '' :  electricimageid.files[0] );
        formData.append("addressProofB", bankimageid === null ? '' : bankimageid.files[0] );
        formData.append("addressProofC", electionimageid === null ? '' : electionimageid.files[0] );
        formData.append("addressProofD", drivingimageid === null ? '' : drivingimageid.files[0] );

        formData.append("typeA", electricimageid !== null && electricimageid.files[0] !== undefined && electrictype === undefined ? 'electri' : electrictype !== undefined ? electrictype : '' );
        formData.append("typeB", bankimageid !== null && bankimageid.files[0] !== undefined && banktype === undefined ? 'bank' : banktype !== undefined ? banktype : '' );
        formData.append("typeC", electionimageid !== null && electionimageid.files[0] !== undefined && electiontype === undefined ? 'election' : electiontype !== undefined ? electiontype : '' );
        formData.append("typeD", drivingimageid !== null && drivingimageid.files[0] !== undefined && drivingtype === undefined ? 'driving' : drivingtype !== undefined ? drivingtype : '' );

        // return
        let responce = await axios.post ( `${Base_Url}/kycUserAddressProof`, formData, {
            headers: { 
                'Authorization': `Bearer ${user}` 
            }
        })
        .then((res)=>{
            if(res.data.status === false){
                setLoading(false)
                errnotify('Select Image Field')
            }else{
                navigate('/kyc_five')
            }
        })
        .catch((err)=>{
            setLoading(false)
            if(err.response.status === 500 ){
                navigate('/kyc_five')
            }
            console.log(err)
        })
    }
    let countinc = () =>{
        setShowimg(showimg+1)
    }

    let typeJson = [
        {
            'data' : 'electri',
            'value' : 'Electricity Bill'
        },
        {
            'data' : 'bank',
            'value' : 'Bank Book'
        },
        {
            'data' : 'election',
            'value' : 'Election Id'
        },
        {
            'data' : 'driving',
            'value' : 'Driving'
        }
    ]

    const electricImagefun = (e) => {
        setOne(true)
        setImageA()
        setElectricimage({'img' : URL.createObjectURL(e.target.files[0])})
    }

    const electricremove = () =>{
        setOne(false)
        document.querySelector('#electricimage').value = "";
        setImageA()
        setElectricimage()
    }

    const bankImageFun = (e) => {
        setTwo(true)
        setImageB()
        setBankimage({'img' : URL.createObjectURL(e.target.files[0])})
    }

    const bankRemove = () =>{
        setTwo(false)
        document.querySelector('#bankimage').value = "";
        setImageB()
        setBankimage()
    }

    const electionImageFun = (e) => {
        setThree(true)
        setImageC()
        setElectionimage({'img' : URL.createObjectURL(e.target.files[0])})
    }

    const electionRemove = () =>{
        setThree(false)
        document.querySelector('#electionimage').value = "";
        setImageC()
        setElectionimage()
    }

    const drivingImageFun = (e) => {
        setFour(true)
        setImageD()
        setDrivingimage({'img' : URL.createObjectURL(e.target.files[0])})
    }

    const drivingRemove = () =>{
        setFour(false)
        document.querySelector('#drivingimage').value = "";
        setImageD()
        setDrivingimage()
    }
 
    const gotosupport =()=>{
        navigate('/support')
    }


  return (
    <>
        {
            kycstatus === 1 ? '' : kycstatus === 0 ? <img style={{ width : '100%' }} src={kyc4} /> : alldatas?.status === false ?  <img style={{ width : '100%' }} src={kyc4} />  :  kycstatus === 2 ? <img style={{ width : '100%' }} src={kyc4} />  : ''
        }
         <div className='mt-4'>
          <div className='row'>
            <div className='col-2'>
            { 
                                                    alldatas?.status === false ? 
                                                    <div class="donut-ring">
                                                        <div className={kycstatus === 1 ? "ring red" : "ring redone"}></div>
                                                        <div className={kycstatus === 1 ? "ring blue" : "ring bluethree"}></div>
                                                        <div className={kycstatus === 1 ? "ring orange" : "ring orangetwo"}></div>
                                                        <div className={kycstatus === 1 ? "ring grrreeeennn4" : "ring green"}></div>
                                                        <div className="cutout">
                                                        <p className='kyc_p_one'>4 Of 4</p>
                                                        </div>
                                                    </div>
                                                    : kycstatus === 1 ? 
                                                        <div class="donut-ring">
                                                        <div className={kycstatus === 1 ? "ring redsgfghfghfd" : "ring red"}></div>
                                                        <div className={kycstatus === 1 ? "ring bluethreeeapp" : "ring blue"}></div>
                                                        <div className={kycstatus === 1 ? "ring orangegreenss" : "ring orange"}></div>
                                                        <div className={kycstatus === 1 ? "ring grrreeeennn4" : "ring green"}></div>
                                                        <div className="cutout">
                                                            <p className='kyc_p_one'>4 Of 4</p>
                                                        </div>
                                                        </div>
                                                    :
                                                    kycstatus === 0 ? 
                                                        <div class="donut-ring">
                                                        <div className={kycstatus === 1 ? "ring red" : "ring redsgnnfnd"}></div>
                                                        <div className={kycstatus === 1 ? "ring blue" : "ring bluethreee"}></div>
                                                        <div className={kycstatus === 1 ? "ring orange" : "ring orangetwoss"}></div>
                                                        <div className={kycstatus === 1 ? "ring grrreeeennn4" : "ring yellowonw"}></div>
                                                        <div className="cutout">
                                                            <p className='kyc_p_one'>4 Of 4</p>
                                                        </div>
                                                        </div>
                                                    :
                                                    kycstatus === 2 ? 
                                                    <div class="donut-ring">
                                                        <div className="ring redfinalll"></div>
                                                        <div className="ring bluethreeeappred"></div>
                                                        <div className="ring orangered444"></div>
                                                        <div className= "ring redones"></div>
                                                        <div className="cutout">
                                                        <p className='kyc_p_one'>4 Of 4</p>
                                                        </div>
                                                    </div>
                                                    : 
                                                    ''

                                                    }
            </div>
            <div className='col-10'>
                <div className='d-flex justify-content-between'>
                    <div>
                        <p className='kyc_p_two'>Step 4</p>
                        <p style={{ marginLeft : 10 }} className='kyc_p_four'>Address</p>
                    </div>
                    {
                        kycstatus === 0 ? <p style={{ color: '#cfcf00', fontSize: '12', fontWeight: '600' }} >Pending</p> : kycstatus === 1 ? <p style={{ color: 'green', fontSize: '12', fontWeight: '600' }} >Approved</p> : kycstatus === 2 ? <p style={{ color: 'red', fontSize: '12', fontWeight: '600' }} >Rejected</p> : ''
                    }
                    </div>
                 </div>
            </div>
        </div>
        <div className='mt-4'>
          <div>
            <p className='kyc_two_para'>Address</p>
            <input type="text" onChange={(e)=>setAadharname(e.target.value)} value={aadharname} disabled={suskyc ? 'disabled' :''} className="form-control kyc_in_one" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Address" />
            {
                aadharnamevali === true ? <p style={{color:'red',fontSize:'9px'}}>Enter Valid Address</p> : ''
            }
          </div>
         
        </div>

        
        {
             showimg >=0  ? 
            <>
                <div className='mt-3'>
                <p className='kyc_two_para'>Type</p>
                    <select onChange={(e)=>setElectrictype(e.target.value)} className="form-select kyc_in_one" aria-label="Default select example">
                    {
                        typeJson.map((data,key)=>{
                            return(
                                <option value={data.data}  selected = {(() => { 
                                    if(typeA === null){
                                        if('electri' === data.data){
                                            return('selected')
                                        }
                                    }else{
                                        if(typeA === data.data){
                                            return('selected')
                                        }
                                    }
                                    
                                })()
                                }
                                >{data.value}</option>
                            )
                        })
                    }
                    </select>
                </div>
                <div className='mt-3'>
                        <p className='kyc_p_four'>Upload Address Proof</p>
                        <div style={{marginBottom:'50px'}} className='kyc_cont'>
                        {
                            imageA ? <img className='kyc_img_one'  style={{width:'100%'}} src={ ImageUrl + '/' + imageA } />  : electricimage ? <img className='kyc_img_one'  style={{width:'100%'}} src={electricimage.img} /> : 
                            alldatas?.status === false ? <img className='kyc_img_one img_opacttty'  src={bill} /> : kycstatus === 2 ?  <img className='kyc_img_one img_opacttty'  src={bill} /> : ''
                        } 
                        {
                            suskyc ||  kycstatus === 0 ? '' :      
                            <div style={{paddingBottom:'100px'}} class="kyc_centered">
                                <div className='kyc_cont_two'>
                                    <div className='d-flex justify-content-around'>
                                        <div className='kyc_cont_two_upload mt-2'>
                                            <img className='kyc_cont_two_upload_two kyc_img_one kyc_img_one' src={file} />
                                            <input style={{ width : 50 }} onChange={(e)=>electricImagefun(e)} accept="image/*" name='file' className='kyc_cont_two_upload_three' id='electricimage' type="file" />
                                        </div>
                                        <div className='mt-2 kyc_line'>
                                        </div>
                                        <div className=' mt-2'>
                                            <img onClick={()=>electricremove()} src={delect} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        </div>
                    </div>
            </>
        :''
        }


        {
             showimg >=1 || typeB != null  ? 
            <>
                <div className='mt-3'>
                <p className='kyc_two_para'>Type</p>
                    <select onChange={(e)=>setBanktype(e.target.value)} className="form-select kyc_in_one" aria-label="Default select example">
                    {
                        typeJson.map((data,key)=>{
                            return(
                            <option selected ={(() => { 
                                if(typeB === null){
                                    if('bank' === data.data){
                                        return('selected')
                                    }
                                }else{
                                    if(typeB === data.data){
                                        return('selected')
                                    }
                                }
                                
                            })()
                            } value={data.data} >{data.value}</option>
                            )
                        })
                    }
                    </select>
                </div>
                <div className='mt-3'>
                        <p className='kyc_p_four'>Upload Address Proof</p>
                        <div style={{marginBottom:'50px'}} className='kyc_cont'>
                        {
                            imageB ? <img className='kyc_img_one'  style={{width:'100%'}} src={ ImageUrl + '/' + imageB } />  : bankimage ? <img className='kyc_img_one'  style={{width:'100%'}} src={bankimage.img} /> : 
                            alldatas?.status === false ? <img className='kyc_img_one img_opacttty'  src={bill} /> :kycstatus === 2 ?  <img className='kyc_img_one img_opacttty'  src={bill} /> : ''
                        } 
                        {
                            suskyc ||  kycstatus === 0 ? '' :      
                            <div style={{paddingBottom:'100px'}} class="kyc_centered">
                                <div className='kyc_cont_two'>
                                    <div className='d-flex justify-content-around'>
                                        <div className='kyc_cont_two_upload mt-2'>
                                            <img className='kyc_cont_two_upload_two kyc_img_one kyc_img_one' src={file} />
                                            <input style={{ width : 50 }} onChange={(e)=>bankImageFun(e)} accept="image/*" name='file' className='kyc_cont_two_upload_three' id='bankimage' type="file" />
                                        </div>
                                        <div className='mt-2 kyc_line'>
                                        </div>
                                        <div className=' mt-2'>
                                            <img onClick={()=>bankRemove()} src={delect} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        </div>
                    </div>
            </>
        :''
        }

            {
             showimg >= 2 || typeC != null ?
            <>
                <div className='mt-3'>
                <p className='kyc_two_para'>Type</p>
                    <select onChange={(e)=>setElectiontype(e.target.value)} className="form-select kyc_in_one" aria-label="Default select example">
                    {
                        typeJson.map((data,key)=>{
                            return(
                            <option selected = {(() => { 
                                if(typeC === null){
                                    if('election' === data.data){
                                        return('selected')
                                    }
                                }else{
                                    if(typeC === data.data){
                                        return('selected')
                                    }
                                }
                                
                            })()
                            } value={data.data} >{data.value}</option>
                            )
                        })
                    }
                    </select>
                </div>
                <div className='mt-3'>
                        <p className='kyc_p_four'>Upload Address Proof</p>
                        <div style={{marginBottom:'50px'}} className='kyc_cont'>
                        {
                            imageC ? <img className='kyc_img_one'  style={{width:'100%'}} src={ ImageUrl + '/' + imageC } />  : electionimage ? <img className='kyc_img_one'  style={{width:'100%'}} src={electionimage.img} /> : 
                            alldatas?.status === false ? <img className='kyc_img_one img_opacttty'  src={bill} /> : kycstatus === 2 ?  <img className='kyc_img_one img_opacttty'  src={bill} /> : ''
                        } 
                        {
                            suskyc ||  kycstatus === 0 ? '' :      
                            <div style={{paddingBottom:'100px'}} class="kyc_centered">
                                <div className='kyc_cont_two'>
                                    <div className='d-flex justify-content-around'>
                                        <div className='kyc_cont_two_upload mt-2'>
                                            <img className='kyc_cont_two_upload_two kyc_img_one kyc_img_one' src={file} />
                                            <input style={{ width : 50 }} onChange={(e)=>electionImageFun(e)} accept="image/*" name='file' className='kyc_cont_two_upload_three' id='electionimage' type="file" />
                                        </div>
                                        <div className='mt-2 kyc_line'>
                                        </div>
                                        <div className=' mt-2'>
                                            <img onClick={()=>electionRemove()} src={delect} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        </div>
                    </div>
            </>
        :''
        }

        {
            showimg >=3 || typeD != null ?

            <>
                <div className='mt-3'>
                <p className='kyc_two_para'>Type</p>
                    <select onChange={(e)=>setDrivingtype(e.target.value)} className="form-select kyc_in_one" aria-label="Default select example">
                    {
                        typeJson.map((data,key)=>{
                            return(
                            <option selected= {(() => { 
                                if(typeD === null){
                                    if('driving' === data.data){
                                        return('selected')
                                    }
                                }else{
                                    if(typeD === data.data){
                                        return('selected')
                                    }
                                }
                                
                            })()
                            } value={data.data} >{data.value}</option>
                            )
                        })
                    }
                    </select>
                </div>
                <div className='mt-3'>
                        <p className='kyc_p_four'>Upload Address Proof</p>
                        <div style={{marginBottom:'50px'}} className='kyc_cont'>
                        {
                            imageD ? <img className='kyc_img_one'  style={{width:'100%'}} src={ ImageUrl + '/' + imageD } />  : drivingimage ? <img className='kyc_img_one'  style={{width:'100%'}} src={drivingimage.img} /> : 
                            alldatas?.status === false ? <img className='kyc_img_one img_opacttty'  src={bill} /> : kycstatus === 2 ?  <img className='kyc_img_one img_opacttty'  src={bill} /> : ''
                        } 
                        {
                            suskyc ||  kycstatus === 0 ? '' :      
                            <div style={{paddingBottom:'100px'}} class="kyc_centered">
                                <div className='kyc_cont_two'>
                                    <div className='d-flex justify-content-around'>
                                        <div className='kyc_cont_two_upload mt-2'>
                                            <img className='kyc_cont_two_upload_two kyc_img_one kyc_img_one' src={file} />
                                            <input style={{ width : 50 }} onChange={(e)=>drivingImageFun(e)} accept="image/*" name='file' className='kyc_cont_two_upload_three' id='drivingimage' type="file" />
                                        </div>
                                        <div className='mt-2 kyc_line'>
                                        </div>
                                        <div className=' mt-2'>
                                            <img onClick={()=>drivingRemove()} src={delect} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        </div>
                    </div>
            </>
        :''
        }

        {
            showimg >=3 || kycstatus === 1 ||  kycstatus === 0 ? '' :
            <div style={{paddingBottom:'75px'}}>
                <div  style={{cursor : 'pointer'}} className='fi_kyc my-4'>
                    <div onClick={()=>countinc()} className='d-flex justify-content-around mt-2'>
                        <img src={ci_plus} />
                        <p>Add More</p>
                    </div>
                </div>
            </div>
        }

        
        

      <div className='mob_footss'>
          <div className='row'>
              <div className='col-6'>
                  <div className='d-flex'>
                      <div className='headset'>
                        <img className='kaart' src={headset} />
                      </div>
                        <Link to='/support' >
                            <div>
                                <p className='compara222'>Support</p>
                                <p className='compara333'>9am - 5Pm</p>
                            </div>
                        </Link>
                    
                  </div>
              </div>
              <div className='col-6'>
                <div onClick={()=>suskyc ? Clickedone() : Clicked()} className='bbtn'>
                    <div  className='d-flex'>
                        <p style={{cursor : 'pointer'}} className='compara nextonee mt-1'>{loading ?
                          <div style={{marginLeft:'-47px' , marginTop : '-4px'}}>
                            <ReactLoading type='balls' color='#ffffff' />
                          </div>
                             : 'Next'}</p>
                    </div>
                </div>
              </div>
          </div>
          <ToastContainer />
      </div>
    </>
  )
}