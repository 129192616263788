import React , { useEffect , useState } from 'react'
import { Link  } from "react-router-dom";
import pl from '../assets/img/pl.png'
import minus from '../assets/img/minus.png'
import savelist from '../assets/img/savelist.png'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { Base_Url } from './globalvariable/globe';
import Checkout from './checkout';
import ReactLoading from 'react-loading';
import { useDispatch } from 'react-redux';
import { LoogIn , Cartcount } from '../redux/actions/productAction';
import { useNavigate } from 'react-router-dom'
import down from '../assets/img/down.png'
import shop from '../assets/img/shop.png'
import logo from '../assets/img/icon.png'
import Footer from '../common/footer'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    ChakraProvider
  } from '@chakra-ui/react'

export default function Cart_detail() {

    const { isOpen, onOpen, onClose } = useDisclosure()

    let dispatch = useDispatch()

    let navigate = useNavigate()

    let token = localStorage.getItem('user_data')
    let typw = localStorage.getItem('user_type')

    const [ another , setAnother ] = useState()
    const [ rent , setRent ] = useState()
    const [ deposit , setDeposit ] = useState()
    const [ active , setActive ] = useState(false)
    const [ qty , setQty ] = useState()
    const [ total , setTotal ] = useState()
    const [ alltotal , setAlltotal ] = useState()

    const [ login , setlogin ] = useState(false)
    const [removess , setRemove ] = useState(true)
    //
    const [countss  , setCountss ]= useState(1)
    let wishss = localStorage.getItem('save_list')
    const [ wish ,  setWish ] = useState()
    const [ shoee , setShoee ] = useState(false)

    const [ loading , setLoading ] = useState(false)

    const [ message , setMessage ] = useState('')
    //

    let deposittotal = 0
    let rents = 0
    const [ tto , setTto ] = useState()

    const ones = () =>{
        deposittotal = 0
        rents = 0
        let data = localStorage.getItem('data')
        if ( data === null ){ 
            setRemove(false)
            return
        }else if(data === '[]'){
            setRemove(false)
        }
        setAnother(JSON.parse(data))
        JSON.parse(data).forEach((element, index) => {
            deposittotal  =  element.totalDeposit + deposittotal 
            rents = element.proTotalAmnt + rents
        })
        setTto(deposittotal)
        setTotal(rents)
        setAlltotal( deposittotal + rents )
    }

    useEffect(()=>{
        //
        wishss = localStorage.getItem('save_list')
        setWish(JSON.parse(wishss))
        //

        if ( token === null ) {
            setlogin(false)
        }else{
            setlogin(true)
        }
        ones()
    },[]) 

    //
    useEffect(()=>{
        wishss = localStorage.getItem('save_list')
        setWish(JSON.parse(wishss))
    },[countss])
    //


    const [ price , setPrice ] = useState(0)
    let one = 0

    let skl = [ 1 , 2 ]

    const errnotify = (dat) => toast.error( dat , {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    let datas = another
    console.log(datas,typeof(datas),'sopdhiwhuidgweuigduewg')
    // datas.reverse()

    
    const plus = ( dat , key ) =>{
      

        console.log(dat,'lkhsuigsuidgfgu')
        another.forEach((element, index) => {
            
            if ( element.proQty > 12 ){
                return
            }
            if(element.proId === dat.proId && element.monthCount === dat.monthCount && element.proBookFrom === dat.proBookFrom && element.proQty === dat.proQty && element.proVarId === dat.proVarId) {
                dat.totalDeposit =   typw === 'corporate' ? 0 : dat.totalDeposit + dat.proDepAmnt;
                if( typw === 'corporate' ){
                    dat.proDepAmnt = 0
                }
                dat.proTotalAmnt = dat.proTotalAmnt + ( dat.proVarAmnt * dat.monthCount );
                dat.proQty = dat.proQty + 1
                console.log('one')
            }
        });

        localStorage.setItem('data' , JSON.stringify(another))

        ones()

    }

    const minuss = ( dat , key ) =>{
      

        if(dat.proQty <= 1){
            return
        }

        another.forEach((element, index) => {
            if(element.proId === dat.proId && element.monthCount === dat.monthCount && element.proBookFrom === dat.proBookFrom && element.proQty === dat.proQty && element.proVarId === dat.proVarId) {
                dat.totalDeposit =   typw === 'corporate' ? 0 : dat.totalDeposit - dat.proDepAmnt;
                if( typw === 'corporate' ){
                    dat.proDepAmnt = 0
                }
                dat.proTotalAmnt = dat.proTotalAmnt - ( dat.proVarAmnt * dat.monthCount );
                dat.proQty = dat.proQty - 1
                console.log('one')
            }
        });

        localStorage.setItem('data' , JSON.stringify(another))

        ones()

    } 

    const remove = (dat , key) => {
        another.forEach((element, index) => {
            if(element.proId === dat.proId) {
                another.splice(index, 1); 
            }
        })

        localStorage.setItem('data' , JSON.stringify(another))
        dispatch(Cartcount(another))
        ones()
        if(another.length === 0){
            setRemove(false)
            // setTimeout(() => {
            //     navigate(0)
            //   }, 2000)
            return
        }
        // setTimeout(() => {
        //     navigate(0)
        //   }, 2000)

    }
//
    const removes = (dat , key) => {
        wish.forEach((element, index) => {
            if(element.proId === dat.proId) {
                wish.splice(index, 1); 
            }
        })

        localStorage.setItem('save_list' , JSON.stringify(wish))
        setCountss(countss +1)
        // if(wish.length === 0){
        //     setRemove(false)
        //     return
        //   }
    }
//

    const Checkout = async () => {
        console.log(another)
        if(login === false){
            dispatch(LoogIn({show : true}))
            return
        }
        console.log(another)
        setLoading(true)
        let responce = await axios ({
            method : 'post',
            url : `${Base_Url}/proceedToCheckOut`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            },
            data : {
                products : another
            }
        })
        .then((res)=>{
            setLoading(false)
            console.log(res)
            if(res.data.status === false){
                setMessage(res.data.message)
                onOpen()
            }else{
                window.localStorage.removeItem('data');
                navigate('/cart_one/'+ res.data.Data.orderId)
            }
        })
        .catch((err)=>{
            setLoading(false)
            errnotify('Checkout Failed')
            if(err?.response?.status === 401){
                dispatch(LoogIn({show : true}))
            }
            console.log(err)
        })
    }

    //

    const savellist =( data , key ) => {
        setCountss(countss +1)
        let sav_list = localStorage.getItem('save_list')
        let anotherss = JSON.parse(sav_list)
        let final = 0
        let save_data = [
            {
                datas : data
            }
        ]

        console.log(data)

        if(localStorage.getItem('save_list')){
            anotherss.forEach((element, index) => {
                if( element.datas.proId === save_data[0].datas.proId){
                    final = 1
                }
            })
            console.log(final,'final')

            if( final === 0 ){
                let allanother =  anotherss.concat(save_data)
                localStorage.setItem('save_list' , JSON.stringify(allanother))


                another.forEach((element, index) => {
                    if(element.proId === data.proId) {
                        another.splice(index, 1); 
                    }
                })
        
                localStorage.setItem('data' , JSON.stringify(another))
                ones()
                if(another.length === 0){
                    setRemove(false)
                    return
                }
                
            }

        }else{
            localStorage.setItem('save_list' , JSON.stringify(save_data))
        }
    }
    //

    //
    const showsave = () =>{
        setShoee(!shoee)
    }

    const movetocarts = (data,key) =>{

        // let save_data = [
        //         data
        // ]

        let localdata =   [
            {
                proVarId : data.datas.proVarId ,
                proId : data.datas.proId ,
                proQty : data.datas.proQty ,
                proDepAmnt : data.datas.proDepAmnt ,
                proRentAmnt : data.datas.proRentAmnt ,
                proVarAmnt : data.datas.proVarAmnt ,
                proTotalAmnt : data.datas.proTotalAmnt ,
                proRentType : data.datas.proRentType ,
                proBookFrom : data.datas.proBookFrom ,
                proBookTo : data.datas.proBookTo,
                imgurl : data.datas.imgurl,
                brand  :  data.datas.brand,
                monthCount : data.datas.monthCount,
                totalDeposit : data.datas.totalDeposit,
                alldet : data.datas.alldet
            }
        ]
        console.log(data,'anotherrr')


        let sav_list = localStorage.getItem('data')
        let anotherss = JSON.parse(sav_list)

        if (sav_list=='[]'){
            localStorage.setItem('data' , JSON.stringify(localdata))
            ones()
        }else{
            let allanother =  anotherss.concat(localdata)
            localStorage.setItem('data' , JSON.stringify(allanother))
            ones()
        }

        

        
        wish.forEach((element, index) => {
            if(element.proId === data.proId) {
                wish.splice(index, 1); 
            }
        })

        localStorage.setItem('save_list' , JSON.stringify(wish))
        setCountss(countss +1)
    }

    return (
    <>
        <div style={{marginTop:'150px'}} >
            <div className='container sdfvdfvddaeeee' style={{height : 'auto'}}>
                <div className='row'>

                    <div  style={{ minHeight : 490 }} className='col-xxl-8 col-xl-8 col-lg-12'>
                        <div className='web_cart_col_8'>
                            {
                                removess === true ? 
                                <>
                                    <div className='web_cart_col_one'>
                                        <div className='row'>
                                            <p className='web_cart_col_two'>My Cart ({ datas ? datas.length :''})</p>
                                        </div>
                                    </div>
                                    <hr />
                                </>
                                :
                                <>
                                    <div className='web_cart_col_one'>
                                        <div className='row'>
                                            <p className='web_cart_col_two'>Cart Is empty</p>
                                        </div>
                                    </div>
                                </>
                            } 
                            

                            {
                                
                                    datas && [...datas].reverse().map((dta,key)=>{
                                        return(
                                        <>
                                            <div className='row p-4'>
                                                <div className='col-2'>

                                                    <img src={dta.imgurl} />

                                                    <div className='row' style={{marginTop:'10px' , paddingBottom:'6px' ,width : '128px', backgroundColor:'beige' , borderRadius:'5px'}}>


                                                        <div className='col-4'>
                                                            <div onClick={()=>minuss(dta,key)} style={{marginLeft:'5px'}}>
                                                                <img style={{margin:'auto' , paddingTop:'15px'}} src={minus} />
                                                            </div>
                                                        </div>


                                                        <div className='col-4'>
                                                            <p style={{marginTop:'8px'}} className='cartotwo'>
                                                                {/* {()=>{
                                                                    setQty(dta.proQty)
                                                                }} */}
                                                                {active === true ? qty : dta.proQty}
                                                                </p>
                                                        </div> 


                                                        <div className='col-4'>
                                                            <div onClick={()=>plus(dta,key)} >
                                                                <img  style={{margin:'auto' , paddingTop:'5px' , display:'block'}} src={pl} />
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div className='col-6 mt-1'>
                                                    <p className='web_cart_col_four' >{dta.brand}</p>
                                                    {/* <p className='web_cart_col_five' >256/8 gb , 13.7” Retina Display</p> */}
                                                    {/* <p className='web_cart_col_four'>Delivery Expected by April 05</p> */}
                                                    <p  className='web_cart_col_five' >{dta.proRentType === 'Monthly' ? 'Month ' : dta.proRentType === 'Weekly' ? 'Week ' : 'Day '}Count : {dta.monthCount}</p>
                                                </div>
                                                <div className='col-4 mt-2'>
                                                    <div className='row'>
                                                        <div className='col-6'>
                                                            <p className='web_cart_col_six'>Deposit</p>
                                                            <p className='web_cart_col_seven'><span style={{fontFamily:'arial'}} >₹</span> { active === true ? deposit : dta.totalDeposit }{dta.proRentType === 'Monthly' ? '/M' : dta.proRentType === 'Weekly' ? '/W' : '/D' }</p>
                                                            {/* <div className='web_cart_col_eight mt-1'>
                                                                <div onClick={()=>savellist( dta , key )} className='d-flex justify-content-center'>
                                                                    <img style={{widows:'20px',height:'20px',marginTop:'10px',marginRight:'10px'}} src={savelist} />
                                                                    <p className='web_cart_col_four' style={{marginTop:'8px', cursor : 'pointer'}}>Save</p>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                        <div className='col-6'>
                                                            <p className='web_cart_col_six'>Rental Price</p>
                                                            <p className='web_cart_col_seven'><span style={{fontFamily:'arial'}} >₹</span> {active ===true ?rent : dta.proTotalAmnt}{dta.proRentType === 'Monthly' ? '/M' : dta.proRentType === 'Weekly' ? '/W' : '/D' }</p>
                                                            {/* <div style={{cursor : 'pointer'}} onClick={()=>remove(dta , key)} className='web_cart_col_nine mt-1'>
                                                                <p className='web_cart_col_ten' >Remove</p>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    <div className='row mt-3' >
                                                        <div className='col-12'>
                                                            <div style={{cursor : 'pointer'}} onClick={()=>remove(dta , key)} className='web_cart_col_nine mt-4'>
                                                                <p className='web_cart_col_ten' >Remove</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {datas?.length === key+1 ? '' : <hr /> }
                                            
                                        </>
                                        )
                                            
                                    })
                                
                            }
                            {(() => {
                                if(shoee === true){

                                if(wish != null){
                                
                                if(wish.length > 0){
                                    return ( 
                                        wish.map((dta,key)=>{
                                        return(
                                        <>
                                            <div className='row p-4'>
                                                <div className='col-2'>

                                                    <img src={dta.datas.imgurl} />


                                                </div>
                                                <div className='col-5 mt-1'>
                                                    <p className='web_cart_col_four' >{dta.datas.brand}</p>
                                                    <p  className='web_cart_col_five' >{dta.datas.proRentType === 'Monthly' ? 'Month ' : dta.datas.proRentType === 'Weekly' ? 'Week ' : 'Day '}Count : {dta.datas.monthCount}</p>
                                                </div>
                                                <div className='col-5 mt-2'>
                                                    <div className='row'>
                                                        <div className='col-7'>
                                                            <p className='web_cart_col_six'>Refundable Deposit</p>
                                                            <p className='web_cart_col_seven'><span style={{fontFamily:'arial'}} >₹</span> { active === true ? deposit : dta.datas.totalDeposit }{dta.datas.proRentType === 'Monthly' ? '/M' : dta.datas.proRentType === 'Weekly' ? '/W' : '/D' }</p>

                                                            <div className='web_cart_col_eight mt-1'>
                                                                <div onClick={()=>movetocarts( dta , key )} className='d-flex justify-content-center'>
                                                                    <img style={{widows:'20px',height:'20px',marginTop:'10px',marginRight:'10px'}} src={shop} />
                                                                    <p className='web_cart_col_four' style={{marginTop:'8px', cursor : 'pointer'}}>Move to cart</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='col-5'>
                                                            <p className='web_cart_col_six'>Rental Price</p>
                                                            <p className='web_cart_col_seven'><span style={{fontFamily:'arial'}} >₹</span> {active ===true ?rent : dta.datas.proTotalAmnt}{dta.datas.proRentType === 'Monthly' ? '/M' : dta.datas.proRentType === 'Weekly' ? '/W' : '/D' }</p>

                                                            <div style={{cursor : 'pointer'}} onClick={()=>removes(dta , key)} className='web_cart_col_nine mt-1'>
                                                                <p className='web_cart_col_ten' >Remove</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {datas?.length === key+1 ? <hr /> : '' }
                                            
                                        </>
                                        )
                                            
                                    })
                                    )
                                    }else{
                                        return (<></>)
                                    }
                                }
                                else{
                                    return ( <></>)
                                }
                            }
                            })()}
                            {
                                !datas && skl.map((dta,key)=>{
                                    return(
                                    <>
                                        {/* <div className='row p-4'>
                                            <div className='col-2'>

                                                <Skeleton width={'100%'} height={'100%'} /> 

                                            </div>
                                            <div className='col-6 mt-1'>
                                                <Skeleton count={1} />
                                                <Skeleton count={1} />
                                                <Skeleton count={1} />
                                                <Skeleton count={1} />
                                            </div>
                                            <div className='col-4 mt-2'>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <Skeleton count={1} />
                                                        <Skeleton count={1} />
                                                        <div className='web_cart_col_eight mt-1'>
                                                            <div className='d-flex justify-content-center'>
                                                                <Skeleton width={'100%'} height={'100%'} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                    <Skeleton count={1} />
                                                        <Skeleton count={1} />
                                                        <div className='web_cart_col_eight mt-1'>
                                                            <div className='d-flex justify-content-center'>
                                                                <Skeleton width={'100%'} height={'100%'} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </>
                                    )
                                
                                })
                            }
                        </div>

                    </div>

                    <div className='col-xxl-4 col-xl-4 col-lg-12'>
                        <div className='web_cart_col_4'  >{
                            removess === true ?
                        <>
                            <p className='web_cart_col_two' style={{paddingRight:'10px' , paddingTop :  10 , paddingBottom :  10}}>PRICE DETAILS</p>
                            <hr />
                            <div className='row'>
                                <div className='col-8'>
                                    <p className='web_cart_col_eleven' >Total Rental Price ({ datas ? datas.length :''} items)</p>
                                    <p className='web_cart_col_eleven' >Total Refundable Deposit</p>
                                    <hr/>
                                    <p className='web_cart_col_four' style={{marginTop:'20px',marginBottom:'20px'}} >Total Amount</p>
                                </div>
                                <div className='col-4'>
                                    <p className='web_cart_col_eleven' style={{textAlign:'right'}} ><span style={{fontFamily:'arial'}} >₹</span> {total}</p>
                                    <p className='web_cart_col_twelve' >{tto}</p>
                                    <hr/>
                                    <p className='web_cart_col_four' style={{marginTop:'20px',marginBottom:'20px' , textAlign:'right'}} ><span style={{fontFamily:'arial'}} >₹</span> {alltotal}</p>
                                </div> 
                            </div>
                            {
                                loading === true ? 

                                <div  style={{cursor:'pointer', width:'100%'}} className='web_cart_col_twenty_one'>
                                    <ReactLoading style={{ fill : '#fff' , margin :  'auto' , width : 64 , height : 64 , paddingTop : 13}} type='balls' color='#ffffff' />
                                </div>
                                :

                                <div onClick={()=>Checkout()} style={{cursor:'pointer', width:'100%'}} className='web_cart_col_twenty_one'>
                                    <p className='web_cart_col_twenty_two' >Proceed to Checkout</p>
                                </div>
                            }
                           
                            </>
                            : <p className='web_cart_col_two' style={{padding:'10px'}}>Cart Is empty</p>
                                }
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </div>
        
        <div style={{marginTop:'100px'}}>
        </div>
        
        <ChakraProvider >
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Out Of Stock</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <p>{message?message : ''}</p>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </ChakraProvider>

        
        

        <ToastContainer />
        <Footer no={datas?.length <= 2 || datas === undefined || datas === null || datas=== ''  ? 1 : 0 } />
    </>
    )
}