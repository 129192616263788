import React , { useState , useEffect } from 'react'
import feed from '../../../assets/img/feed.png'
import downl from '../../../assets/img/downf.png'
import downk from '../../../assets/img/downk.png'
import Timer from '../../mobile_view_comp/otpp/timer'
import { Base_Url , ImageUrl } from '../../globalvariable/globe'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import './running_details_erb.css'
import { useDispatch } from 'react-redux'
import Cart_one_head from '../../mobile_view_comp/cart_one/cart_one_head'
import axios from 'axios'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ChakraProvider,
  useDisclosure,
  Text,
  Button,
} from '@chakra-ui/react'
import hppy from '../../../assets/img/hppy.png'
import smmill from '../../../assets/img/smmill.png'
import saad from '../../../assets/img/saad.png'
import color_sad from '../../../assets/img/color_sad.png'
import color_happy from '../../../assets/img/color_happy.png'
import color_ok from '../../../assets/img/color_ok.png'
import { ToastContainer, toast } from 'react-toastify';

export default function History_detail_mob() {  

    let navigate = useNavigate()

    let dispatch = useDispatch()

    let { id , no } =  useParams()

    let token = localStorage.getItem('user_data')


    const [ alldata , setAlldata ] = useState()
    const [ data , setData ] = useState()

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [rating, setRating] = useState()
    const [feeddata, setFeeeddata] = useState()
    const successnotify = (sus) => toast.success('Feedback Submited Success', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    let Data = async () =>{

        if(token === null){
            navigate('/')
            return
          }

        
      
          if ( no === '1' || no === 1){
            let responce = await axios({
              method : 'post',
              url : `${Base_Url}/viewPendingPaymentOrder`,
              headers: { 
                  'Authorization': `Bearer ${token}` 
              },
              data : {
                  orderId : id
              }
            })
            .then((res)=>{
              setAlldata(res.data.Data)
              console.log(res.data)
            })
            .catch((err)=>{
              console.log(err)
            })
          }else{
            let responce = await axios({
              method : 'post',
              url : `${Base_Url}/viewPaidPaymentOrder`,
              headers: { 
                  'Authorization': `Bearer ${token}` 
              },
              data : {
                  orderId : id
              }
            })
            .then((res)=>{
              setAlldata(res.data.Data)
              console.log(res.data)
            })
            .catch((err)=>{
              console.log(err)
            })
          }

    }

    useEffect(()=>{
        Data()
    },[])

    const ratingChanged = (newRating) => {
      setRating(newRating);
  };
  const clicked = () => { 
      setFeeeddata('')
      onOpen()
  }

  const Feedback = async () => {
      let responce = await axios({
          method: 'post',
          url: `${Base_Url}/runningOrderFeedback`,
          headers: {
              'Authorization': `Bearer ${token}`
          },
          data: {
              orderId : id,
              rating : rating,
              description : feeddata
          }
      })
          .then((res) => {
              onClose()
              successnotify()
              console.log(res)
          })
          .catch((err) => {
              if (err.response.status === 401) {
                  navigate('/login')
              }
              console.log(err)
          })
  }
  

    return (
      <>
       <div style={{width:'90%' , margin:'auto' ,marginTop:'65px'}}>
          <Cart_one_head link='/profile' head='Payment Report' />
              <div className='pro_home_seven mt-3'>
                <div className='run_ord_one' style={ no === '0' ? { backgroundColor : 'rgba(0, 128, 0, 0.25)' } : {}} >
                    <div className='row'>
                        <div className='col-5'>
                            <Timer time={alldata?.orderDetails?.remainingDays} />
                        </div>
                        <div className='col-7'>
                            <p className='run_ord_two mb-4'>ID : {alldata?.orderDetails?.orderId}</p>
                            <p className='run_ord_three' style={{ fontSize : 11 }} >{alldata?.orderDetails?.fromDate} - {alldata?.orderDetails?.toDate}</p>
                        </div>
                    </div>
                </div>
                <div >
              <div className='row mt-2 mb-2' >

                <div className='col-6' >
                  <div onClick={()=>{ clicked() }} style={{ width : '100%' , height : 45 , backgroundColor : '#F4F4F4' , borderRadius :  5  , padding : 14 , cursor : 'pointer' }} >
                    <div>
                    <div className='d-flex justify-content-center' >
                      <div >
                        <img style={{ marginLeft : 'auto' , width : 30 }} className='run_ord_nine' src={feed} />
                      </div>
                      <p style={{ fontWeight : '500' , fontSize :  14 , color : '#000' }} >Feedback</p>
                    </div>
                    </div>
                  </div>
                </div>

                <div className='col-6' >
                  <div style={{ width : '100%' , height : 45 , backgroundColor : '#F4F4F4' , borderRadius :  5  , padding : 14 , cursor : 'pointer' }} >
                    <div>
                    <div className='d-flex justify-content-center' >
                      <div style={{ marginTop :  6  }} >
                        <img style={{ marginLeft : 'auto' , width : 25 }} className='run_ord_nine' src={downl} />
                      </div>
                      <p style={{ fontWeight : '500' , fontSize :  14 , color : '#000' }} >Downlaod</p>
                      <div style={{ marginTop :  10 }} >
                        <img style={{ marginLeft : 'auto' , width : 25 }} className='run_ord_nine' src={downk} />
                      </div>
                    </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
                <p className='run_ord_four'>Order Contains</p>
                <div>
                    {alldata?.productDetails?.map((data,key)=>
                    <div className='run_ord_five mt-3'>
                        <div className='row'>
                        <div className='col-8'>
                            <p className='run_ord_six'>{data.BrandName}</p>
                            <p className='run_ord_sevensss'>{data.ProName}</p>
                            <p className='run_ord_eightsss'>Qty : {data.CartProQty}</p>
                        </div>
                        <div className='col-4'>
                            <img style={{width:'80px'}} className='run_ord_ninea mt-4' src={ImageUrl+'/'+data.ProImgPrimary} />
                            {/* {data.status  ? (data.status == 'Replacement' ?
                            <div className='run_ord_tenss'>
                            <p className='run_ord_eleven'>Handovered</p>
                            </div>
                            :
                            <div className='run_ord_twelvess'>
                            <p className='run_ord_eleven'>Replacement</p>
                            </div>
                            ) : ''} */}
                        </div>
                        </div>
                    </div>
                    )}
                </div>
                
              </div>
        </div>

        <div className='popup_one'>
            <ChakraProvider>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>

                    <ModalHeader>
                          <p style={{ fontSize : 12 , color :'#000' , fontWeight : '600'}}>Did You Enjoy Our Service</p>
                      </ModalHeader>
                       

                      <ModalCloseButton />
                          <ModalBody>
                          <div style={{ marginTop : -15 }} className='d-flex justify-content-start' >
                            <div style={{ marginRight : 30 }} >
                              {
                                rating === 1 ? <img onClick={()=>{setRating(1)}} className='pro_home_three' src={color_sad} /> :  <img onClick={()=>{setRating(1)}} className='pro_home_three' src={saad} />
                              }
                              
                              <p style={{ color : '#000' , fontSize :  14 , fontWeight :  '600' , textAlign : 'center' , marginTop : 5 , marginBottom : 10 }} >Bad</p>
                            </div>
                            <div style={{ marginRight : 30 }} >
                              {
                                rating === 2 ? <img onClick={()=>{setRating(2)}} className='pro_home_three' src={color_happy} /> : <img onClick={()=>{setRating(2)}} className='pro_home_three' src={smmill} />
                              }
                              <p style={{ color : '#000' , fontSize :  14 , fontWeight :  '600' , textAlign : 'center' , marginTop : 5 , marginBottom : 10 }} >Fair</p>
                            </div>
                            <div >
                              {
                                rating === 3 ? <img onClick={()=>{setRating(3)}} className='pro_home_three' src={color_ok} /> : <img onClick={()=>{setRating(3)}} className='pro_home_three' src={hppy} />
                              }
                              
                              <p style={{ color : '#000' , fontSize :  14 , fontWeight :  '600' , textAlign : 'center' , marginTop : 5 , marginBottom : 10 }} >Good</p>
                            </div>
                          </div>

                              <Text fontWeight='bold' mb='1rem'>
                                  Tell us what can be improved !
                              </Text>
                              <textarea onChange={(e)=>setFeeeddata(e.target.value)} value={feeddata} className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                          </ModalBody> 

                        <ModalFooter>
                          <Button onClick={()=>Feedback()} style={{backgroundColor:'#161E40' , color:'white'}}>Submit Feedback</Button>
                        </ModalFooter>
                         
                    </ModalContent>
                </Modal>
            </ChakraProvider>
        </div>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        {/* Same as */}
        <ToastContainer />
 
      </>
    )
}
