import React from 'react'
import Left from '../../../assets/img/left.png'
import './card_one_top.css'
import { useNavigate } from 'react-router-dom'

export default function Cart_one_head(props) {

  let navigate = useNavigate()

  const clicked = () =>{
    navigate(-1)
  }

  return (
    <>
      <div className="second-tops fixed-top navbar-light top-tab-nav">
            <div className="d-flex  m-auto paddd" >
               <div onClick={()=>clicked()} className='loab'>
                  {/* <Link to={props.link}> */}
                    <img src={Left} />
                  {/* </Link> */}
                </div>
               <div style={{margin: 'auto'}} className='loab'>
                   <p className='ccaaat'>{props.head}</p>
               </div>
            </div>
        </div>
    </>
  )
}