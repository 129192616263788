import React  from 'react'
import Left from '../../../assets/img/left.png'
import { useNavigate } from 'react-router-dom'

export default function Shiping_mobile() {


  let navigate = useNavigate()

  const clicked = ()=>{
    navigate(-1)
  }

  return (
    <div className='container' style={{ margin:'auto' ,overflowX:'hidden'}}>
       <div className="second-tops fixed-top navbar-light top-tab-nav">
            <div className="d-flex  m-auto paddd" >
               <div onClick={()=>clicked()} className='loab'>
                  {/* <Link to={props.link}> */}
                    <img src={Left} />
                  {/* </Link> */}
                </div>
               <div style={{margin: 'auto'}} className='loab'>
                   <p className='ccaaat'>Refund and Return/Handover Policy</p>
               </div>
            </div>
        </div>
        <div className='pro_home_seven mt-3'>
        <p className='mt-4'><strong>BILLING AND SHIPPING POLICY</strong></p>
                <p  style={{fontSize :'14px' , fontWeight: '400' , marginTop : '20px'}}>
                RENTLA, accepts online payment, UPI or cash on delivery as well once the order is been processed, it will be shipped directly to your desired location as preferred by customer/lessee.<br /><br />
                RENTLA, features customers/lessee can assign/authorise anyone to collect your order and affix signatures as required by RENTLA on all equipments.<br /><br />
                Delivery orders made on RENTLA will be shipped out or dispatched within 24 Hours before delivery date required by customer/lessee.<br /><br />
                Customers/lessee can take delivery or return the product either by RENTLA or by themselves on all business working days (i.e., Monday to Saturday between 9 AM to 7 PM).
                </p>
              </div>
    </div>
  )
}
