import React , { useState , useEffect} from 'react'
import Header from '../../../common/head_foot/header_two'
import Footer from '../../../common/footer'
import Profile_home_nav from '../../../page/profile_home/profile_home_nav'
import Timer from '../../mobile_view_comp/otpp/timer'
import Qr_one from '../../../assets/img/qr_one.png'
import './Handover_req_web.css'
import axios from 'axios'
import { Base_Url , ImageUrl } from '../../globalvariable/globe'
import { useParams , useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { faL } from '@fortawesome/free-solid-svg-icons'

export default function Handover_req_web() {

    const [ all , setAll ] = useState()

    const [ catdetid , setCatdetid ] = useState([])

    const [ newid , setNewid ] = useState()

    let { id , type } = useParams()

    let navigate = useNavigate()

    let token = localStorage.getItem('user_data')

    const [ vali , setVali ] = useState(false)

    const Alldata = async() => {
        if(token === null){
            navigate('/')
            return
          }
        let responce = await axios({
            method : 'post' ,
            url : `${Base_Url}/viewHandover`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            },
            data : {
                orderId : id,
                type : type
            }
        })
        .then((res)=>{
            setNewid(res.data.Data.orderDetails.orderId)
            setAll(res.data.Data)
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    useEffect(()=>{
        Alldata()
    },[])

    const errnotify = (dat) => toast.error( dat , {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const clickeddd = async() =>{ 
        setVali(true)
        let arry = catdetid.join()

        if(arry === ''){
            setVali(false)
            // errnotify('Check Atleast One Field')
            return
        }else{
            let res = await axios({
                method : 'post',
                url : `${Base_Url}/selectOrderProductToHandover`,
                headers: { 
                    'Authorization': `Bearer ${token}` 
                },
                data : {
                    orderId : newid,
                    cartDetailId : arry
                }
            })
            .then((res)=>{
                if(res.data.status === true){
                    navigate('/handover_detail/'+all.orderDetails.orderId+'/'+arry+'/'+1  )
                }
            })
            .catch((err)=>{
                console.log(err)
            })
        }
    }

    const change = ( e , data ) =>{
        setVali(true)
        if(e.target.checked === true){
            if(catdetid.includes(data.CartDetailId)){
            // setCatdetid( catdetid.filter(item => item !== data.CartDetailId))
            }else{
                
                setCatdetid(catdetid.concat(data.CartDetailId))
            }
        }else{
            let arr = catdetid.filter(function(item) {
                setVali(false)
                return item !== data.CartDetailId
            })
            setCatdetid(arr)
        }        
    }

    const selectAll = (e) => {
        if(e.target.checked === true){
            let iddss = []
            all?.productDetails.map((data,key)=>{
                iddss = iddss.concat(data.CartDetailId)
               
            })
            setCatdetid(iddss)
        }else{
            let iddss = []
            setCatdetid(iddss)
        }
        
    }

  return (
    <>
        <Header />
        <div className='pro_home_one  mt-150 mb-150'>
          <div className='row' >
            
            <Profile_home_nav />

            <div className='col-8'>
            <div className='pro_home_fifteen'>
                <div className='d-flex'>
                  <p className='pro_home_eight'>handover Request</p>
                </div>
              </div>
              <div className='pro_home_seven mt-3'>
                <div className='run_ord_one'>
                  <div className='row'>
                      <div className='col-5'>
                          <Timer time={172} />
                      </div>
                      <div className='col-7'>
                        <p className='run_ord_two'>ID : {all?.orderDetails.orderId}</p>
                        <p className='run_ord_threec'>{all?.orderDetails.fromDate} - {all?.orderDetails.toDate}</p>
                      </div>
                  </div>
                </div>
                <div className='d-flex justify-content-between'>
                  <p className='run_ord_four pt-1'>Order Contains</p>
                  <div className='d-flex'>
                      {/* <input onChange={(e)=>selectAll(e)} className='ch_three mt-4' type='checkbox' />
                      <p className='run_ord_four pt-1'>Select all</p> */}
                  </div>
                </div>
                {all?.productDetails.map((dta,key)=>
                    <>
                        <div className='qr_seven mt-3'>
                            <div className='row'>
                                {
                                    dta.CartDetailProIsHandovered === 0 ?
                                    <div className='col-5'>
                                        <dic className='row'>
                                            <div className='col-3'>
                                                <input onChange={(e)=>change(e,dta)} 
                                                // checked={catdetid?.map((daa,key)=>
                                                //     {
                                                //         if(daa === dta.CartDetailId){
                                                //             return('')
                                                //         }else{
                                                //             return('')
                                                //         }
                                                //     }
                                                //     )}
                                                     className='ch_three mt-4' type='checkbox' />
                                            </div>
                                            <div className='col-9'>
                                                <p className='qr_eight mt-1'>No : {dta.CartDetailInventoryProSerialNo}</p>
                                                <p className='qr_ninemiss'>{dta.BrandName}</p>
                                            </div>
                                        </dic>
                                    </div>
                                    : dta.CartDetailProIsHandovered === 3 ?
                                    <div className='col-5'>
                                        <dic className='row'>
                                            <div className='col-3'>
                                                <input onChange={(e)=>change(e,dta)} className='ch_three mt-4' type='checkbox' />
                                            </div>
                                            <div className='col-9'>
                                                <p className='qr_eight mt-1'>No : {dta.CartDetailInventoryProSerialNo}</p>
                                                <p className='qr_ninemiss'>{dta.BrandName}</p>
                                            </div>
                                        </dic>
                                    </div>
                                    :
                                    <div className='col-5'>
                                        <p className='qr_eight mt-1'>No : {dta.CartDetailInventoryProSerialNo}</p>
                                        <p className='qr_ninemiss'>{dta.BrandName.substring(0, 40) }</p>
                                    </div>
                                }
                                <div className='col-3'>
                                    <img style={{width:'88px'}} className='mt-2' src={ImageUrl+'/'+dta.ProImgPrimary} />
                                </div>
                                <div className='col-4'>
                                    {
                                        dta.CartDetailProIsHandovered === 0 ?
                                            <div className= 'qr_tensss'>
                                                <p className='qr_elevens'>Handover</p>
                                            </div>
                                        : dta.CartDetailProIsHandovered === 1 ?
                                            <div  onClick={()=>{ navigate('/handover_detail/'+all.orderDetails.orderId+'/'+dta.CartDetailId+'/'+0 ) }} className= 'qr_tens'>
                                                <p className='qr_eleven'>Requested</p>
                                            </div>
                                        : dta.CartDetailProIsHandovered === 2 ?
                                            <div className= 'qr_ten'>
                                                <p className='qr_eleven'>Handovered</p>
                                            </div>
                                        :
                                            <div className= 'qr_tenss'>
                                                <p className='qr_eleven'>Damage</p>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                    )}
              </div>
                <div className='qr_threenew'>
                    <div className='row'>
                        <div className='col-7'>
                            <p className='qr_six'>{all?.orderDetails.remainingDays} Days Left</p>
                        </div>
                        <div className='col-5'>
                            {/* <Link to='/service_pick'> */}
                                <div style={{ cursor : 'pointer' }} onClick={()=>clickeddd()} className={vali === true ? 'qr_four' : 'qr_four_lite'}>
                                    <p className='qr_five'>Handover</p>
                                </div>
                            {/* </Link> */}
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <Footer />
    </>
  )
}
