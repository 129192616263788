import React , { useState , useEffect } from 'react' 
import Mobile_head from '../../../page/mobile/common/mobile_head'
import Mobile_foot from '../../../page/mobile/common/mobile_foot'
import { useNavigate , useParams } from 'react-router-dom'
import axios from 'axios'
import { Base_Url , ImageUrl } from '../../globalvariable/globe'
import hrrt from '../../../assets/img/hrrt.png'
import img12 from '../../../assets/img/Vector.png'
import Skeleton from 'react-loading-skeleton'
import { useSelector , useDispatch } from 'react-redux';
import { Wishcount } from '../../../redux/actions/productAction'

export default function Offerweb() {

    const [ all , setAll ] = useState()

    let wishss = localStorage.getItem('wish_list')
    const [ wish ,  setWish ] = useState()

    let navigate = useNavigate()
    let dispatch = useDispatch()

    let { id } = useParams()

    const skl = [1]

    const allData = async () =>{

        let res = await axios ({
            method : 'post' ,
            url : `${Base_Url}/viewOfferProducts`,
            data : {
                catId : id
            }
        })
        .then((res)=>{
            setAll(res.data.Data.products)
            console.log(res)
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    useEffect(()=>{
        wishss = localStorage.getItem('wish_list')
        setWish(JSON.parse(wishss))
        allData()
    },[])

    const [countss  , setCountss ]= useState(1)

    useEffect(()=>{
        wishss = localStorage.getItem('wish_list')
        setWish(JSON.parse(wishss))
    },[countss])

    const clicked = ( dat , key ) =>{
        navigate('/detail/'+ dat.ProName
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '')+'/' +dat.ProId)
    }

     const Clickheart = ( data , key ) => {
        setCountss(countss +1)
        let allata = localStorage.getItem('wish_list')
        let another = JSON.parse(allata)
        let final = 0

        let wish_data = [
            {
                datas : data
            }
        ]

        if(localStorage.getItem('wish_list')){
            another.forEach((element, index) => {
                if( element.datas.ProId === wish_data[0].datas.ProId){
                    let allanother = another
                    allanother.splice(index , 1)
                    localStorage.setItem('wish_list' , JSON.stringify(allanother))
                    dispatch(Wishcount(allanother))
                    final = 1
                }
            })
            console.log(final)
            if( final === 0 ){
                let allanother =  another.concat(wish_data)
                localStorage.setItem('wish_list' , JSON.stringify(allanother))
                dispatch(Wishcount(allanother))
            }

        }else{
            localStorage.setItem('wish_list' , JSON.stringify(wish_data))
            dispatch(Wishcount(wish_data))
        }
     }

  return (
    <>
        <div className='container' style={{ margin:'auto' , paddingTop:'70px' , overflowX:'hidden' , paddingBottom:'92px'}}>
            <Mobile_head />
            {all?.length === 0 ? <p style={{textAlign:'center',marginTop:'20px',fontWeight:'600'}}>No Data Found</p> : <p style={{ textAlign:'left' , fontWeight:'600' }}>Offers</p>  }
            {all?all.map((dat,key)=>
        <div>
            <div className='r1oo'>
                <div className='row' >
                    <div  onClick={()=>{clicked(dat,key)}} style={{margin:'auto'}} className='col-4 imcol1'>
                        <img className='imm' src={ImageUrl+'/'+dat.ProImgPrimary} />
                    </div>
                    <div className='col-8'>
                        <div className='row'>
                            <div  onClick={()=>{clicked(dat,key)}} className='col-8'>
                                <p className='paraone'>{dat.BrandName}</p>
                                <p className='paratwo'>{dat.ProName.substring(0, 30)}...</p>
                                <p className='parathree'>256/8 gb , 13.7” Retina Display</p>
                            </div>
                            <div className='col-4'>
                            {(() => {
                            if(wish != null){
                                console.log(typeof(wish),'wishhhh')
                            var result = wish.filter(x => x.datas.ProId == dat.ProId);

                        if(result.length > 0){
                            return ( <img onClick={()=>Clickheart(dat,key)} className="pop_icon" src={hrrt} />)
                            }else{
                                return ( <img onClick={()=>Clickheart(dat,key)} className="pop_icon" src={img12} />)
                            }
                        }
                        else{
                            return ( <img onClick={()=>Clickheart(dat,key)} className="pop_icon" src={img12} />)
                        }
                        })()}
                                {/* <img style={{width:'21px'}} src={(dat.ProWishlist===0 ?img12 : hrrt)} /> */}
                            </div>
                        </div>
                        <div  onClick={()=>{clicked(dat,key)}} className='row'>
                            <div className='col-6'>
                                <div className='btoone'>
                                    <p className='parafour'>₹ {dat.ProRentAmt}/M</p>
                                </div>
                            </div>
                            {/* <div className='col-6'>
                                <p className='proo_crt_onee'>50% Off</p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
    : 
    <>
                  <div className='row mt-2'>
                    <div className='col-3' style={{ height:'30px' , borderRadius:'10px'}} >
                      <Skeleton width='100%' height='100%' />
                    </div>
                    <div className='col-3' style={{ height:'30px' , borderRadius:'10px'}} >
                      <Skeleton width='100%' height='100%' />
                    </div>
                    <div className='col-3' style={{ height:'30px' , borderRadius:'10px'}} >
                      <Skeleton width='100%' height='100%' />
                    </div>
                    <div className='col-3' style={{ height:'30px' , borderRadius:'10px'}} >
                      <Skeleton width='100%' height='100%' />
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-6' style={{ height:'30px' , borderRadius:'10px'}} >
                      <Skeleton width='100%' height='100%' />
                    </div>
                    <div className='col-6' style={{ height:'30px' , borderRadius:'10px'}} >
                      <Skeleton width='100%' height='100%' />
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-12' style={{ height:'90px' , borderRadius:'10px'}} >
                      <Skeleton width='100%' height='100%' />
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-12' style={{ height:'90px' , borderRadius:'10px'}} >
                      <Skeleton width='100%' height='100%' />
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-12' style={{ height:'90px' , borderRadius:'10px'}} >
                      <Skeleton width='100%' height='100%' />
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-12' style={{ height:'90px' , borderRadius:'10px'}} >
                      <Skeleton width='100%' height='100%' />
                    </div>
                  </div>
                </>
    // <p style={{textAlign:'center',marginTop:'20px',fontWeight:'600'}}>No Data Found</p>
    
    
    }
            <Mobile_foot />
        </div>
    </>
  )
}
