import React, { useEffect, useState } from 'react';
import Header from '../../../common/head_foot/header_two';
import Footer from '../../../common/footer';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Base_Url, ImageUrl } from '../../globalvariable/globe';

const Blog_web = () => {
  const [blogList, setBlogList] = useState([]);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.post(`${Base_Url}/customer_blog`);
        if (response.data.status && response.data.response_code === 1) {
          setBlogList(response.data.Data.blog);
        } else {
          console.error('Error fetching blog data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    };
    fetchBlogData();
  }, []);

  const handleBlogClick = (blogId) => {
    localStorage.setItem('blogId', blogId);
  };

  return (
    <>
      <Header />
      <div className='mt-150'>
        <section id='' className=''>
          <div className='container mt-15' data-aos='fade-up'>
            <div className='row g-5'>
              <div className='col-lg-12'>
                <div className='row g-5'>
                  {Array.isArray(blogList) &&
                    blogList.map((blog) => (
                      <div className='col-lg-4 border-start custom-border' key={blog.BlogId}>
                        <div className='mb-3'>
                          <div className='mb-3'>
                            <div>
                              <span className='lapspec fw-bold py-3' style={{ fontSize: "18px" }}>{blog.BlogTitle}</span>
                            </div>
                            <a href=''>
                              <img src={ImageUrl + '/' + blog.image} className='my-3' alt='Blog' />
                            </a>

                            <p className='py-2'>
                              {blog.BlogDescription.substring(0, 150)}
                              <span>......</span>
                            </p>

                            <Link
                              to={`/blog/${blog.BlogId}`}
                              className='btn btn-2 text-white'
                              onClick={() => handleBlogClick(blog.BlogId)}
                            >
                              Learn More
                            </Link>

                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Blog_web;