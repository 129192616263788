import React from 'react'
import Home_kyc_three_comp from '../components/fullwidth/home_kyc/home_kyc_three_comp'
import Header_two from '../common/head_foot/header_two'
import Footer from '../common/footer'

export default function Home_kyc_three() {
  return (
    <>
        <Header_two />
        <Home_kyc_three_comp />
        <Footer />
    </>
  )
}
