import React, { useEffect, useState } from "react";
import '../App.css';
import Header from "../common/head_foot/header";
import Slider from "../slider/slider";
import Threeimage from "../components/threeimage";
import Product from "../components/product";
import ProductDetail from "../components/productDetail";
import Footer from "../common/footer";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { setProducts } from "../redux/actions/productAction";
import { Base_Url } from "../components/globalvariable/globe";
import Web_offers from "../components/web_offers/web_offers";
import Fun_facts from "../components/fun_facts";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

function Homes() {

  const [res, setRes] = useState()
  const [url, setUrl] = useState('')
  const [cat, setcat] = useState()
  const [homeCard, setHomeCard] = useState([])
  const [homeCardnew, setHomeCardnew] = useState([])
  const dispatch = useDispatch()
  const bodyParameters = {
    key: "value"
  };

  const { id } = useParams()
  // const item = seodata[id];
  const [item, setItem] = useState({})
  console.log(item, "itt")

  if (item.seotitle == null) {
    var titles = 'Best IT Product on Rent Services';

  }
  if (item.seodescription == null) {

    var description = 'Best IT Product on Rent Services';

  }
  if (item.seokeywords == null) {


    var keywords = 'Best IT Product on Rent Services';
  } else {
    var titles = item.seotitle;
    var description = item.seodescription;
    var keywords = item.seokeywords;
  }


  const getSeoItem = async () => {

    await axios.post(`${Base_Url}/home_page_seo`)
      .then((res) => {
        console.log(res.data, "ress")
        setItem(res.data.homeseo)
      }).catch((err) => {
        console.log("error", err)
      })
  }
  const fectch_data = async () => {
    const response = await axios.post(`${Base_Url}/homePageWeb`, bodyParameters)
      .catch((err) => {
        console.log(err)
      })
    dispatch(setProducts(response.data.Data))
    setRes(response.data.Data.banner)
    setUrl(response.data.Data.awsimageurl)
    setcat(response.data.Data.dropDown)
    setHomeCard(response.data.Data.home)
    setHomeCardnew(response.data.Data.homedescription)
  }

  useEffect(() => {
    fectch_data()
    getSeoItem()
  }, [])


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{titles}</title>
        <meta name="description" content={description} data-react-helmet="true" />
        <meta name="keywords" content={keywords} data-react-helmet="true" />
      </Helmet>
      <Header />
      <Slider data={res} urls={url} />
      <Threeimage data={cat} urls={url} />
      <ProductDetail />
      <Web_offers />
      <Fun_facts />
      {/* <Product homeCard={homeCard}/> */}
      <Product homeCard={homeCardnew} />
      {/* <Product /> */}
      <Footer />
    </>
  );
}

export default Homes;