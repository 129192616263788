import React , { useState , useEffect } from 'react'
import twins from '../../../assets/img/twins.png'
import boox from '../../../assets/img/boox.png'

export default function Mob_notification_comp(props) {


  const [ data , setData ] = useState(props.datas)


  useEffect(()=>{
    setData(props.datas)
  },[props])

//   const data = [
//     {
//       "status" : "Laptops & Workstation",
//       "img" : twins,
//       "des" : "Your package ID : #518515 has been Delivered !",
//       "time" : "2h ago"
//   },
//   {
//     "status" : "Waiting for verification",
//     "des" : "Your package ID : #518515 has been Delivered !",
//     "time" : "2h ago"
//   },
//   {
//     "status" : "Order Delivered!",
//     "des" : "Your package ID : #518515 has been Delivered !",
//     "time" : "2h ago"
//   },
//   {
//     "status" : "Order Delivered!",
//     "des" : "Your package ID : #518515 has been Delivered !",
//     "time" : "2h ago"
//   },
//   {
//     "status" : "Order Delivered!",
//     "des" : "Your package ID : #518515 has been Delivered !",
//     "time" : "2h ago"
//   },
//   {
//     "status" : "Order Pickefd up!",
//     "img" : twins,
//     "des" : "Your package ID : #518515 has been Delivered !",
//     "time" : "2h ago"
//   }
// ]

  return (
    <>
    {data?.map((dat,key)=>
      <div className='noti_one mt-4'>
        <div className='row'>
          <div className='col-8'>
            <p className='noti_two'>{dat.tbnotifytitle}</p>
            <p className='noti_three'>{dat.tbnotifymessage}</p>
            {/* <p>{dat.tbnotifytime}</p> */}
          </div>
        <div className='col-4'>
           <img className='noti_four' src={boox} />
        </div>
      </div>
    </div>
    )}

      {
        data?.length === 0 || data=== '' || data === undefined  ?
            <p style={{textAlign:'center' , marginTop:'20px',fontWeight:'600'}}>Notification is empty</p> :''
          
      }
    </>
  )
}
