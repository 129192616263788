import React , { useEffect, useState } from 'react'
import OTPInput from "otp-input-react";
import aright from '../../../assets/img/aright.png'
import otp from '../../../assets/img/otp.png'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate  , useParams ,  Link } from 'react-router-dom';
import { Base_Url } from '../../globalvariable/globe';
import clkk from '../../../assets/img/clkk.png'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter, 
  ModalBody,
  useDisclosure,
  Button,
  ChakraProvider
} from '@chakra-ui/react'
import x from '../../../assets/img/x.png'

export default function Mbl_oyp() {

  let { no } = useParams()

  let navigate = useNavigate()

  const [ timerss , setTimer ] = useState(true)
  const [ timerCont , setTimerCount ] = useState(60)

  const errnotify = () => toast.error( 'Invalid Otp' , {
    position: "top-right",
    autoClose: 1000, 
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });

    const { isOpen, onOpen, onClose } = useDisclosure()

     const succnotify = (data) => toast.success(data, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });


    const errnotifyss = (data) => toast.error( data , {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });

    const [OTP, setOTP] = useState('11111');

    const [ ph , setPh ] = useState('')

    useEffect(()=>{
      setTimer(true)
      timer(60)
      setPh(sessionStorage.getItem('phone'))
    },[])

    const otpSend = async () => {
      let response = await axios({
        method : 'post',
        url : `${Base_Url}/loginCheck`,
        data : {
          phoneNumber : no ,
          otp : OTP
        }
      })
      .then((res)=>{
        if(res.data.status === true){
          localStorage.setItem('user_data', res.data.Data.token)
          localStorage.setItem('user_id', res.data.Data.userId)
          onOpen()
        }else {
          errnotify()
        }
      })
    }

    const resendotp = async ()=>{
      const response = await axios({
        method:'post',
        url : `${Base_Url}/login`,
        data:{
            phoneNumber : no
        }
    })
    .then((res)=>{
        if(res.data.status === true){
            // setOTP(res.data.Data.otp)
            setOTP('11111')
            succnotify('Otp send Successfully')
            setTimerCount(60)
            setTimer(true)
            timer(60)
            // sessionStorage.setItem('phone',res.data.Data.phoneNumber)
            // navigate('/otp/'+phone)
        }else{
            // navigate('/login')
            errnotifyss('Something Went Wrong')
            // errnotify()
        }
    })
    .catch((err)=>{
        console.log(err)
    })
  }


function timer(remaining) {
  remaining -= 1;
  
  if(remaining >= 0) {
    setTimeout(function() {
        timer(remaining);
        setTimerCount(remaining)
    }, 1000);
   
  }
  if( remaining === 0 ) {
    setTimer(false)
  } 
 
  
}




  return (
    <>
      <div>
        <img className='otp_img' src={otp} />
          <p className='ti_p_one'>Thank you for registering, Please type the OTP as shared on your mobile  { ph } </p>
          {/* <p className='ti_p_two'>Resend OTP?</p> */}
          <div className='timerr'>
            <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={5} className='hiiiiiii' otpType="String" disabled={false}  />
          </div>
            <div onClick={()=>otpSend()} className='mob_login_container_five mt-4'>
                <div className='d-flex justify-content-center'>
                    <p className='ti_p_four'>Verify</p>
                    <img className='ti_p_five' src={aright} />
                </div>
            </div>
            {
              timerss === true ? 
              <div  style={{cursor : 'pointer'}} className='d-flex justify-content-center my-3'>
                <p>Resend Otp In : </p>
                <p style={{ marginLeft:'10px' , color:'#F4B644' }}>{timerCont} s</p>
              </div>
              :
              <div onClick={()=>resendotp()} style={{cursor : 'pointer'}} className='d-flex justify-content-center my-3'>
                <p>OTP not recieved?</p>
                <p style={{ marginLeft:'10px' , color:'#F4B644' }}>Resend OTP</p>
              </div>
            }
      </div>
      
      <ChakraProvider>

        <Modal isCentered={true} isOpen={isOpen} size={'xs'} onClose={onClose}>

          <ModalOverlay />
            <ModalContent>
                <>
                <ModalBody>
                  <div className='d-flex justify-content-end' >
                    <Link to='/' >
                      <img  style={{ width: 20 , height : 20 }} src={x} />
                    </Link>
                  </div>
                  {/* <p style={{paddingTop:'10px'}} className='pay_succ'>We will get back to you soon !!</p> */}
                  <p style={{paddingTop:'10px'}} className='pay_succ'>Account Created Successfully</p>
                  <img style={{margin:'auto' , paddingTop:'10px'}} src={clkk} />
                </ModalBody>
    
                <ModalFooter>
                  <Link to='/profile' >
                    <Button bg={'#161E40'} color={'white'} mr={3} >
                      Go to Profile Page
                    </Button>
                  </Link>
                </ModalFooter>
                </>

            </ModalContent>

        </Modal>

      </ChakraProvider>
      <ToastContainer />
    </>
  )
}