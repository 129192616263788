import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector , useDispatch } from "react-redux";
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom';
import ggoo from '../../assets/img/get_playstore.png'

export default function Mbl_product_groub() {

  let navigate = useNavigate()
  let loadPages = [ 1 , 2 , 3 , 4 , 5 , 6 ];
  
  const product =  useSelector((state)=>state)
  const datas = product.allProducts.products.category
  const uels = product.allProducts.products.awsimageurl
  console.log('dfre',datas)

  const clikk = (datas) =>{
    navigate('/products/'+datas.CatName+'/'+datas.CatId +'/'+'All'+'/'+0)
  }

  return (
    <>
   
      <div className="row pra_groub">
          {datas?.map((datas)=>
            <div 
              onClick={()=>clikk(datas)} 
              className='col-4' >
                  <div style={{height:'100px'}} >
                      <img className='imageee' src={uels+'/'+datas.CatImage} />
                  </div>
                  <p style={{ position : 'relative' , zIndex : 10 }} className='ppp'>{datas.CatName}</p>
            </div>
          )}
          {
            !datas && loadPages.map(()=>{
              return(
                <div className='col-4 mt-1' >
                  <Skeleton width='100%' height='80px' />
                </div>
              )
            })
          }
      </div>
    </>
  )
}