import React , { useState , useEffect } from 'react'
import circle1 from '../../../assets/img/circle1.png'
import icon from '../../../assets/img/ico1.png'
import aright from '../../../assets/img/aright.png'
import google from '../../../assets/img/google.png'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from 'react-loading';
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Base_Url } from '../../globalvariable/globe'

export default function Mob_login_two() {

    const [ phone , setPhone ] = useState('')

    const [ loading , setLoading ] = useState(false)

    const [ errmsg , setErrMsg ] = useState('')

    const navigate = useNavigate()

    const [ otp , setOtp ] = useState()

    // const succnotify = () => toast.success('Product added Successfully', {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     });

const errnotify = () => toast.error( errmsg , {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

    const clicked = async() =>{

        let phonevali = /^\d{10}$/;

        if(phonevali.test(phone)){

        }else{
            setErrMsg('Enter Valid PhoneNo')
            // errnotify()
            return
        }

        setLoading(true)

        const response = await axios({
            method:'post',
            url : `${Base_Url}/login`,
            data:{
                phoneNumber : phone
            }
        })
        .then((res)=>{
            if(res.data.status === true){
                setOtp(res.data.Data.otp)
                sessionStorage.setItem('phone',res.data.Data.phoneNumber)
                navigate('/otp/'+phone)
            }else{
                setErrMsg('User Not Registered')
                // errnotify()
                
                setTimeout(()=>{
                    navigate('/reg')
                },3000)
                
            }
            setLoading(false)
        })
        .catch((err)=>{
            setLoading(false)
            setErrMsg('Something Went Wrong Try Again')
            // errnotify()
            console.log(err)
        })
        setLoading(false)
    }

    let phonevalue = (e)=>{
        setPhone(e.target.value.replace(/[^0-9]/g, "").slice(0, 10))
    }
    useEffect(()=>{
        if(errmsg!=''){
          errnotify()
          setTimeout(()=>{
            setErrMsg('')
          },2000)
        }
    },[errmsg])

  return (
    <>
      <div>
          <div>
                <img className='login_two_img1' src={circle1} />
                <div className='login_tow_containner_one'>
                    <img className='login_two_img2' src={icon} />
                    <p className='login_two_img3'>Welcome to <span className='log_two_span'>Rentla</span> !!</p>
                    <p className='login_two_img4'>Phone Number</p>
                    <div className='lo_tw_one'>
                        <div className='d-flex'>
                            <div >
                                <p className='lo_tw_two'>+91</p>
                            </div>
                            <div>
                                <input onChange={(e)=>phonevalue(e)} value={phone}  class="form-control lo_tw_three" id="exampleInputEmail1" aria-describedby="emailHelp"
                                // placeholder="99945 99945"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <Link to='/reg' >
                                <p className='lo_tw_foue'>Register Account</p>
                            </Link>
                        </div>
                        <div className='col-6'>
                            <div className='lo_tw_five'>
                                {
                                    loading ? 
                                    <div className='loas_login'>
                                        <ReactLoading type='balls' color='#ffffff' /> 
                                    </div>
                                :
                                    <div onClick={()=>clicked()} className='row'>
                                        <div className='col-6'>
                                            <p className='lo_tw_six'>Next</p>
                                        </div>
                                        <div className='col-6'>
                                            <img className='lo_tw_seven' src={aright} />
                                        </div> 
                                    </div>
                                }
                                
                            </div>
                        </div>
                    </div>
                    {/* <div style={{marginTop:'30px'}} className='row'>
                        <div className='col-5'>
                            <hr />
                        </div>
                        <div className='col-2'>
                            <p className='lo_tw_eight'>OR</p>
                        </div>
                        <div className='col-5'>
                            <hr />
                        </div>
                    </div>
                    <div className='lo_tw_nine'>
                        <div className='d-flex justify-content-center '>
                            <img className='lo_tw_ten mt-3' src={google} />
                            <p className='lo_tw_elevan'>Log In with Google</p>
                        </div>
                    </div> */}
                    
                        <p className='lo_tw_twelve mt-4'>view and agree to the rentla’s <span style={{ fontWeight : '600' }} ><Link to='/useragreement'> User Agreement </Link></span> and <span style={{ fontWeight : '600' }} ><Link to='/privacy_policy'>Privacy policy</Link></span></p>
                   
                </div>
                <img className='login_two_img233' src={circle1} />
          </div>
      </div>
        <ToastContainer />
    </>
  )
}

{/* <ReactLoading type='balls' color='#ffffff' /> */}