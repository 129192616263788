import React from 'react'
import Mob_login_three from '../../components/mobile_view_comp/mbl_login_three/mob_login_three'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'

export default function Login_three() {
  return (
    <>
        <div style={{width:'90%' , margin:'auto' ,marginTop:'65px'}}>
            <Cart_one_head link='/corporate_login' head='Create Accont' />
            <Mob_login_three />
        </div>
    </>
  )
}