import React from 'react'
import Mob_pay_rep_comp from '../../components/mobile_view_comp/mob_payment_report/mob_pay_rep_comp'
import Cart_one_head from '../../components/mobile_view_comp/cart_one/cart_one_head'

export default function Mob_payment_report() {
  return (
    <>
        <div style={{width:'90%' , margin:'auto' , paddingBottom:'100px' , paddingTop:'65px'}}>
            <Cart_one_head link='/profile' head='Payment Report' />
            <Mob_pay_rep_comp />
        </div>
    </>
  )
}
