import React from 'react'

export default function Arrow_five() {
  return (
    <>
     <div className='row'>
        <div className='col-3'>
            <div className='boc1112'>
                <p className='po'>Cart</p>
            </div>
        </div>
        <div className='col-3'>
            <div className='boc11123'>
                <p className='po'>Kyc</p>
            </div>
        </div>
        <div className='col-3'>
            <div className='boc111'>
                <p className='po2'>Approval</p>
            </div>
        </div>
        <div className='col-3'>
            <div className='boc1113'>
                <p className='po4'>Payment</p>
            </div>
        </div>
      </div>
    </>
  )
}