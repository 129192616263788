import React from 'react'
import { Link } from 'react-router-dom'

export default function Ser_foot() {
  return (
    <>
      <div className='qr_fifteen'>
        <Link to='/code_veri'>
          <div className='qr_sixteen'>
              <p className='qr_sixteens'>Handover</p>
          </div>
        </Link>
      </div>
    </>
  )
}