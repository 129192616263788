import React from 'react'
import Order_home_comp from './order_home/order_home_comp'
import Header_two from '../common/head_foot/header_two'
import Footer from '../common/footer'

export default function Order_home() {
  return (
    <>
        <Header_two />
        <Order_home_comp />
        <Footer />
    </>
  )
}
