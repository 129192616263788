import React from 'react'
import Mob_login_one from '../../components/mobile_view_comp/mob_login/mob_login_one'
import Mobile_foot from './common/mobile_foot'
import Mob_login_head from '../../components/mobile_view_comp/mob_login/mob_login_head'

export default function Mob_login() {
  return (
    <>
      <div style={{width:'90%' , margin:'auto' ,marginTop:'65px'}}>
        <Mob_login_head />
        <Mob_login_one />
        <Mobile_foot />
      </div>
    </>
  )
}