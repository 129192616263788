import React , { useState , useEffect } from 'react'
import Mob_pro_slider from '../../components/mobile_view_comp/mobile_pro_det/mob_pro_slider'
import Mob_nor_head from './common/mob_nor_head'
import '../../components/mobile_view_comp/mobile_pro_det/mob_pro.css'
import Mob_pro_data from '../../components/mobile_view_comp/mobile_pro_det/mob_pro_data'
import { useSelector , useDispatch} from 'react-redux'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { oneProduct } from '../../redux/actions/productAction'
import { Base_Url } from '../../components/globalvariable/globe'

export default function Mobile_product_detail() {

  const dispatch = useDispatch()

  const product =  useSelector((state)=>state.oneproduct)
  const imgs = product.awsbaseurl

  const {id} = useParams()
  
  const bodyParameters = {
    key: "value"
  }; 

  const clikk = async () =>{
    let responce = await axios({
      method: 'post',
      url: `${Base_Url}/selectProduct`,
      bodyParameters, 
      data :{
        proId : id
      }
    })
    dispatch(oneProduct(responce.data.Data))
    
  }

  useEffect(()=>{
    clikk()
  },[])

  return (
    <>
    <div style={{paddingTop:'65px' , backgroundColor : 'rgb(244, 244, 244)'}}>
      <Mob_nor_head link="/products/laptop/3" />
      {product.productImage ? <Mob_pro_slider imggs={imgs} data={product.productImage} /> :''}
    </div>
    <div style={{width:'90%' , margin:'auto' , paddingBottom:'100px' , paddingTop:'15px'}}>
      <Mob_pro_data  /> 
    </div>
    </>
  )
}