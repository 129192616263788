import React , { useState , useEffect }  from 'react'
import { useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import Header_two from '../../common/head_foot/header_two';
import Footer from '../../common/footer';
import Tr from '../../components/mobile_view_comp/track/tr';
import { Link, useNavigate ,useParams } from 'react-router-dom';
import Profile_home_nav from '../profile_home/profile_home_nav';
import axios from 'axios';
import { Base_Url } from '../../components/globalvariable/globe';
import { ImageUrl } from '../../components/globalvariable/globe';
import { LoogIn } from '../../redux/actions/productAction';

export default function Pending_order() {

    let navigate = useNavigate()

    const {id} = useParams()

    let token = localStorage.getItem('user_data')

    const [ alldata , setAlldata ] = useState()

    let dispatch = useDispatch();

    const errnotify = (dat) => toast.error( dat , {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const All = async () =>{
        if(token === null){
            navigate('/')
            return
        }
        let responce = await axios ({
            method : 'post',
            url : `${Base_Url}/viewPendingOrders`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            },
            data : {
                orderId : id
            }
        })
        .then((res)=>{
            setAlldata(res.data.Data)
            console.log(res.data.Data)
        })
        .catch((err)=>{
        if(err.response.status === 401){
            dispatch(LoogIn({show : true}))
            // navigate('/')
            }
            console.log(err)
        })
    }

    useEffect(()=>{
        All()
    },[])

    const next_page = async () =>{
        let response = await axios({

        })
    }

    return (
        <>
        <Header_two />

        <div className='pro_home_one mt-150'>
            <div className='row' >

            <Profile_home_nav />
            <div className='col-8'>
                <div className='pro_home_seven'>

                <div className=''>
                                <div className='my-3'>
                                        <p className='web_cart_col_two'>Pending Orders</p>
                                </div>

                                {
                                    alldata?.productDetails.map((data , key)=>{
                                        return(
                                            <>
                                                <hr />

                                                <div className='row '>
                                                    <div className='col-6 mt-1'>
                                                        <p className='web_cart_col_three' >{data.BrandName}</p>
                                                        <p className='web_cart_col_four' >{data.ProName}</p>
                                                        <p className='web_cart_col_four'>Qty : {data.CartProQty}</p>
                                                    </div>
                                                    <div className='col-6 mt-2'>
                                                        <img style={{ height :  115 }} className='web_pending_one' src={ImageUrl+'/'+data.ProImgPrimary} />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div> 


                            <div className=''  >
                                <p className='web_cart_col_two mt-3' >Details</p>
                                <hr />
                                <div className='row mb-3'>
                                    <div className='col-8'>
                                        <p className='web_cart_col_eleven' >Order Id</p>
                                        <p className='web_cart_col_eleven' >Booking Period</p>
                                        {/* <p className='web_cart_col_eleven' >Payment Type</p> */}
                                    </div>
                                    <div className='col-4'>
                                        <p className='web_cart_col_eleven' style={{textAlign:'right'}} >{alldata?alldata.orderDetails.orderAutoGenId : 'Loading...'}</p>
                                        <p className='web_cart_col_eleven'  style={{textAlign:'right'}} >{alldata?alldata.orderDetails.fromDate + ' - ' + alldata.orderDetails.toDate : 'Loading...'}</p>
                                        {/* <p className='web_cart_col_eleven'   style={{textAlign:'right'}} >Cash on Delivery</p> */}
                                    </div> 
                                </div>
                                <hr />

                                        <p className='web_cart_col_two mt-4 mb-3'>Deliver To</p>
                                  
                                
                                <div className='web_pending_ord '>
                                    <p className='web_cart_col_four' >{alldata?.deliverTo?.UserDelivAddrFName } {alldata?.deliverTo?.UserDelivAddrLName }</p>
                                    <p className='web_cart_col_three' >{alldata?.deliverTo?.UserDelivAddrAddress+', '+ alldata?.deliverTo?.UserDelivAddrAppart +', '+alldata?.deliverTo?.UserDelivAddrCity + ', '+ alldata?.deliverTo?.UserDelivAddrState + ', ' + alldata?.deliverTo?.UserDelivAddrPost+'. '}</p>
                                </div>

                                <div className='mt-3'>
                                        <p className='web_cart_col_two'>Status</p>
                                </div>
                                <Tr data={alldata?alldata.status : 0} />
                                    <div className='d-flex justify-content-between mt-3'>
                                        <div >
                                            <p className='pppp_one'>Rent</p>
                                            <p className='pppp_two'>₹ {alldata?.rentPerMonth}</p>
                                        </div>
                                            <div className='web_cart_col_twenty_one mt-3'>
                                                <Link to={'/checklist/'+alldata?.orderDetails.orderAutoGenId}>
                                                    <p className='web_cart_col_twenty_two' >Verify</p>
                                                </Link>
                                            </div>
                                    </div>
                            </div>

                </div>
            </div> 
            {/* <div style={{marginTop:'150px'}} >
                <div className='container'>
                    <div className='row'>

                        <div  className='col-7'>
                            
                        </div>

                        <div className='col-5'>
                            
                            
                        </div>
                    </div>
                </div>
            </div> */}
            
            <div style={{marginTop:'100px'}}>
            </div>
            <ToastContainer />
        

            </div>
        </div>
        <Footer />
        </>
    )
}

