import React from 'react'
import Header_two from '../common/head_foot/header_two'
import Cart_detail from '../components/cart_detail'


export default function Cart_page() {

  


  return (
    <>
        <Header_two />
        <Cart_detail />
    </>
  )
}