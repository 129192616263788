import React , { useState , useEffect } from 'react'
import Timer from '../otpp/timer'
import { Link , useParams } from 'react-router-dom'
import axios from 'axios'
import { Base_Url , ImageUrl } from '../../globalvariable/globe'
import { useNavigate } from 'react-router-dom'

export default function Ser_req_comp() {
 
  let navigate = useNavigate()

  let { id , type } = useParams()

  let token = localStorage.getItem('user_data')

  const [ alldata , setAlldata ] = useState()

  const start = async()=>{
      if(token === null){
        navigate('/')
        return
      }
      let responce = await axios({
          method : 'post',
          url : `${Base_Url}/viewService`,
          headers: { 
              'Authorization': `Bearer ${token}` 
          },
          data : {
              orderId : id,
              type : type
          }
      })
      .then((res)=>{
          setAlldata(res.data.Data)
          console.log(res)
      })
      .then((err)=>{
        if(err.response.status === 401){
          navigate('/login')
        }
          console.log(err)
      })
  }

  useEffect(()=>{
      start()
  },[]) 

  return (
    <>
      <div className='run_ord_one'>
        <div className='row'>
            <div className='col-5'>
                <Timer time={172} />
            </div>
            <div className='col-7'>
              <p className='run_ord_two'>ID : {alldata?.orderDetails.orderId}</p>
              <p className='run_ord_three'>{alldata?.orderDetails.fromDate} - {alldata?.orderDetails.toDate}</p>
            </div>
        </div>
      </div>
      <p className='service_one my-3'>Order Contains</p>
      { alldata?.productDetails.map((datas,key)=>
        <div className='service_two mt-3'>
          <div className='row' >
            <div className='col-5'>
            {
                datas?.CartDetailInventoryProSerialNo === null ? '' : <p className='service_threeb'>No : {datas.CartDetailInventoryProSerialNo === null ? '' :datas.CartDetailInventoryProSerialNo }</p>
            }
              <p className='service_four'>{datas.ProName === null ? '' : datas.ProName.substring(0,40) }</p>
            </div>
            <div className='col-3'>
              <img className='service_five' style={{width : '50px'}} src={ ImageUrl +'/'+ datas.ProImgPrimary } />
            </div>
            <div className='col-4'>
              <Link to={(datas.RequestedForService=== 0 ? '/service_status/'+datas.CartDetailId+'/'+id+'/'+1 : datas.RequestedForService=== 2 ? '/serviced/'+datas.CartDetailId+'/'+id+'/'+ datas.CartDetailInventoryProSerialNo :
              datas.RequestedForService=== 1 ? '/service_status/'+datas.CartDetailId+'/'+id+'/'+0  : '')}  >
                <div className={(datas.RequestedForService=== 0 ? 'service_six' : datas.RequestedForService=== 1 ? 'service_sixs' : 'service_sixsgreen')} > 
                  <p className={(datas.RequestedForService===0 ? 'service_seven' : 'service_sevens')}>{(datas.RequestedForService=== 0 ? 'Service' : datas.RequestedForService=== 1 ? 'Requested' : 'Serviced')}</p>
                </div>
               </Link>
            </div>
          </div>
        </div>
      )}
    </>
  )
}