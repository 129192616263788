import axios from 'axios'
import React , { useState , useEffect } from 'react'
import Profile_home_nav from '../../../page/profile_home/profile_home_nav'
import { Base_Url } from '../../globalvariable/globe'
import { useDispatch } from 'react-redux'
import { LoogIn } from '../../../redux/actions/productAction'
import { useNavigate } from 'react-router-dom'

export default function Home_bank_detail_comp() {

  let navigate = useNavigate()

  let user = localStorage.getItem('user_data')

  let dispatch = useDispatch()

  const [ name , setName ] = useState('')
  const [ accno , setAccno ] = useState('')
  const [ confiemaccno , setCofirmaccno ] = useState('')
  const [ ifsc , setIfsc ] = useState('')
  const [ upi , setUpi ] = useState('')

  const [ id , setId ] = useState()

  const [ nameerr , setNameerr ] = useState(false)
  const [ accnoerr , setAccnoerr ] = useState(false)
  const [ confiemaccnoerr , setCofirmaccnoerr ] = useState(false)
  const [ ifscerr , setIfscerr ] = useState(false)
  const [ upierr , setUpierr ] = useState(false)

  const [ all , setAll ] = useState(false)

  const [ sus , setSus ] = useState(false)

  const [ updata , setUpdate ] = useState(false)

  const [ allerr , setAllerr ] = useState('Something Went Wrong')

  const start = async () =>{
    if(user === null){
      navigate('/')
      return
    }
    let response = await axios({
      method : 'post',
      url : `${Base_Url}/viewBankAccount`,
      headers: { 
        'Authorization': `Bearer ${user}` 
      }
    })
    .then((res)=>{
      if(res.data.status === true){
        setUpdate(true)
        setName(res.data.Data.accountDetails.UserBankAccountHolderName)
        setAccno(res.data.Data.accountDetails.UserBankAccountNumber)
        setCofirmaccno(res.data.Data.accountDetails.UserBankAccountNumber)
        setIfsc(res.data.Data.accountDetails.UserBankIFSCCode)
        setUpi(res.data.Data.accountDetails.UserBankUPIId)
        setId(res.data.Data.accountDetails.UserBankId)
      }else{
        setUpdate(false)
      }
    })
    .catch((err)=>{
      if(err.response.status === 401){
        dispatch(LoogIn({show : true}))
        // navigate('/')
      }
      setUpdate(false)
      console.log(err)
    })
  }

  useEffect(()=>{
    start()
  },[])

  let submit = async()=>{
    setSus(false)
    setAll(false)
    if( name === '' ){
      setNameerr(true)
    }else{
      setNameerr(false)
    }
    if(accno === ''){
      setAccnoerr(true)
    }else if(accno.length <=8){
      setAccnoerr(true)
    }else{
      setAccnoerr(false)
    }
    if(confiemaccno === ''){
      setCofirmaccnoerr(true)
    }else{
      setCofirmaccnoerr(false)
    }
    if(ifsc===''){
      setIfscerr(true)
    }else{
      setIfscerr(false)
    }
    if( upi === ''){
      setUpierr(true)
    }else{
      setUpierr(false)
    }
    if (accno === confiemaccno){
      setAll(false)
      setCofirmaccnoerr(false)
    }else{
      setAllerr('Confirm Account No Mismatch')
      setAll(true)
      setCofirmaccnoerr(true)
      return
      
    }
    if ( name === '' || accno === '' || confiemaccno === '' || ifsc==='' || upi === '' ){
      return
    }else{
      let response = await axios({
        method : 'post',
        url : `${Base_Url}/addBankAccount`,
        headers: { 
          'Authorization': `Bearer ${user}` 
        },
        data : {
          accountHolderName : name,
          accountNumber : accno,
          ifscCode : ifsc ,
          upiId : upi
        }
      })
      .then((res)=>{
        setSus(true)
        start()
      })
      .catch((err)=>{
        setAllerr('Somthing Went Wrong')
        setAll(true)
        if(err.response.status === 401){
          dispatch(LoogIn({show : true}))
          // navigate('/')
        }
        console.log(err)
      })
    }
  }

  let update  = async()=>{
    setSus(false)
    setAll(false)
    if( name === '' ){
      setNameerr(true)
    }else{
      setNameerr(false)
    }
    if(accno === ''){
      setAccnoerr(true)
    }else{
      setAccnoerr(false)
    }
    if(confiemaccno === ''){
      setCofirmaccnoerr(true)
    }else{
      setCofirmaccnoerr(false)
    }
    if(ifsc===''){
      setIfscerr(true)
    }else{
      setIfscerr(false)
    }
    if( upi === ''){
      setUpierr(true)
    }else{
      setUpierr(false)
    }
    if (accno === confiemaccno){
      setAll(false)
      setCofirmaccnoerr(false)
    }else{
      setAllerr('Confirm Account No Mismatch')
      setAll(true)
      setCofirmaccnoerr(true)
      return
    }
    if ( name === '' || accno === '' || confiemaccno === '' || ifsc==='' || upi === '' ){
      return
    }else{
      let response = await axios({
        method : 'post',
        url : `${Base_Url}/editBankAccount`,
        headers: { 
          'Authorization': `Bearer ${user}` 
        },
        data : {
          userBankId : id,
          accountHolderName : name,
          accountNumber : accno,
          ifscCode : ifsc ,
          upiId : upi
        }
      })
      .then((res)=>{
        setSus(true)
        start()
      })
      .catch((err)=>{
        if(err.response.status === 401){
          dispatch(LoogIn({show : true}))
          // navigate('/')
        }
        setAllerr('Somthing Went Wrong')
        setAll(true)
        console.log(err)
      })
    }
  }
    
return (
  <>
      <div className='pro_home_one  mt-150'>
        <div className='row' >
          
          <Profile_home_nav />

          <div className='col-8'>
          <div className='pro_home_fifteen'>
              <div className='d-flex'>
                <p className='pro_home_eight'>Bank Details</p>
              </div>
            </div>
            <div className='pro_home_seven mt-3'>
              <div className='row'>
                <div className='col-6'>

                    <p className='kyc_two_para'>A/c Holder Name*</p>
                    <input type="text" onChange={(e)=>{
                      let letters = /^[1-9][0-9]*$/;
                      let last = ''
                      if(e.target.value.length >=2) {
                          last = e.target.value.split('').pop()
                      }else{
                          last = e.target.value
                      }
                      if(last.match(letters)){

                      }else{
                          setName(e.target.value)
                      }
                    }} value={name}  className="form-control kyc_in_one" id="exampleInputEmail1"  />
                    {
                      nameerr === true ?  <p style={{fontSize:'10px',color:'red',fontWeight:'600'}}>Enter name</p> : ''
                    }

                    <p className='kyc_two_para mt-3'>Confirm Account Number*</p>
                    <input type="number" onChange={(e)=>setCofirmaccno(e.target.value)} value={confiemaccno} className="form-control kyc_in_one" id="exampleInputEmail1"  />
                    {
                      confiemaccnoerr === true ? <p style={{fontSize:'10px',color:'red',fontWeight:'600'}}>Mismatch Account No</p> : ''
                    }

                    <p className='kyc_two_para mt-3'>UPI Id*</p>
                    <input onChange={(e)=>setUpi(e.target.value)} value={upi} type="text" className="form-control kyc_in_one" id="exampleInputEmail1" />
                    {
                      upierr === true ? <p style={{fontSize:'10px',color:'red',fontWeight:'600'}}>Enter Valid UPI No</p>  : ''
                    }
                    

                </div>
                <div className='col-6'>

                  <p className='kyc_two_para'>Account Number*</p>
                  <input type="number" onChange={(e)=>setAccno(e.target.value.slice(0, 18))} value={accno} className="form-control kyc_in_one" id="exampleInputEmail1"  />
                  {
                    accnoerr === true ? <p style={{fontSize:'10px',color:'red',fontWeight:'600'}}>Enter Valid Account No</p>  : ''
                  }

                  <p className='kyc_two_para mt-3'>IFSC Code*</p>
                  <input onChange={(e)=>setIfsc(e.target.value.slice(0, 11))} value={ifsc} type="text" className="form-control kyc_in_one" id="exampleInputEmail1" />
                  {
                    ifscerr === true ? <p style={{fontSize:'10px',color:'red',fontWeight:'600'}}>Enter Valid IFSC No</p> : ''
                  }

                </div>
              </div>
              <div style={{cursor : 'pointer'}} onClick={()=>updata === true ? update() :submit()} className='bank_det_one mt-4'>
                <p className='bank_det_two' style={{paddingTop:'10px'}}>{updata === true ? 'Save Changes' : 'Add Bank Details'}</p>
              </div>
              {
                all === true ? <p style={{fontSize:'14px',color:'red',fontWeight:'600' , textAlign : 'center' , marginTop:'20PX'}}>{allerr}</p> : ''
              }
              {
                sus === true ? <p style={{fontSize:'14px',color:'green',fontWeight:'600' , textAlign : 'center' , marginTop:'20PX'}}>{updata === true ? 'Account Updated' : 'Account Added'}</p> : ''
              }
            </div>
          </div>
        </div>
      </div>
  </>
  )
}
