import React , { useState , useEffect} from 'react'
import Timer from '../otpp/timer'
import Qr_one from '../../../assets/img/qr_one.png'
import { useParams  , useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Base_Url, ImageUrl } from '../../globalvariable/globe'
import { ToastContainer, toast } from 'react-toastify';

export default function Handover_req_comp() {
 
    const [ all , setAll ] = useState()

    const [ catdetid , setCatdetid ] = useState([])

    const [ newid , setNewid ] = useState()

    let { id , type } = useParams()

    let navigate = useNavigate()

    const [ vali , setVali ] = useState(false)

    let token = localStorage.getItem('user_data')

    const Alldata = async() => {
        if(token === null){
            navigate('/')
            return
          }
        let responce = await axios({
            method : 'post' ,
            url : `${Base_Url}/viewHandover`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            },
            data : {
                orderId : id,
                type : type
            }
        })
        .then((res)=>{
            setNewid(res.data.Data.orderDetails.orderId)
            setAll(res.data.Data)
            console.log(res)
        })
        .catch((err)=>{
            console.log(err)
        })
    }
    useEffect(()=>{
        Alldata()
    },[])

    const errnotify = (dat) => toast.error( dat , {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const clickeddd = async() =>{ 
        setVali(true)
        let arry = catdetid.join()

        if(arry === ''){
            setVali(false)
            // errnotify('Check Atleast One Field')
            return
        }else{
            let res = await axios({
                method : 'post',
                url : `${Base_Url}/selectOrderProductToHandover`,
                headers: { 
                    'Authorization': `Bearer ${token}` 
                },
                data : {
                    orderId : newid,
                    cartDetailId : arry
                }
            })
            .then((res)=>{
                if(res.data.status === true){
                    navigate('/handover_detail/'+all.orderDetails.orderId+'/'+arry+'/'+1  )
                }
                console.log(res)
            })
            .catch((err)=>{
                console.log(err)
            })
        }
    }

    const change = ( e , data ) =>{
        setVali(true)
        if(e.target.checked === true){
           if(catdetid.includes(data.CartDetailId)){
           }else{
                setCatdetid(catdetid.concat(data.CartDetailId))
           }
        }else{
            let arr = catdetid.filter(function(item) {
                setVali(false)
                return item !== data.CartDetailId
            })
            setCatdetid(arr)
        }        
    }

    return (
    <>
    <div className='run_ord_one'>
        <div className='row'>
            <div className='col-5'>
                <Timer time={172} />
            </div>
            <div className='col-7'>
                <p className='run_ord_two'>ID : {all?.orderDetails.orderId}</p>
                <p className='run_ord_three'>{all?.orderDetails.fromDate} - {all?.orderDetails.toDate}</p>
            </div>
        </div>
        </div>
        <div className='d-flex justify-content-between'>
        <p className='run_ord_four pt-1'>Order Contains</p>
        {/* <div className='d-flex'>
            <input className='ch_three mt-4' type='checkbox' />
            <p className='run_ord_four pt-1'>Select all</p>
        </div> */}
        </div>
    {all?.productDetails.map((dta,key)=>
    <>
        <div className='qr_seven mt-3'>
            <div className='row'>
                {
                    dta.CartDetailProIsHandovered === 0 ?
                    <div className='col-5'>
                        <dic className='row'>
                            <div className='col-3'>
                                <input onChange={(e)=>change(e,dta)} className='ch_three mt-4' type='checkbox' />
                            </div>
                            <div className='col-9'>
                                <p className='qr_eight mt-1'>No : {dta.CartDetailInventoryProSerialNo}</p>
                                <p className='qr_nine'>{dta.BrandName}</p>
                            </div>
                        </dic>
                    </div>
                    : dta.CartDetailProIsHandovered === 3 ?
                    <div className='col-5'>
                        <dic className='row'>
                            <div className='col-3'>
                                <input onChange={(e)=>change(e,dta)} className='ch_three mt-4' type='checkbox' />
                            </div>
                            <div className='col-9'>
                                <p className='qr_eight mt-1'>No : {dta.CartDetailInventoryProSerialNo}</p>
                                <p className='qr_nine'>{dta.BrandName}</p>
                            </div>
                        </dic>
                    </div>
                    :
                    <div className='col-5'>
                        <p className='qr_eight mt-1'>No : {dta.CartDetailInventoryProSerialNo}</p>
                        <p className='qr_nine'>{dta.BrandName}</p>
                    </div>
                }
                <div className='col-3'>
                    <img style={{width:'100%'}} className='mt-2' src={ImageUrl+'/'+dta.ProImgPrimary} />
                </div>
                <div className='col-4'>
                    {
                        dta.CartDetailProIsHandovered === 0 ?
                            <div className= 'qr_tensss'>
                                <p className='qr_elevens'>Handover</p>
                            </div>
                        : dta.CartDetailProIsHandovered === 1 ?
                            <div onClick={()=>{ navigate('/handover_detail/'+all.orderDetails.orderId+'/'+dta.CartDetailId+'/'+0 ) }} className= 'qr_tens'>
                                <p className='qr_eleven'>Requested</p>
                            </div>
                        : dta.CartDetailProIsHandovered === 2 ?
                            <div className= 'qr_ten'>
                                <p className='qr_eleven'>Handovered</p>
                            </div>
                        :
                            <div className= 'qr_tenss'>
                                <p className='qr_eleven'>Damage</p>
                            </div>
                    }
                </div>
            </div>
        </div>
    </>
    )}
        <div className='qr_three'>
            <div className='row'>
                <div className='col-7'>
                    <p className='qr_six'>{all?.orderDetails.remainingDays} Days Left</p>
                </div>
                <div className='col-5'>
                    {/* <Link to='/service_pick'> */}
                        <div onClick={()=>clickeddd()} className={vali === true ? 'qr_four' : 'qr_four_lite'}>
                            <p className='qr_five'>Handover</p>
                        </div>
                    {/* </Link> */}
                </div>
            </div>
        </div>
        <ToastContainer />
    </>
  )
}