import React , { useState  , useEffect } from 'react'
import { Input , InputGroup , InputLeftElement , ChakraProvider } from '@chakra-ui/react'
import { Search2Icon } from '@chakra-ui/icons'
import Groub from '../../../assets/img/Group.png'
import './search.css'
import time from '../../../assets/img/time.png'
import data from './search_recent_data.json'
import exx from '../../../assets/img/x.png'
import up from '../../../assets/img/up.png'
import axios from 'axios'
import { Base_Url, ImageUrl } from '../../globalvariable/globe'
import { useNavigate } from 'react-router-dom'
import { useDispatch , useSelector } from 'react-redux'
import hrrt from '../../../assets/img/hrrt.png'
import img12 from '../../../assets/img/Vector.png'
import { selectedRemoveProduct , Wishcount } from '../../../redux/actions/productAction'
import { SearchIcon } from '@chakra-ui/icons'
import { element } from 'prop-types'
import Skeleton from 'react-loading-skeleton'

export default function Search_comp() {

    const [ sear , setSear ] = useState()

    const [ alldata , setAllData ] = useState()

    let navigate = useNavigate(); 
    const product = useSelector((state)=>state.allpoductData)
    const imgs = product.awsbaseurl
    const dispatch = useDispatch()
    let wishss = localStorage.getItem('wish_list')
    const [ wish ,  setWish ] = useState()

    const [ search , setSearch ] = useState()

    const [ show , setShow ] = useState(false)

    const [ msg , setMsg ] = useState('')

    const [ skli , setSkli ] = useState(false)
    
    const clicked = ( dat , key ) =>{
        navigate('/detail/'+ dat.ProName
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '')+'/' +dat.ProId)
        dispatch(selectedRemoveProduct())
    }

    
    useEffect(()=>{
        wishss = localStorage.getItem('wish_list')
        setWish(JSON.parse(wishss))
        onee()
    },[])

    const [countss  , setCountss ]= useState(1)

    let onee = () =>{
        console.log('fgopjroprggop')
        let one = localStorage.getItem('search_data')
        setSearch(JSON.parse(one))
    }

    useEffect(()=>{
        console.log('adkldfjkbsdfbwejkbksdfjbbjkjk')
        wishss = localStorage.getItem('wish_list')
        setWish(JSON.parse(wishss))
    },[countss])

    const Clickheart = ( data , key ) => {
        setCountss(countss +1)
        let allata = localStorage.getItem('wish_list')
        let another = JSON.parse(allata)
        let final = 0
        // setHeart(true)

        let wish_data = [
            {
                datas : data
            }
        ]

        if(localStorage.getItem('wish_list')){
            another.forEach((element, index) => {
                if( element.datas.ProId === wish_data[0].datas.ProId){
                    let allanother = another
                    allanother.splice(index , 1)
                    localStorage.setItem('wish_list' , JSON.stringify(allanother))
                    dispatch(Wishcount(allanother))
                    final = 1
                }
            })
            console.log(final)
            if( final === 0 ){
                let allanother =  another.concat(wish_data)
                localStorage.setItem('wish_list' , JSON.stringify(allanother))
                dispatch(Wishcount(allanother))
            }

        }else{
            localStorage.setItem('wish_list' , JSON.stringify(wish_data))
            dispatch(Wishcount(wish_data))
        }
    }

    const all = async ()=>{
        console.log(sear , 'fdcs')
        if(sear === '' ){
            return
        }
        if(sear === ' '){
            setSear('')
            return
        }
        setSkli(false)
        setMsg('')
        setShow(true)
        let searchdata = localStorage.getItem('search_data')
        let another = JSON.parse(searchdata)

        if(another){
                if (another.includes(sear)){

                }else{
                    let allanother = another.concat(sear)
                    localStorage.setItem('search_data' , JSON.stringify(allanother))
                }
        }
        else{
            let another = [sear]
            localStorage.setItem('search_data' , JSON.stringify(another))
        }

        console.log('jkwgwgewu')
        let respo = await axios({
            method : 'post',
            url : `${Base_Url}/search`,
            data : {
                search : sear
            }
        })
        .then((res)=>{
            console.log(res,'d[fpkpfkhkfpo')
            if(res.data.status===false){
                console.log('worked')
                setSkli(true)
                setShow(false)
                setMsg(res.data.message)
            }
            setAllData(res.data.Data.searchDetails)
            
        })
        .catch((err)=>{
            console.log(err)
        })

        onee()
    }

    const alls = async (data)=>{
        setMsg('')
        setShow(true)
        let searchdata = localStorage.getItem('search_data')
        let another = JSON.parse(searchdata)

        if(another){
                if (another.includes(data)){

                }else{
                    let allanother = another.concat(data)
                    localStorage.setItem('search_data' , JSON.stringify(allanother))
                }
        }
        else{
            let another = [data]
            localStorage.setItem('search_data' , JSON.stringify(another))
        }

        console.log('jkwgwgewu')
        let respo = await axios({
            method : 'post',
            url : `${Base_Url}/search`,
            data : {
                search : data
            }
        })
        .then((res)=>{
            if(res.data.status===false){
                setSkli(true)
                setShow(false)
                setMsg(res.data.message)
            }
            setAllData(res.data.Data.searchDetails)
            console.log(res)
            
        })
        .catch((err)=>{
            console.log(err)
        })

        onee()
    }

    const removesearch = (data) =>{
        let searchdata = localStorage.getItem('search_data')
        let another = JSON.parse(searchdata)
        another.forEach((element,index)=>{
            if(element === data ){
                let allanother = another
                allanother.splice(index , 1)
                localStorage.setItem('search_data' , JSON.stringify(allanother))
            }
        })
        onee()
    }

    const clicksearch = (data) =>{
        setSear(data)
        setAllData()
        setShow(false)
        alls(data)
    }

    let dataaaaa = (data)=>{
        console.log(data,'l;dfv')
        setSear(data.dat)
        setAllData()
        setShow(false)
        alls(data.dat)
    }

  return (
    <>
        <div className='row'>
            <div className='col-10'>
                <ChakraProvider>
                    <InputGroup>
                        <Input type='text' onChange={(e)=>{
                            let one = e.target.value.split('')
                            if(one[0]===' '){
                                setSear('')
                               return
                            }
                            setAllData()
                            setShow(false)
                            setSear(e.target.value)
                        }} value={sear} variant='filled' placeholder='Search Laptops, Camera, Etc......' />
                    </InputGroup>
                </ChakraProvider>
            </div>
            <div className='col-2'>
                <div onClick={()=>all()} className='coool'>
                    <ChakraProvider>
                        <SearchIcon w={4} h={4} color="white" />
                    </ChakraProvider>
                </div>
            </div>
        </div>
        {msg != '' ? <p style={{textAlign:'center',marginTop:'20px',fontWeight:'600'}}>{msg}</p> : ''  }
        {
            show === false ? <>
            <div className='d-flex mt-4'>
            <div style={{paddingRight:'10px'}}>
                <img className='re_img' src={time} />
            </div>
            <div>
                <p className='re_text'>Recent</p>
            </div>
        </div>
                    <div className='d-flex flex-wrap roow'>
                                {
                                    search?.map((data,key)=>{
                                        return(
                                            <div className=' mt-2'>
                                                <div className='collv'>
                                                    <div className='d-flex '>
                                                            <p onClick={()=>clicksearch(data)} className='recent_para'>{data}</p>
                                                            <img style={{marginTop:'-2px' , marginLeft : 7}} onClick={()=>removesearch(data)} className='img_po' src={exx} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {/* <div className='d-flex mt-4'>
                                <div style={{paddingRight:'10px'}}>
                                    <img className='re_img' src={up} />
                                </div>
                                <div>
                                    <p className='re_text'>Trending</p>
                                </div>
                            </div>
                            <div className='row roows'>
                                {data?.map((datas , key)=>
                                    <div key={key} onClick={()=>dataaaaa(datas)} className='col mt-3'>
                                        <div className='collvs'>
                                            <p className='recent_paras'>{datas.dat}</p>
                                        </div>
                                    </div>
                                )}
                            </div> */}
                    </>
                    :''
        }
       

        { alldata? alldata?.map((dat,key)=>
    <div>
        <div className='r1oo'>
            <div className='row' >
                <div  onClick={()=>{clicked(dat,key)}} style={{margin:'auto'}} className='col-4 imcol1'>
                    <img className='imm' src={ImageUrl+'/'+dat.ProImgPrimary} />
                </div>
                <div className='col-8'>
                    <div className='row'>
                        <div  onClick={()=>{clicked(dat,key)}} className='col-9'>
                            <p className='paraone'>{dat.BrandName}</p>
                            <p className='paratwo'>{dat.ProName.substring(0, 90)}...</p>
                            {/* <p className='parathree'>256/8 gb , 13.7” Retina Display</p> */}
                        </div>
                        <div className='col-3'>
                        {(() => {
                          if(wish != null){
                              console.log(typeof(wish),'wishhhh')
                          var result = wish.filter(x => x.datas.ProId == dat.ProId);

                      if(result.length > 0){
                          return ( <img onClick={()=>Clickheart(dat,key)} className="pop_icon" src={hrrt} />)
                          }else{
                              return ( <img onClick={()=>Clickheart(dat,key)} className="pop_icon" src={img12} />)
                          }
                      }
                      else{
                          return ( <img onClick={()=>Clickheart(dat,key)} className="pop_icon" src={img12} />)
                      }
                      })()}
                            {/* <img style={{width:'21px'}} src={(dat.ProWishlist===0 ?img12 : hrrt)} /> */}
                        </div>
                    </div>
                    <div  onClick={()=>{clicked(dat,key)}} className='row'>
                        <div className='col-6'>
                            <div className='btoone'>
                                <p className='parafour'>₹ {dat.ProRentAmt}/M</p>
                            </div>
                        </div>
                        {/* <div className='col-6'>
                            <p className='proo_crt_onee'>50% Off</p>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    )
:  show=== true ?  <>
<div className='row mt-2'>
  <div className='col-3' style={{ height:'30px' , borderRadius:'10px'}} >
    <Skeleton width='100%' height='100%' />
  </div>
  <div className='col-3' style={{ height:'30px' , borderRadius:'10px'}} >
    <Skeleton width='100%' height='100%' />
  </div>
  <div className='col-3' style={{ height:'30px' , borderRadius:'10px'}} >
    <Skeleton width='100%' height='100%' />
  </div>
  <div className='col-3' style={{ height:'30px' , borderRadius:'10px'}} >
    <Skeleton width='100%' height='100%' />
  </div>
</div>
<div className='row mt-2'>
  <div className='col-6' style={{ height:'30px' , borderRadius:'10px'}} >
    <Skeleton width='100%' height='100%' />
  </div>
  <div className='col-6' style={{ height:'30px' , borderRadius:'10px'}} >
    <Skeleton width='100%' height='100%' />
  </div>
</div>
<div className='row mt-2'>
  <div className='col-12' style={{ height:'90px' , borderRadius:'10px'}} >
    <Skeleton width='100%' height='100%' />
  </div>
</div>
<div className='row mt-2'>
  <div className='col-12' style={{ height:'90px' , borderRadius:'10px'}} >
    <Skeleton width='100%' height='100%' />
  </div>
</div>
<div className='row mt-2'>
  <div className='col-12' style={{ height:'90px' , borderRadius:'10px'}} >
    <Skeleton width='100%' height='100%' />
  </div>
</div>
<div className='row mt-2'>
  <div className='col-12' style={{ height:'90px' , borderRadius:'10px'}} >
    <Skeleton width='100%' height='100%' />
  </div>
</div>
</> : ''}

        {/* <div style={{paddingTop:'25px'}}>
            <div className="pro_groub_head">
                Categories
            </div>
        </div> */}
    </>
  )
}