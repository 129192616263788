import React , { useState , useEffect } from 'react'
import Left from '../../../assets/img/left.png'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Base_Url } from '../../globalvariable/globe'
import Html from 'html-react-parser'

export default function Terms_mob() {

  const [ alldata , setAlldata ] = useState()

  let navigate = useNavigate()

  let All = async ()=>{
    let res = await axios({
      method : 'post',
      url : `${Base_Url}/tandc`
    })
    .then((res)=>{
      console.log(res)
      setAlldata(res.data.Data.PolicyValue)
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    All()
  })

  const clicked = ()=>{
    navigate(-1)
  }

  return (
    <div className='container' style={{ margin:'auto' ,overflowX:'hidden'}}>
       <div className="second-tops fixed-top navbar-light top-tab-nav">
            <div className="d-flex  m-auto paddd" >
               <div onClick={()=>clicked()} className='loab'>
                  {/* <Link to={props.link}> */}
                    <img src={Left} />
                  {/* </Link> */}
                </div>
               <div style={{margin: 'auto'}} className='loab'>
                   <p className='ccaaat'>Terms and Conditions</p>
               </div>
            </div>
        </div>
        <p className='mt-70' style={{fontSize :'14px' , fontWeight: '600'}}>{alldata?Html(alldata):''}</p>
    </div>
  )
}
