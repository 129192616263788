import React , { useState , useEffect } from 'react'
import { Link , useNavigate } from 'react-router-dom'
import one123 from '../../assets/img/123.png'
import cccn from '../../assets/img/cccn.png'
// import scan from '../../assets/img/scan.png'
import axios from 'axios'
import { Base_Url } from '../../components/globalvariable/globe'
import { ImageUrl } from '../../components/globalvariable/globe'
import { useDispatch } from 'react-redux'
import { LoogIn } from '../../redux/actions/productAction'
import { ToastContainer, toast } from 'react-toastify';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    ChakraProvider
  } from '@chakra-ui/react'

export default function Order_home_det(props) {

    const { isOpen, onOpen, onClose } = useDisclosure()

    const errnotify = (err) => toast.error(err, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const successnotify = (sus) => toast.success( sus , {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

    let navigate = useNavigate()

    let token = localStorage.getItem('user_data')
    const [ pendings , setPending ] = useState()

    const [ runi , setRuni ] = useState()
    const [ clickruni , setClickruni ] = useState()

    const [ clickhand ,  setClickedhand ] = useState()
    const [ hand , setHand ] = useState()

    const [ serve , setServe ] = useState()
    const [ clickserve , setClickerve ] = useState()

    const [ val , setVal ] = useState(0)
    const [ checked , setChecked ] = useState('checked')

    const [ vals , setVals ] = useState(0)
    const [ checkeds , setCheckeds ] = useState('checked')

    const [ valss , setValss ] = useState(0)
    const [ checkedss , setCheckedss ] = useState('checked')

    const [ canceldata , setCanceldata ] = useState()

    let dispatch = useDispatch()

    const handleChange =async (e) => {

        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/runningOrder`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            },
            data : {
                products : 1
            }
        })
        .then((res)=>{
            setClickruni(res.data.Data)
            console.log(res.data.Data,'click running')
        })
        .catch((err)=>{
            if(err.response.status === 401){
                dispatch(LoogIn({show : true}))
                // navigate('/')
            }
            console.log(err)
        })

        if(val === 1){
            setChecked('checked')
            setVal(0)
        }else{
            setChecked('')
            setVal(1)
        }
    }
    const handleChanges = async (e) => {

        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/handover`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            },
            data : {
                products : 1
            }
        })
        .then((res)=>{
            setClickedhand(res.data.Data)
            console.log(res.data.Data,'click hand')
        })
        .catch((err)=>{
            if(err.response.status === 401){
                dispatch(LoogIn({show : true}))
                // navigate('/')
            }
            console.log(err)
        })
        
        if(vals == 1){
            setCheckeds('checked')
            setVals(0)
        }else{
            setCheckeds('')
            setVals(1)
        }
    }

    const handleChangess = async (e) => {

        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/service`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            },
            data : {
                products : 1
            }
        })
        .then((res)=>{
            setClickerve(res.data.Data)
            console.log(res.data.Data,'click serve')
        })
        .catch((err)=>{
            if(err.response.status === 401){
                dispatch(LoogIn({show : true}))
                // navigate('/')
            }
            console.log(err)
        })

        if(valss == 1){
            setCheckedss('checked')
            setValss(0)
        }else{
            setCheckedss('')
            setValss(1)
        }
    }
    const first = async () => {
        if(token === null){
            navigate('/')
            return
        }
        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/pendingOrders`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            }
        })
        .then((res)=>{
            console.log(res,'pendingggg')
            setPending(res.data.Data)
        })
        .catch((err)=>{
            if(err.response.status === 401){
                dispatch(LoogIn({show : true}))
                // navigate('/')
            }
            console.log(err)
        })
    }

    const run = async ()=>{
        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/runningOrder`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            }
        })
        .then((res)=>{
            setRuni(res.data.Data)
            console.log(res.data.Data,'running')
        })
        .catch((err)=>{
            if(err.response.status === 401){
                dispatch(LoogIn({show : true}))
                // navigate('/')
            }
            console.log(err)
        })
    }

    const handover = async ()=>{
        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/handover`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            }
        })
        .then((res)=>{
            console.log(res.data.Data,'handddddddddddddddddd')
            setHand(res.data.Data)
        })
        .catch((err)=>{
            if(err.response.status === 401){
                dispatch(LoogIn({show : true}))
                // navigate('/')
            }
            console.log(err)
        })
    }

    const service_data = async ()=>{
        let responce = await axios({
            method : 'post',
            url : `${Base_Url}/service`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            }
        })
        .then((res)=>{
            console.log(res.data.Data, 'res.data.Data')
            setServe(res.data.Data)
        })
        .catch((err)=>{
            if(err.response.status === 401){
                dispatch(LoogIn({show : true}))
                // navigate('/')
            }
            console.log(err)
        })
    }

    useEffect(()=>{
        first()
        run()
        handover()
        service_data()
    },[])

    const Service = (data) =>{
        return(
            <>
             <div className='d-flex justify-content-between mt-4'>
                        <div>
                            <p className='switch_order_one'>List by orders</p>
                        </div>
                        <div>
                            <label className="switch">
                                <input type="checkbox" checked={(checkedss)} onChange={e => handleChangess(e)} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
             {
                serve?.orders?.length === 0 || serve === undefined ? <p style={{ textAlign : 'center' , fontSize : '15px' , fontWeight : '600', marginTop:'40px'}}>No Service List</p>  :
                serve?.orders.map((data,key)=>{
                return(
                <>
                   
                    <div className='ord_det_one mt-4'>
                        <div className='row'>
                            <div className='col-6'>
                                <p className='ord_det_two'>Order Id : {data.OrderAutoGenId}</p>
                                <p className='ord_det_threes'>{data.fromdate}   -   {data.todate}</p>
                            </div>
                            <div className='col-6'>
                                <p className='ord_det_four'>₹{data.orderamt}</p>
                                <p className='ord_det_fives'>{data.Type}</p>
                            </div>
                        </div>
                        <div className='row mt-1'>
                        {data.carts.map((dat,key)=>
                            {
                                return(
                                    key >=5 ?
                                    <></>
                                    :   
                                    <div className='col-3' >
                                        <img className='ord_det_seven' src={ImageUrl+'/'+dat.ProImgPrimary} />
                                        <p className='ord_det_eight '>{dat.ProName.substring(0, 20)}...</p>
                                    </div>
                                )
                            }
                                    
                                )}
                            {
                                data.remainingCount === 0 ? '' :
                                    <div className='col-3' >
                                        <img className='ord_det_sevens' src={one123} />
                                        <p className='more_items'>{data.remainingCount}<br/>Items</p>
                                        <p className='ord_det_eights '>View more</p>
                                    </div>   
                                }
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                
                            </div>
                            <div className='col-6'>
                                <Link to={'/service_request/'+data.OrderAutoGenId+ '/' + 2}>
                                    <div onClick={e=>handleChangess(e)} className='ord_det_nine mt-2'>
                                        <p className='ord_det_twelve'>View Details</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </>
                )
            })
            }
                
            </>
        )
    }


    const Orderlist = (data) =>{
        return(
            <>
                <div className='d-flex justify-content-between mt-4'>
                    <div>
                        <p className='switch_order_one'>List by orders</p>
                    </div>
                    <div>
                        <label className="switch">
                            <input type="checkbox" checked={(checked)} onChange={e => handleChange(e)} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
            {
                runi?.orders.length === 0 || runi === undefined ? <p style={{ textAlign : 'center' , fontSize : '15px' , fontWeight : '600', marginTop:'40px'}}>No Running Order</p> :
                runi?.orders.map((data,key)=>
                <>
                    <div className='ord_det_one mt-4'>
                        <div className='row'>
                            <div className='col-6'>
                                <p className='ord_det_two'>Order Id : {data.OrderAutoGenId}</p>
                                <p className='ord_det_threes'>{data.fromdate}   -   {data.todate}</p>
                            </div>
                            <div className='col-6'>
                                <p className='ord_det_four'>₹{data.orderamt}</p>
                                <p className='ord_det_fives'>{data.Type}</p>
                            </div>
                        </div>
                        <div className='row mt-1'>
                        {data.carts.map((dat,key)=>
                                
                                {
                                    return(
                                        
                                    key >=5 ?
                                    <></>
                                    : <div className='col-3' >
                                    <img className='ord_det_seven' src={ImageUrl+'/'+dat.ProImgPrimary} />
                                    <p className='ord_det_eight mt-4'>{dat.ProName.substring(0, 20)}...</p>
                                </div>
                                )
                                }
                                    
                                )}
                            {
                                data.remainingCount === 0 ? '' :
                                    <div className='col-3' >
                                        <img className='ord_det_sevens' src={one123} />
                                    
                                        <p className='more_items'>{data.remainingCount}<br/>Items</p>
                                        <p className='ord_det_eights'>View more</p>
                                    </div>   
                                }
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <Link to={'/running_order/'+data.orderId}>
                                    <div className='ord_det_ten mt-2'>
                                        <p className='ord_det_eleven'>Renew order</p>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-6'>
                                <Link to={'/running_order/'+data.orderId}>
                                    <div onClick={(e)=>handleChange(e)} className='ord_det_nine mt-2'>
                                        <p className='ord_det_twelve'>View Details</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </>
                )
            }
               
            </>
        )
    }

    const Handover = (data) =>{
        return(
            <>
            <div className='d-flex justify-content-between mt-4'>
                    <div>
                        <p className='switch_order_one'>List by orders</p>
                    </div>
                    <div>
                        <label className="switch">
                            <input type="checkbox" checked={(checkeds)} onChange={e => handleChanges(e)} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
            {
                hand?.orders.length === 0 || hand === undefined ? <p style={{ textAlign : 'center' , fontSize : '15px' , fontWeight : '600', marginTop:'40px'}}>No Handover List</p> :
                hand?.orders.map((data,key)=>
                <div className='ord_det_one mt-4'>
                    <div className='row'>
                        <div className='col-6'>
                            <p className='ord_det_two'>Order Id : {data.OrderAutoGenId}</p>
                            <p className='ord_det_threes'>{data.fromdate}   -   {data.todate}</p>
                        </div>
                        <div className='col-6'>
                            <p className='ord_det_four'>₹{data.orderamt}</p>
                            <p className='ord_det_fives'>{data.Type}</p>
                        </div>
                    </div>
                    <div className='row mt-1'>
                        {data.carts.map((dat,key)=>
                            {
                                return(
                                    key >=5 ?
                                    <></>
                                    :   
                                    <div className='col-3' >
                                        <img className='ord_det_seven' src={ImageUrl+'/'+dat.ProImgPrimary} />
                                        <p className='ord_det_eight mt-4'>{dat.ProName.substring(0, 20)}...</p>
                                    </div>
                                )
                            }
                                    
                        )}
                        {
                        data.remainingCount === 0 ? '' :
                            <div className='col-3' >
                                <img className='ord_det_sevens' src={one123} />
                            
                                <p className='more_items'>{data.remainingCount}<br/>Items</p>
                                <p className='ord_det_eights'>View more</p>
                            </div>   
                        }
                    </div>
                    <div className='row '>
                        <div className='col-6'>
                                <div className=' mt-2'>
                                    <p className='ord_det_eleven'>{data.remainingDays}</p>
                                </div>
                        </div>
                        <div className='col-6'>
                            <Link to={'/handover_req/'+data.orderId+'/'+2}>
                                <div className='ord_det_nine mt-2'>
                                    <p className='ord_det_twelve'>Handover</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                )
            }
            </>
        )
    }

 
    const Service_list = () =>{
        return(
            
            <>
           
                <div className='d-flex justify-content-between'>
                    <div>
                        <p className='switch_order_one'>List by orders</p>
                    </div>
                    <div>
                        <label className="switch">
                            <input type="checkbox" checked={(checkedss)} onChange={e => handleChangess(e)}/>
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                {
                    clickserve?.products?.length === 0 || clickserve === undefined ? <p style={{ textAlign : 'center' , fontSize : '15px' , fontWeight : '600', marginTop:'40px'}}>No Service List</p>  :
                    clickserve?.products.map((data,key)=>
                        <div  className='check_change mt-4' style={{ height : 'auto' }}>
                            <Link to={data.RequestedForService === 0 ? '/service_status/'+data.CartDetailId+'/'+data.OrderAutoGenId+'/'+1  : data.RequestedForService === 1 ? 
                            '/service_status/'+data.CartDetailId+'/'+data.OrderAutoGenId+'/'+0 : '/serviced/'+data.CartDetailId+'/'+data.OrderAutoGenId+'/'+data.CartDetailInventoryProSerialNo } >
                                <div className='row'>
                                    <div className='col-8'>
                                        <p className='ord_det_two'>{data.OrderAutoGenId}</p>
                                        <p className='ord_det_threes'>No : {data.CartDetailInventoryProSerialNo}</p>
                                        <p className='check_change_ones'>{data.ProName}</p>
                                        {
                                            data.RequestedForService === 1 ? <div><p style={{fontSize:'12px',fontWeight:'1200',color:'orange'}}>Requested</p></div> : ''
                                        }
                                    </div>
                                    <div className='col-4 mt-4'>
                                        <img className='check_change_twosssssssssss' src={ImageUrl+'/'+data.ProImgPrimary} />
                                        
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )
                }
            </>
        )
    }


    const ListOrder = () =>{ 
        return(
            <>
                <div className='d-flex justify-content-between mt-4'>
                    <div>
                        <p className='switch_order_one'>List by orders</p>
                    </div>
                    <div>
                        <label className="switch">
                            <input type="checkbox" checked={(checked)} onChange={e => handleChange(e)}/>
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                {
                    clickruni?.products.length === 0 || clickruni === undefined ? <p style={{ textAlign : 'center' , fontSize : '15px' , fontWeight : '600', marginTop:'40px'}}>No Running Order</p> :
                    clickruni?.products.map((data,key)=>
                        <div className='check_change mt-4' style={{ height : 'auto' }} >
                            <Link to={'/running_pro_detail/'+ data.OrderAutoGenId +'/'+data.CartDetailId +'/'+data.CartDetailProId} >
                                <div className='row'>
                                    <div className='col-8'>
                                        <p className='ord_det_two'>{data.OrderAutoGenId}</p>
                                        <p className='ord_det_threes'>No : {data.CartDetailInventoryProSerialNo}</p>
                                        <p className='check_change_ones'>{data.ProName}</p>
                                    </div>
                                    <div className='col-4'>
                                    <img className='check_change_twosssssssssss' src={ImageUrl+'/'+data.ProImgPrimary} />
                                    </div>
                                </div>
                            </Link>
                        </div>
                   ) 
                }
            </>
        ) 
    }

    let handleary = []

    const handChange = (e,data) =>{

         handleary=handleary.concat(data.OrderId+'-'+data.CartDetailId)

        console.log(handleary,'sklwsklncklqws')
        if(e.target.checked === true){
           

            if(handleary.includes(data.OrderId+'-'+data.CartDetailId)){
                console.log('sdfkjsrjkbrjb')
            }else{
                console.log('kklsvknkln')
                handleary = handleary.concat(data.OrderId+'-'+data.CartDetailId)
            }
         }else{
             let arr = handleary.filter(function(item) {
                 return item !== data.OrderId+'-'+data.CartDetailId
             })
             handleary = arr
         }
         console.log(handleary);
    }

    const handsusaaa = async () =>{

        let ress = await axios({
            method  : 'post' , 
            url : `${Base_Url}/selectProductToHandover`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            },
            data : {
                orderId_cartDetailId : handleary.join()
            }
        })
        .then((res)=>{
            if(res.data.status === false ){
                errnotify('Atleast Select One Field')
            }else{
                navigate('/handover_all/'+res.data.Data.orderId)
            }
            console.log(res)
        })
       
    }

    const ListOrders = () =>{
        return(
            <>
                <div className='d-flex justify-content-between mt-4'>
                    <div>
                        <p className='switch_order_one'>List by orders</p>
                    </div>
                    <div>
                        <label className="switch">
                            <input type="checkbox" checked={(checkeds)} onChange={e => handleChanges(e)}/>
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                {
                    clickhand?.products.map((data,key)=>
                        <div className='check_change mt-4' style={{ height : 'auto' }} >
                            <div className='row'>
                                <div className='col-1'>
                                    {
                                         data.CartDetailProIsHandovered === 0 ?  <input className='ch_three mt-4' type='checkbox' onClick={(e)=>handChange(e,data)} />  : ''
                                    }
                                   
                                </div>
                                <div className='col-7'>
                                    <Link to={'/detail/'+
                                        data.ProName
                                        .toLowerCase()
                                        .trim()
                                        .replace(/[^\w\s-]/g, '')
                                        .replace(/[\s_-]+/g, '-')
                                        .replace(/^-+|-+$/g, '')+'/'+data.CartDetailProId} >
                                        <p className='ord_det_two'>{data.OrderAutoGenId}</p>
                                        <p className='ord_det_threes'>No : {data.CartDetailInventoryProSerialNo}</p>
                                        <p className='check_change_ones'>{data.ProName}</p>
                                    </Link>
                                </div>
                                <div className='col-4'>
                                    <Link to={'/detail/'+
                                        data.ProName
                                        .toLowerCase()
                                        .trim()
                                        .replace(/[^\w\s-]/g, '')
                                        .replace(/[\s_-]+/g, '-')
                                        .replace(/^-+|-+$/g, '')+'/'+data.CartDetailProId} >
                                        <img className='check_change_twosssssssssss' src={ImageUrl+'/'+data.ProImgPrimary} />
                                    </Link>
                                    {
                                        data.CartDetailProIsHandovered === 0 ? 
                                            <div style={{ cursor : 'pointer' , width : '100%' , height : 25 , backgroundColor  : 'rgba(244, 182, 68, 0.25)' , borderRadius : 5 , padding : '1px 5px' }} >
                                                <p style={{ fontSize : 12 , color : '#F4B644'  , textAlign : 'center' }}>Handover</p>
                                            </div>
                                        : data.CartDetailProIsHandovered === 1 ? 
                                            <div style={{ cursor : 'pointer' , width : '100%' , height : 25 , backgroundColor  : '#F4B644' , borderRadius : 5 , padding : '1px 5px' }} >
                                                <p style={{ fontSize : 12 , color : '#000' , textAlign : 'center' }}>Requested</p>
                                            </div>
                                        :
                                            <div style={{ cursor : 'pointer' , width : '100%' , height : 25 , backgroundColor  : '#008000' , borderRadius : 5 , padding : '1px 5px' }} >
                                                <p style={{ fontSize : 12 , color : '#fff' , textAlign : 'center' }}>Handovered</p>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
                
                {/* <Link to='/qr'>
                    <img className='re_cart_fifteen' src={scan} />
                </Link> */}
                <div className='mt-4'>
                    {/* <Link to='/handover_detail'> */}
                    {
                        clickhand?.products .length !== 0 ? <div style={{cursor:'pointer'}} onClick={()=>handsusaaa()} className='re_cart_thirteen'>
                        <p className='re_cart_fourteen'>Handover</p>
                    </div> : <p style={{textAlign:'center' , marginTop : '20px' , fontWeight:'600'}} >No Products Found</p>
                    }
                        
                    {/* </Link> */}
                </div>
                
                <ToastContainer />

                

            </>
        )
    }

    const pending_cancel = async(datas) =>{
        console.log(datas,'cancellllll')
        // return
        let responce = await axios({
            method  : 'post' , 
            url : `${Base_Url}/cancelPendingOrder`,
            headers: { 
                'Authorization': `Bearer ${token}` 
            },
            data : {
                orderId : datas.orderId
            }
        })
        .then((res)=>{
            if(res.data.response_code === 1){
                first()
                successnotify('Order Cancel Success')
            }
            console.log(res)
        })
    }

    const paymentCheck  = (data) =>{
        console.log(data)
        if(data.status === 1){
            navigate('/kyc_one')
            return
        }
        if(data.status === 2){
            navigate('/kyc_two')
            return
        }
        if(data.status === 3){
            navigate('/kyc_three')
        }
        if(data.status === 4){
            navigate('/kyc_four')
        }
        if(data.status === 5){
            navigate('/kyc_five')
        }
        if(data.status === 6){
            navigate('/payment/0/'+data.orderIdToSend)
        }
        if(data.status === 7){
            navigate('/track/'+data.orderId)
        }
        if(data.status === 8){
            navigate('/cart_one/'+data.orderIdToSend)
        }
    }

  return (
    <>
    
    {(()=>{
         if(props?.text.cat === 'pending'){
            return(
                pendings?.length === 0 ?  <p style={{ textAlign : 'center' , fontSize : '15px' , fontWeight : '600', marginTop:'40px'}}>No Pending Orders</p>  :
                    pendings?.orders.map((datas,key)=>{
                        return(
                        <div className='ord_det_one mt-4'>
                            <div className='row'>
                                <div className='col-6'>
                                    <p className='ord_det_two'>Order Id : {datas.OrderAutoGenId}</p>
                                    <p className='ord_det_threes'>{datas.fromdate}   -   {datas.todate}</p>
                                </div>
                                <div className='col-6'>
                                    <p className='ord_det_four'>₹{datas.orderamt}</p>
                                    <p className='ord_det_fives'>{datas.Type}</p>
                                </div>
                            </div>
                            <div className='row mt-1'>
                                {datas?.carts.map((dat,key)=>
                                
                                {
                                    return(
                                        
                                    key >=5 ?
                                    <></>
                                    : <div className='col-3' >
                                        <img className='ord_det_seven' src={ImageUrl+'/'+dat.ProImgPrimary} />
                                        <p className='ord_det_eight'>{dat.ProName.substring(0, 20)}...</p>
                                    </div>
                                )
                                }
                                    
                                )}
                                {
                                    datas .remainingCount === 0 ? '' :
                                        <div className='col-3' >
                                            <img className='ord_det_sevens' src={one123} />
                                        
                                            <p className='more_items'>{datas.remainingCount}<br/>Items</p>
                                            <p className='ord_det_eights'>View more</p>
                                        </div>   
                                }
                               
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <div style={{cursor:'pointer'}} onClick={()=>{
                                        onOpen()
                                        setCanceldata(datas)
                                    }} className='ord_det_ten mt-2'>
                                        <p className='ord_det_eleven'>Cancel order</p>
                                    </div>
                                </div>
                                <div className='col-6'>

                                    {/* <Link to={'/track/'+datas.orderId}> */}

                                        <div style={{cursor:'pointer'}} onClick={()=>paymentCheck(datas)} className='ord_det_nine mt-2'>
                                            <p className='ord_det_twelve'>View order</p>
                                        </div>

                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                        )
                        
                    })

                    
            )
            
            }else if(props.text.cat==="Handover"){
                return(
                        props.text.detail.map((data,key)=>
                        vals===0 ? <Handover data={data} /> :<ListOrders />))
            }else if(props.text.cat==="Running"){
                return(
                    props.text.detail.map((data,key)=>
                    val===0 ? <Orderlist data={data} /> : <ListOrder />))
                }
            else if(props.text.cat==="Service"){
                return(
                    props.text.detail.map((data,key)=>
                    valss===0 ? <Service data={data} /> : <Service_list />)
                    )
            }

            
    })()}

       
        <ChakraProvider >
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        <img style={{ width : 150 , height : 150 , margin : 'auto' , marginTop : 10 , marginBottom : 10 }} src={cccn} />
                        <p style={{ fontSize :  15 , fontWeight :  '600' , textAlign : 'center' }}>Cancel Order</p>
                    </ModalBody>

                    <ModalFooter>
                        <div style={{ width : '100%' }} >
                            <div className='row' >
                                <div className='col-6'>
                                    <Button width='100%' backgroundColor='#161E40' colorScheme='blue' mr={3} onClick={onClose}>
                                        Close
                                    </Button>
                                </div>
                                <div className='col-6'>
                                    <Button width='100%' colorScheme='red' mr={3} onClick={()=>{  
                                        pending_cancel(canceldata)
                                        onClose()
                                        }}>
                                        Confirm
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </ChakraProvider>    
    </>
  )
}