import React , { useState , useEffect } from 'react'
import img11 from '../../../assets/img/img11.png'
import img12 from '../../../assets/img/Vector.png'
import hrttt from '../../../assets/img/hrttt.png'
import newww from '../../../assets/img/newar.png'
import popularr from '../../../assets/img/popular.png'
import trendd from '../../../assets/img/trend.png'
import { Link } from "react-router-dom";
import { useSelector , useDispatch } from 'react-redux';
import hrrt from '../../..//assets/img/hrrt.png'
import { useNavigate } from 'react-router-dom';
import { selectedRemoveProduct ,Wishcount } from '../../../redux/actions/productAction'; 

export default function Detail_cat() {

    let navigate = useNavigate();
    const product = useSelector((state)=>state.allpoductData)
    const imgs = product.awsbaseurl
    const dispatch = useDispatch()
    let wishss = localStorage.getItem('wish_list')
    const [ wish ,  setWish ] = useState()
    
    const clicked = ( dat , key ) =>{
        navigate('/detail/'+ dat.ProName
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '')+'/' +dat.ProId)
        dispatch(selectedRemoveProduct())
    }

    
    useEffect(()=>{
        wishss = localStorage.getItem('wish_list')
        setWish(JSON.parse(wishss))
    },[])



    const [countss  , setCountss ]= useState(1)

    useEffect(()=>{
        wishss = localStorage.getItem('wish_list')
        setWish(JSON.parse(wishss))
    },[countss])

    const Clickheart = ( data , key ) => {
        setCountss(countss +1)
        let allata = localStorage.getItem('wish_list')
        let another = JSON.parse(allata)
        let final = 0
        // setHeart(true)

        let wish_data = [
            {
                datas : data
            }
        ]

        if(localStorage.getItem('wish_list')){
            another.forEach((element, index) => {
                if( element.datas.ProId === wish_data[0].datas.ProId){
                    let allanother = another
                    allanother.splice(index , 1)
                    localStorage.setItem('wish_list' , JSON.stringify(allanother))
                    dispatch(Wishcount(allanother))
                    final = 1
                }
            })
            if( final === 0 ){
                console.log('susssssss')
                let allanother =  another.concat(wish_data)
                localStorage.setItem('wish_list' , JSON.stringify(allanother))
                dispatch(Wishcount(allanother))
            }

        }else{
            localStorage.setItem('wish_list' , JSON.stringify(wish_data))
            dispatch(Wishcount(wish_data))
        }
        // let wish_list = localStorage.getItem('wish_list')

        // const All = async () => {
        //     let responce = await axios({
        //         method : 'post',
        //         url : `${Base_Url}/wishlist`,
        //         headers: { 
        //             'Authorization': `Bearer ${user}` 
        //         },
        //         data : {
        //             proId : data.ProId
        //         }
        //     })
        //     .then ((res)=>{
        //     })
        // }
        // All()
    }

  return (
    <>
    {product.Products_of_1st_category?.map((dat,key)=>
    <div>
        <div className='r1oo'>
            <div className='row' >
                <div  onClick={()=>{clicked(dat,key)}} style={{margin:'auto'}} className='col-4 imcol1'>
                    <img className='imm' src={imgs+'/'+dat.ProImgPrimary} />
                </div>
                <div className='col-8'>
                    <div className='row'>
                        <div className='d-flex justify-content-end mb-2' >
                            {
                                dat?.ProIsNew === 1 ?
                                    <img style={{ height : 22 , marginRight : -20 }} src={newww} /> :
                                dat?.ProIsTrending === 1 ?
                                    <img style={{ height : 22 , marginRight : -20 }} src={popularr} /> :
                                dat?.ProIsPopular === 1 ? 
                                    <img style={{ height : 22 , marginRight : -20 }} src={trendd} /> : ''

                            }
                        </div>
                        <div className='d-flex justify-content-end'>
                        
                                {(() => {
                                if(wish != null){
                                    console.log(typeof(wish),'wishhhh')
                                var result = wish.filter(x => x.datas.ProId == dat.ProId);

                                if(result.length > 0){
                                    return ( <img onClick={()=>Clickheart(dat,key)} style={{ zIndex : 1000 }} className="pop_icon" src={hrrt} />)
                                    }else{
                                        return ( <img onClick={()=>Clickheart(dat,key)} style={{ zIndex : 1000 }} className="pop_icon" src={hrttt} />)
                                    }
                                }
                                else{
                                    return ( <img onClick={()=>Clickheart(dat,key)} style={{ zIndex : 1000 }} className="pop_icon" src={hrttt} />)
                                }
                                })()}
                        </div>
                        <div style={{ marginTop : -24 }} onClick={()=>{clicked(dat,key)}} className='col-12'>
                        
                            <p style={{ marginBottom : 0 , paddingBottom : 0 , marginTop : 0}} className='paraone'>{dat.BrandName}</p>
                            <p style={{ fontWeight : 'bold' }} className='paratwodfgdg'>{dat.ProName.substring(0, dat.ProName.indexOf('-'))}</p>
                            <p style={{ fontWeight : '500' }} className='paratwo'>{dat.ProName.substring(dat.ProName.indexOf('-') + 1 , 65)}</p>
                            {/* <p className='parathree'>256/8 gb , 13.7” Retina Display</p> */}
                        </div>
                        
                    </div>
                    <div  onClick={()=>{clicked(dat,key)}} className='row'>
                        <div className='col-6'>
                            <div className='btoone'>
                                <p className='parafour'>₹ {dat.ProRentAmt}/M</p>
                            </div>
                        </div>
                        {/* <div className='col-6'>
                            <p className='proo_crt_onee'>50% Off</p>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    )}
    {
       product.Products_of_1st_category.length === 0 ? <p style={{textAlign:'center',marginTop:'20px' , fontWeight : '600' }}>No Product Found</p> : ''
    }
    </>
  )
}