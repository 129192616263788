import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState } from "react";
import store from "./redux/store";
import ScrollToTop from "./components/fullwidth/scroll/scroll";
import './App.css'
import './components/css/cart.css'
import 'react-loading-skeleton/dist/skeleton.css'

import { Provider } from 'react-redux'

import Homes from "./page/home";
import Product_details from "./page/product_details";
import Cart_page from "./page/cart_page";
import Wish from "./page/wish";
import Collection from "./page/collection";
import Checkout_page from "./page/checkout_page";
import Tracking_page from "./page/tracking_page";
import Profile_home from "./page/profile_home";
import Order_home from "./page/order_home";
import History_home from "./page/history_home";
import Payment_report from "./page/payment_report";
import Home_bank_detail from "./page/home_bank_detail";
import Home_kyc_one from "./page/home_kyc_one";
import Home_kyc_two from "./page/home_kyc_two";
import Home_kyc_three from "./page/home_kyc_three";
import Home_kyc_four from "./page/home_kyc_four";
import Home_kyc_five from "./page/home_kyc_five";
import Home_payment_one from "./page/home_payment_one";
import Home_ticket from "./page/home_ticket";
import Demo from "./components/fullwidth/demo/demo";
import Cart_summery from "./page/cart_summery";
import Pending_order from "./page/order_home/pending_order";
import Pending_checklist from "./page/order_home/pending_checklist";
import Running_details_erb from "./components/fullwidth/running_details_erb/Running_details_erb";
import Handover_req_web from "./components/fullwidth/handover_req/Handover_req_web";
import Renew_order_web from "./components/fullwidth/renew_order/Renew_order_web";
import Service_request_web from "./components/fullwidth/service_request_web/Service_request_web";
import Service_status_web from "./components/fullwidth/service_status_web/service_status_web";
import Serviced_web from "./components/fullwidth/serviced_web/serviced_web";
import Hand_reqq_web from "./components/fullwidth/hand_reqq_web/hand_reqq_web";
import Running_pro_detail from "./components/fullwidth/running_pro_detail/running_pro_detail";
import Handover_pro_detail_web from "./components/fullwidth/handover_pro_detail/handover_pro_detail_web";
import Web_search from "./components/fullwidth/web_search/web_search";
import Web_Support from "./components/fullwidth/support/Web_Support";
import OfferWeb from "./components/fullwidth/offerWeb/offerWeb";
import Quality_assurance_web from "./components/fullwidth/Quality_assurance_web/Quality_assurance_web";
import DamagePolicy_web from "./components/fullwidth/damagePolicy_web/damagePolicy_web";
import PrivacyPolicy_web from "./components/fullwidth/privacyPolicy_web/privacyPolicy_web";
import Terms_web from "./components/fullwidth/terms_web/terms_web";
import Blog_web from "./components/fullwidth/Blog_web/Blog_web";
import Blog_webtwo from "./components/fullwidth/Blog_web/Blog_webtwo";


import Refund_return from "./components/fullwidth/refund_return/refund_return";
import Shiping from "./components/fullwidth/shiping/shiping";
import Contact_us from "./components/fullwidth/contact_us/contact_us";
import Home_Notification from "./components/fullwidth/notification/notification";
import About_US from "./components/fullwidth/about_us/about_us";
import Company_profile_web from "./components/mobile_view_comp/mbl_login_three/company_profile_web";
import Company_password_change_web from "./components/mobile_view_comp/mbl_login_three/company_change_password copy";
import Company_contact_web from "./components/mobile_view_comp/mbl_login_three/company_contact_web";
import Edit_Company_contact_web from "./components/mobile_view_comp/mbl_login_three/edit_company_contsct_web";
import History_detail from "./components/fullwidth/running_details_erb/history_detail";
import UserAgrement from "./components/fullwidth/privacyPolicy_web/userAgrement";

import Windows_height_width from "./components/windows_height_width";
import Email_Veri from "./components/mobile_view_comp/mbl_login_three/email_veri";

import Mobile_home from "./page/mobile/mobile_home";
import Search from "./page/mobile/search";
import Dimension_err from "./page/mobile/dimension_err";
import Mob_login from "./page/mobile/mob_login";
import Mob_collection from "./page/mobile/mob_collection";
import Mob_cart from "./page/mobile/mob_cart";
import Mobile_product_detail from "./page/mobile/mobile_product_detail";
import Mobile_pro_detail from "./page/mobile/mobile_pro_detail";
import Mob_cart_one from "./page/mobile/mob_cart_one";
import Kyc_one from "./page/mobile/kyc_one";
import Kyc_two from "./page/mobile/kyc_two";
import Kyc_three from "./page/mobile/kyc_three";
import Kyc_four from "./page/kyc_four";
import Kyc_five from "./page/mobile/kyc_five";
import Payment from "./page/mobile/payment";
import Coupon from "./page/mobile/coupon";
import Login_two from "./page/mobile/login_two";
import Login_three from "./page/mobile/login_three";
import Otp from "./page/mobile/otp";
import Otp_two from "./page/mobile/otp_two";
import Mob_reg from "./page/mobile/mob_reg";
import Mob_profile from "./page/mobile/mob_profile";
import Edit_profile from "./page/mobile/edit_profile";
import New_address from "./page/mobile/new_address";
import Mob_order from "./page/mobile/mob_order";
import Order_track from "./page/mobile/order_track";
import Check_list from "./page/mobile/check_list";
import Checklist_verification from "./page/mobile/checklist_verification";
import Running_order from "./page/mobile/running_order";
import Renew_order from "./page/mobile/renew_order";
import Running_preview from "./page/mobile/running_preview";
import Renew_cart from "./page/mobile/renew_cart";
import Handover_req from "./page/mobile/handover_req";
import Qr_comp_page from "./components/mobile_view_comp/qr_page/qr_comp_page";
import Service_pick from "./page/mobile/service_pick";
import Four_veri from "./page/mobile/four_veri";
import Handover_detail from "./page/mobile/handover_detail";
import Service_request from "./page/mobile/service_request";
import Service_hand_status from "./page/mobile/service_hand_status";
import History from "./page/mobile/history";
import Mob_payment_report from "./page/mobile/mob_payment_report";
import Bank_detail from "./page/mobile/bank_detail";
import Mob_wish from "./page/mobile/mob_wish";
import Notification from "./page/mobile/notification";
import Support from "./page/mobile/support";
import Address from "./page/mobile/address";
import Serviced_mob from "./page/mobile/serviced_mob";
import Hand_reqq from "./components/hand_reqq/hand_reqq";
import Running_pro_detail_mob from "./components/mobile_view_comp/running_pro_detail_mob/running_pro_detail_mob";
import Offerweb from "./components/mobile_view_comp/offer/Offer";
import Quality_assurance_mobile from "./components/mobile_view_comp/Quality_assurance_mobile/Quality_assurance_mobile";
import DamagePolicy_mob from "./components/mobile_view_comp/damagePolicy_mob/damagePolicy_mob";
import PrivacyPolicy_mob from "./components/mobile_view_comp/privacyPolicy_mob/privacyPolicy_mob";
import Terms_mob from "./components/mobile_view_comp/terms_mob/terms_mob";
import Refund_return_mobile from "./components/mobile_view_comp/refund_return_mobile/refund_return_mobile";
import Shiping_mobile from "./components/mobile_view_comp/refund_mobile/refund_mobile";
import Contact_Us_mobile from "./components/mobile_view_comp/contact_us_mobile/contact_us_mobile";
import Company_login from "./components/mobile_view_comp/login_two/company_login";
import Mob_about_us from "./page/mobile/mob_about_us";
import Company_profile from "./components/mobile_view_comp/mbl_login_three/company_profile";
import Company_contact from "./components/mobile_view_comp/mbl_login_three/company_contact";
import Company_password_change from "./components/mobile_view_comp/mbl_login_three/company_change_password";
import Edit_Company_contact from "./components/mobile_view_comp/mbl_login_three/edit_company_contsct";
import History_detail_mob from "./components/fullwidth/running_details_erb/history_detail_mob";
import MobuserAgrement from "./components/mobile_view_comp/privacyPolicy_mob/MobuserAgrement";

import { Base_Url, newURL } from "./components/globalvariable/globe";
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';
import { useCookies } from "react-cookie";
import axios from "axios";
import PrivacyPolicy_agent_web from "./components/fullwidth/privacyPolicy_web/privacyPolicy_agent_web";
import PrivacyPolicy_agent_mob from "./components/mobile_view_comp/privacyPolicy_mob/PrivacyPolicy_agent_mob";



function App() {
  // const [cookies, setCookie] = useCookies(["usercat"]);

  const { height, width } = Windows_height_width();

//   let date = new Date();
//   date.setDate(date.getDate() + 15)
//   let expires = "; expires=" + dateC.toUTCString();

//   let urlParamsObject = new URLSearchParams(window.location.search);
//   let affiliateId = urlParamsObject.get('cat');
//   console.log("parmss", cookies.usercat)
// const sendCookie=cookies.usercat
//   const bodyParameters = {
//     key: "value",
//     cookie:sendCookie
//   };
  
//   const fectch_data = async() =>{
//     const response = await axios.post(`${Base_Url}/homePageWeb`, bodyParameters)
//     .catch((err)=>{
//       console.log(err)
//     })
//     console.log("awef",response.data.Data)
// }

//   if(cookies){
//     fectch_data();
//     console.log("send cookie")
//   }
//   if (affiliateId) {
//     setCookie("usercat", "campaign651", { expires: date });
//     // console.log("parmss", cookies)

//   }
  
  return (



    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Best IT Product on Rent Services in Chennai </title>
            <meta name="description" content="Rent Laptops, Desktops, Macbook, Iphone, Printers and Projectors in Chennai with most affordable prices." />
            <meta name="keywords" content="laptop rental, macbook rental, destop rental, mac rental." />
          </Helmet>
          <ScrollToTop />


          <Routes  >

            <Route path="/" element={width <= 726 ? <Mobile_home /> : <Homes />} />
            <Route path="/detail/:slug/:id" element={width <= 726 ? <Mobile_product_detail /> : <Product_details />} />
            <Route path="/cart" element={width <= 726 ? <Mob_cart /> : <Cart_page />} />
            <Route path="/wish" element={width <= 726 ? <Mob_wish /> : <Wish />} />
            <Route path="/products/:name/:id/:names/:bro" element={width <= 726 ? <Mob_collection /> : <Collection />} />
            <Route path="/track/:id" element={width <= 726 ? <Order_track /> : <Pending_order />} />
            <Route path="/profile" element={width <= 726 ? <Mob_profile /> : <Profile_home />} />
            <Route path="/order" element={width <= 726 ? <Mob_order /> : <Order_home />} />
            <Route path="/history" element={width <= 726 ? <History /> : <History_home />} />
            <Route path="/payment_report" element={width <= 726 ? <Mob_payment_report /> : <Payment_report />} />
            <Route path="/bank_detail" element={width <= 726 ? <Bank_detail /> : <Home_bank_detail />} />
            <Route path="/kyc_one" element={width <= 726 ? <Kyc_one /> : <Home_kyc_one />} />
            <Route path="/kyc_two" element={width <= 726 ? <Kyc_two /> : <Home_kyc_two />} />
            <Route path="/kyc_three" element={width <= 726 ? <Kyc_three /> : <Home_kyc_three />} />
            <Route path="/kyc_four" element={width <= 726 ? <Kyc_four /> : <Home_kyc_four />} />
            <Route path="/kyc_five" element={width <= 726 ? <Kyc_five /> : <Home_kyc_five />} />
            <Route path="/payment/:id/:ordid" element={width <= 726 ? <Payment /> : <Home_payment_one />} />
            <Route path="/cart_one/:id" element={width <= 726 ? <Mob_cart_one /> : <Cart_summery />} />
            <Route path="/coupon/:id" element={width <= 726 ? <Coupon /> : <Home_ticket />} />
            <Route path="/checklist/:id" element={width <= 726 ? <Check_list /> : <Pending_checklist />} />
            <Route path="/running_order/:id" element={width <= 726 ? <Running_order /> : <Running_details_erb />} />
            <Route path="/handover_req/:id/:type" element={width <= 726 ? <Handover_req /> : <Handover_req_web />} />
            <Route path="/renew_order/:id" element={width <= 726 ? <Renew_order /> : <Renew_order_web />} />
            <Route path="/service_request/:id/:type" element={width <= 726 ? <Service_request /> : <Service_request_web />} />
            <Route path="/service_status/:cartid/:id/:no" element={width <= 726 ? <Service_hand_status /> : <Service_status_web />} />
            <Route path="/serviced/:cartid/:id/:serial" element={width <= 726 ? <Serviced_mob /> : <Serviced_web />} />
            <Route path="/handover_detail/:id/:ary/:no" element={width <= 726 ? <Hand_reqq /> : <Hand_reqq_web />} />
            <Route path="/handover_all/:id/:cartid" element={width <= 726 ? <Handover_detail /> : <Handover_pro_detail_web />} />
            <Route path="/running_pro_detail/:id/:catid/:detid" element={width <= 726 ? <Running_pro_detail_mob /> : <Running_pro_detail />} />
            <Route path="/search" element={width <= 726 ? <Search /> : <Web_search />} />
            <Route path="/support" element={width <= 726 ? <Support /> : <Web_Support />} />
            <Route path="/offer/:id" element={width <= 726 ? <Offerweb /> : <OfferWeb />} />



            <Route path="/faq" element={width <= 726 ? <Quality_assurance_mobile /> : <Quality_assurance_web />} />
            <Route path="/damage_policy" element={width <= 726 ? <DamagePolicy_mob /> : <DamagePolicy_web />} />
            <Route path="/privacy_policy" element={width <= 726 ? <PrivacyPolicy_mob /> : <PrivacyPolicy_web />} />
            <Route path="/agent_privacy_policy" element={width <= 726 ? <PrivacyPolicy_agent_mob /> : <PrivacyPolicy_agent_web />} />

            <Route path="/refund_return_policy" element={width <= 726 ? <Refund_return_mobile /> : <Refund_return />} />
            <Route path="/useragreement" element={width <= 726 ? <MobuserAgrement /> : <UserAgrement />} />
            <Route path="/shipping_policy" element={width <= 726 ? <Shiping_mobile /> : <Shiping />} />
            <Route path="/contact_us" element={width <= 726 ? <Contact_Us_mobile /> : <Contact_us />} />
            <Route path="/terms" element={width <= 726 ? <Terms_mob /> : <Terms_web />} />
            <Route path="/blog" element={width <= 726 ? <Blog_web /> : <Blog_web />} />
            <Route path="/blog/:slug" element={width <= 726 ? <Blog_webtwo /> : <Blog_webtwo />} />


            <Route path="/notification" element={width <= 726 ? <Notification /> : <Home_Notification />} />
            <Route path="/about_us" element={width <= 726 ? <Mob_about_us /> : <About_US />} />
            <Route path="/corporate_profile" element={width <= 726 ? <Company_profile /> : <Company_profile_web />} />
            <Route path="/password_change" element={width <= 726 ? <Company_password_change /> : <Company_password_change_web />} />
            <Route path="/corporate_contact/:id" element={width <= 726 ? <Company_contact /> : <Company_contact_web />} />
            <Route path="/edit_corporate_contact/:details/:id" element={width <= 726 ? <Edit_Company_contact /> : <Edit_Company_contact_web />} />
            <Route path="/email_Verification/:id" element={width <= 726 ? <Email_Veri /> : <Email_Veri />} />
            <Route path="/history_detail/:id/:no" element={width <= 726 ? <History_detail_mob /> : <History_detail />} />
            {/* mobile view  */}

            <Route path="/login" element={width <= 726 ? <Mob_login /> : <Dimension_err />} />
            <Route path="/login_two" element={width <= 726 ? <Login_two /> : <Dimension_err />} />
            <Route path="/login_three" element={width <= 726 ? <Login_three /> : <Dimension_err />} />
            <Route path="/corporate_login" element={width <= 726 ? <Company_login /> : <Dimension_err />} />
            <Route path="/otp/:no" element={width <= 726 ? <Otp /> : <Dimension_err />} />
            <Route path="/otp_two" element={width <= 726 ? <Otp_two /> : <Dimension_err />} />
            <Route path="/reg" element={width <= 726 ? <Mob_reg /> : <Dimension_err />} />
            <Route path="/mobile_detail/:id" element={width <= 726 ? <Mobile_pro_detail /> : <Dimension_err />} />
            {/* <Route path="/qr" element={ width <= 726 ?  <Qr_comp_page /> : <Dimension_err />} /> */}
            {/* <Route path="/checklist_verification" element={ width <= 726 ?  <Checklist_verification /> : <Dimension_err />} /> */}
            <Route path="/code_veri" element={width <= 726 ? <Four_veri /> : <Dimension_err />} />
            <Route path="/edit_profile" element={width <= 726 ? <Edit_profile /> : <Dimension_err />} />
            <Route path="/new_address" element={width <= 726 ? <New_address /> : <Dimension_err />} />
            <Route path="/running_preview" element={width <= 726 ? <Running_preview /> : <Dimension_err />} />
            <Route path="/renew_cart" element={width <= 726 ? <Renew_cart /> : <Dimension_err />} />
            <Route path="/service_pick" element={width <= 726 ? <Service_pick /> : <Dimension_err />} />
            <Route path="/address" element={width <= 726 ? <Address /> : <Dimension_err />} />
          


            <Route path="*" element={width <= 726 ? <Mobile_home /> : <Homes />} />


          </Routes>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );
}

export default App;